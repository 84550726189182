import React, { Component } from 'react';

/* local components */
import DataHandler from '../../general/DataHandler';
import TaimerComponent from "../../TaimerComponent";
import DialogBorder from './../../dialogs/DialogBorder';
import DialogSection from './../../dialogs/DialogSection';
import { ReactComponent as infoIcon } from './../../notifications/icons/info.svg';
import SettingsList from "./../../list/lists/SettingsList";
import SimpleSettingRow from "./../../list/rows/SimpleSettingRow";

/* context */
import { SettingsContext } from '../../SettingsContext';

/* styles */
import './RateChanceForInvoicesDialog.css';

class RateChanceForInvoicesDialog extends TaimerComponent {
    static contextType = SettingsContext;

    constructor (props, context){
        super(props, context, "settings/dialogs/RateChanceForInvoicesDialog");

    }

    render() {

        const { onSave, onSaveAndEdit, invoices, newRate, currencySymbol } = this.props.data;
        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };

        const drafts = invoices?.invoices.filter(i => i.bill_type == '2') || [];
        const waiting = invoices?.invoices.filter(i => i.bill_type == '1') || [];
        const bills = invoices?.bills || [];
        const invoiceCount = drafts.length + waiting.length;
        const billCount = bills.length;

        return (
            <DialogBorder
                id="RateChanceForInvoicesDialog"
                icon={infoIcon}
                title={this.tr("Change rates for existing invoices?")}
                onClose={this.props.onDialogClose}
                className="TMRDialogGrid TMRDialogInputs"
                rightButtons={
                    <React.Fragment>
                        <div 
                            className={"TMRDialogButton blue "} 
                            onClick={onSave} >
                                {this.tr("Save")}
                        </div>
                        <div 
                            className={"TMRDialogButton blue "} 
                            onClick={onSaveAndEdit} >
                                {this.tr("Edit & Save")}
                        </div>
                    </React.Fragment>                   
                }>
                <div class="section-container vertical">
                    <div className="currency-invoices-block">
                        <h3 className="section-header full">{this.tr("You are changing the rate for a currency that has ${invoiceCount} invoices in draft and/or waiting status and ${billCount} bills. Edit rates or save without editing.", {invoiceCount: invoiceCount, billCount: billCount})}</h3> 
                        <h4 className="section-header full sub-header">{this.tr("New currency rate")}: <strong>{Number(newRate).toFixed(6)}</strong> </h4>

                        {drafts.length > 0 && <div className="subsection">
                            <h4>{this.tr("Draft invoices")} </h4>
                            {drafts.map(invoice => (
                                <div className="currency-invoice-row">
                                    {Object.entries(invoice).map(([name, value]) => (
                                        !['id', 'bill_type'].includes(name) &&
                                        <div className={`currency-invoice-cell-${name}`} >
                                            {name == 'total' ? `${Number(value)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currencySymbol}` : value}
                                        </div>)
                                    )}
                                </div>
                            ))}
                        </div>}                        

                        {waiting.length > 0 && <div className="subsection">
                            <h4>{this.tr("Waiting invoices")} </h4>
                            {waiting.map(invoice => (
                                <div className="currency-invoice-row">
                                    {Object.entries(invoice).map(([name, value]) => (
                                        !['id', 'bill_type'].includes(name) &&
                                        <div className={`currency-invoice-cell-${name}`} >
                                            {name == 'total' ? `${Number(value)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currencySymbol}` : value}
                                        </div>)
                                    )}
                                </div>
                            ))}
                        </div>}    

                         {bills.length > 0 && <div className="subsection">
                            <h4>{this.tr("Bills")} </h4>
                            {bills.map(invoice => (
                                <div className="currency-invoice-row">
                                    {Object.entries(invoice).map(([name, value]) => (
                                        !['id', 'bill_type'].includes(name) &&
                                        <div className={`currency-invoice-cell-${name}`} >
                                            {name == 'total' ? `${Number(value)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currencySymbol}` : value}
                                        </div>)
                                    )}
                                </div>
                            ))}
                        </div>}          
                    </div>
                </div>  
            </DialogBorder>
        );
    }
}

export default RateChanceForInvoicesDialog;