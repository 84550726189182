/* react */
import React from 'react';

/* css */
import './Efina.css';
import './Maventa.css';

/* material-ui */
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import TaimerComponent from "../../TaimerComponent";
import OutlinedField from '../../general/OutlinedField';

/* others */
import TrashCanIcon from "@mui/icons-material/Delete";
import MenuItem from '@mui/material/MenuItem';
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import List from "../../list/List";
import ListCell from "../../list/ListCell";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import TextInputCell from "../../list/cells/TextInputCell";
import SettingRow from "../../list/rows/SettingRow";
import SettingsGrid from '../pages/SettingsGrid';

class AccountRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            account_number: <TextInputCell
                width={this.props.columnWidthMap['account_number']}
                name="account_number"
                value={this.state.data['account_number']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class ProductRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            product_id: <TextInputCell
                width={this.props.columnWidthMap['product_id']}
                name="product_id"
                value={this.state.data['product_id']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class DimensionHeaderRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class DimensionRow extends SettingRow {
    dimensionHeadersChanged(obj) {
        const newState = this.state.data; 
        newState.accounting_dimension_headers_id = obj.id; 
        this.setState({data: newState}); 
        this.editAndSave();
    }

    render() {
        const dh = this.props.rowProps.dimensionHeaders.find(d => d.id == this.state.data['accounting_dimension_headers_id']);

        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            dimension: <TextInputCell
                width={this.props.columnWidthMap['dimension']}
                name="dimension"
                value={this.state.data['dimension']}
                onEdited={this.editAndSave}
            />,
            accounting_dimension_headers_id: <AutoCompleteCell
                listCellProps={{
                    className: "accounting_dimension_headers_id"
                }}
                editable={true}
                autoCompleteData={this.props.rowProps.dimensionHeaders}
                name="accounting_dimension_headers_id"
                width={this.props.columnWidthMap['accounting_dimension_headers_id']}
                value={dh}
                onEdited={obj => this.dimensionHeadersChanged(obj)}
            />
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class Fennoa extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Fennoa");

        this.state = {
            username: "",
            password: "",
            deliveryType: 0,
            use_integration_id_prefix: 0
        };

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.salesAccountsList = React.createRef();
        this.productsList = React.createRef();
        this.dimensionsList = React.createRef();
        this.dimensionHeadersList = React.createRef();

        this.deliveryTypes = [
            {value: 0, label: this.tr("Automatic")},
            {value: 1, label: this.tr("E-invoice")},
            {value: 4, label: this.tr("Email invoice")},
            {value: 2, label: this.tr("Paper invoice")}, 
            {value: 5, label: this.tr("Manual")}
        ];
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getSalesAccounts();
        this.getProducts();
        this.getDimensions();
        this.getDimensionHeaders();
        this.getDeliveryMethod();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/fennoa`}).done(response => {
            this.setState(response);
        });
    }

    getSalesAccounts = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/fennoa/1`}).done(response => {
            this.setState(response);
        });
    }

    getProducts = () => {
        DataHandler.get({url: `accounting/products/${this.props.company}/fennoa`}).done(response => {
            this.setState(response);
        });
    }

    getDimensions = () => {
        DataHandler.get({url: `accounting/dimensions/${this.props.company}/fennoa`}).done(response => {
            this.setState(response);
        });
    }

    getDimensionHeaders = () => {
        DataHandler.get({url: `accounting/dimension_headers/${this.props.company}/fennoa`}).done(response => {
            this.setState(response);
        });
    }

    getDeliveryMethod = () => {
        DataHandler.get({ url: `settings/company/${this.props.company}/invoicedeliverytype` },)
        .done(resp => {
            this.setState({deliveryType: resp.delivery_type});
        })
        .fail(err => {});
    };

	changeDeliveryMethod = (type) => {
        DataHandler.post({url: `settings/company/${this.props.company}/invoicedeliverytype`}, {default_invoice_delivery_type: type}).done(() => {
            this.setState({deliveryType: type});
        });
    };

    onCheckChange = (update, name, value) => {
        this.setState({[name]: value}, () =>  DataHandler.put({url: `settings/company/${this.props.company}/fennoa/contract_billing`}, this.state));
    }

    onIntegrationSettingsChange = (update, name, value) => {
        this.setState({[name]: value}, () =>  DataHandler.post({url: `settings/integrations/${this.props.company}/fennoa/use_integration_id_prefix`}, {value: value}));
    }

    onClickSave = () => {
        DataHandler.put({url: `settings/company/${this.props.company}/fennoa`}, this.state).done(resp => {
            this.props.enqueueSnackbar(this.tr("Fennoa authentication information saved"), {
                variant: "success",
            });	
            this.updateComponentData();
        });
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        DataHandler.delete({url: `settings/company/${this.props.company}/fennoa`}).done(this.back);
    }
    
    render(){
        const { username, password, activated } = this.state;        
        const { tr } = this;

        const settings = { use_maventa_targeting: this.state.use_maventa_targeting, use_integration_id_prefix: this.state.use_integration_id_prefix };

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Fennoa Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("Authentication information")}</h4>
                    <OutlinedField value={username} onChange={(evt) => this.setState({username: evt.target.value})} label={tr("Company alias")} />
                    <OutlinedField value={password} onChange={(evt) => this.setState({password: evt.target.value})} label={tr("API Key")} autoComplete="new-password" />
                </div>

                <div className="settings">
                    <Button color="primary" onClick={this.onClickSave} size="large">{tr("Save")}</Button>
                </div>

                <div className="settings">
                    <h4>{tr("Default delivery method")}</h4>
                    <OutlinedField select disabled={false} label={"Default"} name={"default"} value={this.state.deliveryType} fullWidth >
                        {this.deliveryTypes.map(({value, label}) => <MenuItem key={value} value={value} onClick={() => this.changeDeliveryMethod(value)}>{label}</MenuItem>)}
                    </OutlinedField>
                </div>

                <div className="settings">
                    <h4>{tr("Sales accounts")}</h4>
                    <Button className="green" onMouseUp={() => this.salesAccountsList.current.addNewRow()} size="large">{tr("Add account")}</Button>
                    <br />
                    <List
                        ref={this.salesAccountsList}
                        id="accountingSalesAccountList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            account_number: "",
                        }}
                        listRowType={AccountRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Account name"), ...this.dColConf },
                            { width: 200, name: "account_number", header: tr("Account number"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                DataHandler.post({url: `accounting/accounts/${this.props.company}/fennoa/1`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: `accounting/accounts/${this.props.company}`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            },
                            onDelete: data => {
                                data.deleted = "1";
                                DataHandler.delete({url: `accounting/accounts/${this.props.company}`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            }
                        }}
                        data={this.state.salesAccounts}
                    />
                </div>

                <div className="settings">                  
                    <h4>{tr("Products")}</h4>
                    <Button className="green" onMouseUp={() => this.productsList.current.addNewRow()} size="large">{tr("Add product")}</Button>
                    <br />
                    <List
                        ref={this.productsList}
                        id="accountingProductsList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            product_id: "",
                        }}
                        listRowType={ProductRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Account name"), ...this.dColConf },
                            { width: 200, name: "product_id", header: tr("Product ID"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                data.company = this.props.company;
                                DataHandler.post({url: 'accounting/products/fennoa'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: 'accounting/products'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            },
                            onDelete: data => {
                                data.deleted = "1";
                                DataHandler.delete({url: 'accounting/products'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            }
                        }}
                        data={this.state.accountingProducts}
                    />
                </div>

                <div className="settings">                  
                    <h4>{tr("Dimension types")}</h4>
                    <Button className="green" onMouseUp={() => this.dimensionHeadersList.current.addNewRow()} size="large">{tr("Add dimension header")}</Button>
                    <br />
                    <List
                        ref={this.dimensionHeadersList}
                        id="dimensionHeadersList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                        }}
                        listRowType={DimensionHeaderRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Dimension header name"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                data.company = this.props.company;
                                DataHandler.post({url: 'accounting/dimension_headers/fennoa'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensionHeaders();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: 'accounting/dimension_headers'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensionHeaders();
                                });
                            },
                            onDelete: data => {
                                data.deleted = "1";
                                DataHandler.delete({url: 'accounting/dimension_headers'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensionHeaders();
                                });
                            }
                        }}
                        data={this.state.dimensionHeaders}
                    />
                </div>

                <div className="settings">                  
                    <h4>{tr("Dimensions")}</h4>
                    <Button className="green" onMouseUp={() => this.dimensionsList.current.addNewRow()} size="large">{tr("Add dimension")}</Button>
                    <br />
                    <List
                        ref={this.dimensionsList}
                        id="dimensionsList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            accounting_dimension_headers_id: 0,
                            branchofbusiness_id: 0
                        }}
                        listRowType={DimensionRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "accounting_dimension_headers_id", header: tr("Dimension header"), ...this.dColConf },
                            { width: 350, name: "name", header: tr("Dimension name"), ...this.dColConf },
                            { width: 350, name: "dimension", header: tr("Dimension code"), ...this.dColConf },
                        ]}
                        rowProps={{
                            dimensionHeaders: this.state.dimensionHeaders ? this.state.dimensionHeaders : [],
                            onCreate: data => {
                                data.company = this.props.company;
                                DataHandler.post({url: 'accounting/dimensions/fennoa'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensions();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: 'accounting/dimensions'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensions();
                                });
                            },
                            onDelete: data => {
                                data.deleted = "1";
                                DataHandler.delete({url: 'accounting/dimensions'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensions();
                                });
                            }
                        }}
                        data={this.state.dimensions}
                    />
                </div>
                
                <div id="settings-maventa" className="main-integration-wrapper">
                    <h4>{tr("Fennoa contract billing")}</h4>
                    <p>{tr("Connect your Fennoa bills to contract billing of your customers")}</p>
                    <SettingsGrid item={settings} onChange={this.onCheckChange} settings={[{
                        type: "check",
                        name: "use_maventa_targeting",
                        label: tr("Fennoa contract billing"),
                        className: "respSetting third"
                    }]} />
                </div>

                <div id="settings-maventa" className="main-integration-wrapper">
                    <h4>{tr("Fennoa customer numbers")}</h4>
                    <p>{tr("Automatically adds letter 'F' in front of the Fennoa customer numbers of new PSA accounts. This setting does not update existing customer numbers")}</p>
                    <SettingsGrid item={settings} onChange={this.onIntegrationSettingsChange} settings={[{
                        type: "check",
                        name: "use_integration_id_prefix",
                        label: tr("Add an F in front of the Fennoa IDs of new PSA accounts"),
                        className: "respSetting third"
                    }]} />
                </div>
            </div>
        )
    }
}

export default Fennoa;
