import React, { Component } from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import DataHandler from '../../general/DataHandler';
import DialogBorder from '../DialogBorder';
import DialogSection from '../DialogSection';
import TaimerComponent from  '../../TaimerComponent';
import ImportReview from './ImportReview';

/* dialog parts */
import ImportTool from '../../general/ImportTool';
import LoadingScreen from '../../general/LoadingScreen';
import FileUpload from './FileUpload';
import ResultsScreen from './ResultsScreen';
import ErrorScreen from './ErrorScreen';

/* material ui */
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';

/* other */
import { withSnackbar } from 'notistack';
import _ from 'lodash';

/* css */
import './ImportDialog.css'

const initialState = {
        file: [],
        fileData: false,
        importObjects: [],
        importData: [],
        importStage: "upload",
        error: false,
        noMemory: false,
        onlyIntStr: false,
        fileAttributes: {
            totals: {
                rowTotal: 0,
                taimerTotal: 0,
                fileErrorTotal: 0,
            },
            duplicateNameDiffCode: [],
            duplicateCodeDiffName: [],
            duplicateCode: [],
            //codeExistsDiffName: [],
            codeExists: [],
            nameExists: [],
            valueNaN: [],
            noProduct: [],
        },
        errorHandling: {
            skip: true,
            override: false,
        },
        errorRows: {
            inFile: [],
            inTaimer: [],
        },
        importResults: {
            total: 0,
            success: 0,
            skip: 0,
            errors: {
                totals: {
                    totalErrors: 0,
                    rowErrors: 0,
                },
                product: {
                    product: [],
                    group: [],
                }
            }
        },
        importStep: 0,
    }


class ImportProductDialog extends TaimerComponent {

    static contextType = SettingsContext;
    constructor(props, context) { 
        super(props, context, "dialogs/imports/ImportProductDialog");

        this.productFields = [
            {id: 0,  name: "" },
            {id: 1,  name: this.tr("Product Code")},
            {id: 2,  name: this.tr("Product Name")},
            {id: 3,  name: this.tr("Product Group")},
            {id: 4,  name: this.tr("Product Sub-Group 1")},
            {id: 5,  name: this.tr("Product Sub-Group 2")},
            {id: 6,  name: this.tr("Product Additional Sub-Groups")},
            {id: 7,  name: this.tr("Product Free Balance")},
            {id: 8,  name: this.tr("Product Unit Cost")},
            {id: 9,  name: this.tr("Product Selling Price")},
            {id: 10, name: this.tr("Product VAT %")},
            {id: 11, name: this.tr("Product Discount %")},
            {id: 12, name: this.tr("Product Unit")},
            {id: 13, name: this.tr("Product Comment")}
        ];

        this.state = _.cloneDeep(initialState);
        this.dialog = React.createRef();
        this.upload = React.createRef();


        ["setErrorHandling", 
        "setImportObjects", 
        "setFileData", 
        "startLoadingAnimation", 
        "reviewImport", 
        "save", 
        "handleNext", 
        "handleBack",
        "stageFile", 
        "uploadFile"].forEach(e => this[e] = this[e].bind(this));
    }

    async componentDidMount() {
        super.componentDidMount();
        const products = await DataHandler.get({url: "imports/products_by_code"});
        this.setState({productsByCode: products});
        const groups = await DataHandler.get({url: "imports/product_groups_by_category"});
        this.setState({productGroupsByCategory: groups});
    }

    stageFile(file) {
        this.setState({file: file});
    }

    uploadFile() {
        const { enqueueSnackbar } = this.props;
        const { file } = this.state;
        if(file.length == 0) {
            enqueueSnackbar(this.tr(`Upload a file to continue.`), {
                variant: "error",
            });
            return;
        }
        if(file.length > 0 && !file[0].name.endsWith('xlsx')) {
            enqueueSnackbar(this.tr(`File format is not XLSX.`), {
                variant: "error",
            });
            return;
        }
        if(file.length > 0 && file[0].size > (1048576 * 5)) {
            enqueueSnackbar(this.tr(`File is too large`, "."), {
                variant: "error",
            });
            return;
        }

        this.upload.current.uploadFiles(file);
    }

    setImportObjects(importObjects) {
        this.setState({ importObjects: importObjects });
    }

    setFileData(data) {
        if (data.errors.out_of_memory) {
            this.setState({importStage: "error", noData: true});
        } else if (data.errors.only_int_with_str) {
            this.setState({importStage: "error", onlyIntStr: true});
        } else if (data.errors.error) {
            this.setState({importStage: "error"});
        } else {
            const objects = [];
            _.forEach(data.data, d => {
                const obj = JSON.parse(d)
                objects.push(obj)
            })
            data.data = objects;

            this.setState({fileData: data, importStage: "config", file: []});
            this.handleNext();
        }
    }

    startLoadingAnimation(e) {
        if(e.target.name === "upload")
            this.setState({importStage: "loading"});
    }
    
    setErrorHandling(e) {
        this.setState({ errorHandling: e});
    }

    close = () => {
        this.props.onDialogClose();
        //this.props.updateParent();
    }

    handleNext() {
        let { importStep } = this.state;
        importStep++;
        this.setState({importStep: importStep});
    }

    handleBack() {
        let component = "";
        let { importStep } = this.state;
        const stage = this.state.importStage;
        if(stage === "config" || stage === "error") {
            component = "upload";
            this.setState(_.cloneDeep(initialState));
        } else if(stage === "review") {
            component = "config";
            this.setState({
                fileAttributes: {
                    totals: {
                        rowTotal: 0,
                        taimerTotal: 0,
                        fileErrorTotal: 0,
                    },
                    duplicateNameDiffCode: [],
                    duplicateCodeDiffName: [],
                    //codeExistsDiffName: [],
                    duplicateCode: [],
                    codeExists: [],
                    nameExists: [],
                    valueNaN: [],
                    noProduct: [],
                },
                errorHandling: "skip",
                errorRows: {
                    inFile: [],
                    inTaimer: [],
                },
            });
        }
        if(importStep > 0)
            importStep--;
        this.setState({importStep: importStep, importStage: component});
    }

    toColumnName = (num) => {
        for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
          ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
        }
        return ret;
    }

    handleConflict = (e) => {
        const { errorHandling } = this.state;
        errorHandling[e.target.value] = !errorHandling[e.target.value]; 
        this.setState({ errorHandling: errorHandling});
    }

    reviewImport() { 
        const { fileAttributes, fileData, importObjects, errorRows, productsByCode, productGroupsByCategory } = this.state;
        const { enqueueSnackbar } = this.props
        let duplicates = 0;
        const fieldsToSave = [], productsToSave = [];
        const seenCodes = {}, seenCodesRows = {};
        const seenNames = {}, seenNamesRows = {};
        const seenGroupsByCode = {}, seenGroupsByCodeRows = {};
        const seenGroupsByName = {}, seenGroupsByNameRows = {};
        
        this.setState({importStage: "loading"}, () => {
            _.forEach(importObjects, object => {
                if (object.importable) {
                    if (fieldsToSave.find(x => x.taimerField.id == object.taimerField.id)) {
                        duplicates++;
                    }
                    fieldsToSave.push(object);
                }
    
            });
            if (duplicates > 0) { 
                enqueueSnackbar(this.tr(`You have `) + duplicates + this.tr(` duplicate fields`), {
                    variant: "error",
                });
                this.setState({importStage: "config", importObjects: importObjects});
                return;
            }
            fileData.data.forEach((product, i) => {
                const productFields = {};
                const groupStructure = [];
                let currentName, currentCode, currentGroup = "";
                const groupParentCount = -1;
                let existingParentCount, groupParent  = 0;
                let newGroup, hasCode, hasName = false;

                _.forEach(fieldsToSave, (field, n) => {
                    productFields[field.taimerField.id] = product[field.id].name;
                    if(field.taimerField.id == 1){
                        currentCode = product[field.id].name;
                        if(product[field.id].name !== "")
                            hasCode = true;
                    }
                    if(field.taimerField.id == 2){
                        currentName = String(product[field.id].name).toLowerCase();
                        if(product[field.id].name !== "")
                            hasName = true;
                    }
                    if(field.taimerField.id > 2 && field.taimerField.id < 6) {
                        currentGroup = String(product[field.id].name);
                        if(currentGroup !== ""){
                            groupStructure.unshift(currentGroup);
                        }
                    }
                    if(field.taimerField.id == 6) {
                        if(product[field.id].name != "") {
                            const groups = product[field.id].name.split(',');
                            groups.forEach(group => {
                                currentGroup = String(group);
                                if(currentGroup !== "") {
                                    groupStructure.unshift(currentGroup);
                                }
                            })
                        }
                    }
                    if(field.taimerField.id == 7 || field.taimerField.id == 8 || field.taimerField.id == 9 || field.taimerField.id == 10 || field.taimerField.id == 11) {
                        if (typeof(product[field.id].name) === "string") {
                            product[field.id].name = Number(product[field.id].name.replace(',', '.'));
                        } else {
                            product[field.id].name = Number(product[field.id].name);
                        }
                        if(isNaN(product[field.id].name)){
                            fileAttributes.valueNaN.push(this.toColumnName(product[field.id].id) + " & " + (i+2));
                            fileAttributes.totals.fileErrorTotal++;
                            errorRows.inFile.push(i);
                        }
                    }
                });
                  
                const diffByCode = undefined; 
                let diffByName = undefined;
                if (!currentCode) {
                    _.forEach(productGroupsByCategory.by_name, (product, i) => {
                        if (product.hasOwnProperty(currentName)) {
                            let i_bn = 0;
                            groupStructure.filter(g => {
                                diffByName = productGroupsByCategory.by_name[i][currentName].includes(g, i_bn);
                                i_bn++;
                            })
                        } 
                    }) 
                }
                if((currentCode && seenCodes[currentCode] && seenCodes[currentCode] !== currentName)) {
                    fileAttributes.duplicateCodeDiffName.push((seenCodesRows[currentCode] + " & " + (i+2)));
                    fileAttributes.totals.fileErrorTotal++;
                    errorRows.inFile.push(i);
                }
                if(currentCode && seenCodes.hasOwnProperty(currentCode)) {
                    fileAttributes.duplicateCode.push((seenCodesRows[currentCode] + " & " + (i+2)));
                    fileAttributes.totals.fileErrorTotal++;
                    errorRows.inFile.push(i);
                }
                /*if(currentCode && productsByCode[currentCode] && String(productsByCode[currentCode]).toLowerCase() !== currentName) {
                    fileAttributes.codeExistsDiffName.push(i+2);
                    fileAttributes.totals.taimerTotal++;
                    errorRows.inTaimer.push(i);
                }*/
                if(currentCode && productsByCode.hasOwnProperty(currentCode)) {
                    fileAttributes.codeExists.push(i+2);
                    fileAttributes.totals.taimerTotal++;
                    errorRows.inTaimer.push(i);
                }
                if(!currentCode && diffByName) {
                    fileAttributes.nameExists.push(i+2);
                    fileAttributes.totals.taimerTotal++;
                    errorRows.inTaimer.push(i);
                }
                if(!hasCode && !hasName) {
                    fileAttributes.noProduct.push(i+2);
                    fileAttributes.totals.fileErrorTotal++;
                    errorRows.inFile.push(i);
                }

                fileAttributes.totals.rowTotal++;
                seenCodes[currentCode] = currentName;
                if (!seenCodesRows[currentCode])
                    seenCodesRows[currentCode] = i+2; 
                seenNames[currentName] = currentCode;
                if (!seenNamesRows[currentName])
                    seenNamesRows[currentName] = i+2;
                groupStructure.forEach(group => {
                    if(productGroupsByCategory.by_name.hasOwnProperty(group) && productGroupsByCategory.by_name[group].id == groupParent) {
                        groupParent = productGroupsByCategory.by_name[group].parent_id;
                        existingParentCount++
                    }
                });
                if(existingParentCount == groupStructure.length && !newGroup) {
                    seenGroupsByCode[currentCode] = groupStructure;
                    seenGroupsByName[currentName] = groupStructure;
                }
                if(!seenGroupsByCodeRows[currentCode]) 
                    seenGroupsByCodeRows[currentCode] = i+2;
                if(!seenGroupsByNameRows[currentName])
                    seenGroupsByNameRows[currentName] = i+2;    
                productsToSave.push(productFields);
            });
            setTimeout(() =>{
                this.handleNext();
                this.setState({importData: productsToSave, fileAttributes: fileAttributes, errorRows: errorRows, importStage: "review"})
            }, 1000);
        });
       
    } 

    async save() {
        let { importData, errorHandling, errorRows, importResults, importStep } = this.state;
        this.setState({importStage: "import"})
        const chunks = _.chunk(importData, 1000);
        let i = 0;

        for (const chunk of chunks) {
            const response = await DataHandler.post({ url: `imports/products`}, 
                { details: { 
                    companies_id: this.props.company, 
                    products: chunk, 
                    errorHandle: errorHandling,
                    errorRows: errorRows,
                    chunk: i,
                } 
            });
            importResults.total += response.processed_rows;
            importResults.success += response.success_rows;
            importResults.skip += response.skipped_rows;
            _.forEach(response.errors, (category, i) => {
                _.forEach(category, (error, n) => {
                    if(i !== "totals") {
                        importResults.errors[i][n] = [...importResults.errors[i][n], ...error];
                    } else {
                        importResults.errors[i][n] += error; 
                    }
                })    
            }) 
            i++;   
        }            
        this.setState({importResults: importResults, importStage: "results", importStep: importStep++});
        this.handleNext();
    }

    render() {

        const { fileData, importStage, importObjects, fileAttributes, importStep, importResults } = this.state;
        const { company } = this.props;
        const { tr } = this;
        const importSteps = [tr("Upload File"), tr("Map Attributes"), tr("Review Import"), tr("Results")];

        const errorHeaders = {
            valueNaN: tr("Value is not a number in column"),
            duplicateCodeDiffName: tr("Product with same code and different name found on row"),
            duplicateCode: tr("Product with duplicate code on row"),
            //codeExistsDiffName: tr("Code already exist for a different product in taimer"),
            codeExists: tr("Product with code already exists in taimer"),
            nameExists: tr("Product with name and product group exists in taimer"),
            noProduct: tr("Product has no name or code and will not be imported")
        };
        const totalHeaders = {
            taimerTotal: tr("Existing Products"),
            fileErrorTotal: tr("File Errors"),
            rowTotal: tr("Rows Validated"),
        };
        const resultHeaders = {
            totals: {
                success: tr("Products Imported"),
                total: tr("Rows Processed"),
                skip: tr("Rows Skipped"),
                errors: tr("Errors")
            },
            errors: {
                product: {
                    product: tr("Failed to import product on row"),
                    group: tr("Failed to import product and product group(s) on row"),
                }
            }
        };
        
        const Components = {
            error: 
                <ErrorScreen noData={this.state.noData} />,
            upload: 
                <FileUpload
                    ref={this.upload}
                    startLoadingAnimation={this.startLoadingAnimation} 
                    setFileData={this.setFileData}
                    stageFile={this.stageFile}
                    company={company}
                    uploadHeader={tr("Import products by dragging a file here or by")}
                    suggestedFilesize={tr("Max file size: 5.0MiB")}
                    fileSizeWarningThreshold={(1048576 * 5)}
                />,
            loading:
                <LoadingScreen loadingMessage={tr("Processing data...")} />, 
            config: 
                <ImportTool 
                    importFields={Object.keys(importObjects).length > 0 ? importObjects : fileData.field_names}
                    exampleData={fileData.example_data}
                    taimerFields={this.productFields}
                    setData={this.setImportObjects}
                    dialogRef={this.dialog}
                />,   
            import: 
                <LoadingScreen loadingMessage={tr("Importing...")} />,
            review: 
                <ImportReview 
                    fileAttributes={fileAttributes} 
                    errorHeaders={errorHeaders} 
                    totalHeaders={totalHeaders} 
                    buttonLabels={{
                        skip: tr("Skip errors"),
                        override: tr("Update existing Products"),
                    }}
                    handleConflict={this.handleConflict}
                    conflictOptions={this.state.errorHandling}
                    noContinue={this.state.fileAttributes.valueNaN.length > 0 ? {error: true, msg: tr("Check file or remap columns so that fields requiring numbers, only contain numbers before continuing") + "."} : {error: false}}
                />,
            results: <ResultsScreen results={importResults} headers={resultHeaders} />
        }

        const Component = Components[importStage];
        
        return (
            <DialogBorder
                title={importStage !== "review" ? tr("Import products from file") : tr("Review & import")}
                onClose={this.close}
                className="Product"
                id="ImportDialog"
                saveText={importStage === "results" ? tr("Close") : importStage === "review" ? tr("Import") : tr("Continue")}
                onSave={
                    importStage === "upload" ? this.uploadFile : 
                    importStage === "review" && this.state.fileAttributes.valueNaN.length == 0 ? this.save : 
                    importStage === "config" ? this.reviewImport : 
                    importStage === "results" ? this.close : 
                    undefined
                }
                onBack={(importStage !== "results" && importStage !== "upload" && importStage !== "loading" && importStage !== "import") ? this.handleBack : undefined}
                dialogRef={this.dialog}>
                <DialogSection>
                    <Stepper 
                        activeStep={importStep} 
                        alternativeLabel 
                        className="stepper">
                      {importSteps.map((label, i) => (
                        <Step key={i} className="step">
                          <StepLabel className="step-label">{label}</StepLabel>
                        </Step>
                        
                      ))}
                    </Stepper>
                    {Component}
                </DialogSection>
            </DialogBorder>
        );
    }
}

export default withSnackbar(ImportProductDialog);