import { FormControlLabel, Switch } from '@mui/material';
import { cloneDeep, isEqual, uniqBy } from 'lodash';
import React from 'react';
import DataHandler from '../general/DataHandler';
import DataList from '../general/DataList';
import { convertDateFormat } from '../helpers';
import TaimerComponent from '../TaimerComponent';
import styles from './ExpensePrintPreview.module.scss';
import PDFView from '../general/PDFView';
import TaimerWizard, { TaimerWizardAction } from '../general/TaimerWizard';
import FileSaver from 'file-saver';
import { format } from 'date-fns';
import { TaimerWizardPage } from '../general/TaimerWizard';
import { EmailComposeView, EmailComposeViewState, RecipientOption } from '../projects/SendQuoteWizard';
import { WithSnackbarProps, withSnackbar } from 'notistack';

import { ReactComponent as SentImage } from '../general/icons/completed.svg';
import { ReactComponent as ErrorImage } from '../general/icons/error.svg';

interface QuotePrintPreviewProps extends WithSnackbarProps {
    ids: number[];
    companies_id: number;
    printLanguage?: string;
    module: "expenses" | "travel_expenses";
    printDateFormat?: string;
    printMode?: boolean;
}

interface QuotePrintPreviewState {
    printDateFormat?: string;
    printLanguage?: any;
    printLanguageOptions: any[];
    printDateOptions: any[];
    quote?: any;
    loadingPDF?: any;
    pdfFile?: any;
    email: EmailComposeViewState;
    contacts: any[];
    error: boolean;
}

class ExpensePrintPreview extends TaimerComponent<QuotePrintPreviewProps, QuotePrintPreviewState> {
    quotePDFFile: any = React.createRef();
    constructor(props, context) {
        super(props, context, 'expenses/ExpensePrintPreview');
        this.state = {
            printLanguageOptions: [],
            printDateOptions: [],
            loadingPDF: true,
            email: {
                recipients: [],
                message: '',
            },
            contacts: [],
            error: false,
        };
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.getCompanyData();
        this.getContacts();
    }

    getCompanyData = async () => {
        const {
            companies_id
        } = this.props;
        const companies = await DataHandler.get({
            url: `subjects/companies_with_project_right/read+project_cost_estimate_read`,
            currency: 1,
            date_format: 1,
            print_lang: 1,
            country_lang: 1,
            print_options: 1,
        });
        const currentCompany = companies.find((c) => c.id == companies_id);
        if (!currentCompany) return;
        const defaultPrintOptions: any = this.getDefaultPrintOptions(currentCompany);
        this.setState(
            {
                ...defaultPrintOptions,
            },
            () => {
                this.fetchPdf();
            }
        );
    }

    getContacts = () => {
        const { printMode, companies_id } = this.props;
        if (printMode) return;
        DataHandler.get({ url: `autocomplete_data/contacts/${companies_id}` })
            .done((response) => {

                const customerContacts: any[] = response.contacts || [];
                const allContacts: any[] = response.all_contacts || [];

                const contacts = (customerContacts.length > 0 ? customerContacts : allContacts).filter((c) => !!c.email);
                this.setState({ contacts });
            })
            .fail((error) => console.log(error));
    }

    getDefaultPrintOptions = (company) => {
        return {
            printLanguage: this.props.printLanguage || (company.print_lang ? company.print_lang : company.country_lang),
            printLanguageOptions: company.print_languages || [],
            printDateFormat: this.props.printDateFormat || company.date_format || convertDateFormat(this.context.taimerAccount.companyDateFormat, true),
            printDateOptions: company.print_date_formats || [],
        };
    };

    onPrintSettingChanged = ({ value }, { name }) => {
        if (isEqual(this.state[name], value)) return;
        const update: any = {
            [name]: value,
            loadingPDF: true,
            pdfFile: undefined,
        };
        this.setState(update, () => {
            this.fetchPdf();
        });
    };

    convertPrintDateFormat(format) {
        return format.replace('DD', '%d')
            .replace('MM', '%m')
            .replace('YYYY', '%Y')
    }

    fetchPdf = () => {
        const { ids, module, companies_id } = this.props;
        const { printLanguage, printDateFormat } = this.state;

        const parameters = {
            use_netvisor: this.context.addons.netvisor ? 1 : 0,
            module,
            ids,
            showattachments: 1,
            company_id: companies_id,
            pdf: 1,
            rtlang: printLanguage,
            auth: this.context.functions.getStorage().taimerToken,
            dateFormat: this.convertPrintDateFormat(printDateFormat),
        };

        DataHandler.postArrayBuffer({ url: 'expenses/print_pdf', ...parameters })
            .done((response) => {
                if (!response.error) {
                    this.setState({ pdfFile: response, loadingPDF: false });
                }
                else {
                    this.setState({ loadingPDF: false });
                }
            })
            .fail(err => {
                this.props.enqueueSnackbar(this.tr("Failed to generate PDF-file."), {
                	variant: "error",
                });
            });
    }

    renderPreview = () => {
        const { pdfFile } = this.state;

        return <PDFView file={pdfFile} />
    }

    renderSettings = () => {
        const { module } = this.props;
        const { printDateFormat, printLanguage, printLanguageOptions, printDateOptions, loadingPDF, pdfFile } = this.state;

        const translatedPrintLanguages = printLanguageOptions.map((o) => ({ ...o, label: this.tr(o.label) }));

        return <div className={`${styles.settings} ${loadingPDF ? styles.disabled : ''}`}>
            <h2>{this.tr('PDF details')}</h2>
            <div className={styles.fields}>
                <DataList
                    name="printLanguage"
                    label={this.tr('Print language')}
                    options={translatedPrintLanguages}
                    value={translatedPrintLanguages.find((pl) => pl.value == printLanguage)}
                    onChange={this.onPrintSettingChanged}
                />
                <DataList
                    name="printDateFormat"
                    label={this.tr('Date format')}
                    options={printDateOptions}
                    value={printDateOptions.find((pd) => pd.value == printDateFormat)}
                    onChange={this.onPrintSettingChanged}
                />
            </div>
        </div>
    }

    print = () => {
        const { module } = this.props;
        const { pdfFile } = this.state;

        const blob = new Blob([pdfFile], {
            type: 'application/pdf'
        });

        FileSaver.saveAs(blob, module + '_' + format(new Date(), "YYYY-MM-DD") + '.pdf');
    }

    onEmailFieldChanged = (key: string, value: string | RecipientOption[]) => {
        this.setState({ email: { ...this.state.email, [key]: value } });
    }

    onCreateRecipient = (params) => {
        this.context.functions.addContact(
            {
                ...params,
            },
            { onContactCreated: this.onContactCreated }
        );
    }

    onContactCreated = (contact) => {
        const recipient: RecipientOption = { id: contact.id, label: `${contact.firstname} ${contact.lastname}`, email: contact.email };
        const recipients = cloneDeep(this.state.email?.recipients || []);
        recipients.push(recipient);
        this.setState({ email: { ...this.state.email, recipients } }, () => {
            this.getContacts();
        });
    };

    renderComposer = () => {
        const { module } = this.props;
        const { contacts, email } = this.state;
        const { recipients, message } = email;

        const real = uniqBy(contacts, x => x.label);

        return (
            <EmailComposeView
                recipients={recipients}
                recipientOptions={real}
                onCreateRecipient={this.onCreateRecipient}
                onFieldChanged={this.onEmailFieldChanged}
                message={message}
            />
        );
    }

    renderSent = () => {
        const { error } = this.state;

        return (
            <div className={styles.sent}>
                {error ? <ErrorImage /> : <SentImage />}
                <h2>{error ? this.tr('Sending expense failed!') : this.tr('Expense sent successfully!')}</h2>
                {error && <p>{this.tr('Please try again later.')}</p>}
            </div>
        );
    };

    requiredEmailFieldsMissing = () => {
        const { email, printLanguage, printDateFormat } = this.state;
        const { recipients, message } = email;

        return (this.state.email?.recipients || []).length == 0 || message.trim() === '';
    }

    send = async (): Promise<'close'|'next'> => {
        const { ids, module, companies_id } = this.props;
        const { email, printLanguage, printDateFormat } = this.state;
        const { recipients, message } = email;
        const {
            project_read_companies: companies,
            userObject: { fullname },
        } = this.context;

        const typeName = module === 'travel_expenses' ? 'Travel Expense' : 'Expense';
        const typeNamePlural = module === 'travel_expenses' ? 'Travel Expenses' : 'Expenses';

        const company = (companies || []).find((c) => c.id == companies_id)?.name || this.context.taimerAccount.name;

        const isSingle = ids.length === 1;

        const parameters = {
            use_netvisor: this.context.addons.netvisor ? 1 : 0,
            module,
            ids,
            showattachments: 1,
            company_id: companies_id,
            pdf: 1,
            rtlang: printLanguage,
            auth: this.context.functions.getStorage().taimerToken,
            dateFormat: this.convertPrintDateFormat(printDateFormat),
            recipients: recipients.map(x => x.email),
            message,
            subject: this.beTr(
                isSingle ? typeName + ' #${expenseNumber} from ${company}' : typeNamePlural + ' from ${company}',
                printLanguage || 'en',
                { expenseNumber: ids[0], company },
            ),
            sender: this.beTr('${userName} via Heeros', printLanguage || 'en', { userName: fullname.split(' ').reverse().join(' ') }, 'general/backendTranslations/InvoiceTranslations'),
        };

        try {
            const response = await DataHandler.postArrayBuffer({ url: 'expenses/send_pdf' }, parameters);
            this.setState({error: false});
        } catch (error) {
            console.error(error);
            this.setState({error: true});
        }

        return 'next';
    }

    render() {
        const { module, printMode } = this.props;
        const { loadingPDF, pdfFile } = this.state;

        const pages: TaimerWizardPage[] = !printMode ? [
            {
                title: this.tr('Preview'),
                content: {
                    main: this.renderPreview,
                    right: this.renderSettings,
                },
                actions: [
                    {
                        label: this.tr('Continue'),
                        disabled: () => !pdfFile || loadingPDF,
                        action: 'next',
                    },
                ],
            },
            {
                title: this.tr('Compose email'),
                content: {
                    main: this.renderComposer,
                },
                actions: [
                    {
                        label: this.tr('Send'),
                        disabled: this.requiredEmailFieldsMissing,
                        action: this.send,
                        setLoading: true,
                    },
                ],
            },
            {
                id: 'sent',
                title: this.tr('Sent'),
                content: { main: this.renderSent },
                actions: [
                    {
                        label: this.tr('Done'),
                        action: 'close',
                    },
                ],
                noReturn: true,
            },
        ] : [{
            title: this.tr('Preview'),
            content: {
                main: this.renderPreview,
                right: this.renderSettings,
            },
            actions: [
                {
                    label: this.tr('Print'),
                    disabled: () => !pdfFile || loadingPDF,
                    action: this.print,
                },
            ],
        }];

        const title = (printMode ? "Print" : "Send") + " " + (module === 'expenses' ? 'Expense' : 'Travel Expense');

        return (
            <TaimerWizard title={this.tr(title)} pages={pages} />
        );
    }
}

export default withSnackbar(ExpensePrintPreview);