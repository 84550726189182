import React from 'react';
import TaimerComponent from "../../TaimerComponent";
 
/* mui */
import { Button } from '@mui/material';

/* mui icons */
import { ChevronLeft } from '@mui/icons-material';

/* local components */
import OutlinedField from '../../general/OutlinedField';

/* data backend */
import DataHandler from '../../general/DataHandler';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* styles */
import './Docusign.css';

class Docusign extends TaimerComponent {
    static contextType = SettingsContext;
    
    state = {
        username: "",
        password: "",
    }
    constructor(props, context) {
        super(props, context, "settings/api-settings/Docusign");
    }
    componentDidMount () {
        super.componentDidMount();
        this.updateCompnentData();
    }
    updateCompnentData = () => {
        const { userObject } = this.context;
        DataHandler.get({url: `settings/company/${userObject.companies_id}/docusign`}).done(resp => this.setState(resp));
    }
    onChange = (evt) => {
        const { name, value } = evt.target;
        const { userObject } = this.context;
        
        this.setState({[name]: value}, () => {  
            DataHandler.put({url: `settings/company/${userObject.companies_id}/docusign`}, this.state).done(() => {
                this.setState({activated: this.state.username != '' && this.state.password != '' ? '1' : '0'});
            });
        });
    }
    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''})
    }
    deActivate = () => {
        const { userObject } = this.context;
        DataHandler.delete({url: `settings/company/${userObject.companies_id}/docusign`}).done(this.back);
    }
    
    render(){
        const { username, password, activated } = this.state;        
        const { tr } = this;
        return (
            <div id="integration-docusign" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("DocuSign Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deActivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("Activate DocuSign")}</h4>
                    <p>
                        {tr("If you don't already have a DocuSign account")}:<br/>
                        <a href="https://www.docusign.com/" target="_blank">{tr("Go to")}</a>
                    </p>
                    <OutlinedField name="username" value={username} onChange={this.onChange} label={tr("Username")} />
                    <OutlinedField autoComplete='new-password' type="password" name="password" value={password} onChange={this.onChange} label={tr("Password")} />
                </div>
            </div>
        )
    }
}

export default Docusign;