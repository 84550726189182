import React from 'react';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import TaimerComponent from  '../../TaimerComponent';
import ImportHourEntriesReview from './ImportHourEntriesReview';
import ImportDialog from './ImportDialog';
import ImportTemplate from './import-examples/hour_import_example.xlsx';

/* other */
import { withSnackbar } from 'notistack';

class ImportHourEntriesDialog extends TaimerComponent {

    static contextType = SettingsContext;
    constructor(props, context) {
        super(props, context, "dialogs/imports/ImportHourEntriesDialog")
       
        this.psaFields = [
            { id: 0, name: "" },
            { id: 'user_email', name: this.tr("User email") },
            { id: 'project_number', name: this.tr("Project number") },
            { id: 'company', name: this.tr("Project's company") },
            { id: 'date', name: this.tr("Date") },
            { id: 'start_time', name: this.tr("Start time") },
            { id: 'hours', name: this.tr("Hours") },
            { id: 'jobtype', name: this.tr("Jobtype") },
            { id: 'task', name: this.tr("Task") },
            { id: 'description', name: this.tr("Description") },
        ];
    }
    
    componentDidMount() {
        super.componentDidMount();
    }

    getRowError = (e) => {
        let msg = "Unknown error";

        switch (e.error) {
            case "ROW_SAVING_FAILED":
                msg = this.tr("Error in saving row.");
                break;
            case "INVALID_NUMERIC_VALUE":
                msg = this.tr("Invalid numeric value: ${field}.", { field: this.tr(e.message) });
                break;
            case "REQUIRED_VALUE_MISSING":
                msg = this.tr("Required value missing: ${field}.", { field: this.tr(e.message) });
                break;
            case "INVALID_HOURS":
                msg = this.tr("Hours need to be positive number.");
                break;
            case "COMPANY_NOT_FOUND":
                msg = this.tr("Project company's name is not found from PSA.");
                break;
            case "PROJECT_NOT_FOUND":
                msg = this.tr("No active project found for project number from company.");
                break;
            case "USER_NOT_FOUND":
                msg = this.tr("No active user with this e-mail found from the companies that you have the permission to import hours to.");
                break;
            case "INVALID_JOBTYPE":
                msg = this.tr("Jobtype not found or invalid for project.");
                break;
            case "INVALID_TASK":
                msg = this.tr("Task not found for project or not allocated for user.");
                break;
            case "TASK_REQUIRED":
                msg = this.tr("Task is required for user's company.");
                break;
            case "MULTICOMPANY_PROJECT_HOURS_PREVENTED":
                msg = this.tr("Adding hours to another company's project prevented.");
                break;
            case "MAIN_PROJECT_HOURS_PREVENTED":
                msg = this.tr("Adding hours to main project prevented.");
                break;
            case "HOURS_OVERLAP_TO_NEXT_DAY":
                msg = this.tr("hours can't go past midnight.");
                break;
            case "INVALID_DATE":
                msg = this.tr("Invalid date.");
                break;
            case "INVALID_START_TIME":
                msg = this.tr("Invalid start time.");
                break;
            case "HOURS_OVERLAP_WITH_EXISTING":
                msg = this.tr("Entry overlaps with existing entry.");
                break;
            case "HOURS_OVERLAP_IN_EXCEL":
                msg = this.tr("Entry overlaps with another row in Excel.");
                break;
            case "ONLY_TEAM_MEMBER_HOURS_ALLOWED":
                msg = this.tr("User not in project team: Only team members allowed to log hours to project.");
                break;
            default: //
        }

        return msg;
    }

    renderReviewComponent = (results) => {
        return <ImportHourEntriesReview
            data={results?.rows_data || []}
        />
    }

    render() {
        const { company, enqueueSnackbar, onClose, onSuccess, descriptionRequired } = this.props;
        const { tr } = this;

        const requiredFields = ['user_email', 'project_number', 'date', 'start_time', 'jobtype', 'hours'];
        let requiredFieldNames = `${this.tr('User email')}, ${this.tr('Project number')}, ${this.tr('Date')}, ${this.tr('Start time')}, ${this.tr('Jobtype')}, ${this.tr('Hours')}`;

        if (descriptionRequired) {
            requiredFields.push("description");
            requiredFieldNames += `, ${this.tr('Description')}`
        }

        return <ImportDialog 
            title={tr("Import hour entries from file")}
            company={company}
            getRowError={this.getRowError}
            renderReviewComponent={(results) => this.renderReviewComponent(results)}
            requiredFields={requiredFields}
            requiredFieldNames={requiredFieldNames}
            enqueueSnackbar={enqueueSnackbar}
            psaFields={this.psaFields}
            importUrl={`imports/hour_entries/`}
            reviewUrl={`imports/hour_entries/review/`}
            onClose={onClose}
            onSuccess={onSuccess}
            importDataExtra={{ company }}
            ImportTemplate={ImportTemplate}
        />
    }
}

export default withSnackbar(ImportHourEntriesDialog);
