import React from 'react';
import PropTypes from "prop-types";
import InputAdornment from '@mui/material/InputAdornment';
import { Tooltip } from '@mui/material';

/* local components */
import OutlinedField from "./OutlinedField";

import './LabelField.css';

class LabelField extends React.Component {
	static defaultProps = {
		datePicker: false,
		editorType: OutlinedField,
        onClick: () => {},
        className: ""
	};

    renderIcon = () => {
        const { icon, iconTooltip, iconClassName} = this.props;
        const Icon = icon;

        if (iconTooltip) {
            return (
                <Tooltip title={iconTooltip}>
                    <div className={"icon" + (iconClassName ? " " + iconClassName : "")}>
                        {Icon}
                    </div>
                </Tooltip>
            )
        }
        return (
            <div className={"icon" + (iconClassName ? " " + iconClassName : "")}>
                {Icon}
            </div>
        )
    }


    render () {
        const { label, innerRef, labelFieldClass, infoTooltip, icon, disabledTooltip, ...rest } = this.props;
        const Editor = this.props.editorType;

        let endAdornment = undefined;
        if (infoTooltip) {
            endAdornment = <InputAdornment position="end"><div className="HasTooltip label-field" title={infoTooltip}></div></InputAdornment>
        }
        
        return (
            <div data-testid={`label_field_${this.props.name}`} className={`label-field ${labelFieldClass || ''} ${this.props.className}`} >
                {icon && this.renderIcon()}
                {label && <div className="label">{label + ":"}</div>}
                {this.props.editMode !== false && (disabledTooltip && this.props.disabled ? 
                    <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={disabledTooltip} arrow placement="top">
                        <div>
                        <Editor components={this.props.components} autoComplete = {"new-" + this.props.name} {...rest} ref={innerRef} InputProps={{endAdornment: endAdornment}} />
                        </div>
                    </Tooltip>
                    : <Editor components={this.props.components} autoComplete = {"new-" + this.props.name} {...rest} ref={innerRef} InputProps={{endAdornment: endAdornment}} />
                )}
                {this.props.editMode === false && <Editor components={this.props.components} {...rest} ref={innerRef} readonly />}
            </div>
        );
    }
}

export default LabelField;
