import React from "react";
import ListRow from "./ListRow";
import PropsOnlyListRow from "./PropsOnlyListRow";
import ListCell from "./ListCell";
import ContextMenu from '../general/ContextMenu';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { MenuItem } from '@mui/material';
import TextInputCell from "./cells/TextInputCell";
import BranchIndicator, { createBranchIndicators } from "./../general/BranchIndicator";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import RadioButtonCell from "./cells/RadioButtonCell";

import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';
import { ReactComponent as AddSubCategoryIcon } from '../general/icons/AddSubCategory.svg';

class TreeListRow extends PropsOnlyListRow {
    static defaultProps = {
        rowProps: {
            rowClassName: "",
            rowHeight: 44,
            indentWidth: 50,
            addChildLabel: "Add child",
            deleteLabel: "Delete"
        }
    };


    render() {
        const className        = [`listElement row ${this.props.rowProps.rowClassName}`, this.props.hidden ? "hidden" : "", this.props.data['id'] < 0 ? "new" : ""].join(" ");
        const childRows        = this.createChildren(this.props.children, TreeListRow);
        const branchIndicators = createBranchIndicators({
            height: this.props.rowProps.rowHeight,
            indent: this.props.rowProps.indentWidth
        })(this.props, this.props.recursionLevel, this.props.isLastChild);

        return (
            <div className="listElement" style={{ position: "relative" }}>
                <div className={className} style={{
                    height: !this.props.hidden ? parseInt(this.props.rowProps.rowHeight) + "px" : "0px",
                    lineHeight: parseInt(this.props.rowProps.rowHeight) + "px"
                }}>
                    {<ContextMenu label={<MoreHoriz />} buttonProps={{className: 'action-menu'}} className="cell row-menu" width={this.props.columnWidthMap['contextMenu']} style={{width: this.props.columnWidthMap['contextMenu'] + 'px', flex: this.props.columnWidthMap['contextMenu'] + " 1 0px" }} noExpandIcon>
                        <MenuItem 
                            onClick={() => {
                                this.props.rowProps.addChild(this.props.data)
                            }}><AddSubCategoryIcon />{this.props.rowProps.addChildLabel ? this.props.rowProps.addChildLabel : "Add child"}</MenuItem>
                        <MenuItem className="delete" onClick={this.delete}><RemoveIcon  className="Delete"/>{this.props.rowProps.deleteLabel ? this.props.rowProps.deleteLabel : "Delete"}</MenuItem>
                    </ContextMenu>}
                    {branchIndicators.map(Indicator => Indicator)}
                    {this.props.rowProps.hasOwnProperty("rowDefinition")
                        &&
                        this.props.columnOrder
                        .filter(columnName => columnName !== "contextMenu")
                        .map(columnName => this.props.rowProps.rowDefinition[columnName])}
                    {!this.props.rowProps.hasOwnProperty("rowDefinition")
                        && 
                        <TextInputCell 
                            onEdited={(name, value) => {
                                let data   = cloneDeep(this.props.data);
                                data[name] = value;

                                this.setDataAndUpdate(data);

                                // this.props.data.id < 0 ? this.create(this.setData(name, value)) : this.update(this.setData(name, value))
                            }}
                            name="name"
                            validation={["empty"]}
                            width={this.props.columnWidthMap['name'] - (this.props.recursionLevel * this.props.rowProps.indentWidth)}
                            value={this.props.data['name']}   
                            focusOnMount={true}
                            listCellProps={{ inEditMode: this.props.data.id < 0 }}
                             />
                    }
                    {this.props.columnConfig.radiobutton
                        &&
                        <RadioButtonCell
                            onEdited={(event, checked) => {
                                // TODO: Solve in some other way.
                                // TODO: There shouldn't be a RadioButtonCell here; 
                                // make this into a customizable row
                                // or create a completely new row type.
                                if(this.props.data[this.props.rowKey] < 0) {
                                    return;
                                }

                                this.setDataAndUpdate('radiobutton', checked);
                            }}
                            name={"radiobutton"}
                            width={this.props.columnWidthMap['radiobutton']}
                            checked={this.props.data['radiobutton'] == 1}
                        />
                    }
                </div>
                {childRows !== false && <div className="listElement">{childRows}</div>}
            </div>
        );
    }
}

export default TreeListRow;
