import React from "react";
import { startOfWeek, endOfWeek } from "date-fns";
import { withSnackbar } from "notistack";
import moment from "moment";

import TaimerComponent from "../../../TaimerComponent";
import BlockTopBar from "./BlockTopBar";
import DataHandler from "../../../general/DataHandler";
import NewActivitiesList from "../../../list/lists/NewActivitiesList";
import Utils from "../../../general/Utils";

import "./ActivitiesList.css";

const MODE_KEY = "my_day_activities_mode";
const COLUMNS_KEY = "my_day_activities_list_columns";

class ActivitiesList extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "dashboard/my_day/components/ActivitiesList");

    this._activitiesList = React.createRef();

    this.modes = [
      {
        key: "all",
        label: this.tr("All"),
        action: () => this._setMode("all"),
      },
      {
        key: "this_week",
        label: this.tr("Activity Date This Week"),
        action: () => this._setMode("this_week"),
      },
      {
        key: "today",
        label: this.tr("Activity Date Today"),
        action: () => this._setMode("today"),
      },
      {
        key: "due_this_week",
        label: this.tr("Due This Week"),
        action: () => this._setMode("due_this_week"),
      },
      {
        key: "due_today",
        label: this.tr("Due Today"),
        action: () => this._setMode("due_today"),
      },
      {
        key: "overdue",
        label: this.tr("Overdue"),
        action: () => this._setMode("overdue"),
      },
    ];

    this.columns = [
      {
        field: "type",
        name: "type",
        header: this.tr("Type"),
        width: 70,
        resizeable: false,
        moveable: false,
        hideable: false,
        showMenu: false,
      },
      {
        field: "description",
        name: "description",
        header: this.tr("Description"),
        width: 200,
        resizeable: true,
        hideColumnsFromMenu: true,
        moveable: true,
        hideable: false,
        showMenu: true,
      },
      {
        field: "status",
        name: "status",
        header: this.tr("Status"),
        width: 120,
        resizeable: true,
        hideColumnsFromMenu: true,
        moveable: true,
        hideable: false,
        showMenu: true,
      },
      {
        field: "reminder",
        name: "reminder",
        header: this.tr("Reminder"),
        width: 150,
        resizeable: true,
        hideColumnsFromMenu: true,
        moveable: true,
        hideable: false,
        showMenu: true,
      },
      {
        field: "contact",
        name: "contact",
        header: this.tr("Contact"),
        width: 150,
        resizeable: true,
        hideColumnsFromMenu: true,
        moveable: true,
        hideable: false,
        showMenu: true,
      },
      {
        field: "project_account",
        name: "project_account",
        header: this.tr("Project / Account"),
        width: 200,
        resizeable: true,
        hideColumnsFromMenu: true,
        moveable: true,
        hideable: false,
        showMenu: true,
      },
    ];

    this.newIcons = Utils.generateImgSrcsMap(Utils.refineGlobImportKeys(import.meta.glob([
			'../../../settings/img/Activity_icons/*.png',
			'../../../settings/img/Activity_icons/*.jpg',
			'../../../settings/img/Activity_icons/*.jpeg',
			'../../../settings/img/Activity_icons/*.svg'
		  ], { as: 'raw', eager: true })));
    this.defaultNameField = Utils.getNameFieldByUserLang(this.context.userObject.language, 'name');        

    const mode = this._getModeFromLS(MODE_KEY, "all");
    const filters = this._getFiltersByMode(mode);

    const selectedColumns = [
      ...new Set(this._getColumnsFromLS(this.columns.map((c) => c.field))),
    ];

    this.state = {
      mode,
      selectedColumns,
      data: [],
      loading: true,
      totalCount: 0,
      pageCount: 0,
      dataLoaded: false,
      filterData: {
        targetedto: [this.context.userObject.usersId],
        sortby: "reminder",
        sortasc: true,
        from_myday: 1,
        // reminder: 1,
        ...filters,
      },
    };
  }

  getSortStringFromColumnKey = (columnKey) => {
    switch (columnKey) {
      case "project_account":
        return "customerproject";
      case "project_account":
        return "customerproject";
      default:
        return columnKey;
    }
  };

  shouldComponentUpdate = (newProps, newState) => {
    return newState != this.state;
  };

  _getData = (project, pageData, activityTypes) => {
    this.setState({ loading: true }, () => {
      const filters = { ...this.state.filterData, ...pageData };
      DataHandler.post({ url: `activities/activities` }, filters).done(
        (response) => {
          const data = response.listActivities || [];
          //Icon data to listrows
          data.map((row, i) => {
            const activityType = (activityTypes || []).find((type) => type.id === row.type_id) || {};
            let icon = activityType.icon || "";
            if (!activityType.old_icon_set)
              icon = this.newIcons[icon];
            const newRow = {
              ...row,
              icon,
              key: row.projects_id ? "p" + row.id : "c" + row.id,
            };
            data[i] = newRow;
          });
          const totalCount = response.activitiesCount || 0;
          const pageCount = response.pageCount || 0;
          this.setState({
            data,
            loading: false,
            totalCount,
            pageCount,
            dataLoaded: true,
            updatedValues: {},
          });
        }
      );
    });
  };

  _getModeFromLS = (key, defaultValue) => {
    let mode = defaultValue;
    try {
      mode = localStorage.getItem(key) || defaultValue;
    } catch (e) {}
    return mode;
  };

  _getColumnsFromLS = (defaultValue) => {
    let item = defaultValue;
    try {
      item = localStorage.getItem(COLUMNS_KEY) || defaultValue;
    } catch (e) {}
    if (!Array.isArray(item)) {
      item = item.split(",");
    }
    return item;
  };

  _saveColumns = (selectedColumns) => {
    this.setState({ selectedColumns });
    try {
      localStorage.setItem(COLUMNS_KEY, selectedColumns.join(","));
    } catch (e) {}
  };

  _getDropdownColumns = () => {
    return this.columns.filter((col) => {
      if (!col.moveable) return false;
      if (col.checkRight) {
        return col.checkRight();
      }
      return true;
    });
  };

  _toggleColumn = (column) => {
    const selectedColumns = [...this.state.selectedColumns];
    const index = selectedColumns.indexOf(column.name);
    const newIndex = this.columns.findIndex((c) => c.field == column.field);
    index == -1
      ? selectedColumns.splice(newIndex, 0, column.name)
      : selectedColumns.splice(index, 1);
    this._saveColumns(selectedColumns);
  };

  _getActiveColumns = () => {
    const { selectedColumns } = this.state;
    return selectedColumns.reduce((filtered, col) => {
      const found = this.columns.find((obj) => obj.name == col);
      if (found) {
        filtered.push(found);
      }
      return filtered;
    }, []);
  };

  _getFiltersByMode = (mode) => {
    let startDate;
    let endDate;
    let dueStartDate;
    let dueEndDate;
    let status = [2, 3];
    const today = new Date();
    const options = { weekStartsOn: this.context.calendar.startOfWeek };
    switch (mode) {
      case "today":
        startDate = moment(today).format("YYYY-MM-DD");
        endDate = moment(today).format("YYYY-MM-DD");
        break;
      case "due_today":
        dueStartDate = moment(today).format("YYYY-MM-DD");
        dueEndDate = moment(today).format("YYYY-MM-DD");
        break;
      case "this_week":
        startDate = moment(startOfWeek(today, options)).format("YYYY-MM-DD");
        endDate = moment(endOfWeek(today, options)).format("YYYY-MM-DD");
        break;
      case "due_this_week":
        dueStartDate = moment(startOfWeek(today, options)).format("YYYY-MM-DD");
        dueEndDate = moment(endOfWeek(today, options)).format("YYYY-MM-DD");
        break;
      case "overdue":
        status = [3];
        break;
      default:
        break;
    }
    return { startDate, endDate, dueStartDate, dueEndDate, status };
  };

  _setMode = (mode) => {
    const filters = this._getFiltersByMode(mode);
    const filterData = {
      ...this.state.filterData,
      ...filters,
    };
    this.setState(
      { filterData, mode },
      () =>
        this._activitiesList.current && this._activitiesList.current.onPageSettingsChange({ page: 1 })
    );
    try {
      mode = localStorage.setItem(MODE_KEY, mode);
    } catch (e) {}
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  onSortRows = (sortby, sortasc) => {
    this.setState(
      { filterData: { ...this.state.filterData, sortby: this.getSortStringFromColumnKey(sortby), sortasc } },
      () =>
        this._activitiesList.current && this._activitiesList.current.onPageSettingsChange({ page: 1 })
    );
  };

  render() {
    const {
      mode,
      loading,
      data,
      dataLoaded,
      totalCount,
      pageCount,
    } = this.state;
    
    
    return (
      <div className="my-day-activities-list">
        <BlockTopBar
          title={this.tr("My Activities")}
          dropdownTitle={this.tr("View")}
          dropdownItems={this.modes}
          selected={mode}
          loading={loading}
          allowSearch={false}
          viewButton={{
            title: this.tr("Manage column visibility"),
            items: this._getDropdownColumns(),
            selectedItems: this.state.selectedColumns,
            onItemClick: this._toggleColumn,
          }}
          hidden={data.length>0 ? false : true}
        />
        <NewActivitiesList
          ref={this._activitiesList}
          data={data}
          setPopUp={this.props.setPopUp}
          columns={this._getActiveColumns()}
          onColumnOrderChange={this._saveColumns}
          totalCount={totalCount}
          pageCount={pageCount}
          stopLoading={this.stopLoading}
          dataLoaded={dataLoaded}
          onSortRows={this.onSortRows}
          getData={this._getData}
          icons={this.newIcons}
          nameField={this.defaultNameField}
          parentComponent="MyDay - Activity List"
        />
      </div>
    );
  }
}

export default withSnackbar(ActivitiesList);
