import { format } from 'date-fns';
import moment, { Moment } from 'moment';

export type valid_view = "day" | "week" | "month" | "agenda";

/**
 * 
 * @param view Selected view
 * @param date 
 * @param exactMonth Return exact month start and end (otherwise expeanded to full weeks visible in calendar)
 * @returns start and end dates
 */
export function GetDateRange(view: valid_view, date: Date|string, exactMonth = false) {
	let start: Moment|null = null;
	let end: Moment|null = null;

	if (view === 'day') {
		start = moment(date).startOf('day');
		end = moment(date).endOf('day');
	}
	else if (view === 'week') {
		start = moment(date).startOf('week');
		end = moment(date).endOf('week');
	}
	else if (view === 'month' && !exactMonth) {
		start = moment(date).startOf('month').subtract(7, 'days');
		end = moment(date).endOf('month').add(7, 'days');
	}
	else if (view === 'month' && exactMonth) {
		start = moment(date).startOf('month');
		end = moment(date).endOf('month');
	}
	else if (view === 'agenda') {
		start = moment(date).startOf('day');
		end = moment(date).endOf('day').add(1, 'month');
	}

    if (!start || !end) {
        throw 'Invalid View';
    }

	return {start, end};
}

interface Event {
	start: Date;
	end: Date;
	isWorktripProject: boolean;
	is_task: boolean;

	user: {
		id: number|string;
	}
}

type DayInfo = {type: number; workday: boolean; hours: number|null};

type Workdays = Record<string, DayInfo>;

type FilterEventsResult = {
	dayInfo: DayInfo;
	/**
	 * Actual Worktime
	 */
	realWorktime: number;
	/**
	 * Worktime, including travel time up to expectedWorktime
	 */
	worktime: number;
	/**
	 * Total travel time
	 */
	travelTime: number;
	/**
	 * Total worktime + total traveltime
	 */
	totalTime: number;
	/**
	 * Expected Worktime for this day
	 */
	expectedWorktime: number;
	/**
	 * Difference of worktime and expectedWorktime
	 */
	differenceToExpected: number;
}

export function filterEventsForDay(user: number|false, date: Date, events: Event[], workdays: Workdays): FilterEventsResult {
	const start = moment(date);
	const rangeEvents = events.filter(e => moment(e.start).isSame(start, 'date') && (user === false || e.user.id == user));
	const dateCompare = format(date, "YYYY-MM-DD");

	const dayInfo = workdays[dateCompare] ?? {type: 0, hours: null};

	const travelEvents: Event[] = [];
	const normalEvents: Event[] = [];
	
	let totalTimeMs = 0;
	let travelTimeMs = 0;
	let worktimeMs = 0;

	for (const event of rangeEvents) {
		const len = moment(event.end).diff(event.start);

		totalTimeMs += len;

		if (event.isWorktripProject && !event.is_task) {
			travelEvents.push(event);
			travelTimeMs += len;

		} else if (!event.is_task) {
			normalEvents.push(event);
			worktimeMs += len;
		}	
	}
	
	const expectedWorktime = user !== false && dayInfo?.workday ? dayInfo?.hours ?? 0 : 0;
	const totalTime = Number((totalTimeMs / 3600000).toFixed(2));
	const travelTime = Number((travelTimeMs / 3600000).toFixed(2));
	const realWorktime = Number((worktimeMs / 3600000).toFixed(2));
	let worktime = realWorktime;

	// Add travel time up to expected worktime
	if (travelTime > 0 && worktime < expectedWorktime) {
		worktime = Math.min(worktime + travelTime, expectedWorktime)
	}

	return {
		dayInfo,
		realWorktime,
		worktime,
		travelTime,
		totalTime,
		expectedWorktime,
		differenceToExpected: Number((expectedWorktime - worktime).toFixed(2)),
	}
}
