import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Button, Radio, Tooltip, Switch, DrawerProps, MenuItem, FormControlLabel, CircularProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import DatePicker from './react-date-range/src/components/DatePicker';
import DateRangePicker from './react-date-range/src/components/DateRangePicker';
import TimeRangeOutlinedField from './TimeRangeOutlinedField';
import TimeOutlinedField from './TimeOutlinedField';
import { cloneDeep, isEqual, differenceWith, uniqBy, omit, functions } from 'lodash';
import moment from 'moment';
import OutlinedField, { validators } from './OutlinedField';
import DataList from './DataList';
import TaimerComponent from '../TaimerComponent';
import CoreDialog from '../dialogs/mass_operations/CoreDialog';
import { validEmailMultiple } from '../dialogs/Validate';

import styles from './FieldEditSlider.module.scss';
import TreeSelect from './TreeSelect';
import LoaderButton from './LoaderButton';
import Slider, { AnimatedSlider } from './Slider';
import ContextMenu from './ContextMenu';
import TextFieldWithLimit from './TextFieldWithLimit';
import Utils from './Utils';
import { InfoOutlined, Star, Info } from '@mui/icons-material';

const userFieldtypes = ['assigned_to_id', 'employees', 'account_manager', 'account_manager2'];

export interface EditableField {
    title?: string;
    /* a *unique* key for the field – by default is used to set/get values from the object edited. */
    key: string;
    /* An override to use instead of the default key when setting/getting values for the object. Use this if for some reason you need to edit two fields with the same key. */
    overrideValueKey?: string;
    startKey?: string;
    endKey?: string;
    dateKey?: string;
    validation?: string;
    type?: string;
    disabled?: boolean | ((item) => boolean);
    options?: any[];
    getOptions?: (item: any, options: any[]) => any[];
    optionsMap?: any;
    autoFocus?: boolean | ((item) => boolean);
    setOtherValuesWithSelection?: (item: any, selectedValue: any) => object;
    setOtherValuesWithSelectionPromise?: (item: any, selectedValue: any) => Promise<any>;
    addNoneOption?: boolean;
    adornment?: ReactNode;
    adornmentPos?: string;
    isHidden?: (item: any) => boolean;
    removeValueIfHidden?: boolean;
    defaultValue?: string;
    getDefaultValue?: (item: any) => string;
    rows?: number;
    maxRows?: number;
    class?: string;
    nested?: EditableField[];
    useStringValueIfNan?: boolean;
    loadOptions?: any;
    noOptionsMessage?: (value: any) => string | null;
    cacheUniq?: any;
    debounceTimeout?: number;
    required?: boolean;
    error?: boolean;
    errorMessage?: string | ((item) => ReactNode);
    warningMessage?: string | (() => ReactNode);
    afterEdit?: (item: any) => void;
    isMulti?: boolean;
    noOptions?: any;
    additionalProps?: any;
    onItemCreated?: (item: any, selectValueCallback: () => void) => void;
    customComponent?: any;
    customComponentMediator?: (fes: FieldEditSlider) => any;
    checkValidity?: (item) => boolean;
    filterBy?: string | ((item: any) => string);
    getFilterByValue?: (item: any) => string;
    filterByKey?: string;
    getFilterByKeyValue?: (item: any) => string;
    divideToSubFieldsKey?: string;
    suboptionIndex?: number;
    subFieldProps?: any;
    selectFirstAutomatically?: boolean;
    hideIfEmpty?: boolean;
    rightComponent?: any;
    onChange?: (value: any) => void;
    allowValueOutsideOptions?: boolean;
    triggerSelection?: string[];
    placeholder?: string;
    getPlaceholder?: (item: any) => string;
    formatValue?: (value: string, item: any) => string;
    tooltip?: string | undefined | ((item) => string | undefined);
    fieldTooltip?: string | undefined | ((item) => string | undefined);
    handleValueAfterEdit?: (value: any) => any;
     /**
     * Is midnight end time allowed (for timerange)
     */
    useMidnightEnd?: boolean;
    timeRangeOutputFormat?: string;
    onClick?: (value: any) => void;
    label?: string;
}

export interface FieldEditSliderProps extends DrawerProps {
    open: boolean;
    onClose: (event?: any, reason?: string) => void;
    title: string;
    fields: EditableField[];
    item: any;
    canDeleteItem?: (item: any) => boolean;
    checkValidityOfAllFieldsOnChange?: boolean;
    onDeleteItem?: (item: any) => void;
    onSave?: (item: any) => void;
    additionalFields?: ReactNode | ((item: any, onItemChanged: (values: any) => void) => ReactNode);
    onItemChanged?: (item: any) => void;
    saving?: boolean;
    additionalValidations?: (item: any) => any;
    alwaysShowDelete?: boolean;
    deleteDisabledTooltip?: string;
    options?: any[];
    leftComponent?: any;
    showLeftComponent?: boolean;
    onToggleLeftComponent?: () => void;
    width?: number;
    rightComponent?: any;
    leftComponentButtonTooltip?: {
        open: string;
        closed: string;
    };
    optionsAsAdd?: boolean;
    saveDisabled?: boolean;
    saveHidden?: boolean;
    showLockedUsersWithTag?: boolean;
    saveOnEnter?: boolean;
    useAnimatedSlider?: boolean;
    /* Shows loading indicator in slider instead of content */
    loadingData?: boolean;
    /* Text to show under loading indicator if loadingData=true */
    loadingText?: string;
}

interface State {
    item: any;
    fieldErrors: any;
    confirmDialogData?: any;
    fields: EditableField[];
    menuPlacements: any;
    saveTimeoutActive?: boolean;
}

class FieldEditSlider extends TaimerComponent<FieldEditSliderProps, State> {
    originalItem: any;
    selectRefs: any = {};
    menuPlacementTimeout: any;
    constructor(props, context) {
        super(props, context, 'general/FieldEditSlider');
        this.originalItem = cloneDeep(this.props.item);
        this.state = {
            item: cloneDeep(this.props.item),
            fields: this.formatFields(this.props.fields),
            fieldErrors: {},
            menuPlacements: {},
        };
    }

    getDescendantProp = (obj, key) => {
        const arr = key.split('.');
        while (arr.length && (obj = obj[arr.shift()]));
        return obj;
    };

    addChildrenRecursively = (option, parentMap) => {
        const finalOption = { ...option };
        const parentSuboptions = parentMap[option.id];
        if (parentSuboptions) {
            parentSuboptions.forEach((suboption) => {
                const formattedSuboption = this.addChildrenRecursively(suboption, parentMap);
                const suboptions = finalOption.suboptions || [];
                suboptions.push(formattedSuboption);
                finalOption.suboptions = suboptions;
            });
        }
        return finalOption;
    };

    formatFields = (baseFields) => {
        const fields: any = cloneDeep(baseFields || []);
        fields.forEach((field) => {
            let allOptions = (field.getOptions ? field.getOptions(this.state?.item || this.props.item, field.options) : field.options) || [];
            const optionMap = {};
            const parentMap = {};
            if (field.divideToSubFieldsKey) {
                for (let i = 0; i < allOptions.length; i++) {
                    const val = allOptions[i];
                    const parentKey = this.getDescendantProp(val, field.divideToSubFieldsKey);
                    if (parentKey && Number(parentKey) > 0) {
                        const arr = parentMap[parentKey] || [];
                        arr.push(val);
                        parentMap[parentKey] = arr;
                    } else {
                        optionMap[val.id] = val;
                    }
                }
                Object.values(optionMap).forEach((option: any) => {
                    // check for children
                    const optionWithChildren = this.addChildrenRecursively(option, parentMap);
                    optionMap[option.id] = optionWithChildren;
                });
                allOptions = Object.values(optionMap);
            }
            if (field.filterByKey) {
                field.optionsMap = this.makeMapFromArray(allOptions, field.filterByKey);
            }
            if (userFieldtypes.includes(field.key))
                field.options = field.options?.map((o) => ({
                    ...o,
                    name: `${o.name} ${o.companies_id < 1 ? ` (${this.tr('freelancer')})` : ''}${o.locked > 0 && this.props.showLockedUsersWithTag ? ` (${this.tr('locked')})` : ''}`,
                    label: `${o.label} ${o.companies_id < 1 ? ` (${this.tr('freelancer')})` : ''}${o.locked > 0 && this.props.showLockedUsersWithTag ? ` (${this.tr('locked')})` : ''}`,
                }));
        });
        return fields;
    };

    makeMapFromArray = (arr, key) => {
        const map = {};
        for (const item of arr) {
            const finalKey = this.getDescendantProp(item, key);
            const subArr = [...(map[finalKey] || [])];
            subArr.push(item);
            map[finalKey] = subArr;
        }
        return map;
    };

    setInitialFieldValues = () => {
        let newItem = {
            ...this.state.item,
        };

        //setting initial selections for fields that want first row selected automatically
        const { selections, deleteValues } = this.getCorrectSelections(
            this.state.fields.filter((f) => f.selectFirstAutomatically && (f.options || []).length > 0),
            newItem
        );
        deleteValues.forEach((dv) => delete newItem[dv]);
        newItem = {
            ...newItem,
            ...selections,
        };
        if (!isEqual(newItem, this.state.item)) {
            this.props.onItemChanged && this.props.onItemChanged(newItem);
            this.setState({
                item: newItem,
            });
        }
    };

    handleFieldsChange = (changedFieldsWithoutFunctions, callback?) => {
        const sort = {};
        (this.props.fields || []).forEach((f, i) => (sort[f.key] = i));
        const newFieldKeys = (this.props.fields || []).map((f) => f.key);
        const currentFields = (this.state.fields || []).filter((f) => newFieldKeys.indexOf(f.key) != -1);
        const changedFields: any = changedFieldsWithoutFunctions.map((f) => this.props.fields.find((ff) => ff.key == f.key) || {});
        const fields = uniqBy([...this.formatFields(changedFields), ...currentFields], 'key').sort((a, b) => sort[a.key] - sort[b.key]);
        this.setState(
            {
                fields,
            },
            () => {
                callback && callback();
                this.setMenuPlacements();
            }
        );
    };

    handleItemChange = () => {
        this.originalItem = cloneDeep(this.props.item);
        let item = this.props.onItemChanged
            ? cloneDeep(this.props.item)
            : {
                  ...this.state.item,
                  ...cloneDeep(this.props.item),
              };
        item = this.setCorrectSelections(item);
        this.setState(
            {
                item,
            },
            () => {
                if (this.props.onItemChanged && !isEqual(this.state.item, this.props.item)) {
                    this.props.onItemChanged(cloneDeep(this.state.item));
                }
            }
        );
    };

    componentDidUpdate = (oldProps, oldState) => {
        const itemChanged = !isEqual(oldProps.item, this.props.item);
        // isEqual doesn't work when comparing functions – it always returns false. For this reason, function properties are filtered out from this comparison.
        // Be careful with the field objects as changing functions do not properly change inside the slider.
        const oldFieldsWithoutFunctions = (oldProps.fields || []).map((f) => omit(f, functions(f)));
        const fieldsWithoutFunctions = (this.props.fields || []).map((f) => omit(f, functions(f)));

        // I'm not completely sure how this comparison should work, 
        // and I don't intend to find out either. All I know is it works 
        // sometimes, sometimes not. It fails in some obvious cases though,
        // like when the length of the 'fields' prop in the old and new props
        // is inequal. I've added a check for that on top of the old solution
        // in order to try to not break the existing solution.
        const changedFieldsWithoutFunctions = differenceWith(fieldsWithoutFunctions, oldFieldsWithoutFunctions, isEqual);
        const fieldsChanged = changedFieldsWithoutFunctions.length > 0 
            || oldFieldsWithoutFunctions.length !== fieldsWithoutFunctions.length;

        if (itemChanged && fieldsChanged) {
            this.handleFieldsChange(changedFieldsWithoutFunctions, this.handleItemChange);
        } else if (fieldsChanged) {
            this.handleFieldsChange(changedFieldsWithoutFunctions, this.setInitialFieldValues);
        } else if (itemChanged) {
            this.handleItemChange();
        }
        if (!oldProps.open && this.props.open) {
            window.addEventListener('resize', this.onWindowResize);
            this.setState(
                {
                    fieldErrors: [],
                    item: cloneDeep(this.props.item),
                },
                () => {
                    this.setMenuPlacements();
                }
            );
        } else if (oldProps.open && !this.props.open) {
            window.removeEventListener('resize', this.onWindowResize);
        }
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.onWindowResize);
    };

    isFieldInvalid = (field, item) => {
        let validFormat = true;
        const fieldValueKey = this.getFieldValueKey(field);
        // phone validation disabled for now, doesn't work with all numbers apparently
        if (field.validation && field.validation != 'phone') {
            const validate = validators[field.validation];
            validFormat = field.validation == 'emailMultiple' ? validEmailMultiple(item[fieldValueKey] || '', true) : validate(item[fieldValueKey] || '');
        }
        return (
            !validFormat ||
            (field.required &&
                (!field.isHidden || !field.isHidden(item)) &&
                (field.checkValidity ? !field.checkValidity(item) : !item[fieldValueKey] || (Array.isArray(item[fieldValueKey]) && item[fieldValueKey].length == 0)))
        );
    };

    checkFieldValidity = (field) => {
        if (!field) return;
        const { item } = this.state;
        let fieldErrors = cloneDeep(this.state.fieldErrors);
        fieldErrors = {
            ...fieldErrors,
            [field.key]: this.isFieldInvalid(field, item),
        };
        this.setState({ fieldErrors });
        return Object.values(fieldErrors).indexOf(true) == -1;
    };

    checkValidity = (item = this.state.item) => {
        const fields = this.state.fields;
        let fieldErrors = {};
        let manuallyErroredFields = false;
        fields.forEach((field) => {
            if (field.error) manuallyErroredFields = true;
            if (this.isFieldInvalid(field, item)) {
                fieldErrors = {
                    ...fieldErrors,
                    [field.key]: true,
                };
            }
        });
        this.setState({ fieldErrors });
        const additionalValidationsValid = !this.props.additionalValidations || (this.props.additionalValidations && this.props.additionalValidations(item));
        return !manuallyErroredFields && additionalValidationsValid && Object.values(fieldErrors).indexOf(true) == -1;
    };

    onAdditionalFieldChanged = (values) => {
        const item = cloneDeep(this.state.item);
        const newItem = {
            ...item,
            ...values,
        };
        this.setState({
            item: newItem,
        });
    };

    formatNumericValue = (value, field) => {
        if (!value) return value;
        let formatted = String(value).replace(',', '.');
        if (field?.additionalProps?.maximumFractionDigits != undefined && Number(formatted) != 0) {
            formatted = Utils.truncateDecimals(formatted, field?.additionalProps?.maximumFractionDigits, false);
        }
        return formatted;
    };

    hasNumericValidation = (f: EditableField) => {
        const str: string | undefined = f?.validation;

        return str === "numeric"
            || str === "numericNonNegative";
    };

    setCorrectSelections = (item = this.state.item) => {
        let newItem = cloneDeep(item);
        const { selections, deleteValues } = this.getCorrectSelections(this.state.fields, newItem);
        deleteValues.forEach((dv) => delete newItem[dv]);
        newItem = {
            ...newItem,
            ...selections,
        };
        return newItem;
    };

    isSelectType = (field) => {
        return field?.type == 'select' || field?.type == 'data_select' || field?.type == 'treeselect';
    };

    setFieldValue = (field: string, value) => {
        this.onFieldEdited(
            {
                target: {
                    value: value,
                    name: field
                }
            }
        );
    };

    handleValueAfterEdit = (fieldName: string, value: any): any => {
        const field: EditableField | undefined = this.state.fields
            .find((f: EditableField) => f.key === fieldName);

        if(!field || !field?.handleValueAfterEdit) {
            return value;
        }

        return field.handleValueAfterEdit(value);
    }

    onFieldEdited = (e, additionalValues = {}) => {
        if (!this.state.item) return;
        let value = e.target.value ?? e.target.checked; //This is used for checkbox

        value = this.handleValueAfterEdit(e.field ?? e.target.name, value);
        
        const item = cloneDeep(this.state.item);
        let otherValues = {};
        const field = e.field || this.state.fields.find((f) => f.key == e.target.name);
        if(this.hasNumericValidation(field)) {
            value = this.formatNumericValue(value, field);
        }
        if (field?.setOtherValuesWithSelection) {
            otherValues = {
                [this.getFieldValueKey(field)]: value,
                ...field.setOtherValuesWithSelection(item, value),
            };
        }
        let newItem = {
            ...item,
            [e.target.name]: value,
            ...otherValues,
            ...additionalValues,
        };
        if (this.isSelectType(field)) {
            const { selections, deleteValues } = this.getCorrectSelections(this.state.fields, newItem, field);
            deleteValues.forEach((dv) => delete newItem[dv]);
            newItem = {
                ...newItem,
                ...selections,
            };
        }
        this.props.onItemChanged && this.props.onItemChanged(newItem);
        this.setState(
            {
                item: newItem,
            },
            () => {
                if (field?.setOtherValuesWithSelection
                   || this.props.checkValidityOfAllFieldsOnChange) {
                    this.checkValidity(this.state.item);
                } else {
                    this.checkFieldValidity(field);
                }

                field?.afterEdit && field?.afterEdit(newItem);
            }
        );
    };

    onFieldBatchEdited = (values, field) => {
        if (!this.state.item) return;
        let otherValues = {};
        const item = cloneDeep(this.state.item);
        if (field?.setOtherValuesWithSelection) {
            otherValues = {
                ...field.setOtherValuesWithSelection(item, values),
            };
        }
        let newItem = {
            ...item,
            ...values,
            ...otherValues,
        };
        if (this.isSelectType(field)) {
            const { selections, deleteValues } = this.getCorrectSelections(this.state.fields, newItem, field);
            deleteValues.forEach((dv) => delete newItem[dv]);
            newItem = {
                ...newItem,
                ...selections,
            };
        }
        this.props.onItemChanged && this.props.onItemChanged(newItem);
        this.setState(
            {
                item: newItem,
            },
            () => {
                if (field?.setOtherValuesWithSelection) {
                    this.checkValidity(this.state.item);
                } else {
                    this.checkFieldValidity(field);
                }
                field.afterEdit && field.afterEdit(newItem);
            }
        );
    };

    getFieldValueKey = (field) => {
        return field?.overrideValueKey || field?.key;
    };

    onSelectChanged = async (field: EditableField, value) => {
        const item = cloneDeep(this.state.item);
        if (field.divideToSubFieldsKey) {
            const keys = Object.keys(this.state.item).filter((f) => f != field.key && f.startsWith(`${field.key}_sub_`));
            keys.forEach((key) => {
                delete item[key];
            });
        }
        if (value == undefined) return;
        this.setState({ item }, async () => {
            if (field.setOtherValuesWithSelectionPromise) {
                const newValues = await field.setOtherValuesWithSelectionPromise(this.state.item, value);
                const values = {
                    [this.getFieldValueKey(field)]: value,
                    ...newValues,
                };
                this.onFieldBatchEdited(values, field);
            } else {
                this.onFieldEdited({ field, target: { name: this.getFieldValueKey(field), value } });
            }
        });
    };

    onRadioChanged = (e) => {
        this.onFieldEdited({ target: { name: e.target.name, value: e.target.checked ? 1 : 0 } });
    };

    onDateRangeChanged = (field: EditableField, event) => {
        const startDate = moment(event.selection.startDate).format('YYYY-MM-DD');
        const endDate = moment(event.selection.endDate).format('YYYY-MM-DD');
        const values = {
            [field.startKey || 'startDate']: startDate,
            [field.endKey || 'endDate']: endDate,
        };
        this.onFieldBatchEdited(values, field);
    };

    onDateRangeInputChanged = (field: EditableField, type, date) => {
        this.onFieldEdited({ target: { name: type == 'start' ? field.startKey || 'startDate' : field.endKey || 'endDate', value: moment(date).format('YYYY-MM-DD') } });
    };

    onDateChanged = (key, date) => {
        this.onFieldEdited({ target: { name: key, value: moment(date).format('YYYY-MM-DD') } });
    };

    onDateInputChanged = (key, date) => {
        this.onFieldEdited({ target: { name: key, value: moment(date).format('YYYY-MM-DD') } });
    };

    getTextualFieldDefaultValue = (key): string | undefined => {
        const field: EditableField | undefined = this.props.fields
            .find((f: EditableField) => f.key === key);

        return field?.defaultValue;
    };

    onTimeRangeChanged = (field: EditableField, value, start, end, isFullDay = false) => {
        let startTime = moment(start).format(field.timeRangeOutputFormat ?? 'HH:mm');
        let endTime = moment(end).format(field.timeRangeOutputFormat ?? 'HH:mm');
        let fullDay = false;

        if (endTime === '00:00' || endTime === '00:00:00') {
            endTime = field.useMidnightEnd ? '24:00:00' : '23:59:59';
        }

        if (isFullDay) {
            fullDay = true;
            startTime = '00:00:00';
            endTime = '00:00:00';
        }

        const values = {
            [field.startKey || 'startTime']: startTime,
            [field.endKey || 'endTime']: endTime,
            [this.getFieldValueKey(field) || 'timeRange']: value,
            fullDay,
        };
        this.onFieldBatchEdited(values, field);
    };

    onTimeChanged = (key, value) => {
        this.onFieldEdited({ target: { name: key, value: value } });
    };

    getOptions = (field, item) => {
        const filterKey = typeof field.filterBy == 'function' ? field.filterBy(item) : field.filterBy;
        const finalFilterKey = filterKey ? this.getDescendantProp(item, filterKey) : undefined;
        let options = finalFilterKey != undefined ? (field.optionsMap || {})[finalFilterKey] || [] : field.getOptions ? field.getOptions(item, field.options) : field.options;
        options = field.addNoneOption ? [{ id: 0, value: 0, label: this.tr('Not selected'), name: this.tr('Not selected') }, ...options] : options;
        
        return options;
    };

    handleAutoFocus = (field: EditableField): boolean => {
        return typeof(field.autoFocus) === "function"
            ? field.autoFocus(this.state.item)
            : field?.autoFocus ?? false;
    };

    recursivelyFindById = (arr, id) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id == id) {
                return arr[i];
            } else if (arr[i].children && arr[i].children.length > 0) {
                const child = this.recursivelyFindById(arr[i].children, id);
                if (child) {
                    return child;
                }
            }
        }
    };

    getSelectedValue = (field, options, item) => {
        const itemValue = item?.[this.getFieldValueKey(field)];
        if (field.isMulti) {
            const multiValueArr = Array.isArray(itemValue) ? itemValue : itemValue?.split(',');
            if (field.formatMultiValue) {
                const fullValues: any = [];
                (multiValueArr || []).forEach((multiValue) => {
                    const val = field.formatMultiValue(multiValue, options);
                    if (val) {
                        fullValues.push(val);
                    }
                });
                return fullValues;
            }
            return itemValue;
        }
        let value;
        if (field?.type == 'treeselect') {
            value = this.recursivelyFindById(options || [], typeof itemValue == 'object' ? itemValue.id : itemValue);
        } else {
            value = typeof itemValue == 'object' && field.allowValueOutsideOptions ? itemValue : options?.find((o) => o?.id == (typeof itemValue == 'object' ? itemValue?.id : itemValue));
            if (!value && field?.allOptions) {
                value = field?.allOptions?.find((o) => o?.id == (typeof itemValue == 'object' ? itemValue?.id : itemValue));
            }
        }
        if (field.useStringValueIfNan && isNaN(itemValue)) {
            value = !itemValue ? undefined : { id: 0, label: itemValue };
        }
        if (value == undefined && typeof field.getDefaultValue === "function") {
           value = options?.find((o) => o.id == field.getDefaultValue(item)) ;
        }
        if (value == undefined && field.defaultValue) value = options?.find((o) => o.id == field.defaultValue);
        return value;
    };

    getCorrectSelections = (fields, item, triggerField?: EditableField) => {
        let selections = {};
        const originalItem = cloneDeep(item);
        const deleteValues: string[] = [];
        fields.forEach((field) => {
            if (!field.isMulti && field.options && this.isSelectType(field)) {
                const correctSelection = this.getCorrectSelection(field, { ...originalItem, ...selections }, triggerField);
                selections = {
                    ...selections,
                    [this.getFieldValueKey(field)]: field.type == 'data_select' || field.type == 'treeselect' ? correctSelection?.id || correctSelection : correctSelection,
                };
                if (field.divideToSubFieldsKey) {
                    let subfieldIndex = 1;
                    if (correctSelection?.suboptions && correctSelection?.suboptions.length > 0) {
                        let previousField = correctSelection;
                        while (previousField?.suboptions && previousField?.suboptions.length > 0) {
                            const subField = {
                                ...field.subFieldProps,
                                subFieldProps: field.subFieldProps,
                                divideToSubFieldsKey: field.divideToSubFieldsKey,
                                key: `${field.key.split('_sub_')[0]}_sub_${subfieldIndex}`,
                                options: previousField?.suboptions,
                                suboptionIndex: subfieldIndex,
                            };
                            const correctSubSelection = this.getCorrectSelection(subField, { ...originalItem, ...selections });
                            selections = {
                                ...selections,
                                [this.getFieldValueKey(subField)]: field.type == 'data_select' || field.type == 'treeselect' ? correctSelection?.id || correctSelection : correctSubSelection,
                            };
                            previousField = correctSubSelection;
                            subfieldIndex++;
                        }
                    }
                    //removing sub values that are left over
                    while (originalItem[`${field.key.split('_sub_')[0]}_sub_${subfieldIndex}`]) {
                        const additionalSubItemKey = `${field.key.split('_sub_')[0]}_sub_${subfieldIndex}`;
                        deleteValues.push(additionalSubItemKey);
                        delete originalItem[additionalSubItemKey];
                        subfieldIndex++;
                    }
                }
            }
        });
        return { selections, deleteValues };
    };

    getCorrectSelection = (field, item, triggerField?: EditableField) => {
        const options = this.getOptions(field, item);
        let value = ((field.getOptions ? field.getOptions(item, field.options) : field.options) || []).length == 0 ? item?.[this.getFieldValueKey(field)] : this.getSelectedValue(field, options, item);
        const emptyLength = field?.addNoneOption ? 1 : 0;
        // if selectFirstAutomatically is specified, selecting the first value of current options when no value is present
        if ((value == undefined || (triggerField?.triggerSelection && triggerField?.triggerSelection.indexOf(field.key) != -1)) && field.selectFirstAutomatically && options.length > emptyLength) {
            value = field.addNoneOption ? options[1] : options[0];
        }
        // setting an empty selection when no options are found but a value is selected
        else if (
            value == undefined &&
            field.selectFirstAutomatically &&
            options.length == emptyLength &&
            ((field.getOptions ? field.getOptions(item, field.options) : field.options) || []).length &&
            !!item?.[this.getFieldValueKey(field)]
        ) {
            value = undefined;
        }
        // emptying a selection that is not found in current options
        if (value == undefined && options.length > emptyLength && !!item?.[this.getFieldValueKey(field)]) {
            value = undefined;
        }
        if (field.removeValueIfHidden && ((field.getOptions ? field.getOptions(item, field.options) : field.options) || []).length && field.isHidden && field.isHidden(item)) {
            value = undefined;
        }
        return value;
    };

    tagCreated = (field, tag) => {
        const fields = cloneDeep(this.state.fields);
        let item = cloneDeep(this.state.item);
        const tagFieldIndex = fields.findIndex((f) => f.key == field.key);
        if (tagFieldIndex != -1 && Array.isArray(fields[tagFieldIndex].options || [])) {
            const options: any = fields[tagFieldIndex].options || [];
            options.unshift(tag);
            fields[tagFieldIndex] = {
                ...fields[tagFieldIndex],
                options,
            };
        }
        const valueArr = item[this.getFieldValueKey(field)] || [];
        valueArr.push(field.formatMultiValue ? tag?.label : tag);
        item = {
            ...item,
            [this.getFieldValueKey(field)]: valueArr,
        };
        this.props.onItemChanged && this.props.onItemChanged(item);
        this.setState({ fields, item }, () => {
            field.additionalProps?.tagCreated && field.additionalProps?.tagCreated(tag);
        });
    };

    typeCreated = (field, type) => {
        const fields = cloneDeep(this.state.fields);
        let item = cloneDeep(this.state.item);
        const typeFieldIndex = fields.findIndex((f) => f.key == field.key);
        if (typeFieldIndex != -1 && Array.isArray(fields[typeFieldIndex].options || [])) {
            const options: any = fields[typeFieldIndex].options || [];
            options.unshift(type);
            fields[typeFieldIndex] = {
                ...fields[typeFieldIndex],
                options,
            };
        }
        const valueArr = item[this.getFieldValueKey(field)] || [];
        valueArr.push(field.formatMultiValue ? type?.label : type);
        item = {
            ...item,
            [this.getFieldValueKey(field)]: valueArr,
        };
        this.props.onItemChanged && this.props.onItemChanged(item);
        this.setState({ fields, item }, () => {
            field.additionalProps?.typeCreated && field.additionalProps?.typeCreated(type);
        });
    };

    getCustomComponentProps = (field: EditableField) => {
        const {
            customComponentMediator
        }: {
            customComponentMediator?: ((fes: FieldEditSlider) => any) | undefined
        } = field;

        return customComponentMediator !== undefined
            ? customComponentMediator(this)
            : {};
    }

    renderField = (field: EditableField, index) => {
        const { item, fieldErrors, menuPlacements } = this.state;

        if (item && field.isHidden && field.isHidden(item)) {
            return null;
        }
        if (field.nested?.length) {
            return (
                <div key={field.key} className={styles.nestedRow}>
                    {(field.nested || []).map((field, i) => {
                        return this.renderField(field, i);
                    })}
                </div>
            );
        }
        if (field.customComponent) {
            return (
                <div key={field.key} className={styles.field}>
                    {React.cloneElement(
                        field.customComponent(item), 
                        { 
                            error: field.error || !!fieldErrors[field.key],
                            ...this.getCustomComponentProps(field)
                        }
                    )}
                </div>
            );
        }
        let fieldElement: any;
        const fieldValueKey = this.getFieldValueKey(field);
        const fieldDisabled: boolean | undefined = typeof(field.disabled) === "function"
            ? field.disabled(this.state.item)
            : field.disabled;

        switch (field.type) {
            case 'header':
                fieldElement = <h3>{field.title}</h3>;
                break;
            case 'info':
                fieldElement = <div className={styles.infoRow}><Info className={styles.infoIcon} /> <span className={styles.infoText}>{field.title}</span></div>;
                break;
            case 'subLabelButton':
                fieldElement = (
                    <div className={styles.subLabelButtonRow}>
                        <span className={styles.buttonLabel}>{field.label}</span>
                        <span className={styles.buttonText} onClick={() => field.onClick && field.onClick(field.key)}>{field.title}</span>
                    </div>
                );
                break;
            case 'radio':
                fieldElement = (
                    <div data-testid={field.key} key={field.key} className={styles.radioRow}>
                        <FormControlLabel
                            control={<Radio color="primary" name={fieldValueKey} onChange={this.onRadioChanged} checked={item && Number(item[fieldValueKey]) > 0} />}
                            label={field.title || ''}
                        />
                    </div>
                );
                break;
            case 'switch':
                fieldElement = (
                    <div data-testid={field.key} className={styles.switchRow}>
                        <div className={styles.switch}>
                            <FormControlLabel
                                control={<Switch color="primary" name={fieldValueKey} onChange={this.onRadioChanged} checked={item && Number(item[fieldValueKey]) > 0} />}
                                label={field.title || ''}
                            />
                        </div>
                    </div>
                );
                break;
            case 'dateswitch':
                fieldElement = (
                    <div className={styles.switchRow}>
                        <div className={styles.switch}>
                            <FormControlLabel
                                control={<Switch color="primary" name={fieldValueKey} onChange={this.onRadioChanged} checked={item && Number(item[fieldValueKey]) > 0} />}
                                label={field.title || ''}
                            />
                        </div>
                        {item && Number(item[fieldValueKey]) > 0 && (
                            <div className={styles.dateSwitchValue}>
                                <p>{this.tr('from')}</p>
                                <DatePicker
                                    key={field.key}
                                    className="basic-info yet-another-date-design"
                                    disabled={fieldDisabled}
                                    usePopper
                                    usePlaceholder
                                    date={item && !!item[field.dateKey || 'date'] && item[field.dateKey || 'date'] != '0000-00-00' ? item[field.dateKey || 'date'] : undefined}
                                    onChange={(date) => this.onDateChanged(field.dateKey || 'date', date)}
                                    onInputChange={(_, date) => this.onDateInputChanged(field.dateKey || 'date', date)}
                                    dateFormat={this.context.userObject.dateFormat}
                                />
                            </div>
                        )}
                    </div>
                );
                break;
            case 'data_select':
            case 'select': {
                // TODO: Without this, if any field in SliderFieldGroup's fields prop is given the filterBy and/or filterByKey
                // prop, getOptions is possibly called too early, leading to getDescendantProp to crash.
                // Might be worth investigating whether there's a bigger fault in the logic somehow.
                const options = item !== undefined 
                    ? this.getOptions(field, item) 
                    : [];

                const value = this.getSelectedValue(field, options, item);
                if (field.hideIfEmpty && options.length === (field.addNoneOption ? 1 : 0)) return null;
                fieldElement = (
                    <div className={styles.datalistContainer}>
                        <DataList
                            ref={(ref) => {
                                this.selectRefs = {
                                    ...this.selectRefs,
                                    [field.key]: ref,
                                };
                            }}
                            key={field.key}
                            name={field.key}
                            autoFocus={this.handleAutoFocus(field)}
                            label={field.title + (field.required ? '*' : '')}
                            options={options}
                            value={field.additionalProps?.noValue ? undefined : item && value}
                            shownCount={field.additionalProps?.virtualized ? undefined : 20}
                            onChange={(value) => (field.onChange ? field.onChange(value) : this.onSelectChanged(field, field.type == 'select' ? value : value.id))}
                            loadOptions={field.loadOptions}
                            getNoOptionsMessage={field.noOptionsMessage}
                            cacheUniq={field.cacheUniq}
                            debounceTimeout={field.debounceTimeout}
                            required={field.required}
                            error={field.error || !!fieldErrors[field.key]}
                            loadingMessage={() => this.tr('Loading...')}
                            isMulti={field.isMulti}
                            noOptions={field.noOptions}
                            onItemCreated={(item) => (field.onItemCreated ? field.onItemCreated(item, () => this.onSelectChanged(field, item)) : this.onSelectChanged(field, item))}
                            openMenuOnFocus
                            tabSelectsValue={false}
                            menuPosition={"fixed"}
                            menuWidth={100}
                            menuPlacement={menuPlacements[field.key]}
                            isDisabled={fieldDisabled}
                            {...field.additionalProps}
                            tagCreated={(tag) => this.tagCreated(field, tag)}
                            typeCreated={(type) => this.typeCreated(field, type)}
                        />
                        {field.divideToSubFieldsKey &&
                            value?.suboptions &&
                            value?.suboptions.length > 0 &&
                            this.renderField(
                                {
                                    ...field.subFieldProps,
                                    subFieldProps: field.subFieldProps,
                                    divideToSubFieldsKey: field.divideToSubFieldsKey,
                                    key: `${field.key.split('_sub_')[0]}_sub_${(field.suboptionIndex || 0) + 1}`,
                                    options: value?.suboptions,
                                    suboptionIndex: (field.suboptionIndex || 0) + 1,
                                },
                                index
                            )}
                    </div>
                );
                break;
            }
            case 'treeselect':
                {
                    const options = item !== undefined 
                        ? this.getOptions(field, item)
                        : [];

                    if (field.hideIfEmpty && options.length === (field.addNoneOption ? 1 : 0)) return null;
                    fieldElement = (
                        <TreeSelect
                            autoFocus={this.handleAutoFocus(field)}
                            label={field.title}
                            options={options}
                            value={item && item[fieldValueKey]}
                            shownCount={20}
                            onChange={(e) => this.onSelectChanged(field, e.target.value)}
                            loadOptions={field.loadOptions}
                            getNoOptionsMessage={field.noOptionsMessage}
                            cacheUniq={field.cacheUniq}
                            debounceTimeout={field.debounceTimeout}
                            required={field.required}
                            error={field.error || !!fieldErrors[field.key]}
                            loadingMessage={() => this.tr('Loading...')}
                            isMulti={field.isMulti}
                            noOptions={field.noOptions}
                            disabled={fieldDisabled}
                            {...field.additionalProps}
                        />
                    );
                }
                break;
            case 'checkbox':
                fieldElement = (
                    <>
                        <Checkbox
                            key={field.key}
                            name={fieldValueKey}
                            disabled={fieldDisabled}
                            // showCheckedEvenWhenDisabled
                            // TODO: there's some sort of a bug
                            // with the order in which things are done,
                            // and item can still be undefined here,
                            // and I can't find a way to affect it externally. 
                            checked={item?.[fieldValueKey] || false}
                            onChange={(e) => {
                                // Could've changed something in onFieldEdited,
                                // but changing something here is risking breaking only
                                // one field type.
                                this.onFieldEdited({
                                    target: {
                                        value: e.currentTarget.checked,
                                        name: e.target.name
                                    }
                                });
                            }}
                            // onChange={this.onFieldEdited}
                            // label={field.title}
                        />{field.title}
                    </>
                );
                break;
            case 'daterange':
                fieldElement = (
                    <DateRangePicker
                        key={field.key}
                        className={field.class}
                        ranges={[
                            {
                                startDate: item && item[field.startKey || 'startDate'],
                                endDate: item && item[field.endKey || 'endDate'],
                                key: 'selection',
                            },
                        ]}
                        onChange={(event) => this.onDateRangeChanged(field, event)}
                        onInputChange={(type, date) => this.onDateRangeInputChanged(field, type, date)}
                        label={field.title}
                        dateFormat={this.context.userObject.dateFormat}
                        disabled={fieldDisabled}
                    />
                );
                break;
            case 'date':
                fieldElement = (
                    <DatePicker
                        key={field.key}
                        name={field.key}
                        className="date full"
                        disabled={fieldDisabled}
                        date={item && !!item[fieldValueKey] && item[fieldValueKey] != '0000-00-00' ? item[fieldValueKey] : this.getTextualFieldDefaultValue(field.key)}
                        error={field.error || Boolean(fieldErrors[field.key])}
                        onChange={(date) => this.onDateChanged(fieldValueKey, date)}
                        onInputChange={(_, date) =>
                            !field.additionalProps?.noEmptyDates || (field.additionalProps?.noEmptyDates && date != '') ? this.onDateInputChanged(fieldValueKey, date) : undefined
                        }
                        label={field.title + (field.required ? '*' : '')}
                        dateFormat={this.context.userObject.dateFormat}
                        usePopper
                        popperBottom
                    />
                );
                break;
            case 'timerange': {
                let value = item && item[fieldValueKey];

                if (!value && field.startKey && field.endKey && item[field.startKey] && item[field.endKey]) {
                    let start = item[field.startKey];
                    let end = item[field.endKey];

                    const momentStart = moment(start, field.timeRangeOutputFormat ?? 'HH:mm');
                    const momentEnd = moment(end, field.timeRangeOutputFormat ?? 'HH:mm');
                    
                    const endTimeExact = momentEnd.format('HH:mm:ss');

                    if (momentStart.isValid() && momentEnd.isValid()) {
                        start = momentStart.format('LT');
                        end = momentEnd.format('LT');
                    }

                    // Selection to end of day, use 24:00
                    if (field.useMidnightEnd && (end === '00:00' || endTimeExact === '23:59:59')) {
		                const use12hr = this.context.calendar.clock === 0;

                        end = use12hr ? '12:00 AM' : '24:00';
                    }

                    value = `${start} - ${end}`;
                }

                fieldElement = (
                    <TimeRangeOutlinedField
                        key={field.key}
                        rootClass="time-range"
                        label={field.title}
                        value={value}
                        useState
                        onChange={(value, start, end, isFullDay) => this.onTimeRangeChanged(field, value, start, end, isFullDay)}
                        error={field.error || !!fieldErrors[field.key]}
                        useMidnightEnd={field.useMidnightEnd}
                        showFullDayButton
                        {...field.additionalProps}
                    />
                );
                break;
            }
            case 'time':
                fieldElement = (
                    <TimeOutlinedField
                        key={field.key}
                        value={item && item[fieldValueKey] ? item[fieldValueKey] : this.getTextualFieldDefaultValue(field.key)}
                        onChange={(event, value) => this.onTimeChanged(fieldValueKey, event.target.value)}
                        label={field.title}
                        name={fieldValueKey}
                        error={field.error || !!fieldErrors[field.key]}
                        type="time"
                        clock={this.context.calendar.clock === 0 ? 12 : 24}
                    />
                );
                break;
            case 'textarea':
                fieldElement = (
                    <OutlinedField
                        key={field.key}
                        adornmentPos={field.adornmentPos}
                        adornment={field.adornment}
                        autoFocus={this.handleAutoFocus(field)}
                        disabled={fieldDisabled}
                        onChange={this.onFieldEdited}
                        label={field.title}
                        name={fieldValueKey}
                        value={item && item[fieldValueKey]}
                        multiline
                        rows={field.rows}
                        rowsMax={field.maxRows}
                        required={field.required}
                        error={field.error || !!fieldErrors[field.key]}
                    />
                );
                break;
            case 'limited_text':
                fieldElement = (
                    <TextFieldWithLimit
                        key={field.key}
                        limitEditorType={OutlinedField}
                        limit={field.additionalProps?.limit}
                        autoFocus={this.handleAutoFocus(field)}
                        onChange={this.onFieldEdited}
                        label={field.title}
                        name={fieldValueKey}
                        value={item && (item[fieldValueKey] || (field.getDefaultValue && field.getDefaultValue(item)))}
                        required={field.required}
                        placeholder={field.getPlaceholder ? field.getPlaceholder(item) : field.placeholder}
                        shrinkLabel={field.placeholder ? true : undefined}
                        disabled={fieldDisabled}
                        error={field.error || !!fieldErrors[field.key]}
                        useAbsoluteLimitPositioning
                        {...field.additionalProps}
                    />
                );
                break;
            default:
                {
                    let value =
                        item &&
                        (field.formatValue
                            ? field?.formatValue?.(item[fieldValueKey] || (field.getDefaultValue && field.getDefaultValue(item)), item)
                            : item[fieldValueKey] ?? (field.getDefaultValue && field.getDefaultValue(item)));

                    if(this.hasNumericValidation(field)) {
                        value = this.formatNumericValue(value, field);
                    }

                    fieldElement = (
                        <OutlinedField
                            key={field.key}
                            adornmentPos={field.adornmentPos}
                            adornment={field.adornment}
                            autoFocus={this.handleAutoFocus(field)}
                            disabled={fieldDisabled}
                            onChange={this.onFieldEdited}
                            label={field.title}
                            name={fieldValueKey}
                            value={value}
                            required={field.required}
                            error={field.error || !!fieldErrors[field.key]}
                            placeholder={field.getPlaceholder ? field.getPlaceholder(item) : field.placeholder}
                            shrinkLabel={(field.getPlaceholder ? field.getPlaceholder(item) : field.placeholder) ? true : undefined}
                            usePropValue
                            {...field.additionalProps}
                        />
                    );
                }
                break;
        }

        return (
            <div data-testid={field.key} key={`${field.key}_${index}`} className={styles.field}>
                <div className={`${styles.row} ${field.type == 'header' ? styles.headerRow : ''}`}>
                    {/* renderFieldElement can wrap the field in a Tooltip component,
                        whereas renderInfoTooltip will render the "old" info sphere
                        with a tooltip after (on the right side of) the actual field editor */}
                    {this.renderFieldElement(fieldElement, field)}
                    {this.renderInfoTooltip(field)}
                    {field.rightComponent && <div>{field.rightComponent(item)}</div>}
                </div>
                {field.errorMessage && <div className={styles.errorMessage}>{typeof field.errorMessage == 'function' ? field.errorMessage(this.state.item) : <p>{field.errorMessage}</p>}</div>}
                {field.warningMessage && <div className={styles.warningMessage}>{typeof field.warningMessage == 'function' ? field.warningMessage() : <p>{field.warningMessage}</p>}</div>}
            </div>
        );
    };

    protected renderFieldElement = (fieldElement: React.ReactElement, field: EditableField): React.ReactElement => {
        const text: string | undefined = typeof(field.fieldTooltip) === "function" 
            ? field.fieldTooltip(this.state.item)
            : field.fieldTooltip;

        return !text || field.type === "dateswitch"
            ? fieldElement 
            : (
                <Tooltip 
                    title={text} 
                    arrow 
                    classes={{ tooltip: 'darkblue-tooltip' }}>
                    {fieldElement}
                </Tooltip>
            );
    }

    protected renderInfoTooltip = (field: EditableField): React.ReactElement | null => {
        const text: string | undefined = typeof(field.tooltip) === "function"
            ? field.tooltip(this.state.item)
            : field.tooltip;

        if(!text || field.type === "dateswitch") {
            return null;
        }

        return (
            <div className={styles.tooltip}>
                <Tooltip title={
                    typeof(field.tooltip) === "function"
                        ? field.tooltip(this.state.item)
                        : field.tooltip
                    } 
                    arrow 
                    classes={{ tooltip: 'darkblue-tooltip' }}>
                    <InfoOutlined />
                </Tooltip>
            </div>
        );
    }

    onDeleteItem = () => {
        this.props.onDeleteItem && this.props.onDeleteItem(this.state.item);
    };

    onSave = () => {
        const { item } = this.state;
        const saveItem = cloneDeep(item);

        // below option could be useful instead, not sure
        // const saveItem = {};
        // //only saving the fields that are in the slider, even if the item has more
        // this.state.fields.forEach((field) => {
        //     saveItem[field.key] = item[field.key];
        // });
        const fieldsWithDefaults = this.state.fields.filter((f) => f.defaultValue);
        if (fieldsWithDefaults.length > 0) {
            fieldsWithDefaults.map((fd) => {
                saveItem[this.getFieldValueKey(fd)] = saveItem[this.getFieldValueKey(fd)] || fd.defaultValue;
            });
        }
        if (!this.checkValidity(saveItem)) return;

        // disabling the save button for approx. 1 second so no accidental double clicks happen
        this.setState({ saveTimeoutActive: true }, () => {
            if(this.props.onSave) {
                this.props.onSave(saveItem);
            }
            setTimeout(() => {
                this.setState({ saveTimeoutActive: false });
            }, 1000);
        });
    };

    showConfirmDialog = (confirmDialogData) => this.setState({ confirmDialogData });
    closeConfirmDialog = () => this.setState({ confirmDialogData: undefined });

    onClose = () => {
        if (!isEqual(this.originalItem, this.state.item)) {
            this.showConfirmDialog({
                header: this.tr('Close slider'),
                translatedConfirmButtonText: this.tr('Close slider'),
                warning: () => this.tr('Are you sure you want to close the slider? All unsaved changes will be lost.'),
                onConfirm: () => {
                    this.setState({ confirmDialogData: undefined }, () => {
                        this.props.onClose && this.props.onClose();
                    });
                },
            });
        } else {
            this.props.onClose && this.props.onClose();
        }
    };

    beforeOnClose = (onClose) => {
        if (!isEqual(this.originalItem, this.state.item)) {
            this.showConfirmDialog({
                header: this.tr('Close slider'),
                translatedConfirmButtonText: this.tr('Close slider'),
                warning: () => this.tr('Are you sure you want to close the slider? All unsaved changes will be lost.'),
                onConfirm: () => {
                    this.setState({ confirmDialogData: undefined }, () => {
                        onClose && onClose();
                    });
                },
            });
        } else {
            onClose && onClose();
        }
    };

    setMenuPlacements = () => {
        let menuPlacements = {};
        Object.keys(this.selectRefs).forEach((key) => {
            const selectRef: any = this.selectRefs[key];
            const domNode: any = ReactDOM.findDOMNode(selectRef);
            const windowHeight = window.innerHeight;
            const fieldBottom = (domNode?.getBoundingClientRect && domNode?.getBoundingClientRect())?.bottom || 0;
            let menuHeight = 0;
            const field = (this.state.fields || []).find((f) => f.key == key);
            if (field) {
                const options = this.getOptions(field, this.state.item);
                menuHeight = Math.min(400, (options || []).length * 46);
            }
            const menuPlacement = fieldBottom + menuHeight > windowHeight - 50 ? 'top' : 'bottom';
            menuPlacements = {
                ...menuPlacements,
                [key]: menuPlacement,
            };
        });
        this.setState({ menuPlacements });
    };

    onWindowResize = () => {
        if (this.menuPlacementTimeout) {
            clearTimeout(this.menuPlacementTimeout);
        }
        this.menuPlacementTimeout = setTimeout(() => {
            this.setMenuPlacements();
        }, 300);
    };

    onContentScroll = () => {
        if (this.menuPlacementTimeout) {
            clearTimeout(this.menuPlacementTimeout);
        }
        this.menuPlacementTimeout = setTimeout(() => {
            this.setMenuPlacements();
            window.dispatchEvent(new Event('fieldEditSliderScrolled'));
        }, 300);
    };

    renderAdditionalFields = () => {
        const { item } = this.state;
        const { additionalFields } = this.props;
        return typeof additionalFields == 'function' ? additionalFields(item, this.onAdditionalFieldChanged) : additionalFields;
    };

    handleKeyPress = (event) => {
        if (event.key == 'Enter' && this.props.saveOnEnter) {
            event.preventDefault();
            const activeElement: any = document.activeElement;
            if (activeElement) {
                activeElement.blur && activeElement.blur();
            }
            setTimeout(() => {
                this.onSave();
            }, 200);
        }
        if (event.key == 'Escape') {
            event.preventDefault();
            this.onClose();
        }
    };

    renderLoading = () => {
        const { loadingText } = this.props;

        return(
            <div className={styles.loadingIndicator}>
                <CircularProgress color="primary" disableShrink size={40} />
                {loadingText &&
                    <span className={styles.loadingText}>{loadingText}</span>
                }
            </div>
        )
    }

    render() {
        const { onClose, canDeleteItem, saving, alwaysShowDelete, deleteDisabledTooltip, options, width, optionsAsAdd, saveDisabled, saveHidden, loadingData } = this.props;
        const { item, confirmDialogData, fields, saveTimeoutActive } = this.state;
        this.selectRefs = {};
        const cantDeleteItem = item && (!canDeleteItem || (canDeleteItem && !canDeleteItem(item)));
        const content = (
            <>
                <div className={styles.fieldEditSlider} style={{ width: width || 500 }} onKeyPress={this.handleKeyPress}>
                    {loadingData ? this.renderLoading() : (
                        <div className={styles.fields} onScroll={this.onContentScroll}>
                            {(fields || []).map((field, i) => {
                                return this.renderField(field, i);
                            })}
                            <div className={styles.additionalFields}>{this.renderAdditionalFields()}</div>
                        </div>
                    )}
                    <div className={styles.actions}>
                        <Button onClick={(e) => onClose(e, 'cancel')} variant="text" size="large" data-testid="slider-cancel-button">
                            {this.tr('Cancel')}
                        </Button>
                        <div className={styles.right}>
                            {options && options.length > 0 && (
                                <ContextMenu
                                    //@ts-ignore
                                    className={`${styles.options} ${optionsAsAdd ? styles.add : ''}`}
                                    variant="outlined"
                                    size="large"
                                    label={optionsAsAdd ? this.tr('Add new') : this.tr('Options')}
                                    placement={'bottom-end'}
                                >
                                    {options.map((item) => {
                                        return (
                                            <MenuItem onClick={item.onClick} key={item.key}>
                                                {item.label}
                                                {item.needsUpgrade && <Star className={styles.starIcon} />}
                                            </MenuItem>
                                        );
                                    })}
                                </ContextMenu>
                            )}
                            {!(Number(item?.id || 0) <= 0) && (alwaysShowDelete || (canDeleteItem && canDeleteItem(item))) && (
                                <Tooltip title={cantDeleteItem && deleteDisabledTooltip ? deleteDisabledTooltip : ''}>
                                    <div>
                                        <Button onClick={this.onDeleteItem} disabled={cantDeleteItem} size="large" color="secondary">
                                            {this.tr('Delete')}
                                        </Button>
                                    </div>
                                </Tooltip>
                            )}
                            {!saveHidden && (
                                <LoaderButton
                                    disabled={saveDisabled || saveTimeoutActive || loadingData}
                                    text={this.tr('Save')}
                                    loading={saving || saveTimeoutActive || loadingData}
                                    onClick={this.onSave}
                                    size="large"
                                    color="primary"
                                    data-testid="slider-save-button"
                                />
                            )}
                        </div>
                    </div>
                </div>
                {confirmDialogData && (
                    <CoreDialog
                        dialogType="delete"
                        useKeyboardShortcuts={true}
                        dialogProps={{
                            onCloseClick: this.closeConfirmDialog,
                            close: this.closeConfirmDialog,
                            onCancel: this.closeConfirmDialog,
                            cancelButtonText: this.tr('Cancel'),
                            onConfirm: confirmDialogData.onConfirm,
                            header: confirmDialogData.header,
                            translatedConfirmButtonText: confirmDialogData.translatedConfirmButtonText,
                            warning: confirmDialogData.warning,
                        }}
                    />
                )}
            </>
        );
        if (this.props.useAnimatedSlider) {
            return (
                <AnimatedSlider anchor="right" {...this.props} ref={undefined} beforeOnClose={this.beforeOnClose} onClose={this.props.onClose}>
                    {typeof this.props.children == 'function' ? this.props.children(content) : content}
                </AnimatedSlider>
            );
        }
        return (
            <Slider anchor="right" {...this.props} ref={undefined} onClose={this.onClose}>
                {typeof this.props.children == 'function' ? this.props.children(content) : content}
            </Slider>
        );
    }
}

export default FieldEditSlider;
