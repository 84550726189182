import React from 'react';
import TaimerComponent from '../TaimerComponent';
import PDFView from '../general/PDFView';
import DataHandler from '../general/DataHandler';
import styles from './ExternalQuote.module.scss';
import { Button } from '@mui/material';
import { Check, Close, CloudDownload } from '@mui/icons-material';
import FileSaver from 'file-saver';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import LoaderButton from '../general/LoaderButton';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
import { ReactComponent as ExternalQuoteIcon } from "../general/icons/externalQuote.svg";
import { ReactComponent as HeerosLogo } from "../navigation/heeros_logo_white.svg";


type Props = WithSnackbarProps
interface State {
    quotePDF: Blob | undefined;
    status: string;
    isPending: boolean;
    saving?: 'approve' | 'decline';
}

class ExternalQuote extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'external/ExternalQuote');
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('ext_token');
        if (token) {
            sessionStorage.setItem('external_token', token);
        }
        this.state = {
            quotePDF: undefined,
            status: 'sent',
            isPending: true,
        };
    }

    componentDidMount(): void {
        this.getQuotePDF();
    }

    getQuotePDF = (): void => {
        DataHandler.getArrayBuffer({ url: 'auth_ext/quote_attachment' }).done((file, _, xhr) => {
            const respHeaders = xhr.getResponseHeader('Taimer-Quote-Status').split(', ');
            let isPending = true;
            let status = '';
            let quotePDF;
            respHeaders.forEach((el) => {
                const split = el.split('=');
                if (split[0] === 'is-pending') {
                    isPending = split[1] === '1';
                } else if (split[0] === 'status') {
                    status = split[1];
                }
            });
            const receiverEmail = xhr.getResponseHeader('Taimer-Quote-Receiver');
            if (receiverEmail) {
                this.context.functions.setMixpanelAlias(receiverEmail);
            }
            if (file !== false) {
                quotePDF = new Blob([file], {
                    type: 'application/pdf',
                });
            }
            this.setState({ quotePDF, isPending, status });
        });
    };

    sendStatusAnalyticsEvent = (approve) => {
        const analyticsData = {
            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
        };
        //this.context.functions.sendAnalytics(approve ? 'receiver_approved_quote' : 'receiver_declined_quote', analyticsData);
        this.context.functions.sendMixpanelEvent(approve ? 'Approve received sales quote' : 'Decline received sales quote');
    };

    sendDownloadAnalyticsEvent = () => {
        const analyticsData = {
            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
        };
        //this.context.functions.sendAnalytics('receiver_downloaded_quote', analyticsData);
        this.context.functions.sendMixpanelEvent('Download received sales quote');
    };

    approveQuote = async () => {
        this.setState({ saving: 'approve' }, async () => {
            try {
                await DataHandler.put({ url: 'auth_ext/quote_approve' });
                this.sendStatusAnalyticsEvent(true);
                this.setState({ status: 'approved', saving: undefined });
            } catch (e) {
                this.props.enqueueSnackbar(this.tr('Approving quote failed!'), {
                    variant: 'error',
                });
                this.setState({ saving: undefined });
                console.error(e);
            }
        });
    };
    declineQuote = async () => {
        this.setState({ saving: 'decline' }, async () => {
            try {
                await DataHandler.put({ url: 'auth_ext/quote_decline' });
                this.sendStatusAnalyticsEvent(false);
                this.setState({ status: 'declined', saving: undefined });
            } catch (e) {
                this.props.enqueueSnackbar(this.tr('Declining quote failed!'), {
                    variant: 'error',
                });
                this.setState({ saving: undefined });
                console.error(e);
            }
        });
    };

    downloadQuote = (): void => {
        const { quotePDF } = this.state;
        if (!quotePDF) return;
        this.sendDownloadAnalyticsEvent();
        FileSaver.saveAs(quotePDF, 'heeros_quote_' + moment().format('YYYY-MM-DD') + '_' + '.pdf');
    };

    renderActions = () => {
        const { saving, quotePDF, status } = this.state;
        // if (!quotePDF) return <Skeleton width={250} height={'100%'} />;
        switch (status) {
            case 'sent':
                return (
                    <>
                        <LoaderButton disabled={!!saving} loading={saving == 'decline'} onClick={this.declineQuote} className={styles.decline} size="large" text={this.tr('Decline')} />
                        <LoaderButton disabled={!!saving} loading={saving == 'approve'} onClick={this.approveQuote} className={styles.approve} size="large" text={this.tr('Approve')} />
                    </>
                );
            case 'approved':
                return (
                    <div className={`${styles.statusIndicator} ${styles[status]}`}>
                        <Check />
                        {this.tr('You have approved this sales quote')}
                    </div>
                );
            case 'declined':
                return (
                    <div className={`${styles.statusIndicator} ${styles[status]}`}>
                        <Close />
                        {this.tr('You have declined this sales quote')}
                    </div>
                );
            default:
                return null;
        }
    };

    quoteIsValid = () => {
        const { status } = this.state;
        const validStatuses = ['sent', 'approved', 'declined'];
        return validStatuses.includes(status);
    };

    render() {
        const { quotePDF } = this.state;
        return (
            <div className={styles.externalQuote}>
                <div className={styles.topBar}>
                    <HeerosLogo className="logo" />
                </div>
                {this.quoteIsValid() ? (
                    <div className={styles.content}>
                        <div className={styles.main}>
                            <div className={styles.toolbar}>
                                {true ? (
                                    <Button onClick={this.downloadQuote} className={styles.download} size="large">
                                        <CloudDownload />
                                        <p>{this.tr('Download')}</p>
                                    </Button>
                                ) : (
                                    <Skeleton width={150} height={'100%'} />
                                )}
                                <div>{this.renderActions()}</div>
                            </div>
                            <PDFView file={quotePDF} />
                        </div>
                        <div className={styles.right}>
                            <div>
                                <ExternalQuoteIcon />
                                <h2>{this.tr('Review sales quote')}</h2>
                                <p>
                                    {this.htmlTr("Here you can approve or decline your sales quote.${linebreak}We'll handle it from there and inform the sender about your decision.", {
                                        linebreak: [<br />, <br />],
                                    })}
                                </p>
                            </div>
                            <div>
                                <p>
                                    {this.tr('This sales quote was sent via Heeros.')}
                                    <a href="https://www.heeros.com/en/solutions/psa-professional-service-automation-platform" target="_blank" rel="noopener noreferrer">
                                        {' '}
                                        {this.tr('Try now for free')}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={styles.error}>
                        <div>
                            <Loading  />
                            <h2>{this.tr('Oops! Nothing to show here.')}</h2>
                            <p>{this.tr('This sales quote is no longer valid.')}</p>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withSnackbar(ExternalQuote);
