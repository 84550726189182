import React from "react";
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import PropsOnlyListRow from "../../list/PropsOnlyListRow";
import ListCell from "../../list/ListCell";
import DateCell from "../../list/cells/DateCell";
import EditableStatusCell from "../../list/cells/EditableStatusCell";
import EditIcon from '@mui/icons-material/Edit';
import TextInputCell from "../../list/cells/TextInputCell";
import DropdownCell from "../../list/cells/DropdownCell";
import Switch from '@mui/material/Switch';
import ContextMenu from '../../general/ContextMenu';
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ArrowRight from '@mui/icons-material/ArrowRight';
import CircularProgress from '@mui/material/CircularProgress';
import { MenuItem, Popper } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayWrapper from "../../general/ClickAwayWrapper";

import { calculateWeekTotal } from "../../general/WorkWeekUtils";
import { makeCheckMap } from "../ListUtils";
import Utils from "../../general/Utils";
import cloneDeep from "lodash/cloneDeep";

import "./WorkWeekTemplateRow.css";
import "./AlternativeRow.css";

class ConcretePercentualListCell extends ListCell {
    presentValue(value) {
        const { 
            concrete, 
            percentual 
        } = value;

        return (
            <React.Fragment>
                <p style={{ lineHeight: "14px", margin: "0px" }}>{concrete}</p>
                <p style={{ lineHeight: "14px", margin: "0px", color: "#aaa" }}>{percentual}</p>
            </React.Fragment>
        );
    }
}

class WorkWeekTemplateRow extends PropsOnlyListRow {
	static rowDimensions = {
		height: "44px",
		lineHeight: "44px"
	};

    static days = [
        "mon",
        "tue",
        "wed",
        "thu",
        "fri",
        "sat",
        "sun"
    ];

	constructor(props, context) {
        super(props, {}, {}, "list/rows/WorkWeekTemplateRow");

        [
            "onEdit",
            "onBasedOnDayEdit",
            "createBasedOnHighlightPopper",
            "createErrorContent",
            "determineMinAndMaxDates",
            "showModifiedExplanation",
            "hideModifiedExplanation",
            "showBasedOnHighlight",
            "hideBasedOnHighlight",
            "createTemplateIdAdditionalContent",
            "determineEditable"
        ].forEach(f => this[f] = this[f].bind(this));
    }

    defineClassName() {
        return `workWeekTemplateRow alternativeRow`;
    }

    onEdit(a, b) {
        b === undefined
            ? this.setData(a)
            : this.setData(a, b);
    }

    onBasedOnDayEdit(day, value) {
        const { 
            listRef,
            data
        } = this.props;

        const { 
            concrete, 
            percentual 
        } = data.concrete_and_percentual[day];

        const floatPercentual = parseInt(percentual) / 100;
        const basedOnConcrete = concrete / floatPercentual;
        const valueFloat      = parseInt(value) / 100;
        const newConcrete     = (basedOnConcrete * valueFloat).toFixed(2);

        listRef.editData({ concrete_and_percentual: {
            ...data.concrete_and_percentual,
            [day]: {
                percentual: value,
                concrete: !isNaN(newConcrete) 
                    ? newConcrete 
                    : ""
            }
        } }, data.id)
        .then(() => this.onEdit(day, value));
    }

    showModifiedExplanation() {
        const list     = this.props.listRef;
        const allIds   = list.getData().map(d => d.id);
        const { data } = this.props;

        list
            .batchEditAttributes()
            .edit({ showModifiedExplanation: false }, allIds)
            .edit({ showModifiedExplanation: true }, data.id)
            .commit();
    }

    hideModifiedExplanation() {
        const list = this.props.listRef;

        list.editAttributes({ showModifiedExplanation: false }, 
            list.getData().map(d => d.id))
    }

    showBasedOnHighlight() {
        const { data } = this.props;
        const { id }   = data;
        const list     = this.props.listRef;
        const allIds   = list.getData().map(d => d.id);
        const editor   = list
            .batchEditAttributes()
            .edit({ basedOnHighlight: false }, allIds);

        const concrete = (!data.hasOwnProperty("based_on") || data.based_on === null)
            ? this.props.listRef.getData()
                .reverse()
                .find(week => week.based_on === null 
                    && week.id !== id && !week.deleted)?.id
            : data.based_on;

        editor
            .edit({ basedOnHighlight: true }, concrete)
            .commit();
    }

    hideBasedOnHighlight() {
        const { data } = this.props;
        const { id }   = data;
        const list     = this.props.listRef;
        const allIds   = list.getData().map(d => d.id);
        const editor   = list
            .batchEditAttributes()
            .edit({ basedOnHighlight: false }, allIds)
            .commit();
    }

    createBasedOnHighlightPopper() {
        const { tr } = this;

        // TODO: Create a generic div for these poppers.

        return (cell) => {
            return (
                <Popper 
                    anchorEl={cell.cell.current}
                    placement="left"
                    open={true}>
                    <div style={{
                        background: "#2979ff",
                        borderRadius: "2px",
                        boxShadow: "0px 0px 16px #bababa",
                        padding: "4px",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "10px"
                    }}>
                        {tr("The percentages are in reference to this workweek definition's hours")}
                        <ArrowRight style={{ 
                            marginLeft: "8px", 
                            width: "16px", 
                            height: "16px",
                            fill: "#fff"
                        }} />
                    </div>
                </Popper>
            );
        };
    }

    createErrorContent(field) {
        const { 
            attributes
        }  = this.props;
        const errorsIn  = attributes?.errorsIn || {};
        const errorMsgs = attributes?.errorMsgs || {};

        const error = errorsIn.hasOwnProperty(field) 
            && errorsIn[field];
        const open  = error && errorMsgs.hasOwnProperty(field);

        return (cell) => {
            return (
                <Popper 
                    anchorEl={cell.cell.current}
                    placement="top"
                    open={open}>
                    <div style={{
                        opacity: 0.85,
                        background: "#2f2f2f",
                        borderRadius: "2px",
                        padding: "8px",
                        color: "#f6f6f6",
                        fontSize: "10px"
                    }}>
                        {errorMsgs?.[field] || ""}
                    </div>
                </Popper>
            );
        };
    }

    createTemplateIdAdditionalContent() {
        const { tr } = this;
        const { 
            data, 
            attributes 
        } = this.props;
        const { 
            modified,
            template_id
        } = data;
        const { 
            errorsIn,
            showModifiedExplanation
        } = attributes
        const atLeastOneSaveAttempt = attributes?.atLeastOneSaveAttempt || false; 

        if((errorsIn?.template_id || false) 
            && (atLeastOneSaveAttempt || data.id > 0)) {
            return this.createErrorContent("template_id");
        }

        if(template_id && modified && showModifiedExplanation) {
            return (cell) => {
                return (
                    <Popper 
                        anchorEl={cell.cell.current}
                        placement="top-start"
                        open={true}>
                        <div style={{
                            opacity: 0.85,
                                background: "#2f2f2f",
                                borderRadius: "2px",
                                padding: "8px",
                                color: "#f6f6f6",
                                fontSize: "10px"
                        }}>
                        {tr("This definition is considered modified because it has either been modified \
                            here, or the original template's definition has changed.")}
                        </div>
                    </Popper>
                );
            };
        }

        return () => {};
    }

    determineMinAndMaxDates() {
        const { 
            data 
        } = this.props;
        const { 
            getPreviousRow,
            getNextRow,
        } = this.props.rowProps;

        const today          = Utils.today();
        const previousRow    = getPreviousRow(data.id);
        const nextRow        = getNextRow(data.id);
        const hasPreviousRow = !!previousRow;
        const hasNextRow     = !!nextRow;
        const hasStartDate   = data.start_date
            && data.start_date !== "0000-00-00"
            && data.start_date !== "Invalid Date";
        const hasEndDate   = data.end_date
            && data.end_date !== "0000-00-00"
            && data.end_date !== "Invalid Date";

        const minMaxDates = {
            start: {
                min: undefined,
                max: undefined
            },
            end: {
                min: undefined,
                max: undefined
            }
        };

        // Minimum start date.
        if(hasPreviousRow) {
            const dates = {
                [previousRow.start_date]: "start", 
                [previousRow.end_date]: "end", 
            };

            const keys = Object.keys(dates)
                .filter(k => k !== "null") // If any of the dates was null, the key will be "null".
                .sort();

            const latest          = keys[keys.length - 1];
            minMaxDates.start.min = Utils.dateAdd(new Date(latest), +1);

            // const key = dates[latest];
            // minMaxDates.start.min = Utils.dateAdd(new Date(latest), 
                // key !== "today" ? +1 : 0);

            // The minimum start date must be a least two days
            // more than the previous row's start date.
            const difference = (new Date(minMaxDates.start.min) 
                - new Date(previousRow.start_date)) / 1000 / 60 / 60 / 24;

            if(difference < 2) {
                minMaxDates.start.min = Utils.dateAdd(minMaxDates.start.min, +1);
            }
        }

        if(minMaxDates.start.min !== undefined || (
            data.start_date !== undefined
            && data.start_date !== null
            && data.start_date !== "0000-00-00"
            && data.start_date !== "Invalid Date"
        )) {
            minMaxDates.end.min = Utils.dateAdd(data.start_date !== undefined 
                ? data.start_date 
                : minMaxDates.start.min, +1);
        }

        // Maximum start date.
        if(hasNextRow && nextRow.start_date) {
            minMaxDates.start.max = Utils.dateAdd(nextRow.start_date, -2);

            // Maximum end date.
            minMaxDates.end.max = Utils.dateAdd(nextRow.start_date, -1);
        }

        if(hasEndDate) {
            minMaxDates.start.max = Utils.dateAdd(data.end_date, -1);
        }

        return minMaxDates;
    }

    determineEditable() {
        const { 
            data,
            rowProps
        } = this.props;

        const { 
            mode 
        } = rowProps;

        const {
            end_date
        } = data;

        // Kylläpä kannatti tämäkin tehdä.
        
        return ({
            time_management_settings: () => !data.deleted,
            user_view: () => data.id < 0 || !data.deleted
            // user_view: () => data.id < 0 
                // || (!data.deleted && Utils.today() < end_date)
        })[mode]();
    }

    defineCells() {
        const { 
            props,
            tr
        } = this;
        const { 
            data, 
            attributes,
            checked,
            listRef,
			sharedData,
            rowProps,
            columnOrder,
            rowCallbacks
        } = props;

        const {
            showEditingExistingTemplateDialogForTemplate
        } = rowCallbacks;

        const {
            start_date,
            end_date,
            deleted
        } = data;

        const { 
            mode,
            getPreviousRow,
            getNextRow,
            newUserMode
        } = rowProps;

        const previousRow    = getPreviousRow(data.id);
        // const nextRow        = getNextRow(data.id);
        const hasPreviousRow = !!previousRow;
        // const hasNextRow     = !!nextRow;
        const hasBasedOnRows = listRef.getData()
            .filter(d => parseInt(d.based_on) === parseInt(data.id))
            .length > 0;
        const hasBeenSaved   = parseInt(data.id) > 0;
        const today          = Utils.today();
        const isCurrentWeek  = start_date <= today 
            && (today <= end_date || end_date === null)
            && data.id > 0;
        const isBasedOn      = data.hasOwnProperty("based_on") 
            && data.based_on !== null;
        const isPercentual   = (hasBeenSaved && data['is_percentage']) 
            || Boolean(WorkWeekTemplateRow.days
                .find(d => String(data[d]).indexOf("%") > -1))

        const total = calculateWeekTotal(data);
        const minMaxDates = this.determineMinAndMaxDates();

        const listCellProps = { 
            noBorder: true,
            noInitFocus: true,
            showTooltipForOverflownText: true
        };

        const textInputCellProps = {
            onAlternativeRow: true,
            listCellProps: listCellProps,
            delayOnEdit: 200
        };

        const descriptionTextInputCellProps = cloneDeep(textInputCellProps);

        descriptionTextInputCellProps.listCellProps.className = "textInputCell";
        descriptionTextInputCellProps.listCellProps.editable  = !deleted;
            // && (today < end_date || (hasNextRow && today < nextRow.start_date) || isCurrentWeek);

        const editable = this.determineEditable();

        if(!editable) {
            listCellProps.editable = false;
        }

        const weekDayProps = { 
            textAlign: "center", 
            textInputProps: { 
                ...textInputCellProps.textInputProps,
                charRegEx: /[^0-9,.%]+/g,
                style: {
                    padding: 0
                }
            }
        };

        const days                  = {};
        let errorsIn                = attributes?.errorsIn || {};
        const atLeastOneSaveAttempt = attributes?.atLeastOneSaveAttempt || false; 
        const basedOnHighlight      = attributes?.basedOnHighlight || false; 

        errorsIn = {
            ...makeCheckMap(columnOrder, false),
            ...errorsIn
        };

        const useConcretePercentualListCell = mode === "user_view" 
            && hasBeenSaved
            && data['based_on'] != null;

        const WeekDayListCellType = useConcretePercentualListCell
            ? ConcretePercentualListCell
            : ListCell;

        const totalString = !isPercentual 
            ? `${total}h`
            : "";

        const totalListCell = isBasedOn
            ? <ListCell 
                alignCell={true}
                textAlign="right"
                editable={false}
                onlyDisplay={true}>
                <>
                    <p style={{ lineHeight: "14px", margin: "0px" }}>
                        <strong>{total.string}</strong>
                    </p>
                    <p style={{ lineHeight: "14px", margin: "0px", color: "#aaa" }}>{total.percentual}</p>
                </>
            </ListCell>
            : <ListCell // TODO
                alignCell={true}
                textAlign="right"
                editable={false}
                onlyDisplay>
                <strong>{totalString}</strong>
            </ListCell>;

        let templateName = undefined;

        if(mode === "user_view") {
            templateName = sharedData.templates
                .find(t => t.id === data.template_id)?.name || "";

            templateName = parseInt(data.template_id) === 0
                ? tr("(No template)")
                : templateName;

            templateName += data.modified && data.template_id !== null
                ? ` (${tr("modified")})`
                : "";
        }       

        const dropdownCellListCellProps = mode === "user_view" 
            ? { value: templateName } 
            : {};

        WorkWeekTemplateRow.days.forEach(day => {
            const v     = data[day];
            const error = errorsIn[day];

            days[day] = (
                <TextInputCell 
                    onAlternativeRow={true}
                    name={day}
                    listCellType={WeekDayListCellType}
                    listCellProps={{
                        ...textInputCellProps.listCellProps,
                        additionalContent: (!basedOnHighlight || day !== "mon") 
                            ? this.createErrorContent(day)
                            : this.createBasedOnHighlightPopper(),
                        value: data['based_on'] != null
                            ? data.concrete_and_percentual[day]
                            : v,
                        onMouseEnter: () => {
                            if(!(isBasedOn 
                                || (!hasBeenSaved && isPercentual)) 
                                || listRef.getData().filter(d => d.id < 0).length > 0) {
                                return;
                            }

                            this.showBasedOnHighlight();
                        },
                        onMouseLeave: () => {
                            if(!(isBasedOn 
                                || (!hasBeenSaved && isPercentual)) 
                                || listRef.getData().filter(d => d.id < 0).length > 0) {
                                return;
                            }

                            this.hideBasedOnHighlight();   
                        }
                    }}
                    runOnEditOnInput={true}
                    delayOnEdit={200}
                    onEdit={!isBasedOn || !hasBeenSaved
                        ? this.onEdit 
                        : this.onBasedOnDayEdit} 
                    showErrorBorder={error}
                    value={data['based_on'] != null 
                        ? data.concrete_and_percentual[day].percentual
                        : v} 
                    {...weekDayProps} 
                /> 
            );
        });

        const showArchiveOption = !data.deleted && !data.is_default &&
            ((today > data.end_date && mode === "user_view") || mode === "time_management_settings");

        let status;

        if(isCurrentWeek) {
            status = 1;
        } else if(data.deleted) {
            status = 2;
        } else {
            status = -1;
        }

        return {
            context: data.id > 0 && !attributes?.rowInEditMode
            ? <ListCell 
                inEditMode={true}
                noBorder
                style={{ background: "transparent" }}>
                <ContextMenu 
                    popperProps={{ disablePortal: false }}
                    className="cell row-menu"
                    buttonProps={{ className: 'action-menu' }}
                    label={<ContextMenuIcon />}
                    noExpandIcon>
                    {!data.deleted && <MenuItem disabled={deleted} onClick={() => {
                        this.enterEditMode(() => {
                            if(mode === "time_management_settings") {
                                this.getCellRef("template_name").focusOnEditor();
                            } else {
                                this.getCellRef("template_id").focusOnEditor(); 
                            }
                        });
                    }}><EditIcon className="workWeekTemplateRow__contextMenuIcon" />{tr("Edit")}</MenuItem>}
                    {showArchiveOption && <MenuItem onClick={() => {
                        mode === "time_management_settings" 
                            ? rowCallbacks.archiveWorkWeek(data)
                            : rowCallbacks.deleteUsersWorkWeek(data, "archive");
                    }}><ArchiveIcon className="workWeekTemplateRow__contextMenuIcon" />{tr("Archive")}</MenuItem>}
                    {(mode === "user_view" && (today < data.start_date || isCurrentWeek) && !hasBasedOnRows) 
                        && <MenuItem className="workWeekTemplateRow__deleteMenuItem" onClick={() => {
                        rowCallbacks.deleteUsersWorkWeek({
                                ...data,
                                isCurrentWeek: isCurrentWeek
                            }, "delete");
                    }}><ArchiveIcon className="workWeekTemplateRow__contextMenuIcon red" /><span>{tr("Delete")}</span></MenuItem>}
                    {data.deleted && <MenuItem onClick={() => {
                        rowCallbacks.reactivateWorkWeek(data);
                    }}><UnarchiveIcon className="workWeekTemplateRow__contextMenuIcon" />{tr("Reactivate")}</MenuItem>}
                </ContextMenu>
            </ListCell>
            : <ListCell
                inEditMode={false}
                style={{ background: "transparent" }}
                noBorder
                onlyDisplay={!newUserMode}>
                {!attributes?.creating 
                    ? <Tooltip title={this.tr("Save")} placement="bottom">
                        <CheckCircle 
                            disabled={true} 
                            className="saveNewRowCheckCircleIcon" 
                            onClick={async () => {
                                if(data.id < 0) {
                                    this.create()
                                        .then(() => this.hideBasedOnHighlight());

                                    return;
                                } 
                               
                                if(mode === "time_management_settings") {
                                    showEditingExistingTemplateDialogForTemplate(data.id)
                                        .then(() => {
                                            this.exitEditMode(() => {
                                                this.hideBasedOnHighlight();
                                            });
                                        }, () => {
                                            this.cancel();
                                        });
                                } else {
                                    this.update().then(() => {
                                        this.hideBasedOnHighlight(); 
                                    });
                                }
                        }} />
                    </Tooltip> 
                    : <CircularProgress size={18} style={{ padding: 6 }} className="" />}
            </ListCell>,
            status: data.id > 0 && !attributes?.rowInEditMode
            ? <EditableStatusCell 
                value={status} 
                editable={false} 
                options={[
                    { label: tr("active"), id: 1, color: "#50e3c2" },
                    { label: tr("archived"), id: 2, color: "#696969" },
                    { label: "", id: -1, color: "transparent" },
                ]} />
            : <ListCell 
                permanentEditMode={true} 
                onlyDisplay={!newUserMode} 
                editable={false} 
                inEditMode={false}>
                {!attributes?.creating 
                    ? <Tooltip title={this.tr("Cancel")} placement="bottom">
                        <Cancel className="cancelIcon" onClick={() => {
                            if(parseInt(data.id) > 0) {
                                this.cancel(() => this.hideBasedOnHighlight());
                            } else {
                                listRef.removeRow(data.id);
                                this.hideBasedOnHighlight();
                            }
                        }} />
                    </Tooltip>
                : null}
            </ListCell>,
            template_id: <DropdownCell 
                onAlternativeRow={true}
                data={(sharedData.templates || []).filter(t => {
                    return hasPreviousRow 
                        ? true 
                        : !t.is_percentage;
                })}
				onSelect={value => {
                    if(!value) {
                        return;
                    }

                    const newData = {
                        template_id: value.id
                    };

                    // TODO: Move to onDataChange.
                    WorkWeekTemplateRow.days.forEach(day => {
                        newData[day] = value[day];
                    });

                    newData.count_balance = value.count_balance;
                    newData.based_on      = null;

                    // When creating a new row and entering percentual values
                    // there will be a Popper to indicate which concrete week
                    // the percentual calculations will be based on.
                    value.is_percentage
                        ? this.showBasedOnHighlight()
                        : this.hideBasedOnHighlight();

                    this.editData(newData);
                }}
                listCellProps={{ 
                    ...listCellProps, 
                    editable: !data.deleted,
                    ...dropdownCellListCellProps,
                    additionalContent: this.createTemplateIdAdditionalContent(),
                    showTooltipForOverflownText: true
                    // onMouseEnter: this.showModifiedExplanation,
                    // onMouseLeave: this.hideModifiedExplanation 
                }} 
                // textInputProps={textInputCellProps.textInputProps}
				value={data.template_id}
                showErrorBorder={errorsIn?.template_id 
                    && atLeastOneSaveAttempt 
                    && data.id < 0}
				dataDescription={{
					filterBy: "name",
					label: "name",
					value: "id"
				}}
            />,
            template_name: <TextInputCell 
                name="name"
                value={data.name} 
                onEdit={this.onEdit}
                runOnEditOnInput={true}
                {...textInputCellProps} 
            />,
            start_date: <DateCell 
                name="start_date"
                className="workWeekDateCell"
                offsetCalendar={true}
                openOnEnterEditMode={false}
                closeOnComplete={false}
                closeCalendarOnComplete={true}
                usePopper={true}
                popperBottom={true}
                showErrorBorder={errorsIn.start_date && (atLeastOneSaveAttempt || data.id > 0)}
                minDate={minMaxDates.start.min}
                maxDate={minMaxDates.start.max}
                listCellProps={{
                    ...listCellProps,
                    additionalContent: (errorsIn?.start_date || false) 
                        && (atLeastOneSaveAttempt || data.id > 0) 
                        ? this.createErrorContent("start_date")
                        : () => {},
                        editable: !data.deleted
                }} 
                value={data.start_date}
                onEdit={(name, value) => {
                    // this.onEdit(name, value);
                    
                    rowCallbacks.startDateEdited(data.id, value, listRef, this);
                }}
            />,
            end_date: <DateCell 
                name="end_date"
                className="workWeekDateCell"
                offsetCalendar={true}
                openOnEnterEditMode={false}
                closeOnComplete={false}
                closeCalendarOnComplete={true}
                usePopper={true}
                popperBottom={true}
                showErrorBorder={errorsIn.end_date}
                minDate={minMaxDates.end.min}
                maxDate={minMaxDates.end.max}
                listCellProps={{
                    ...listCellProps,
                    // editable: listCellProps.editable 
                        // || end_date > today 
                        // || isCurrentWeek 
                        // || (hasNextRow && today < nextRow.start_date)
                        // || !hasNextRow,
                    editable: !data.deleted,
                    additionalContent: this.createErrorContent("end_date")
                }} 
                value={data.end_date}
                onEdit={this.onEdit}
            />,
            description: <TextInputCell 
                name="description"
                runOnEditOnInput={true}
                value={data.description} 
                onEdit={this.onEdit}
                {...descriptionTextInputCellProps} />,
            ...days,
            total: totalListCell,
            count_balance: <ListCell 
                inEditMode={true} 
                noBorder={true}
                style={{ background: "transparent" }}>
                <Switch 
                    size="small"
                    color="primary" 
                    checked={data.count_balance}
                    disabled={data.deleted} 
                    onChange={(_, checked) => {
                        if(hasBeenSaved) {
                            this.setDataAndUpdate("count_balance", checked);
                        } else {
                            this.onEdit("count_balance", checked);
                        }
                    }} />
            </ListCell>,
            is_default: <ListCell 
                inEditMode={true} 
                noBorder={true}
                style={{ background: "transparent" }}>
                <Switch
                    data-testid={"Defaul_template_"+data.name}
                    size="small"
                    color="primary" 
                    checked={data.is_default}
                    disabled={isPercentual || !hasBeenSaved || data.deleted}
                    onChange={(_, checked) => {
                        if(data.is_default) {
                            return;
                        }

                        const ids = listRef
                            .getData()
                            .map(d => d.id);

                        listRef.batchEdit()
                            .edit({ is_default: false }, ids)
                            .edit({ is_default: checked }, data.id)
                            .commit()
                            .then(() => {
                                rowCallbacks.setDefault(data.id);
                            });
                    }} />
            </ListCell>,
        };
    }

    // For when people want click-away saving back.
    // wrapRow(content, data, attributes) {
        // const { 
            // errorsIn
        // } = attributes;

        // const {
            // rowCallbacks,
            // rowProps
        // } = this.props;

        // const { 
            // mode,
            // dialogOpen
        // } = rowProps;

        // const {
            // showEditingExistingTemplateDialogForTemplate
        // } = rowCallbacks;

        // return (
            // <ClickAwayWrapper 
                // active={!dialogOpen && (attributes?.rowInEditMode || false)}
                // onClickAway={() => {
                    // if(Object.keys(errorsIn).length > 0) {
                        // return;
                    // }

                    // if(mode === "user_view") {
                        // this.update();
                        // this.hideBasedOnHighlight();

                        // return;
                    // } 

                    // showEditingExistingTemplateDialogForTemplate(data.id)
                        // .then(() => {
                            // this.exitEditMode(() => {
                                // this.hideBasedOnHighlight();
                            // });
                        // }, () => {
                            // this.cancel();
                        // });
                // }}>
                // {content}
            // </ClickAwayWrapper>
        // );
    // }

    canExitEditMode() {
        const { attributes } = this.props;
        const errorsIn       = attributes?.errorsIn || {};

        return Object.keys(errorsIn).length === 0;
    }
}

export default WorkWeekTemplateRow;
