import React from 'react';
import classNames from 'classnames';
import TaimerComponent from './../../TaimerComponent';
import { SettingsContext } from './../../SettingsContext';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';

import './../../general/react-date-range/src/styles.css';
import './../../general/react-date-range/src/theme/default.css';

const styles = theme => ({
    paper: {
        width: '360px'
    },
    topBar: {
        backgroundColor: '#2D9FF7',
        height: '8px',
        borderRadius: '4px 4px 0 0',
        marginBottom: '10px'
    }    
});

class ConfirmationDialog extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/dialogs/ConfirmationDialog");
    }

    handleCancel = () => {
        this.props.onDialogClose();
    }

    handleOk = () => {
        const { saveFunc, id } = this.props.data;   
        this.props.onDialogSave(saveFunc, id);
    }

    render() {
        const { data, classes } = this.props,
            { text } = data,
            { taimerAccount } = this.context;        

        return (
            <Dialog
                open
                className="invoiceDialog"
                maxWidth="xs"
                classes={{paper: classNames(classes.paper)}} 
                aria-labelledby="markAsPaidSingle" >
                <div className={classNames(classes.topBar)}/> 
                <DialogContent className="invoiceDialogContent" ref={cont => this.container = cont}>
                    <p style={{textAlign: 'center'}} >{text}</p>
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="outlined" onClick={this.handleCancel} >
                        {this.props.cancelText || this.tr("Cancel")}
                    </Button>
                    <Button size="large" variant="outlined" onClick={this.handleOk} color="primary">
                        {this.props.okText || this.tr("Ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmationDialog.defaultProps = {
    onDialogClose: () => {},
    onDialogSave: () => {},
    cancelText: undefined,
    okText: undefined
}

export default withStyles(styles)(ConfirmationDialog);
