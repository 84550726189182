import React from 'react';
import CollaborateView from "../collaborate/CollaborateView";
import DataHandler from './../general/DataHandler';
import TaimerComponent from "../TaimerComponent";
import PageTopSection from '../general/PageTopSection';

import { Elements, StripeProvider } from 'react-stripe-elements';
import BuyTaimer from './../general/BuyTaimer';
import Taimer from './../Taimer';
import './TabCollaborate.scss';

/* context */
import { SettingsContext } from '../SettingsContext';


class TabCollaborate extends TaimerComponent {
    static contextType = SettingsContext;

	constructor(props, context) {
        super(props, context, "projects/TabCollaborate");
        this.state = {
			extranetsId: false,
            showStripe: false
		};
	}

	checkColaborate() {
		const params = {
			projects_id: this.props.id
		};
		DataHandler.post({url: `collaborate/create_collaborate`}, params).done(data => {
            if(data.status == "fail") {
                //this.context.mixpanel.track('Open Add-on Dialog', {'Clicked add-on': 'collaborate', 'Trial dialog': false});
                this.setState({showStripe: true});
            } else {
			    this.setState({extranetsId: data.id})
            }
        });
	}

	componentDidMount() {
        super.componentDidMount();
        this.checkColaborate();
        this.context.functions.sendMixpanelEvent('Open boards tab');
    }

    hideStripe = () => {
        this.setState({showStripe: false});
    }

    render () {
        const { userObject, functions: { getEnv } } = this.context;
        const STRIPE_PUBLIC_KEY = getEnv('VITE_STRIPE_PUBLIC') 

        const stripeProps = {
            whoami: Taimer.whoami,
            stripe: Taimer.stripe,
            toggleBuyDialog: this.hideStripe
        };

        return (
            this.state.showStripe ?
            (
                <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
                    <Elements locale={userObject.language}>
                        <BuyTaimer {...stripeProps} />
                    </Elements>
                </StripeProvider>
            )
            :
            (
            <div id="projects-collaborate">
                {this.props.header && (
                    <PageTopSection
                        header={this.props.header}
                        subheaders={this.props.subheaders}
                    />
                )}
                <CollaborateView
                    noShadow
                	single
                	extranetsId={this.state.extranetsId}
                />
            </div>
            )
        );
    }


}

export default TabCollaborate;