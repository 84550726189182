/* react */
import React from 'react';
import _ from 'lodash';
import FileSaver from 'file-saver';

/* css */
import './Efina.css';
import './Maventa.css';

/* material-ui */
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";

/* others */
import DataHandler from '../../general/DataHandler';
import { SettingsContext } from '../../SettingsContext';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import SettingRow from "../../list/rows/SettingRow";
import TextInputCell from "../../list/cells/TextInputCell";
import SettingsList from "../../list/lists/SettingsList";
import SettingsGrid from '../pages/SettingsGrid';

class StageRow extends SettingRow {

    render() {
        const cells = {
            name: <TextInputCell editable={false} name="name" width={this.props.columnWidthMap['name']} value={this.state.data['name']} listCellProps={{ inEditMode: false }} />,
            hubspot_id: <TextInputCell onEdited={this.editAndSave} name="hubspot_id" width={this.props.columnWidthMap['hubspot_id']} value={this.state.data['hubspot_id']} />,
            get_projects: <Button disabled={this.state.data['hubspot_id'] == ""} className="row-button" color="primary" size="medium" onClick={() => this.props.rowProps.getDeals(this.state.data['hubspot_id'])}>{this.props.rowProps.tr("Get from HubSpot")}</Button>,
            send_projects: <Button disabled={this.state.data['hubspot_id'] == ""} className="row-button" color="primary" size="medium" onClick={() => this.props.rowProps.sendDeals(this.state.data['hubspot_id'], this.state.data['id'])}>{this.props.rowProps.tr("Send to HubSpot")}</Button>
        }

        return <div className="listElement row flex" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;

    }
}

class HubSpot extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/HubSpot");

        this.state = {
            activated: 0,
            dialogData: undefined,
            pipelines: [],
            allow_companyless_hubspot_contacts: false
        };

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getPipelines();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/hubspot`}).done(response => {
            this.setState(response);
        });
    }

    getPipelines = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/pipelines`}).done(data => {
            const pipelines = data.pipelines.filter(item => item.id != -1 && item.id != -5)
            .sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            pipelines.forEach(pl => {
                pl.stages = data.stages.filter(s => s.projects_pipelines_id == pl.id)
            });
            this.setState({pipelines: pipelines});
        });
    }

    getUsers = () => {
        const key = this.props.enqueueSnackbar(this.tr("Generating user ID list"), {
            variant: "info",
            persist: true
        });

        DataHandler.getArrayBuffer({url: `hubspot/${this.props.company}/users`}).done((response) => {
            this.props.closeSnackbar(key);
            
            if (response) {
                const blob = new Blob([response], {
                    type: 'text/plain'
                });
                FileSaver.saveAs(blob, "hubspot_ids.txt");
            }
        });
    }

    stageChange = (evt) => {
        const { name, value } = evt.target;
        
        DataHandler.post({url: "hubspot/" + this.props.company + "/wonloststage/"}, {type: name, stage_id: value}).done(() => {
            this.setState({[name]: value});
        });
    }

    onSettingCheckChange = (update, name, value) => {
        this.setState({[name]: value}, () =>  DataHandler.put({url: "hubspot/" + this.props.company + "/settings/"}, this.state));
    }

    getWonDeals = () => {
        const key = this.props.enqueueSnackbar(`${this.tr("Fetching deals from HubSpot")}...`, {
            variant: "info",
            persist: true
        });
        
        DataHandler.get({url: "hubspot/" + this.props.company + "/wondeals/" + this.state.won_stage}).done(() => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Deals fetched") + '!', {
                variant: "success",
            });
        });
    }

    getLostDeals = () => {
        const key = this.props.enqueueSnackbar(this.tr("Fetching deals from HubSpot..."), {
            variant: "info",
            persist: true
        });
        
        DataHandler.get({url: "hubspot/" + this.props.company + "/lostdeals/" + this.state.lost_stage}).done(() => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Deals fetched") + '!', {
                variant: "success",
            });
        });
    }

    postWonDeals = () => {
        const key = this.props.enqueueSnackbar(this.tr("Sending deals to HubSpot") + '...', {
            variant: "info",
            persist: true
        });
        
        DataHandler.post({url: "hubspot/" + this.props.company + "/wondeals/"}, {hubSpotStageId: this.state.won_stage}).done(() => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Deals sent") + '!', {
                variant: "success",
            });
        });
    }

    postLostDeals = () => {
        const key = this.props.enqueueSnackbar(this.tr("Sending deals to HubSpot") + '...', {
            variant: "info",
            persist: true
        });
        
        DataHandler.post({url: "hubspot/" + this.props.company + "/lostdeals/"}, {hubSpotStageId: this.state.lost_stage}).done(() => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Deals sent") + '!', {
                variant: "success",
            });
        });
    }

    sendContacts = () => {
        const key = this.props.enqueueSnackbar(this.tr("Sending contacts to HubSpot..."), {
            variant: "info",
            persist: true
        });
        
        DataHandler.post({url: "hubspot/" + this.props.company + "/contacts/"}).done(() => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Contacts sent!"), {
                variant: "success",
            });
        });
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    deactivate = () => {
        this.setState({
            dialogData: {
                saveFunc: () => DataHandler.delete({url: `settings/company/${this.props.company}/hubspot`}).done(this.back),
                text: this.tr("Do you want to deactivate the HubSpot integration?")
            }
        }, () => this.openDialog('confirmation'));
    }

    authenticate = () => {
        if (this.state.url != "") 
            window.location = this.state.url;
    }
    
    render(){
        const { activated } = this.state;
        const { company } = this.props;    
        const { tr } = this;
        const dColConf = {resizeable: false, moveable: false, showMenu: false, showResizeMarker: false};
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        const settings = { allow_companyless_hubspot_contacts: this.state.allow_companyless_hubspot_contacts };

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("HubSpot Settings")}</h3>
                    {activated == 1 && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                {activated != 1 && <div className="settings">
                    <Button color="primary" onClick={this.authenticate} size="large">{tr("Connect HubSpot")}</Button>
                </div>}

                {activated == 1 && <div className="settings">
                    <Button color="primary" onClick={this.getUsers} size="large">{tr("Get User IDs")}</Button>
                </div>}

                {activated == 1 && (<div className="settings">
                    <h4>{tr("Won and lost stages")}</h4>
                    <OutlinedField name="won_stage" value={this.state.won_stage} onChange={this.stageChange} label={tr("Won HubSpot stage ID")} />
                    <Button color="primary" className="stage-button" disabled={this.state.won_stage == ""} onClick={this.getWonDeals} size="medium">{tr("Get from HubSpot")}</Button>
                    <Button color="primary" className="stage-button" disabled={this.state.won_stage == ""} onClick={this.postWonDeals} size="medium">{tr("Send to HubSpot")}</Button>

                    <OutlinedField name="lost_stage" value={this.state.lost_stage} onChange={this.stageChange} label={tr("Lost HubSpot stage ID")} />
                    <Button color="primary" className="stage-button" disabled={this.state.lost_stage == ""} onClick={this.getLostDeals} size="medium">{tr("Get from HubSpot")}</Button>
                    <Button color="primary" className="stage-button" disabled={this.state.lost_stage == ""} onClick={this.postLostDeals} size="medium">{tr("Send to HubSpot")}</Button>
                </div>)}

                {activated == 1 && (<div id="settings-maventa" className="main-integration-wrapper">
                    <h4>{tr("HubSpot contacts without company association")}</h4>
                    <p>{tr("Set Taimer to create company with same name as the contact when new HubSpot contact is synced to Taimer")}</p>
                        <SettingsGrid item={settings} onChange={this.onSettingCheckChange} settings={[{
                            type: "check",
                            name: "allow_companyless_hubspot_contacts",
                            label: tr("Create company for contact"),
                            className: "respSetting third"
                        }]} />
                </div>)}

                {activated == 1 && (
                    <div className="settings">
                    <h3>{tr("HubSpot Stage Mapping")}</h3>
                    {this.state.pipelines.length > 0 && this.state.pipelines.map(pipeline => (
                        <React.Fragment>
                        <h4>{pipeline.name}</h4>
                        <SettingsList 
                            key={pipeline.id}
                            className="settingsList integrationList"
                            height="auto"
                            rowHeight={SettingRow.rowHeight}
                            listRowType={StageRow}
                            columns={[
                                { width: 350, name: "name", header: tr("Name"), ...dColConf },
                                { width: 350, name: "hubspot_id", header: tr("HubSpot ID"), ...dColConf },
                                { width: 150, name: "get_projects", ...dColConf },
                                { width: 150, name: "send_projects", ...dColConf }
                            ]}
                            data={pipeline.stages ? pipeline.stages : []}
                            rowProps={{
                                tr: this.tr,
                                onUpdate: (data) => {
                                    let newData = {...data, company: company};
                                    DataHandler.put({url: "settings/hubspot/stage"}, newData);
                                },
                                getDeals: (stageId) => {
                                    const key = this.props.enqueueSnackbar(this.tr("Fetching deals from HubSpot..."), {
                                        variant: "info",
                                        persist: true
                                    });
                                    
                                    DataHandler.get({url: "hubspot/" + company + "/stagedeals/" + stageId}).done(() => {
                                        this.props.closeSnackbar(key);
                                        this.props.enqueueSnackbar(this.tr("Deals fetched") + '!', {
                                            variant: "success",
                                        });
                                    });
                                },
                                sendDeals: (stageId, id) => {
                                    const key = this.props.enqueueSnackbar(this.tr("Sending deals to HubSpot") + '...', {
                                        variant: "info",
                                        persist: true
                                    });
                                    
                                    DataHandler.post({url: "hubspot/" + company + "/stagedeals/"}, {hubSpotStageId: stageId, taimerStageId: id}).done(() => {
                                        this.props.closeSnackbar(key);
                                        this.props.enqueueSnackbar(this.tr("Deals sent") + '!', {
                                            variant: "success",
                                        });
                                    });
                                }
                            }}
                        />
                        </React.Fragment>
                    ))}
                    </div>
                )}

                {activated == 1 && (
                    <div className="settings">
                        <h4>{tr("Send Contacts to HubSpot")}</h4>
                        <Button color="primary" onClick={this.sendContacts} size="medium">{tr("Send Contacts to HubSpot")}</Button>
                    </div>
                )}
                    
                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
  
            </div>
        )
    }
}

export default HubSpot;