import React, { Component } from 'react';

/* local components */
import TaimerComponent from "../../TaimerComponent";
import DialogBorder from './../../dialogs/DialogBorder';
import DialogSection from './../../dialogs/DialogSection';
import { ReactComponent as infoIcon } from './../../notifications/icons/info.svg';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* styles */
import './RateChanceForInvoicesDialog.css';

class RateChanceForInvoiceDialog extends TaimerComponent {
    static contextType = SettingsContext;

    constructor (props, context){
        super(props, context, "settings/dialogs/RateChanceForInvoiceDialog");

    }

    render() {

        const { onSave, onSaveAndEdit, text } = this.props.data;

        return (
            <DialogBorder
                id="RateChanceForInvoicesDialog"
                icon={infoIcon}
                title={this.tr("Update currency rate on invoice?")}
                onClose={this.props.onDialogClose}
                className="TMRDialogGrid TMRDialogInputs"
                rightButtons={
                    <React.Fragment>
                        <div 
                            className={"TMRDialogButton blue "} 
                            onClick={onSave} >
                                {this.tr("Save")}
                        </div>
                        <div 
                            className={"TMRDialogButton blue "} 
                            onClick={onSaveAndEdit} >
                                {this.tr("Edit & Save")}
                        </div>
                    </React.Fragment>                   
                }>
                <div class="section-container vertical">
                    <div className="currency-invoices-block">
                        <h3 className="section-header full">
                            {text}
                        </h3>        
                    </div>
                </div>  
            </DialogBorder>
        );
    }
}

export default RateChanceForInvoiceDialog;