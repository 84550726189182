import DataHandler from "./../general/DataHandler";

function companyHasDimensionAddOn(company, addons) {
  return addons
    ?.dimensions
    ?.used_by_companies
    .map(c => String(c))
    .indexOf(String(company)) > -1 || false;
}

async function getDimensionAutoCompleteData(company) {
  let dimensions;
  let dimensionItems;

  try {
    dimensions = await DataHandler.get({ 
      url: `settings/company/${company}/dimensions` 
    });

    dimensionItems = await DataHandler.get({ 
      url: `settings/company/${company}/dimensions/all_items?include_teamless_trees=false` 
    });
  } catch(e) {
    return {
      dimensions: [], 
      dimensionItems: {
        items: [],
        units: [],
        teams: []
      },
      dimensionAutoCompleteData: [],
      dimensionAutoCompleteDataFilters: []
    };
  }

  const dimensionAutoCompleteData = {};

  dimensionAutoCompleteData.dimension_teams = dimensionItems.teams;
  dimensionAutoCompleteData.dimension_units = dimensionItems.units;

  if(dimensionItems.items) {
    dimensionItems.items.forEach(item => {
      const key = `dimension_${item.dimension_id}`;

      if(!dimensionAutoCompleteData[key]) {
        dimensionAutoCompleteData[key] = [];
      }

      dimensionAutoCompleteData[key].push(item);
    });
  }

  const unitParentDimensionId = dimensions
    .find(d => d.id === "units")?.parent_dimensions_id;

  const customDimensionFilters = {};

  dimensions
    .filter(d => parseInt(d.id) > 0)
    .forEach(d => {
      const str = `parent_dimension_items_id|dimension_${d.parent_dimensions_id}.id`;

      customDimensionFilters[`dimension_${d.id}`] = str;
    });

  const filters = {
    dimension_teams: "dimension_units_id|dimension_units.id",
    dimension_units: `parent_dimension_items_id|dimension_${unitParentDimensionId}.id`,
    ...customDimensionFilters
  };

  return { 
    dimensions: dimensions, 
    dimensionItems: dimensionItems,
    dimensionAutoCompleteData: dimensionAutoCompleteData,
    dimensionAutoCompleteDataFilters: filters
  }; 
}

function createAdvancedSearchFieldsForDimensions(dimensions) {
  return dimensions.map(dimension => ({
    field: `dimension_${dimension.id}`,
    transl: dimension.name,
    // TODO: All across searches that use dimensions,
    // the text mode should usually be usable.
    entityMode: true,
    excludedOperators: ["contains", "doesnotcontain"],
    disableNonEntityValues: true
  }));
}

function getInitialDimensionState() {
  return {
    dimensions: [],
    dimensionItems: {},
    dimensionAutoCompleteData: {},
    dimensionAutoCompleteDataFilters: {}
  };
}

export {
  companyHasDimensionAddOn,
  getDimensionAutoCompleteData,
  createAdvancedSearchFieldsForDimensions,
  getInitialDimensionState
};
