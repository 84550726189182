import React from 'react';
import PropTypes from "prop-types";
import $ from 'jquery';
import cloneDeep from "lodash/cloneDeep";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';

/* local components */
import List from '../List'
import ContextMenu from './../../general/ContextMenu';
import FileSize from './../../general/FileSize';
import DataHandler from './../../general/DataHandler';
import TaimerComponent from './../../TaimerComponent';
import { ReactComponent as RemoveIcon } from './../../general/icons/remove.svg';
import MassDialog from '../../dialogs/mass_operations/CoreDialog';

/* context */
import { SettingsContext } from './../../SettingsContext';

/* material icons */
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined'

import { withSnackbar } from 'notistack';

import './../../general/TabAttachments.css';

class AttachmentsRow extends TaimerComponent {

    constructor(props, context) {
        super(props, context, "list/dialogs/HandleRowAttachments");
    }

    handleView = event => {
        const { rowProps, data } = this.props;
        rowProps.handleView(data);
    };
    handleDelete = event => {
        const { rowProps, data } = this.props;
        rowProps.handleDelete(data);
    };
    render() {
        const { columnOrder, columnWidthMap, data } = this.props;
        const props = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": prop, style: {width: columnWidthMap[prop] + 'px', flex: columnWidthMap[prop] + " 1 0px" }} } 
        });

        const cells = {
            "context": (
                <ContextMenu label={<MoreHoriz />} {...props.context} noExpandIcon>
                    <MenuItem className="delete" onClick={this.handleDelete}><RemoveIcon className="menuIcon delete"/> {this.tr("Delete")}</MenuItem>
                </ContextMenu>
            ),
            "filename": (
                <a href="#" onClick={this.handleView} {...props.filename}>{data.filename}</a>
            ),
            "size": (
                <div {...props.size}>
                    <FileSize>{data.filesize-0}</FileSize>
                </div>
            ),
            // "createdby": (
            //     <div {...props.createdby}>
            //         <Avatar className="avatar" src={data.avatar}>{data.abbreviation}</Avatar>
            //         <div className="person">
            //             <span>{data.created_by}</span><br/>
            //             {data.role}
            //         </div>
            //     </div>
            // ),
        };

        return (
            <div className="list-element">
                {columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }     
}

class HandleRowAttachments extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/dialogs/HandleRowAttachments");

        this.upload = React.createRef();

        this.state = {
            entity_attachments: [],
            showDeleteConfirmation: false
        }

        let functionBinds = ['onChange', 'handleCancel', 'handleClose'];
        [...functionBinds].forEach(f => this[f] = this[f].bind(this));
    }

    componentDidMount() {
        super.componentDidMount();
        //this.setState({entity_attachments: this.props.data.entity_attachments});
        // NO KOKKEILLAAS TÄTÄ
        this.getAttachments();
    }

    onDragOver = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy';
    };

    getAttachments = () => {
        const { id, targetModule } = this.props.data;

        if (!id) return;

        DataHandler.request("GET", {url: targetModule + '/' + id + '/attachments'}).done(response => {
            const filteredData = response.filter(file => {
                if(file.filesize == null || file.id == null || file.filesize == "0")
                    return false;
                else
                    return true;
            });

            this.setState({entity_attachments: filteredData});
        }); 
    }

    uploadFiles = (evt) => {
        const t = this;
        const { id, targetModule } = this.props.data;
        const _this = this;
        const { taimerAccount: { attachmentMaxSize } } = this.context;
        const { enqueueSnackbar } = this.props;

        evt.stopPropagation();
        evt.preventDefault();
        const files = Array.from(evt.target.files || evt.dataTransfer.files);

        const url = targetModule + '/' + id + '/attachments';

        let totalSize = 0;

        files.forEach(function(file){
            totalSize += file.size;
        });

        if (totalSize > attachmentMaxSize) {
            enqueueSnackbar(this.tr('Selected file is too large'), {
                variant: "error"
            });
            return;
        }
        
        files.forEach(function(file){
            DataHandler.file({url: url}, file, undefined, e => {
                if (e.responseJSON === false) {
                    enqueueSnackbar(this.tr('Uploading file failed'), {
                        variant: "error"
                    });
                    return;
                }
                
                _this.getAttachments();
            });
        });
    }

    deleteAttachment = (id) => {
        const entity_attachments = cloneDeep(this.state.entity_attachments);
        const { targetModule } = this.props.data;

        DataHandler.delete({url: `attachments/${id}`})
        .done(resp => {
            entity_attachments.splice(entity_attachments.findIndex(e => e.id == id), 1);
            this.setState({entity_attachments});
        })
        .fail(data => {

        });

        this.closeDeleteDialog();
    };    

//

    onChange(e) {
        const val = e.target.value;
        this.setState((prevState) => {
            const paid = (prevState.paid-0) + (val-0);
            return {paid: paid,
                    payment: (val-0),
                    left: (this.props.data.total-0) - paid};
        });
    }

    handleCancel() {
        this.props.onDialogClose();
    }

    handleClose() {
        this.props.onDialogSave(this.state.attachments);
    }    

    openAttachmentDeleteDialog = (attachment) => {
        const data = {
            confirmFunc: () => this.deleteAttachment(attachment.id),
            header: this.tr("Delete attachment?"),
            warning: this.tr("Are you sure you want to delete attachment: ${name}?", {name: attachment.filename})
        }

        this.setState({ showDeleteConfirmation: true, deleteDialogData: data });
    }

    closeDeleteDialog = () => {
        this.setState({ 
            showDeleteConfirmation: false, 
            deleteDialogData: undefined
        })
    }

    renderDeleteDialog = () => {
        return (
            <MassDialog
                onDialogClose={this.closeDeleteDialog}
                onDialogSave={this.state.deleteDialogData.confirmFunc}
                dialogType={"delete"}
                dialogProps={{
                    confirmButtonText: this.state.deleteDialogData.confirmButtonText,
                    onCloseClick: this.closeDeleteDialog,
                    open: this.state.deleteDialogOpen,
                    close: this.closeDeleteDialog,
                    header: this.state.deleteDialogData.header,
                    warning: () => {
                        return this.state.deleteDialogData.warning;
                    },
                    onConfirm: () => {
                       this.state.deleteDialogData.confirmFunc();
                    }
                }}
            />
        )
    }

    render() {

        const { data } = this.props;

        const rowProps = {
            // handleDelete: this.deleteAttachment,
            handleDelete: attachment => {
                this.openAttachmentDeleteDialog(attachment);
            },
            handleView: row => {
                const params = {
                    id: row.id,
                    attachment: "attachment",
                    _auth: this.context.functions.getStorage().taimerToken,
                };
 
                window.open (this.context.functions.getDownloadPath() + $.param(params));
            }
        };
        
        const columns = [
            { name: "context", header: "", width: 60, showMenu: false, resizeable: false, moveable: false, hideable: false },
            { name: "filename", header: this.tr("Filename"), width: 200, showMenu: false },
            { name: "size", header: this.tr("Size"), width: 60, showMenu: false, resizeable: false, moveable: false, hideable: false },
            // { name: "createdby", header: "Added by", width: 60 },
        ];

        return (
            <React.Fragment>
                <Dialog
                    open
                    maxWidth="sm"
                    aria-labelledby="attachments" >
                    <DialogContent className="invoiceDialogContent" ref={cont => this.container = cont}>
                        <div className="expenseDialogHeader">
                            <button className="expenseDialogClose" onClick={this.handleCancel}>&times;</button>
                        </div>
                        <div className="expenseDialogAdd-file" onDragOver={this.onDragOver} onDrop={this.uploadFiles}>
                            <div>
                                <CloudDownloadOutlined /><br/>
                                <span onClick={() => this.upload.current.click()}>{this.tr("Choose a file")}</span> <br/> {this.tr("or drag it here")}
                                <input ref={this.upload} onChange={this.uploadFiles} type="file"  multiple />
                            </div>
                        </div>
                        <div className="connect-options">
                            <div>{this.tr("Connect files to the cloud")}</div><br/>
                            {this.tr("Allow your Taimer account to access your cloud storage. Click on icon to connect")}
                        </div>                
                        <List fluid columns={columns} height="240" fluid data={this.state.entity_attachments.sort((a, b) => {
                            let af = a.filename.toLowerCase();
                            let bf = b.filename.toLowerCase();

                            if(af < bf)
                                return -1;
                            if(af > bf)
                                return 1;

                            return 0;
                        })} listRowType={AttachmentsRow} rowProps={rowProps} />
                    </DialogContent>
                    <DialogActions>
                        <Button size="large" variant="outlined" onClick={this.handleCancel} >
                            {this.tr("Close")}
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.showDeleteConfirmation && this.renderDeleteDialog()}
            </React.Fragment>
        );
    }
}

HandleRowAttachments.defaultProps = {
    onDialogClose: () => {},
    onDialogSave: () => {}
}

export default withSnackbar(HandleRowAttachments);
