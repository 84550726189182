import React from 'react';
import PropTypes from "prop-types";

/* material ui */
import { Button } from '@mui/material';

/* local components */
import TaimerComponent from "../../TaimerComponent";
import OutlinedField from "../OutlinedField";

/* css */
import './AddProjectType.css';

/* data backend */
import DataHandler from '../DataHandler';
 
class AddProjectType extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "general/no-options/AddProjectType");
 
        ["addNew"].forEach(e => this[e] = this[e].bind(this));
    }
    
    addNew () {
        const { selectProps } = this.props;
        const company = selectProps.company || this.context.userObject.companies_id;
        
        const newEntity = {
            name: selectProps.inputValue,
            types: "default",
        };
        
        DataHandler.post({url: 'subjects/project_types/' + company}, newEntity).done(e => {
            selectProps.typeCreated && selectProps.typeCreated(e[0]);
            this.props.setValue();
        });
    }
    
    render () {
        if (this.props.selectProps.inputValue == '')
            return <div className="add-project-type"><div>{this.tr("No project types")}</div></div>;
        
        return (
            <div className="add-project-type">
                <div><span className="primary">{this.props.selectProps.inputValue}</span> {this.tr('is not in project types yet.')}</div>
                <div className="details">
                    <OutlinedField label={this.tr("Project Type")} value={this.props.selectProps.inputValue} disabled fullWidth />
                </div>
                <div className="controls">
                    <Button size="large" color="primary" onClick={this.addNew}>{this.tr('Add a New Project Type')}</Button>
                </div>
            </div>
        );
    }
    
}

export default AddProjectType;