import React from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
/*context*/
import { SettingsContext } from '../SettingsContext';

import WithTabs from '../navigation/WithTabs';

/* local components */
import OutlinedField from '../general/OutlinedField';
/*material-ui*/
import ArchiveIcon from '@mui/icons-material/Archive';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import { Button, MenuItem } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { differenceInCalendarDays, format, isValid } from 'date-fns';

import CoreDialog from "../dialogs/mass_operations/CoreDialog";
import CurrencyListCell from '../list/CurrencyListCell';
import List from '../list/List';
import ListCell from '../list/ListCell';
import PropsOnlyListRow from '../list/PropsOnlyListRow';
import SimpleList from '../list/SimpleList';
import DateCell from '../list/cells/DateCell';
import TextInputCell from '../list/cells/TextInputCell';

import MoreHoriz from '@mui/icons-material/MoreHoriz';
import moment from 'moment';
import ContextMenu from '../general/ContextMenu';

import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';

import Link from '../general/Link';
import Utils from './../general/Utils';

import WorkWeekTemplateList from '../list/lists/WorkWeekTemplateList';

import '../list/rows/AlternativeRow.css';

/*datahandler*/
import DataHandler from '../general/DataHandler';

/*other*/
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import TaimerComponent from '../TaimerComponent';
import ToggleableContainerList from '../general/ToggleableContainerList';
import NoPermissionOverlay from './../list/overlays/NoPermissionOverlay';

import { CopyAll, Email, EmojiEvents, Phone } from '@mui/icons-material';
import Skeleton from 'react-loading-skeleton';
import colors from '../colors';
import contactCardStyles from '../contacts/TabPersonalDetails.module.scss';
import SliderFieldGroup from '../general/SliderFieldGroup';
import StatusTag from '../general/StatusTag';
import TaimerAvatar from '../general/TaimerAvatar';
import VersionContentManager from '../general/VersionContentManager';
import cardStyles from '../general/styles/CardStyles.module.scss';

class CostHistoryRow extends PropsOnlyListRow {
    constructor(props, subtypeState = {}, rowTypeConfig = {}) {
        super(props);
    }

    saveNew = () => {
        if (this.state.invalids.length > 0) {
            return;
        }
        this.setInvalidFields();
        this.create(this.state.data).then((response) => {
            this.setState({ data: response });
        });
    };

    defineClassName() {
        return 'users-cost-history-row';
    }

    defineCells() {
        const data = this.props.data;
        const cells = {
            context:
                data.id > 0 ? (
                    <ContextMenu
                        label={<MoreHoriz />}
                        buttonProps={{ className: 'action-menu' }}
                        className="cell row-menu"
                        width={this.props.columnWidthMap['context']}
                        style={{ width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + ' 1 0px' }}
                        noExpandIcon
                    >
                        <MenuItem
                            className="delete"
                            onClick={() => {
                                this.delete();
                            }}
                        >
                            <RemoveIcon className="Delete" />
                            {this.tr('Delete')}
                        </MenuItem>
                    </ContextMenu>
                ) : (
                    <ListCell width={this.props.columnWidthMap.context} permanentEditMode={true} onlyDisplay={true}>
                        <CheckCircle titleAccess="Save" className="saveNewRowCheckCircleIcon" onClick={() => this.saveNew()} />
                        <Cancel titleAccess="Cancel" className="cancelIcon" onClick={() => this.delete(this.state.data)} />
                    </ListCell>
                ),
            date: (
                <DateCell
                    editable={true}
                    width={this.props.columnWidthMap['crm_created']}
                    value={data.date}
                    name="date"
                    usePopper
                    listCellProps={{
                        className: 'editAlignLeft',
                    }}
                    onEdit={(n, date) => {
                        data.id < 1 ? this.setData(n, date) : this.setDataAndUpdate(n, date);
                    }}
                />
            ),
            cost: (
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{
                        currency: this.props.rowProps.currency,
                        maximumFractionDigits: 4,
                    }}
                    value={data.cost}
                    editable={true}
                    validation={['numeric']}
                    onEdit={(value) => {
                        if (isNaN(value)) {
                            this.setInvalidFields(['cost']);
                            return;
                        }
                        this.setInvalidFields();
                        data.id < 1 ? this.setData('cost', value) : this.setDataAndUpdate('cost', value);
                    }}
                />
            ),
        };
        return cells;
    }
}

class HourBalanceManagementRow extends PropsOnlyListRow {
	constructor(props, context) {
        super(props, {}, {}, "list/rows/HourBalanceManagementRow");
    }

    onEdit = (a, b) => {
        b === undefined 
            ? this.setData(a) 
            : this.setData(a, b);
    };

    openDeleteConfirmationDialog = () => {
        const {
            openDialog,
            closeDialog
        } = this.props.rowCallbacks;

        openDialog({
            header: this.tr("Delete entry"),
            confirmButtonClass: "blue",
            onConfirm: () => {
                this.delete();
                closeDialog();
            },
            onCancel: closeDialog,
            onCloseClick: closeDialog,
            warning: () => {
                return (
                    <React.Fragment>
                        <p>{this.tr("Are you sure you want to delete this hour balance management entry?")}
                        </p>
                    </React.Fragment>
                );
            },
            confirmButtonText: this.tr("Yes"),
            cancelButtonText: this.tr("Cancel"),
        });
    };

    defineClassName() {
        return 'alternativeRow';
    }

    defineCells() {
        const { 
            data, 
            attributes, 
            listRef
        } = this.props;
        const { 
            tr 
        } = this;

        const listCellProps = {
            noBorder: true,
            noInitFocus: true,
        };

        return {
            save: (
                data.id < 0 || attributes?.rowInEditMode
                ? <ListCell permanentEditMode={true}>
                    {(attributes?.rowInEditMode || data.id < 0 || false) && (
                        <Tooltip title={this.tr('Save')} placement="bottom">
                            <CheckCircle
                                className="saveNewRowCheckCircleIcon"
                                onClick={() => {
                                    data.id < 0 
                                        ? this.create()
                                        : this.exitEditMode();
                                }}
                            />
                        </Tooltip>
                    )}
                </ListCell>
                : <ListCell 
                    inEditMode={true}
                    noBorder
                    style={{ background: "transparent" }}>
                    <ContextMenu 
                        popperProps={{ disablePortal: false }}
                        className="cell row-menu"
                        buttonProps={{ className: 'action-menu' }}
                        label={<ContextMenuIcon />}
                        noExpandIcon>
                            <MenuItem className="workWeekTemplateRow__deleteMenuItem" onClick={() => {
                                this.openDeleteConfirmationDialog();
                            }}><ArchiveIcon className="workWeekTemplateRow__contextMenuIcon red" /><span>{tr("Delete")}</span></MenuItem>
                        </ContextMenu>
                </ListCell>
            ),
            delete: (
                <ListCell permanentEditMode={true}>
                    {(attributes?.rowInEditMode || data.id < 0 || false) && (
                        <Tooltip title={this.tr('Cancel')} placement="bottom">
                            <Cancel
                                className="cancelIcon"
                                onClick={() => {
                                    data.id < 0 ? listRef.removeRow(data.id) : this.cancel();
                                }}
                            />
                        </Tooltip>
                    )}
                </ListCell>
            ),
            date: (
                <DateCell
                    value={data.date}
                    editable={false}
                    listCellProps={listCellProps}
                    offsetCalendar={true}
                    openOnEnterEditMode={false}
                    closeOnComplete={false}
                    closeCalendarOnComplete={true}
                    usePopper={true}
                    popperBottom={true}
                ></DateCell>
            ),
            paydate: (
                <DateCell
                    name="paydate"
                    value={data.paydate}
                    onEdit={this.onEdit}
                    listCellProps={listCellProps}
                    offsetCalendar={true}
                    openOnEnterEditMode={false}
                    closeOnComplete={false}
                    closeCalendarOnComplete={true}
                    usePopper={true}
                    popperBottom={true}
                ></DateCell>
            ),
            hours: <TextInputCell 
                value={Number(data.hours).toFixed(2)} 
                name="hours" 
                onEdit={value => {
                    value = value.replaceAll(",", ".");

                    this.onEdit("hours", value);
                }} 
                onAlternativeRow={true} 
                listCellProps={listCellProps} />,
            description: <TextInputCell 
                value={data.description} 
                name="description" 
                onEdit={this.onEdit} 
                onAlternativeRow={true} 
                listCellProps={listCellProps} />
        };
    }
}

class TimeTrackingTab extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'users/TimeTrackingTab');

        this.state = {
            hourBalanceManagement: [],
            hourBalance: { balance: 0, overtime_balance: 0 },
            dialogOpen: false,
            dialogProps: {}
        };

        this.hourBalanceManagementList = React.createRef();

        this.initFetch();
    }

    openDialog = (dialogProps) => {
        this.setState({ 
            dialogOpen: true,
            dialogProps: dialogProps
        });
    };

    closeDialog = () => {
        this.setState({ 
            dialogOpen: false,
            dialogProps: {}
        });
    };

    initFetch = () => {
        const { company, id } = this.props.masterProps;

        Promise.all([
            DataHandler.get({ url: "timetracker/workhourbalances", users: [id] }),
            DataHandler.post({ url: `settings/company/${company}/hour_balance_management`, user_id: id, locked: [0, -1, 1] })
        ]).then(([balance, balanceManagement]) => {
            this.setState({
                hourBalance: balance?.users?.[id] || { balance: 0, overtime_balance: 0 },
                hourBalanceManagement: balanceManagement?.managementData || [],
            });
        });
    };

    refreshHourBalance = () => {
        const { id } = this.props.masterProps;

        DataHandler.get({ url: "timetracker/workhourbalances", users: [id] }).then(resp => {
            this.setState({
                hourBalance: resp?.users?.[id] || { balance: 0, overtime_balance: 0 },
            });
        });
    };

    createUpdateHourBalanceManagementEntry = (data, row, list) => {
        const { id, company } = this.props.masterProps;

        const d = { ...data };
        d.users_id = id;

        DataHandler.put({ url: `settings/company/${company}/hour_balance_management` }, d).then((r) => {
            row.editData({ id: r.id });
        }).then(this.refreshHourBalance);
    };

    deleteEntry = (data, row, list) => {
        const { 
            id, 
            company 
        } = this.props.masterProps;

        const d = {
            ...data,
            deleted: 1,
            users_id: id
        };

        DataHandler.put({ url: `settings/company/${company}/hour_balance_management` }, d).then((r) => {
            list.removeRow(data.id);
        }).then(this.refreshHourBalance);
    };

    render() {
        const { 
            masterProps, 
            masterState 
        } = this.props;
        const { 
            id, 
            company 
        } = masterProps;

        const { 
            hourBalanceManagement,
            dialogOpen,
            dialogProps,
            hourBalance
        } = this.state;

        const { tr } = this;

        const commonConf = {
            sortable: false,
            showMenu: false,
            resizeable: false,
            showResizeMarker: false,
            moveable: false,
            hideable: false,
            visibleInToolbar: false,
        };

        const isFreelancer = parseInt(masterState?.data?.companyId) === 0;

        return (
            <React.Fragment>
                <div className={contactCardStyles.timeTracking}>
                    <div>
                        <h3>{this.tr('Employment type & working hours')}</h3>
                    </div>
                    <div>
                        {!isFreelancer && <p>
                            <span>{tr("Hour balance")}: <strong>{`${hourBalance?.balance || 0} h`}</strong></span>
                            <span>{tr("overtime balance")}: <strong>{`${hourBalance?.overtime_balance || 0} h`}</strong></span>
                        </p>}
                    </div>
                </div>
                <div className={contactCardStyles.listContainer}>
                    <WorkWeekTemplateList
                        companyId={company}
                        userId={id}
                        mode="user_view"
                        enqueueSnackbar={masterProps.enqueueSnackbar}
                        excludeFields={[
                            'is_default', 
                            'template_name', 
                            isFreelancer ? 'template_id' : null,
                            isFreelancer ? 'count_balance' : null,
                        ].filter((f) => f)}
                        style={{ paddingTop: 8 }}
                        afterRequests={this.refreshHourBalance}
                    />
                </div>
                <div className={contactCardStyles.listContainer}>
                    <h3>{this.tr('Manage hour balance')}</h3>
                    <Button
                        className="green"
                        size="large"
                        onMouseUp={() => {
                            this.hourBalanceManagementList.current.addNewRow();
                        }}
                        style={{
                            width: 173,
                            marginBottom: 12,
                            marginLeft: 24,
                        }}
                    >
                        {tr('Add a row')}
                    </Button>
                    <List
                        alternativeRows={true}
                        ref={this.hourBalanceManagementList}
                        data={hourBalanceManagement}
                        noStateData={true}
                        editMode="row"
                        fluid={true}
                        height="auto"
                        listRowType={HourBalanceManagementRow}
                        sharedData={{
                            users: this.state.users,
                        }}
                        newRow={{
                            date: Utils.today(),
                            hours: 0,
                            paydate: Utils.today(),
                            description: '',
                        }}
                        columns={[
                            { name: 'save', width: 2, ...commonConf, showMenu: false },
                            { name: 'delete', width: 2, ...commonConf },
                            { width: 3, name: 'hours', header: tr('Hours'), ...commonConf },
                            { width: 5, name: 'date', header: tr('Date'), ...commonConf },
                            { width: 5, name: 'paydate', header: tr('Change date'), ...commonConf },
                            { width: 22, name: 'description', header: tr('Description'), ...commonConf },
                        ]}
                        rowCallbacks={{
                            onCreate: this.createUpdateHourBalanceManagementEntry,
                            onUpdate: this.createUpdateHourBalanceManagementEntry,
                            onDelete: this.deleteEntry,
                            openDialog: this.openDialog,
                            closeDialog: this.closeDialog
                        }}
                    />
                </div>
                {dialogOpen && <CoreDialog 
                    dialogType="delete" 
                    dialogProps={dialogProps}
                    open={dialogOpen}
                />}
            </React.Fragment>
        );
    }
}

class DimensionTeamHistoryTab extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'users/DimensionTeamHistoryTab');
    }

    render() {
        const { history, data, dimensionTeams } = this.props.masterState;

        const historyCells = [
            // { name: "duration", header: this.tr("Duration"), width: 40, resizeable: false, moveable: false, sortable: false },
            // { name: "field_name", header: this.tr("Field name"), width: 40, resizeable: false, moveable: false, sortable: false },
            { name: 'old_value', header: '', width: 30, resizeable: false, moveable: false, sortable: false, hideable: false, showMenu: false, showResizeMarker: false },
            { name: 'arrow', width: 5, showMenu: false, resizeable: false, moveable: false, sortable: false, hideable: false, showResizeMarker: false },
            { name: 'value', header: '', width: 30, resizeable: false, moveable: false, sortable: false, hideable: false, showMenu: false, showResizeMarker: true },
            { name: 'date', header: this.tr('Changed at'), width: 60, resizeable: false, moveable: false, sortable: false, hideable: false, showMenu: false },
            // { name: "user", header: this.tr("Edited by"), width: 100, resizeable: false, moveable: false, sortable: false },
        ];

        return (
            <div className={contactCardStyles.listContainer}>
                <h3>{this.tr("User's team history")}</h3>

                <SimpleList
                    data={history
                        .filter((row) => {
                            return row.field === 'dimension_team';
                        })
                        .map((row) => {
                            row._type = row.previous_value ? 'default' : 'first';

                            return row;
                        })}
                    visible={10}
                    rowHeight={30}
                    listProps={{
                        fluid: true,
                        height: 300,
                        noColorVariance: true,
                        ignoreRowPropsChange: false,
                        sharedData: {
                            currentTeamId: data.dimension_teams_id,
                            teams: dimensionTeams,
                            fieldTranslations: {
                                dimension_teams_id: this.tr('Team'),
                                firstname: this.tr('First name'),
                                lastname: this.tr('Surname'),
                            },
                        },
                    }}
                    rowConfiguration={{
                        default: {
                            definesHeader: true,
                            cells: historyCells,
                            presentation: {
                                date: (data) => format(new Date(data.datetime), this.context.userObject.datetimeFormat),
                                duration: (data) => (data.duration ? moment.duration(data.duration, 'seconds').humanize() : '-'),
                                field_name: (data, props, listRef, sharedData) => {
                                    const { fieldTranslations } = sharedData;

                                    return fieldTranslations[data.field];
                                },
                                old_value: (data, props, listRef, sharedData) => {
                                    // const { teams } = sharedData;
                                    // let value = data.field === 'dimension_team' ? teams.find((t) => t.id === data.previous_value)?.name : data.previous_value;

                                    return [data.previous_value, { align: 'right' }];
                                },
                                arrow: (data) => [
                                    <ArrowRightAltIcon />,
                                    {
                                        cellValueStyle: { padding: 0 },
                                        align: 'center',
                                    },
                                ],
                                value: (data, props, listRef, sharedData) => {
                                    const { value } = data;

                                    return data.is_current
                                        ? <u>{value}</u>
                                        : value;

                                    // const { teams } = sharedData;
                                    // let value;

                                    // if (data.field === 'dimension_team') {
                                        // value = teams.find((t) => t.id === data.value)?.name;
                                        // value = data.is_current ? <u>{value}</u> : value;
                                    // } else {
                                        // value = data.value;
                                    // }

                                    // return value;
                                },
                                user: (data) => data.user,
                            },
                        },
                        first: {
                            cells: (mainOrder, widthMap) => {
                                const cells = historyCells.filter((c) => {
                                    return c.name === 'date' || c.name === 'value';
                                });

                                const width = ['old_value', 'arrow', 'value'].map((n) => widthMap[n]).reduce((acc, cur) => acc + cur);

                                cells.find((c) => c.name === 'value').width = width;

                                return cells;
                            },
                            presentation: {
                                date: (data) => {
                                    return format(new Date(data.datetime), this.context.userObject.datetimeFormat);
                                },
                                value: (data, props, listRef, sharedData) => {
                                    return [
                                        data.value,
                                        { align: 'center' },
                                    ];
                                },
                            },
                        },
                    }}
                />
            </div>
        );
    }
}

class CostHistoryTab extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'users/CostHistoryTab');

        this.costHistoryList = React.createRef();
    }

    render() {
        const { costHistory, currency } = this.props.masterState;
        const { id, fetchData } = this.props.masterProps;

        return (
            <div className={contactCardStyles.listContainer}>
                <h3>{this.tr("User's internal hourly cost")}</h3>
                <List
                    ref={this.costHistoryList}
                    height={200}
                    userListSettingsKey="user_cost_history"
                    saveColumnConfig={true}
                    row
                    columns={[
                        {
                            field: 'context',
                            name: 'context',
                            header: '',
                            columnHeaderType: 'roundButton',    
                            width: 80,
                            showMenu: false,
                            resizeable: false,
                            moveable: false,
                            hideable: false,
                            showResizeMarker: false,
                        },
                        { field: 'date', name: 'date', header: this.tr('Starting'), width: 100, resizeable: true, moveable: true },
                        { field: 'cost', name: 'cost', header: this.tr('Internal cost'), width: 100, resizeable: true, moveable: true },
                    ]}
                    data={costHistory}
                    newRow={{
                        date: new Date(),
                        cost: 0,
                    }}
                    listRowType={CostHistoryRow}
                    noStateData={true}
                    rowProps={{
                        onDelete: (data) => {
                            if (data.id > 0) {
                                return DataHandler.delete({ url: 'users/' + id + '/cost_history' }, data).then(() => fetchData());
                            } else {
                                this.costHistoryList.current.removeNewRow(data.id);
                            }
                        },
                        onCreate: (data) => {
                            return DataHandler.post({ url: 'users/' + id + '/cost_history' }, data).then(() => fetchData());
                        },
                        onUpdate: (data) => {
                            return DataHandler.post({ url: 'users/' + id + '/cost_history' }, data).then(() => fetchData());
                        },
                        currency: currency,
                    }}
                />
            </div>
        );
    }
}

export const random_password_generate = (maxLength, minLength) => {
    let passwordChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz#@!%&';
    let randPwLen = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
    let randPassword = '';

    let crypto = window.crypto || window.msCrypto;
    if (crypto !== undefined) {
        randPassword = Array(randPwLen)
            .fill(passwordChars)
            .map((x) => x[Math.floor((crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) * x.length)])
            .join('');
    } else {
        randPassword = Array(randPwLen)
            .fill(passwordChars)
            .map((x) => x[Math.floor(Math.random() * x.length)])
            .join('');
    }
    return randPassword;
};
class TabPersonalDetails extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = { showLockedUsersWithTag: true };

    constructor(props, context) {
        super(props, context, 'users/TabPersonalDetails');

        this.costHistoryList = React.createRef();
        this.showUserNameInHeader = false;

        this.detailsSections = [
            {
                key: 'details',
                title: this.tr('User details'),
                initiallyOpen: true,
            },
            {
                key: 'password',
                title: this.tr('Password'),
            },
        ];

        this.initialState = {
            data: {
                companyId: props.company || context.functions.getCompany('admin', 'admin'),
                supervisorId: 0,
                employmentStartdate: format(new Date(), 'YYYY-MM-DD'),
                employmentEnddate: '',
                balanceStartdate: format(new Date(), 'YYYY-MM-DD'),
                overtime_balancestart: '',
            },
            validationErrors: {
                email: false,
                firstname: false,
                lastname: false,
            },
            history: [],
            users: [],
            permissionGroups: [],
            permissionGroupIds: [],
            protitles: [],
            teamGroups: [],
            teamGroupIds: [],
            costHistory: [],
            dimensionTeams: [],
            newPassword: '',
            confirmPassword: '',
            submitted: false,
        };

        const initialStateParams = _.cloneDeep(this.initialState);

        this.state = {
            ...initialStateParams,
            companies: [],
            currency: 'EUR',
            loading: false,
            success: false,
            initialFetchDone: false,
            selectedTab: 'time_tracking',
            tabs: [],
        };

        this.tabContent = {
            time_tracking: <TimeTrackingTab />,
            cost_history: <CostHistoryTab />,
            dimension_team: <DimensionTeamHistoryTab />,
        };

        this.userTypes = [
            { id: '1', name: this.tr('user').charAt(0).toUpperCase() + this.tr('user').slice(1) },
            { id: '2', name: this.tr('freelancer').charAt(0).toUpperCase() + this.tr('freelancer').slice(1) },
        ];

        this.translations = {
            locked: this.tr('locked'),
            freelancer: this.tr('freelancer'),
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id && Number(this.props.id) < 0) {
            this.setState(
                {
                    ...this.initialState,
                    data: {
                        ...this.initialState.data,
                        companyId: this.state.data.companyId,
                    },
                },
                () => this.fetchData()
            );
        }
    }

    fetchData = () => {
        const { id } = this.props;
        const { companyId } = this.state.data;

        this.fetchGroups(companyId);

        DataHandler.get({ url: 'subjects/companies/admin/admin' }).done((companies) => this.setState({ companies }));

        if (Number(id) < 1) {
            DataHandler.request('GET', { url: `settings/company/${companyId}/defaults` }).done((response) => {
                this.setState({ currency: response.currency || this.state.currency });
            });

            return;
        }

        DataHandler.request('GET', { url: 'users/' + id })
            .done((response) => {
                if (response.user?.cognito_id?.length > 0)
                    this.detailsSections = this.detailsSections.filter(e => e.key !== 'password')
                
                DataHandler.request('GET', { url: `settings/company/${response.user.companyId}/defaults` }).done((response) => {
                    this.setState({ currency: response.currency || this.state.currency });
                });

                const company = response.user.companyId == 0 ? '' : response.user.companyId;
                DataHandler.get({ url: 'subjects/employees/' + company }).done((response) => {
                    const userTagProps = {
                        fields: { name: 'name' },
                        showLocked: this.props.showLockedUsersWithTag,
                        transl: this.translations,
                    };                    
                    response.forEach((p, i) => {
                        response[i] = { ...Utils.showLockedAndFreelancerUserTag(p, userTagProps) };
                    });
                    this.setState({ users: response });
                });
                this.setState(
                    {
                        data: response.user,
                        permissionGroupIds: response.permissionGroupIds,
                        teamGroupIds: response.teamGroupIds,
                        costHistory: response.cost_history,
                        history: response.history,
                        initialFetchDone: true,
                    },
                    this.determineTabs
                );
                if (response.user.companyId !== companyId) this.fetchGroups(response.user.companyId == 0 ? 'freelancer' : response.user.companyId);
            })
            .fail(() => {
                this.setState({ error: true });
            });
    };

    fetchHistory = async () => {
        try {
            this.setState({
                history: await DataHandler.get({ url: `users/${this.props.id}/history` }),
            });
        } catch (e) {
            console.warn(e);
        }
    };

    fetchGroups = async (companyId) => {
        const permissionGroups = await DataHandler.get({ url: `settings/usergroups/${companyId}` });
        const teamGroups = await DataHandler.get({ url: `settings/usergroups/${companyId}`, type: 'teams' });
        const protitles = await DataHandler.get({ url: `settings/company/${companyId}/protitles` });
        const dimensionTeams = await DataHandler.get({ url: `subjects/dimensions/teams/${companyId}` });

        this.setState({
            permissionGroups,
            teamGroups: teamGroups,
            protitles: protitles,
            dimensionTeams: dimensionTeams,
        });
    };

    determineTabs = () => {
        const { data } = this.state;
        const { addons } = this.context;

        const companyId = parseInt(data.companyId);

        const tabs = [
            {
                id: 'time_tracking',
                label: this.tr('Time tracking'),
                upgrade: VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'timeTracking'),
                hidden: VersionContentManager.isFeatureHidden(this.namespace, 'timeTracking'),
            },
            {
                id: 'cost_history',
                label: this.tr('Cost history'),
                hidden: companyId == 0 || VersionContentManager.isFeatureHidden(this.namespace, 'internalCost'),
                upgrade: VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'internalCost')
            },
            {
                id: 'dimension_team',
                label: this.tr('Dimension team history'),
                hidden: companyId == 0 || (addons?.dimensions?.used_by_companies || []).map((c) => parseInt(c)).indexOf(companyId) == -1,
            },
        ];

        this.setState({ tabs });
    };

    onUserEdited = (user) => {
        const update = {
            data: user,
            permissionGroupIds: user.permissionGroupIds.map((p) => ({ groups_id: p.id || p.groups_id })),
            teamGroupIds: user.teamGroupIds.map((t) => ({ groups_id: t.id || t.groups_id })),
        };
        this.setState(update, () => {
            this.updateUser(user.id, user, true);
        });
    };

    updateUser = (id, data, saveGroups = false) => {
        const { enqueueSnackbar } = this.props;
        if (!this.state.initialFetchDone) {
            enqueueSnackbar(this.tr('Update failed!'), { variant: 'error' });
            return;
        }

        const promises = [DataHandler.put({ url: 'users/' + id }, data)];

        if (saveGroups) {
            promises.push(
                DataHandler.put(
                    { url: `users/${id}/permissionGroups` },
                    { groupId: [...data.permissionGroupIds.map((p) => p.id || p.groups_id), ...data.teamGroupIds.map((t) => t.id || t.groups_id)], deleteBeforeInsert: true }
                )
            );
        }

        Promise.all(promises)
            .then(() => {
                setTimeout(() => {
                    this.fetchData();
                }, 1000);
            })
            .catch((err) => {
                this.showError(err);
            });
    };

    showError = (resp) => {
        const { enqueueSnackbar } = this.props;
        let message = this.tr('Error in saving!');

        if (resp.status == 422) {
            let invalidEmail = false;
            if (resp.responseJSON.email) {
                message = this.tr('Invalid email!');
                invalidEmail = true;
            } else if (resp.responseJSON.error && (resp.responseJSON.error === 'EMAIL_IN_USE' || resp.responseJSON.error === 'EMPTY_EMAIL_NOT_ALLOWED')) {
                message = this.tr(resp.responseJSON.msg);
                invalidEmail = true;
            }

            if (invalidEmail) {
                let validationErrors = this.state.validationErrors;
                validationErrors.email = true;
                this.setState({ validationErrors: validationErrors });
            }
        }
        enqueueSnackbar(message, { variant: 'error' });
    };

    updatePassword = ({ target: { name, value } }) => {
        this.setState({ [name]: value });
    };

    savePassword = async () => {
        const { data, newPassword, confirmPassword } = this.state;
        const { id, enqueueSnackbar } = this.props;
        const companyId = this.state.data.companyId;

        const checks = {
            confirmError: false,
        };
        if (newPassword !== confirmPassword) checks.confirmError = true;
        if (checks.confirmError) {
            enqueueSnackbar(this.tr('Passwords did not match!'), {
                variant: 'error',
            });
            const validationErrors = { ...this.state.validationErrors, newPassword: true };
            this.setState({ success: false, submitted: true, validationErrors });
            return;
        } else {
            try {
                const reply = await DataHandler.post(
                    { url: `settings/user/${id}/changepassword` },
                    {
                        newpassword: newPassword,
                        company: companyId,
                    }
                );
                if (reply.status === 'OK') {
                    enqueueSnackbar(this.tr('Password changed successfully!'), {
                        variant: 'success',
                    });
                    const validationErrors = { ...this.state.validationErrors, newPassword: false };

                    this.setState({
                        newPassword: '',
                        confirmPassword: '',
                        success: true,
                        submitted: false,
                        validationErrors,
                    });
                }
            } catch (error) {
                enqueueSnackbar(this.tr('Password must be at least 4 characters!'), {
                    variant: 'error',
                });
                this.setState({ success: false, submitted: true });
            }
        }
        this.setState(checks);
    };

    onChange = async (e) => {
        const { data, validationErrors, newPassword, confirmPassword } = this.state;
        const { id } = this.props;
        let { name, value } = e.target;

        if (e.target.type === 'checkbox') {
            value = e.target.checked ? '1' : '0';
        }

        if (data[name] == value) return;

        if (typeof validationErrors[name] != 'undefined' && value == '') {
            validationErrors[name] = true;
            this.setState({ validationErrors });
        } else if (typeof validationErrors[name] != 'undefined' && value != '') {
            validationErrors[name] = false;
            this.setState({ validationErrors });
        }

        if (name == 'type') {
            data.companyId = value == 2 ? 0 : data.companyId || 1;
            this.fetchGroups(data.companyId == 0 ? 'freelancer' : data.companyId);
        }

        data[name] = value;
        this.setState({ data }, () => name === 'companyId' && this.fetchData());

        if (id > 0 && !_.find(validationErrors, (e) => e == true)) this.updateUser(id, data);
    };

    getEmploymentString = () => {
        const { data: user, companies } = this.state;
        if (!user) return '';
        const employmentStart = new Date(user.employmentStartdate);
        const employmentLengthInDays = differenceInCalendarDays(new Date(), employmentStart);
        const years = Math.floor(employmentLengthInDays / 365);
        const months = Math.floor((employmentLengthInDays / 30) % (365 / 30));
        const days = employmentLengthInDays - (years * 365 + months * 30);
        let string = '${name} has been working with ${company} for ';
        const company = (companies || []).find(c => c.id == user.companyId)?.name || this.context.taimerAccount.name;
        let replacers = { name: user.firstname, company, years, months, days };
        let addStrings = [];
        if (employmentLengthInDays < 0) {
            return this.tr('${name} will start working at ${company} in the future.', replacers);
        }
        if (years > 0) {
            addStrings.push('${years} years');
        }
        if (months > 0) {
            addStrings.push('${months} months');
        }
        if (days > 0) {
            addStrings.push('${days} days');
        }
        addStrings.forEach((addString, i) => {
            string += addString;
            string += i == addStrings.length - 1 ? '.' : i == addStrings.length - 2 ? ' and ' : ', ';
        });
        return this.tr(addStrings.length == 0 ? '${name} has just started working with ${company}.' : string, replacers);
    };

    renderDetailsTopSection = () => {
        const { data: user } = this.state;
        if (!user) return null;
        const employmentStart = new Date(user.employmentStartdate);
        return (
            <div className={cardStyles.detailsTopSection}>
                {user.id > -1 && (
                    <div className={cardStyles.topRow}>
                        <div className={cardStyles.tags}>
                            <StatusTag text={user.locked == 1 ? this.tr('locked') : this.tr('Active')} color={user.locked == 1 ? colors.faded_red : colors.greenish_cyan} />
                            {(user.type == 2 || user.companyId == 0) && <StatusTag text={this.tr('Freelancer')} color={colors.bluey_purple} />}
                        </div>
                    </div>
                )}
                <div className={cardStyles.titleRow}>
                    {user.id > -1 && <TaimerAvatar size="large" id={user.id} name={`${user.firstname} ${user.lastname}`} />}
                    <div className={`${cardStyles.titles} ${!this.props.id ? cardStyles.noTopMargin : ''}`}>
                        <h1>{user.id == -1 ? this.tr('New user') : user.firstname ? `${user.firstname} ${user.lastname}` : <Skeleton />}</h1>
                        {user.id != -1 && user.title && <p>{user.title}</p>}
                        {isValid(employmentStart) && (
                            <div>
                                <EmojiEvents />
                                <p>{this.getEmploymentString()}</p>
                            </div>
                        )}
                    </div>
                </div>
                {user.id > -1 && (
                    <div className={`${cardStyles.keyInfo} ${cardStyles.iconSection}`}>
                        {user.phone && (
                            <div className={cardStyles.iconRow}>
                                <div className={cardStyles.icon}>
                                    <Phone />
                                </div>
                                <Link url={`tel:${user.phone}`}>{user.phone}</Link>
                                <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={this.tr('Copy to clipboard')} placement="right" arrow>
                                    <button
                                        onClick={() => {
                                            this.props.enqueueSnackbar(this.tr('Copied to clipboard!'), { variant: 'success' });
                                            navigator.clipboard.writeText(user.phone);
                                        }}
                                    >
                                        <CopyAll />
                                    </button>
                                </Tooltip>
                            </div>
                        )}
                        {user.email && (
                            <div className={cardStyles.iconRow}>
                                <div className={cardStyles.icon}>
                                    <Email />
                                </div>
                                <Link url={`mailto:${user.email}`}>{user.email}</Link>
                                <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={this.tr('Copy to clipboard')} placement="right" arrow>
                                    <button
                                        onClick={() => {
                                            this.props.enqueueSnackbar(this.tr('Copied to clipboard!'), { variant: 'success' });
                                            navigator.clipboard.writeText(user.email);
                                        }}
                                    >
                                        <CopyAll />
                                    </button>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    renderContentForDetailsSection = (section) => {
        const {
            data,
            validationErrors,
            users,
            permissionGroups,
            teamGroups,
            permissionGroupIds,
            teamGroupIds,
            dimensionTeams,
            protitles,
            newPassword,
            confirmPassword,
            submitted,
            success,
            companies,
            currency,
        } = this.state;
        const { id } = this.props;
        const {
            addons,
            taimerAccount: { isMulticompany },
        } = this.context;

        const fieldProps = {
            onChange: this.onChange,
            className: 'field',
            autoComplete: 'off',
        };
        const passwordProps = Object.assign({}, fieldProps, {
            onChange: this.updatePassword,
            type: 'password',
            error: submitted == true && success == false ? newPassword.length < 4 || validationErrors.newPassword : undefined,
            disabled: id < 1,
        });

        switch (section) {
            case 'details':
                return (
                    <SliderFieldGroup
                        items={[{ ...data, type: data.type || (data.companyId > 0 ? 1 : 2), permissionGroupIds, teamGroupIds }]}
                        editButtonLocation="top"
                        onItemSaved={this.onUserEdited}
                        sliderEditTitle={this.tr('Edit user')}
                        fields={[
                            {
                                key: 'companyId',
                                title: this.tr('Company'),
                                type: 'data_select',
                                options: companies.map((c) => ({ ...c, label: c.name, value: c.id })),
                                disabled: data.deletable < 1,
                                isHidden: () => !isMulticompany || Number(this.state.data?.companyId || 0) <= 0,
                            },
                            {
                                key: 'type',
                                title: this.tr('type'),
                                type: 'data_select',
                                options: this.userTypes.map((t) => ({ ...t, value: t.id, label: t.name })),
                                disabled: data.deletable < 1,
                                isHidden: (item, isPreview) => isPreview || VersionContentManager.isFeatureHidden(this.namespace, 'userType'),
                            },
                            {
                                key: 'firstname',
                                title: this.tr('First name'),
                                required: true,
                            },
                            {
                                key: 'lastname',
                                title: this.tr('Surname'),
                                required: true,
                            },
                            {
                                key: 'email',
                                title: this.tr('Email'),
                                validation: 'email',
                                disabled: data.cognito_id?.length > 0
                            },
                            {
                                key: 'phone',
                                title: this.tr('Phone'),
                                validation: 'phone',
                            },
                            {
                                key: 'title',
                                title: this.tr('Title'),
                            },
                            {
                                key: 'bankAccount',
                                title: this.tr('Bank account'),
                            },
                            {
                                key: 'position',
                                title: this.tr('Position'),
                            },
                            {
                                key: 'division',
                                title: this.tr('Division'),
                                disabled: true,
                                isHidden: () => !(this.context.addons && this.context.addons.division),
                            },
                            {
                                key: 'supervisorId',
                                title: this.tr('Supervisor'),
                                type: 'data_select',
                                options: users,
                                addNoneOption: true,
                                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'supervisor'),
                            },
                            {
                                key: 'dimension_teams_id',
                                title: this.tr('Dimension team'),
                                type: 'data_select',
                                options: dimensionTeams,
                                isHidden: () => (addons?.dimensions?.used_by_companies || []).indexOf(this.state?.data?.companyId) == -1,
                            },
                            {
                                key: 'protitle_groups_id',
                                title: this.tr('Professional Title'),
                                type: 'data_select',
                                options: protitles.map((p) => ({ ...p, label: p.name, value: p.id })),
                                addNoneOption: true,
                                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'professionalTitle'),
                            },
                            {
                                key: 'permissionGroupIds',
                                title: this.tr('Permission groups'),
                                type: 'select',
                                isMulti: true,
                                options: permissionGroups.map((p) => ({ ...p, label: p.name, value: p.id, groups_id: p.id })),
                                formatMultiValue: (value, options) => options.find((o) => o.id == value.groups_id),
                                strictMultiValue: true,
                                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'permissionGroups'),
                            },
                            {
                                key: 'teamGroupIds',
                                title: this.tr('Team'),
                                type: 'select',
                                isMulti: true,
                                options: teamGroups.map((t) => ({ ...t, label: t.name, value: t.id, groups_id: t.id })),
                                formatMultiValue: (value, options) => options.find((o) => o.id == value.groups_id),
                                strictMultiValue: true,
                                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'teams'),
                            },
                            {
                                key: 'administrative_group',
                                title: this.tr('Administrative group'),
                                disabled: true,
                                isHidden: () => !data.administrative_group,
                            },
                            {
                                key: 'employmentStartdate',
                                title: this.tr('Employment start date'),
                                type: 'date',
                                additionalProps: {
                                    noEmptyDates: true,
                                },
                            },
                            {
                                key: 'employmentEnddate',
                                title: this.tr('Employment end date'),
                                type: 'date',
                                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'employmentEndDate'),
                            },
                            {
                                key: 'overtime_balancestart',
                                title: this.tr('Overtime balance start date'),
                                type: 'date',
                                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'overtime'),
                            },
                            {
                                key: 'price',
                                title: this.tr('User Price / hour'),
                                validation: 'numeric',
                                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'hourlyPrice'),
                                additionalProps: {
                                    format: 'currency',
                                    currency,
                                    maximumFractionDigits: 4,
                                },
                            },
                            {
                                key: 'id',
                                title: this.tr('User ID'),
                                disabled: true,
                            },
                            {
                                key: 'creationdate',
                                title: this.tr('Created'),
                                type: 'date',
                                disabled: true,
                            },
                            {
                                key: 'activated_date',
                                title: this.tr('Activated'),
                                type: 'date',
                                disabled: true,
                            },
                            {
                                key: 'locked_date',
                                title: this.tr('Closed'),
                                type: 'date',
                                disabled: true,
                            },
                            {
                                key: 'modified_by',
                                title: this.tr('Modified by'),
                                disabled: true,
                            },
                            {
                                key: 'procountorId',
                                title: this.tr('Procountor ID'),
                                isHidden: () => !(this.context.addons && this.context.addons.procountor),
                            },
                            {
                                key: 'procountor_user_number',
                                title: this.tr('Procountor User Number'),
                                isHidden: () => !(this.context.addons && this.context.addons.procountor),
                            },
                            {
                                key: 'netvisorId',
                                title: this.tr('Netvisor ID'),
                                isHidden: () => !(this.context.addons && this.context.addons.netvisor),
                            },
                            {
                                key: 'mepco_id',
                                title: (this.context.addons && this.context.addons.esalary?.used_by_companies?.includes(this.props.company)) ? this.tr("eSalary ID") : this.tr('Mepco ID'),
                                isHidden: () => !(this.context.addons && (this.context.addons.mepco || this.context.addons.mepco_v2 || this.context.addons.esalary)),
                            },
                            {
                                key: 'salary_code',
                                title: this.tr('Salary code'),
                                isHidden: () => !(this.context.addons && this.context.addons.esalary),
                            },
                            {
                                key: 'emce_id',
                                title: this.tr('EmCe ID'),
                                isHidden: () => !(this.context.addons && this.context.addons.emce_invoicing),
                            },
                            {
                                key: 'mediapro_id',
                                title: this.tr('Mediapro ID'),
                                isHidden: () => !(this.context.addons && this.context.addons.mediapro),
                            },
                            {
                                key: 'hubspot_id',
                                title: this.tr('Hubspot ID'),
                                isHidden: () => !(this.context.addons && this.context.addons.hubspot),
                            },
                            {
                                key: 'm2_id',
                                title: this.tr('M2 ID'),
                                isHidden: () => !(this.context.addons && this.context.addons.m2_expenses_import),
                            },
                            {
                                key: 'hr_id',
                                title: this.tr("HR user number"),
                                isHidden: () => !(this.context.addons && (this.context.addons.hr?.used_by_companies?.includes(this.props.company))),
                            },
                        ]}
                    />
                );
            case 'password':
                return (
                    <div className={contactCardStyles.detailsSection}>
                        <OutlinedField {...passwordProps} label={this.tr('New Password')} name="newPassword" value={newPassword} autoComplete="new-password" />
                        <OutlinedField {...passwordProps} label={this.tr('Confirm Password')} name="confirmPassword" value={confirmPassword} autoComplete="confirm-password" />
                        {id > 0 && (
                            <Button
                                className="btn change-pswd"
                                size="large"
                                color="primary"
                                style={{ marginTop: 8 }}
                                data-testid="change_password_button"
                                onClick={() => {
                                    this.savePassword();
                                    this.setState({ submitted: true });
                                }}
                            >
                                {this.tr('Change password')}
                            </Button>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    onDetailsScroll = (e) => {
        if (!this.props.id) return;
        if (e.currentTarget.scrollTop >= 100 && !this.showUserNameInHeader) {
            this.showUserNameInHeader = true;
            this.props.setShowUserNameInHeader(true);
        } else if (e.currentTarget.scrollTop < 100 && this.showUserNameInHeader) {
            this.showUserNameInHeader = false;
            this.props.setShowUserNameInHeader(false);
        }
    };

    render = () => {
        const { 
            error, 
            tabs,
        } = this.state;

        if (error) {
            return <div>{<List showOverlay={true} overlayComponent={NoPermissionOverlay} />}</div>;
        }

        return (
            <div id={contactCardStyles.personalDetails}>
                {!this.state.initialFetchDone && (
                    <div className={contactCardStyles.savingOverlay}>
                        <Loading  />
                    </div>
                )}
                <div className={`${cardStyles.detailsBox}`} onScroll={this.onDetailsScroll}>
                    {this.renderDetailsTopSection()}
                    <div className={contactCardStyles.detailsSections}>
                        <ToggleableContainerList sections={this.detailsSections} renderContentForSection={this.renderContentForDetailsSection} />
                    </div>
                </div>
                {tabs.filter(t => !t.hidden).length > 0 && <div className={cardStyles.mainContent}>
                    <WithTabs offsetTop={10} tabs={tabs} selectedTab={this.state.selectedTab} noUpdateView onTabClick={(tab) => this.setState({ selectedTab: tab.id })}>
                        {(selectedTab) => (React.cloneElement(this.tabContent[selectedTab], {
                            masterState: this.state,
                            masterProps: {...this.props, fetchData: this.fetchData},
                        }))}
                    </WithTabs>
                </div>}
            </div>
        );
    };
}

export default withSnackbar(TabPersonalDetails);
