import React from 'react'

/* css */
import './ActivitiesInsightBox.css';

/* others */
import _ from 'lodash';
import { ReactComponent as DueIcon } from './icons/Due-activities.svg';
import { ReactComponent as DoneIcon } from './icons/Done-activities.svg';
import { ReactComponent as TotalIcon } from './icons/Total-activities.svg';
import { ReactComponent as OverdueIcon } from './icons/Overdue-activities.svg';
import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from '../../../SettingsContext';

class ActivitiesInsightCase extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context){
        super(props, context, "dashboard/insights/activities/ActivitiesInsightCase");

        this.cases = [
            { 
                id: 0,
                name: 'total',
                label: this.tr('Total'),
                icon: <TotalIcon title="due" />
                
            },
            { 
                id: 1,
                name: 'done',
                label: this.tr('Done'),
                icon: <DoneIcon title="done" />
            },
            { 
                id: 2,
                name: 'due',
                label: this.tr('Due'),
                icon: <DueIcon title="total" />
            },
            { 
                id: 3,
                name: 'overdue',
                label: this.tr('Overdue'),
                icon: <OverdueIcon title="overdue" />
            }
        ];
    }

    render() {
        return (
            <React.Fragment>
                {_.map(this.cases, box => {
                    return <div className={`${this.props.classNameBox} box ${box.name}`} >
                        <div className={ `${this.props.classNameBoxHeader}` } >{ box.label }</div>
                        <div className={ "box-contents" } >
                            <div className={ "icon" } >
                                { box.icon } 
                                <span>{this.props.boxdata[box.name]}</span>
                            </div>
                        </div>
                    </div>;
                })}
            </React.Fragment>
        )
    }
}

export default ActivitiesInsightCase;