import React from 'react';
import classNames from 'classnames';

import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from './../SettingsContext';

import withStyles from '@mui/styles/withStyles';


const styles = theme => ({
    reqContainer: {
        gridArea: "address",
        justifySelf: "end",
        width: "60%"
    }    
});

class InvoiceTotals extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "invoices/InvoiceTotals");

        this.context = context;

    };
    
    render() {
        const { userObject } = this.context;
        const { rows, className, symbol } = this.props;

        return (
            <div className={`${className} sum-info`} >
                {rows.map((row, i) => {
                    const sum = isNaN(Number(row.sum)) ? 0 : Number(row.sum);
                    return (<div key={i} className={`row ${row.grey ? "grey" : row.black ? "black" : row.greyvat ? "greyvat" : ""}`}>
                        <div key={`${i}-name`} className="name">{row.name}</div>
                        <div data-testid={`invoice_totals_${i}-sum`} key={`${i}-sum`} className="sum">{`${sum?.toFixed(2).replace('.', ',').toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${symbol}`}</div>
                    </div>);
                })}
            </div>
        );
    }
}

InvoiceTotals.defaultProps = {

}

export default withStyles(styles)(InvoiceTotals);
