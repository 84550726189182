import { defaults } from 'react-chartjs-2';
import colors from '../colors';

defaults.plugins.tooltip.enabled = false;
defaults.plugins.legend.display = false;
defaults.font.size = 14;
defaults.font.family = "'Open Sans', sans-serif"; 

export default {
    colorPalette: [
        "#57ABD0", 
        "#6BB9B4", 
        "#858BC5", 
        "#2e8ed9", 
        "#31add4",
        "#4ebbb5", 
        "#838bc9", 
        "#ffbd3f", 
        "#7cc751", 
        "#5d6978",
        //dashboard chart colors 
        "rgba(87, 89, 98, 0.60)", 
        "rgba(245, 166, 35, 0.71)", 
        "rgba(45, 159, 247, 0.60)", 
        "rgba(80, 227, 194, 0.60)", 
        "rgba(111, 66, 193, 0.60)", 
        "rgba(255,87,34, 0.80)",
        "rgba(111,66,193, 1)" 
    ],
    randomColors: [
        colors.greenish_cyan,
        '#2D9FF7',
        '#E57BA0',
        '#FFAF0F',
        '#6F42C1',
        '#FF5722',
        '#34495E'
    ],
    pieColors: [
        "#f5a623",
        "#f7548f",
        colors.greenish_cyan
    ],
    pieColors_v2: {
        due: "#f5a623",
        overdue: "#f7548f",
        done: colors.greenish_cyan
    },
    pieOptions: { 
        cutoutPercentage: 60, 
        responsive: true, 
        maintainAspectRatio: false, 
    },
    barOptions: {
        responsive: true,
        maintainAspectRatio: false,
        barThickness: "flex",
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    display: false
                },
            },
            y: {
                beginAtZero: true,
                min: 0
            }
        },
        fontSize: 14,
    },
}