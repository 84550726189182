import React from "react";
import ListCell from "./ListCell";
import { SettingsContext } from './../SettingsContext';
import Link from "../general/Link";

class CurrencyListCell extends ListCell {
    static defaultProps = {
        ...ListCell.defaultProps,
        allowNaN: false,
        allowNull: true,
        maximumFractionDigits: undefined,
        textAlign: 'right'
    };

	static contextType = SettingsContext;

	presentValue(value) {
		const { currency, allowNaN, allowNull, maximumFractionDigits } = this.props;

		if(this.props.allowEmpty && value === false)  {
			return "";
		}

		const { taimerAccount } = this.context;
		const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency || taimerAccount.currency,
            maximumFractionDigits: maximumFractionDigits,
        }).format;

        let returnValue = undefined;

        if(allowNaN && isNaN(value))
            returnValue = value;
        else if (allowNull && value === null)
            returnValue = value;
        else {
            if(value == 0 && !this.props.showZero)
                returnValue = currencyFormatter(0);
            else
                returnValue = currencyFormatter(String(value).replace(",","."));

            returnValue += this.props.extraAfter ? this.props.extraAfter : "";
        }

        if ((this.props.asLink && this.props.url) || (this.props.useOnClick && this.props.onClick)) {
            return <Link url={this.props.url} onClick={this.props.useOnClick ? this.props.onClick : undefined}>{returnValue}</Link>
        }

        return returnValue;
	}
}

export default CurrencyListCell;
