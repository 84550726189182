import React from 'react';
import TaimerComponent from '../../TaimerComponent';

import { SettingsContext } from '../../SettingsContext';

import './AccountingAccounts.scss';

import DataHandler from "../../general/DataHandler";

import SettingRow from "../../list/rows/SettingRow";
//import List from "../../list/List";
import SettingsList from "../../list/lists/SettingsList";
import ListCell from "../../list/ListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import RadioButtonCell from "../../list/cells/RadioButtonCell";

import Button from '@mui/material/Button';
import { withSnackbar } from 'notistack';

import TrashCanIcon from "@mui/icons-material/Delete";

import ConfirmationDialog from "./../dialogs/ConfirmationDialog";

class AccountRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            account_number: <TextInputCell
                width={this.props.columnWidthMap['account_number']}
                name="account_number"
                value={this.state.data['account_number']}
                onEdited={this.editAndSave}
            />,
            is_default: <RadioButtonCell 
                onEdited={(event, checked) => {
                    this.editAndSave("is_default", checked);
                }}
                width={this.props.columnWidthMap['is_default']}
                checked={this.state.data['is_default'] == 1} 
            />
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName]) }</div>;
    }
}

class AccountingAccounts extends TaimerComponent {
    static defaultProps = {
		showDefaultOption: false
    }
    
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/AccountingAccounts");

        this.state = {
            accounts: []
        }

        this.columnConfig = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.list = React.createRef();

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
    }

    componentDidMount () {
        super.componentDidMount();
        this.getData();
    }

    getData = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/${this.props.integration}/${this.props.type}`}).done(response => {
            this.setState({accounts: response[Object.keys(response)[0]]});
        });
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    render() {
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <div className="accounting-list">
                <Button className="green" onMouseUp={() => this.list.current.addNewRow()} size="large">{this.props.addButtonText}</Button>
            
                <SettingsList
                    innerRef={this.list}
                    className="settingsList integrationList"
                    height="auto"
                    rowHeight={SettingRow.rowHeight}
                    newRow={{
                        id: -1,
                        name: "",
                        account_number: "",
                    }}
                    listRowType={AccountRow}
                    columns={[
                        {width: 55, name: "delete", header: "", ...this.columnConfig},
                        {width: 350, name: "name", header: this.tr("Accounting account name"), ...this.columnConfig},
                        {width: 350, name: "account_number", header: this.tr("Accounting account number"), ...this.columnConfig},
                        ...(this.props.showDefaultOption ? [{width: 100, name: "is_default", header: this.tr("Default"), ...this.columnConfig}] : []),
                    ]}
                    rowProps={{
                        onCreate: data => {
                            DataHandler.post({url: `accounting/accounts/${this.props.company}/${this.props.integration}/${this.props.type}`}, {data: JSON.stringify(data)}).done(response => {
                                this.getData();
                            });
                        },
                        onUpdate: data => {
                            DataHandler.put({url: `accounting/accounts/${this.props.company}`}, {data: JSON.stringify(data)}).done(response => {
                                this.getData();
                            });
                        },
                        onDelete: data => {
                            if (Number(data.id) < 0) {
                                this.list.current.removeRow(data.id);
                                return;
                            }
                            if (data.is_default == true) {
                                this.props.enqueueSnackbar(this.tr("Default sales account can't be removed"), {
                                    variant: "error",
                                });	
                                return;
                            }
                            data.deleted = "1";
                            this.setState({
                                dialogData: {
                                    saveFunc: () => DataHandler.delete({url: `accounting/accounts/${this.props.company}`}, {data: JSON.stringify(data)}).done(response => {
                                        this.getData();
                                    }),
                                    text: this.tr("Do you want to delete sales account") + ": " + data.name + "?" 
                                }
                            });
                            this.openDialog('confirmation');
                        }
                    }}
                    data={this.state.accounts}
                />

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
            </div>
        )
    }
}

export default withSnackbar(AccountingAccounts);