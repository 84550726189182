import ChevronLeft from '@mui/icons-material/ChevronLeft';
import TrashCanIcon from "@mui/icons-material/Delete";
import Button from '@mui/material/Button';
import React from 'react';
import { SettingsContext } from '../../SettingsContext';
import TaimerComponent from "../../TaimerComponent";
import DataHandler from '../../general/DataHandler';
import List from "../../list/List";
import ListCell from "../../list/ListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import SettingRow from "../../list/rows/SettingRow";

import './Venda.css';

class ProductRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            product_id: <TextInputCell
                width={this.props.columnWidthMap['product_id']}
                name="product_id"
                value={this.state.data['product_id']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class Venda extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Venda");

        this.state = {
            accountingProducts: [],
        };

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };


        this.productsList = React.createRef();

    }

    componentDidMount() {
        super.componentDidMount();
        this.getProducts();
    }

    back = () => {
		const {
			functions: { updateView },
		} = this.context;
		this.props.fetchData();
		updateView({ module: "settings", action: "index", group: "integrations", page: "default", subpage: "" });
    };
    
    getProducts = () => {
        DataHandler.get({url: `accounting/products/${this.props.company}/venda`}).done(response => {
            this.setState(response);
        });
    }

    render() {
		const { tr } = this;

        return (
            <div id="integration-venda" className="main-integration-wrapper">
                <div className="header">
					<Button className="return-button venda-button" variant="text" size="large" onClick={this.back}>
						{<ChevronLeft />}
					</Button>
					<h3>{tr("Venda settings")}</h3>
				</div>
                <div className='settings'>
                    <h4>{tr('Venda products')}</h4>
                    <p>{tr("Manage Venda product dimensions") + "."}</p>
                    <Button
                        className='green venda-button'
                        style={{ float: 'left' }}
                        onMouseUp={() => this.productsList.current.addNewRow()}
                        size='large'
                    >
                        {tr('add product')}
                    </Button>
                    <br />
                    <List
                        ref={this.productsList}
                        id="accountingProductsList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            product_id: "",
                        }}
                        listRowType={ProductRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Procut name"), ...this.dColConf },
                            { width: 350, name: "product_id", header: tr("Product ID"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                data.company = this.props.company;
                                DataHandler.post({url: 'accounting/products/venda'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: 'accounting/products'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            },
                            onDelete: data => {
                                if(data.id == this.state.default_accounting_products_id) {
                                    this.props.enqueueSnackbar(this.tr("Default product cannot be deleted"), {
                                        variant: "error",
                                    });	
                                    return;
                                }
                                data.deleted = "1";
                                DataHandler.delete({url: 'accounting/products'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            }
                        }}
                        data={this.state.accountingProducts}
                    />
                </div>
            </div>
        );
    }
}

export default Venda;