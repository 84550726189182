import moment from 'moment';
import React from 'react';
import TaimerComponent from "../TaimerComponent";

/*context */
import { SettingsContext } from './../SettingsContext';

/* local components */
import Link from '../general/Link';
import { ReactComponent as LoginLogo } from '../navigation/psa_logo.svg';
import ForgotPasswordView from "./ForgotPasswordView";
import LoginView from "./LoginView";
import PasswordRequestedView from "./PasswordRequestedView";

/*material ui */
import Button from '@mui/material/Button';

/* css */
import './Login.css';

import { ReactComponent as AppstoreLogo } from '../images/appstore_logo.svg';
import { ReactComponent as PlaystoreLogo } from '../images/playstore_logo.svg';

class Login extends TaimerComponent {
    static contextType = SettingsContext;
    
    state = {
        account: window.location.href.indexOf(':8080')  > -1 || window.location.href.indexOf("branches.dev.psa") > -1 ? null : window.location.href.split("/")[3], 
        page: "login",
        email: "",
    }

    
    constructor(props, context) {
        super(props, context, "login/Login" /* translation namespace */);
        if(this.state.account && this.state.account.startsWith("?")) {
            this.state.account = null;
        }
        this.showSSO = window.location.host == "saml.taimer.com";
        this.state.isMobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

        this.loginViewRef = React.createRef();
        this.forgotPassvordViewRef = React.createRef();
    }

    componentDidMount() {
        let code;
        
        if (code = window.location.href.match(/onboarding_auth=([^&]+)/))
            this.loginViewRef.current.onboardingAuth(code[1]);
        
        if (code = window.location.href.match(/jwt=([^&]+)/))
            this.loginViewRef.current.igAuth(code[1]);

    }

    changePage = (page, email) => {
        this.setState({ page, email });
    }

    handleKeyPress = (event) => {
        if(event.key == 'Enter'){
            event.preventDefault();
            if (this.state.page === "login")
                this.loginViewRef.current.invokeListener();
            if (this.state.page === "forgotPassword")
                this.forgotPassvordViewRef.current.sendPasswordRequest();
        }   
    }

    sendMobileDownloadAnalyticsEvent = (platform) => {
        const eventData = {
            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
            mobile_platform: platform,
        };
        //this.context.functions.sendAnalytics('mobile_download_clicked', eventData);
    }

    render () {
        const { state } = this;
        const { account, page, email, taimerVersion, lang } = state;
        const lng = window.navigator.userLanguage || window.navigator.language;

        return (
            <div id="taimer-login" onKeyPress={this.handleKeyPress} >
                {
                    this.state.isMobile && (
                        <div class="mobile">
                            <span class="app-info">
                                {this.tr("Download our native mobile app")}
                            </span>
                            <Link 
                                class="app-store"
                                href="https://apps.apple.com/fi/app/taimer-sales-crm-projects/id1453159925"
                                onClick={() => this.sendMobileDownloadAnalyticsEvent('ios')}
                                onClickWithCtrl
                                allowHrefWithOnClick
                            >
                                <AppstoreLogo 
                                    alt="Download from App Store"
                                    width="157"
                                    height="52"
                                />
                            </Link>
                            <Link
                                class="play-store"
                                href="https://play.google.com/store/apps/details?id=com.taimer.enterprise"
                                onClick={() => this.sendMobileDownloadAnalyticsEvent('android')}
                                onClickWithCtrl
                                allowHrefWithOnClick
                            >
                                <PlaystoreLogo 
                                    alt="Download from Google Play"
                                    width="157"
                                    height="51"
                                />
                            </Link>
                        <span class="or">{this.tr("or")}</span>                            
                        <Button color="primary" className="login-now" onClick={() => this.setState({isMobile: false})}>{this.tr("Login now")}</Button>
                        </div>
                    )
                }
                {
                    !this.state.isMobile && (
                        <React.Fragment>
                            <LoginLogo className='login-logo' />
                            {page === "login" && (
                                <LoginView ref={this.loginViewRef} account={account || this.props.account} changePage={this.changePage} showSSO={this.showSSO} cognitoSignIn={this.props.cognitoSignIn} username={this.props.username} onSignIn={this.props.onSignIn} message={this.props.message} />
                            )}
                            {page === "forgotPassword" && (
                                <ForgotPasswordView ref={this.forgotPassvordViewRef} changePage={this.changePage} cognitoSignIn={this.props.cognitoSignIn} username={this.props.username} />
                            )}
                            {page === "passwordRequested" && (
                                <PasswordRequestedView changePage={this.changePage} email={email} />
                            )}

                            <div class="footer">
                                <p>&copy; {moment().format('YYYY')} {this.tr("Heeros Plc")}. {this.tr("All Rights Reserved")}.</p>
                                <Link url={lng == "fi" ? `https://psahelpcenter.heeros.com/hc/en-us/articles/8081572644754` : `https://psahelpcenter.heeros.com/hc/en-us/articles/8081572644754`} openInNewTab>{this.tr("Privacy Policy")}</Link>
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
        );
    }
}

export default Login;