import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import moment from 'moment';
import './TimeRangeOutlinedField.css';
import { FilledInput, InputAdornment } from '@mui/material';
import TaimerComponent from '../TaimerComponent';
import { is } from 'date-fns/locale';

class TimeRangeOutlinedField extends TaimerComponent {
    static defaultProps = {
        label: 'Time',
        disabled: false,
        disabledReason: undefined,
        useMidnightEnd: false,
        format: 'LT',
    };

    constructor(props, context) {
        super(props, context, "settings/api-settings/TimeRangeOutlinedField");
        this.state = {
            value: this.props.value,
            isFullDay: false,
            previousValue: null,
        }
    }

    parseTextToRange = (text) => {
        const { useMidnightEnd } = this.props;
        let [start, end] = text.split('-');

        if (!start || !end)
        {
            start = undefined;
            end = undefined;
        }
        else
        {
            start = moment(start, "LT");
            end = moment(end, "LT");

            if (useMidnightEnd && end.isSameOrBefore(start) && end.hour() === 0 && end.minute() === 0) {
                end = start.clone().endOf('day').add(1, 'second');
            }

            if (!start.isValid() || !end.isValid())
            {
                start = undefined;
                end = undefined;
            }
        }

        return [start, end]
    }

    onBlur = (e) => {
        if (!this.props.useState) return;
        const [start, end] = this.parseTextToRange(e.target.value);
        this.props.onChange(e.target.value, start, end);
    }

    onChange = (e) => {
        const [start, end] = this.parseTextToRange(e.target.value);
        if (this.props.useState) {
            this.setState({ value: e.target.value });
        } else {
            this.props.onChange(e.target.value, start, end);
        }
    }

    toggleFullDays = () => {
        if (!this.props.useState) return;
        if (this.state.isFullDay) {
            const [start, end] = this.parseTextToRange(this.state.previousValue);
            this.setState({ value: this.state.previousValue, isFullDay: false }, () => {
                this.props.onChange(this.state.previousValue, start, end, false);   
            });
        } else {
            const fullDayText = this.tr('Full Day');
            this.setState({ value: fullDayText, isFullDay: true, previousValue: this.state.value }, () => {
                this.props.onChange(fullDayText, '00:00', '00:00', true);
            });
        }
        
    }

    render() {
        const { name, rootClass, value, label = "Time", disabled, disabledReason, useState } = this.props;

        const [start, end] = this.parseTextToRange(value);

        const len = (start && end ? end.diff(start, "hours", true) : 0).toFixed(2);
        
        return (
            <FormControl className={rootClass} variant="filled">
                <InputLabel
                    shrink
                    ref={ref => {
                        this.labelRef = ReactDOM.findDOMNode(ref);
                    }}
                    htmlFor={`${name}-date`} >

                    {label} {len > 0 && <span className="timeRangeHours">{len} h</span>}
                    {disabledReason && <span className="timeRangeDisabledReason">{disabledReason}</span>}
                </InputLabel>

                <FilledInput
                    autoComplete="off" 
                    type="text"
                    // name={`${name}`}
                    id={`${name}-date`}
                    value={useState ? this.state.value : value}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    error={this.props.error}
                    inputProps={{
                        'data-testid': this.props['data-testid'] || 'time-range'
                    }}
                    disabled={disabled} 
                    endAdornment={
                        this.props.showFullDayButton && (
                            <InputAdornment position='end'>
                                <div className='fullDayButton-disabled'>
                                    <button className='fullDayButton' disabled={false} onClick={this.toggleFullDays}>{this.state.isFullDay ? this.tr('Reset') : this.tr('Set Full Days')}</button>
                                </div>
                            </InputAdornment>
                        )
                    }/>
            </FormControl>
        );
    }
}

TimeRangeOutlinedField.defaultProps = {
    value: "07:00",
}

export default TimeRangeOutlinedField;
