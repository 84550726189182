import { AddCircleOutline, Delete, KeyboardArrowDown, KeyboardArrowUp, Person, Sell, SendRounded, Star } from '@mui/icons-material';
import { cloneDeep, uniqBy } from 'lodash';
import React from 'react';
import DataHandler from '../general/DataHandler';
import TaimerWizard, { TaimerWizardPage } from '../general/TaimerWizard';
import TaimerComponent from '../TaimerComponent';
import styles from './QuoteWizard.module.scss';
import OutlinedField from './OutlinedField';
import DataList from './DataList';
import Utils from './Utils';
import { DatePicker } from './react-date-range/src';
import SliderFieldGroup from './SliderFieldGroup';
import { addWeeks, format } from 'date-fns';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import FieldEditSlider from './FieldEditSlider';
import TabQuotes from '../projects/TabQuotes';
import ProposalEditor from '../projects/proposal/ProposalEditor';
import ToggleableContainerList from './ToggleableContainerList';
import moment from 'moment';
import { generateQuoteAttachment, QuoteEmailComposerView, sendQuoteEmail } from '../projects/SendQuoteWizard';
import ProjectTreeDropdown from '../projects/ProjectTreeDropdown';
import { AddProject } from './no-options/AddItemComponents';
import { convertDateFormat, formatInputNumber } from '../helpers';
import QuotePrintPreview from '../projects/QuotePrintPreview';
import VersionContentManager from './VersionContentManager';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import { ReactComponent as QuoteImage } from './icons/salesQuote.svg';
import { ReactComponent as AddItemsIcon } from './icons/addItems.svg';
import { ReactComponent as SendIcon } from './icons/sendQuote.svg';
import { ReactComponent as PreviewIcon } from './icons/previewQuote.svg';
import { ReactComponent as ProposalIcon } from './icons/proposal.svg';
import { ReactComponent as SentImage } from './icons/completed.svg';
import { ReactComponent as ErrorImage } from './icons/error.svg';

interface QuoteWizardProps extends WithSnackbarProps {
    project?: string;
    onboardingMode?: boolean;
    quote?: any;
    sliderMode?: boolean;
}

interface QuoteWizardState {
    project?: any;
    addresses: any[];
    users: any[];
    quote: any;
    header?: string;
    rows: any[];
    selectedAddress?: any;
    selectedSender?: any;
    quotePDF?: any;
    pages: TaimerWizardPage[];
    currency: string;
    printLanguage: string;
    printDateFormat: string;
    includeProposal?: boolean;
    printLanguageOptions: any[];
    printDateOptions: any[];
    loadingPDF?: boolean;
    email?: any;
    contacts: any;
    defaultVAT: string | number;
    quoteHTML?: any;
    products: any[];
    receiverContacts: any[];
    quoteSaved?: boolean;
    error?: boolean;
}

class QuoteWizard extends TaimerComponent<QuoteWizardProps, QuoteWizardState> {
    wizard: any = React.createRef();
    proposalEditor: any = React.createRef();
    projectTreeDropdown: any = React.createRef();
    explainerSections = [
        {
            key: 'details',
            title: this.tr('Sales quote details'),
            initiallyOpen: true,
        },
        {
            key: 'receiverDetails',
            title: this.tr('Receiver'),
            initiallyOpen: true,
        },
        {
            key: 'senderDetails',
            title: this.tr('Sender'),
            initiallyOpen: true,
        },
    ];
    listColumns = [
        {
            id: 'order',
            label: '',
            width: 25,
            noClick: true,
        },
        {
            id: 'item',
            label: this.tr('Item/description'),
        },
        {
            id: 'quantity',
            label: this.tr('Quantity'),
        },
        {
            id: 'unitCost',
            label: this.tr('Unit cost'),
        },
        {
            id: 'sellingPrice',
            label: this.tr('Selling price'),
        },
        {
            id: 'subtotal',
            label: this.tr('Subtotal'),
            align: 'right',
        },
        {
            id: 'vat',
            label: this.tr('Vat'),
            align: 'right',
        },
        {
            id: 'total',
            label: this.tr('Total'),
            align: 'right',
        },
        {
            id: 'delete',
            label: '',
            align: 'right',
            width: 40,
            noClick: true,
        },
    ];

    tabQuotes: any = React.createRef();
    deletedRows: any[] = [];
    onboardingMode = false;

    constructor(props, context) {
        super(props, context, 'general/QuoteWizard');
        const isOdin = window.location.href.indexOf(':8080') > -1;
        this.onboardingMode =
            !this.props.quote &&
            (this.props.onboardingMode ||
                ((this.context.taimerAccount.onboardingSeen != 1 || isOdin) &&
                    VersionContentManager.getOnboardingItems().indexOf('quote') != -1 &&
                    (this.context.userObject.completedOnboardingItems || []).indexOf('quote') == -1));
        const pages: TaimerWizardPage[] = [
            {
                title: props.quote ? this.tr('Edit details') : this.tr('Add details'),
                content: {
                    main: this.renderQuoteDetailsEditor,
                    right: this.renderExplainer,
                },
                actions: [
                    ...(this.onboardingMode
                        ? []
                        : [
                              {
                                  setLoading: true,
                                  label: this.tr('Save & close'),
                                  action: () => this.saveQuote(true),
                                  className: styles.greyButton,
                                  visible: () => !!props.quote,
                              },
                          ]),
                    {
                        label: props.quote ? this.tr('Edit items') : this.tr('Add items'),
                        action: 'next',
                        disabled: () => !this.state.project || !this.state.quote.name || !this.state.selectedAddress || !this.state.selectedSender,
                    },
                ],
            },
            {
                title: props.quote ? this.tr('Edit items') : this.tr('Add items'),
                content: {
                    main: this.renderQuoteRowEditor,
                    right: this.renderExplainer,
                },
                actions: [
                    ...(this.onboardingMode
                        ? []
                        : [
                              {
                                  setLoading: true,
                                  label: this.tr('Save & close'),
                                  action: () => this.saveQuote(true),
                                  className: styles.greyButton,
                              },
                          ]),
                    {
                        setLoading: true,
                        label: this.hasProposalRight() ? (props.quote?.proposals_id ? this.tr('Save & edit proposal') : this.tr('Save & add proposal')) : this.tr('Save & preview'),
                        action: this.saveQuote,
                        disabled: () => this.state.rows.length == 0,
                    },
                ],
            },
            ...(this.hasProposalRight()
                ? [
                      {
                          title: props.quote?.proposals_id ? this.tr('Edit proposal') : this.tr('Add proposal'),
                          content: {
                              main: this.renderProposalEditor,
                          },
                          actions: [
                              ...(this.onboardingMode
                                  ? []
                                  : [
                                        {
                                            setLoading: true,
                                            label: this.tr('Save & close'),
                                            action: () => this.onProposalCreated(true),
                                            className: styles.greyButton,
                                        },
                                    ]),
                              {
                                  setLoading: true,
                                  label: this.tr('Save & preview'),
                                  action: this.onProposalCreated,
                              },
                          ],
                      },
                  ]
                : []),
            {
                title: this.tr('Preview'),
                content: {
                    main: () =>
                        this.state.quoteSaved ? (
                            <QuotePrintPreview
                                includeProposal={this.state.includeProposal == undefined ? !!this.state.quote?.proposals_id : this.state.includeProposal}
                                quoteId={this.state.quote?.id}
                                project={this.state.project}
                                onPDFChanged={this.onPDFChanged}
                                onPrintSettingChanged={this.onPrintSettingChanged}
                                onPrintSettingsLoaded={this.onPrintSettingsLoaded}
                                printLanguage={this.state.printLanguage}
                                printDateFormat={this.state.printDateFormat}
                                onboardingContent={
                                    this.onboardingMode
                                        ? {
                                              image: <PreviewIcon />,
                                              header: this.tr('Preview your quote'),
                                              description: this.tr('Here you can see a preview of the created quote and do some final changes to the pdf settings.'),
                                          }
                                        : undefined
                                }
                            />
                        ) : null,
                },
                actions: [
                    ...(this.onboardingMode
                        ? []
                        : [
                              {
                                  label: this.tr('Save & close'),
                                  action: 'close' as unknown as 'close',
                                  className: styles.greyButton,
                              },
                          ]),
                    {
                        label: this.tr('Compose email'),
                        action: 'next' as unknown as 'next',
                        disabled: () => !this.state.quotePDF,
                    },
                ],
            },
            {
                title: this.tr('Compose email'),
                content: {
                    main: () => (
                        <QuoteEmailComposerView
                            quote={this.state.quote}
                            project={this.state.project}
                            onEmailChanged={this.onEmailChanged}
                            includeSelf={this.onboardingMode}
                            initialReceiver={{
                                id: this.state.selectedAddress?.contact?.id || 'initialReceiver',
                                email: this.state.selectedAddress?.email,
                                freeValue: !this.state.selectedAddress?.custom_contact,
                                label:
                                    this.state.selectedAddress?.custom_contact ||
                                    (typeof this.state.selectedAddress?.contact == 'object' ? this.state.selectedAddress?.contact.label : this.state.selectedAddress?.contact) ||
                                    this.state.selectedAddress?.email,
                            }}
                        />
                    ),
                    right: this.onboardingMode
                        ? {
                              image: <SendIcon />,
                              header: this.tr('Send your first sales quote'),
                              description: this.htmlTr(
                                  'For this first one, we have included you as one of the receivers so you can see the email and try out the flow.${linebreak}The receiver will be provided with the ability to approve or decline the offer, and we will let you know when it happens!',
                                  {
                                      linebreak: [<br />, <br />],
                                  }
                              ),
                          }
                        : undefined,
                },
                actions: [
                    ...(this.onboardingMode
                        ? []
                        : [
                              {
                                  label: this.tr('Save & close'),
                                  action: 'close' as unknown as 'close',
                                  className: styles.greyButton,
                              },
                          ]),
                    {
                        label: this.tr('Send'),
                        action: this.sendEmail,
                        setLoading: true,
                        disabled: this.requiredEmailFieldsMissing,
                    },
                ],
            },
            {
                title: this.tr('Sent'),
                content: { main: this.renderSent },
                actions: [
                    {
                        label: this.tr('Done'),
                        action: 'close',
                    },
                ],
                noReturn: true,
            },
        ];
        this.state = {
            addresses: [],
            users: [],
            quote: {
                validUntil: addWeeks(new Date(), 1),
            },
            rows: [],
            pages,
            currency: this.context.taimerAccount.currency,
            printLanguage: 'en',
            printDateFormat: convertDateFormat(this.context.taimerAccount.companyDateFormat, true),
            printLanguageOptions: [],
            printDateOptions: [],
            contacts: [],
            email: {
                recipients: [],
                message: '',
            },
            defaultVAT: 0,
            products: [],
            receiverContacts: [],
            ...this.formatQuote(this.props.quote),
        };
    }

    componentDidMount(): void {
        super.componentDidMount();
        if (this.props.project || this.props.quote) {
            this.getProjectData();
        }
    }

    getProjectData = async (projectId = this.props.project || this.props.quote?.projectId) => {
        const project = await DataHandler.get({ url: `projects/${projectId}` });
        this.setState({ project }, () => {
            this.getAddresses();
            this.getUsers();
            this.getCompanyData();
            this.getContacts();
            this.getDefaultVAT();
            this.getProducts();
            this.getReceiverContacts();
        });
    };

    getReceiverContacts = async () => {
        const { project } = this.state;
        const customerContacts = await DataHandler.get({ url: `subjects/contacts/${project.account.id}` });
        let receiverContacts = [...(customerContacts || [])];
        if (project.contacts) {
            const projectContacts = [...project.contacts];
            const compare = (value) => String(value.id);
            receiverContacts = uniqBy(customerContacts.concat(projectContacts), compare);
        }
        this.setState({ receiverContacts });
    };

    getProducts = async () => {
        const {
            project: { companies_id, account },
        } = this.state;
        const products = (await DataHandler.get({ url: `/products/array/${companies_id}`, include_deleted: 0, account: account.id })) || {};

        this.setState({
            products: (products.products || []).map((product) => ({ ...product, label: product.name })),
        });
    };

    getDefaultVAT = async () => {
        const {
            project: { companies_id },
        } = this.state;
        const response = await DataHandler.get({ url: `settings/company/${companies_id}/project/defaultQuoteVat` });
        this.setState({ defaultVAT: response.default_quote_vat || 0 });
    };

    onProjectSelected = (project, isNew = false) => {
        this.getProjectData(project.id);
        if (isNew) {
            this.projectTreeDropdown.current && this.projectTreeDropdown.current.fetchProjects();
        }
    };

    getContacts = () => {
        const { project } = this.state;
        DataHandler.get({ url: `autocomplete_data/contacts/${project.companies_id}/customer/${project.account.id}` })
            .done((response) => {
                const contacts = (response.contacts || []).filter((c) => !!c.email);
                this.setState({ contacts });
            })
            .fail((error) => console.error(error));
    };

    getCompanyData = async () => {
        const {
            project: { companies_id },
        } = this.state;
        const companies = await DataHandler.get({
            url: `subjects/companies_with_project_right/read+project_cost_estimate_read`,
            currency: 1,
            date_format: 1,
            print_lang: 1,
            country_lang: 1,
            print_options: 1,
        });
        const currentCompany = companies.find((c) => c.id == companies_id);
        if (!currentCompany) return;
        const defaultPrintOptions = this.getDefaultPrintOptions(currentCompany);
        this.setState({
            ...defaultPrintOptions,
            currency: currentCompany.currency,
        });
    };

    getDefaultPrintOptions = (company) => {
        return {
            printLanguage: company.print_lang ? company.print_lang : company.country_lang,
            printLanguageOptions: company.print_languages || [],
            printDateFormat: company.date_format || convertDateFormat(this.context.taimerAccount.companyDateFormat, true),
            printDateOptions: company.print_date_formats || [],
        };
    };

    onPrintSettingChanged = (key, value) => {
        const settingUpdate = {
            [key]: value,
        };
        this.setState({ ...settingUpdate, quotePDF: undefined });
    };

    onPrintSettingsLoaded = (printSettings) => {
        this.setState({ ...printSettings });
    };

    hasProposalRight = () => {
        return this.context.functions.checkPrivilege('projects', 'proposal_write');
    };

    onPDFChanged = (quotePDF) => {
        this.setState({ quotePDF });
    };

    onEmailChanged = (email) => this.setState({ email });

    formatQuote = (quoteFromProps): any => {
        if (!quoteFromProps) return null;
        const quote = {
            ...quoteFromProps,
            name: quoteFromProps.name,
            validUntil: quoteFromProps.valid_until,
        };
        const rows: any[] = [];
        (quoteFromProps.headers[0]?.rows || []).forEach((r, i) => {
            if (r.type == 2) {
                const parentIndex = rows.length - 1;
                if (parentIndex >= 0) {
                    rows[parentIndex] = {
                        ...rows[parentIndex],
                        description: r.name,
                        descriptionId: r.id,
                    };
                }
            } else {
                const row = {
                    ...r,
                    item: r.product_name || r.name,
                    sellingPrice: r.value,
                    unitCost: r.cost,
                    quantity: r.quantity,
                    vat: r.vat,
                    discountPercentage: undefined,
                    product: (r.product_id || 0) != 0 ? { id: r.product_id, name: r.product_name, label: r.product_name } : undefined,
                    quantityType: r.quantityType,
                    workType: r.workType,
                    type: r.type,
                };
                rows.push(row);
            }
        });
        return { quote, rows, header: quoteFromProps.headers[0]?.name };
    };

    saveQuote = async (closeAfter = false) => {
        this.setState({ quoteSaved: false }, async () => {
            const { project, selectedAddress, selectedSender, quote, rows: addedRows, header } = this.state;
            let rows: any[] = [];
            let rowIndex = 0;
            addedRows.forEach((r, i) => {
                rows.push({
                    ...r,
                    description: r.item,
                    name: r.item,
                    value: r.sellingPrice,
                    cost: r.unitCost,
                    quantity: r.quantity,
                    vat: r.vat,
                    type: r.type || r.product ? 3 : 1,
                    quantityType: r.quantityType || (r.product?.unit || '').trim() != '' ? r.product?.unit : 1,
                    discountPercentage: undefined,
                    product_id: r.product?.id,
                    workType: r.workType || r.product ? 4 : 1,
                    roworder: rowIndex++,
                });
                if (r.description) {
                    rows.push({
                        id: r.descriptionId,
                        name: r.description,
                        type: 2,
                        roworder: rowIndex++,
                    });
                }
            });

            rows = [...rows, ...this.deletedRows];

            const data = {
                quotes: [
                    {
                        id: this.state.quote?.id || -1,
                        projectId: project.id,
                        project_name: project.name,
                        deleted: 0,
                        userId: this.context.userObject.usersID,
                        editedAddress: selectedAddress,
                        sender_contact_id: selectedSender.id,
                        company_email: selectedSender?.email,
                        company_phone: selectedSender?.phone,
                        sent_date: format(new Date(), 'YYYY-MM-DD'),
                        valid_until: quote.validUntil,
                        name: quote.name,
                        delivery_date: format(new Date(), 'YYYY-MM-DD'),
                        print_exceptions: ['cost', 'margin', 'workType'],
                        active: 1,
                        status: this.state.quote?.status || 1,
                        customer_action_pending: this.state.quote?.customer_action_pending || 0,
                        headers: this.state.quote?.headers
                            ? [{ ...this.state.quote.headers[0], name: header, hidden_for_print: !header, rows }]
                            : [
                                  {
                                      id: '-1',
                                      name: header,
                                      hidden_for_print: !header,
                                      rows,
                                  },
                              ],
                    },
                ],
            };
            try {
                const response = await DataHandler.post({ url: 'projects/quotes' }, data);
                const ids = response.cost_est_ids || [];
                const quoteId = this.state.quote?.id || ids[0]?.newId;
                if (Number(this.state.quote?.id || -1) < 0) {
                    /*this.context.functions.sendMixpanelEvent('create_sales_quote', {
                        'origin_point': 'quote_view',
                    });
                    this.context.functions.sendMixpanelPeople('set_once', {
                        'first_create_sales_quote_start': new Date().toISOString(),
                    });
                    this.context.functions.sendMixpanelPeople('set', {
                        'last_create_sales_quote_start': new Date().toISOString(),
                    });
                    this.context.functions.sendMixpanelPeople('increment', {
                        'lifetime_create_sales_quote': 1,
                    });*/
                }
                this.setState({ quote: { ...this.state.quote, id: quoteId }, quoteSaved: true });
                if (closeAfter) {
                    setTimeout(() => {
                        window.dispatchEvent(new Event(`quoteSaved`));
                    }, 1000);
                    this.wizard.current && this.wizard.current.close();
                }
            } catch (error) {
                this.props.enqueueSnackbar(this.tr('Saving quote failed!'), {
                    variant: 'error',
                });
                console.error(error);
            }
        });
    };

    getUsers = async () => {
        const { project } = this.state;
        let users = await DataHandler.get({ url: `subjects/employees/${project.companies_id}` });
        const userTagProps = {
            fields: { name: 'name' },
            showLocked: false,
            transl: {
                locked: this.tr('locked'),
                freelancer: this.tr('freelancer'),
            },
        };
        users = users.map((u) => ({ ...Utils.showLockedAndFreelancerUserTag(u, userTagProps) }));
        let selectedSender = users.find((u) => u.id == this.context.userObject.usersId) || users[0];
        if (this.props.quote) {
            selectedSender = { ...users.find((u) => u.id == this.props.quote.sender_contact_id), email: this.props.quote.company_email, phone: this.props.quote.company_phone };
        }
        this.setState({
            users,
            selectedSender,
        });
    };

    getAddresses = async () => {
        const { project } = this.state;
        let addresses = await DataHandler.get({ url: `accounts/${project.account.id}/visiting_addresses/${project.companies_id}` });
        addresses = addresses.map((a) => ({ ...a, label: this.generateAddressLabel(a) }));
        let selectedAddress = addresses.find((a) => a.id == this.state.selectedAddress?.id) || addresses[0];
        if (this.props.quote) {
            selectedAddress = { ...addresses.find((a) => a.id == this.props.quote.address_id), ...this.props.quote.editedAddress };
            selectedAddress.label = this.generateAddressLabel(selectedAddress);
        }
        this.setState({ addresses, selectedAddress });
    };

    onChangeAddress = (selectedAddress) => {
        this.setState({ selectedAddress });
    };

    onChangeSender = (selectedSender) => {
        this.setState({ selectedSender });
    };

    onChangeDate = (validUntil) => {
        this.setState({
            quote: {
                ...this.state.quote,
                validUntil: format(validUntil, 'YYYY-MM-DD'),
            },
        });
    };

    onChangeName = ({ target: { value: name } }) => {
        this.setState({
            quote: {
                ...this.state.quote,
                name,
            },
        });
    };

    onSaveRow = (row) => {
        const rows = cloneDeep(this.state.rows);
        if (row.isNew) {
            rows.push({ ...row, isNew: false, id: -rows.length });
        } else {
            const index = rows.findIndex((r) => r.id == row.id);
            if (index != -1) {
                rows[index] = row;
            }
        }
        this.setState({ rows }, () => {
            this.context.functions.closeSlider();
        });
    };

    showProductSlider = (item = { isNew: true, vat: this.state.defaultVAT }) => {
        const { currency, products } = this.state;
        this.context.functions.showSlider(
            <FieldEditSlider
                useAnimatedSlider
                saveOnEnter
                open={true}
                onClose={this.context.functions.closeSlider}
                title={item.isNew ? this.tr('Add item') : this.tr('Edit item')}
                onSave={this.onSaveRow}
                item={item}
                fields={[
                    {
                        key: 'product',
                        title: this.tr('Product'),
                        type: 'select',
                        options: products,
                        autoFocus: item.isNew,
                        setOtherValuesWithSelection: (_, value) => {
                            return {
                                item: value.name,
                                quantity: 1,
                                unitCost: value.cost_price,
                                sellingPrice: value.income_price,
                                vat: value.vat,
                            };
                        },
                    },
                    {
                        key: 'item',
                        title: this.tr('Name'),
                        required: true,
                    },
                    {
                        key: 'description',
                        title: this.tr('Description'),
                    },
                    {
                        key: 'quantity',
                        title: this.tr('Quantity'),
                        validation: 'numeric',
                        required: true,
                        additionalProps: {
                            maximumFractionDigits: 2,
                        },
                    },
                    {
                        key: 'unitCost',
                        title: this.tr('Unit cost'),
                        validation: 'numeric',
                        required: true,
                        additionalProps: {
                            format: 'currency',
                            currency,
                        },
                    },
                    {
                        key: 'sellingPrice',
                        title: this.tr('Selling price'),
                        validation: 'numeric',
                        required: true,
                        additionalProps: {
                            format: 'currency',
                            currency,
                        },
                    },
                    {
                        key: 'vat',
                        title: this.tr('Vat'),
                        required: true,
                        validation: 'numeric',
                        additionalProps: {
                            maximumFractionDigits: 2,
                        },
                    },
                ]}
            />
        );
    };

    showRowSlider = (item = { isNew: true, vat: this.state.defaultVAT }) => {
        const { currency } = this.state;
        this.context.functions.showSlider(
            <FieldEditSlider
                useAnimatedSlider
                saveOnEnter
                open={true}
                onClose={this.context.functions.closeSlider}
                title={this.tr('Add item')}
                onSave={this.onSaveRow}
                item={item}
                fields={[
                    {
                        key: 'item',
                        title: this.tr('Item'),
                        autoFocus: item.isNew,
                        required: true,
                    },
                    {
                        key: 'description',
                        title: this.tr('Description'),
                    },
                    {
                        key: 'quantity',
                        title: this.tr('Quantity'),
                        validation: 'numeric',
                        required: true,
                        additionalProps: {
                            maximumFractionDigits: 2,
                        },
                    },
                    {
                        key: 'unitCost',
                        title: this.tr('Unit cost'),
                        validation: 'numeric',
                        required: true,
                        additionalProps: {
                            format: 'currency',
                            currency,
                        },
                    },
                    {
                        key: 'sellingPrice',
                        title: this.tr('Selling price'),
                        validation: 'numeric',
                        required: true,
                        additionalProps: {
                            format: 'currency',
                            currency,
                        },
                    },
                    {
                        key: 'vat',
                        title: this.tr('Vat'),
                        required: true,
                        validation: 'numeric',
                        additionalProps: {
                            maximumFractionDigits: 2,
                        },
                    },
                ]}
            />
        );
    };

    onChangeHeader = ({ target: { value: header } }) => {
        this.setState({ header });
    };

    onDeleteRow = (row) => {
        const rows = cloneDeep(this.state.rows);
        const foundIndex = rows.findIndex((r) => r.id == row.id);
        if (foundIndex != -1) {
            rows.splice(foundIndex, 1);
        }
        if (Number(row.id) > 0) {
            this.deletedRows.push({ ...row, deleted: 1 });
        }
        this.setState({ rows });
    };

    moveRowUp = (row) => {
        const rows = cloneDeep(this.state.rows);
        const index = rows.findIndex((r) => r.id == row.id);
        if (index != -1) {
            const newIndex = Math.max(0, index - 1);
            rows.splice(index, 1);
            rows.splice(newIndex, 0, row);
            this.setState({ rows });
        }
    };

    moveRowDown = (row) => {
        const rows = cloneDeep(this.state.rows);
        const index = rows.findIndex((r) => r.id == row.id);
        if (index != -1) {
            const newIndex = Math.min(rows.length - 1, index + 1);
            rows.splice(index, 1);
            rows.splice(newIndex, 0, row);
            this.setState({ rows });
        }
    };

    renderCellValue = (row, key) => {
        const { currency } = this.state;
        switch (key) {
            case 'order':
                return (
                    <div className={styles.order}>
                        <button onClick={() => this.moveRowUp(row)}>
                            <KeyboardArrowUp />
                        </button>
                        <button onClick={() => this.moveRowDown(row)}>
                            <KeyboardArrowDown />
                        </button>
                    </div>
                );
            case 'delete':
                return (
                    <Button className={styles.delete} onClick={() => this.onDeleteRow(row)}>
                        <Delete />
                    </Button>
                );
            case 'item':
                return (
                    <div className={styles.itemCell}>
                        <p>{row[key]}</p>
                        {row.description && <p>{row.description}</p>}
                    </div>
                );
            case 'sellingPrice':
            case 'unitCost':
                return this.context.functions.presentCurrency(row[key], currency);
            case 'quantity':
                return formatInputNumber(row[key], 'hours');
            case 'vat':
                return formatInputNumber(row[key], 'hours') + ' %';
            case 'subtotal':
                return this.context.functions.presentCurrency(Number(row.sellingPrice) * Number(row.quantity), currency);
            case 'total':
                const rowVat = (Number(row.vat) / 100) * (Number(row.sellingPrice) * Number(row.quantity));
                const rowSubtotal = Number(row.sellingPrice) * Number(row.quantity);
                return this.context.functions.presentCurrency(rowSubtotal + rowVat, currency);
            default:
                return row[key];
        }
    };

    onProposalCreated = (closeAfter = false) => {
        this.proposalEditor.current && this.proposalEditor.current._save((id) => this.afterProposalCreated(id, closeAfter));
    };

    renderSent = () => {
        const { error } = this.state;
        return (
            <div className={styles.sent}>
                {error ? <ErrorImage /> : <SentImage />}
                <h2>{error ? this.tr('Sending sales quote failed!') : this.tr('Sales quote sent successfully!')}</h2>
                <p>{error ? this.tr('Please try again later.') : this.tr('Now sit back and wait for approval.')}</p>
            </div>
        );
    };

    requiredEmailFieldsMissing = () => {
        return !this.onboardingMode && (this.state.email?.recipients || []).length == 0;
    };

    sendEmail = async () => {
        const {
            project,
            email: { message, recipients },
            quote,
            printLanguage,
            currency,
            quotePDF,
        } = this.state;
        const {
            project_read_companies: companies,
            userObject: { fullname, dateFormat },
        } = this.context;
        const totals = this.getTotals();
        const company = (companies || []).find((c) => c.id == project.companies_id)?.name || this.context.taimerAccount.name;
        const quoteName = `"${quote?.name == 'New quote' ? this.beTr('New quote header', printLanguage || 'en', {}, 'general/backendTranslations/QuotePrint') : quote.name}"`;
        const attachmentId = await generateQuoteAttachment(quote.id, project, quotePDF, this.tr('sent_quote') + '_' + moment().format(dateFormat).replace(/\./g, '_') + '.pdf');
        return new Promise<'next' | 'close'>((resolve) => {
            sendQuoteEmail({
                recipients: recipients.map((r) => r.email),
                subject: this.beTr('Sales quote ${quoteName} from ${company}', printLanguage || 'en', { quoteName, company }, 'general/backendTranslations/QuotePrint'),
                sender: this.beTr('${userName} via Heeros', printLanguage || 'en', { userName: fullname.split(' ').reverse().join(' ') }, 'general/backendTranslations/QuotePrint'),
                message: message || ' ',
                senderUser: fullname,
                senderCompany: company,
                quoteValue: `${this.context.functions.presentCurrency(totals?.subtotal || 0, currency)} (${this.beTr('VAT 0%', printLanguage || 'en', {}, 'general/backendTranslations/QuotePrint')})`,
                quoteName,
                validUntil: quote.valid_until && moment(quote.valid_until, 'YYYY-MM-DD').isValid() ? moment(quote.valid_until, 'YYYY-MM-DD').format(dateFormat) : '-',
                attachmentId,
                companiesId: project.companies_id,
                quoteId: quote.id,
                lang: printLanguage || 'en',
            })
                .then(() => {
                    this.sendAnalyticsEvent();
                    resolve('next');
                })
                .catch(() => {
                    this.setState({ error: true }, () => {
                        resolve('next');
                    });
                });
        });
    };

    sendAnalyticsEvent = () => {
        const analyticsData = {
            taimer_version: this.context.versionId,
            taimer_language: this.context.userObject.language,
            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
        };
        //this.context.functions.sendAnalytics('quote_sent_by_email', analyticsData);
        this.context.functions.sendMixpanelEvent('send_sales_quote');
    };

    afterProposalCreated = (proposalId, closeAfter = false) => {
        if (closeAfter) {
            setTimeout(() => {
                window.dispatchEvent(new Event(`quoteSaved`));
            }, 1000);
            this.wizard.current && this.wizard.current.close();
            return;
        }
        this.setState({ quote: { ...this.state.quote, proposals_id: proposalId } }, () => {
            this.wizard.current && this.wizard.current.nextPage();
        });
    };

    renderProposalEditor = () => {
        const { project, printLanguage, printDateFormat, selectedSender, selectedAddress } = this.state;
        const params = this.tabQuotes.current && this.tabQuotes.current.getQuotePrintParams();
        return (
            <ProposalEditor
                printLanguage={printLanguage}
                printDateFormat={convertDateFormat(printDateFormat)}
                ref={this.proposalEditor}
                refreshQuoteData={() => {}}
                quoteHTML={params.html}
                checkPrivilege={this.context.functions.checkPrivilege}
                project={{ ...project, account: { id: project.account.id, name: project.account.name }, rights: [] }}
                quote={this.state.quote}
                noTopBar
                afterCreated={this.afterProposalCreated}
                overrideSender={selectedSender}
                overrideReceiver={{
                    label: selectedAddress?.custom_contact || (typeof selectedAddress?.contact == 'object' ? selectedAddress?.contact?.label : selectedAddress?.contact),
                    phone: selectedAddress?.phone,
                    email: selectedAddress?.email,
                }}
                onboardingContent={
                    this.onboardingMode
                        ? {
                              image: <ProposalIcon />,
                              header: this.tr('Add a proposal'),
                              description: this.htmlTr(
                                  'The proposal tool allows you to create diverse and visual proposals, with written text, images and PDFs, that you can attach together with your sales quote.${linebreak}To make your life even easier, you can create and add templates for cover pages and content sections.',
                                  {
                                      linebreak: [<br />, <br />],
                                  }
                              ),
                          }
                        : undefined
                }
            />
        );
    };

    getTotals = () => {
        const { rows } = this.state;
        let subtotal = 0;
        let vatTotal = 0;
        let vatTotals = {};
        let total = 0;
        rows.forEach((row) => {
            const rowSubtotal = Number(row.sellingPrice) * Number(row.quantity);
            const rowVat = (Number(row.vat) / 100) * (Number(row.sellingPrice) * Number(row.quantity));
            const rowVatKey = Number(row.vat || 0).toFixed(2);
            const rowTotal = rowSubtotal + rowVat;
            subtotal += rowSubtotal;
            vatTotal += rowVat;
            vatTotals = {
                ...vatTotals,
                [rowVatKey]: (vatTotals[rowVatKey] || 0) + rowVat,
            };
            total += rowTotal;
        });
        const totals: any = {
            subtotal,
            vatTotal,
            vatTotals,
            total,
        };
        return totals;
    };

    renderQuoteRowEditor = () => {
        const { currency, header, rows, products } = this.state;
        const totals = this.getTotals();
        return (
            <div className={`${styles.content}`} style={{ width: 1000 }}>
                <div>
                    <div className={styles.box}>
                        <div className={styles.title}>
                            <div className={styles.icon}>
                                <Sell />
                            </div>
                            <div className={styles.right}>
                                <h2>{this.tr('Sales quote items')}</h2>
                                {/* <Tooltip title={this.tr('Items description')}>
                                    <InfoOutlined />
                                </Tooltip> */}
                            </div>
                        </div>
                        <div className={styles.rowEditor}>
                            <div className={styles.headerContainer}>
                                <input placeholder={this.tr('Add header here...')} value={header} onChange={this.onChangeHeader} />
                            </div>
                            {rows.length == 0 ? (
                                <span />
                            ) : (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {this.listColumns.map((c: any) => (
                                                <TableCell key={c.id} align={c.align || 'left'} width={c.width} className={c.id == 'order' ? styles.orderContainer : ''}>
                                                    {c.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.length == 0 ? (
                                            <span />
                                        ) : (
                                            rows.map((r, i) => (
                                                <TableRow key={`${r.id}_${i}`}>
                                                    {this.listColumns.map((c: any, i) => (
                                                        <TableCell
                                                            width={c.width}
                                                            key={`${r.id}_${i}_${c.id}`}
                                                            align={c.align}
                                                            className={c.id == 'order' ? styles.orderContainer : ''}
                                                            onClick={!c.noClick ? () => (r.product ? this.showProductSlider(r) : this.showRowSlider(r)) : undefined}
                                                        >
                                                            {this.renderCellValue(r, c.id)}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            )}
                            <div className={styles.actions}>
                                <button onClick={() => this.showRowSlider()}>{this.tr('Add item')}</button>
                                {products.length > 0 && <button onClick={() => this.showProductSlider()}>{this.tr('Add product')}</button>}
                            </div>
                            <div className={styles.grandTotal}>
                                <h2>{this.tr('Grand total')}</h2>
                                <div className={styles.totalValues}>
                                    <div className={styles.totalValue}>
                                        <h3>{this.tr('Subtotal')}</h3>
                                        <p>{this.context.functions.presentCurrency(totals?.subtotal || 0, currency)}</p>
                                    </div>
                                    <div>
                                        <div className={styles.totalValue}>
                                            <h3>{this.tr('Vat')}</h3>
                                            <p>{`${this.context.functions.presentCurrency(totals?.vatTotal || 0, currency)}`}</p>
                                        </div>
                                        {Object.values(totals?.vatTotals || {}).filter((vatValue) => (vatValue || 0) != 0).length > 0 && (
                                            <div className={styles.vatTotals} style={{ height: 17 * Object.values(totals?.vatTotals || {}).filter((vatValue) => (vatValue || 0) != 0).length + 17 }}>
                                                <hr />
                                                {Object.keys(totals?.vatTotals || {})
                                                    .sort((a, b) => Number(a) - Number(b))
                                                    .map((vat) => {
                                                        const vatValue = totals?.vatTotals[vat] || 0;
                                                        return vatValue == 0 ? null : (
                                                            <div key={vat} className={styles.vatTotalRow}>
                                                                <p>{`${vat} %:`}</p>
                                                                <p>{`${this.context.functions.presentCurrency(vatValue, currency)}`}</p>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <h3>{this.tr('Total')}</h3>
                                        <p>{this.context.functions.presentCurrency(totals?.total || 0, currency)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    setReceiverAddress = (address) => {
        const selectedAddress = {
            ...address,
            custom_contact: address.contact?.label,
        };
        this.setState({ selectedAddress });
    };

    setSender = (selectedSender) => {
        this.setState({ selectedSender });
    };

    onSaveAddress = async (address) => {
        this.context.functions.closeSlider();
        const addressData = {
            id: -1,
            ...address,
            contact: address.contact?.label,
        };
        const response = await DataHandler.put({ url: `accounts/${this.state.project.customers_id}/visiting_addresses/${this.state.project.companies_id}` }, { visiting_addresses: [addressData] });
        this.onChangeAddress({ ...addressData, id: response[0].id });
        setTimeout(this.getAddresses, 1000);
    };

    onAddAddress = ({ selectProps: { inputValue: address } }) => {
        const { receiverContacts } = this.state;
        const {
            taimerAccount: { showState },
        } = this.context;
        this.context.functions.showSlider(
            <FieldEditSlider
                useAnimatedSlider
                onSave={this.onSaveAddress}
                item={{ name: this.state.project?.account?.name, address, vatid: this.state.project?.account?.vatid }}
                fields={[
                    {
                        title: this.tr('Account'),
                        key: 'name',
                    },
                    {
                        title: this.tr('Address'),
                        key: 'address',
                    },
                    ...(showState
                        ? [
                              {
                                  title: this.tr('City'),
                                  key: 'city',
                              },
                              {
                                  title: this.tr('State'),
                                  key: 'state',
                              },
                              {
                                  title: this.tr('Zip'),
                                  key: 'postalcode',
                              },
                          ]
                        : [
                              {
                                  title: this.tr('Zip'),
                                  key: 'postalcode',
                              },
                              {
                                  title: this.tr('City'),
                                  key: 'city',
                              },
                          ]),
                    {
                        title: this.tr('Country'),
                        key: 'country',
                    },
                    {
                        title: this.tr('VAT no.'),
                        key: 'vatid',
                    },
                    {
                        key: 'contact',
                        title: this.tr('Contact'),
                        options: receiverContacts,
                        type: 'select',
                        allowValueOutsideOptions: true,
                        setOtherValuesWithSelection: (_, value) => {
                            return {
                                custom_contact: value.label,
                                email: value.email,
                            };
                        },
                    },
                    {
                        key: 'email',
                        title: this.tr('Email'),
                        validation: 'email',
                    },
                ]}
                title={this.tr('New receiver address')}
                open={true}
                onClose={() => this.context.functions.closeSlider()}
            />
        );
    };

    renderQuoteDetailsEditor = () => {
        const { quote, addresses, users, selectedAddress, selectedSender, receiverContacts } = this.state;
        const {
            taimerAccount: { showState },
        } = this.context;
        return (
            <div className={`${styles.content}`} style={{ width: 700 }}>
                <div>
                    <div className={styles.box}>
                        <div className={styles.title}>
                            <div className={styles.icon}>
                                <Star />
                            </div>
                            <div className={styles.right}>
                                <h2>{this.tr('Sales quote details')}</h2>
                                {/* <Tooltip title={this.tr('Details description')}>
                                    <InfoOutlined />
                                </Tooltip> */}
                            </div>
                        </div>
                        {!this.props.project && !this.props.quote && (
                            <ProjectTreeDropdown
                                ref={this.projectTreeDropdown}
                                label={this.tr('Project')}
                                value={this.state.project?.id}
                                disableBeforeInit={true}
                                route={'projects/dropdown_new'}
                                showWholeTrees={true}
                                queryParameters={{
                                    right: 'read',
                                    company: this.context.userObject.companies_id,
                                    context: 'bill_targeting',
                                }}
                                noOptionsMessage={AddProject}
                                noOptionsMessageProps={{
                                    selectProps: {
                                        companies_id: this.context.userObject.companies_id,
                                        onItemCreated: (item) => this.onProjectSelected(item, true),
                                        sliderWidth: 550,
                                    },
                                }}
                                treeDropdownProps={{
                                    activateBestMatch: true,
                                    highlightMatches: true,
                                    useTooltip: true,
                                    growOptionContainer: true,
                                    usePopper: true,
                                }}
                                onSelect={this.onProjectSelected}
                            />
                        )}
                        <OutlinedField value={quote.name} label={this.tr('Sales quote name')} onChange={this.onChangeName} callOnChangeOnKeyUp />
                        <DatePicker
                            className="date full"
                            date={quote.validUntil}
                            onChange={this.onChangeDate}
                            onInputChange={(_, date) => this.onChangeDate(date)}
                            label={this.tr('Valid until')}
                            dateFormat={this.context.userObject.dateFormat}
                            usePopper
                            popperBottom
                        />
                    </div>
                    <div className={`${styles.box} ${styles.fieldWithDetails}`}>
                        <div className={styles.title}>
                            <div className={styles.icon}>
                                <Person />
                            </div>
                            <div className={styles.right}>
                                <h2>{this.tr('Receiver')}</h2>
                                {/* <Tooltip title={this.tr('Receiver description')}>
                                    <InfoOutlined />
                                </Tooltip> */}
                            </div>
                        </div>
                        <DataList
                            label={this.tr('Receiver address')}
                            options={addresses}
                            onChange={this.onChangeAddress}
                            value={selectedAddress}
                            noOptions={(e) => (
                                <div className={styles.addAddress} onClick={() => this.onAddAddress(e)}>
                                    <AddCircleOutline />
                                    {this.tr('Add receiver address: ${address}', { address: e.selectProps?.inputValue })}
                                </div>
                            )}
                        />
                        {selectedAddress && (
                            <SliderFieldGroup
                                editButtonLocation="top"
                                items={[
                                    {
                                        ...selectedAddress,
                                        contact: { label: selectedAddress.custom_contact || (typeof selectedAddress.contact == 'object' ? selectedAddress.contact.label : selectedAddress.contact) },
                                    },
                                ]}
                                onItemSaved={this.setReceiverAddress}
                                fields={[
                                    {
                                        key: 'name',
                                        title: this.tr('Account'),
                                    },
                                    {
                                        key: 'address',
                                        title: this.tr('Address'),
                                    },
                                    ...(showState
                                        ? [
                                              {
                                                  title: this.tr('City'),
                                                  key: 'city',
                                              },
                                              {
                                                  title: this.tr('State'),
                                                  key: 'state',
                                              },
                                              {
                                                  title: this.tr('Zip code'),
                                                  key: 'postalcode',
                                              },
                                          ]
                                        : [
                                              {
                                                  title: this.tr('Zip code'),
                                                  key: 'postalcode',
                                              },
                                              {
                                                  title: this.tr('City'),
                                                  key: 'city',
                                              },
                                          ]),
                                    {
                                        key: 'country',
                                        title: this.tr('Country'),
                                    },
                                    {
                                        key: 'vatid',
                                        title: this.tr('VAT no.'),
                                    },
                                    {
                                        key: 'contact',
                                        title: this.tr('Contact'),
                                        options: receiverContacts,
                                        type: 'select',
                                        allowValueOutsideOptions: true,
                                        setOtherValuesWithSelection: (_, value) => {
                                            return {
                                                custom_contact: value.label,
                                                email: value.email,
                                                phone: value.phone,
                                            };
                                        },
                                    },
                                    {
                                        key: 'phone',
                                        title: this.tr('Phone'),
                                    },
                                    {
                                        key: 'email',
                                        title: this.tr('Email'),
                                        validation: 'email',
                                    },
                                ]}
                            />
                        )}
                    </div>
                    <div className={`${styles.box} ${styles.fieldWithDetails}`}>
                        <div className={styles.title}>
                            <div className={styles.icon}>
                                <SendRounded />
                            </div>
                            <div className={styles.right}>
                                <h2>{this.tr('Sender')}</h2>
                                {/* <Tooltip title={this.tr('Sender description')}>
                                    <InfoOutlined />
                                </Tooltip> */}
                            </div>
                        </div>
                        <DataList label={this.tr('Sender contact')} options={users} onChange={this.onChangeSender} value={selectedSender} />
                        {selectedSender && (
                            <SliderFieldGroup
                                editButtonLocation="top"
                                items={[selectedSender]}
                                onItemSaved={this.setSender}
                                fields={[
                                    {
                                        key: 'name',
                                        title: this.tr('Name'),
                                        disabled: true,
                                    },
                                    {
                                        key: 'phone',
                                        title: this.tr('Phone'),
                                    },
                                    {
                                        key: 'email',
                                        title: this.tr('Email'),
                                        validation: 'email',
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    generateAddressLabel = (address) => {
        if (!address) return '';
        const items = [address.name, address.address, address.postalcode, address.city, address.country];
        return items.filter((item) => !!item).join(', ');
    };

    renderContentForExplainerSection = (section) => {
        const { project, quote, rows, selectedAddress, selectedSender } = this.state;
        switch (section) {
            case 'predetails':
                return (
                    <SliderFieldGroup
                        editingDisabled
                        items={[
                            {
                                ...quote,
                                customer: project?.account.name,
                                project: project?.name,
                            },
                        ]}
                        fields={[
                            {
                                key: 'customer',
                                title: this.tr('Account'),
                            },
                            {
                                key: 'project',
                                title: this.tr('Project'),
                            },
                        ]}
                    />
                );
            case 'details':
                return (
                    <SliderFieldGroup
                        editingDisabled
                        items={[
                            {
                                ...quote,
                                customer: project?.account.name,
                                project: project?.name,
                                to: this.generateAddressLabel(selectedAddress),
                                from: selectedSender?.label,
                                dealValue: rows.reduce((prev, row) => {
                                    const rowVat = (Number(row.vat) / 100) * (Number(row.sellingPrice) * Number(row.quantity));
                                    const rowSubtotal = Number(row.sellingPrice) * Number(row.quantity);
                                    prev += rowSubtotal + rowVat;
                                    return prev;
                                }, 0),
                            },
                        ]}
                        fields={[
                            {
                                key: 'customer',
                                title: this.tr('Account'),
                            },
                            {
                                key: 'project',
                                title: this.tr('Project'),
                            },
                            {
                                key: 'name',
                                title: this.tr('Sales quote name'),
                            },
                            {
                                key: 'validUntil',
                                title: this.tr('Valid until'),
                                type: 'date',
                            },
                        ]}
                    />
                );
            case 'receiverDetails':
                return (
                    selectedAddress && (
                        <SliderFieldGroup
                            items={[selectedAddress]}
                            fields={[
                                {
                                    key: 'name',
                                    title: this.tr('Account'),
                                },
                                {
                                    key: 'address',
                                    title: this.tr('Address'),
                                },
                                {
                                    key: 'postalcode',
                                    title: this.tr('Zip code'),
                                },
                                {
                                    key: 'city',
                                    title: this.tr('City'),
                                },
                                {
                                    key: 'country',
                                    title: this.tr('Country'),
                                },
                                {
                                    key: 'vatid',
                                    title: this.tr('VAT no.'),
                                },
                                {
                                    key: 'custom_contact',
                                    title: this.tr('Contact'),
                                },
                                {
                                    key: 'phone',
                                    title: this.tr('Phone'),
                                },
                                {
                                    key: 'email',
                                    title: this.tr('Email'),
                                    validation: 'email',
                                },
                            ]}
                        />
                    )
                );
            case 'senderDetails':
                return (
                    selectedSender && (
                        <SliderFieldGroup
                            items={[selectedSender]}
                            fields={[
                                {
                                    key: 'name',
                                    title: this.tr('Name'),
                                },
                                {
                                    key: 'phone',
                                    title: this.tr('Phone'),
                                },
                                {
                                    key: 'email',
                                    title: this.tr('Email'),
                                    validation: 'email',
                                },
                            ]}
                        />
                    )
                );
        }
    };

    renderExplainer = (currentPage) => {
        if (this.onboardingMode) {
            switch (currentPage) {
                case 1:
                    return {
                        image: <QuoteImage />,
                        header: this.tr('Creating your first sales quote'),
                        description: this.htmlTr('Let’s take you through creating and sending your first sales quote and proposal.${linebreak}Start by adding in the details and select a receiver.', {
                            linebreak: [<br />, <br />],
                        }),
                    };
                case 2:
                    return {
                        image: <AddItemsIcon />,
                        header: this.tr('Add items to sales quote'),
                        description: this.htmlTr('What are you selling? Start adding item or product rows to your sales quote.', {
                            linebreak: [<br />, <br />],
                        }),
                    };
            }
        }
        return (
            <div className={styles.explainer}>
                <ToggleableContainerList
                    sections={
                        currentPage == 1
                            ? [
                                  {
                                      key: 'predetails',
                                      title: this.tr('Sales quote details'),
                                      initiallyOpen: true,
                                  },
                              ]
                            : this.explainerSections
                    }
                    renderContentForSection={this.renderContentForExplainerSection}
                />
            </div>
        );
    };

    onQuoteHTMLReady = (quote) => {
        this.setState({
            ...this.formatQuote(quote),
        });
        this.wizard.current && this.wizard.current.nextPage();
    };

    onClose = (currentPage) => {
        if (currentPage == this.state.pages.length || this.state.quoteSaved) {
            window.dispatchEvent(new Event(`quoteSaved`));
            if (this.onboardingMode) { 
                this.context.functions.sendMixpanelEvent('sales_quote_wizard_completed');
                this.context.functions.sendMixpanelPeople('set', {
                    "sales_quote_wizard_end_date": new Date().toISOString(),
                });
                this.context.functions.onOnboardingItemCompleted('quote', this.props.sliderMode);
            }
        }
    };

    render() {
        const { project, pages, quote, quoteSaved } = this.state;
        return (
            <>
                <TaimerWizard
                    ref={this.wizard}
                    title={this.onboardingMode ? this.tr('Create your first sales quote') : this.props.quote ? this.tr('Edit sales quote') : this.tr('Create sales quote')}
                    pages={pages}
                    onClose={this.onClose}
                />
                {quoteSaved && (
                    <div className={styles.tabQuotes}>
                        <TabQuotes
                            onPrintQuoteReady={this.onQuoteHTMLReady}
                            ref={this.tabQuotes}
                            selectedQuoteId={quote?.id}
                            project={{ ...project, account: { id: project.account.id, name: project.account.name }, rights: [] }}
                            checkPrivilege={this.context.functions.checkPrivilege}
                            printMode
                        />
                    </div>
                )}
            </>
        );
    }
}

export default withSnackbar(QuoteWizard);
