import React from "react";

import List from "../List";
import moment from "moment";
import PropTypes from "prop-types";
import DataHandler from "../../general/DataHandler";
import MyHoursListRow from "../rows/MyHoursListRow";
import AdvancedSearch from "../../search/AdvancedSearch";
import TaimerComponent from "../../TaimerComponent";
import { format } from "date-fns";
import { DateRangePicker } from "./../../general/react-date-range/src";
import { SettingsContext } from "../../SettingsContext";
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import InsightDropDown from "../../dashboard/insights/InsightDropDown";
import DataList from "../../general/DataList";
import { ColumnHeaderButton } from '../ListHeader';
import { CheckCircleOutline, AssignmentReturned } from '@mui/icons-material';
import FileSaver from 'file-saver';
import { withSnackbar } from 'notistack';
import _ from 'lodash';

import { deleteWorkhourEntry } from '../../Data';

import "./MyHoursList.css";
import ModifiedHours from "../../workhours/time-tracker/ModifiedHours";
import VersionContentManager from "../../general/VersionContentManager";
import ModifiedHoursList from "./ModifiedHoursList";

import { ReactComponent as HoursListedIcon } from '../../dashboard/my_day/assets/list.svg';
import { ReactComponent as ModifiedEntriesIcon } from '../../dashboard/my_day/assets/bulk.svg';

class MyHoursList extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/list/MyHoursList");

        let dateRange = {
            startDate: format(
                moment()
                    .startOf("month")
                    .toDate(),
                "YYYY-MM-DD"
            ),
            endDate: format(
                moment()
                    .endOf("month")
                    .toDate(),
                "YYYY-MM-DD"
            ),
            key: "selection",
        };

        this.listModes = [
            {
              key: "hoursListed",
              label: this.tr("Hours listed"),
              action: () => {},
              icon: HoursListedIcon,
            },
            {
              key: "modifiedLog",
              label: this.tr("Modified entries"),
              action: () => this.onSelectViewMode("myHours-modified"),
              icon: ModifiedEntriesIcon,
            }
        ];      

        this.invoicingTypes = [
			{ value: 0, label: this.tr("All") },
			{ value: 1, label: this.tr("Billable") },
			{ value: 2, label: this.tr("Non billable") },
			{ value: 3, label: this.tr("Vacation") },
		];

        this.state = {
            data: [],
            dateRange,
            project: { id: 0, name: "" },
            customer: { id: 0, name: "" },
            invoicingType: this.invoicingTypes[0],
            page: 1,
            pageCount: 1,
            entryCount: 0,
            perpage: this.props.perpage,
            worktypes: [],
            loaded: false,
            deleteDialogContent: {},
            autoCompleteData: false,
            status: { value: 0, label: this.tr("All") },
            viewMode: 'hoursListed'
        };
        this.stickySearchKey = "my_hours_list";

        this.filtersInitialValues = {
            dateRange: dateRange,
            project: { id: 0, name: "" },
            customer: { id: 0, name: "" },
            invoicingType: this.invoicingTypes[0],
            perpage: this.props.perpage,
            sort: undefined
        };

        this.fields = [
            { field: "actions", name: "actions", header: "", width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, visibleInToolbar: true },
            { field: "checked", name: "checked", header: "", columnHeaderType: "checkbox", width: 50, showMenu: false, moveable: false, hideable: false, visibleInToolbar: true },
            { field: "date", type: "date", name: "date", header: this.tr("Date"), width: 103 },
            { field: "starttime", type: "number", name: "starttime", header: this.tr("Start time"), width: 103 },
            { field: "endtime", type: "number", name: "endtime", header: this.tr("End time"), width: 103 },
            { field: "hours", type: "number", name: "hours", header: this.tr("Hours"), width: 82 },
            { field: "customer", name: "customer", header: this.tr("Account"), width: 150 },
            { field: "project", name: "project", header: this.tr("Project"), width: 150 },
            { field: "task", name: "task", header: this.tr("Task"), width: 181 },
            { field: "jobtype", name: "jobtype", header: this.tr("Jobtype"), width: 143 },
            { field: "description", name: "description", header: this.tr("Description"), width: 300 },
            { field: "type", name: "type", header: this.tr("type"), width: 103 },
            ... (props.hasApprovalCompanies ? [{ field: "status", name: "status", header: this.tr("status"), width: 130, resizeable: false } ] : []),
            ... (props.hasApprovalCompanies ? [{ field: "decline_message", name: "decline_message", header: this.tr("Decline message"), width: 130, resizeable: true } ] : []),
            { field: "invoicing_type", name: "invoicing_type", header: this.tr("Invoicing type"), width: 103 },
            { field: "selling_price", name: "selling_price", header: this.tr("Selling price"), type: "number", width: 103 }
        ];

        this.allFields = this.fields.map(f => f.field);
        this.advancedSearchFields = this.fields
            .filter(f => f.field !== "checked" && f.field !== "actions" && f.field !== "date" && f.field !== "project" && f.field !== "customer" && f.field !== "type" && f.field !== "invoicing_type")
            .map(f => {
                let field = { field: f.field, transl: f.header, type: f.type };

                if (f.hasOwnProperty("autoCompleteData")) field.autoCompleteData = f.autoCompleteData;

                return field;
            });

        this.list = React.createRef();
        this.modifiedList = React.createRef();
        this.advancedSearch = React.createRef();
        this.pageChanged = this.pageChanged.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.setData = this.setData.bind(this);
        this.addRow = this.addRow.bind(this);
        this.dialogCancel = this.dialogCancel.bind(this);
        this.filtersAreInInitialState = this.filtersAreInInitialState.bind(this);
        this.initializeStickySearch = this.initializeStickySearch.bind(this);
        this.saveStickySearch = this.saveStickySearch.bind(this);
        this.searchTerms = undefined;
    }

    componentDidMount() {
        super.componentDidMount();
        this.getInitialData();
        this.listenReset();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.unListenReset();
    }

    getInitialData = async () => {
        const autoCompleteData = await this.props.getAutoCompleteData();
        const timeTrackerSettings = this.context.functions.getTimeTrackerSettings();
        this.setState({ autoCompleteData, timeTrackerSettings, loaded: true }, () => this.initializeStickySearch());
    }

    listenReset = () => {
        document.body.addEventListener("keyup", this._resetFilters);
    }

    unListenReset = () => {
        document.body.removeEventListener("keyup", this._resetFilters);
    }

    fetchPropsFilterData = async (props) => {
        let filters = {};
        let currentFilters = [];
        const status = props.status_filter_value ? this.props.propsStatusFilterValues.find(v => v.value == props.status_filter_value) : this.state.status;

        if (props.ids) {
            const fetchIds = props.ids.split(",");
            let showIds = _.cloneDeep(fetchIds);
            if (showIds.length > 5) {
                showIds = showIds.slice(0, 5);
                showIds.push(" ...");
            }

            const currentFilter = {
                entityMode: true,
                tooltip: this.tr("ID") + " " + this.tr("is") + " " + fetchIds.join(","),
                identifier: showIds.join(","),
                name: "workhour",
                nameTransl: this.tr("ID"),
                operator: "is",
                operatorTransl: this.tr("is"),
                value: showIds.join(","),
                fetchValue: fetchIds.join(",")
            }
            currentFilters.push(currentFilter);
        }

        this.searchTerms = {
            advanced_search_criteria: {
                filters: filters
            },
            currentFilters
        }
        this.searchTerms.mode = "advanced";

        this.setState({stickySearchInitialized: true, status}, () => this.fetchData());
	}

    initializeStickySearch() {
        if (this.props.timeTrackerProps?.status_filter_value || this.props.timeTrackerProps?.ids) {
            this.fetchPropsFilterData(this.props.timeTrackerProps);
            return;
        }

        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` }).done((response, _, request) => {
            if (request.status !== 200) {
                this.fetchData();
                return;
            }

            this.searchTerms = response.searchTerms;
            if (response.status_filter_value || response.status_filter_value == 0) {
                response.status = this.state.autoCompleteData?.statuses?.find(s => s.value == response.status_filter_value);
            }

            this.setState({ ...response }, () => this.fetchData({ ids: response.ids }, true, false, true));
        }).fail(response => {
            this.fetchData();

        }).always((response, _, request) => {
            this.setState({ stickySearchInitialized: true });
        });
    }


    saveStickySearch() {
        const { page } = this.state;
        let filters = {};
        for (let key in this.filtersInitialValues) {
            filters[key] = this.state[key];
        }

        filters.page = page;
        filters.searchTerms = this.searchTerms;
        filters.ids = this.searchTerms?.currentFilters?.find(c => c.name == "workhour")?.fetchValue?.split(",");
        filters.status_filter_value = this.state.status?.value;

        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}`, }, { search: filters });
    }

    filtersAreInInitialState() {
        const initial = _.cloneDeep(this.filtersInitialValues);
        delete initial.perpage;
        delete initial.sort;
        let filters = {};

        for (let key in initial) {
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = JSON.stringify(this.state[key]);
        }

        const freetext = this.searchTerms ? this.searchTerms.freetextSearchTerm : ""

        return _.isEqual(initial, filters) && freetext === "" && this.state.status?.value == 0;
    }

    _resetFilters = (evt) => {
        if (!evt || evt.keyCode == '27') {
            this.advancedSearch.current.clearSearch(undefined, true);
            this.advancedSearch.current.clearSearchTextInput();
            this.list.current.checkAll(false);
            this.searchTerms = undefined;

            this.setState({
                ...this.filtersInitialValues,
                status: { value: 0, label: this.tr("All") }
            }, () => this.fetchData({}, true, true));
        }
    }

    pageChanged(page) {
        this.list.current && this.list.current.startPageChangeAnimation();

        this.setState({page}, () => this.fetchData({}, false));
    }

    async fetchData(override = {}, updatePageCount = true, resetPage = false, initialFetch = false) {
        const { sort, status, page } = this.state;
        const ids = this.searchTerms?.currentFilters?.find(c => c.name == "workhour")?.fetchValue;
        this.props.setLoading && this.props.setLoading(true);
        this.list.current && this.list.current.setState({ isLoading: true});
        let parameters = {
            page,
            perpage: this.state.perpage,
            start: moment(this.state.dateRange.startDate).format("YYYY-MM-DD"),
            end: moment(this.state.dateRange.endDate).format("YYYY-MM-DD"),
            only_own: 1,
            project: this.state.project ? this.state.project.id : "0",
            customer: this.state.customer ? this.state.customer.id : "0",
            invoicingType: this.state.invoicingType.value,
            sort,
            resourcing: 0,
            status: status?.value
        };
        let postParams = {};
        postParams.ids = override.ids ? override.ids : ids;

        if (this.searchTerms !== undefined) {
            parameters.mode = this.searchTerms.mode;
            if (this.searchTerms.mode == "advanced") {
                postParams.advanced_search_criteria = JSON.stringify(this.searchTerms.advanced_search_criteria);
            } else {
                postParams.freetext = this.searchTerms.freetextSearchTerm;
            }
        }

        for (let oi in override) parameters[oi] = override[oi];

        if (override.get_all_ids) {
            const res = await DataHandler.autoRetryPost({ url: "timetracker/workhours", ...parameters }, postParams);

            this.props.setLoading && this.props.setLoading(false);
            this.list.current && this.list.current.setState({ isLoading: false });

            return res;
        }

        if (!initialFetch)
            this.saveStickySearch();

        try {
            const data = await DataHandler.autoRetryPost({ url: "timetracker/workhours", ...parameters }, postParams);
            this.list.current && this.list.current.setState({ isLoading: false});
            let entries = [];
            if (data.entries) {
                entries = data.entries;
                if (entries) {
                    entries = entries.filter(el => !(el.is_task > 0));

                    entries.forEach((entry) => entry.original = _.cloneDeep(entry));
                }
            }

            if (updatePageCount) {
                try {
                    const response = await DataHandler.autoRetryPost({ url: "timetracker/workhours", ...parameters, getcount: 1 }, postParams);
                    this.setState({
                        entryCount: response.count,
                        pageCount: response.page_count,
                    });
                } catch (err) {
                    console.error(err);
                };
            }

            // If fetched by ids, set daterange from workhours.
            if (postParams.ids && data.date_start && data.date_end) {
                let dateRange = {
                    startDate: data.date_start,
                    endDate: data.date_end,
                    key: "selection",
                };

                this.setState({ dateRange });
            }

            this.setData(entries);
            this.props.setLoading && this.props.setLoading(false);

            if (!initialFetch)
                this.props.getApprovalTotals && this.props.getApprovalTotals();
        }
        catch (err) {
            this.props.setLoading && this.props.setLoading(false);
            this.list.current && this.list.current.setState({ isLoading: false});
            console.error(err);
        };
    }

    setData(data) {
        this.setState({ data: data !== undefined || data.length > 0 ? data : [] }, this.list.current?.endPageChangeAnimation);
    }

    addRow() {
        this.list.current.addNewRow();
    }

    onChange = e => {
        const current = this.state[e.target.name];
        this.setState({ [e.target.name]: e.target.value });
        this.list.current.checkAll(false);

        if (current && typeof current === "object" && "id" in current) {
            this.fetchData({ [e.target.name]: e.target.value.id }, true, true);
        } else {
            this.fetchData({ [e.target.name]: e.target.value }, true, true);
        }
    };

    onChangeFilter = (name, value) => {
        if (name == "customer")
            this.setState({ [name]: value, project: null }, () => this.fetchData({}, true, true));
        else   
            this.setState({ [name]: value }, () => this.fetchData({}, true, true));

        this.list.current.checkAll(false);
    };

    onDateChange = event => {
        const { startDate, endDate } = event.selection;
        this.list.current.checkAll(false);

        this.setState(
            {
                page: 1,
                dateRange: {
                    startDate: format(startDate, "YYYY-MM-DD"),
                    endDate: format(endDate, "YYYY-MM-DD"),
                    key: "selection",
                },
            },
            () => this.fetchData({}, true)
        );
    };

    onDateInputChange = (dateType, date) => {
        const { endDate, startDate } = this.state.dateRange;
        date = format(date, "YYYY-MM-DD");
        this.list.current.checkAll(false);

        if (dateType == "start") {
            this.setState(
                {
                    page: 1,
                    dateRange: {
                        startDate: date,
                        endDate: endDate,
                        key: "selection",
                    },
                },
                () => this.fetchData({}, true, true)
            );
        } else {
            this.setState(
                {
                    page: 1,
                    dateRange: {
                        startDate: startDate,
                        endDate: date,
                        key: "selection",
                    },
                },
                () => this.fetchData({}, true, true)
            );
        }
    };

    deleteConfirm = (item) => {
        this.setState({ deleteDialogContent: item, showDeleteConfirmation: true });
    }

    getSelectedDateRange = () => {
        const dateRange = _.cloneDeep(this.state.dateRange);
        return dateRange;
    }

    delete = async () => {
        const { deleteDialogContent } = this.state;

        const response = await deleteWorkhourEntry(deleteDialogContent.id);

        if (response.status === "FAILED") {

            let msg = false;

            if (response.msg) {
                msg = response.msg;
            } else if (response.errorcode === 'ALREDY_BILLED') {
                msg = 'Hour is already billed';
            }

            msg = this.tr("Failed to delete workhour") + (msg ? `: ${this.tr(msg)}`  : "" ) + "!";
            this.props.enqueueSnackbar(msg, {
                variant: "error",
            });
        }
        else {
            this.fetchData({}, true, true);
            this.props.enqueueSnackbar(this.tr("Workhour deleted successfully!"), {
                variant: "success",
            });
        }
        this.setState({ showDeleteConfirmation: false });
    }

    deleteMultiple = async () => {
        const { deleteDialogContent } = this.state;

        const response = await DataHandler.post({ url: `timetracker/mass_delete` }, { ids: deleteDialogContent.deletableRows });

        if (response.notDeleted > 0) {
            const msg = this.tr("Failed to delete ${amount} workhours!", { amount: response.notDeleted });
            this.props.enqueueSnackbar(msg, {
                variant: "error",
            });
        }
        if (response.deleted > 0) {
            this.fetchData({}, true, true);
            this.props.enqueueSnackbar(this.tr("${amount} workhours deleted successfully!", { amount: response.deleted }), {
                variant: "success",
            });
        }
        this.list.current.checkAll(false);
        this.setState({ showDeleteConfirmation: false });
    }

    dialogCancel() {
        this.setState({ showDeleteConfirmation: false });
        this.list.current && this.list.current.setState({ isLoading: false});
    }

    fetchAllIds = async () => {
        const data = await this.fetchData({ get_all_ids: 1 }, false);
        return data.hours.map(e => e.id);
    }

    getSelectedRows = async () => {
        const allCheckedExcept = this.list.current.getAllCheckedExcept();
        let selected;

        if (allCheckedExcept) {
            const ids = await this.fetchAllIds();
            selected = ids.filter(id => {
                return !allCheckedExcept[id];
            });
        } else {
            selected = this.list.current.getCheckedRows();
        }

        return selected;
    }

    onToolbarExportClick = () => {
        if (this.modifiedList.current) {
            this.modifiedList.current.export();
        } else {
            this.export("xlsx", this.fields);
        }
    }

    onToolbarEditClick = async () => {
        let hours = await this.getSelectedRows();
        this.props.openEditDialog(hours);
    }

    unCheckAll = () => {
        this.list.current.checkAll(false);
    }

    onToolbarDeleteClick = async () => {
        const checkedRows = await this.getSelectedRows();
        const checkData = {
            ids: checkedRows,
            name: "delete",
            value: ""
        }

        DataHandler.post({ url: `timetracker/workhours/check_massedit` }, { data: checkData, module: "myHours" }).done((response) => {
            response.isMassDelete = true;
            this.setState({ deleteDialogContent: response, showDeleteConfirmation: true });
        })
    }

    export = async (target, fields) => {
        const columns = this.list && this.list.current ? this.list.current.visibleColumnsOrder : null;
        const exportHeaders = [];
        let params = {};

        let selected = this.list.current ? await this.getSelectedRows() : [];
        if (selected.length === 0)
            selected = await this.fetchAllIds();

        if (!columns || selected.length === 0) {
            this.props.enqueueSnackbar(this.tr("Nothing to export!"), {
                variant: "warning",
            });
            return;
        }

        _.forEach(columns, column => {
            _.forEach(fields, (field, i) => {
                if (column == field.name && column != "actions" && column != "checked") {
                    exportHeaders.push(field.header);
                }
            })
        })

        params.order       = columns;
        params.columnNames = exportHeaders;
        params.sort        = this.state.sort;
        params.currency    = this.context.taimerAccount.currency;
        params.company     = this.context.userObject.companies_id;
        params.file_name   = this.tr("my_hours_list_export");

        DataHandler.postArrayBuffer({ ...params, url: "timetracker/workhours/list_export/my_hours", export: target }, { ids: selected }, true).done(response => {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            FileSaver.saveAs(blob, `${params.file_name}.${target}`);
            this.list.current && this.list.current.setState({ isLoading: false});
        }).fail(() => {
            this.props.enqueueSnackbar(this.tr("Export failed!"), {
                variant: "error",
            });
            this.list.current && this.list.current.setState({ isLoading: false});
        });
    }

    renderDeleteDialogContent = () => {
        const { isMassDelete, deletableRows, notDeletableRows } = this.state.deleteDialogContent;

        if (isMassDelete) {
            const deletableAmount = deletableRows.length;
            const notDeletableAmount = notDeletableRows.length;
            const warningText = deletableAmount < 1 ? this.tr("No deletable workhours.") : this.tr("Are you sure you want to delete ${amount} workhours?", { amount: deletableAmount });
            const infoText = notDeletableAmount > 0 ? this.tr("${amount} workhours can not be deleted.", { amount: notDeletableAmount }) : "";

            return (
                <>
                    <p>{warningText}</p>
                    {notDeletableAmount > 0 &&
                        <ul><li>{infoText}</li></ul>
                    }
                </>
            )
        }
        else {
            let regExp = new RegExp("([a-zA-Z])");
            let delimiter = ".";
            this.dateFormat = this.context.userObject.dateFormat;
            this.dateFormat.split("").map(char => {
                if (regExp.exec(char) === null) {
                    delimiter = char;
                }
                return null;
            });
            const workhour = moment(this.state.deleteDialogContent.start).format("DD" + delimiter + "MM" + delimiter + "YYYY" + " HH:mm") + " - " + moment(this.state.deleteDialogContent.end).format("HH:mm");

            return (
                <p id="product-delete-dialog-warning">{this.tr("Are you sure you want to delete workhour \"${workhour}\"?", { workhour })}</p>
            )
        }
    }

    openHourEntryDialog = (data) => {
        this.context.functions.addHours(data, { parentComponent: "My Hours List", afterSaveCallback: this.onHourEntrySaved });
    };

    onHourEntrySaved = () => {
        setTimeout(() => {
            this.fetchData();
        }, 1000);
    };

    confirmHourSubmit = async (type, id = false) => {
        debugger;
        let selectedRows = [];
        if (!id)
            selectedRows = await this.getSelectedRows();
        else 
            selectedRows = [id];
        
        const fetchData = {
            ids: selectedRows,
            name: "status",
            type
        }

        let checkedData = {};
        try {
            checkedData = await DataHandler.post({ url: `timetracker/workhours/check_massedit` }, { data: fetchData, type: type});
        } catch (e) { }      
        
        this.props.openSubmitHoursDialog("myHours", type, {ids: selectedRows, ...checkedData});
    }

    onSelectViewMode = (viewMode) => this.setState({ viewMode });

    render() {

        if (this.state.viewMode == 'myHours-modified') {
            return <div className="MyHours">
                <ModifiedHoursList ref={this.modifiedList} autoCompleteData={this.props.resourcingAutoCompleteData} navigate={this.onSelectViewMode} />
            </div>
        }

        if (!this.state.stickySearchInitialized || !this.state.autoCompleteData) {
            return null;
        }
        const { customer, project, dateRange, worktypes, loaded, showDeleteConfirmation, timeTrackerSettings, autoCompleteData, invoicingType, status, page } = this.state;
        const { userObject } = this.context;
        const { noModifiedButton } = this.props;

        if (!loaded) {
            return <div />
        }

        const confirmDisabled = this.state.deleteDialogContent && this.state.deleteDialogContent.isMassDelete && this.state.deleteDialogContent.deletableRows.length < 1;
        const enable_approval_submitting = timeTrackerSettings?.enable_approval_submitting || (userObject.companies_id == "0" && autoCompleteData.enable_submit_companies.length > 0);
        
        return (
            <div className="contentBlock contentBlockMyHours">
                {showDeleteConfirmation &&
                    <MassDialog
                        onDialogClose={this.dialogCancel}
                        onDialogSave={this.state.deleteDialogContent.isMassDelete ? this.deleteMultiple : this.delete}
                        dialogType={"delete"}
                        dialogProps={{
                            testid: 'delete-workhours',
                            wider: true,
                            onCloseClick: this.dialogCancel,
                            open: showDeleteConfirmation,
                            close: this.dialogCancel,
                            confirmDisabled: confirmDisabled,
                            header: this.tr("Delete workhour?"),
                            warning: () => this.renderDeleteDialogContent(),
                            onConfirm: () => {
                                this.state.deleteDialogContent.isMassDelete ? this.deleteMultiple() : this.delete()
                            }

                        }}
                    />
                }
               
                <div className="listControlsContainer">
                    <div className="actionContainer clearfix">
                        <div className="workhoursLeft header-container primary">
                            <div className="drop-container drop-container-ddof">
                                <DateRangePicker
                                    className="daterange"
                                    ranges={[dateRange]}
                                    onChange={this.onDateChange}
                                    onInputChange={this.onDateInputChange}
                                    label={this.tr("Time span")}
                                    dateFormat={userObject.dateFormat}
                                />
                            </div>
                            <div className="drop-container">
                                <DataList
                                    label={this.tr("Account Name")}
                                    name="customer"
                                    options={autoCompleteData.accounts}
                                    value={customer}
                                    onChange={value => this.onChangeFilter("customer", value)}
                                    virtualized
                                    shownCount={20}
                                    isClearable={true}
                                />
                            </div>
                            <div className="drop-container">
                                <DataList
                                    label={this.tr("Project")}
                                    name="project"
                                    options={customer && customer.id != 0 ? autoCompleteData.projects.filter(p => p.customers_id == customer.id && !p.disabled) : autoCompleteData.projects.filter(p => !p.disabled)}
                                    value={project}
                                    onChange={value => this.onChangeFilter("project", value)}
                                    virtualized
                                    shownCount={20}
                                    isClearable={true}
                                />
                            </div>
                            <div className="drop-container">
                                <DataList
                                    className="invoicing-type"
                                    label={this.tr("Invoicing type")}
                                    name="invoicingType"
                                    options={this.invoicingTypes}
                                    value={invoicingType}
                                    onChange={value => this.onChangeFilter("invoicingType", value)}
                                    virtualized
                                />
                            </div>
                            <div className="drop-container">
                                <DataList
                                    label={this.tr("status")}
                                    options={this.state.autoCompleteData.statuses}
                                    value={status}
                                    onChange={val => this.onChangeFilter("status", val)}
                                    virtualized
                                />
                            </div>

                            <AdvancedSearch
                                ref={this.advancedSearch}
                                mode={this.searchTerms && this.searchTerms.mode ? this.searchTerms.mode : undefined}
                                initialFilters={this.searchTerms && this.searchTerms.currentFilters ? this.searchTerms.currentFilters : undefined}
                                mainConfig={this.searchTerms && this.searchTerms.advanced_search_criteria ? { operator: this.searchTerms.advanced_search_criteria.operator } : undefined}
                                freetextLabel={this.searchTerms ? this.searchTerms.freetextSearchTerm : ""}
                                alwaysShowClearFilters={!this.filtersAreInInitialState()}
                                onClearSearch={this._resetFilters}
                                fields={this.advancedSearchFields}
                                onSearchResult={this.setData}
                                autoCompleteData={autoCompleteData}
                                noRequests={true}
                                onSearchTrigger={searchTerms => {
                                    this.searchTerms = searchTerms;
                                    this.fetchData({}, true, true);
                                    this.list.current.checkAll(false);
                                }}
                                perpage={this.state.perpage}
                                autoCompleteData={autoCompleteData ? {
                                    jobtype: autoCompleteData['worktypes'],
                                } : {}}
                            />
                        </div>
                        {!noModifiedButton && <div className="workhoursRight">
                            <div>
                                <InsightDropDown
                                    title={this.tr("Show")}
                                    tabs={this.listModes}
                                    selected={"hoursListed"}
                                />
                            </div>
                        </div>}
                    </div>
                </div>
                <List
                    ref={this.list}
                    data={this.state.data}
                    noStateData
                    columns={this.fields}
                    sharedData={{
                        ...autoCompleteData,
                        worktypes,
                    }}
                    rowHeight={56}
                    height="fitRemaining"
                    className="myhoursList"
                    listRowType={MyHoursListRow}
                    ignoreRowPropsChange={false}
                    rowProps={{ 
                        currency: this.context.taimerAccount.currency, 
                        invoicingTypes: this.invoicingTypes,
                        openHourEntryDialog: this.openHourEntryDialog, 
                        list: this, 
                        tr: this.tr, 
                        onDelete: this.deleteConfirm, 
                        onUpdate: this.fetchData,
                        confirmHourSubmit: this.confirmHourSubmit,
                    }}
                    userListSettingsKey="timetracker_myhours"
                    saveColumnConfig={true}
                    page={page}
                    pageCount={this.state.pageCount}
                    totalCount={this.state.entryCount}
                    perpage={this.state.perpage}
                    showPageSelector={true}
                    enableToolbar={true}
                    onToolbarExportClick={this.onToolbarExportClick}
                    onToolbarEditClick={this.onToolbarEditClick}
                    onToolbarDeleteClick={this.onToolbarDeleteClick}
                    useAllCheckedExcept={true}
                    onPerPageChange={perpage => {
                        this.setState({ perpage: perpage });
                        this.fetchData({ perpage: perpage }, true, true);
                    }}
                    onPageChange={page => this.pageChanged(page)}
                    onSortRows={(name, asc) => {
                        this.setState({
                            sort: {
                                name: name,
                                asc: asc,
                            },
                        });

                        this.fetchData({
                            sort: {
                                name: name,
                                asc: asc,
                            },
                        }, true, true);
                    }}
                    additionalToolbarColumns={[
                        { name: "send_approval", header: "", columnHeaderType: "sendButton", width: 100 },
                        { name: "revoke", header: "", columnHeaderType: "revokeButton", width: 100 },
                    ]}
                    additionalToolbarButtons={enable_approval_submitting && !VersionContentManager.isFeatureHidden(this.namespace, 'hoursApproval') ? {
                        sendButton: <ColumnHeaderButton color={"#2D9FF7"} title={this.tr("Send for approval")} onClick={() => this.confirmHourSubmit("submit")} icon={CheckCircleOutline} />,
                        revokeButton: <ColumnHeaderButton color={"#716ACA"} title={this.tr("Revoke")} onClick={() => this.confirmHourSubmit("revoke")} icon={AssignmentReturned} />,
                    } : {}}
                    useHSRightPadding
                />

            </div>
        );
    }
}

MyHoursList.propTypes = {
    perpage: PropTypes.number.isRequired,
};

MyHoursList.defaultProps = {
    perpage: 30,
};

export default withSnackbar(MyHoursList);
