import TaimerComponent from '../TaimerComponent';

/* local components */
import DialogBorder from '../dialogs/DialogBorder';
import OutlinedField from '../general/OutlinedField';
import TranslationTool from './TranslationTool';

import { SettingsContext } from './../SettingsContext';

/* material-ui */
import { Button, CircularProgress, MenuItem, Switch } from '@mui/material';

/* data backend */
import Slider from '../general/Slider';
import DataHandler from './../general/DataHandler';

import styles from './DevOptions.module.scss';

const DEV_LOCATIONS = ['taimer-stack:3000', ':8080', 'localhost:3000', 'branches.dev.psa.heeros.com', 'dev.psa.heeros.com', 'dev.taimer.com'];

export default class DevOptions extends TaimerComponent {
    static contextType = SettingsContext;

    state = {
        dialog: false,
        inProgress: false,
        account: 0,
        project: 0,
        sliderOpen: false
    };

    onLangChange(lang) {
        localStorage.devLang = lang;
        setTimeout(() => {
            window.location.reload();
        }, 20);
    }

    onOriginChange(origin) {
        localStorage.devOrigin = origin;
        setTimeout(() => {
            window.location.reload();
        }, 20);
    }

    resetDb = (db) => {
        this.setState({ inProgress: 'reset', dialog: false });
        if (db != 'reset' && db != 'reset_test'&& db != 'test_automation' && db != 'cost_data') {
            return;
        }
        DataHandler.post({ url: 'dev/reset_db' }, { db: db }).done((e) => window.location.reload());
    };

    createAutomaticInvoices = (db, companyId) => {
        this.setState({ inProgress: 'create_automatic_invoices', dialog: false });
        if (db != 'reset' && db != 'react'&& db != 'test_automation') {
            return;
        }
        DataHandler.post({ url: 'dev/create_automatic_invoices' }, { db: db, companies_id: companyId }).done((e) => window.location.reload());
    };

    fakePerms = () => {
        if (this.context.fakePrivileges) {
            this.props.changeDevPerms(false);
            return;
        }

        DataHandler.post({ url: 'dev/fakeperms' }).done((response) => {
            // sessionStorage.setItem('taimerPrivileges', JSON.stringify() );

            this.props.changeDevPerms(response.privileges);
        });
    };

    changeDevVersion = (evt) => {
        const {
            functions: { getStorage, setTokenExpiration },
        } = this.context;
        const storage = getStorage();

        DataHandler.get({ url: 'auth/dev/' + evt.target.value }).done((response) => {
            storage.setItem('taimerToken', response.token);
            storage.setItem('taimerPrivileges', JSON.stringify(response.privileges));
            storage.setItem('taimerVersion', response.version);
            storage.setItem('taimerVersionId', response.version_id);
            storage.setItem('taimerAddons', JSON.stringify(response.addons));
            storage.setItem('onboardingEnded', response.onboarding_ended);
            sessionStorage.setItem('testVersion', true);
            setTokenExpiration();
            setTimeout(() => {
                window.location.reload();
            }, 20);
        });
    };

    randomName = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    generateAccount = async (projects, defaults) => {
        const newAccount = {
            name: `${this.randomName()}`,
            types: 'default',
        };

        const e = await DataHandler.post({ url: 'accounts/0' }, newAccount);

        const newProject = {
            ...defaults,
            account: e.id,
        };

        for (let i = 0; i < projects; i++) {
            this.setState({ project: i + 1, dialog: 'test', sliderOpen: false });
            await this.generateProject(newProject);
        }

        this.setState({ project: 0 });
    };

    generateProject = (data) => {
        data.name = `${this.randomName()}`;
        return DataHandler.post({ url: 'projects' }, data).done((e) => {});
    };

    generateTestData = async () => {
        const defaults = await DataHandler.get({ url: 'projects/0' });

        const accounts = parseInt(prompt('Number of accounts'));
        const projects = parseInt(prompt('Number of projects in account'));

        this.setState({ account: 0, dialog: 'test', sliderOpen: false });

        for (let i = 0; i < accounts; i++) {
            this.setState({ account: i + 1, dialog: 'test', sliderOpen: false });
            await this.generateAccount(projects, defaults);
        }

        this.setState({ dialog: 'test', sliderOpen: false });
    };

    toggleSlider = () => {
        this.setState({ sliderOpen: !this.state.sliderOpen });
    }

    render() {
        if (!DEV_LOCATIONS.some((e) => window.location.href.indexOf(e) !== -1)) return null;

        const props = this.props;
        const { inProgress, sliderOpen } = this.state;
        const {
            taimerAccount: { origin },
        } = this.context;
        const {
            functions: { startWorkhourTimer, stopWorkhourTimer },
            addons,
        } = this.context;

        return (
            <>
                {this.props.children ? this.props.children(this.toggleSlider) : <Button className="devOptionsButton" onClick={this.toggleSlider}>{this.tr('Dev options')}</Button>}
                <Slider open={sliderOpen} title={this.tr("Dev options")} onClose={this.toggleSlider}>
                    <div className={styles.devOptions}>
                        <OutlinedField fullWidth label="Taimer version" name="dev_version" onChange={this.changeDevVersion} value={props.versionId} select>
                            <MenuItem value="1">Free crm</MenuItem>
                            <MenuItem value="2">Sales crm</MenuItem>
                            <MenuItem value="3">Project management</MenuItem>
                            <MenuItem value="4">Erp</MenuItem>
                            <MenuItem value="7">Free CRM (IG)</MenuItem>
                            <MenuItem value="8">Sales CRM (IG)</MenuItem>
                            <MenuItem value="5">Lean CRM (IG)</MenuItem>
                            <MenuItem value="6">Sales crm + projects (IG)</MenuItem>
                            <MenuItem value="10">Entry Free</MenuItem>
                            <MenuItem value="11">Entry Growth</MenuItem>
                            <MenuItem value="12">Entry Business</MenuItem>
                        </OutlinedField>

                        <OutlinedField
                            fullWidth
                            label="Choose language"
                            name="lang"
                            data-testid="select-language-field"
                            onChange={(evt) => {
                                if (evt.fromBlur) {
                                    return;
                                }
                                this.onLangChange(evt.target.value);
                            }}
                            value={localStorage.devLang || ''}
                            select
                        >
                            <MenuItem value="">User defined</MenuItem>
                            <MenuItem value="en">EN</MenuItem>
                            <MenuItem value="en_us">EN-US</MenuItem>
                            <MenuItem value="en_au">EN-AU</MenuItem>
                            <MenuItem value="en_ca">EN-CA</MenuItem>
                            <MenuItem value="fi">FI</MenuItem>
                            <MenuItem value="se">SE</MenuItem>
                            <MenuItem value="lt">LT</MenuItem>
                            <MenuItem value="nn">NO</MenuItem>
                            <MenuItem value="da">DK</MenuItem>
                            <MenuItem value="es">ES</MenuItem>
                            <MenuItem value="pt">PT</MenuItem>
                            <MenuItem value="it">IT</MenuItem>
                            <MenuItem value="fi_special1">FI Ourbusiness</MenuItem>
                            <MenuItem value="en_special1">EN Ourbusiness</MenuItem>
                        </OutlinedField>

                        <OutlinedField fullWidth label="List addons" name="addons" onChange={() => {}} select>
                            {Object.keys(addons)
                                .sort()
                                .map((i) => {
                                    return <MenuItem value={i}>{i}</MenuItem>;
                                })}
                        </OutlinedField>

                        <OutlinedField
                            fullWidth
                            label="Origin"
                            name="origin"
                            onChange={(evt) => {
                                if (evt.fromBlur) {
                                    return;
                                }
                                this.onOriginChange(evt.target.value);
                            }}
                            value={origin}
                            select
                        >
                            <MenuItem value="onboarding">Onboarding</MenuItem>
                            <MenuItem value="ingram">Ingram (T-Mobile)</MenuItem>
                        </OutlinedField>
                        <Button size='large' color="primary" className="menu-button-actions translate-button" onClick={() => this.setState({ dialog: 'translate', sliderOpen: false })}>
                            Translations
                        </Button>
                        <Button size='large' color="primary" className="menu-button-actions" onClick={this.fakePerms}>
                            {this.context.fakePrivileges ? 'Real' : 'Fake'} Permissions
                        </Button>
                        {(this.context.folder === 'reset' || this.context.folder === 'reset_test'  || this.context.folder === 'test_automation'  || this.context.folder?.includes('test_automation') || this.context.folder === 'cost_data') && (
                            <Button size='large' color="primary" className="menu-button-actions translate-button" onClick={() => this.setState({ dialog: 'reset', sliderOpen: false })} disabled={inProgress === 'reset'}>
                                {inProgress === 'reset' && <CircularProgress />}
                                Reset db
                            </Button>
                        )}
                        {(this.context.folder === 'reset' || this.context.folder === 'react'  || this.context.folder === 'test_automation') && (
                            <Button size='large' color="primary" className="menu-button-actions translate-button" onClick={() => this.setState({ dialog: 'create_automatic_invoices', sliderOpen: false })} disabled={inProgress === 'create_automatic_invoices'}>
                                {inProgress === 'create_automatic_invoices' && <CircularProgress />}
                                Create automatic invoices
                            </Button>
                        )}
                        <div>
                            <Switch
                                name="us_installation"
                                color="primary"
                                onChange={() => {
                                    localStorage.testUS = localStorage.testUS === '1' ? '0' : '1';
                                    window.location.reload();
                                }}
                                checked={localStorage.testUS === '1'}
                            />
                            Test as US installation
                        </div>
                    </div>
                </Slider>
                {this.state.dialog === 'translate' && <TranslationTool onClose={() => this.setState({ dialog: false })} />}

                        {this.state.dialog === 'reset' && (
                            <DialogBorder title="Reset this db?" saveText="Reset" onClose={() => this.setState({ dialog: false })} onSave={() => this.resetDb(this.context.folder)}>
                                <div id="container" style={{ margin: 30 }}>
                                    You are about to reset this db to default. This can take a minute or two. After reset you will have to log in again. Do you want to continue?
                                </div>
                            </DialogBorder>
                        )}

                        {this.state.dialog === 'test' && (
                            <DialogBorder title="Add Test Data" saveText="Close" onClose={() => this.setState({ dialog: false })} onSave={() => this.setState({ dialog: false })}>
                                <div id="container" style={{ margin: 30 }}>
                                    Account {this.state.account} / Project {this.state.project}
                                </div>
                            </DialogBorder>
                        )}

                        {this.state.dialog === 'create_automatic_invoices' && (
                            <DialogBorder title="Create automatic invoices for this db?" saveText="Create invoices" onClose={() => this.setState({ dialog: false })} onSave={() => this.createAutomaticInvoices(this.context.folder, document.querySelector("#automatic_invoices_company").value)}>
                                <div id="container" style={{ margin: 30 }}>
                                    <span>Company id:  </span>
                                    <input id="automatic_invoices_company" type="number" value="1" placeholder="Companies id" />
                                </div>
                            </DialogBorder>
                        )}
            </>
        );
    }
}
