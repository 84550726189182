import React from 'react';
import TaimerComponent from "../TaimerComponent";
import _ from 'lodash';
import { SettingsContext } from './../SettingsContext';
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";
import DatePicker from "./../general/react-date-range/src/components/DatePicker";
import DataHandler from "../general/DataHandler";
import { format } from "date-fns";
import LabelFieldGroup from "../general/LabelFieldGroup";
import clone from "lodash/clone";
import { Header } from "../general/PaperUtils";
import Logo from "../invoices/Logo";
import TextAreaCell from '../list/cells/TextAreaCell';
import { AddAccount, AddContact } from './../general/no-options/AddItemComponents';
import DataList from "../general/DataList";
import { FlexChild } from "../general/FlexUtils";
import ReactSelect from "react-select";
export default class PurchaseOrderHeader extends TaimerComponent {
    // dummy commit
    static contextType  = SettingsContext;
    static defaultProps = {
        id: undefined,
        paperProps: {},
        listProps: {},
        onSave: () => {},
        supplierId: undefined,
        accountId: undefined,
        customerName:undefined,
        editMode: false
    };


    constructor(props, context) {
        super(props, context, "bills/PurchaseOrderHeader");

        this.context = context;
        /*[  
            "addContact"
          ].forEach(e => this[e] = this[e].bind(this));*/
 
        // this.generateClassName = createGenerateClassName({
        //     dangerouslyUseGlobalCSS: true
        // });

        // Later redefined in initState, but the initialized property is required during the first render.
        this.state                    = { initialized: false, nonValidFields: [],  contacts: [], rows: [], headerDataLoaded: false, logoLoaded: this.context.taimerAccount.showLogo != 1, printed: false };
        this.stateBeforeEditMode      = { ...this.state };

        this.supplierContactsPool     = [];
        this.createdDeliveryAddresses = [];
        this.createdAccountIds        = [];

        this.list                 = React.createRef();
        this.logo                 = React.createRef();
        this.initState            = this.initState.bind(this);
        this.initializeData       = this.initializeData.bind(this);
        this.editAddress          = this.editAddress.bind(this);
        this.editDeliveryAddress  = this.editDeliveryAddress.bind(this);
        this.setDeliveryAddress   = this.setDeliveryAddress.bind(this);
        this.getDeliveryAddresses = this.getDeliveryAddresses.bind(this);

        this.pristineData         = undefined;
        this.deliveryAddressModel = {
            account: undefined,
            address: undefined,
            postalcode: undefined,
            city: undefined,
            state: undefined,
            country: undefined,
            contact: undefined,
            phone: undefined,
            email: undefined
        };
    }

    componentDidMount() {
        if (isNaN(parseInt(this.props.id)))
            this.initState(false, this.props.company);
        else
            this.initState(this.props.data, this.props.company);    
    }

    getDeliveryAddress = () => {
        return _.cloneDeep(this.state.deliveryAddress);
    }

    getComponentData = () => {
        const classData = [
            "createdAccountIds",
            "createdDeliveryAddresses"
        ];

        const stateData = [
            "deliveryAddress",
            "deliveryAddresses",
            "accounts",
            "supplierId",
            "deliveryDate",
            "estimatedDueDate",
            "orderedDate",
            "orderUserId",
            "supplierContactId",
            "accountId",
            "supplierContactId",
            "deliveryAddressId",
            "misc"
        ];

        let data = {};
        stateData.forEach(d => data[d] = _.cloneDeep(this.state[d]));
        classData.forEach(d => data[d] = _.cloneDeep(this[d]));

        return data;
    }
    
        /*async addContact (contact) {
        if(!contact.id)
            return

            let contacts = await this.getSupplierContacts();                                  
            this.supplierContactsPool = this.transformData(contacts.suplier_contacts); 
            this.filterSupplierContacts(this.state.supplierId);
            this.setState({ 
                supplierContactId: {
                    id:contact.id, 
                    firstname:contact.firstname,
                    lastname:contact.lastname, 
                    label:contact.lastname +" "+ contact.firstname,
                    name:contact.lastname +" "+ contact.firstname
                } }); 
            return;
           
    } */


    async initState(details = false, company = "1") {
        const state   = {
            nonValidFields: [],
            id: details && !details.quoteId ? details.id : -1,
            customerCreatePrivilege: this.context.functions.checkPrivilege("customers", "write", company),

            orderedDate: details && details.order_date ? details.order_date : "",
            estimatedDueDate: details && details.duedate ? details.duedate : "",
            deliveryDate: details && details.delivery_date ? details.delivery_date : "",

            supplierId: details ? details.customers_id : undefined,
            customerName: details ? details.customer : "",
            supplierContactId: details ? details.customers_contacts_id : undefined, 
            supplierContact: undefined, 
            contact_email: details ? details.contact_email : undefined, 
            contact_phone: details ? details.contact_phone : undefined, 
            supplierAddress: undefined,
            supplierAddressId: undefined,
            deliveryAddressId: undefined,
            
            tempSupplier:        undefined,
            tempDeliveryAccount: undefined,
            tempDeliveryAddress: undefined,

            orderUserId: details && details.users_id ? details.users_id : this.context.userObject.usersId,
            orderEmail: details ? details.order_email : this.context.userObject.email,

            newDeliveryAddress: false,
            deliveryAddress: {
                account: details.delivery_name || "",
                address: details.delivery_address || "",
                postalcode: details.delivery_postalcode || "",
                city: details.delivery_city || "",
                state: details.delivery_state || "",
                country: details.delivery_country || "",
                contact: details.delivery_contact || "",
                phone: details.delivery_phone || "",
                email: details.delivery_email || "",
                delivery_method: details.delivery_method || ""
            },
            editedAddress: undefined,
            deliveryAddressEdited: false,
            misc: details ? details.misc : "",
            suppliers: [],
            accounts: [],
            supplierAddresses: [],
            deliveryAddresses: [],
            company: company,
            user: {},
            contactCreatePrivilege: false
        };

        this.initializeData(state);
    }


    async initializeData(state = {}) {
        const address          = await this.getAddress(state.company);
        const suppliers        = this.transformData(this.props.accounts);
        const supplierContacts = await this.getSupplierContacts(state.supplierId);
        const accounts         = this.transformData(await this.getAccounts(state.company));
       
        const users = this.props.users.map(u => {
            u.label = `${u.lastname} ${u.firstname}`;
            if (u.companies_id == 0) {
                u.label += " (" + this.tr("Freelancer") + ")";
                u.name = u.label;
            }
            u.value = u.id;
            return u;
        });

        const allUsers = this.props.allUsers.map(u => {
            u.label = `${u.lastname} ${u.firstname}`;
            if (u.companies_id == 0) {
                u.label += " (" + this.tr("Freelancer") + ")";
                u.name = u.label;
            }
            u.value = u.id;
            return u;
        });

        const user = allUsers.find(u => u.id == state.orderUserId);
        this.supplierContactsPool = supplierContacts || [];
        const supplierId = state.supplierId !== undefined ? state.supplierId : this.state.supplierId;
        const contactCreatePrivilege = await this.checkContactCreatePrivilege(supplierId);
        
        const { onPrintRenderReady, poDataLoaded } = this.props;
        const { logoLoaded, printed } = this.state;
        const print = onPrintRenderReady && poDataLoaded && logoLoaded;

        this.setState({
            ...state,
            initialized: true,
            address: address, 
            suppliers: suppliers, 
            supplierContacts: supplierContacts,
            supplierContact: supplierContacts.find(e => e.id == state.supplierContactId),
            accounts: accounts,
            user,
            users,
            allUsers,
            supplierId,
            contactCreatePrivilege,
            accountId: state.accountId !== undefined ? state.accountId : undefined,
            headerDataLoaded: true,
            printed: print
        }, () => {
            if(state.supplierId !== undefined)
                this.getSupplierAddresses(state.supplierId);

            if(this.state.accountId !== undefined)
                this.getDeliveryAddresses(state.accountId);
            
            setTimeout(() => {        
                !printed && print && this.props.onPrintRenderReady();
            }, 500);
        });
    }


    componentDidUpdate(prevProps, prevState) { // "Reset" the component by re-instantiating its state.
        if((prevProps.data !== this.props.data) || (prevProps.accounts !== this.props.accounts && !this.props.dontUpdateHeaderDetails)) {
            this.initState(this.props.data, this.props.company);
            
            if (this.props.dontUpdateHeaderDetails) {
                this.props.resetUpdateHeaderDetails();
            }
            
            return;
        }

        if(prevProps.editMode !== this.props.editMode) {
            this.setState({ deliveryAddressEditMode: false });
        }

        if(prevState.deliveryAddressId !== this.state.deliveryAddressId) {
            const newAddress = this.state.deliveryAddresses.find(address => address.id == this.state.deliveryAddressId);

            if(newAddress)
                this.setDeliveryAddress(newAddress);
        }
    }

    async getSuppliers(company) {
        return await DataHandler.get({ url: `/subjects/suppliers/${company}` });
    }

    getSupplierContacts = async (id) => {
        if (!id || Number(id) < 1) 
            return [];

        return await DataHandler.get({url: `accounts/${id}/contacts/${this.props.company}`});
    }


    getAccounts(company) {
        return DataHandler.get({ url: `/subjects/accounts/${company}` });
    }


    getAddress(company) {
        // TODO: Replace with user's (or the purchase order's) company
        return DataHandler.get({ url: `/settings/company/${company}/address` });
    }


    async getSupplierAddresses(supplierId, supplierAddressId = undefined) {
        const supplierAddresses = this.transformData(await DataHandler.get({ url: `/subjects/addresses/${supplierId}` }));

        this.setState({
            supplierAddresses: supplierAddresses,
            supplierAddressId: this.props.id === undefined ? Array.isArray(supplierAddresses) && supplierAddresses.length > 0 ? supplierAddressId !== undefined ? supplierAddressId : supplierAddresses[0].id : undefined : undefined
        });
    }


    async getDeliveryAddresses(customerId, deliveryAddressIdP = false) {
        const isNew = this.props.id === undefined || Number(this.props.id) < 0;

        const deliveryAddresses = this.transformData(await DataHandler.get({ url: `/accounts/${customerId}/delivery_addresses/${this.props.company}` }));
        const deliveryAddressId = deliveryAddressIdP || (isNew ? Array.isArray(deliveryAddresses) && deliveryAddresses.length > 0 ? deliveryAddresses[0].id : undefined : undefined);
       
        let newState = {
            deliveryAddresses,
            deliveryAddressId,
        };

        if(isNew && deliveryAddressId === undefined)
            newState.deliveryAddress = clone(this.deliveryAddressModel);

        this.setState(newState);
    }

    transformData(array) {
        if (!array) return;

        return array.map(element => {
            element.value = element.id;

            if(!element.hasOwnProperty("label"))
                element.label = element.name;

            return element;
        });
    }


    editAddress(name, value) {
        let editedAddress   = (this.state.editedAddress === undefined) ? {} : this.state.editedAddress;
        editedAddress[name] = value;

        this.setState({ editedAddress });
    }


    setDeliveryAddress(address) {
        for(let i in address)
            this.editDeliveryAddress(i, address[i]);
    }

    setAddressError(valid) {
        let nonValidFields = [];
        
        if (!valid)
            nonValidFields.push("email");

        this.setState({ nonValidFields });
    }

    editDeliveryAddress(name, value) {
        let deliveryAddress   = (!this.state.deliveryAddress) ? Object.assign({}, this.deliveryAddressModel) : this.state.deliveryAddress;
        deliveryAddress[name] = value;

        let { nonValidFields } = this.state;

        if(nonValidFields.indexOf(name) > -1)
            nonValidFields = nonValidFields.filter(f => f !== name);

        this.setState({ 
            nonValidFields, 
            deliveryAddress 
        });
    }

    getClassName = (columnName) => {            
        return "";
    }

    supplierCreated = async (supplier) => {
        const suppliers = this.state.suppliers.concat(supplier);
        suppliers.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase(), "fi"))
        this.setState({ suppliers }, () => { 
            this.supplierChanged(supplier);
            this.props.supplierChanged(supplier, false, suppliers);
        })
    }

    supplierChanged = async (supplier, company) => {      
        const contactCreatePrivilege = await this.checkContactCreatePrivilege(supplier?.id);
        const supplierContacts = await this.getSupplierContacts(supplier?.id);

        if (company) {
            this.setState({ supplierId: supplier?.id}, () => this.initState(false, company));
        }

        this.setState({ supplierId: supplier?.id, customerName: supplier?.name, contactCreatePrivilege, supplierContacts, supplierContactId: 0, supplierContact: undefined, contact_email: "", contact_phone: "" });
    }

    addNewContact = async (contact) => {
        setTimeout(async () => {
            const supplierContacts = await this.getSupplierContacts(this.state.supplierId);
            this.setState({ supplierContacts, supplierContact: contact, supplierContactId: contact.id, contact_email: contact.email, contact_phone: contact.phone }); 
        }, 1000);
    }

    checkContactCreatePrivilege = async (supplierId = false) => {
        const { functions: { checkPrivilege } } = this.context;
        const { company } = this.props;
        let supplierPrivileges = false;

        if (!supplierId || Number(supplierId) < 1) {
            return false;
        }

        try {
             supplierPrivileges = await DataHandler.get({ url: `/accounts/${supplierId}/company/${company}/rights` });
        } catch (e) {
            return false;
        }

        return supplierPrivileges.includes("write") && checkPrivilege("persons", "read", company);
    }

    getSelectedUser = () => {
        const { orderUserId, users, allUsers } = this.state;

		let response = users && users.find(e => e.id == orderUserId);
		if (!response) {
			const user = allUsers && allUsers.find(e => e.id == orderUserId);
			if (user) 
				response = user.name;
			else 
				response = false;
        }
		return response;
    }

    getSelectedSupplier = () => {
        const { all_accounts } = this.props;
        const { supplierId, suppliers } = this.state;
        if (!supplierId || Number(supplierId) < 1)
            return undefined;

		let response = suppliers && suppliers.find(e => e.id == supplierId);
		if (!response) {
			response = all_accounts && all_accounts.find(e => e.id == supplierId);
        }
		return response;
    }

    isDeliveryAddressEmpty = () => {
        let empty = true;
        Object.keys(this.deliveryAddressModel).forEach(e => {
            if (this.state.deliveryAddress[e] && this.state.deliveryAddress[e] != "")
                empty = false;
        })
        return empty;
    }
    
    noOptions = () => {
        return <p class="no-options">{this.tr("No options")}</p> 
    }

    getTranslation = (value) => {
        const { renderPrintPo, printLanguage } = this.props;
        return !renderPrintPo ? this.tr(value) : this.beTr(value, printLanguage || "en", {}, "general/backendTranslations/PurchaseOrderPrint");
    }

    onLogoLoaded = () => {
        const { onPrintRenderReady, poDataLoaded } = this.props;
        const { headerDataLoaded, printed } = this.state;
        const print = onPrintRenderReady && poDataLoaded && headerDataLoaded;

        setTimeout(() => {
            this.setState({ logoLoaded: true, printed: print }, () => !printed && print && this.props.onPrintRenderReady());
        }, 500);
    }

    render() {
        if(!this.state.initialized)
            return null;

        const { functions: { checkPrivilege }, taimerAccount: { showState } } = this.context;
        const { editMode, company } = this.props;
        const { user, contactCreatePrivilege, id, deliveryAddressEditMode } = this.state;

        const usMode = showState;    
        const isNew  = !id || Number(id) < 0;
        const name   = !isNew ? this.getTranslation("Purchase order") + " #" + id : this.tr("Create new purchase order");

        const selectedUser = this.getSelectedUser();
        const selectedSupplier = this.getSelectedSupplier();
        const isLogoLoaded = this.logo.current && this.logo.current.isImageLoaded();
        const noDeliveryAddress = this.isDeliveryAddressEmpty();
        const deliveryTooltipMessage= this.tr("You can add different delivery address. Will not show in print if empty.");
        const dateFormat = this.props.renderPrintPo ? this.props.printDateFormat : this.context.userObject.dateFormat;

        return (
                    <Header noFlex={true}>
                        <div className="purchaseorder-details">
                            <div className={`logo-container ${!isLogoLoaded ? "hide-print" : ""} ${!editMode && !isLogoLoaded ? "hide-logo-upload" : ""}`}>
                                {this.context.taimerAccount.showLogo == 1 ? <Logo onLogoLoaded={this.onLogoLoaded} ref={this.logo} editMode={editMode} company={company} base64Encode={true} /> : <div></div>}
                            </div>
                            <h1 className="purchaseorder-name">{name}</h1>
                            {!isNew && <p className="purchaseorder-info">
                                {this.getTranslation("Please add")} <strong><i>PO{id}</i></strong> {this.getTranslation("to invoice")} <strong><i>{this.getTranslation("your reference")}</i></strong> {this.getTranslation("field")}
                            </p>}
                        </div>
                        <div className={`address-container ${editMode ? "" : "field-group"}`}>
                        <LabelFieldGroup
                            editMode={editMode}
                            title={this.getTranslation("SUPPLIER")}
                            dontGenerateClassName={true}
                            fields={[
                                {
                                    id: "po-supplier-account",
                                    className: "not-editmode",
                                    label: this.getTranslation("Supplier name"), name: "company",
                                    value: this.state.customerName,
                                    editable: false,
                                    noOptionsProps: {company: this.state.company},
                                    editorType: TextAreaCell, 
                                },
                                {
                                    label: this.getTranslation("Contact"), name: "contact", 
                                    id: "po-supplier-contact",
                                    className: editMode ? (isNew ? "add-contact-field isNew" : "add-contact-field") : "not-editmode",
                                    editable: editMode,
                                    editorType: editMode ? DataList : TextAreaCell,
                                    value: editMode ? this.state.supplierContact : this.state.supplierContact?.label,
                                    noOptions: AddContact,
                                    noOptionsMessage: () => this.tr("No options"),                                    
                                    shownCount: 20,
                                    options: this.state.supplierContacts, 
                                    hideCreate: !contactCreatePrivilege,
                                    hideSelectsWhenNotMounted: true,
                                    companies_id: this.state.company,
                                    customers_id: this.state.supplierId,
                                    account: {id: this.state.supplierId},
                                    component: ReactSelect,
                                    company: this.state.company,
                                    onItemCreated: (contact) => this.addNewContact(contact),
                                    enqueueSnackbar: this.props.enqueueSnackbar,
                                    onChange: async (contact) => {
                                        this.setState({ supplierContact: contact, supplierContactId: contact.id, contact_email: contact.email, contact_phone: contact.phone });
                                    }
                                },
                                this.state.contact_email && this.state.contact_email.length > 0 && { label: this.tr("Email"), className: `not-editmode ${editMode ? "email" : ""}`, name: "contact_email", value: this.state.contact_email, onKeyUp: e => this.editAddress("email", e.target.value), editable: false, editorType: TextAreaCell },
                                this.state.contact_phone && this.state.contact_phone.length > 0 && { label: this.tr("Phone"), className: "not-editmode", name: "contact_phone", value: this.state.contact_phone, onKeyUp: e => this.editAddress("email", e.target.value), editable: false, editorType: TextAreaCell },
                                {
                                    label: this.getTranslation("Ordered"), name: "ordered_date", 
                                    date: this.state.orderedDate || "0000-00-00",
                                    editorType: DatePicker, 
                                    dateFormat: dateFormat,
                                    className: editMode ? "" : "no-calendar-icon",
                                    onInputChange: (name, date) => this.setState({ orderedDate: date }),
                                    onChange: date => this.setState({ orderedDate: date })
                                },
                                { 
                                    label: this.getTranslation("Est. payment date"), name: "estimated_duedate", 
                                    date: this.state.estimatedDueDate || "0000-00-00",
                                    className: "hide-print" + (editMode ? "" : " no-calendar-icon"),
                                    editorType: DatePicker, 
                                    dateFormat: dateFormat,
                                    onInputChange: (name, date) => this.setState({ estimatedDueDate: date }),
                                    onChange: date => this.setState({ estimatedDueDate: date })
                                },
                                { 
                                    label: this.getTranslation("Delivery date"), name: "delivery_date", 
                                    date: this.state.deliveryDate || "0000-00-00",
                                    editorType: DatePicker, 
                                    className: editMode ? "" : "no-calendar-icon",
                                    dateFormat: dateFormat,
                                    onInputChange: (name, date) => this.setState({ deliveryDate: date }),
                                    onChange: date => this.setState({ deliveryDate: date })
                                }
                            ]} />

                        <LabelFieldGroup
                            editMode={editMode}
                            editable={editMode}
                            title={this.getTranslation("ORDERED BY")}
                            values={this.state.address}
                            fields={[
                                { label: this.getTranslation("Orderer name"), name: "name", className: "not-editmode", editable: false, editorType: TextAreaCell },
                                { label: this.getTranslation("Address"), name: "address", className: "not-editmode", editable: false, editorType: TextAreaCell },
                                usMode && { label: this.getTranslation("City"), name: "city", className: "not-editmode", onKeyUp: e => this.editAddress("city", e.target.value), editable: false, editorType: TextAreaCell },
                                usMode && { label: this.getTranslation("State"), name: "state", className: "not-editmode", onKeyUp: e => this.editAddress("state", e.target.value), editable: false, editorType: TextAreaCell },
                                { label: this.getTranslation("Zip code"), name: "postalcode", className: "not-editmode", onKeyUp: e => this.editAddress("postalcode", e.target.value), editable: false, editorType: TextAreaCell },
                                !usMode && { label: this.getTranslation("City"), name: "city", className: "not-editmode", onKeyUp: e => this.editAddress("city", e.target.value), editable: false, editorType: TextAreaCell },
                                { label: this.getTranslation("Country"), name: "country", className: "not-editmode", onKeyUp: e => this.editAddress("country", e.target.value), editable: false, editorType: TextAreaCell },
                                { 
                                    label: this.getTranslation("Name"), name: "contact", editorType: editMode ? Select : TextAreaCell, options: this.state.users, editable: true,
                                    className: editMode ? "" : "not-editmode",
                                    editable: editMode,
                                    noOptionsMessage: () => this.tr("No options"),
                                    value: selectedUser ? selectedUser : "", 
                                    showStringValue: !selectedUser.id, 
                                    onChange: user => {
                                        let userObj = {
                                            id: user.id,
                                            name: `${user.lastname} ${user.firstname}`,
                                            email: user.hasOwnProperty("email") ? user.email : undefined
                                        };

                                        this.setState({
                                            user,
                                            orderUserId: userObj.id,
                                            orderEmail: userObj.email !== undefined ? userObj.email : this.state.orderEmail,
                                        });

                                    }, /*onKeyUp: e => this.editAddress("contact", e.target.value)*/
                                },
                                { label: this.getTranslation("Email address"), name: "email", className: `not-editmode ${editMode ? "email" : ""}`, value: user ? user.email : "", onKeyUp: e => this.editAddress("email", e.target.value), editable: false, editorType: TextAreaCell },
                                { label: this.getTranslation("Phone"), name: "phone", className: "not-editmode", value: user ? user.phone : "", onKeyUp: () => {}, editable: false, editorType: TextAreaCell },
                            ]} />

                        {noDeliveryAddress && !deliveryAddressEditMode && editMode &&
                            <FlexChild className="labelFieldGroup">
                                <h4 className="HasTooltip" title={deliveryTooltipMessage}>{this.getTranslation("DELIVERY")}</h4>
                                <a data-testid="add_delivery_address_button" style={{ marginLeft: "25%" }} onClick={() => this.setState({deliveryAddressEditMode: true})}>+{this.tr(' Add delivery address')}</a>
                            </FlexChild>
                        }

                        {(deliveryAddressEditMode || !noDeliveryAddress || !editMode) && 
                        <LabelFieldGroup
                            editMode={editMode && (deliveryAddressEditMode || !noDeliveryAddress)}
                            className={noDeliveryAddress ? "hide-print" : ""}
                            headerClassName="HasTooltip"
                            tooltipMessage={deliveryTooltipMessage}
                            title={this.getTranslation("DELIVERY")}
                            errorsInFields={this.state.nonValidFields.filter(f => ["email"].indexOf(f) > -1)}
                            fields={!editMode && noDeliveryAddress ? [] : [
                                {
                                    label: this.getTranslation("Deliverer name"),
                                    name: "account",
                                    className: editMode ? "" : "address-field not-editmode",
                                    editable: editMode ? true : false,
                                    value: isNew ? this.state.accountId : this.state.deliveryAddress.account,
                                    editorType: isNew ? CreatableSelect : (editMode ? undefined : TextAreaCell),
                                    formatCreateLabel: (value) => this.tr("Create") + " \"" + value + "\"",
                                    options: this.state.accounts,
                                    noOptionsMessage: () => this.tr("No options"),
                                    onChange: async (account) => {
                                        if(account.__isNew__) {
                                            const response = await DataHandler.post({ url: `/accounts/company/${this.props.company}`}, {
                                                name: account.value,
                                                types: "default"
                                            });
                                            this.createdAccountIds.push(response.id);

                                            this.setState({
                                                accounts: this.transformData(await this.getAccounts(this.props.company)),
                                                accountId: response.id,
                                                deliveryAddressEdited: false
                                            }, () => this.getDeliveryAddresses(response.id));
                                        } else {
                                            let state = { accountId: account.id };

                                            if(account.id !== this.state.accountId)
                                                state.deliveryAddressEdited = false;

                                            this.setState(state, () => this.getDeliveryAddresses(account.id));
                                        }

                                    }, onKeyUp: e => this.editDeliveryAddress("account", e.target.value)
                                },
                                { 
                                    label: this.getTranslation("Address"), name: "address",
                                    className: editMode ? "" : "address-field not-editmode",
                                    value: isNew ? this.state.deliveryAddressId : this.state.deliveryAddress.address,
                                    // disabled: !this.state.deliveryAddressEdited,
                                    editable: editMode,
                                    editorType: isNew ? CreatableSelect : (editMode ? undefined : TextAreaCell),
                                    options: this.state.deliveryAddresses, 
                                    formatCreateLabel: (value) => this.tr("Create") + " \"" + value + "\"",
                                    noOptionsMessage: () => this.tr("No options"),
                                    onChange: async (address) => {
                                        if (!isNew) {
                                            return;
                                        }

                                        if(address.__isNew__) {
                                            const response = await DataHandler.post({ url: `/accounts/${this.state.accountId}/delivery_address/${this.props.company}` }, { address: address.value })

                                            this.createdDeliveryAddresses.push(response.id);

                                            this.getDeliveryAddresses(this.state.accountId, response.id);
                                        } else {
                                            this.setState({ deliveryAddressId: address.id, deliveryAddress: address });
                                        }
                                    },
                                    onKeyUp: e => this.editDeliveryAddress("address", e.target.value),
                                    onClick: e => this.setState({ deliveryAddressEdited: true })
                                },
                                !usMode && {
                                    label: this.getTranslation("Zip code"), name: "postalcode", 
                                    value: this.state.deliveryAddress.postalcode,
                                    className: editMode ? "address-field" : "not-editmode",
                                    editable: editMode,
                                    editorType: editMode ? undefined : TextAreaCell,
                                    // disabled: !this.state.deliveryAddressEdited,
                                    onKeyUp: e => this.editDeliveryAddress("postalcode", e.target.value),
                                    onClick: e => this.setState({ deliveryAddressEdited: true })
                                },
                                {
                                    label: this.getTranslation("City"), name: "city", 
                                    value: this.state.deliveryAddress.city,
                                    className: editMode ? "address-field" : "not-editmode",
                                    // disabled: !this.state.deliveryAddressEdited,
                                    editable: editMode,
                                    editorType: editMode ? undefined : TextAreaCell,
                                    onKeyUp: e => this.editDeliveryAddress("city", e.target.value),
                                    onClick: e => this.setState({ deliveryAddressEdited: true })
                                },
                                usMode && {
                                    label: this.getTranslation("State"), name: "state", 
                                    value: this.state.deliveryAddress.state,
                                    className: editMode ? "address-field" : "not-editmode",
                                    // disabled: !this.state.deliveryAddressEdited,
                                    editable: editMode,
                                    editorType: editMode ? undefined : TextAreaCell,
                                    onKeyUp: e => this.editDeliveryAddress("state", e.target.value),
                                    onClick: e => this.setState({ deliveryAddressEdited: true })
                                },
                                usMode && {
                                    label: this.getTranslation("Zip code"), name: "postalcode", 
                                    value: this.state.deliveryAddress.postalcode,
                                    className: editMode ? "address-field" : "not-editmode",
                                    // disabled: !this.state.deliveryAddressEdited,
                                    editable: editMode,
                                    editorType: editMode ? undefined : TextAreaCell,
                                    onKeyUp: e => this.editDeliveryAddress("postalcode", e.target.value),
                                    onClick: e => this.setState({ deliveryAddressEdited: true })
                                },
                                {
                                    label: this.getTranslation("Country"), name: "country", 
                                    value: this.state.deliveryAddress.country,
                                    className: editMode ? "address-field" : "not-editmode",
                                    // disabled: !this.state.deliveryAddressEdited,
                                    editable: editMode,
                                    editorType: editMode ? undefined : TextAreaCell,
                                    onKeyUp: e => this.editDeliveryAddress("country", e.target.value),
                                    onClick: e => this.setState({ deliveryAddressEdited: true })
                                },
                                {
                                    label: this.getTranslation("Contact person"), name: "contact", 
                                    value: this.state.deliveryAddress.contact,
                                    className: editMode ? "address-field" : "not-editmode",
                                    // disabled: !this.state.deliveryAddressEdited,
                                    editable: editMode,
                                    editorType: editMode ? undefined : TextAreaCell,
                                    onKeyUp: e => this.editDeliveryAddress("contact", e.target.value),
                                    onClick: e => this.setState({ deliveryAddressEdited: true })
                                },
                                {
                                    label: this.getTranslation("Phone"), name: "phone", 
                                    value: this.state.deliveryAddress.phone,
                                    className: editMode ? "address-field" : "not-editmode",
                                    // disabled: !this.state.deliveryAddressEdited,
                                    editable: editMode,
                                    editorType: editMode ? undefined : TextAreaCell,
                                    onKeyUp: e => this.editDeliveryAddress("phone", e.target.value),
                                    onClick: e => this.setState({ deliveryAddressEdited: true })
                                },
                                {
                                    label: this.getTranslation("Email address"), name: "email", 
                                    value: this.state.deliveryAddress.email,
                                    className: editMode ? "address-field" : "not-editmode",
                                    // disabled: !this.state.deliveryAddressEdited,
                                    editable: editMode,
                                    editorType: editMode ? undefined : TextAreaCell,
                                    onKeyUp: e => this.editDeliveryAddress("email", e.target.value),
                                    onClick: e => this.setState({ deliveryAddressEdited: true })
                                },
                                {
                                    label: this.getTranslation("Delivery method"), name: "delivery_method", 
                                    value: this.state.deliveryAddress.delivery_method,
                                    className: editMode ? "address-field" : "not-editmode",
                                    // disabled: !this.state.deliveryAddressEdited,
                                    editable: editMode,
                                    editorType: editMode ? undefined : TextAreaCell,
                                    onKeyUp: e => this.editDeliveryAddress("delivery_method", e.target.value),
                                    onClick: e => this.setState({ deliveryAddressEdited: true })
                                }
                            ]} />
                        }
                    </div>
                    </Header>
        );
    }
}
