import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';

// Wrapper to support Drag & Drop of Projects
// Monthly view
class DateCellWrapper extends Component
{
	static contextTypes = {
		timetracker: PropTypes.object
	};

	constructor(props)
	{
		super(props)

		this.dragEnter = this.dragEnter.bind(this);
		this.dragLeave = this.dragLeave.bind(this);
		this.dragOver  = this.dragOver.bind(this);
		this.drop      = this.drop.bind(this);

		this.state = {over: false};
	}

	dragEnter(e)
	{
		if (e.dataTransfer.types.indexOf("event") === -1)
			return;

		this.setState({over: true});
	}

	dragLeave(e)
	{
		if (e.dataTransfer.types.indexOf("event") === -1)
			return;

		this.setState({over: false});
	}

	dragOver(e)
	{
		if (e.dataTransfer.types.indexOf("event") === -1)
			return;

		e.preventDefault();
	}

	drop(e)
	{
		if (e.dataTransfer.types.indexOf("event") === -1)
			return;
			
		const data = e.dataTransfer.getData("event");
		const event = JSON.parse(data);

		event.start = this.props.value;
		event.end = this.props.value;
		this.setState({over: false});

		this.context.timetracker.createNewEvent(event, new Date(event.start), new Date(event.end), true);
	}

	render() {
		const dateCompare = moment(this.props.value).format("YYYY-MM-DD"); 
        //const holiday = this.context.timetracker.holidays.find(date => date.date === dateCompare);
        const holiday = false;

		return (<div 
			className={cn("rbc-day-bg-wrapper", holiday ? "holiday" : "workday", this.state.over && "rbc-addons-dnd-over")} 
			onDragEnter={this.dragEnter} onDragLeave={this.dragLeave} onDrop={this.drop} onDragOver={this.dragOver}>
                <div>a</div>
			{this.props.children}
		</div>);
	}
}

DateCellWrapper.contextTypes = {
	timetracker: PropTypes.object
};

export default DateCellWrapper