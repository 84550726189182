import React from 'react';
import Emoticon from '@mui/icons-material/InsertEmoticon';
import { Picker } from 'emoji-mart';
import { getElementOffset } from '../helpers'
import { SettingsContext } from './../SettingsContext';
import { IconButton, ClickAwayListener } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import 'emoji-mart/css/emoji-mart.css'


const styles = theme => ({
    button: {
    },
    icon: {
    },
});

class EmojiPickerButton extends React.Component {
    static contextType = SettingsContext;

    constructor(props) {
		super(props);

		this.state = {
            open: false,
        };
    }

    open = (e) => {
        this.setState({open: true});
    }

    close = () => {
        this.setState({open: false});   
    }

    select = (emoji) => {
        const { onSelect } = this.props;

        onSelect && onSelect(emoji);

        this.close();
    }

    render() {
        const { classes, top, disabled } = this.props;
        const { userObject } = this.context;
        const { open } = this.state;

        return (
            <React.Fragment>
                <IconButton
                    disabled={disabled}
                    classes={{root: classes.button}}
                    onClick={this.open}
                    size="large">
                    <Emoticon classes={{root: classes.icon}}  />
                </IconButton>
                {open && <div className={`TeamChatEmojiPopup${top ? "Top" : "Bottom"}`}>
                    <ClickAwayListener onClickAway={this.close}>
                        <Picker
                            emoji="" title="" 
                            onSelect={this.select}
                            set="twitter" />
                    </ClickAwayListener>
                </div>}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(EmojiPickerButton);