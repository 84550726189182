import React from 'react';
import TaimerComponent from "../../TaimerComponent";
/* local components */
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import Container from '../components/IntegrationContainer';
import Quickbooks from '../api-settings/Quickbooks';
import Maventa from '../api-settings/Maventa';
import Procountor from '../api-settings/Procountor';
import Efina from '../api-settings/Efina';
import Docusign from '../api-settings/Docusign';
import Netvisor from '../api-settings/Netvisor';
import Talenom from '../api-settings/Talenom';
import RopoCapital from '../api-settings/RopoCapital';
import EmCe from '../api-settings/EmCe';
import Fennoa from '../api-settings/Fennoa';
import Wintime from '../api-settings/Wintime';
import Fivaldi from '../api-settings/Fivaldi';
import Mepco from '../api-settings/Mepco';
import MepcoV2 from '../api-settings/MepcoV2';
import Mediapro from '../api-settings/Mediapro';
import Nav from '../api-settings/Nav';
import Venda from '../api-settings/Venda';
import Taimer from '../api-settings/Taimer';
import Fortnox from '../api-settings/Fortnox';
import HubSpot from '../api-settings/HubSpot';
import MeritAktiva from '../api-settings/MeritAktiva';
import Xero from '../api-settings/Xero';
import Heeros from '../api-settings/Heeros';
import GoogleDrive from '../api-settings/GoogleDrive';
import ESalary from '../api-settings/ESalary';
import DATEV from '../api-settings/DATEV';
import NavCSV from '../api-settings/NavCSV';
import HR from '../api-settings/HR';
import Tietotili from '../api-settings/Tietotili';
import Eventos from '../api-settings/Eventos';
import SAP from '../api-settings/SAP';
import NetSuite from '../api-settings/NetSuite';

/* logos */
import MaventaLogo from '../img/maventa-logo2.png';
import NetvisorLogo from '../img/netvisor2.png';
import ProcountorLogo from '../img/procountor.png';
import QuickbooksLogo from '../img/quickbooks-logo2.png';
import TalenomLogo from '../img/talenom-logo2.png';
import VismaLogo from '../img/visma-logo.png';
import EfinaLogo from '../img/efina-logo.png';
import DocuSignLogo from '../img/docusign-logo2.png';
import RopoCapitalLogo from '../img/ropo.png';
import EmCeLogo from '../img/emce.png';
import FennoaLogo from '../img/fennoa-logo.png';
import FivaldiLogo from '../img/fivaldi2.png';
import MepcoLogo from '../img/mepco.png';
import NavLogo from '../img/microsoft-nav-logo.png'
import VendaLogo from '../img/venda.png';
import FortnoxLogo from '../img/fortnox-logo-new.png';
import HubSpotLogo from '../img/hubspot-logo.png';
import MeritAktivaLogo from '../img/meritaktiva.png';
import XeroLogo from '../img/xero_logo.png';
import HeerosLogo from '../img/heeros_logo.png';
import GoogleDriveLogo from '../img/google-drive2.png';
import DATEVLogo from '../img/datev.png';
import TietotiliLogo from '../img/tietotili.png';
import EventosLogo from '../img/eventos_logo2.png';
import PasseliLogo from '../img/passeli_logo.png';
import SAPLogo from '../img/sap-s4hana.png';
import NetSuiteLogo from '../img/NetSuite_logo.png';

import _ from "lodash";
import { withSnackbar } from 'notistack';

/* css */
import "../Settings.css";

class Accounting extends TaimerComponent {
    static contextType = SettingsContext
    constructor(props, context) {
        super(props, context, "settings/pages/Accounting");

        this.keys = {
            key: "procountor",
            key: "talenom",
            key: "netvisor",
            key: "tikon",
            key: "quickbooks",
            key: "docusign",
            key: "efina",
            key: "emce",
            key: "fennoa",
            key: "wintime",
            key: "mepco",
            key: "mepco_v2",
            key: "nav",
            key: "venda",
            key: "taimer",
            key: "fortnox",
            key: "hubspot",
            key: "meritaktiva",
            key: "xero",
            key: "heeros",
            key: "googledrive",
            key: "esalary",
            key: "datev",
            key: "navscv",
            key: "tietotili",
            key: "eventos",
            key: "sap",
            key: "netsuite",
        }

        this.state = {
            active: {
                procountor: false,
                talenom: false,
                netvisor: false,
                tikoninvoices: false,
                efina: false,
                nav: false,
                quickbooks: false,
                docusign: false,
                emce: false,
                fennoa: false,
                wintime: false,
                fivaldi: false,
                mepco: false,
                mepco_v2: false,
                mediapro: false,
                venda: false,
                taimer: false,
                fortnox: false,
                hubspot: false,
                meritaktiva: false,
                xero: false,
                heeros: false,
                googledrive: false,
                esalary: false,
                datev: false,
                navcsv: false,
                hr: false,
                tietotili: false,
                eventos: false,
                sap: false,
                netsuite: false,
            },
            companyAddress: {}
        }

        this.integrationSettings = {
            quickbooks: {
                id: 'quickbooks',
                name: "Quickbooks",
                component: Quickbooks
            },
            maventa: {
                id: 'maventa',
                name: "Maventa",
                component: Maventa
            },
            procountor: {
                id: 'procountor',
                name: 'Procountor',
                component: Procountor
            },
            efina: {
                id: 'efina',
                name: 'Efina',
                component: Efina
            },
            talenom: {
                id: 'talenom',
                name: 'Talenom',
                component: Talenom
            },
            docusign: {
                id: 'docusign',
                name: 'Docusign',
                component: Docusign
            },
            netvisor: {
                id: 'netvisor',
                name: 'Netvisor',
                component: Netvisor
            },
            ropocapital: {
                id: 'ropocapital',
                name: 'ropocapital',
                component: RopoCapital
            },
            emce: {
                id: 'emce',
                name: 'emce',
                component: EmCe
            },
            fennoa: {
                id: 'fennoa',
                name: 'fennoa',
                component: Fennoa
            },
            wintime: {
                id: 'wintime',
                name: "Wintime",
                component: Wintime
            },
            fivaldi: {
                id: 'fivaldi',
                name: 'Fivaldi',
                component: Fivaldi
            },
            mepco: {
                id: 'mepco',
                name: 'mepco',
                component: Mepco
            },
            mepco_v2: {
                id: 'mepco_v2',
                name: 'mepco_v2',
                component: MepcoV2
            },
            mediapro: {
                id: 'mediapro',
                name: 'mediapro',
                component: Mediapro
            },
            nav: {
                id: 'nav',
                name: 'nav',
                component: Nav
            },
            venda: {
                id: 'venda',
                name: 'venda',
                component: Venda
            },
            taimer: {
                id: 'taimer',
                name: 'taimer',
                component: Taimer
            },
            fortnox: {
                id: 'fortnox',
                name: 'fortnox',
                component: Fortnox
            },
            hubspot: {
                id: 'hubspot',
                name: 'hubspot',
                component: HubSpot
            },
            meritaktiva: {
                id: 'meritaktiva',
                name: 'meritaktiva',
                component: MeritAktiva
            },
            xero: {
                id: 'xero',
                name: 'xero',
                component: Xero
            },
            heeros: {
                id: 'heeros',
                name: 'heeros',
                component: Heeros
            },
            googledrive: {
                id: 'googledrive',
                name: 'googledrive',
                component: GoogleDrive
            },
            esalary: {
                id: 'esalary',
                name: 'esalary',
                component: ESalary
            },
            datev: {
                id: 'datev',
                name: 'datev',
                component: DATEV
            },   
            navcsv: {
                id: 'navcsv',
                name: 'navcsv',
                component: NavCSV
            }, 
            hr: {
                id: 'hr',
                name: 'hr',
                component: HR
            },          
            tietotili: {
                id: 'tietotili',
                name: 'tietotili',
                component: Tietotili
            }, 
            eventos: {
                id: 'eventos',
                name: 'eventos',
                component: Eventos
            },
            sap: {
                id: 'sap',
                name: 'sap',
                component: SAP
            },
            netsuite: {
                id: 'netsuite',
                name: 'netsuite',
                component: NetSuite
            }
        }

        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount(){
        super.componentDidMount();
        this.fetchData();
        this.getComapnyAddress();
        {this.props.error && this.showError(this.props.error)}
    }
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.fetchData();
    }
    fetchData(){

        const active = {
            procountor: false,
            talenom: false,
            talenom_bill: false,
            netvisor: false,
            tikoninvoices: false,
            efina: false,
            efina_bill: false,
            quickbooks: false,
            docusign: false,
            ropocapital: false,
            fennoa: false,
            wintime: false,
            mepco: false,
            mepco_v2: false,
            nav: false,
            venda: false,
            taimer: false,
            hubspot: false,
            meritaktiva: false,
            xero: false,
            heeros: false,
            esalary: false,
            navcsv: false,
            googledrive: this.state.active.googledrive,
            datev: false,
            hr: false,
            tietotili: false,
            eventos: false,
            sap: false,
            netsuite: false,
        };

        const { company } = this.props;
        return DataHandler.get({url: `settings/integrations/get/${company}`}).done(response => {
            _.forEach(response, api => {
                if(api.activated == 1){
                    active[api.api_name] = true;
                } else if (api.activated == -1){
                    active[api.api_name] = false;
                }
            })
            
            //If google drive's being active is solely based on personal athorization, then we have to fetch them separately. That however leads to many uncontrollable cases
            this.setState({active: active}/*,() => this.fetchGoogleDriveStatus()*/);
        });
    }

    getComapnyAddress = async () => {
        const { company } = this.props;
        const data = await DataHandler.get({url: `settings/company/${company}/address`});
        this.setState({companyAddress: data});
    }

    //We have to do this separately as long as drive is not implemented in integrations_settings and settings_extras, but as a user_integration instead
    fetchGoogleDriveStatus = () => {
        const { company } = this.props;
        DataHandler.post({ url: `drive/google/connect` }, {company: company})
        .done(response => {
            if (response.authenticated) {
                this.setState({
                    active:{
                        ...this.state.active,
                        googledrive: true,
                    }
                });
            }
        });
    }

    showError = (errors) => {
        _.map(errors, error => {
            _.map(error, e => {
                return this.props.enqueueSnackbar(this.tr("Please try again later or contact support and provide them with the following error. ${error}", {error: e}), {
                    variant: "error",
                    dissmissDuration: 10000
                });
            })
        })
    }

    render(){
        const { active } = this.state;
        const { keys, subpage, error } = this.props;
        const { tr } = this;
        const { taimerAccount: { origin } } = this.context;
        let country = "FI";
        if (['Viro', 'Eesti', 'Estonia', 'EE'].includes(this.state.companyAddress?.country)) {
            country = "EE";
        }

        if (!subpage){
            return (
            <div id="accounting-main-wrapper">
                <h3>{this.tr("Accounting Integration")}</h3>
                <div className="accounting-inner-wrapper">
                    <div className="content-block">
                        {origin !== "ingram" && <Container
                            active={active.heeros}
                            label={tr("Heeros HSI & HPI")}
                            name="heeros"
                            sublabel={this.tr("Heeros sales and purchase invoices")}
                            img={HeerosLogo}
                        /> }
                        {origin !== "ingram" && this.context.addons.show_esalary && <Container
                            active={active.esalary}
                            label={tr("eSalary")}
                            name="esalary"
                            sublabel={tr("Export hours, leaves and vacations to eSalary ")}
                            img={HeerosLogo}
                            activateInstantly={true}
                            company={this.props.company}
                        />}
                        {origin !== "ingram" && this.context.addons.show_hr && <Container
                            active={active.hr}
                            label={tr("Heeros HR")}
                            name="hr"
                            sublabel={tr("Sync users, hours, vacations and leaves")}
                            img={HeerosLogo}
                            company={this.props.company}
                        />}
                        {this.context.addons.nav && <Container
                            active={active.nav}
                            label={tr("Microsoft Dynamics NAV")}
                            name="nav"
                            sublabel=""
                            img={NavLogo}
                        /> }
                        {origin !== "ingram" && <Container
                            active={active.googledrive}
                            label={tr("Google Drive")}
                            name="googledrive"
                            sublabel={this.tr("Activate online files sharing")}
                            img={GoogleDriveLogo}
                            company={this.props.company}
                        /> }                        
                        {origin !== "ingram" && this.context.addons.open_api && <Container
                            active={active.taimer}
                            label={tr("Heeros PSA")}
                            name="taimer"
                            sublabel={this.tr("Integration between Heeros PSA and other applications")}
                            img={HeerosLogo}
                            company={this.props.company}
                            className="taimer"
                        /> }
                        {this.context.addons.venda && <Container
                            active={active.venda}
                            label={tr("Venda (Heeros)")}
                            name="venda"
                            sublabel=""
                            img={VendaLogo}
                        /> }   
                        {origin !== "ingram" && <Container
                            active={active.procountor}
                            label={tr("Procountor")}
                            name="procountor"
                            sublabel={this.tr("Transfer invoices & bills automatically")}
                            img={ProcountorLogo}
                        />}
                        {origin !== "ingram" && <Container
                            active={active.talenom || active.talenom_bill}
                            label={tr("Talenom")}
                            name="talenom"
                            sublabel={this.tr("Transfer invoices & bills automatically")}
                            img={TalenomLogo}
                        />}
                        {origin !== "ingram" && <Container
                            active={active.netvisor}
                            label={tr("Netvisor")}
                            name="netvisor"
                            sublabel={this.tr("Transfer invoices automatically")}
                            img={NetvisorLogo}
                        />}
                        {/* origin !== "ingram" && <Container
                            soon
                            active={active.tikoninvoices}
                            label={tr("Tikon")}
                            name="tikon"
                            sublabel={this.tr("Transfer invoices automatically")}
                            img={TikonLogo}
                        /> */}
                        {origin !== "ingram" && <Container
                            active={active.maventa}
                            label={<>{tr("Maventa")} <span>{tr("(Only in Finland)")}</span></>}
                            name="maventa"
                            sublabel={this.tr("Transfer invoices electronically to your customer")}
                            img={MaventaLogo}
                            company={this.props.company}
                        />}
                        <Container
                            active={active.quickbooks}
                            label={this.tr("Quickbooks (US)")}
                            name="quickbooks"
                            sublabel={this.tr("Transfer invoices electronically to your accounting software")}
                            img={QuickbooksLogo}
                            company={this.props.company}
                        />
                        <Container
                            active={active.docusign}
                            label={this.tr("DocuSign")}
                            name="docusign"
                            sublabel={this.tr("Used for signing documents")}
                            img={DocuSignLogo}
                        />
                        {origin !== "ingram" && <Container
                            active={active.efina || active.efina_bill}
                            label={tr("eFina")}
                            name="efina"
                            sublabel={this.tr("Authorized financial management service provider")}
                            img={EfinaLogo}
                        />}
                        {origin !== "ingram" && <Container
                            active={active.ropocapital}
                            label={tr("Ropo Capital")}
                            name="ropocapital"
                            sublabel={this.tr("Transfer invoices electronically to your customer")}
                            img={RopoCapitalLogo}
                        /> }       
                        {origin !== "ingram" && <Container
                            active={active.emce}
                            label={tr("EmCe")}
                            name="emce"
                            sublabel={this.tr("Generate invoice files for import")}
                            img={EmCeLogo}
                            activateInstantly={true}
                            company={this.props.company}
                        /> }
                        {origin !== "ingram" && <Container
                            active={active.fennoa}
                            label={tr("Fennoa")}
                            name="fennoa"
                            sublabel={this.tr("Transfer invoices automatically")}
                            img={FennoaLogo}
                            activateInstantly={true}
                            company={this.props.company}
                        /> } 
                        {origin !== "ingram" && this.context.addons.wintime && <Container
                            active={active.wintime}
                            label={tr("Wintime")}
                            name="wintime"
                            sublabel={this.tr("Export invoices over SFTP")}
                            img={VismaLogo}
                        /> }
                        {origin !== "ingram" && <Container
                            active={active.fivaldi}
                            label={tr("Fivaldi")}
                            name="fivaldi"
                            sublabel={this.tr("Transfer invoices & bills")}
                            img={FivaldiLogo}
                        /> } 
                        {origin !== "ingram" && !this.context.addons.show_mepco_v2 && <Container
                            active={active.mepco}
                            label={tr("Mepco")}
                            name="mepco"
                            sublabel={this.tr("Export work hour entries, vacation information and sick leaves.")}
                            img={MepcoLogo}
                            activateInstantly={true}
                            company={this.props.company}
                        /> }    
                        {origin !== "ingram" && this.context.addons.show_mepco_v2 && <Container
                            active={active.mepco_v2}
                            label={tr("Mepco")}
                            name="mepco_v2"
                            sublabel={this.tr("Export work hour entries, vacation information and sick leaves.")}
                            img={MepcoLogo}
                            activateInstantly={true}
                            company={this.props.company}
                        /> }
                        {origin !== "ingram" && this.context.addons.mediapro && <Container
                            active={active.mediapro}
                            label={tr("Mediapro")}
                            name="mediapro"
                            sublabel=""
                            imgText={<h1 style={{ alignSelf: "center", textTransform: "uppercase" }}>Mediapro</h1>}
                            activateInstantly={true}
                            company={this.props.company}
                        /> }
                        {origin !== "ingram" && <Container
                            active={active.fortnox}
                            label={tr("Fortnox")}
                            name="fortnox"
                            sublabel={this.tr("Transfer invoices (in Sweden only)")}
                            img={FortnoxLogo}
                        /> }  
                        {origin !== "ingram" && <Container
                            active={active.hubspot}
                            label={tr("HubSpot")}
                            name="hubspot"
                            sublabel={this.tr("Sync deals, companies and contacts with Taimer and HubSpot")}
                            img={HubSpotLogo}
                            company={this.props.company}
                        />}
                        {origin !== "ingram" && country == "EE" && <Container
                            active={active.meritaktiva}
                            label={tr("Merit Aktiva")}
                            name="meritaktiva"
                            sublabel={this.tr("Transfer invoices")}
                            img={MeritAktivaLogo}
                            company={this.props.company}
                        />}
                        {origin !== "ingram" && country == "FI" && <Container
                            active={active.meritaktiva}
                            label={tr("Passeli Merit")}
                            name="meritaktiva"
                            sublabel={this.tr("Transfer invoices")}
                            img={PasseliLogo}
                            company={this.props.company}
                        />}
                        {origin !== "ingram" && <Container
                            active={active.xero}
                            label={tr("Xero")}
                            name="xero"
                            sublabel={this.tr("Send sales invoices")}
                            img={XeroLogo}
                            company={this.props.company}
                        />}
                        {origin !== "ingram" && <Container
                            active={active.datev}
                            label={tr("DATEV")}
                            name="datev"
                            sublabel={tr("Export invoices")}
                            img={DATEVLogo}
                            activateInstantly={true}
                            company={this.props.company}
                        />}
                        {this.context.addons.navcsv && origin !== "ingram" && <Container
                            active={active.navcsv}
                            label={tr("NAV")}
                            name="navcsv"
                            sublabel={this.tr("Generate CSV-files for accounting")}
                            img={NavLogo}
                            company={this.props.company}
                            activateInstantly={true}
                        />}
                        {origin !== "ingram" && <Container
                            active={active.tietotili}
                            label={tr("TietotiliOnline")}
                            name="tietotili"
                            sublabel={this.tr("Send sales invoices and receive invoice payment information")}
                            img={TietotiliLogo}
                            company={this.props.company}
                        />}
                        {origin !== "ingram" && <Container
                            active={active.eventos}
                            label={tr("Eventos")}
                            name="eventos"
                            sublabel={tr("Sync exhibitor information from PSA to Eventos")}
                            img={EventosLogo}
                            activateInstantly={false}
                            company={this.props.company}
                        />}
                        {origin !== "ingram" && <Container
                            active={active.sap}
                            label={tr("SAP S/4HANA")}
                            name="sap"
                            sublabel={tr("Send invoices and sync customers")}
                            img={SAPLogo}
                            company={this.props.company}
                        />}
                        {origin !== "ingram" && <Container
                            active={active.netsuite}
                            label={tr("NetSuite")}
                            name="netsuite"
                            sublabel={tr("Generate CSV-files for accounting")}
                            img={NetSuiteLogo}
                            company={this.props.company}
                            activateInstantly={true}
                        />}
                    </div>
                </div>
            </div>
        )} else {
            const Component = this.integrationSettings[subpage].component;

            return(
                <div>{Component && <Component {...this.props} active={active[subpage]} fetchData={this.fetchData} />}</div>
            )
        }
    }
}

export default withSnackbar(Accounting);
