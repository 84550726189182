import React from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
import { SettingsContext } from './../SettingsContext';
import SendMessageBar from './SendMessageBar';
import './GroupView.css';
import cn from 'classnames';
import { renderWithEmoji } from '../helpers';
import moment from 'moment';
import AutoScroll from '../general/AutoScroll';
import TaimerAvatar from '../general/TaimerAvatar';
import TaimerComponent from '../TaimerComponent';
import { MoreHoriz, AccessTime as Saving } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText, Modal } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ReactComponent as ReminderIcon } from './icons/reminder.svg';
import ContextMenu from './../general/ContextMenu';
import _ from 'lodash';

const styles = theme => ({
    more: {
        '&active': {
            color: '#2d9ff7'
        }
    },
    primary: {
        color: '#6b7897',
    },
    icon: {
        fill: '#b7becd',
        margin: 0,
    },
});

class GroupViewMessage extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "team-chat/GroupViewMessage");

        this.state = {
            modalOpen: false,
        }
    }

    showActivityDialog = () => {
        const item = {
            customers_id: this.props.msg.customers_id,
            projects_id: this.props.msg.projects_id,
        };
        this.context.functions.openActivitySlider(item);
    }

    render() {
        const { classes, msg, msg: {
            message, timestamp, creator, creator_id, creator_color, attachments_id
        } } = this.props;

        const { userObject: { dateFormat, usersId: currentUser } } = this.context;

        let attach = null;

        const devParam = '&_auth=' + this.context.functions.getStorage().taimerToken;
        const attachUrl = attachments_id && this.context.functions.getDownloadPath() + `attachment=newsfeed&id=${attachments_id}&is_comment=false${devParam}`;

        const openModal = (e) => { 
            e.preventDefault();
            this.setState({modalOpen: true}); 
        }
        const closeModal = () => this.setState({ modalOpen: false });

        let modal = undefined;

        if (msg.attachment_type && msg.attachment_type.startsWith("image/")) {
            attach = <div><a onClick={openModal} href={attachUrl} target="_blank" rel="noreferrer"><img className="attachment" src={attachUrl} alt={msg.attachment_name} /></a></div>
            modal = <Modal className="TeamChatImageModalOuter" open={this.state.modalOpen} onClose={closeModal}>
                <div className="TeamChatImageModal" onClick={closeModal}><img src={attachUrl} alt={msg.attachment_name} /></div>
            </Modal>
        }
        else if (msg.attachment_type && msg.attachment_type.startsWith("video/")) {
            attach = <div><video src={attachUrl} controls /></div>
        }
        else if (attachments_id)
            attach = <div><a href={attachUrl} target="_blank" rel="noreferrer">{msg.attachment_name}</a></div>

        const isReverse = currentUser == creator_id;

        const ts = moment.utc(timestamp).local();
        let date = ts.format(dateFormat);

        const today = moment();
        const yesterday = moment().subtract(1, 'days');

        if (today.isSame(ts, "day"))
            date = this.tr("Today");
        else if (yesterday.isSame(ts, "day"))
            date = this.tr("Yesterday");

        return (<div className={cn("TeamChatGroupViewMessage", isReverse && "reverse")}>
            <div className="TeamMessageAvatar">
                <TaimerAvatar id={creator_id} name={creator} color={creator_color} size="medium" />
            </div>
            <div className="TeamMessageMain">
                <div className="TeamMessageInfo">
                    <div>
                        {creator}
                    </div>
                    {msg.pending ? <div className="TeamMessageSaving">
                        <Saving />
                    </div> : <div>
                            {date} {ts.format('LT')}
                        </div>}
                </div>
                <div className="TeamMessageMessageContainer">
                    <div className="TeamMessageMessage">
                        {message && renderWithEmoji(message)}

                        {attach}

                        {modal}
                    </div>
                </div>
            </div>
            <ContextMenu className="TeamChatActionsMenu" label={<MoreHoriz classes={{ root: isReverse ? classes.moreAlternate : classes.more }} />} noExpandIcon>
                <MenuItem onClick={this.showActivityDialog}>
                    <ListItemIcon classes={{ root: classes.icon }}>
                        <ReminderIcon className="contextSvg" />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.primary }} inset primary={this.tr("Activity")} />
                </MenuItem>
                {/* 
                    lisää tr kun tuot näkyviin
                    <MenuItem onClick={this.menuClose}>
                    <ListItemIcon classes={{root: classes.icon}}>
                        <TaskIcon className="contextSvg" />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.primary }} inset primary="Task" />
                </MenuItem>
                <MenuItem onClick={this.menuClose}>
                    <ListItemIcon classes={{root: classes.icon}}>
                        <WorkphaseIcon className="contextSvg" />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.primary }} inset primary="Workphase" />
                </MenuItem> */}
            </ContextMenu>
        </div>)
    }
}

class GroupView extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "team-chat/GroupView");

        this.refSendMessage = React.createRef();
        this.newMessageID = -1;

        this.state = {
            pendingMessages: [],
        };

        this.refAutoScroll = React.createRef();
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.refSendMessage.current.focus();
    }

    loadOlder = () => {
        const { data: { hasOlder, olderId, hasNewer, newerId } } = this.props;

        this.props.onNavigatePage({ before: olderId });
    }

    loadNewer = () => {
        const { data: { hasOlder, olderId, hasNewer, newerId } } = this.props;

        this.props.onNavigatePage({ after: newerId });
    }

    sendMessage = async (message, attachments) => {
        const { onSendMessage } = this.props;
        const { userObject } = this.context;

        const id = this.newMessageID--;

        this.setState((state) => ({
            pendingMessages: [...state.pendingMessages, {
                pending: true,
                type: '0',
                id,
                message,
                timestamp: moment(),
                creator: userObject.fullname,
                creator_id: userObject.usersId,
                creator_color: userObject.color,
                attachments_id: 0,
                comments: [],
            }]
        }));

        try {
            const realid = await onSendMessage(message, attachments);

            this.setState((state) => {
                const index = state.pendingMessages.findIndex(m => m.id == id);

                if (index !== -1) {

                    const pd = [...state.pendingMessages];

                    pd[index] = { ...pd[index], id: realid };

                    return {
                        pendingMessages: pd,
                    }
                }
                
                return {};
            });

            return realid;
        } catch (error) {
            return false;
        }
    }
    
    isAtBottom = () => {
        return this.refAutoScroll.current && this.refAutoScroll.scrolledToBottom;
    }

    render() {
        const { classes, className, data: { messages, hasOlder, olderId, hasNewer, newerId }, messageComponent: Component = GroupViewMessage,
            messageProps = {}, desc = false } = this.props;

        const { pendingMessages } = this.state;

        const loadOlder = hasOlder ? <div className="LoadMore" onClick={this.loadOlder}>{this.tr("View Older")}</div> : undefined;
        const loadNewer = hasNewer ? <div className="LoadMore" onClick={this.loadNewer}>{this.tr("View Newer")}</div> : undefined;

        if (!messages)
            return <div><Loading className='main-page-loading-indicator' /></div>

        const ids = messages ? _.map(messages, 'id') : [];

        const minid = _.min(ids) || 0;

        const pm = pendingMessages.filter(x => ids.indexOf(String(x.id)) === -1 && (x.id >= minid || x.id < 0));

        if (pm.length !== pendingMessages.length) {
            setTimeout( (x) => this.setState({pendingMessages: pm}) , 10 );
        }

        return (<div className={cn("TeamChatGroupView", className)}>
            <AutoScroll ref={this.refAutoScroll} disabled={desc} className="TeamChatMessages">
                {!desc ? loadOlder : loadNewer}
                {desc && pm.reverse().map((msg) => <Component classes={classes} key={msg.id} msg={msg} {...messageProps} />)}
                {messages && messages.map(msg => msg.id && <Component classes={classes} key={msg.id} msg={msg} {...messageProps} />)}
                {!desc && pm.map((msg) => <Component classes={classes} key={msg.id} msg={msg} {...messageProps} />)}
                {desc ? loadOlder : loadNewer}
            </AutoScroll>
            <div className="TeamChatSendMessageBar">
                <SendMessageBar ref={this.refSendMessage} disabled={this.props.disabled} onSendMessage={this.sendMessage} />
            </div>
        </div>)
    }
}

export default withStyles(styles)(GroupView);