import { addYears, format, isAfter, isBefore } from 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import Calendar from './Calendar';
//import { ReactComponent as calendarIcon } from '../../../../general/icons/date_range.svg';
import CalendarIcon from '@mui/icons-material/DateRange';
import { FormControl, InputLabel } from '@mui/material';
import Popper from '@mui/material/Popper';
import classnames from 'classnames';
import ClickAwayWrapper from "../../../ClickAwayWrapper";
import '../styles.css';
import '../theme/default.css';
import { SettingsContext } from './../../../../SettingsContext';
import TaimerComponent from './../../../../TaimerComponent';

class DatePicker extends TaimerComponent {
  static contextType = SettingsContext;

  constructor(props, context) {
    super(props, context, 'general/react-date-range/src/components/DatePicker');
    const { userObject } = this.context;

    this.state = {
      open: props.initialOpen,
      dateFormat: props.dateFormat ? props.dateFormat : userObject.dateFormat
    };

    this.input = React.createRef();
    this.anchorEl = React.createRef();
    this.anchorElCalendar = React.createRef();

    const regExp = new RegExp("([a-zA-Z])");

    this.state.dateFormat.split("").map(char => {
      if (regExp.exec(char) === null) {
        this.delimeter = char;
      }
      return null;
    });

    this.formatArray = this.state.dateFormat.split(this.delimeter).map(el => el.toLowerCase());
  }

  componentDidUpdate(prevProps, prevState) {
		if(prevProps.dateFormat !==  this.props.dateFormat) {
      this.setState({ dateFormat: this.props.dateFormat }, () => this.onDateFormatChacge());
    }
  }
  
  onDateFormatChacge = () => {
    const regExp = new RegExp("([a-zA-Z])");

    this.state.dateFormat.split("").map(char => {
        if (regExp.exec(char) === null) {
          this.delimeter = char;
        }
        return null;
      });

      this.formatArray = this.state.dateFormat.split(this.delimeter).map(el => el.toLowerCase());
  }

  handleOnChange = (event) => {
    if (event.target.value.indexOf("_") == -1) {
      if (event.target.value != "") {
        const dateParts = event.target.value.split(this.delimeter);

        const year  = dateParts[this.formatArray.indexOf('yyyy')];
        const month = dateParts[this.formatArray.indexOf('mm')] - 1;
        const day   = dateParts[this.formatArray.indexOf('dd')];

        let date = new Date(year, month, day);

        if (isAfter(date, this.props.maxDate)) {
          date = this.props.maxDate;
        } else if (isBefore(date, this.props.minDate)) {
          date = this.props.minDate;
        }

        this.props.onInputChange("start", date);

        if (this.state.open) {
          this.calendar.changeShownDate(date);

          if (this.props.closeOnComplete)
            this.handleBlur();
        }
      } else {
        this.props.onInputChange("start", "");
      }
    }
  }
  
  componentDidMount() {
    super.componentDidMount();
    if(this.props.callOnChangeOnMount) {
      this.props.onChange((this.props.date == "" || this.props.date == "Invalid Date") ? new Date() : this.props.date);
    } 
  }

  handleInputOnKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.inputOnEnter(event);
      this.handleOnChange(event);

      this.setState({ open: false });
    }

    this.props.inputOnKeyPress(event);
  }

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  }

  handleFocus = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  }

  handleBlur = () => {
    this.setState({ open: false });
    this.props.onClose && this.props.onClose();
  }

  handleChange = (date) => {
    this.props.onChange(date);

    if (this.props.closeCalendarOnComplete || this.props.closeOnComplete)
      this.handleBlur();
  }

  focusOnInput = () => {
    this.input.current.inputElement.focus();
  }

  onCalendarClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    // const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/YYYY');
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe(this.state.dateFormat.toLowerCase());

    const regExp = new RegExp("([a-zA-Z])");
    const mask = this.state.dateFormat.split("").map(char => {
      if (regExp.exec(char) === null)
        return char;

      return /\d/;
    });

    const { 
        disabled, 
        noMonthChange, 
        usePlaceholder,
        popperProps
    } = this.props;

    let calendarClassName = "calendarView";
    if (this.props.calendarRight) calendarClassName = "calendarViewRight";

    const date = (this.props.date == "" || this.props.date == "Invalid Date") ? new Date() : this.props.date;

    return (
      <ClickAwayWrapper 
        active={this.state.open || this.props.open} 
        onClickAway={this.handleBlur}
        mouseEvent="onMouseDown">
        <div ref={this.anchorEl} className={classnames("dateWrapper", this.props.className, disabled && "disabled", this.props.error && 'error')}>
          <div>
            {this.props.mode != "list" ? (<FormControl className={"formControl"} variant="filled">
              <InputLabel
                ref={ref => {
                  this.labelRef = ReactDOM.findDOMNode(ref);
                }}
                shrink={true}>
                {this.props.label}
              </InputLabel>
              <div className="inputWrapper">
                <MaskedInput
                  data-testid={"datepicker_input_" + this.props.name}
                  disabled={this.props.disabled}
                  mask={mask}
                  ref={this.input}
                  className="datePickerInput"
                  keepCharPositions={true}
                  placeholder={usePlaceholder ? this.tr("Select...") : undefined}
                  guide={true}
                  pipe={autoCorrectedDatePipe}
                  value={date == "0000-00-00" ? null : format(this.props.date, this.state.dateFormat.toUpperCase())}
                  onChange={this.handleOnChange}
                  onFocus={this.handleFocus}
                  onKeyPress={this.handleInputOnKeyPress} />
                <CalendarIcon
                  alt="Date picker"
                  onClick={(e) => this.handleToggle(e)}
                />
              </div>
            </FormControl>
            ) : (
                <div className="inputWrapper">
                  <MaskedInput
                    disabled={this.props.disabled}
                    mask={mask}
                    ref={this.input}
                    className="datePickerInput"
                    keepCharPositions={true}
                    guide={true}
                    pipe={autoCorrectedDatePipe}
                    placeholder={usePlaceholder ? this.tr("Select...") : undefined}
                    value={date == "0000-00-00" ? null : format(this.props.date, this.state.dateFormat.toUpperCase())}
                    onChange={this.handleOnChange}
                    onFocus={this.handleFocus}
                    onKeyPress={this.handleInputOnKeyPress} />
                  <CalendarIcon
                    alt="Date picker"
                  // onClick={(e) => this.handleToggle(e)}
                  />
                </div>
              )}
          </div>
          {this.props.usePopper ? (!this.props.disabled && (this.props.open ? this.props.open : this.state.open) && 
            <Popper 
                open={(this.props.open ? this.props.open : this.state.open)} 
                anchorEl={this.state.anchorEl ?? this.anchorElCalendar.current ?? this.anchorEl.current} 
                placement={this.props.popperBottom ? 'bottom-start' : 'top-start'}
                {...popperProps}
            >
            <div ref={this.anchorElCalendar} className={calendarClassName}>
              <Calendar
                showMonthAndYearPickers={!noMonthChange}
                showMonthArrow={!noMonthChange}
                date={date == "0000-00-00" ? new Date() : date}
                onChange={this.handleChange}
                locale={this.context.calendar.locale}
                onMouseUp={this.props.preventDefaultOnCalendarClick && this.onCalendarClick}
                ref={t => (this.calendar = t)}
                showDateDisplay={false}
                maxDate={this.props.maxDate}
                minDate={this.props.minDate}
              />
            </div>
          </Popper>) : (
              !this.props.disabled && (this.props.open ? this.props.open : this.state.open) &&
              <div className={calendarClassName}>
                <Calendar
                  showMonthAndYearPickers={!noMonthChange}
                  showMonthArrow={!noMonthChange}
                  date={date == "0000-00-00" ? new Date() : date}
                  onChange={this.handleChange}
                  locale={this.context.calendar.locale}
                  ref={t => (this.calendar = t)}
                  showDateDisplay={false}
                  maxDate={this.props.maxDate}
                  minDate={this.props.minDate}
                />
              </div>
            )
          }
        </div>
      </ClickAwayWrapper>
    );
  }
}

DatePicker.defaultProps = {
  mode: "input",
  disabled: false,
  callOnChangeOnMount: false,
  initialOpen: false,
  open: false,
  onChange: () => { },
  onInputChange: () => { },
  inputOnEnter: () => { },
  inputOnKeyPress: () => { },
  closeOnComplete: true,
  closeCalendarOnComplete: true,
  usePopper: false,
  popperProps: {},
  maxDate: addYears(new Date(), 20),
  minDate: addYears(new Date(), -100),
};

export default DatePicker;
