/* zeplin https://app.zeplin.io/styleguide/5d0b5f333ffa80708b4b8afa/colors */

const colors = {
  heerosBlue: "#003A78",
  dark: "#2c3547",
  dark_grey_blue: "#34495e",
  steel: "#6b7897",
  bluey_purple: "#6f42c1",
  cloudy_blue: "#b2b9c9",
  light_sky_blue: "#dde3e8",
  pale_lavender: "#ece9f1",
  white: "#ffffff",
  dark_sky_blue: "#2d9ff7",
  // greenish_cyan: "#50e3c2",
  greenish_cyan: "#20C6A1",
  warm_pink: "#f7548f",
  orangey_yellow: "#f5a623",
  maize: "#f7bc59",
  egg_shell: "#fce8c7",
  melon: "#ff8058",
  peachy_pink: "#ffa082",
  light_peach: "#ffd4c7",
  deep_sky_blue: "#0084f4",
  sky_blue: "#62b7f9",
  light_sky_blue_two: "#cbe7fd",
  carnation_pink: "#f97fab",
  light_pink: "#fed5e4",
  tiffany_blue: "#7bead1",
  light_light_blue: "#d4f8f0",
  black_50: "rgba(0, 0, 0, 0.5)",
  pale_grey: "#f7f5f9",
  scarlet: "#d0021b",
  faded_red: "#dc4054",
  pastel_pink: "#f4bfc6",
  white_two: "#f9f9f9",
  black_54: "rgba(0, 0, 0, 0.54)",
  eggplant: "#1a051d",
  cornflower: "#6979f8",
  pale_grey_two: "#f8f9fa",
  pale_grey_three: "#f4f5f8"
}

colors.primary = colors.dark_sky_blue;
colors.main = colors.dark_sky_blue;
colors.error = colors.warm_pink;
colors.darkred = colors.scarlet;
colors.salmon = colors.warm_pink;
colors.darkblue = colors.steel;
colors.darkerblue = colors.dark_grey_blue;
colors.darkgrey = colors.steel;
colors.white3 = colors.white;
colors.white6 = colors.white;
colors.whitef9 = colors.white_two;
colors.lightgrey = colors.light_sky_blue;

colors.subtitle = colors.dark_grey_blue;
colors.title = colors.dark;
colors.list_header_bg = "#dee7f0";
colors.border = "#c3cbdb";

export default colors;