import React from "react";
import CheckboxCell from "../../../list/cells/CheckboxCell";
import StatusCell from "../../../list/cells/StatusCell";
import TextInputCell from "../../../list/cells/TextInputCell";
import TreeStructureIndicatorCell from "../../../list/cells/TreeStructureIndicatorCell";
import CurrencyListCell from "../../../list/CurrencyListCell";
import LinkListCell from "../../../list/LinkListCell";
import PropsOnlyListRow from "../../../list/PropsOnlyListRow";

import cn from 'classnames';
import styles from './ProjectListBlockRow.module.scss';

export default class ProjectListBlockRow extends PropsOnlyListRow {
    static rowDimensions = {
        height: "44px",
        lineHeight: "44px"
    };

    popperRoot: React.RefObject<unknown>;

    constructor(props) {
        super(props, {}, { childRowType: ProjectListBlockRow }, "customview/blocks/ProjectList/ProjectListBlockRow");

        this.popperRoot = React.createRef();
    }

    defineClassName = () => {
        const childClass       = this.props.isChild ? "child" : "";
        const matchedSearchClass = this.props.rowProps.showTreeStructures && this.props.rowProps.showWholeTrees && this.props.data.matched_search ? "matchedSearch" : "";

        return cn("overviewRow", styles.row, childClass, matchedSearchClass);
    }


    defineCells() {
        const { data, rowProps, columnWidthMap } = this.props;

        const staticColumnProps = { showMenu: false, showMenuContainer: true, resizeable: false, showResizeMarker: false, moveable: false, hideable: false };

        const commonProps = { editable: false, openEditOnFocus: false };

        const joinedCellWidth = columnWidthMap.name*1 + columnWidthMap.customer*1 + columnWidthMap.status*1; 

        const cells = {
            checked:
                <CheckboxCell 
                    checked={this.props.checked}
                    listCellProps={data.id < 0 && {style: {width: `${columnWidthMap.checked}px`, flexGrow: columnWidthMap.checked, flexShrink:1, flexBasis: '0px'}}}  
                    onClick={() => {
                        this.props.listRef.check(this.props.data.id);
                        rowProps.onCheck(this.props.listRef.getCheckedRows());
                    }} />,
            project_id: 
                (data.id > 0 ? <LinkListCell
                    {...commonProps}
                    urlHandler={value => `index.html?module=projects&action=view&id=${data.id}`}
                    value={data.project_id}
                    noTab={true} 
                    useIndenting={false}
                    indent={this.props.recursionLevel} />
                    : <div className="disabled-div" style={{width: `${columnWidthMap.project_id}px`, flexGrow: columnWidthMap.project_id, flexShrink:1, flexBasis: '0px'}} />)
                ,                
            name:
                (data.id > 0 ? <TreeStructureIndicatorCell
                    name="name"
                    childrenVisible={this.props.childrenVisible}
                    listCellProps={{ alignCell: true, textAlign: "left" }}
                    rowData={this.props}
                    onExpanderClick={e => this.props.listRef.toggleShowChildren(this.props.data.id)}
                    value={data.name}>
                    <TextInputCell
                        {...commonProps}
                        listCellType={LinkListCell} 
                        listCellProps={{ 
                            urlHandler: value => `index.html?module=projects&action=view&id=${data.id}`,
                            noTab: true,
                            inEditMode: false, 
                            editable: false, 
                            style: { height: "44px" }, 
                            width: columnWidthMap['name'] - ((this.props.children && Array.isArray(this.props.children) && this.props.children.length > 0 ? 1 : 0) + this.props.recursionLevel) * 25 
                        }} 
                        name="project"
                        value={data.name} />
                </TreeStructureIndicatorCell> : 
                <div className="disabled-div enchanced" style={{width: `${joinedCellWidth}px`, flexGrow: joinedCellWidth, flexShrink:1, flexBasis: '0px'}} >
                    {this.tr("All invoices that are created without project for the customer")} 
                </div> ),
            customer:
                data.id > 0 ? <LinkListCell
                    {...commonProps}
                    asText={this.props.data.can_see_account == "0"}
                    value={data.customer}
                    urlHandler={() => `index.html?module=customers&action=view&id=${data.customers_id}`}
                    noTab={true} /> 
                    : 
                    <div className="disabled-div" />,
            status: 
                data.id > 0 ? <StatusCell 
                    listCellProps={{style: {width: `${columnWidthMap.status}px`, flexGrow: columnWidthMap.status, flexShrink:1}}} 
                    displayData={rowProps.statuses.find(s => s.id == data.status) || rowProps.statuses.find(s => s.id == -1)} 
                    value={data.status} /> : <div className="disabled-div" />,
            invoiced:
                <CurrencyListCell
                    {...commonProps}
                    currency={this.props.rowProps.currency}
                    style={data.id < 0 && {flexBasis: 0}}
                    className={data.id < 0 ? 'enchanced' : ''}
                    value={data.invoiced}
                    textAlign="right" />,
            budgeted_sales:
                (data.id > 0 ? <CurrencyListCell
                    {...commonProps}
                    currency={this.props.rowProps.currency}
                    value={data.budgeted_sales}
                    textAlign="right" />
                : <div className="disabled-div" style={{width: `${columnWidthMap.budgeted_sales}px`, flexGrow: columnWidthMap.budgeted_sales, flexShrink:1, flexBasis: '0px'}} />),
            sale_difference:
                (data.id > 0 ? <CurrencyListCell
                    className={data.invoiced - data.budgeted_sales < 0 ? styles.negative : ''}
                    {...commonProps}
                    currency={this.props.rowProps.currency}
                    value={data.invoiced - data.budgeted_sales}
                    textAlign="right" />
                : <div className="disabled-div" style={{width: `${columnWidthMap.budgeted_sales}px`, flexGrow: columnWidthMap.budgeted_sales, flexShrink:1, flexBasis: '0px'}} />),
            budgeted_costs:
                (data.id > 0 ? <CurrencyListCell
                    {...commonProps}
                    currency={this.props.rowProps.currency}
                    value={data.budgeted_costs}
                    textAlign="right" />
                : <div className="disabled-div" style={{width: `${columnWidthMap.budgeted_sales}px`, flexGrow: columnWidthMap.budgeted_sales, flexShrink:1, flexBasis: '0px'}} />),
            actual_costs:
                (data.id > 0 ? <CurrencyListCell
                    {...commonProps}
                    currency={this.props.rowProps.currency}
                    value={data.actual_costs}
                    textAlign="right" />
                : <div className="disabled-div" style={{width: `${columnWidthMap.budgeted_sales}px`, flexGrow: columnWidthMap.budgeted_sales, flexShrink:1, flexBasis: '0px'}} />),
            cost_difference:
                (data.id > 0 ? <CurrencyListCell
                    className={data.budgeted_costs - data.actual_costs < 0 ? styles.negative : ''}
                    {...commonProps}
                    currency={this.props.rowProps.currency}
                    value={data.budgeted_costs - data.actual_costs}
                    textAlign="right" />
                : <div className="disabled-div" style={{width: `${columnWidthMap.budgeted_sales}px`, flexGrow: columnWidthMap.budgeted_sales, flexShrink:1, flexBasis: '0px'}} />),
        };

        return cells;
    }
}