import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import React from 'react';
import validator from 'validator';
import ContextMenu from '../../general/ContextMenu';
import DataHandler from '../../general/DataHandler';
import AutoCompleteCell from "../cells/AutoCompleteCell";
import CheckboxCell from "../cells/CheckboxCell";
import DateCell from "../cells/DateCell";
import TextInputCell from "../cells/TextInputCell";
import LinkListCell from "../LinkListCell";
import ListCell from "../ListCell";
import PropsOnlyListRow from "../PropsOnlyListRow";
import { SettingsContext } from './../../SettingsContext';


import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import { ReactComponent as ViewIcon } from '../../general/icons/view.svg';

import '../lists/ContactsAndUserList.css';


const nEmptyString = v => typeof v === "string" && v.trim().length > 0;
const nEmail = e => !e.length || validator.isEmail(e);

class ContactListRow extends PropsOnlyListRow {
	static contextType = SettingsContext;


	constructor(props) {
		super(props, { checked: false, emailUsed: false }, undefined, "list/rows/ContactListRow");

		this.contactTypes = [
			{ id: "1", name: this.tr("Contact"), value: "1", label: this.tr("Contact") },
			{ id: "2", name: this.tr("Partner"), value: "2", label: this.tr("Partner") }
		];

		this.focusGroup = React.createRef();

		this.cellEdited = this.cellEdited.bind(this);
		this.saveNewRow = this.saveNewRow.bind(this);
		//this.check = this.check.bind(this);
		this.saveRow = this.saveRow.bind(this);

		this.newRowValidators = {
			firstname: { validator: nEmptyString, columnName: "firstname" },
			lastname: { validator: nEmptyString,  columnName: "lastname" },
			email: { validator: nEmail, columnName: "email" },
		};
	}
	
	/*check(check = undefined, fromCheckAll = false) {
		const checked = check !== undefined ? check : !this.state.checked;

		(this.props.rowProps.hasOwnProperty("onCheck")) && (this.props.rowProps.onCheck(checked, this.props.data, fromCheckAll));

		this.setState({ checked: checked }, () => this.forceUpdate());
	}*/

	shouldComponentUpdate(nextProps, prevProps) {
		if(nextProps.hasOwnProperty("data") && nextProps.data.id !== this.props.data.id) {
			return false;
		}

		return true;
	}
	
	cellEdited(name, value) {
		this.props.rowProps.setChangedField(name, () => {
			if (this.props.data.id > 0) {
				this.setDataAndUpdate(name, value);
			} else {
				this.setData(name, value);
			}
		});	
	}

	checkInvalids = () => {
		const invalids = [];
		const data     = this.props.data;
	 
		for (const i in this.newRowValidators) {
			const { validator, columnName } = this.newRowValidators[i];
			if (!validator(data[i])) {
				invalids.push(columnName);
			}
		}
		this.setInvalidFields(invalids);
		return invalids;
	}

	saveNewRow(name, value) {
		if (this.props.data['id'] > -1)
			return;

		const data = this.props.data;
		data[name] = value;
		 if(data.id == -1 && data['contact_owner']['id'] == 0)
		{ 
			data['contact_owner']['id'] = this.context.userObject.usersId;
		} 
		const invalids = this.checkInvalids();
		if (invalids.length > 0) {
			_.forEach(invalids, (error, i) => {
				this.props.enqueueSnackbar(this.props.rowProps.newRowErrors[error], {
					variant: 'error'
				});
			})
			return;
		}
		else
	
			return this.create(data);
	}

	saveRow(data) {
		const d = this.state.data;
		const url = d.id < 0 ? "contacts_new" : "contacts_save";

		d.companies_id = this.props.rowProps.companies_id;

		return DataHandler.post({ url: `${url}/${d.id}` }, data)
			.done(response => {
				this.setState({ invalids: [] });
				if (d.id < 0)
					this.setData("id", response.id);

				this.props.rowProps.fetchData();
				this.props.rowProps.fetchAutoCompleteData();

			})
			.fail(response => {
				if (response.status === 422) {
					this.setState({ invalids: Object.keys(response.responseJSON) });
					_.forEach(response.responseJSON, (error, i) => {
						this.props.enqueueSnackbar(this.props.rowProps.newRowErrors[i], {
							variant: 'error'
						});
					})
				}
			});
	}

	getUser = (id, users) => {
		let response = users && users.find(e => e.id == id);
		if (!response) {
			const user = this.props.sharedData['users'] && this.props.sharedData['users'].find(e => e.id == id);
			if (user) 
				response = user.name;
			else 
				response = "";
		}
		return response || "";
	}

	onTagCreate = (tag, values) => {
        const data = {tag: tag.value, type: 3, id: -1};
        DataHandler.post({url: `tags/save/${this.props.rowProps.companies_id}`}, {data}).done(t => {
			if (Number(this.props.data.id) < 0)
				this.cellEdited("tags", values);

			this.cellEdited("new_tag", tag);
            this.props.enqueueSnackbar(this.tr("Tag ${tag} created!", {tag: data.tag}), {
                variant: "success",
            });
        })
        .fail(response => {
            if (response && response.responseJSON && response.responseJSON.error === "TAG_EXISTS_AS_DISABLED") {    
                this.props.enqueueSnackbar(this.tr("Tag already exists, but is disabled!"), {
                    variant: "error",
                });
            }
        });
	}
	
	onTagDelete = (selectedObject, tags) => {
		const deleted = tags.filter(e => !selectedObject.find(s => s.id == e.id))[0];
		const data = {tag: deleted.tag, type: 3, id: -1};
        DataHandler.post({url: `tags/save/${this.props.rowProps.companies_id}`}, {data});
	}

	defineCells() {
		const { showDialog } = this.state;
		const { sharedData, columnWidthMap, data, rowProps: { languageOptions, contactSettings } } = this.props;
		const { privileges, userObject, functions: { updateView, checkPrivilege } } = this.context;
		const tn = _.uniqBy(_.map(data.tags, t => t.name));
		const writePrivileges = checkPrivilege("persons", "read");
		const className = ["contactListRow listElement row", this.props.hidden ? "hidden" : "", data['id'] < 0 ? "new" : ""].join(" ");

		const mailList = sharedData['mailing_list'].filter(ml => data['on_mailing_lists'].indexOf(ml.id) > -1);
		let account = sharedData['customers'].find(c => c.id === data['customers_id']);
		const position = sharedData['positions'].find(p => data['vacance'] === p.id);
		const lang = languageOptions.find(l => data.lang == l.value);
		let contactOwner = sharedData['users'].find(co => data['contact_owner'] === co.id);
		 if( data.id == -1 && !contactOwner)
        { 
            contactOwner = this.context.userObject.usersId;
        } 
		const tagPool = sharedData['tags'].filter(t => Number(t.disabled) < 1 && tn.indexOf(t.name) === -1);
		const ct = sharedData['customer_types'].filter(ct => data['customer_types'].indexOf(ct.id) > -1);
		const modifier = this.getUser(data['modified_by'], sharedData['modified_by']);
		const customerPartnerArr = (data.customer_partner_concat || "").split(",").filter(cp => cp == "1" || cp == "2").map(cp => this.contactTypes.find(ct => ct.id == cp)?.label || "");

		if (account === undefined)
			account = { id: 1, name: "undefined" };

		const cells = {
			expand:
				this.props.data['id'] > 0 ?
					<ContextMenu label={<MoreHoriz />} buttonProps={{ className: 'action-menu' }} className="cell row-menu" style={{ width: columnWidthMap['expand'] + "px", flex: columnWidthMap['expand'] + " 1 0px" }} noExpandIcon>
						<MenuItem
							onClick={() => updateView({ module: 'contact', action: 'view', id: data.contact_id, company: data.companies_id }, false)} >
							<ViewIcon />{this.tr("Open")}
						</MenuItem>
						{writePrivileges && (
							<MenuItem className="delete"
								onClick={() => this.delete(data)}
							>
								<RemoveIcon className="Delete" />{this.tr("Delete")}
							</MenuItem>
						)}
					</ContextMenu>
					:
					<ListCell tabIndex="1" onlyDisplay={true} width={columnWidthMap['expand']}>
						<Tooltip title={this.tr("Save")} placement="bottom">
							<CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.saveNewRow()} />
						</Tooltip>
					</ListCell>,
			checked:
				this.props.data['id'] > 0 ?
					<CheckboxCell checked={this.props.checked} width={columnWidthMap['checked']} onClick={() => this.props.listRef.check(this.props.data.list_id)} />
					:
					<ListCell tabIndex="2" onlyDisplay={true} width={columnWidthMap['checked']}>
						<Tooltip title="Cancel" placement="bottom">
							<Cancel className="cancelIcon" onClick={() => {this.delete(this.props.data);}} />
						</Tooltip>
					</ListCell>,
			lastname:
				<TextInputCell
					listCellType={LinkListCell}
					listCellProps={{
						urlHandler: value => `index.html?module=contact&action=view&id=${data.contact_id}&company=${data.companies_id}`,
						editable: true,
						noTab: true,
						inEditMode: data.id < 0,
						showErrorBorder: data.lastname == ''
					}}
					width={columnWidthMap['lastname']}
					name="lastname"
					value={data['lastname']}
					validation={["empty"]}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					placeholder={this.tr("Surname")} />,
			firstname:
				<TextInputCell
					listCellType={LinkListCell}
					listCellProps={{
						urlHandler: value => `index.html?module=contact&action=view&id=${data.contact_id}&company=${data.companies_id}`,
						editable: true,
						noTab: true,
						inEditMode: data.id < 0,
						showErrorBorder: data.firstname == ''
					}}
					width={columnWidthMap['firstname']}
					validation={["empty"]}
					name="firstname"
					value={data['firstname']}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					placeholder={this.tr("First Name")} />,
			account:
				<AutoCompleteCell
					listCellType={LinkListCell}
					listCellProps={{
						urlHandler: customer => customer.hasOwnProperty("id") ? `index.html?module=customers&action=view&id=${customer.id}` : "",
						valueHandler: customer => customer.hasOwnProperty("name") ? customer.name : "",
						noTab: true,
						inEditMode: data.id < 0,
						editable: data.id < 0
					}}
					menuPlacement="bottom"
					autoCompleteData={sharedData['customers']}
					width={columnWidthMap['account']}
					value={data['account']}
					placeholder={this.tr("Account")}
					onEdited={writePrivileges ? customer => {
						this.cellEdited("account", customer['value']);
					} : undefined
				}
                />,
            customers_types_name_concat:
                <AutoCompleteCell
                    listCellProps={{
                        inEditMode: data.id < 0
                    }}
                    autoCompleteData={sharedData['customers_types_name_concat']}
                    multiple={false}
                    width={columnWidthMap['customers_types_name_concat']}
                    value={data['customers_types_name_concat']}
                    placeholder={this.tr("Account type")}
                    editable={false}
				/>,
			customer_partner:
				<AutoCompleteCell
				listCellProps={{
					inEditMode: data.id < 0
				}}
				editable={false} // quick fix at least for now – multiple relations possible
				autoCompleteData={this.contactTypes}
				multiple={false}
				width={columnWidthMap['contact_types']}
				value={customerPartnerArr.join(", ")}
				showStringValue
				placeholder={this.tr("Contact type")}
				onEdited={writePrivileges ? customer => {
					this.cellEdited("customer_partner", customer['value']);
				} : undefined}
				/>,

			contact_owner:
				<AutoCompleteCell
					listCellProps={{
						inEditMode: data.id < 0,
						showErrorBorder: contactSettings.contacts_force_owner === "1" ? (contactOwner == '' || contactOwner <= 0 || _.isEmpty(contactOwner)) : false,
					}}
					autoCompleteData={sharedData['users']}
					multiple={false}
					width={columnWidthMap['contact_owner']}
					value={contactOwner}
					placeholder={this.tr("Contact owner")}
					validation={contactSettings.contacts_force_owner === "1" ? ['empty'] : []}
					onEdited={writePrivileges ? values => {
									
							this.cellEdited("contact_owner", values.value);
						
					} : undefined
					}
				/>,
			created:
				<DateCell
					width={columnWidthMap['created']}
					value={data['created']}
					editable={false} />,
			position:
				<AutoCompleteCell
					listCellProps={{
						inEditMode: data.id < 0,
						showErrorBorder: contactSettings.contacts_force_position === "1" ? (position == '' || position <= 0 || _.isEmpty(position)) : false,
					}}
					autoCompleteData={sharedData['positions']}
					multiple={false}
					width={columnWidthMap['position']}
					value={position}
					placeholder={this.tr("Position")}
					allowCreate={true}
					validation={contactSettings.contacts_force_position === "1" ? ['empty'] : []}
					onEdited={writePrivileges ? values => {
						if (values && values.new) {
							values.id = 0;
							this.cellEdited("new_vacance", values);
						} else {			
							this.cellEdited("vacance", values.value);
						}
					} : undefined
					}
				/>,
			on_mailing_lists:
				<AutoCompleteCell
					listCellProps={{
						inEditMode: data.id < 0
					}}
					autoCompleteData={sharedData['mailing_list']}
					multiple={true}
					width={columnWidthMap['on_mailing_lists']}
					value={mailList}
					placeholder={this.tr("Mailing Lists")}
					onEdited={writePrivileges ? (data['id'] > 0 ?
						values => {
							this.cellEdited("on_mailing_lists", values.map(m => m.id));
						}
						:
						values => {
							this.cellEdited('on_mailing_lists', values.map(m => m.value));
						}) : undefined

					}
				/>,
			branch_of_business:
				<ListCell width={columnWidthMap['branch_of_business']} value={data['branchofbusiness']} editable={false} />,
			title:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0,
						showErrorBorder: contactSettings.contacts_force_title === "1" ? data.title == '' : false,
					}}
					width={columnWidthMap['title']}
					name="title"
					value={data['title']}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					validation={contactSettings.contacts_force_title === "1" ? ['empty'] : []}
					placeholder={this.tr("Title")} />,
			customer_types:
				<AutoCompleteCell
					listCellProps={{
						inEditMode: data.id < 0
					}}
					autoCompleteData={sharedData.customer_types}
					width={columnWidthMap['customer_types']}
					multiple={true}
					value={ct}
					placeholder={this.tr("Contact Type")}
					onEdited={writePrivileges ? values => {
						const d = data;
						d['customer_types'] = values;
						this.cellEdited("customer_types", values.map(ct => ct.id));
					} : undefined}
				/>,
			email:
				<TextInputCell
					listCellType={LinkListCell}
					listCellProps={{
						urlHandler: value => `mailto:${data.email}`,
						noTab: true,
						asNormalLink: true,
						inEditMode: (data.id < 0 || this.state.invalids.email && this.state.invalids.email.length > 0),
						showErrorBorder: contactSettings.contacts_force_email === "1" ? data.email == '' : false,
					}}
					width={columnWidthMap['email']}
					name="email"
					value={data['email']}
					validation={contactSettings.contacts_force_email === "1" ? ['empty', 'email'] : []}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					placeholder={this.tr("Email")} />,
			phone:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0,
						showErrorBorder: contactSettings.contacts_force_phone === "1" ? data.phone == '' : false,
					}}
					width={columnWidthMap['phone']}
					name="phone"
					value={data['phone']}
					validation={contactSettings.contacts_force_phone === "1" ? ['empty'] : []}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					placeholder={this.tr("Phone")} />,
			edited_by:
				<ListCell 
					width={columnWidthMap['edited_by']} 
					value={modifier}                     
					showStringValue={!modifier.id}
					editable={false} />,
			edited:
				<DateCell
					width={columnWidthMap['edited']}
					value={data['modified']}
					editable={false} />,
			lang:
				<AutoCompleteCell 
					value={lang}
					listCellProps={{
						inEditMode: data.id < 0,
						showErrorBorder: contactSettings.contacts_force_language === "1" ? (lang == '' || lang <= 0 || _.isEmpty(lang)) : false,
					}}
					autoCompleteData={languageOptions}
					placeholder={this.tr("Language")}
					autoCompleteLabelKey="name"
					width={columnWidthMap['lang']}
					validation={contactSettings.contacts_force_language === "1" ? ['empty'] : []}
					onEdited={writePrivileges ? 
						lang => {
							this.cellEdited("lang", lang.value)
						}
						: undefined}
				/>,
			tags:
				<AutoCompleteCell
					listCellProps={{
						inEditMode: data.id < 0
					}}
					autoCompleteData={tagPool}
					multiple={true}
					value={data.tags}
					placeholder={this.tr("Tags")}
					autoCompleteLabelKey="name"
					width={columnWidthMap['tags']}
					allowCreate={sharedData.tagPoolSettingsData ? sharedData.tagPoolSettingsData.create_tags_only_from_settings == "0" : false}
					onEdited={writePrivileges ? 
						values => {
							if (values.length < data.tags.length)
								this.onTagDelete(values, data.tags);

							if (values[values.length - 1] && values[values.length - 1].new == true) {
								this.onTagCreate(values[values.length - 1], values);
							} else {
								this.cellEdited("tags", values);
							}
						}
						: undefined}
				/>,
			project_tags:
				<ListCell 
					width={columnWidthMap['project_tags']} 
					value={data['project_tags']}  
					showTooltipForOverflownText={true}                   
					editable={false} />,
			address:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0
					}}
					width={columnWidthMap['address']}
					name="street_address"
					value={data['street_address']}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					placeholder={this.tr("Address")} />,
			postal_code:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0
					}}
					width={columnWidthMap['postal_code']}
					name="postal_code"
					value={data['postal_code']}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					placeholder={this.tr("Zip code")} />,
			city:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0
					}}
					width={columnWidthMap['city']}
					name="city"
					value={data['city']}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					placeholder={this.tr("City")} />,
			state:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0
					}}
					width={columnWidthMap['state']}
					name="state"
					value={data['state']}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					placeholder={this.tr("State")} />,
			country:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0
					}}
					width={columnWidthMap['country']}
					name="country"
					value={data['country']}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					placeholder={this.tr("Country")} />,
		}

		return cells;
	}
}

export default withSnackbar(ContactListRow);
