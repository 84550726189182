import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from './../SettingsContext';
import LabelFieldGroup from '../general/LabelFieldGroup';
import DataHandler from "./../general/DataHandler";
import Utils from "./../general/Utils";

import withStyles from '@mui/styles/withStyles';


const styles = theme => ({
    reqContainer: {
        gridArea: "address",
        justifySelf: "end",
        width: "60%"
    }
});

function SimpleTextDisplay(props) {
    return <span>{props.value}</span>
}

class InvoiceFooter extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "invoices/InvoiceFooter");

        this.state = {
            bankData: [],
            primary: undefined,
            secondary: undefined
        }

        this.scrollAnchor = React.createRef();

    };
    
    componentDidMount() {
        super.componentDidMount();
    };
    
    editAddress = (name, value, capitalize, allcap) => {

        if (this.state.addressData[name] == value)
            return;

        value = capitalize ? (!allcap ? Utils.capitalize(value) : Utils.startCase(value)) : value;

        const { onAddressChange } = this.props;

        onAddressChange ? onAddressChange(name, value) : DataHandler.post({url: 'invoices/company_address'}, {name: name, value: value}).done(response => {
            this.setState(state => {
                state.addressData[name] = response;
                return state;
            });
        });
    };

    editBankAccount = (name, item) => {
        const bankaccount = _.cloneDeep(this.props.primary);
        const bankAccounts = _.cloneDeep(this.props.bankData);
        
        if (!bankaccount.id)
            return;
        
        bankaccount[name] = item.value.toUpperCase();
        bankAccounts.find(e => e.id == bankaccount.id)[name] = item.value.toUpperCase();
        
        this.props.updateData({bankaccount, bankAccounts});
        
        DataHandler.post({url: `invoices/${bankaccount.id}/bank_account`}, {name: name, value: bankaccount[name]});
    };

    handleBankAccountChange = (name, item) => {
        const { updateData } = this.props;
        
        if (item.__isNew__) {
            let value = Utils.startCase(item.value);

            DataHandler.post({url: 'invoices/bank_account'}, {value: value, company: this.props.company}).done(response => {
                updateData({bankaccount: response, bankAccounts: [..._.cloneDeep(this.props.bankData), response]});
            });

        } else {
            const bankaccount = this.props.bankData.find(e => e.id === item.value);
            updateData({bankaccount});
        }
    };

    render() {

        const { userObject, taimerAccount: { showState }, addons, functions: { checkPrivilege } } = this.context;
        const { classes, editMode, addressData, invoice_footer, settings, ...rest } = this.props;
        const { company, primary, secondary } = this.state;
        const bankData = this.props.bankData ? this.props.bankData : [];

        const bankAccounts = bankData && bankData.map(b => {return {label: b.name, value: b.id}});

        const usMode = /* (item && item.state) ||  */showState;

        const extraFields = settings?.invoice_footer?.fields ?? [];

        return (
            <React.Fragment>
                    <div ref={this.scrollAnchor} />
                    <LabelFieldGroup
                        flexWeight={1}
                        required
                        editMode={false}
                        values={addressData}
                        fields={[
                            { label: this.tr("Company"), name: "name", value: undefined, editable: false },
                            { label: this.tr("Address"), name: "address", value: undefined, onChange: e => this.editAddress("address", e.target.value, true, true) },
                            usMode && { label: this.tr("City"), name: "city", value: undefined, onChange: e => this.editAddress("city", e.target.value, true, true)/*, labelFieldClass: "inline" */},
                            usMode && { label: this.tr("State"), name: "state", value: undefined, onChange: e => this.editAddress("state", e.target.value, true, true)/*, labelFieldClass: "inline" */},
                            { label: this.tr("Zip code"), name: "postalcode", value: undefined, onChange: e => this.editAddress("postalcode", e.target.value)/*, labelFieldClass: "inline" */},
                            !usMode && { label: this.tr("City"), name: "city", value: undefined, onChange: e => this.editAddress("city", e.target.value, true, true)/*, labelFieldClass: "inline" */},
                            { label: this.tr("Country"), name: "country", value: undefined, onChange: e => this.editAddress("country", e.target.value, true, true)/*, labelFieldClass: "inline" */}
                        ]}
                        {...rest} />
                    <LabelFieldGroup
                        flexWeight={1.15}
                        required
                        editMode={false}
                        values={addressData}
                        fields={[
                            { 
                                label: this.tr("Email"),
                                name: "email", 
                                value: undefined, 
                                onChange: e => this.editAddress("email", e.target.value),
                                editorType: SimpleTextDisplay,
                                className: 'invoice_footer_extraField',
                            },
                            { 
                                label: this.tr("Phone"), 
                                name: "telephone", value: undefined, 
                                onChange: e => this.editAddress("telephone", e.target.value), 
                                editorType: SimpleTextDisplay,
                                className: 'invoice_footer_extraField',
                            },
                            { 
                                label: this.tr('"WWW"'),
                                name: "www", value: undefined,
                                onChange: e => this.editAddress("www", e.target.value),
                                editorType: SimpleTextDisplay,
                                className: 'invoice_footer_extraField',
                            },
                            ...(extraFields.filter(x => x.title.trim() != '' || x.value.trim() != '').map(x => ({
                                label: x.title,
                                value: x.value,
                                editorType: SimpleTextDisplay,
                                className: 'invoice_footer_extraField',
                            }))),
                        ]}
                        {...rest} />
                    
                    <LabelFieldGroup
                        flexWeight={1}
                        required
                        editMode={editMode}
                        values={this.props.primary}
                        key={this.props.bankData}
                        fields={[
                            { label: this.tr("Bank"),
                                name: "bank_name",
                                editorType: (checkPrivilege("admin", "admin") ? CreatableSelect : Select),
                                options: bankAccounts,
                                onChange: v => this.handleBankAccountChange('primary', v),
                                value: this.props.primary
                            },
                            { label: this.tr("SWIFT/BIC"), name: "swift", disabled: (checkPrivilege("admin", "admin") ? false : true), onChange: e => this.editBankAccount("swift", e.target) },
                            { label: this.tr("IBAN"), name: "iban", disabled: (checkPrivilege("admin", "admin") ? false : true), onChange: e => this.editBankAccount("iban", e.target) },
                        ]}
                        {...rest} />
                    {/* TAIM9-606, finvoice doesn't support this <LabelFieldGroup
                        required
                        editMode={editMode}
                        fields={[
                            { label: this.tr("Bank"),
                                name: "secondaryBankAccount",
                                editorType: CreatableSelect,
                                options: bankAccounts,
                                value: secondary ? secondary : (bankData.find(b => b.id == this.props.secondary) || bankData.find(b => b.additional_account > 0)),
                                onChange: v => this.handleBankAccountChange('secondary', v) },
                            { label: this.tr("SWIFT/BIC"), name: "secondary", value: getBankDataValue('secondary', 'swift') !== null ? getBankDataValue('secondary', 'swift') : (bankData.length > 0 ? (bankData.find(b => b.additional_account > 0) ? bankData.find(b => b.additional_account > 0).swift : '') : ''), onChange: e => this.editBankAccount("swift", e.target) },
                            { label: this.tr("IBAN"), name: "secondary", value: getBankDataValue('secondary', 'iban') !== null ? getBankDataValue('secondary', 'iban') : (bankData.length > 0 ? (bankData.find(b => b.additional_account > 0) ? bankData.find(b => b.additional_account > 0).iban : '') : ''), onChange: e => this.editBankAccount("iban", e.target) },
                        ]}
                        {...rest} /> */}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(InvoiceFooter);
