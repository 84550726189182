import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MaskedInput from 'react-text-mask'

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
const autoCorrectedDatePipe = createAutoCorrectedDatePipe('HH:MM')

function createTimeMask(timeFormat = 24) {
    return timeFormat === 24
        ? [/\d/, /\d/, ':', /\d/, /\d/]
        : twelveHourMask;
}

function twelveHourMask(value) {
    const [h, m] = value.split(':');
    const hInt   = parseInt(h[0]);
    let rest     = [':', /\d/, /\d/, ' ', /[APap]/, /[Mm]/];
    const mFirst = m?.[0]?.toUpperCase();

    if(hInt === 1) {
        return [/[0-1]/, /[0-2]/, ...rest];
    }

    if(hInt > 1) {
        return ['0', /[0-9]/, ...rest];
    }

    if(['A', 'P'].indexOf(mFirst) > -1) {
        rest = [':', '0', '0', ' ',  mFirst, 'M'];
    }
    
    return [/[0-9]/, /[0-9]/, ...rest];
}

class TimeOutlinedField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.value) {
            return {
                value: props.value
            };
        }
        return null;
    }

    render() {
        const { name, rootClass, disabled } = this.props;
        const { value } = this.state;

        return (
            <FormControl 
                data-testid={`time_outlined_field_${this.props.name}`} 
                className={rootClass} 
                variant="filled" 
                fullWidth={this.props.fullWidth}
                error={this.props.error}>
                <InputLabel
                    shrink
                    ref={ref => {
                        this.labelRef = ReactDOM.findDOMNode(ref);
                    }}
                    htmlFor={`${name}-date`} >

                    {this.props.label}
                </InputLabel>

                <FilledInput
                    notched
                    inputComponent={MaskedInput}
                    inputProps={{
                        mask: createTimeMask(this.props.clock),
                        showMask: true,
                        placeholderChar: '-',
                        keepCharPositions: true,
                        pipe: autoCorrectedDatePipe
                    }}
                    type="text"                    
                    name={name}
                    value={value}
                    disabled={disabled}
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                    labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0} />
            </FormControl>
        );
    }
}

TimeOutlinedField.defaultProps = {
    error: false,
    value: "07:00",
    clock: 24, //  12 or 24
}

export default TimeOutlinedField;
