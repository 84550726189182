import { AttachEmail, Attachment } from '@mui/icons-material';
import { Autocomplete, Box, Chip, createFilterOptions, TextField } from '@mui/material';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import React from 'react';
import DataHandler from '../general/DataHandler';
import TaimerWizard, { TaimerWizardPage } from '../general/TaimerWizard';
import TaimerComponent from '../TaimerComponent';
import styles from './SendQuoteWizard.module.scss';
import FileSaver from 'file-saver';
import validator from 'validator';
import QuotePrintPreview from './QuotePrintPreview';

import { ReactComponent as SentImage } from '../general/icons/completed.svg';
import { ReactComponent as ErrorImage } from '../general/icons/error.svg';

export interface RecipientOption {
    label: string;
    email: string;
    id: string | number;
    inputValue?: string;
    freeValue?: string;
}

interface Attachment {
    name: string;
}

interface EmailComposeViewProps {
    recipientOptions: RecipientOption[];
    recipients?: RecipientOption[];
    fixedOptions?: RecipientOption[];
    message?: string;
    onCreateRecipient: (params: object) => void;
    attachments?: Attachment[];
    onFieldChanged: (key: string, value: string | RecipientOption[]) => void;
    allowSubject?: boolean;
    allowFreeRecipients?: boolean;
}
export interface EmailComposeViewState {
    recipients: RecipientOption[];
    subject?: string;
    message: string;
}

type SendQuoteParams = {
    recipients: string[];
    subject: string;
    sender: string;
    message: string;
    senderUser: string;
    senderCompany: string;
    quoteValue: string;
    quoteName: string;
    validUntil: string;
    attachmentId: string;
    companiesId: string;
    quoteId: string;
    lang: string;
};

export const sendQuoteEmail = (params: SendQuoteParams) => {
    return new Promise<void>((resolve, reject) => {
        DataHandler.postArrayBuffer({ url: 'projects/quotes/send_pdf' }, params)
            .done(() => {
                resolve();
            })
            .fail((err) => {
                if (err.status != 200) {
                    reject(err);
                } else {
                    resolve();
                }
            });
    });
};

export const generateQuoteAttachment = async (quoteId, project, pdf, filename) => {
    if (pdf) {
        const uploadResponse = await DataHandler.file({ url: `projects/${project.id}/attachments`, filename, quoteId }, pdf);
        return uploadResponse;
    }
    return false;
};

export class EmailComposeView extends TaimerComponent<EmailComposeViewProps, EmailComposeViewState> {
    constructor(props, context) {
        super(props, context, 'general/EmailComposeView');
        this.state = {
            recipients: [...(this.props.fixedOptions || []), ...(this.props.recipients || []).filter((r) => (this.props.fixedOptions || []).findIndex((f) => f.email == r.email) == -1)],
            message: this.props.message || '',
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        //making sure the possible initial options are updated to the parent
        this.props.onFieldChanged('recipients', this.state.recipients);
    };

    componentDidUpdate = (oldProps, oldState) => {
        if (!isEqual(oldProps.recipients, this.props.recipients) && !isEqual(this.state.recipients, this.props.recipients)) {
            this.setState({
                recipients: [...(this.props.fixedOptions || []), ...(this.props.recipients || []).filter((r) => (this.props.fixedOptions || []).findIndex((f) => f.email == r.email) == -1)],
            });
        }
        if (this.props.message && !isEqual(oldProps.message, this.props.message) && !isEqual(this.state.message, this.props.message)) {
            this.setState({
                message: this.props.message,
            });
        }
    };

    onFieldChanged = (key: 'recipients' | 'subject' | 'message', value: string | RecipientOption[]) => {
        const update: any = { [key]: value };
        this.setState(update);
        this.props.onFieldChanged(key, value);
    };

    emailIsValid = (email) => {
        return validator.isEmail(email);
    };

    render() {
        const { recipients, subject, message } = this.state;
        const { recipientOptions, onCreateRecipient, attachments, allowSubject } = this.props;
        const filter = createFilterOptions<RecipientOption>({ stringify: (option: RecipientOption) => `${option.label} ${option.email}` });
        return (
            <div className={styles.composer}>
                <h2>{this.tr('Compose email')}</h2>
                <div className={styles.field}>
                    <p>{this.tr('To')}:</p>
                    <Autocomplete
                        noOptionsText={this.tr('No options')}
                        value={recipients}
                        classes={{ root: styles.autoComplete }}
                        renderInput={(params) => <TextField {...params} InputProps={{ ...params.InputProps, endAdornment: undefined }} classes={{ root: styles.input }} variant="standard" />}
                        disablePortal
                        multiple
                        filterSelectedOptions
                        options={recipientOptions}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <div className={styles.emailOption}>
                                    <h3>{option.label}</h3>
                                    <p>{option.email}</p>
                                </div>
                            </Box>
                        )}
                        getOptionLabel={(option: RecipientOption) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.label;
                        }}
                        renderTags={(value: readonly any[], getTagProps) =>
                            value.map((option: any, index: number) => (
                                <Chip
                                    classes={{ root: `${styles.chip} square ${option.freeValue ? styles.freeValue : ''}` }}
                                    label={option.freeValue ? option.label : `${option.label} (${option.email})`}
                                    {...getTagProps({ index })}
                                    onDelete={option.isSelf ? undefined : getTagProps({ index }).onDelete}
                                />
                            ))
                        }
                        onKeyDown={(e: any) => {
                            if ((e.key === 'Enter' || e.key == 'Tab') && this.emailIsValid(e.target.value)) {
                                e.defaultMuiPrevented = true;
                                const recipients = cloneDeep(this.state.recipients);
                                const emailValue = { id: -1, label: e.target.value, email: e.target.value, freeValue: e.target.value };
                                recipients.push(emailValue);
                                this.onFieldChanged('recipients', recipients);
                                e.target.blur && e.target.blur();
                            }
                        }}
                        filterOptions={(options: any, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;

                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.label || inputValue == option.email);
                            if (inputValue !== '' && !isExisting) {
                                if (this.emailIsValid(inputValue)) {
                                    filtered.push({ label: this.tr("Add '${inputValue}'", { inputValue }), id: -1, email: '', freeValue: inputValue });
                                }
                                filtered.push({ label: this.tr("Create contact '${inputValue}'", { inputValue }), inputValue, id: -2, email: '' });
                            }
                            return filtered;
                        }}
                        onChange={(_, newValue) => {
                            const recipients: RecipientOption[] = [];
                            newValue.forEach((value) => {
                                if (typeof value != 'string') {
                                    if (value.inputValue) {
                                        let nameOrEmailValues = {};
                                        if (value.inputValue.includes('@')) {
                                            nameOrEmailValues = {
                                                ...nameOrEmailValues,
                                                email: value.inputValue,
                                            };
                                        } else {
                                            const names = value.inputValue.split(' ');
                                            nameOrEmailValues = {
                                                ...nameOrEmailValues,
                                                firstname: names[0],
                                                lastname: names[1],
                                            };
                                        }
                                        onCreateRecipient(nameOrEmailValues);
                                        return;
                                    } else if (value.freeValue) {
                                        const emailValue = { ...value, label: value.freeValue, email: value.freeValue };
                                        recipients.push(emailValue);
                                        return;
                                    }
                                    recipients.push(value);
                                }
                            });
                            this.onFieldChanged('recipients', [
                                ...(this.props.fixedOptions || []),
                                ...recipients.filter((r) => (this.props.fixedOptions || []).findIndex((f) => f.email == r.email) == -1),
                            ]);
                        }}
                    />
                </div>
                {allowSubject && (
                    <div className={styles.field}>
                        <p>{this.tr('Subject')}:</p>
                        <input value={subject} onChange={(e) => this.onFieldChanged('subject', e.target.value)} />
                    </div>
                )}
                <textarea value={message} placeholder={this.tr('Your message here...')} onChange={(e) => this.onFieldChanged('message', e.target.value)} />
                {attachments && (
                    <div className={styles.attachments}>
                        {attachments.map((attachment, i) => {
                            return (
                                <div key={i} className={styles.attachment}>
                                    <AttachEmail />
                                    <p>{attachment.name}</p>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

interface QuoteEmailComposerViewState {
    contacts: any[];
    email: EmailComposeViewState;
}

interface QuoteEmailComposerViewProps {
    quote: any;
    project: any;
    onEmailChanged?: (email: EmailComposeViewState) => void;
    initialReceiver?: any;
    includeSelf?: boolean;
}

export class QuoteEmailComposerView extends TaimerComponent<QuoteEmailComposerViewProps, QuoteEmailComposerViewState> {
    selfOption: any;
    constructor(props, context) {
        super(props, context, 'projects/QuoteEmailComposerView');
        const recipients: any = [];
        this.selfOption = { id: this.context.userObject.usersId, label: this.context.userObject.fullname, email: this.context.userObject.email, isSelf: true, deletable: false };
        if (this.props.initialReceiver && !!this.props.initialReceiver.email) {
            recipients.push(this.props.initialReceiver);
        }
        this.state = {
            contacts: [],
            email: {
                recipients,
                message: '',
            },
        };
    }
    componentDidMount = () => {
        super.componentDidMount();
        this.getContacts();
    };

    componentDidUpdate = (_, oldState) => {
        if (!isEqual(oldState.email, this.state.email)) {
            this.props.onEmailChanged && this.props.onEmailChanged(this.state.email);
        }
    };

    getContacts = () => {
        const { project } = this.props;
        DataHandler.get({ url: `autocomplete_data/contacts/${project.companies_id}/customer/${project.account.id}` })
            .done((response) => {
                const contacts = (response.contacts || []).filter((c) => !!c.email);
                this.setState({ contacts });
            })
            .fail((error) => console.log(error));
    };

    onCreateRecipient = (params) => {
        const { project } = this.props;
        this.context.functions.addContact(
            {
                ...params,
                customers_id: project.account.id,
                projects: [{ name: `${project.name} (${project.project_id})`, label: `${project.name} (${project.project_id})`, id: project.id, value: project.id }],
            },
            { onContactCreated: this.onContactCreated }
        );
    };

    onContactCreated = (contact) => {
        const recipient: RecipientOption = { id: contact.id, label: `${contact.firstname} ${contact.lastname}`, email: contact.email };
        const recipients = cloneDeep(this.state.email?.recipients || []);
        recipients.push(recipient);
        this.setState({ email: { ...this.state.email, recipients } }, () => {
            this.getContacts();
        });
    };

    onEmailFieldChanged = (key: string, value: string | RecipientOption[]) => {
        this.setState({ email: { ...this.state.email, [key]: value } });
    };

    render() {
        const { contacts, email } = this.state;
        const { quote } = this.props;
        const { recipients, message } = email;
        return (
            <EmailComposeView
                recipients={recipients}
                recipientOptions={contacts}
                fixedOptions={this.props.includeSelf ? [this.selfOption] : undefined}
                onCreateRecipient={this.onCreateRecipient}
                attachments={[{ name: quote?.name == 'New quote' ? this.tr('New quote header') : quote?.name }]}
                onFieldChanged={this.onEmailFieldChanged}
                message={message}
            />
        );
    }
}

interface SendQuoteWizardProps {
    project: any;
    quote: any;
    printMode?: boolean;
    quoteTotal: number;
    currency: string;
}

interface SendQuoteWizardState {
    quotePDF?: any;
    email: EmailComposeViewState;
    printLanguage?: string;
    printDateFormat?: string;
    error?: boolean;
}

class SendQuoteWizard extends TaimerComponent<SendQuoteWizardProps, SendQuoteWizardState> {
    pages: TaimerWizardPage[] = [];
    tabQuotes: any = React.createRef();
    proposal: any = React.createRef();
    constructor(props, context) {
        super(props, context, 'projects/SendQuoteWizard');
        this.pages = props.printMode
            ? [
                  {
                      title: this.tr('Preview'),
                      content: {
                          main: <QuotePrintPreview quoteId={this.props.quote.id} project={this.props.project} onPDFChanged={this.onPDFChanged} />,
                      },
                      actions: [
                          {
                              label: this.tr('Print'),
                              disabled: () => !this.state.quotePDF,
                              action: this.printQuote,
                          },
                      ],
                  },
              ]
            : [
                  {
                      id: 'preview',
                      title: this.tr('Preview'),
                      content: {
                          main: () => (
                              <QuotePrintPreview
                                  quoteId={this.props.quote.id}
                                  project={this.props.project}
                                  onPDFChanged={this.onPDFChanged}
                                  onPrintSettingChanged={this.onPrintSettingChanged}
                                  onPrintSettingsLoaded={this.onPrintSettingsLoaded}
                                  printLanguage={this.state.printLanguage}
                                  printDateFormat={this.state.printDateFormat}
                              />
                          ),
                      },
                      actions: [
                          {
                              label: this.tr('Next'),
                              action: 'next',
                              disabled: () => !this.state.quotePDF,
                          },
                      ],
                  },
                  {
                      id: 'email',
                      title: this.tr('Compose email'),
                      content: { main: <QuoteEmailComposerView quote={this.props.quote} project={this.props.project} onEmailChanged={this.onEmailChanged} /> },
                      actions: [
                          {
                              label: this.tr('Send'),
                              action: this.sendEmail,
                              setLoading: true,
                              disabled: this.requiredEmailFieldsMissing,
                          },
                      ],
                  },
                  {
                      id: 'sent',
                      title: this.tr('Sent'),
                      content: { main: this.renderSent },
                      actions: [
                          {
                              label: this.tr('Done'),
                              action: this.onDone,
                          },
                      ],
                      noReturn: true,
                  },
              ];
        this.state = {
            email: {
                recipients: [],
                message: '',
            },
        };
    }

    printQuote = () => {
        const { project, quote } = this.props;
        const { quotePDF } = this.state;
        if (!quotePDF) return;
        FileSaver.saveAs(quotePDF, moment().format('YYYY-MM-DD') + '_' + quote.name + '_' + project.name + '.pdf');
        return 'close';
    };

    onEmailChanged = (email) => this.setState({ email });
    onPrintSettingChanged = (key, value) => {
        const settingUpdate = {
            [key]: value,
        };
        this.setState({ ...settingUpdate, quotePDF: undefined });
    };

    onPrintSettingsLoaded = (printSettings) => {
        this.setState({ ...printSettings });
    };

    onPDFChanged = (quotePDF) => {
        this.setState({ quotePDF });
    };

    sendEmail = async () => {
        const {
            email: { message, recipients },
            printLanguage,
            quotePDF,
        } = this.state;
        const { quote, project } = this.props;
        const {
            project_read_companies: companies,
            userObject: { fullname, dateFormat },
        } = this.context;
        const company = (companies || []).find((c) => c.id == project.companies_id)?.name || this.context.taimerAccount.name;
        const quoteName = `"${quote?.name == 'New quote' ? this.beTr('New quote header', printLanguage || 'en', {}, 'general/backendTranslations/QuotePrint') : quote.name}"`;
        const attachmentId = await generateQuoteAttachment(quote.id, project, quotePDF, this.tr('sent_quote') + '_' + moment().format(dateFormat).replace(/\./g, '_') + '.pdf');
        return new Promise<'next' | 'close'>((resolve) => {
            sendQuoteEmail({
                recipients: recipients.map((r) => r.email),
                subject: this.beTr('Sales quote ${quoteName} from ${company}', printLanguage || 'en', { quoteName, company }, 'general/backendTranslations/QuotePrint'),
                sender: this.beTr('${userName} via Heeros', printLanguage || 'en', { userName: fullname.split(' ').reverse().join(' ') }, 'general/backendTranslations/QuotePrint'),
                message: message || ' ',
                senderUser: fullname,
                senderCompany: company,
                quoteValue: `${this.context.functions.presentCurrency(this.props.quoteTotal, this.props.currency)} (${this.beTr(
                    'VAT 0%',
                    printLanguage || 'en',
                    {},
                    'general/backendTranslations/QuotePrint'
                )})`,
                quoteName,
                validUntil: quote.valid_until && moment(quote.valid_until, 'YYYY-MM-DD').isValid() ? moment(quote.valid_until, 'YYYY-MM-DD').format(dateFormat) : '-',
                attachmentId,
                companiesId: project.companies_id,
                quoteId: quote.id,
                lang: printLanguage || 'en',
            })
                .then(() => {
                    this.sendAnalyticsEvent();
                    resolve('next');
                })
                .catch((error) => {
                    console.error(error);
                    this.setState({ error: true }, () => {
                        resolve('next');
                    });
                });
        });
    };

    sendAnalyticsEvent = () => {
        const analyticsData = {
            taimer_version: this.context.versionId,
            taimer_language: this.context.userObject.language,
            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
        };
        //this.context.functions.sendAnalytics('quote_sent_by_email', analyticsData);
        this.context.functions.sendMixpanelEvent('Send sales quote');
    };

    requiredEmailFieldsMissing = () => {
        return (this.state.email?.recipients || []).length == 0;
    };

    onDone: () => 'close' = () => {
        window.dispatchEvent(new Event(`quoteSaved`));
        return 'close';
    };

    onClose = (currentPage) => {
        if (currentPage == this.pages.length) {
            this.onDone();
        }
    };

    renderSent = () => {
        const { error } = this.state;
        return (
            <div className={styles.sent}>
                {error ? <ErrorImage /> : <SentImage />}
                <h2>{error ? this.tr('Sending sales quote failed!') : this.tr('Sales quote sent successfully!')}</h2>
                <p>{error ? this.tr('Please try again later.') : this.tr('Now sit back and wait for approval.')}</p>
            </div>
        );
    };

    render() {
        return (
            <TaimerWizard title={this.props.printMode ? this.tr('Print quote') : this.tr('Send quote')} pages={this.pages} updateTriggers={{ quotePDF: this.state.quotePDF }} onClose={this.onClose} />
        );
    }
}

export default SendQuoteWizard;
