import React, { Component } from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

/* local components */
import OutlinedField from "./../../general/OutlinedField";
import Select, { components } from 'react-select';
import Creatable from "react-select/creatable";
import TreeDropdown from "../../general/TreeDropdown";
import ProjectTreeDropdown from "../../projects/ProjectTreeDropdown";
import { DatePicker } from './../../general/react-date-range/src';
import DataList from './../../general/DataList';

/* material ui */
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import { DropdownIndicator, IndicatorsContainer } from "../../list/cells/AutoCompleteCell";

// import { withStyles } from "@mui/material/styles";

/* context */
import { SettingsContext } from './../../SettingsContext';

import cloneDeep from "lodash/cloneDeep";

import "./Edit.css";

// Editor styles
import "./Select.css";

// If you're going to add any editor to EDIT_DIALOG_TYPE_MAP,
// please make it accept an onChange prop, which accepts a function, to which the selected
// value is passed.
// If possible, use the "drop" editor as an example.
// And all in all, try to provide an interface that is consistent with all the other editors used here.

const OPTION_LABEL_FIELDS  = ["label", "title", "name", "value"];
const EDIT_DIALOG_TYPE_MAP = {
    drop: (props) => {
        return (
            <OutlinedField disabled={props.disabled} select label={props.label} value={props.value} style={{ width: "100%" }} onChange={e => props.onChange(e.target.value)}>
                {props.options.map(option => {
                    const label = option.label || option.name || option.value;

                    return option.tooltip && option.disabled ? (
                        <Tooltip title={option.tooltip} placement="top">
                            <div>
                                <MenuItem disabled={option.disabled} key={option.value} value={option.value}>{label}</MenuItem>
                            </div>
                        </Tooltip>
                    ) : (
                        <MenuItem disabled={option.disabled} key={option.value} value={option.value}>{label}</MenuItem>
                    )
                })}
            </OutlinedField>
        );
    },
    treeDrop: (props) => {
        const options = props.options.map(o => {
            o.label = o.label || o.name || o.value;

            return o;
        });

        return (
            <TreeDropdown 
                data={options} 
                label={props.label}
                onSelect={el => props.onChange(el.id)}
                onlySelectMode={true}
                parentKey={props.parentKey || "parentId"}
                onErase={() => props.onChange(undefined)}
                value={props.value}
            />
        ); 
    },
    projectTreeDrop: (props) => {
        const options = props.options.map(o => {
            o.label = o.label || o.name || o.value;

            return o;
        });

        return (
            <ProjectTreeDropdown 
                name="project" // TODO: Onko tarpeellinen?
                // data={options} 
                label={props.label}
                onSelect={el => props.onChange(el.id)}
                onlySelectMode={true}
                // parentKey={props.parentKey || "parentId"}
                onErase={() => props.onChange(undefined)}
                value={props.value}
            />
        ); 
    },
    date: (props) => {
        return (
            <DatePicker 
                className="basic-info date"
                callOnChangeOnMount={true}
                label={props.label}
                onChange={props.onChange}
                date={props.value || new Date()}
            /> 
        ); 
    },
    dropMultiple: (props) => {
        const options = props.options.map(o => {
            o.label = o.label || o.name || o.value; 
            o.value = o.value || o.id || o.label;

            return o;
        });

        const SelectType = props.allowCreate ? Creatable : Select;

        return (
            <SelectType 
                components={{ DropdownIndicator: DropdownIndicator, IndicatorsContainer: IndicatorsContainer }}
                className="autoCompleteEditor"
                classNamePrefix="autoCompleteEditor"
                options={options}
                isMulti={true}
                isSearchable={true}
                openMenuOnFocus={true}
                onChange={value => {
                    props.onChange(value); 
                }}
            />
        ); 
    },
    text: (props) => {
        return (
            <OutlinedField
                label={props.label}
                value={props.value}
                style={{ width: "100%" }}
                onChange={e => props.onChange(e.target.value)}
            />
        );
    },
    textMultiline: (props) => {
        return (
            <OutlinedField
                multiline
                label={props.label}
                value={props.value}
                style={{ width: "100%" }}
                onChange={e => props.onChange(e.target.value)}
            />
        );
    },
    numeric: (props) => {
        return (
            <OutlinedField
                label={props.label}
                value={props.value}
                style={{ width: "100%" }}
                onChange={e => props.onChange(e.target.value.replace(/[^0-9.,\-]/g, ""))}
            />
        );
    },
    datalist: (props) => {
        const options = props.options.map(o => {
            o.label = o.label || o.name || o.value;

            return o;
        });

        return (
            <DataList 
                options={options} 
                label={props.label}
                onChange={el => props.onChange(el.id)}
                value={options.find(o => o.id == props.value)}
                shownCount={20}
                {...props.datalistProps}
            />
        ); 
    },
};

class Edit extends React.Component {
    static contextType  = SettingsContext;
    static defaultProps = {
        cancelText: "(placeholder)",
        confirmText: "(placeholder)",
        disableConfirm: false,
        fields: {},
        onCancel: () => {},
        onConfirm: () => {},
        sharedData: undefined,
        title: "",
        extraContent: undefined,
        additionalContent: undefined,
        hideEdit: false,
        isLoading: false
    };
    

    constructor(props, context) {
        super(props, context);

        this.initializeStateData = this.initializeStateData.bind(this);
        this.cancel              = this.cancel.bind(this);
        this.confirm             = this.confirm.bind(this);
        this.propertyChanged     = this.propertyChanged.bind(this);
        this.areSelectionsValid  = this.areSelectionsValid.bind(this);
        this.checkValidity       = this.checkValidity.bind(this);
        this.createEditors       = this.createEditors.bind(this);
        this.isEmpty             = this.isEmpty.bind(this);
        this.emptyValues         = this.emptyValues.bind(this);

        this.state                = { 
            data: this.initializeStateData(),
            valid: false
        };
    }


    componentDidUpdate(prevProps, prevState) {
        // The fields to edit have changed, 
        // insert new properties into state data.
        if(this.props.fields !== prevProps.fields) {
            // I'm not sure I know all the rules,
            // but I surely am breaking some of them!
            setTimeout(() => {
                this.setState({
                    data: this.initializeStateData() 
                }, this.checkValidity);
            });
        }
    }


    initializeStateData() {
        // Dummy call the fields props if it's a function 
        // to instantiate data with the correct names in both cases;
        const fields = (typeof(this.props.fields) === "function") ? this.props.fields({}, this.props.sharedData) : this.props.fields;
        const data   = {};

        Object.keys(fields).forEach(name => {
            if(this.state && this.state.data !== undefined && this.state.data[name] !== undefined)
                data[name] = this.state.data[name];
            else
                data[name] = fields[name].hasOwnProperty("value") ? fields[name].value : undefined;
        });

        return data;
    }


    cancel() {
        this.props.onCancel(); 
    }


    confirm() {
        this.props.onConfirm(this.state.data); 
    }


    propertyChanged(name, value) {
        const data = cloneDeep(this.state.data);
        data[name] = value;

        this.setState({ data }, this.checkValidity);
    }

    emptyValues(names) {
        const data = cloneDeep(this.state.data);
        names.forEach(n => {
            data[n] = undefined;
        })

        this.setState({data, valid: false });
    }

    areSelectionsValid() {
        const invalid = Object.keys(this.state.data).filter(name => {
            return this.isEmpty(this.state.data[name]);
        });

        return invalid.length === 0;
    }


    // TODO: This is temporary. Make it possible to pass validation conditions in field props.
    isEmpty(value) {
        let isEmpty = false;

        return (Array.isArray(value) && value.length === 0)
            ||
            (typeof(value) === "string" && value.trim().length === 0)
            ||
            value === undefined;
    }


    checkValidity() {
        const valid = this.areSelectionsValid();

        if(this.state.valid === valid)
            return;

        this.setState({ valid: valid });
    }


    createEditors() {
        const editors = [];
        const fields  = (typeof this.props.fields === "function") ? this.props.fields(this.state.data, this.props.sharedData) : this.props.fields;

        for(let name in fields) {
            let fConf = fields[name];

            if(!EDIT_DIALOG_TYPE_MAP.hasOwnProperty(fConf["_type"]))
                continue;

            // If an onChange callback was already passed with the 
            // field's configuration, call that in the editor's onChange.
            let onChange = fConf.onChange ? fConf.onChange : () => {};

            fConf.onChange = (value) => {
                onChange(value);

                this.propertyChanged(name, value);
            };

            fConf.value = this.state.data.hasOwnProperty(name) ? this.state.data[name] : undefined;

            editors.push(EDIT_DIALOG_TYPE_MAP[fConf["_type"]](fConf));
        }

        return editors;
    }

    renderContent = () => {
        const editors = this.createEditors();

        if(this.props.isLoading) {
            return <div className="loading"><Loading  /></div>
        }

        return (
            <DialogContent className="content">
                {this.props.additionalContent && this.props.additionalContent()}
                {!this.props.hideEdit && editors.map(editor => {
                    return editor; 
                })}
                {this.props.extraContent && this.props.extraContent()}
            </DialogContent>
        )
    }


    render() {
        return (
            <div className="edit main implementation" style={{ minWidth: "400px" }}>
                <DialogTitle className="title">
                    {this.props.title}<span className="subtitle">{this.props.subtitle}</span>
                </DialogTitle>
                {this.renderContent()}
                <DialogActions className="buttons">
                    <Button onClick={this.cancel} variant="text" className="white">
                        {this.props.cancelText}
                    </Button>
                    <Button disabled={this.props.disableConfirm || !this.state.valid || this.props.isLoading} onClick={this.confirm} variant="contained" color="primary">
                        {this.props.confirmText}
                    </Button>
                </DialogActions>
            </div>
        );
    }
}

export default Edit;
