/* react */
import React from 'react';

/* css */
import './Efina.css';
import './Maventa.css';

/* material-ui */
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";

/* others */
import DataHandler from '../../general/DataHandler';
import { SettingsContext } from '../../SettingsContext';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";

class Taimer extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Taimer");

        this.state = {
            activated: 0,
            apikey: "",
            dialogData: undefined
        };

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/taimer`}).done(response => {
            this.setState(response);
        });
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    deactivate = () => {
        this.setState({
            dialogData: {
                saveFunc: () => DataHandler.delete({url: `settings/company/${this.props.company}/taimer`}).done(this.back),
                text: this.tr("Do you want to deactivate the integration? Your API key will be invalidated and your requests to the API will stop working.")
            }
        }, () => this.openDialog('confirmation'));
    }

    generateApiKey = () => {
        this.setState({
            dialogData: {
                saveFunc: () => DataHandler.get({url: `settings/company/${this.props.company}/taimer/apikey`}).done(response => {
                    this.setState({...response, ...{activated: "1"}});
                }),
                text: this.tr("Do you want to generate a new API key? Old key will be invalidated and your requests will stop working with the old API key.")
            }
        }, () => this.openDialog('confirmation'));
    }
    
    render(){
        const { activated, apikey } = this.state;        
        const { tr } = this;

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Taimer API Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("Company API key")}</h4>
                    <p>{tr("Generate API Key to access Taimer API by pressing the 'Generate API key' button. API key is valid until you generate new key or you deactivate the integration.")}</p>
                    <OutlinedField value={apikey} onChange={(evt) => this.setState({username: evt.target.value})} label={tr("Company API key")} />
                </div>

                <div className="settings">
                    <Button color="primary" onClick={this.generateApiKey} size="large">{tr("Generate API key")}</Button>
                </div>

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
  
            </div>
        )
    }
}

export default Taimer;