import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import OutlinedField from "../../general/OutlinedField";
import Button from '@mui/material/Button';
import DataHandler from '../../general/DataHandler';
import { withSnackbar } from 'notistack';

const styles = theme => ({
});

class TermsAndConditions extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/TermsAndConditions");

        this.state = {
            loaded: false,
            canJoin: true,
        }
    }
 
    render() {
        const { tr } = this;
        return <React.Fragment>
             <div className="settings-feature-firstwrapper">
                <h3>{tr("Terms & Conditions")}</h3>
                <p style={{textAlign: 'center'}}>
                    <a href={this.context.userObject.language == "fi" ? "https://psahelpcenter.heeros.com/hc/fi/articles/10207381240978" : "https://psahelpcenter.heeros.com/hc/en-us/articles/10207381240978"} target="_blank" rel="noopener noreferrer">{tr("Terms of Services")}</a>
                 |
                    <a href={this.context.userObject.language == "fi" ? "https://psahelpcenter.heeros.com/hc/en-us/articles/8081572644754" : "https://psahelpcenter.heeros.com/hc/en-us/articles/8081572644754"} target="_blank" rel="noopener noreferrer">{tr("Privacy Policy")}</a>
                </p>
            </div>
        </React.Fragment>
    }
}

export default withSnackbar(withStyles(styles)(TermsAndConditions));
