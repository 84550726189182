import React from "react";
import List from "./../List";

class SettingsList extends React.Component {
    static defaultProps = {
        onDataChange: () => {}
    };


    constructor(props) {
        super(props);

        this.state = {
            hideHeader: false 
        };
    }


    render() {
        return (
            <List 
                ref={this.props.innerRef}
                {...this.props} 
                hideHeader={this.state.hideHeader}
                disableNewData={true}
                onDataChange={event => {
                    this.props.onDataChange(event);

                    if((event.newData.length === 0 && event.data.length === 0) === this.state.hideHeader) {
                        return;
                    }

                    this.setState({ hideHeader: !this.state.hideHeader });
                }}
            />
        );
    }
}

export default SettingsList;
