import {
  addDays,
  addMonths,
  addYears,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns';

const defineds = {
  startOfWeek: () => startOfWeek(new Date(), {weekStartsOn: 0}),
  endOfWeek: () => endOfWeek(new Date(), {weekStartsOn: 0}),
  startOfLastWeek: () => startOfWeek(addDays(new Date(), -7), {weekStartsOn: 0}),
  endOfLastWeek: () => endOfWeek(addDays(new Date(), -7), {weekStartsOn: 0}),
  startOfToday: () => startOfDay(new Date()),
  endOfToday: () => endOfDay(new Date()),
  startOfYesterday: () => startOfDay(addDays(new Date(), -1)),
  endOfYesterday: () => endOfDay(addDays(new Date(), -1)),
  startOfMonth: () => startOfMonth(new Date()),
  endOfMonth: () => endOfMonth(new Date()),
  startOfLastMonth: () => startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: () => endOfMonth(addMonths(new Date(), -1)),
  startOfNextMonth: () => startOfMonth(addMonths(new Date(), +1)),
  endOfNextMonth: () => endOfMonth(addMonths(new Date(), +1)),
  oneYearFromNow: () => addYears(new Date(), 1),
  startOfYear: () => startOfYear(new Date()),
  endOfYear: () => endOfYear(new Date()),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday(),
      endDate: defineds.endOfToday(),
    }),
  },
  /*{
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },*/

  {
    label: 'This Week',
    range: () => ({
      startDate: defineds.startOfWeek(),
      endDate: defineds.endOfWeek(),
    }),
  },
  {
    label: 'Last Week',
    range: () => ({
      startDate: defineds.startOfLastWeek(),
      endDate: defineds.endOfLastWeek(),
    }),
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: defineds.startOfMonth(),
      endDate: defineds.endOfMonth(),
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: defineds.startOfLastMonth(),
      endDate: defineds.endOfLastMonth(),
    }),
  },
  {
    label: 'Next Month',
    range: () => ({
      startDate: defineds.startOfNextMonth(),
      endDate: defineds.endOfNextMonth(),
    }),
  },
  {
    label: 'Full year',
    range: () => ({
      startDate: defineds.startOfYear(),
      endDate: defineds.endOfYear(),
    }),
  },
]);

export function getDefaultStaticRanges(dateOptions, rangeOptions = {}) {

  const rangesToDisplay = [
    {
      label: 'Today',
      range: () => ({
        startDate: defineds.startOfToday(),
        endDate: defineds.endOfToday(),
      }),
    },
    /*{
      label: 'Yesterday',
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },*/
  
    {
      label: 'This Week',
      range: () => ({
        startDate: startOfWeek(new Date(), dateOptions),
        endDate: endOfWeek(new Date(), dateOptions),
      }),
    },
    {
      label: 'Last Week',
      range: () => ({
        startDate: startOfWeek(addDays(new Date(), -7), dateOptions),
        endDate: endOfWeek(addDays(new Date(), -7), dateOptions),
      }),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: defineds.startOfMonth(),
        endDate: defineds.endOfMonth(),
      }),
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: defineds.startOfLastMonth(),
        endDate: defineds.endOfLastMonth(),
      }),
    },
    {
      label: 'Next Month',
      range: () => ({
        startDate: defineds.startOfNextMonth(),
        endDate: defineds.endOfNextMonth(),
      }),
    },
    {
      label: 'Full year',
      range: () => ({
        startDate: rangeOptions.useFinancialYear ? rangeOptions.financialYear.start : defineds.startOfYear(),
        endDate: rangeOptions.useFinancialYear ? rangeOptions.financialYear.end : defineds.endOfYear(),
      }),
    },
  ];

  return createStaticRanges(rangesToDisplay);
}

export const defaultInputRanges = [
  {
    label: 'days up to today',
    range(value) {
      return {
        startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
        endDate: defineds.endOfToday,
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
      if (!range.startDate) return '∞';
      return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
    },
  },
  {
    label: 'days starting today',
    range(value) {
      const today = new Date();
      return {
        startDate: today,
        endDate: addDays(today, Math.max(Number(value), 1) - 1),
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.startDate, defineds.startOfToday)) return '-';
      if (!range.endDate) return '∞';
      return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
    },
  },
];
