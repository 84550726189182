import cn from 'classnames';
import React from 'react';
import { SettingsContext } from '../../SettingsContext';
import TaimerComponent from '../../TaimerComponent';
import ColorBall from './ColorBall';
import { getColorClass } from './Colors';
import './SidebarProject.css';

// Project item in sidebar
class SidebarProject extends TaimerComponent {
	static contextType = SettingsContext;
	constructor(props, context)
	{
		super(props, context, "workhours/time-tracker/SidebarProject");

		this.state = {
			showTooltip: false,
		}

		this.refTitle = React.createRef();
	}

	componentDidMount = () => {
		const el = this.refTitle.current;

		// If is truncated activate tooltip
		if (el.scrollWidth > el.clientWidth)
		{
			this.setState({showTooltip: true});
		}
	}

	onHide = () => {
		this.props.onHide(this.props.item);
	}

	onChangeColor = (color) => {
		this.props.onChangeColor && this.props.onChangeColor(this.props.item, color);
	}

	render() {
		const { item, disableDrag } = this.props;

		return (<div title={this.state.showTooltip ? item.name : undefined} className={cn("SidebarProject", !disableDrag && "draggable", getColorClass(item, 'project'))}>
				{this.state.showTooltip && <div className="tooltip">{item.name}</div>}
				<ColorBall onChangeColor={this.onChangeColor} />
				<div className="titleContainer">
					<div className="title" ref={this.refTitle}>{item.name}</div>
					<div className="subtitle">{item.unit?.id ? item.unit.name : item.customer.name}</div>
				</div>
			<div className="hours">{item.hours || 0.00} / {item.maxhours || 0.00} h</div>
				<button title = {this.tr("Hide the project")} className="remove" onClick={this.onHide}>X</button>
			</div>);
	}
}

export default SidebarProject;