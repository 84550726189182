import React from "react";

import SettingRow from "./SettingRow";
import ListCell from "../ListCell";
import TextInputCell from "../cells/TextInputCell";

import TrashCanIcon from "@mui/icons-material/Delete";
import { CheckBox } from "@mui/icons-material";
import { Switch } from "@mui/material";

import PropsOnlyListRow from "../PropsOnlyListRow";

import './PriorityListRow.css';

class PriorityListRow extends PropsOnlyListRow {
	constructor(props) {
		super(props);
    }

    editAndSave = (name, value) => {
        let data   = this.props.data;
        data[name] = value;

        this.setDataAndUpdate(data);
    }
    
    changeDefault = (e, checked) => {
        this.editAndSave("is_default", checked ? "1" : "0");
    }

    defineCells() {
        const { data } = this.props;

        const Icon = data.icon;

        let cells = {
            name:   
                <ListCell alignCell onlyDisplay name="name" value={data['name']}>
                    <Icon /> {data.name}
                </ListCell>,
            default:
                <ListCell className="" width={this.props.columnWidthMap['default']} onlyDisplay>
                    <Switch color="primary" checked={data.default} onChange={this.changeDefault} />
                </ListCell>

        };

        return cells;
    }

    defineClassName() {
        return "PriorityListRow";
    }
}

export default PriorityListRow;