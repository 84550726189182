import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import List from "../../list/List";
import NotificationListRow from '../../list/rows/NotificationListRow';
import OutlinedField from "../../general/OutlinedField";
import InsightDropDown from "../../dashboard/insights/InsightDropDown";

import TreeSettingsList from "../components/TreeSettingsList";

import NotificationsDefinitions from './../../notifications/NotificationsDefinitions';

import { MenuItem } from '@mui/material';

import _ from 'lodash';
import {cloneDeep, omit, isEqual, merge} from "lodash";

const styles = theme => ({
    main: {
        paddingLeft: 0,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

class Notifications extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/Notifications");
        const { tr } = this;

        this.state = {
            loaded: false,
            notificationsSettings: {},
            savedNotifications: [],
            enabledIntegrationTypes: {},
            hasPoRights: false,
            company: props.company || '1'
        }

        this.timeTrackerList = React.createRef();
        this.invoiceRefs = {
            1: this.newList = React.createRef(),
            2: this.overdueList = React.createRef(),
            3: this.paidList = React.createRef(),
        }
        this.projectRefs = {
            1: this.roleAddedList = React.createRef(),
        }
        this.definedNotifications = NotificationsDefinitions.NotificationsDefinitions.getDefinitions();
        const treeColumnConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
        this.treeListColumns = [
            { width: 300, name: "name", ...treeColumnConf },
            { width: 60, name: "enable_notification", header: tr("In app"), className: 'align-center', ...treeColumnConf},
            { width: 60, name: "enable_email", header: tr("Email"), className: 'align-center', ...treeColumnConf},
        ]

    }    

    async componentDidMount() {
        super.componentDidMount();
        this.getSettings();
        DataHandler.get({url: 'subjects/companies/notifications'}).done( companies => this.setState({companies}) );
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(this.props.company != prevProps.company) {
            this.setState({company: this.props.company}, () => {
                this.getSettings();
            });
        }
    }

    getSettings = async (data) => {

        const {company} = this.state;
        let settings = {};
        if (data) {
            const {savedNotifications} = this.state;
            data.forEach(d => {
                Object.entries(savedNotifications).forEach(([group, s]) => {
                    savedNotifications[group] = s.map(r => ({
                        ...r, 
                        ...(r.level == d.level && r.settings_group_id == d.settings_group_id && r.sub_type == d.sub_type ? {enable_notification: d.enable_notification, enable_email: d.enable_email} : {})
                    }));
                })
            })
            settings = {
                has_po_rights: this.state.hasPoRights,
                ...this.state.savedNotifications
            }
            
        } else {
            settings = await DataHandler.get({url: `settings/company/${company}/notifications/settings`, users_id: this.props.settingsLevel == 'user' ? this.context.userObject.usersId : 0});

        }

        const { has_po_rights, ...rest } = settings;
        const notificationsSettings = {};

        Object.entries(this.definedNotifications).forEach(([i, d]) => {
            if (d.visible === false)
                return;

            notificationsSettings[i] = {};
            Object.entries(d.setting_groups).forEach(([j, sg]) => {
                notificationsSettings[i][j] = [];
                let index = 1;
                sg.settings.forEach(s => {
                    let currentMainLevelIndex = 1;
                    if (!s.visible)
                        return;
                  
                    s?.levels?.forEach(l => {
                        //both 0 (possible group joint set value) or -2 (pseudolevel for user experience) act as main level identifiers here 
                        if (l.level == -2)
                            currentMainLevelIndex = index;

                        let override = {};
                        //as we have two different criteria for grouping - levels and predefined subtypes
                        const controls = l.controls || s.controls;
                        const groupId = l.settings_group_id || s.id;

                        const overrides = rest[i]?.filter(r => controls?.includes(Number(r.sub_type)) && l.level == r.level && r.settings_group_id == groupId);                        

                        if (overrides) {
                            override = overrides.reduce((acc, o) => merge(acc, o), {});                            
                            override.realIds = overrides.map(o => o.realId);
                        }
                        
                        if (l.level == -2) {
                            const allowedLevels = s.levels?.map(x => x.level) ?? [];
                            override = override || {};
                            const groupItems = rest[i]?.filter(r => controls?.includes(Number(r.sub_type)) && allowedLevels.includes(Number(r.level)) && r.settings_group_id == groupId);

                            if (groupItems?.length > 0) {                                
                                override.partiallyCheckedNotification = groupItems?.some(r => r.enable_notification > 0) && (groupItems.length != (s.levels.length-1)*controls.length || !groupItems?.every(r => r.enable_notification > 0));
                                override.partiallyCheckedEmail = groupItems?.some(r => r.enable_email > 0) && (groupItems.length != (s.levels.length-1)*controls.length || !groupItems?.every(r => r.enable_email > 0));
                            }

                            if (((groupItems?.length / controls.length) == s.levels.length -1) || (s.forcedGrouping && (groupItems?.length == s.levels.length -1)) ) {                               
                                override.enable_notification = groupItems?.every(r => r.enable_notification > 0);
                                if (override.enable_notification > 0)
                                    override.partiallyCheckedNotification = null;
                                override.enable_email = groupItems?.every(r => r.enable_email > 0);
                                if (override.enable_email)
                                    override.partiallyCheckedEmail = null;
                            }
                        }

                        notificationsSettings[i][j].push({
                            id: index,
                            users_id: this.props.settingsLevel == 'user' ? this.context.userObject.usersId : 0,
                            companies_id: company,
                            sub_types: controls,
                            level: l.level,
                            levels: s.levels,
                            name: l.header || s.header,
                            enable_notification: override?.enable_notification || 0,
                            enable_email: override?.enable_email || 0,
                            partiallyCheckedNotification: override.partiallyCheckedNotification || false,
                            partiallyCheckedEmail: override.partiallyCheckedEmail || false,
                            parent_id: l.level == -2 || s.levels?.length < 2 ? null : currentMainLevelIndex,
                            realId: override?.realId,
                            realIds: override?.realIds,
                            settings_group_id: groupId,
                            forcedGrouping: s.forcedGrouping,
                            settings: override?.settings,
                            settingsString: l.settingsString ?? s.settingsString,
                            settingsConfig: l.settingsConfig ?? s.settingsConfig,

                        })
                        index ++;
                    })
                })
            })
        });

        this.setState({notificationsSettings: notificationsSettings, savedNotifications: rest, hasPoRights: !!has_po_rights});
    }

    gatherTreeListProps = (dataClass, index, groupIndex) => {

        if (!dataClass)
            return false;

        return {
            rows: dataClass, 
            columns: this.treeListColumns.map(r => r.header ? r : ({...r, header: this.definedNotifications[index].setting_groups[groupIndex].title})),
            onRowChanged: (row, changedField) => this.onSettingRowChanged(row, changedField, {index: index, groupIndex: groupIndex}),
            startWithClosedRows: true
        };
    }

    onSettingRowChanged = (row, changedField, params = {}) => {

        const savedSettings = cloneDeep(this.state.savedNotifications);
        const notificationsSettings = cloneDeep(this.state.notificationsSettings);
        const {company} = this.state;
        let data = [];
        let dataRow = {};

        if (row.level >= 0) {
            data = row.sub_types.map(t => {
                dataRow = savedSettings[params.index]?.find(r => r.sub_type == t && r.level == row.level && r.settings_group_id == row.settings_group_id);
                dataRow = {
                    ...dataRow, 
                    sub_type: t, 
                    level: row.level, 
                    users_id: this.props.settingsLevel == 'user' ? this.context.userObject.usersId : 0, 
                    id: this.props.settingsLevel == 'user' && dataRow?.users_id < 1 ? null : dataRow?.realId,
                    settings_group_id: row.settings_group_id,
                    ...changedField};
                return dataRow;
            });
        } else if (row.level == '-2') {

            if (row.forcedGrouping) {
                    const dataRows = notificationsSettings[params.index][params.groupIndex].filter(r => r.parent_id == row.id && r.settings_group_id == row.settings_group_id);

                    if (dataRows.length > 0) {
                        dataRows?.forEach((d, i)=> {
                            dataRow = savedSettings[params.index]?.find(r => d.realId == r.realId && r.level >= 0);

                            dataRow = {
                                ...dataRow, 
                                sub_type: dataRow?.sub_type || row.levels[i+1]?.controls?.[0] || row.sub_types[i], 
                                level: d.level, 
                                users_id: this.props.settingsLevel == 'user' ? this.context.userObject.usersId : 0, 
                                id: this.props.settingsLevel == 'user' && dataRow?.users_id < 1 ? null : dataRow?.realId, 
                                settings_group_id: row.settings_group_id, 
                                ...changedField};
                          
                            data.push(dataRow);
                        })
                    }                

            } else {
                row.sub_types.forEach(t => {
                    // let dataRows = notificationsSettings[params.index][params.groupIndex].filter(r => isEqual(r.sub_types, row.sub_types) && r.level >= 0 && r.settings_group_id == row.settings_group_id);

                    const dataRows = notificationsSettings[params.index][params.groupIndex].filter(r => r.parent_id == row.id && r.settings_group_id == row.settings_group_id);

                    if (dataRows.length > 0) {
                        dataRows?.forEach(d => {
                            dataRow = savedSettings[params.index]?.find(r => d.realIds?.includes(r.realId) && r.sub_type == t && r.level >= 0);

                            dataRow = {
                                ...dataRow, 
                                sub_type: t, 
                                level: d.level, 
                                users_id: this.props.settingsLevel == 'user' ? this.context.userObject.usersId : 0, 
                                id: this.props.settingsLevel == 'user' && dataRow?.users_id < 1 ? null : dataRow?.realId, 
                                settings_group_id: row.settings_group_id, 
                                ...changedField};
                            data.push(dataRow);
                        })
                    }
                });                
            }


        }
        this.getSettings(data);
        DataHandler.post({url: `settings/company/${company}/notifications`}, {data}).done(resp => {
            this.getSettings();
        });

    }

    render() {

        const { tr } = this;
        const {group} = this.props;
        const {company} = this.state;
        const { notificationsSettings, hasPoRights, companies } = this.state;
        const { userObject, addons, functions: { checkPrivilege } } = this.context;

        return (
            <div id="settings-notifications">
                <React.Fragment>
                    <div className="top-container">
                        <div className="top-left">
                            <h3>{this.props.group == 'company' ? tr("Your company notification preferences") : tr("Your notification preferences")}</h3>
                            <span>{this.props.group == 'company' ? tr("Here you can define the company's notification settings") : tr("Here you can define how you want to be notified")}</span>
                        </div>
                        {companies && companies.length > 1 && this.props.group != 'company' && <InsightDropDown
                            title={tr('company')}
                            tabs={companies.map(c => ({
                                key: c.id,
                                label: c.name,
                                action: () => this.setState({company: c.id}, () => this.getSettings()),
                            }))


                                }
                            selected={company} />
                        }
                    </div>
                </React.Fragment>

                {Object.entries(notificationsSettings).map(([i, s]) => 
                    Object.entries(s).map(([groupid, settings]) => {
                        let condition = true;
                        switch (i) {
                            case 1:
                                condition = addons && addons.timetracker;
                            case 2:
                                const basicRights = checkPrivilege("invoices", "write_simple", company);
                                const fullRights = checkPrivilege("invoices", "write_full", company);
                                if (!checkPrivilege("invoices", "write_full", company)) {
                                    settings = settings.filter(x => [5,6,9,10,13,14].indexOf(x.id) > -1);
                                }
                                condition = basicRights || fullRights;
                                break;
                            case 4:
                                condition = hasPoRights;
                                break;

                        }

                        // Hide from company settings if setting is common for all companies. So no company defaults can be selected.
                        const hideSetting = group == "company" && this.definedNotifications[i]?.commonForAllCompanies;

                        return condition && !hideSetting ? (
                            <TreeSettingsList {...this.gatherTreeListProps(settings, i, groupid)} />
                        ) : null;
                    })
                )}

            </div>
        ) 
    }
}

export default withStyles(styles)(Notifications);
