import TaimerComponent from '../TaimerComponent';
import DialogBorder from './DialogBorder';
import LoaderButton from "../general/LoaderButton";
import './SubtitleDialog.css';

/* material ui */
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

export interface SubtitleDialogProps {
    onDialogClose: (event?: any, reason?: string) => void;
    onConfirm?: (event?: any, reason?: string) => void;
    title?: string;
    subtitle?: string;
    content?: any;
    hideButtons?: boolean;
    confirmButtonText?: string;
    confirmButtonClass?: string;
    confirmDisabled?: boolean;
    className?: string;
    loading?: boolean;
    confirmButtonLoading?: boolean;
    additionalButton?: any;
    confirmHidden?: boolean;
    confirmDisabledTooltip?: string;
}

class SubtitleDialog extends TaimerComponent<SubtitleDialogProps> {
    constructor(props, context) {
        super(props, context, "dialogs/SubtitleDialog");
    }

    renderButtons = () => {
        const { onDialogClose, onConfirm, confirmButtonText, confirmDisabled, confirmButtonClass, confirmButtonLoading, additionalButton, confirmHidden, confirmDisabledTooltip } = this.props;

        return (
            <DialogActions className="buttons">
                <Button
                    data-testid="button-cancel"
                    className={"cancel-delete"}
                    onClick={(e) => {
                        onDialogClose();
                        e.stopPropagation();
                    }}
                    variant="text">
                    {this.tr('CANCEL')}
                </Button>
                <div className='rightButtons'>
                    {!confirmHidden && <LoaderButton
                        data-testid="button-confirm"
                        notAllowed={confirmDisabled}
                        notAllowedTooltip={confirmDisabledTooltip}
                        loading={confirmButtonLoading}
                        onClick={(e) => {
                            onConfirm && onConfirm();
                            e.stopPropagation();
                        }}
                        variant={"outlined"}
                        className={confirmButtonClass ? confirmButtonClass : "blue"}
                        text={confirmButtonText}
                    />}
                    {additionalButton && <div className='additionalButton'>
                        {additionalButton}
                    </div>}
                </div>
            </DialogActions>
        )
    }

    renderLoading = () => {
        return (
            <div className='loading-container'><Loading  /></div>
        )
    }

    render() { 
        const { title, subtitle, onDialogClose, children, hideButtons, className, loading } = this.props;

        return (
            <DialogBorder
                id="SubtitleDialog"
                title={title || ""}
                subtitle={subtitle || ""}
                onClose={onDialogClose}
                className={`TMRDialogGrid TMRDialogInputs ${className ? className : ""}`}
                hideCancel={true}
                >
                <div className="content">
                    {loading && this.renderLoading()}
                    {!loading && children}
                </div>
                { !hideButtons && this.renderButtons() }
            </DialogBorder>
        )
    }
}

export default SubtitleDialog;