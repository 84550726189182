import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';

import { SettingsContext } from '../../SettingsContext';
import OutlinedField from "../../general/OutlinedField";
import { DatePicker } from '../../general/react-date-range/src';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { TextField, MenuItem } from '@mui/material';

import { format } from "date-fns";
import { withSnackbar } from 'notistack';

import './../../general/react-date-range/src/styles.css';
import './../../general/react-date-range/src/theme/default.css';
import DataHandler from '../../general/DataHandler';
import TaimerComponent from '../../TaimerComponent';

const styles = theme => ({
    topBar: {
        backgroundColor: '#2D9FF7',
        height: '8px',
        borderRadius: '4px 4px 0 0',
        marginBottom: '10px'
    },
    field: {
        marginBottom: "10px",
        width: "calc(100% - 14px)"
    },
    shrink: {
        fontSize: "14px"
    },
    input: {
        textAlign: 'right',
        paddingTop: '19px',
        paddingBottom: '19px',
        fontSize: "14px",
        paddingRight: "15px"
    },
    datePicker: {
        width: '100%',
        boxSizing: 'border-box',
        minHeight: '56px',
        margin: '8px 0 4px'
    }    
});

class BillAssign extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/dialogs/BillAssign");

        this.state = {
            user: props.user,
            message: '',
            hideButtons: false,
        }
    }

    handleCancel = () => {
        this.props.onClose();
    }

    handleSave = async () => {
        const { user, message } = this.state;
        let response = {};
        if(this.props.makeRequest) {
            try {
                response = await DataHandler.post({
                    url: 'bills/status'
                }, {
                    ids: JSON.stringify(this.props.ids),
                    user,
                    message,
                    status: this.props.status
                });
            } catch (e) {
                this.props.enqueueSnackbar(this.tr("Error in updating bills!"), {
                    variant: "error",
                });
            }
        }

        this.props.onSaved && this.props.onSaved({ response, user: user, message: message, status: this.props.status });
        this.props.onClose && this.props.onClose();
    }

    onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

    render() {
        const { tr } = this;
        const { taimerAccount, userObject } = this.context;

        const { classes, autoCompleteData, status } = this.props;
        const { user, message } = this.state;

        const statusLabel = status !== undefined && status !== undefined && autoCompleteData.bills_statuses.find(x => x.id == status);

        return (
            <Dialog
                open
                className="invoiceDialog"
                maxWidth="md"
                onClose={this.handleCancel}
                aria-labelledby="billAssign" >
                <div className={classNames(classes.topBar)}/> 
                <DialogContent className="invoiceDialogContent">
                    {statusLabel && <div>{statusLabel.label}</div>}
                    <OutlinedField 
                        fullWidth
                        select 
                        label={this.tr("Assigned User") }
                        className="listFilterOutlinedField" 
                        value={user}>
                        {
                            autoCompleteData.users.map( (opt) =>
                                <MenuItem value={opt.id} key={opt.id} onClick={() => this.setState({user: opt.id})}>
                                    {opt.name}
                                </MenuItem>
                            )
                        }
                    </OutlinedField>

                    <TextField 
                        rows={3}
                        // InputProps={{classes: {root: classes.comment}}} 
                        variant="filled" 
                        multiline 
                        name="message"
                        placeholder={this.tr("Message")}
                        onChange={this.onChange}
                        value={message}
                        fullWidth />
                </DialogContent>
                <DialogActions>
                    {this.state.hideButtons == false && <Button data-testid="bill_assign_cancel" size="large" variant="outlined" onClick={this.handleCancel} >
                        {this.tr('Cancel')}
                    </Button>}
                    {this.state.hideButtons == false && <Button data-testid="bill_assign_ok" size="large" variant="outlined" onClick={() => this.setState({hideButtons: true}, this.handleSave)} color="primary">
                        {this.tr('Ok')}
                    </Button>}
                    {this.state.hideButtons == true && <div>
                        <p>{this.tr("Updating invoices. This may take a while.")}</p>
                    </div>}
                </DialogActions>
            </Dialog>
        );
    }
}

BillAssign.defaultProps = {
    onDialogClose: () => {},
    makeRequest: true
}

export default withSnackbar(withStyles(styles)(BillAssign));
