import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

import TaimerComponent from '../TaimerComponent';
import styles from './PDFView.module.scss';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

interface PDFViewProps {
    file: any;
}

interface PDFViewState {
    pageCount: number;
}

class PDFView extends TaimerComponent<PDFViewProps, PDFViewState> {
    constructor(props, context) {
        super(props, context, 'general/PDFView');
        this.state = {
            pageCount: 0,
        };
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ pageCount: numPages });
    };

    renderLoader = () => {
        return (
            <div className={styles.loadingPDF}>
                <Loading  />
                <p>{this.tr('Generating PDF')}...</p>
            </div>
        );
    };

    renderError = () => {
        return (
            <div className={styles.loadingPDF}>
                <p>{this.tr('Generating PDF failed.')}</p>
            </div>
        );
    };

    render() {
        const { pageCount } = this.state;
        const { file } = this.props;
        const pages: any[] = [];

        for (let i = 1; i <= (pageCount || 0); i++) {
            pages.push(
                <div className={styles.page}>
                    <Page pageNumber={i} width={700} />
                </div>
            );
        }
        return (
            <div className={styles.pdfView}>
                <Document file={file} onLoadSuccess={this.onDocumentLoadSuccess} loading={this.renderLoader} noData={this.renderLoader} error={this.renderError}>
                    {pages}
                </Document>
            </div>
        );
    }
}

export default PDFView;
