// Material UI
import ContentCopy from '@mui/icons-material/ContentCopy';
import { Button, CircularProgress, DrawerProps, Tooltip } from '@mui/material';

// Local components
import TaimerComponent from '../TaimerComponent';
import CoreDialog from '../dialogs/mass_operations/CoreDialog';
import DataList from '../general/DataList';
import LoaderButton from '../general/LoaderButton';
import NotificationBar from '../general/NotificationBar';
import Slider from '../general/Slider';
import { getMultiDimensionHeaders } from './helpers';

// Other
import { cloneDeep, isEqual } from 'lodash';

// Styles
import styles from './AccountingSlider.module.scss';

export interface Column {
   field: string;
   name: string;
   type: string;
   header: string;
   usedIn: string[];
   optionsName: string;
}

export interface dimensionHeader {
    id: string; // id that includes order number
    orig_id?: string; // original id without order number
    name?: string;
    label?: string;
    options?: any[];
    visible?: number;
    order_num?: string;
    integration?: string;
    dimension_header?: string;
 }

 export interface dimensionValue {
    header_id: string; // id that includes order number
    orig_header_id?: string; // original id without order number
    dimension_id?: string;
    type?: number; // 1 = Invoices, 2 = Bills, 3 = Contract bill, 4 = Products
 }

export interface invoiceRow {
    id?: string; 
    efina_product?: string;
    efina_cost_pool?: string;
    accounting_product?: string;
    product_register_product?: string;
    dimension_item?: string;
    sales_account?: string;
    purchase_account?: string;
    dimension_values?: dimensionValue[];
    deleted?: number;
 }

 export interface dataRow {
    id?: string; 
    description?: string;
    total?: number;
    roworder?: string;
 }

 export interface accountingData {
    purchaseAccounts?: any[];
    dimensions?: any[];
    purchaseVatCodes?: any[];
    dimension_headers?: dimensionHeader[];
 }

export interface AccountingSliderProps extends DrawerProps {
    onClose: (event?: any, reason?: string) => void;
    onSave?: (data?: any) => void;
    open: boolean;
    accountingData: accountingData;
    invoiceRows: invoiceRow[];
    dataRows: dataRow[];
    type?: string;
    company?: string;
    editingDisabled?: boolean;
    currency?: string;
    isNewInvoice?: boolean;
    editRow: (name, value, rowId) => [];
    onRowsCopied: (rows: invoiceRow[], onRowsCopied: () => void) => void;
}

interface State {
    confirmDialogData?: any;
    listColumns: Column[];
    numberFormat: Intl.NumberFormat;
    initialUpdateDone: boolean;
    copyingRowId: number;
    dimensionHeadersDiffer: boolean;
    dimensionHeaders: any;
}

class AccountingSlider extends TaimerComponent<AccountingSliderProps, State> {
    originalRows: invoiceRow[];
    columns: Column[];
    constructor(props, context) {
        super(props, context, 'invoices/AccountingSlider');
        this.originalRows = cloneDeep(this.props.invoiceRows);
        this.state = {
            listColumns: [],
            dimensionHeaders: [],
            initialUpdateDone: false,
            copyingRowId: 0,
            dimensionHeadersDiffer: false,
            numberFormat: new Intl.NumberFormat(this.context.taimerAccount.numberFormat, { style: 'currency', currency: props.currency ? props.currency : this.context.taimerAccount.currency })
        };

        this.columns = [
            // eFina stuff is not working correctly now for automatic invoices. Would need database updates. Maybe better to refactor eFina to use the same accounting fields as other integrations.
            ...(props.module == "InvoiceView" ? [{ field: "efina_product", name: "efina_product", type: "sales_invoices", header: this.tr("eFina Product"), optionsName: "efinaProducts", usedIn: ['efina'] }] : []),
            ...(props.module == "InvoiceView" ? [{ field: "efina_cost_pool", name: "efina_cost_pool", type: "sales_invoices", header: this.tr("eFina Dimension"), optionsName: "efinaCostPools", usedIn: ['efina'] }] : []),
            { field: "accounting_product", name: "accounting_product", type: "sales_invoices", header: this.tr("Product"), optionsName: "accountingProducts", usedIn: ['netvisor', 'procountor', 'talenom', 'fennoa', 'fivaldi', 'venda', 'meritaktiva', "xero",] },
            { field: "product_register_product", name: "product_register_product", type: "sales_invoices", header: this.tr("Product"), optionsName: "productRegisterProducts", usedIn: ['heeros',] },
            { field: "dimension_item", name: "dimension_item", type: "sales_invoices", header: this.tr("Dimension"), optionsName: "dimensions", usedIn: ['netvisor', 'talenom', 'fennoa', 'wintime', 'emce_invoicing', 'meritaktiva', 'heeros', 'tietotili', 'netsuite'] },
            { field: "sales_account", name: "sales_account", type: "sales_invoices", header: this.tr("Account"), optionsName: "salesAccounts", usedIn: ['netvisor', 'procountor', 'emce', 'talenom', 'fennoa', 'wintime', "xero", 'meritaktiva', 'datev', 'navcsv', 'heeros', 'tietotili', 'netsuite'] },
            { field: "purchase_account", name: "purchase_account", type: "purchase_invoices", header: this.tr("Purchase account"), optionsName: "purchaseAccounts", usedIn: ['netvisor', 'procountor', 'heeros'] },
            { field: "dimension_item", name: "dimension_item", type: "purchase_invoices", header: this.tr("Dimension"), optionsName: "dimensions", usedIn: ['netvisor', 'heeros'] }
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.getListColumns();
        this.getDimensionHeaders();
    }

    getListColumns = () => {
        const { addons } = this.context;
        const { company } = this.props;
        const companyAddons : string[] = [];
        const listColumns : Column[] = [];

        Object.keys(addons).forEach(a => {
            if (addons[a] && addons[a].used_by_companies?.indexOf(company) > -1) {
                companyAddons.push(a);
            }
        });

        for (const column of this.columns) {
            if (column.type != this.props.type) {
                continue;
            }
            const addon = companyAddons.find(a => {
                return column.usedIn.indexOf(a) > -1;
            });
            addon && listColumns.push(column);
        }

        this.setState({ listColumns }, () => {
            setTimeout(() => {
                this.setState({ initialUpdateDone: true });
            }, 10);
        });
    }

    onSave = () => {
        this.props.onSave && this.props.onSave({originalRows: this.originalRows, dimensionHeadersDiffer: this.state.dimensionHeadersDiffer, dimensionHeaders: this.state.dimensionHeaders});
    };

    onClose = () => {
        if (this.dataChanged()) {
            this.showConfirmDialog({
                header: this.tr('Close slider'),
                translatedConfirmButtonText: this.tr('Close slider'),
                warning: () => this.tr('Are you sure you want to close the slider? All unsaved changes will be lost.'),
                onConfirm: () => {
                    this.setState({ confirmDialogData: undefined }, () => {
                        this.closeSlider();
                    });
                },
            });
        } else {
            this.closeSlider();        
        }
    };

    closeSlider = () => {
        this.props.onClose && this.props.onClose({originalRows: this.originalRows, dataChanged: this.dataChanged()});
    }

    dataChanged = () => {
        return !isEqual(this.originalRows, this.props.invoiceRows);
    }

    showConfirmDialog = (confirmDialogData) => this.setState({ confirmDialogData });
    closeConfirmDialog = () => this.setState({ confirmDialogData: undefined });

    handleKeyPress = (event) => {
        if (event.key == 'Enter') {
            event.preventDefault();
            const activeElement: any = document.activeElement;
            if (activeElement) {
                activeElement.blur && activeElement.blur();
            }
            setTimeout(() => {
                this.onSave();
            }, 200);
        }
        if (event.key == 'Escape') {
            event.preventDefault();
            this.onClose();
        }
    };

    setRowData = (rowId, name, value) => {
        const { editRow } = this.props;
        if (Number(value.id) < 0) {
            value.id = 0;
        }
        if (name == "sales_account" && value?.integration == "talenom") {
            name = "talenom_account";
        }
        const val = ["efina_product", "efina_cost_pool", "talenom_account"].find(e => e == name) ? value : value.id;
        editRow(name, val, rowId);
    }

    setRowDimensionValues = (rowId, dimensionHeaderId, value) => {
        const { editRow } = this.props;
        const invoiceRows = cloneDeep(this.props.invoiceRows);
        const rowIndex = invoiceRows.findIndex(i => i.id == rowId);

        if (rowIndex < 0) {
            return;
        }
        if (Number(value) < 0) {
            value = 0;
        }

        const rowDimensionValues = invoiceRows[rowIndex]["dimension_values"] || [];
        const valueIndex = rowDimensionValues.findIndex(i => i.header_id == dimensionHeaderId);
        if (valueIndex > -1) {
            rowDimensionValues[valueIndex] = { header_id: dimensionHeaderId, dimension_id: value };
        }
        else {
            rowDimensionValues.push({ header_id: dimensionHeaderId, dimension_id: value });
        }

        editRow("dimension_values", rowDimensionValues, rowId);
    }

    copyRow = (row) => {
        const { onRowsCopied, type } = this.props;
        const { listColumns } = this.state;
        const invoiceRows = cloneDeep(this.props.invoiceRows);

        this.setState({ copyingRowId: row.id })

        const newRows = invoiceRows.map(r => {
            listColumns.forEach(c => {
                if (!["efina_product", "efina_cost_pool"].find(e => e == c.name)) {
                    r[c.name] = row[c.name];
                }
            });
            if (type == "sales_invoices") {
                ["efina_product_id", "efina_product_label", "efina_costpool_id", "efina_costpool_label"].forEach(e => {
                    r[e] = row[e];
                })
            }
            r.dimension_values = row.dimension_values;
            return r;
        });

        setTimeout(() => {
            onRowsCopied(newRows, () => this.onRowsCopied());
        }, 100);
    }

    onRowsCopied = () => {
        this.setState({ copyingRowId: 0 });
    }

    /**
    * Gets shown multidimension headers for invoices or bills.
    * Gets info if rows order number dimension headers differ from settings order number headers.
    */
    getDimensionHeaders = () => {
        const dimensionHeaderData = getMultiDimensionHeaders(this.props);
        const { dimensionHeaders, dimensionHeadersDiffer } = dimensionHeaderData;

        this.setState({ dimensionHeaders, dimensionHeadersDiffer })
    }

    renderRow = (row, rowIndex) => {
        const { accountingData, dataRows, editingDisabled, type } = this.props;
        const { listColumns, copyingRowId, dimensionHeaders } = this.state;

        const rowData = dataRows.find(r => r.id == row.id) || {};

        if (rowData['row_type'] < 0) { // For example already invoiced total row
            return;
        }

        const isHeaderRow = type == "sales_invoices" && (rowData['row_category'] == 2 || rowData['row_category'] == 5);

        if (isHeaderRow) {
            const nextRow = dataRows.find(r => Number(r.roworder) == Number(rowData.roworder) + 1);
            const nextRowIsHeader = nextRow && type == "sales_invoices" && (nextRow['row_category'] == 2 || nextRow['row_category'] == 5 || nextRow['row_type'] < 0);

            if (!nextRow || nextRowIsHeader) {
                return;
            }

            return <h1 key={`row_${rowIndex}`} data-testid={`row_description_${rowIndex}`}>{rowData?.description || this.tr("New header")}</h1>
        }

        const rowDescription = rowData?.description || (type == "sales_invoices" ? this.tr("Item") : "");

        return (
            <ul key={`row_${rowIndex}`}>
                <li className={styles.rowInfo}>
                    <Tooltip title={rowDescription} placement="top" classes={{ tooltip: 'darkblue-tooltip' }} arrow={true}>
                        <span data-testid={`row_description_${rowIndex}`} className={styles.rowDescription}>{rowDescription}</span>
                    </Tooltip>
                    <br/>
                    <span data-testid={`row_sum_${rowIndex}`} className={styles.rowSum}>{this.state.numberFormat.format(rowData?.total || 0)}</span>
                </li>
                {(listColumns || []).map((column, i) => {
                    let fieldValue: any = undefined;
                    if (column.name == "efina_product" && Number(row["efina_product_id"]) > 0) {
                        fieldValue = {label: row["efina_product_label"]};
                    }
                    else if (column.name == "efina_cost_pool" && Number(row["efina_costpool_id"]) > 0) {
                        fieldValue = {label: row["efina_costpool_label"]};
                    }              
                    
                    if (!fieldValue) {
                        fieldValue = row[column.name] == 0 ? undefined : (accountingData[column.optionsName] || []).find(a => a.id == row[column.name]);
                    } 

                    return (
                        <li key={`${column.name}_${i}`}>
                            <DataList
                                label={column.header}
                                name={column.name + "_" + rowIndex}
                                value={fieldValue}
                                options={accountingData[column.optionsName] || []}
                                onChange={(e) => this.setRowData(row.id, column.name, e)}
                                shownCount={20}
                                className={styles.accountingField}
                                isDisabled={editingDisabled}
                                error={row[column.name + "_invalid"]}
                            />
                        </li>)
                })}
                {(dimensionHeaders || []).map((header, i) => {
                    const headerDimensionValue = row.dimension_values?.find(v => v.header_id == header.id) || {dimension_id: 0};
                    return (
                        <li key={`dimension_header_${i}`}>
                            <DataList
                                label={header.label}
                                name={header.name + "_" + rowIndex}
                                value={(header.options || []).find(o => o.id == headerDimensionValue?.dimension_id)}
                                options={header.options || []}
                                onChange={(e) => this.setRowDimensionValues(row.id, header.id, e.id)}
                                shownCount={20}
                                className={styles.accountingField}
                                isDisabled={editingDisabled}
                            />
                        </li>)
                })}
                {!editingDisabled && (
                    <li>
                        {copyingRowId == row.id ? 
                            <CircularProgress className={styles.copyIconContainer} color="primary" disableShrink size={16} /> : (
                            <Tooltip title={this.tr("Copy values to all rows")} placement="left" classes={{ tooltip: 'darkblue-tooltip' }} arrow={true}>
                                <div data-testid={`copy_row_${rowIndex}`} className={styles.copyIconContainer} onClick={() => this.copyRow(row)}>
                                    <ContentCopy />
                                </div>
                            </Tooltip>)}
                    </li>
                )}
                {editingDisabled && <div className={styles.emptyRightMargin}/>} 
            </ul>
        );
    }

    /**
     * Renders rows for slider.
     * Renders one empty row that is hidden when slider is opened, so it opens faster, and slider width is correct immediately.
     */
    renderRows = () => {
        const { invoiceRows } = this.props;
        const { initialUpdateDone } = this.state;

        return (
            <div className={styles.rows} style={{ visibility: !initialUpdateDone ? "hidden" : undefined }}>
                {!initialUpdateDone ? this.renderRow({}, 0) :
                    // Filter out deleted rows. Invoice has also deleted rows in props. They are needed in props so saving deleted invoice rows works correctly after saving slider.
                    (invoiceRows || []).filter(r => Number(r.deleted || 0) < 1).map((row, i) => {
                        return this.renderRow(row, i);
                    })
                }
            </div>
        )
    }

    renderButtons = () => {
        const { editingDisabled } = this.props;
        const { initialUpdateDone } = this.state;

        return (
            <div className={styles.actions}>
                <Button onClick={() => this.closeSlider()} variant="text" size="large" data-testid="slider-cancel-button">
                    {editingDisabled ? this.tr('Close') : this.tr("Cancel")}
                </Button>
                {!editingDisabled &&
                    <LoaderButton
                        text={this.tr('Save')}
                        onClick={this.onSave}
                        size="large"
                        color="primary"
                        data-testid="slider-save-button"
                        loading={!initialUpdateDone}
                    />
                }
            </div>
        )
    }

    renderLoading = () => {
        return(
            <div className={styles.loading}>
                <CircularProgress color="primary" disableShrink size={40} />
            </div>
        )
    }

    /** 
     * Removes all dimensions from rows that don't have header in settings.
     * Then getDimensionHeaders will get headers from settings in those places.
    */
    setSettingsDimensions = () => {
        const { onRowsCopied, accountingData } = this.props;
        const invoiceRows = cloneDeep(this.props.invoiceRows);

        this.setState({ dimensionHeadersDiffer: false })

        const newRows = invoiceRows.map(r => {
            const newDimensions : dimensionValue[] = [];
            r.dimension_values?.forEach(d => {
                if ((accountingData.dimension_headers || []).find(a => a.id == d.header_id && a.visible == 1)) {
                    newDimensions.push(d);
                }
            })
            r.dimension_values = newDimensions;
            return r;
        });

        setTimeout(() => {
            onRowsCopied(newRows, () => this.getDimensionHeaders());
        }, 100);
    }

    renderDimensionWarning = () => {
        if (!this.state.dimensionHeadersDiffer || this.props.editingDisabled) {
            return undefined;
        }

        return (
            <NotificationBar 
                title={this.tr("Dimensions differ from settings")} 
                buttonTitle={this.tr("Update dimensions")}
                style="sliderHeader"
                onButtonClick={this.setSettingsDimensions}
            />
        )
    }

    renderConfirmationDialog = () => {
        const { confirmDialogData } = this.state;

        return (
            <CoreDialog
                dialogType="delete"
                dialogProps={{
                    onCloseClick: this.closeConfirmDialog,
                    close: this.closeConfirmDialog,
                    onCancel: this.closeConfirmDialog,
                    cancelButtonText: this.tr('Cancel'),
                    onConfirm: confirmDialogData.onConfirm,
                    header: confirmDialogData.header,
                    translatedConfirmButtonText: confirmDialogData.translatedConfirmButtonText,
                    warning: confirmDialogData.warning,
                }}
            />
        )
    }

    render() {
        const { confirmDialogData, initialUpdateDone } = this.state;

        return (
            <Slider anchor="right" {...this.props} title={this.tr("Accounting")} ref={undefined} onClose={this.onClose} rightComponent={this.renderDimensionWarning()}>
                <div className={styles.accountingSlider} onKeyPress={this.handleKeyPress}>
                    {!initialUpdateDone && this.renderLoading()}
                    {this.renderRows()}
                    {this.renderButtons()} 
                </div>
                {confirmDialogData && this.renderConfirmationDialog()}
            </Slider>
        );
    }

}

export default AccountingSlider;
