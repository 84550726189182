import React, { Component } from 'react';
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import OutlinedField from "./../../general/OutlinedField";
import Button from '@mui/material/Button';
import DataHandler from '../../general/DataHandler';
import { withSnackbar } from 'notistack';
import { Auth } from '@aws-amplify/auth'

const styles = theme => ({
    main: {
        paddingLeft: 0,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

class ChangePassword extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/ChangePassword");

        this.state = {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            confirmError: false,
            invalidPassword: false,
        }
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState({ [name]: value });
    };

    save = async () => {
        try {
            const awsUser = await Auth.currentAuthenticatedUser();
            this.saveCognitoPassword(awsUser);

        }
        catch (e) {
            this.saveLocalPassword();
        }
        
    }
    saveCognitoPassword = async (user) => {
        const { currentPassword, newPassword, confirmPassword } = this.state;

        var checks = {
            currentError: false,
            confirmError: newPassword !== confirmPassword,
            invalidPassword: false,
        }
        this.setState(checks);

        if (checks.confirmError)
            return this.errorMessage(checks);
        
        try {
            const response = await Auth.changePassword(user, currentPassword, newPassword);
            console.log(response);
            this.changedSuccesfully();
        }
        catch (e) {
            if (e.code === "InvalidPasswordException")
                checks.invalidPassword = true;

            if (e.code === "NotAuthorizedException")
                checks.currentError = true;

            if (e.code === "LimitExceededException")
                checks.LimitExceededException = true;

            this.setState(checks);
            this.errorMessage(checks);
            console.log("error changing password", e);
        }
    }

    saveLocalPassword = async () => {
        const { currentPassword, newPassword, confirmPassword } = this.state;
        

        var checks = {
            currentError: currentPassword === '',
            confirmError: newPassword !== confirmPassword,
            invalidPassword: newPassword.length < 4,
        }

        this.setState(checks);

        if (!checks.confirmError && !checks.invalidPassword && !checks.currentError) {
            try {
                const reply = await DataHandler.post({ url: 'settings/user/changepassword' }, {
                    oldpassword: currentPassword,
                    newpassword: newPassword,
                });

                if (reply.status === "oldpassword") {
                    checks.currentError = true;
                }
                else if (reply.status === "OK") {
                    this.changedSuccesfully();
                }
            } catch (error) {
                if (error.responseJSON.oldpassword)
                    checks.currentError = true;
                if (error.responseJSON.newpassword)
                    checks.invalidPassword = true;
            }
        }

        this.setState(checks);
    }
    errorMessage = (checks) => {
        const { enqueueSnackbar } = this.props;

        if (checks.currentError)
        {
            enqueueSnackbar(this.tr("Current Password is wrong"), {
                variant: "error",
            });
        }
        else if (checks.invalidPassword)
        {
            enqueueSnackbar(this.tr("Password must be at least 4 characters"), {
                variant: "error",
            });
        }
        else if (checks.confirmError)
        {
            enqueueSnackbar(this.tr("New Passwords did not match"), {
                variant: "error",
            });
        }
        else if (checks.LimitExceededException) {
            enqueueSnackbar(this.tr("Attempt limit exceeded, please try after some time."), {
                variant: "error",
            });
        }

    } 

    changedSuccesfully = () => {
        const { enqueueSnackbar } = this.props;

        this.setState({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        });

        enqueueSnackbar(this.tr("Password changed successfully!"), {
            variant: "success",
        });
    }

    render() {
        const { currentPassword, newPassword, confirmPassword, invalidPassword, confirmError, currentError } = this.state;
        const { tr } = this;
        
        return <React.Fragment>
            <h3>{tr("Change Password")}</h3>

            <div className="SettingsGrid">
                <div className="full">
                    <OutlinedField name="currentPassword" label={tr("Current Password")}
                        value={currentPassword} error={currentError} onChange={this.handleChange} type="password" fullWidth autoComplete="new-password" />
                </div>

                <div className="full">
                    <OutlinedField name="newPassword" label={tr("New Password")}
                        value={newPassword} error={invalidPassword} onChange={this.handleChange} type="password" fullWidth autoComplete="new-password" />
                </div>

                <div className="full">
                    <OutlinedField name="confirmPassword" label={tr("Confirm Password")}
                        value={confirmPassword} error={confirmError} onChange={this.handleChange} type="password" fullWidth autoComplete="new-password" />
                </div>

                <div>
                    {/* Forgot your current password? click here */}
                </div>
                <div className="right">
                    <Button onClick={this.save} color="primary">{tr("Save")}</Button>
                </div>
            </div>
        </React.Fragment>
    }
}

export default withSnackbar(withStyles(styles)(ChangePassword));