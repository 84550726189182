import cloneDeep from "lodash/cloneDeep";

// TODO: Extra parameters for each function: 
// identifierKey (id, identifier, etc.)

// The parent must have refs to its children.
function gatherFromRoot(root, idKey = "id") {
    const ids = [];

    _gatherFromRoot(root, idKey, ids);

    return ids;
}

function _gatherFromRoot(root, idKey = "id", arrayRef) {
    arrayRef.push(root[idKey]);

    for(const ref of root.childRefs) {
        if(ref.childRefs.length > 0) {
            _gatherFromRoot(ref, idKey, arrayRef);
        } else {
            arrayRef.push(ref[idKey]);
        }
    }
}

function findRoot(child, idKey = "id") {
	return child.parentRef 
		? 
		findRoot(child.parentRef, idKey)
		: 
		child[idKey];
}


function getChildDepth(from, depth = 0) {
	return !from.parentRef
		? depth
		: getChildDepth(from.parentRef, ++depth);		
}


function createHierarchyDataMap(data, parentKey = "parentId", idKey = "id") {
	data = Array.isArray(data)
		? data
		: [data];

	// Sever the reference in case the caller didn't.
	data = cloneDeep(data);
	const map = {};

	data.forEach(d => {
		map[d[idKey]] = d;
		map[d[idKey]].childRefs = [];
		map[d[idKey]].parentRef = null;
	});
	
	Object.keys(map).forEach(id => {
		const d = map[id];

		if(d[parentKey] == 0) {
			return;
		}

		d.parentRef = map[d[parentKey]];

		if(map[d[parentKey]] === undefined) {
			return;
		}

		map[d[parentKey]].childRefs.push(d);
	});

	return map;
}

export {
	gatherFromRoot,
	findRoot,
	createHierarchyDataMap,
	getChildDepth
};
