/* react */
import React from 'react';
import TaimerComponent from "../../TaimerComponent";

import { ChevronLeft, Delete } from '@mui/icons-material';
import { Button, Switch } from '@mui/material';

/* others */
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import List from "../../list/List";
import ListCell from "../../list/ListCell";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import TextInputCell from "../../list/cells/TextInputCell";
import SettingRow from "../../list/rows/SettingRow";

import _ from "lodash";

import './Efina.css';

class ProjectRow extends SettingRow {

    setFieldData = (name, value) => {
        this.editAndSave(name, value);
        this.setState({data: {...this.state.data, [name]: value}})        
    }

    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <Delete />
            </ListCell>,
            code: <TextInputCell
                width={this.props.columnWidthMap['code']}
                name="code"
                value={this.state.data.code}
                onEdited={this.setFieldData}
            />,
            // company: <AutoCompleteCell
            //     listCellProps={{
            //         className: "project-cell"
            //     }}
            //     editable={true}
            //     autoCompleteData={this.props.rowProps.companies}
            //     name="company"
            //     width={this.props.columnWidthMap['company']}
            //     value={this.state.data.companies_id}
            //     onEdited={this.editAndSave}
            // />,            
            paid: 
                <ListCell className="" width={this.props.columnWidthMap["paid"]} onlyDisplay>    
                    <Switch
                        color="primary"
                        checked={this.state.data.paid == 1}
                        onChange={event => {
                            this.setFieldData("paid", event.target.checked ? 1 : 0);
                        }} />
                </ListCell>,
            project: <AutoCompleteCell
                listCellProps={{
                    className: "project-cell"
                }}
                editable={true}
                autoCompleteData={this.props.sharedData.projects}
                hideOptions={(options) => options?.filter(p => !this.props.rowProps.usedProjects.includes(p.id))}
                name="projects"
                width={this.props.columnWidthMap['project']}
                value={this.props.sharedData.projects.find(f => f.id == this.state.data.projects_id)}
                onEdited={(obj) => {
                    this.setFieldData('projects_id', obj.id);
                }}
            />,            
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}


class ESalary extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/ESalary");

        this.state = {
            // company: this.props.company,
            companies: [],
            projects: [],
            workhourProjects: []
        };

        this.integration = "esalary";

        this.workhourProjectList = React.createRef();
        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };        
    }
    
    componentDidMount () {
        super.componentDidMount();
        // this.getCompanies();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.getProjects();
    }

    updateComponentData = () => {
        this.getProjects();
        this.getWorkhourProjects();
    }

    // getCompanies = async () => {
    //     const companies = await DataHandler.get({ url: 'subjects/companies' });
    //     this.setState({companies});
    // }    

    getProjects = async () => {
        const projects = await DataHandler.get({ url: 'projects/dropdown_new', onlyInternal: true, company: this.props.company});
        this.setState({projects});
    }

    getWorkhourProjects = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/workhour_projects/1`}).done((workhourProjects) => {
            this.setState({workhourProjects});
        });
    }

    onRowChange = (data) => {
        if (data.id > 0)
            DataHandler.put({url: `settings/company/${this.props.company}/workhour_projects`}, data).done((resp) => {
                this.getWorkhourProjects();
            });
        else
            DataHandler.post({url: `settings/company/${this.props.company}/workhour_projects/1`}, data).done((resp) => {
                this.getWorkhourProjects();
            });
    };

    back = () => {
        const {functions: { updateView } } = this.context;
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }    

    deactivate = () => {
        DataHandler.delete({url: `settings/company/${this.props.company}/esalary`}).done(this.back);
    }    
    
    render(){     
        const { tr } = this;

        const usedProjects = this.state.workhourProjects.map(w => w.projects_id);
        
        return (
            <div id="integration-esalary" className="main-integration-wrapper">
                <div className="header" style={{display: 'flex'}} >
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("eSalary Settings")}</h3>
                    {this.props.active && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">                  
                    <h4>{tr("Vacation and leave projects")}</h4>
                    <Button className="green" onMouseUp={() => this.workhourProjectList.current.addNewRow()} size="large">{tr("Add salary code")}</Button>
                    <br />
                    <List
                        ref={this.workhourProjectList}
                        id="workhourProjectList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            // companies_id: this.props.company,
                            projects_id: 0,
                            code: '',
                            paid: 0
                        }}
                        listRowType={ProjectRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            // { width: 200, name: "company", header: tr("Company name"), ...this.dColConf },
                            { width: 500, name: "project", header: tr("Project"), ...this.dColConf },
                            { width: 100, name: "code", header: tr("Salary code"), ...this.dColConf },
                            { width: 50, name: "paid", header: tr("Paid leave"), ...this.dColConf },
                        ]}
                        sharedData={{projects: this.state.projects}}
                        rowProps={{
                            usedProjects: usedProjects,
                            onCreate: this.onRowChange,
                            onUpdate: this.onRowChange,
                            onDelete: row => {
                                if(row.id > 0) {
                                    DataHandler.delete({url: `settings/company/${this.props.company}/workhour_projects`, id: row.id}).done(response => {
                                        this.getWorkhourProjects();
                                    });
                                } else {
                                    let workhourProjects = _.cloneDeep(this.state.workhourProjects);
                                    workhourProjects = workhourProjects.filter(wp => wp.id != row.id);
                                    this.setState({workhourProjects});
                                }

                            }
                        }}
                        data={this.state.workhourProjects}
                    />
                </div>
            </div>
        )
    }
}

export default ESalary;