import React from 'react';
import TaimerComponent from "../TaimerComponent";
import actionIcons from './ActionIcons';
import './ActionMenu.css';
import TaimerAvatar from '../general/TaimerAvatar';
import UpgradeButton from '../general/UpgradeButton';
import { ExitToApp, NewReleases } from '@mui/icons-material';
import { ReactComponent as AccountIcon } from './ActionIcons/account.svg';
import { ReactComponent as PlanIcon } from './ActionIcons/plan.svg';
import { ReactComponent as InviteUser } from './ActionIcons/inviteuser.svg';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Link from '../general/Link';
import { ClickAwayListener, Tooltip } from '@mui/material';
import styles from './ActionMenu.module.scss';
import VersionContentManager from '../general/VersionContentManager';

class ActionMenuButton extends React.PureComponent {

    state = {
        menuOpen: false
    }

    onClick = () => this.setState({menuOpen: !this.state.menuOpen});

    render () {
        const { menuContent, isOpen, testid } = this.props;
        const { menuOpen } = this.state;

        const classes = [this.props.className];
        classes.push((menuOpen || isOpen) ? "open" : 'closed');

        let Icon = this.props.icon || actionIcons[this.props.src + '_open'];
        if ((!menuOpen && !isOpen) || !Icon) Icon = actionIcons[this.props.src] || this.props.src;

        const content = (
            this.props.href ? (
                <Link data-testid={this.props['data-testid']} href={this.props.href} className={'menu-button ' + classes.join(" ")} onClick={this.props.onClick || this.onClick} id={this.props.id} >
                    {!this.props.noIcon && (Icon && <Icon /> || this.props.img)}{this.props.desc}
                </Link>
            ) : (
                <div data-testid={this.props['data-testid']} className={'menu-button ' + classes.join(" ")} onClick={this.props.onClick || this.onClick} id={this.props.id} >
                    {!this.props.noIcon && (Icon && <Icon /> || this.props.img)}{this.props.desc}
                </div>
            )
        );

        return (
            <div className={styles.actionMenuButton} data-testid={testid}>
                {this.props.tooltip ? <Tooltip title={this.props.tooltip}>{content}</Tooltip> : content}
                {menuContent && menuOpen && <ClickAwayListener onClickAway={this.onClick}>
                    <div className={styles.menuContent} onClick={this.onClick}>
                        {menuContent}
                    </div>
                </ClickAwayListener>}
            </div>

        )
    }
}

class ActionMenuItem extends React.Component {
    render () {
        const Icon = actionIcons[this.props.src] || this.props.icon;
        return (
            <div data-testid={this.props.src} className={'menu-item ' + this.props.className} onClick={this.props.onClick} onMouseUp={this.props.onMouseUp}>
                {Icon !== undefined && <Icon />} {this.props.desc}
            </div>
        );
    }
}

class ActionsMenu extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "navigation/ActionMenu");
    }

    render () {
        const { privileges, userObject, functions: { hasPrivilege } } = this.context;
        const { tr } = this;

        return (
            <div className={styles.menu}>
                { userObject.hasAccountWritePermission ? <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("account", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey)} src="account" desc={tr("Account")} /> : undefined }
                { hasPrivilege("persons", "read") ? <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("contact", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey)} src="contact" desc={tr("Contact")} /> : undefined }
                { userObject.hasCrmWritePermission ? <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("activity", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey, evt.button)} src="activity" desc={tr("Activity")} /> : undefined }
                { hasPrivilege("projects", "write") ? <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("project", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey)} src="project" desc={tr("Project")} /> : undefined }
                { hasPrivilege("workhours", "write") ? <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("workhour", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey)} src="workhours" desc={tr("Hour")} /> : undefined }
                {this.context.addons && this.context.addons.resourcing && <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("task", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey)} src="task" desc={tr("Task")} /> }
                {this.context.addons && this.context.addons.resourcing && <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("milestone", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey)} src="milestone" desc={tr("Milestone")} />}
                {  privileges.products ? <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("product", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey)} src="product" desc={tr("Product")} /> : undefined }
                {!VersionContentManager.isFeatureHidden(this.namespace, 'travelExpenses') && privileges.worktrips && privileges.worktrips.write && !this.context.userObject.isFreelancer
                    ? <ActionMenuItem 
                        onMouseUp={(evt) => this.props.menuClicked("travel", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey)} 
                        src="travel" 
                        desc={tr("Travel Expense")} /> 
                    : undefined}
                { privileges.worktrips && privileges.worktrips.write && !this.context.userObject.isFreelancer
                    ? <ActionMenuItem 
                        onMouseUp={(evt) => this.props.menuClicked("worktrips", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey)} 
                        src="expenses" 
                        desc={tr("Expense")} />  
                    : undefined}
                { privileges.invoices && privileges.invoices.write_full ? <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("invoice", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey)} src="invoice" desc={tr("Invoice")} /> : undefined }
                {this.context.addons && this.context.addons.bills && hasPrivilege("receivedinvoices", ["pre_approve", "approve"]) ? <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("bills", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey)} src="bill" desc={tr("Bill")} /> : undefined }
                { !(userObject.hasCrmWritePermission || (privileges.worktrips && privileges.worktrips.write) || userObject.hasAccountWritePermission || (privileges.invoices && privileges.invoices.write_full)) ? <ActionMenuItem desc={tr("No rights to add new items")} src="account" /> : undefined }
                {this.context.addons && this.context.addons.bills && hasPrivilege("purchaseorders", ["write"]) ? <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("purchaseorders", {}, evt.button === 1 || evt.ctrlKey || evt.metaKey)} src="purchaseorders" desc={tr("Purchase order")} /> : undefined }
            </div>
        );
    }
}

class ReportMenu extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "navigation/ActionMenu");
    }
    
    render () {
        const { tr } = this;
        return (
            <div className="menu-overlay reports" onMouseUp={(evt) => this.props.closeMenu(evt)}>
                {<div className="arrow" style={{"left": this.props.menuOffset + "px"}}>&nbsp;</div>}
                <div className="menu">
                    <div className="sub-menu">
                        <div className="menu-header">Hour Reports</div>
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("hours-by-customer", evt.button)} desc={tr("By Customer")}/>
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("hours-daily", evt.button)} desc={tr("Customer Daily")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("hours-users-summary", evt.button)} desc={tr("Users Summary")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("hours-by-users", evt.button)} desc={tr("Hours listed by User")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("hours-users-daily", evt.button)} desc={tr("Users daily")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("hours-invoicing-rate", evt.button)} desc={tr("Invoicing Rate")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("hours-hour-balance", evt.button)} desc={tr("Hour Balance")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("hours-by-category", evt.button)} desc={tr("By Category")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("hours-by-jobtype", evt.button)} desc={tr("By Jobtype")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("hours-invoiced-hours", evt.button)} desc={tr("Invoiced Hours")} />
                    </div>
                    <div className="sub-menu">
                        <div className="menu-header">Expenses Reports</div>
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("expenses-by-customer", evt.button)} desc={tr("By Customer")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("expenses-by-project", evt.button)} desc={tr("By Project")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("expenses-by-employees", evt.button)} desc={tr("By Employees")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("expenses-listed", evt.button)} desc={tr("Expenses Listed")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("expenses-claims", evt.button)} desc={tr("Claim Listed")} />
                    </div>
                    <div className="sub-menu">
                        <div className="menu-header">Sales Reports</div>
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("sales-report", evt.button)} desc={tr("Sales Report")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("sales-project-margin", evt.button)} desc={tr("Project Margin Report")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("sales-by-customer", evt.button)} desc={tr("By Customer")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("sales-by-employees", evt.button)} desc={tr("By Employees")} />
                    </div>
                    <div className="sub-menu">
                        <div className="menu-header">Invoice Reports</div>
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("invoice-summary", evt.button)} desc={tr("Summary of Invoices")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("invoice-by-accounts", evt.button)} desc={tr("By Accounts")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("invoice-monitoring-invoices", evt.button)} desc={tr("Monitoring Invoices")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("invoice-scheduled-payments", evt.button)} desc={tr("Scheduled Payments")} />
                    </div>
                    <div className="sub-menu">
                        <div className="menu-header">Billing Reports</div>
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("billing-report", evt.button)} desc={tr("By Customer")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("billing-project-margin", evt.button)} desc={tr("By Group")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("billing-by-customer", evt.button)} desc={tr("By Category")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("billing-by-employees", evt.button)} desc={tr("By Supplier")} />
                    </div>
                    <div className="sub-menu">
                        <div className="menu-header">Budgeting Reports</div>
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("budgeting-profit", evt.button)} desc={tr("Gross Profit Margin Vs. Target")} />
                        <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("budgeting-sales", evt.button)} desc={tr("Gross Sales Value Vs. Target")} />
                    </div>
                </div>
            </div>
        );
    }
}

class UserMenu extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "navigation/ActionMenu");

        this.taimerVersions = {
            1: "Free CRM",
            2: "Sales CRM",
            3: "Project management",
            4: "ERP",
            7: "Free CRM (IG)",
            8: "Sales CRM (IG)",
            5: "Lean CRM (IG)",
            6: "Sales CRM + projects (IG)",
            10: "Free",
            11: "Growth",
            12: "Business"
        }
    }
    

    addUser = () => {
        this.context.functions.addUser();
    }

    knowledgeBaseClicked = () => {
        if (this.context.userObject.language == "fi")
                window.open("https://taimersupport.zendesk.com/hc/fi", "_blank");
            else
                window.open("https://taimersupport.zendesk.com/hc/en-us", "_blank");
    }

    latestUpdatesClicked = () => {
        this.context.userObject.language == "fi" ? window.open("https://taimersupport.zendesk.com/hc/fi/categories/6457343331346-Versiotiedotteet", "_blank") : window.open("https://taimersupport.zendesk.com/hc/en-us/categories/6457343331346-Release-Notes", "_blank");
    }

    render () {
        const { tr } = this;
        const { functions: { hasPrivilege }, taimerAccount: { origin } } = this.context;
        const rights = hasPrivilege("admin", "admin") ? 'admin' : 'user';
        let trialDays = 0;
        const companyName = Number(this.context.userObject.companies_id) > 0 ? this.context.taimerAccount.name : "Freelancer";

        if (this.context.taimerAccount.trialStatus > 0)
            trialDays = moment(this.context.taimerAccount.trialEnds).endOf('day').diff(moment(new Date()).startOf('day'), 'days');
       
       return (
            <div className="menu-overlay user" onMouseUp={(evt) => this.props.closeMenu(evt)}>
                <div className="arrow">&nbsp;</div>
                <div className="menu">
                    <div className="menu-header">
                        <TaimerAvatar
                            id={this.props.userObject.usersId}
                            name={this.props.userObject.fullname}
                            color={this.props.userObject.color}
                            size="large"
                        />
                        <div className="full-name">{this.props.userObject.fullname}</div>
                    </div>
                    <br/>
                    <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("settings-index", evt.ctrlKey || evt.metaKey || evt.button === 1, {group: 'my-profile', page: 'default'})} src="my_profile" desc={tr("My Profile")} />
                    <ActionMenuItem onMouseUp={this.knowledgeBaseClicked} src="faq" desc={tr("Knowledge Base")} />
                    <ActionMenuItem onMouseUp={this.latestUpdatesClicked} icon={NewReleases} desc={tr("Latest updates")} />
                    <hr />
                    {rights === 'admin' && <h3>{this.tr('Account settings')}</h3>}
                    {rights === 'admin' && <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("settings-index", evt.ctrlKey || evt.metaKey || evt.button === 1)} src="settings" desc={tr("Settings")} />}
                    {rights === 'admin' && <ActionMenuItem onMouseUp={this.addUser} icon={InviteUser} desc={tr("Invite user")} />}
                    {rights === 'admin' && <hr />}
                   <h3>{this.tr('Account')}</h3>
                   <div className="details-section">
                       <Grid container wrap="nowrap" spacing={2}>
                           <Grid item className="details-section-header">
                               <AccountIcon title="" className="details-section-icon" />
                               <span>{tr("Company Name")}:</span>
                           </Grid>
                           <Grid item xs className="details-section-text">
                                <span >{companyName}</span>
                           </Grid>
                       </Grid>
                   </div>
                   <div className="details-section">
                       <Grid container wrap="nowrap" spacing={2}>
                           <Grid item>
                               <PlanIcon title="" className="details-section-icon" />
                           </Grid>
                           <Grid item xs className="details-section-no-left-padding">
                               <span className="details-section-header">{tr("Plan")}:</span>
                               <span className="details-section-text">{this.taimerVersions[this.context.versionId]}</span>
                               {origin !== "ingram" && rights === 'admin' && <UpgradeButton updateView={this.context.functions.updateView} />}
                               <br />
                               {origin !== "ingram" && rights === 'admin' && this.context.taimerAccount.trialStatus > 0 && companyName !== "Freelancer" &&
                                   <span className="details-section-trial">{tr("Free trial ends in ${trialDays} days", { trialDays })}</span>
                               }
                           </Grid>
                       </Grid>
                   </div>
                    <hr />
                    <h3>{this.tr('Share the love')}</h3>
                    <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("settings-index", evt.ctrlKey || evt.metaKey || evt.button === 1, {group: 'my-profile', page: 'referral'})} src="share" desc={tr("Tell a Friend")} />
                    <hr />
                    {/* <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("faq", evt.button)} src="faq" desc={tr("FAQ")} />
                    <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("help", evt.button)} src="help" desc={tr("Helps")} />
                    <br/> */}
                    <div className="logout-button" onMouseUp={() => this.props.menuClicked("logout")}>
                        <ExitToApp />
                        {this.tr('Logout')}
                        </div>
                </div>
            </div>
        );
    }
}

class HelpMenu extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "navigation/ActionMenu");
    }

    openIntercom = () => {
        window.Intercom && window.Intercom("show");
    }

    render () {
        const { tr } = this;
        return (
            <div className="menu-overlay help-menu" onMouseUp={(evt) => this.props.closeMenu(evt)}>
                <div className="arrow" style={{"left": this.props.menuOffset + "px"}}>&nbsp;</div>
                <div className="menu">
                    <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("faq", evt)} src="faq" desc={tr("Knowledge Base")} />
                    <ActionMenuItem onMouseUp={(evt) => this.props.menuClicked("latestUpdate", evt)} src="latestUpdate" desc={tr("Latest Updates")} />
                    <ActionMenuItem onMouseUp={() => this.openIntercom()} src="helpChat" desc={tr("Chat with us")} />

                </div>
            </div>
        );
    }
}

export {
    ActionMenuButton,
    ActionMenuItem,
    ActionsMenu,
    ReportMenu,
    UserMenu,
    HelpMenu,
}
