import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import ContextMenu from '../../general/ContextMenu';
import EditableStatusCell from "../cells/EditableStatusCell";
import CurrencyListCell from "../CurrencyListCell";
import LinkListCell from "../LinkListCell";
import PropsOnlyListRow from "../PropsOnlyListRow";

/* others */
import { SettingsContext } from '../../SettingsContext';
import AttachmentCell from '../cells/AttachmentCell';
import AutoCompleteCell from '../cells/AutoCompleteCell';
import CheckboxCell from "../cells/CheckboxCell";
import DateCell from '../cells/DateCell';
import ListCell from '../ListCell';

import { ReactComponent as ViewIcon } from '../../general/icons/view.svg';

class BillsListRow extends PropsOnlyListRow {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, {}, {}, "list/rows/BillsListRow");
        this.context        = context;
        this.checkPrivilege = this.context.functions.checkPrivilege;
        this.defineCells    = this.defineCells.bind(this);
    }

    getSelectedUser = (id, users) => {
		let response = users && users.find(e => e.id == id);
		if (!response) {
			const user = this.props.rowProps.sharedData['users'] && this.props.rowProps.sharedData['users'].find(e => e.id == id);
			if (user) 
				response = user.name;
			else 
				response = "";
		}
		return response || "";
	}

    defineClassName() {
        return "billsListRow";
    }

    defineCells() {
        const commonProps = {
            editable: false
        };

        const { data } = this.props;
        const { columnWidthMap, rowProps: { currency, sharedData: { openDialog, users, privileged_users, canSeePo } } } = this.props;
        const selectedUser = this.getSelectedUser(data.targeting_user_id, privileged_users);
        let allowedStatuses = [];

        switch(Number(data.state)) {
            case 2:
                allowedStatuses = [0,1,2,3,5];
              break;
            case 4:
                allowedStatuses = [0,4,5];
                break;
            case 5:
                allowedStatuses = [0,4,5];
                break;
            default:
                allowedStatuses = [0,1,2,3,4,5];
        }

        allowedStatuses = allowedStatuses.map(s => this.props.sharedData.bills_statuses.find(e => e.id == s));

        return {
            expand:
                <ContextMenu data-testid={`bill-list-row-context-menu-${data.id}`} label={<ContextMenuIcon />} buttonProps={{ className: 'action-menu' }} className="cell row-menu" style={{ width: this.props.columnWidthMap['expand'] + 'px', flex: this.props.columnWidthMap['expand'] + " 1 0px" }} noExpandIcon>
                    <MenuItem data-testid={`bill-list-row-context-menu-view-${data.id}`} onClick={() => this.context.functions.updateView({ module: "receivedinvoice", action: "view", id: data.id, company: data.companies_id })}><ViewIcon />{this.tr("View")}</MenuItem>
                </ContextMenu>,
            checked:
                <CheckboxCell 
                    checked={this.props.checked} 
                    onClick={() => this.props.listRef.check(this.props.data.id)}
                    width={columnWidthMap['checked']} />,
            attachment: 
                <AttachmentCell
                    onClick={e => this.props.rowProps.openAttachment(this.props.data.id)}
                    hidden={this.props.data.has_attachments == -1}
                    width={columnWidthMap['attachment']}
                />,
            state:
                <EditableStatusCell
                    options={allowedStatuses}
                    value={this.props.data.state}
                    width={columnWidthMap['state']}
                    onEdited={state => {
                        const stateId = parseInt(state.id);
                        const pre = this.checkPrivilege("receivedinvoices", "pre_approve");
                        const approve = this.checkPrivilege("receivedinvoices", "approve");

                        if((!pre && !approve) || ([0, 3].indexOf(stateId) === -1 && !approve) || (stateId === 3 && (!pre && !approve))) {
                            this.props.rowProps.enqueueSnackbar(this.tr("You don't have the needed rights to perform this operation."), {
                                variant: "error"
                            });

                            return;
                        }

                        if (this.props.data.state === "2" && state.id === "4") {
                            this.props.rowProps.enqueueSnackbar(this.tr("Rejected bills cannot be archived."), {
                                variant: "error"
                            });

                            return;
                        }

                        const ids = [data.id];
                        this.props.rowProps.updateStatus(stateId, ids);
                    }}
                />,
            id:
                <LinkListCell
                    {...commonProps}
                    value={data.invoice_nr}
                    urlHandler={value => `index.html?module=receivedinvoice&action=view&id=${data.id}&company=${data.companies_id}`}
                    noTab={true} />,
            supplier:
                <LinkListCell
                    {...commonProps}
                    value={data.company_name}
                    urlHandler={value => `index.html?module=customers&action=view&id=${this.props.data.customers_id}`}
                    width={columnWidthMap['supplier']}
                    asText={data.can_see_account == 0 || data.customers_id == 0}
                    noTab={true}
                />,
            date:
                <DateCell
                    {...commonProps}
                    value={data.date}
                    width={columnWidthMap['date']}
                />,
            voucher_date:
                <DateCell
                    {...commonProps}
                    value={data.voucher_date}
                    width={columnWidthMap['voucher_date']}
                />,
            date_due:
                <DateCell 
                    {...commonProps}
                    value={data.date_due}
                    width={columnWidthMap['date_due']}
                />,
            sum:
                <CurrencyListCell
                    {...commonProps}
                    value={data.sum}
                    width={columnWidthMap['sum']}
                    currency={currency}
                />,
            sum_vat:
                <CurrencyListCell
                    {...commonProps}
                    value={data.sum_tax}
                    width={columnWidthMap['sum_vat']}
                    currency={currency}
                />,
            approved: 
                <AutoCompleteCell
                    {...commonProps}
                    value={data.users_id}
                    width={columnWidthMap['approved']}
                    autoCompleteData={users}
            />,
            assigned: 
                <AutoCompleteCell
                    {...commonProps}
                    editable
                    onEdited={(value) => {
                        openDialog("assign", {
                            ids: [data.id],
                            user: value.id,
                            onSaved: (update) => {
                                this.cellEdited("targeting_user_id", update.user)
                            }
                        });
                    }}
                    showStringValue={!selectedUser.id}
                    value={selectedUser}
                    width={columnWidthMap['assigned']}
                    autoCompleteData={privileged_users}
            />,
            project:
                <LinkListCell
                    value={data.target_project}
                    valueHandler={value => value.name}
                    urlHandler={value => value.url}
                    noTab={true}
                    editable={false}
                    width={columnWidthMap['project']}
                    />,
            in_procountor:
                    <ListCell
                        {...commonProps}
                        name="in_procountor"
                        editable={false}
                        width={columnWidthMap['in_procountor']}
                        value={this.props.data.in_procountor == 1 ? "P" : ""}
                />,
            in_netvisor:
                <ListCell
                    {...commonProps}
                    name="in_netvisor"
                    editable={false}
                    width={columnWidthMap['in_netvisor']}
                    value={this.props.data.in_netvisor == 1 ? "N" : ""}
            />,
            buyer_reference:
                <ListCell
                    {...commonProps}
                    name="buyer_reference"
                    editable={false}
                    width={columnWidthMap['buyer_reference']}
                    value={this.props.data.buyer_reference}
            />,
            reference_nr:
                <ListCell
                    {...commonProps}
                    name="reference_nr"
                    editable={false}
                    width={columnWidthMap['reference_nr']}
                    value={this.props.data.reference_nr}
            />,
            synced_to_po:
                <LinkListCell
                    value={data.projects_subcontracts_id == '0' ? "" : ("PO" + data.projects_subcontracts_id)}
                    urlHandler={value => `index.html?module=purchaseorder&action=view&id=${data['projects_subcontracts_id']}&companies_id=${data.companies_id}`}
                    noTab={true}
                    editable={false}
                    asText={!canSeePo}
                    width={columnWidthMap['synced_to_po']}
                />,
            currency_sum:
                data.currency_rate ?
                    <CurrencyListCell
                        {...commonProps}
                        value={data.currency_sum}
                        width={columnWidthMap['currency_sum']}
                        currency={data.invoice_currency}
                    />
                    :
                    <ListCell
                        {...commonProps}
                        name="currency_sum"
                        editable={false}
                        width={columnWidthMap['currency_sum']}
                        value={""}
                    />,
            currency_sum_tax:
                data.currency_rate ?
                    <CurrencyListCell
                        {...commonProps}
                        value={data.currency_sum_tax}
                        width={columnWidthMap['currency_sum_tax']}
                        currency={data.invoice_currency}
                    />
                    :
                    <ListCell
                        {...commonProps}
                        name="currency_sum_tax"
                        editable={false}
                        width={columnWidthMap['currency_sum_tax']}
                        value={""}
                    />,
            invoice_currency:
                <ListCell
                    {...commonProps}
                    name="currency"
                    editable={false}
                    width={columnWidthMap['invoice_currency']}
                    value={data.invoice_currency}
                />,
            currency_rate:
                <ListCell
                    {...commonProps}
                    name="currency_rate"
                    editable={false}
                    width={columnWidthMap['currency_rate']}
                    value={data.currency_rate}
                />,
        };
    }
}

export default BillsListRow;
