
/* local components */
import TaimerComponent from "../../TaimerComponent";

/* material ui */
import Dialog from '@mui/material/Dialog';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* styles */
import './UserLimitDialog.css';

/* eslint import/no-webpack-loader-syntax: off */
import { ReactComponent as Upgrade } from './../img/Upgrade.svg';

class UserLimitDialog extends TaimerComponent {
    static contextType = SettingsContext;

    constructor (props, context){
        super(props, context, "settings/dialogs/UserLimitDialog");
    }

    render() {

        const { tr } = this;

        return (
            <Dialog className="UserlimitDialog" open maxWidth={false} >
                <div className="UserlimitDialogContent">
                    <div className="top section">
                        <h1>{tr("Upgrade your Taimer version")}</h1>
                        <div className="footnote-container">
                            <small>{tr("You have reached the limit of 2 users in Free CRM")}</small>
                            <small>{tr("Upgrade your Taimer version to take your business to the next level")}</small>
                        </div>
                    </div>
                    <div className="middle section">
                        <div className="left">
                            <Upgrade />
                        </div>
                        <div className="right">

                        </div>
                    </div>
                    <div className="bottom section">

                    </div>
                </div>
            </Dialog>
        );
    }
}

export default UserLimitDialog;