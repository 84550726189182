/* react */
import React from 'react';

/* css */
import './Efina.css';

/* material-ui */
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";

/* others */
import List from "../../list/List";
import ListCell from "../../list/ListCell";
import SettingRow from "../../list/rows/SettingRow";
import DataHandler from '../../general/DataHandler';
import TrashCanIcon from "@mui/icons-material/Delete";
import TextInputCell from "../../list/cells/TextInputCell";
import { SettingsContext } from '../../SettingsContext';
import { MenuItem } from '@mui/material';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";

class AccountRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            account_number: <TextInputCell
                width={this.props.columnWidthMap['account_number']}
                name="account_number"
                value={this.state.data['account_number']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class DimensionRow extends SettingRow {
    render() {     
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            dimension: <TextInputCell
                width={this.props.columnWidthMap['dimension']}
                name="dimension"
                value={this.state.data['dimension']}
                onEdited={this.editAndSave}
            />
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class Tietotili extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Tietotili");

        this.state = {
            salesAccounts: [],
            dimensions: [],
            username: "",
            password: "",
            currentDialog: false,
            activated: 0
        };

        this.salesAccountsList = React.createRef();
        this.dimensionsList = React.createRef();

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getSalesAccounts();
        this.getDimensions();
        this.getAccountingDefaults();
    }

    getSalesAccounts = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/tietotili/1`}).done(response => {
            this.setState(response);
        });
    }

    getDimensions = () => {
        DataHandler.get({url: `accounting/dimensions/${this.props.company}/tietotili`}).done(response => {
            this.setState(response);
        });
    }

    getAccountingDefaults = () => {
        DataHandler.get({url: `accounting/defaults/${this.props.company}`}).done(response => {
            this.setState(response);
        });
    }

    getAuthData = () => {
        DataHandler.get({url: `integrations`, company_id: this.props.company, integration: 'tietotili'}).done(response => {
            this.setState(response);
        });
    }

    saveAuth = () => {
		const { username, password } = this.state;

		DataHandler.put(
			{ url: `integrations` },
			{ integration: 'tietotili', 'company_id': this.props.company, username: username, password: password }
		).done(resp => this.updateComponentData());
	};

    changeDefault = (type, id) => {
        const company = this.props.company;
        this.setState({[type]: id}, () => DataHandler.put({url: `accounting/defaults/${company}`}, {type: type, id: id }));
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        this.setState({
            dialogData: {
                saveFunc: () => DataHandler.delete({url: `integrations`, company_id: this.props.company, integration: 'tietotili'}).done(() => {
                    this.props.enqueueSnackbar(this.tr("TietotiliOnline authentication deactivated"), {
                        variant: "success"
                    });	
                    this.back();
                }),
                text: this.tr("Do you want to deactivate TietotiliOnline integration?")
            }
        }, () => this.openDialog('confirmation'));
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }
    
    render(){
        const { tr } = this;

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("TietotiliOnline Settings")}</h3>
                    {this.state.activated > 0 && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
					<h4>{tr("SFTP credentials")}</h4>
					<OutlinedField
						value={this.state.username}
                        name="username"
						onChange={evt => this.setState({ username: evt.target.value })}
						label={tr("SFTP username")}
					/>
					<OutlinedField
						value={this.state.password}
                        name="password"
                        type="password"
						onChange={evt => this.setState({ password: evt.target.value })}
						label={tr("SFTP password")}
                        autoComplete="new-password"
					/>             
					<div className="settings-buttons">
						<Button data-testid="save_tietotili" className="blue" color="primary" onClick={this.saveAuth} size="large">
							{tr("Save")}
						</Button>
					</div>
				</div>

                <div className="settings">
                    <h4>{tr("Sales accounts")}</h4>
                    <Button className="green" onMouseUp={() => this.salesAccountsList.current.addNewRow()} size="large">{tr("Add account")}</Button>
                    <br />
                    <List
                        ref={this.salesAccountsList}
                        id="accountingSalesAccountList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            account_number: "",
                        }}
                        listRowType={AccountRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Account name"), ...this.dColConf },
                            { width: 200, name: "account_number", header: tr("Account number"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                DataHandler.post({url: `accounting/accounts/${this.props.company}/tietotili/1`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url:  `accounting/accounts/${this.props.company}`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            },
                            onDelete: data => {
                                if(data.id == this.state.default_accounting_accounts_id) {
                                    this.props.enqueueSnackbar(this.tr("Default account cannot be deleted"), {
                                        variant: "error",
                                    });	
                                    return;
                                }
                                data.deleted = "1";
                                DataHandler.delete({url:  `accounting/accounts/${this.props.company}`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            }
                        }}
                        data={this.state.salesAccounts}
                    />

                    <div className="drop-section">
                        <h4>{tr("Default sales account")}</h4>
                        <OutlinedField select disabled={false} label={tr("Default")} name={"default"} value={this.state.default_accounting_accounts_id} fullWidth >
                            <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_accounts_id", 0)}>{this.tr("No default")}</MenuItem>
                            {this.state.salesAccounts.map(({name, id}) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_accounts_id", id)}>{name}</MenuItem>)}
                        </OutlinedField>
                    </div>

                    <div className="settings">                  
                        <h4>{tr("Dimensions")}</h4>
                        <Button className="green" onMouseUp={() => this.dimensionsList.current.addNewRow()} size="large">{tr("Add dimension")}</Button>
                        <br />
                        <List
                            ref={this.dimensionsList}
                            id="dimensionsList"
                            className="settingsList integrationList"
                            height="auto"
                            rowHeight={SettingRow.rowHeight}
                            newRow={{
                                id: -1,
                                name: "",
                                dimension: ""
                            }}
                            listRowType={DimensionRow}
                            columns={[
                                { width: 55, name: "delete", header: "", ...this.dColConf },
                                { width: 350, name: "name", header: tr("Dimension name"), ...this.dColConf },
                                { width: 200, name: "dimension", header: tr("Dimension value"), ...this.dColConf },
                            ]}
                            rowProps={{
                                onCreate: data => {
                                    data.company = this.props.company;
                                    DataHandler.post({url: 'accounting/dimensions/tietotili'}, { data: JSON.stringify(data) }).done(response => {
                                        this.getDimensions();
                                    });
                                },
                                onUpdate: data => {
                                    DataHandler.put({url: 'accounting/dimensions'}, { data: JSON.stringify(data) }).done(response => {
                                        this.getDimensions();
                                    });
                                },
                                onDelete: data => {
                                    if(data.id == this.state.default_accounting_dimensions_id) {
                                        this.props.enqueueSnackbar(this.tr("Default dimension cannot be deleted"), {
                                            variant: "error",
                                        });	
                                        return;
                                    }
                                    data.deleted = "1";
                                    DataHandler.delete({url: 'accounting/dimensions'}, { data: JSON.stringify(data) }).done(response => {
                                        this.getDimensions();
                                    });
                                }
                            }}
                            data={this.state.dimensions}
                        />
                    </div>

                    <div className="drop-section">
                        <h4>{tr("Default dimension")}</h4>
                        <OutlinedField select disabled={false} label={tr("Default")} name={"default"} value={this.state.default_accounting_dimensions_id} fullWidth >
                            <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_dimensions_id", 0)}>{this.tr("No default")}</MenuItem>
                            {this.state.dimensions.map(({name, id}) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_dimensions_id", id)}>{name}</MenuItem>)}
                        </OutlinedField>
                    </div>

                    {Dialog && <Dialog
                        open
                        company={this.props.company}
                        onDialogClose={this.closeDialog}
                        onDialogSave={this.confirmDialog}
                        data={this.state.dialogData}
                    />}
                </div>
            </div>
        )
    }
}

export default Tietotili;