import React from 'react';
/* css */
import "./UsageList.css";

/* others */
import List from "./../List";
import TaimerComponent from '../../TaimerComponent';
import UsageListRow from "./../rows/UsageListRow";

class UsageList extends TaimerComponent {
    constructor(props) {
        super(props, null, 'list/lists/UsageList');

        this.list = React.createRef();
        this.state = {
            columnWidths: {},
            columnsHidden: {},
            columnOrder: false,
            listWidth: 0,
            users: this.props.data ? this.props.data.users : [],
            fields: [
                { field: "user_icon", name: "user_icon", className:"user-icon", header: '', width: 80, sortable: false, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
                { field: "lastname", name: "lastname", header: this.tr("Surname"), width: 200},
                { field: "firstname", name: "firstname", header: this.tr("First Name") , width: 200},
                { field: "hours_possible_including_vacations", name: "hours_possible_including_vacations", header: this.tr("Possible workhours") , width: 200},
                { field: "hours_away", name: "hours_away", header: this.tr("Hours away") , width: 200},
                { field: "hours_possible_excluding_vacations", name: "hours_possible_excluding_vacations", header: this.tr("Resourceable hours") , width: 200},
                { field: "hours_resourced", name: "hours_resourced", header: this.tr("Resourced hours") , width: 200},
                { field: "hours_free", name: "hours_free", header: this.tr("Free hours") , width: 200},
                { field: "availability", name: "availability", header: this.tr("Availability"), width:  200},
            ]
        };

    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps) {
        if(this.props.usageGetCount !== prevProps.usageGetCount && this.props.data) {
            this.setState({users: this.props.data.users});
        }
    }

    onWidthCalculate = (values) => {
        let columnWidths = {};
        values.columnWidths.forEach(el => {
            columnWidths[el.name] = el.width;
        })
        let listWidth = 0;
        let contents = document.querySelector(".mainListOuterWrapper.usageList  .contents.usageList");
        if(contents) {
            listWidth = contents.clientWidth;
        }
        this.setState({columnWidths: columnWidths, listWidth: listWidth});
    }

    onColumnVisibilityChange = (columns) => {
        let columnsHidden = columns.reduce((obj, el) => {
            obj[el.field] = !el.visible;
            return obj;
        } ,{});
        this.setState({columnsHidden: columnsHidden});
    }

    onColumnOrderChange = (columnOrder) => {
        let fields = this.state.fields.slice();
        let newFields = [];
        columnOrder.forEach(function(key) {
            let found = false;
            fields = fields.filter(function(item) {
                if(!found && item.name == key) {
                    newFields.push(item);
                    found = true;
                    return false;
                } else {
                    return true;
                }
            })
        })
        this.setState({fields: newFields});
    }

    sortRows = (colName, asc) => {
        let sortedData = [...this.props.data.users];
        let multiplier = asc ? 1 : -1;
        sortedData.sort((a, b) => {
            let A = a[colName];
            let B = b[colName];
            if(colName == 'lastname' || colName == 'firstname') {
                return (A + "").localeCompare(B) * multiplier;
            }
            return (Number(A) - Number(B)) * multiplier;

            
        });
        this.setState({users: sortedData});
    }
  

    render() {
        let totalWidth = 0;

        for(let i in this.state.columnWidths) {
            totalWidth += this.state.columnWidths[i];
        }

        return (
            <React.Fragment>
                <List
                    fluid={true}
                    ref={this.list}
                    data={this.props.data ? this.state.users : []}
                    columns={this.state.fields}
                    height="fitRemaining"
                    className="usageList"
                    listRowType={UsageListRow}
                    userListSettingsKey="usage_list"
                    rowProps={{}}
                    trimHeight={-40}
                    onPerPageChange={perpage => {
                        this.setState({ perpage: perpage });
                        this.fetchData({ perpage: perpage });
                    }}
                    onWidthCalculate={this.onWidthCalculate}
                    onColumnVisibilityChange={this.onColumnVisibilityChange}
                    onColumnOrderChange={this.onColumnOrderChange}
                    saveColumnConfig={true}
                    userListSettingsKey="usage_list"
                    onSortRows={this.sortRows}
                />
                <div className="resourcing-usage-totals" style={{width: this.state.listWidth + "px"}}>
                    {
                        this.state.fields.map(el => {
                            if(!this.props.data || !this.props.data.totals || this.state.columnsHidden[el.field]) {
                                return null;
                            }
                            let value = '';
                            if(el.field == 'user_icon') {
                                value = this.tr('Total');
                            } else if(this.props.data && this.props.data.totals && this.props.data.totals[el.field] != undefined) {
                                value = Number(this.props.data.totals[el.field]).toFixed(2);
                            }
                            return el.field == 'availability' ? 
                            (<div className={el.field} style={{width: (this.state.columnWidths[el.name] ? this.state.columnWidths[el.name] + "px" : '')}}>
                                <div className="percent">{this.props.data ? Number(this.props.data.totals.availability).toFixed(0) + "%" : ''}</div>
                                <div className="bar">                               
                                    <div className={"indicator" + (this.props.data && this.props.data.totals.availability >= 100 ? ' over' : '')} style={{width: this.props.data ? (this.props.data.totals.availability + '%') : '0%'}}></div>
                                </div>                      
                            </div>)
                            :
                            (<div className={el.field} style={{width: (this.state.columnWidths[el.name] ? this.state.columnWidths[el.name] + "px" : '')}}>
                                {
                                    value
                                }
                            </div>)
                        })
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default UsageList;
