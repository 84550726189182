import React from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
import TaimerComponent from "../../TaimerComponent";
import DataHandler from './../../general/DataHandler';
import { SettingsContext } from '../../SettingsContext';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import SettingsGrid from './SettingsGrid';
import List from "../../list/List";
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";

import _ from "lodash";

import './Resourcing.css';
import { Switch, FormControlLabel } from '@mui/material';
import ResourcingSkillListRow from '../../list/rows/ResourcingSkillListRow';
import PriorityListRow from '../../list/rows/PriorityListRow';

import { ReactComponent as HighestPriority } from '../../resourcing/images/priority/highest.svg';
import { ReactComponent as HighPriority } from '../../resourcing/images/priority/high.svg';
import { ReactComponent as NormalPriorirty } from '../../resourcing/images/priority/normal.svg';
import { ReactComponent as LowPriorirty } from '../../resourcing/images/priority/low.svg';
import { ReactComponent as LowestPriorirty } from '../../resourcing/images/priority/lowest.svg';

import { withSnackbar } from 'notistack';

const styles = theme => ({

});

class Resourcing extends TaimerComponent {
    static contextType = SettingsContext;


    constructor(props, context) {
        super(props, context, "settings/pages/Resourcing");

        this.prioritiesList = React.createRef();
        this.skillsList = React.createRef();

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
        this.dialogTexts = {
            deleteCustomType: this.tr("Are you sure you want to delete the expense type? If you choose 'yes', the type becomes unusable for further expenses, but is still visible in reports.")
        };

        this.state = {
            currentDialog: false,
            dialogData: false,
            loaded: false,
            skills: [],
            priorities: [],
            general: {},
            additional: {},
            sHeaderVisisble:false,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateComponentData();

    }
    updateComponentData = async () => {
        const { company } = this.props;

        const general = await DataHandler.get({ url: `settings/resourcing` });
        const skills = await DataHandler.get({ url: `resourcing/skills/${company}` });
        const additional = await DataHandler.get({ url: `settings/company/${company}/resourcing` });

        this.setState({
            loaded: true,
            skills: skills,
            general: general,
            additional,
        }, this.updatePriorities);
    }
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    fetchSkills = (extra = {}) => {
        const { company } = this.props;
        DataHandler.get({ url: `resourcing/skills/${company}`, ...extra }).done(data => {
            this.setState({ skills: data });
            if(data.length == 0){
                this.setState({sHeaderVisible:false});
            } 
        });
    }

    companyLevelSettings = {
        allow_tracking_all_tasks: true,
    };

    onChange = (settings, setting, value) => {
        if (this.companyLevelSettings[setting]) {
            this.onChangeCompany(settings, setting, value);
            return;
        }

        let obj = {};
        obj[setting] = value;
        DataHandler.post({ url: `settings/resourcing` }, obj)
            .done(data => {
                this.setState({ general: data });
            })
    }

    onChangeCompany = (settings, setting, value) => {
        const { company } = this.props;

        let obj = {};
        obj[setting] = value;
        DataHandler.post({ url: `settings/company/${company}/resourcing` }, obj)
            .done(data => {
                this.setState({ additional: data });
            })
    }

    close = () => {
        this.props.onClose();
    }

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    }

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    }

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    }

    updatePriorities = () => {
        const { additional: { resourcing_default_priority } } = this.state;

        const priorities = [
            {
                id: 1,
                name: this.tr('Lowest'),
                default: false,
                icon: LowestPriorirty,
            }, {
                id: 2,
                name: this.tr('Low'),
                default: false,
                icon: LowPriorirty,
            }, {
                id: 3,
                name: this.tr('Normal'),
                default: false,
                icon: NormalPriorirty,
            }, {
                id: 4,
                name: this.tr('High'),
                default: false,
                icon: HighPriority,
            }, {
                id: 5,
                name: this.tr('Highest'),
                default: false,
                icon: HighestPriority,
            }
        ].reverse();

        const dp = priorities.find(x => x.id == resourcing_default_priority);

        if (dp) dp.default = true;

        this.setState({ priorities });
    }

    render() {
        const { currentDialog, dialogData, additional, loaded, priorities, sHeaderVisible } = this.state;
        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
        const { tr } = this;
        const { company, enqueueSnackbar } = this.props;

        const Dialog = currentDialog ? this.dialogs[currentDialog] : undefined;
        const loaderStyle = {
            width: 60,
            height: 60,
            padding: 20,
          };

        const settings = [
            {
                type: "check",
                name: "resourcing_count_saturday",
                label: tr("Count saturdays"),
                className: "respSetting half"
            },
            {
                type: "check",
                name: "resourcing_count_sunday",
                label: tr("Count sundays"),
                className: "respSetting half"
            },
            {
                type: "check",
                name: "use_subresources",
                label: tr("Use subresources"),
                className: "respSetting half"
            },
            {
                type: "check",
                name: "use_extra_project_hours",
                label: tr("Use extra project hours"),
                className: "respSetting half"
            },
            {
                type: "check",
                name: "allow_resourcing_to_project",
                label: tr("Allow resourcing directly to a project in grid"),
                className: "respSetting half"
            },
            {
                type: "check",
                name: "allow_tracking_all_tasks",
                label: tr("Allow tracking hours to other users tasks"),
                className: "respSetting half"
            },
            {
                type: "check",
                name: "hide_other_company_tasks",
                label: tr("Also check company of user for permission (otherwise project only)"),
                className: "respSetting half"
            },
        ];
        
        return (
             <React.Fragment>
                {!loaded && 
                    <div> 
                      <Loading />
                    </div>
                }
                {loaded && 
                    <React.Fragment>   
                        <h3>{tr("General")}</h3>
                        <p className="subtitle">{tr("Count saturdays and sundays as workdays")}</p>
                        <SettingsGrid settings={settings} item={{...this.state.general, ...this.state.additional}} onChange={this.onChange} />

                        <div className="settings-feature-wrapper">
                            <h3>{tr("Priorities")}</h3>
                            <p className="subtitle">{tr("Manage all resourcing priorities.")}</p>
                            <FormControlLabel
                                control={<Switch color="primary" onChange={(e, v) => this.onChangeCompany(null, "resourcing_disable_priority", v ? "1" : "0")}
                                    checked={this.state.additional.resourcing_disable_priority === '1'} />}
                                label={tr("Hide priority field from all dialogs")} />

                        {this.state.additional.resourcing_disable_priority !== '1' &&
                            <List
                                id="resourcingPriorities"
                                ref={this.prioritiesList}
                                className="settingsList"
                                height="auto"
                                row_key="id"
                                rowHeight={73}
                                listRowType={PriorityListRow}
                                emptyNewDataOnUpdate
                                rowProps={{
                                    onUpdate: data => {
                                        DataHandler.post({ url: `settings/company/${company}/resourcing` }, {
                                            resourcing_default_priority: data.id,
                                        });

                                        this.setState({ additional: { ...this.state.additional, resourcing_default_priority: data.id } }, this.updatePriorities);
                                    }
                                }}
                                noStateData
                                columns={[
                                    { width: 352, name: "name", header: tr("Priority"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                                    { width: 130, name: "default", header: tr("Default"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },

                                ]}
                                newRow={{
                                    name: "",
                                    companies_id: company
                                }}
                                data={priorities}
                            />}
                        </div>

                        <div className="settings-feature-wrapper">
                            <h3>{tr("Skills")}</h3>
                            <p className="subtitle">{tr("Manage all resourcing skills.")}</p>
                                <FormControlLabel
                                    control={<Switch color="primary" onChange={(e, v) => this.onChangeCompany(null, "resourcing_disable_skill", v ? "1" : "0")}
                                        checked={this.state.additional.resourcing_disable_skill === '1'} />}
                                    label={tr("Hide skills field from all dialogs")} />

                        {this.state.additional.resourcing_disable_skill !== '1' && 
                            <div>
                                <Button className="green" onMouseUp={() => {this.skillsList.current.addNewRow(); this.setState({sHeaderVisible:true})}} size="large">{tr("Add skill")}</Button>
                                <List
                                    id="resourcingSkills"
                                    ref={this.skillsList}
                                    className="settingsList"
                                    height="auto"
                                    row_key="id"
                                    rowHeight={73}
                                    listRowType={ResourcingSkillListRow}
                                    hideHeader={( sHeaderVisible || (this.state.skills && this.state.skills.length > 0)) ? false: true}
                                    emptyNewDataOnUpdate={false}
                                    manualCreate
                                    rowProps={{
                                        onCreate: data => DataHandler.post({ url: 'resourcing/edit_skill/' + data.id }, data).done(response => {
                                        // this.skillsList.current.editData({id: response.id}, data.id);
                                        this.skillsList.current.removeRow(data.id);

                                        this.fetchSkills({ refresh: 1 });

                                        }).fail(response => {
                                            enqueueSnackbar("There is skill with same name.", { variant: "error" });
                                        }),
                                        onUpdate: async data => {
                                            try {
                                                const res = await DataHandler.post({ url: 'resourcing/edit_skill/' + data.id }, data);

                                                this.fetchSkills({ refresh: 1 });

                                                return res;
                                            } catch (err) {
                                                return { error: err };
                                            }
                                        },
                                        onDelete: data => {
                                            if (data.id < 0) {
                                                this.skillsList.current.removeRow(data.id);
                                                return;
                                            }

                                        this.setState({
                                            dialogData: {
                                                id: data.id,
                                                saveFunc: (id) => {
                                                    DataHandler.post({ url: 'resourcing/edit_skill/' + id }, { deleted: 1 }).done(response => {
                                                        this.fetchSkills({ refresh: 1 });
                                                    });
                                                },
                                                text: tr("Do you want to delete skill") + ": " + data.name + "?"
                                            }
                                        });
                                        this.openDialog('confirmation');
                                        }
                                    }}
                                    noStateData
                                    columns={[
                                        { width: 65, "name": "delete", header: "", resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                                        { width: 352, name: "name", header: tr("Skill"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                                        { width: 575, name: "description", header: tr("Description"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                                        { width: 130, name: "default", header: tr("Default"), resizeable: false, moveable: false, showMenu: false, showResizeMarker: false },
                                    ]}
                                    newRow={{
                                        name: "",
                                        companies_id: company
                                    }}
                                    data={this.state.skills} />
                            </div>}
                        </div>
                    </React.Fragment>
                }

                {Dialog && <Dialog
                    open
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={dialogData} />}
            </React.Fragment>
        );
    }
}

export default withSnackbar(withStyles(styles)(Resourcing));