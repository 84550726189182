import React from "react";
import moment from "moment";
import TaimerComponent from "../TaimerComponent";
import ResourceRecurrenceDialog from './ResourceRecurrenceDialog';
import { ReactComponent as Repeat } from '../general/icons/repeat.svg';
import './RepeaterDialog.css';

export class RepeaterDialog extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "dialogs/RepeaterDialog");

        this.state = {
            repeatRule: '',
            showRecurrenceDialog: false
        };
    }

    repeatRuleToHumaReadable(rrule) {
        const { userObject } = this.context;
        let defaulMonth = {value: 1, label: this.tr('Jan')};
        let data = {
            freq: 'yearly',
            interval: 1,
            ends: 'never',
            after: 1,
            monthday: 1,
            setPos: {value: 1, label: this.tr("First")},
            month: defaulMonth,
            endDate: new Date(),
            selectedFormat: 'monthday',
            selectedWeekDays: {
                su: true,
                mo: false,
                tu: false,
                we: false,
                th: false,
                fr: false,
                sa: false,
                'su,mo,tu,we,th,fr,sa': false,
                'mo,tu,we,th,fr': false,
                'su,sa': false
            }
        };
        let rrules = rrule.split(";");
        let format = 'monthday';
        let weekdays = [
            {value: 'su', label: this.tr("Sun")},
            {value: 'mo', label: this.tr("Mon")},
            {value: 'tu', label: this.tr("Tue")},
            {value: 'we', label: this.tr("Wed")},
            {value: 'th', label: this.tr("Thu")},
            {value: 'fr', label: this.tr("Fri")},
            {value: 'sa', label: this.tr("Sat")},
            {value: 'su,mo,tu,we,th,fr,sa', label: this.tr("Day"), isComposite: true},
            {value: 'mo,tu,we,th,fr', label: this.tr("Weekday"), isComposite: true},
            {value: 'su,sa', label: this.tr("Weekend day"), isComposite: true},
        ];
        let months = [
            {value: 1, label: this.tr("Jan")},
            {value: 2, label: this.tr("Feb")},
            {value: 3, label: this.tr("Mar")},
            {value: 4, label: this.tr("Apr")},
            {value: 5, label: this.tr("May")},
            {value: 6, label: this.tr("Jun")},
            {value: 7, label: this.tr("Jul")},
            {value: 8, label: this.tr("Aug")},
            {value: 9, label: this.tr("Sep")},
            {value: 10, label: this.tr("Oct")},
            {value: 11, label: this.tr("Nov")},
            {value: 12, label: this.tr("Dec")},
        ];
        let onTheDays = [
            {value: 1, label: this.tr("First")},
            {value: 2, label: this.tr("Second")},
            {value: 3, label: this.tr("Third")},
            {value: 4, label: this.tr("Fourth")},
            {value: -1, label: this.tr("Last")},
        ];
        let endFormat = 'never';
        for(let i = 0; i < rrules.length; i++) {
            let split = rrules[i].split("=");
            let name = split[0].toLowerCase();
            let value = split[1].toLowerCase();                
            switch(name) {
                case 'freq':
                    data.freq = value;
                    break;
                case 'interval':
                    data.interval = value;
                    break;
                case 'bysetpos':
                    format = 'countday';
                    data.setPos = onTheDays.find(el => el.value == value);
                    break;
                case 'bymonth':
                    data.month = months.find(el => el.value == value);
                    break;
                case 'bymonthday':
                    data.monthday = value;
                    break;
                case 'byday':
                    for(let j in data.selectedWeekDays) {
                        data.selectedWeekDays[j] = false;
                    }
                    if(data.selectedWeekDays[value] !== undefined) {
                        data.selectedWeekDays[value] = true;
                    } else {
                        let splittedDays = value.split(",")     
                        for(let k = 0; k < splittedDays.length; k++) {
                            if (splittedDays[k])
                                data.selectedWeekDays[splittedDays[k]] = true;
                        }                            
                    }
                    break;
                case 'count':
                    endFormat = 'after';
                    data.after = value;
                    break;
                case 'until':
                    endFormat = 'onDate';
                    data.endDate = moment(value.toUpperCase())._d;
                    break;
            }                
        }
        data.selectedFormat = format;            
        data.ends = endFormat;                    
        let humanReadable = "";
        let weekdayTranslations = [];
        const freq = data.freq;
        
        if(freq != 'yearly') {
            humanReadable += "interval=1";
        }
        humanReadable += "freq=1";

        if(freq == 'yearly' || freq == 'monthly') {            
            if(data.selectedFormat == 'monthday') {                
                if(freq == 'yearly') {
                    humanReadable += "bymonth=1";
                }
                humanReadable += "bymonthday=1";
            }
            if(data.selectedFormat == 'countday') {          
                let singleWeekday = false;
                if(data.selectedFormat == 'countday') {
                    for(let i in data.selectedWeekDays) {
                        if(data.selectedWeekDays[i]) {
                            singleWeekday = i;
                            weekdayTranslations.push(weekdays.find(el => el.value == i).label);
                            break;
                        }
                    }
                }
                humanReadable += "bysetpos=1";
                humanReadable += "byday=1";
                if(freq == 'yearly') {
                    humanReadable += "bymonth=1";
                }
            }
        }
        if(freq == 'weekly') {
            humanReadable += "byday=1";
            let selected = [];
            for(let i in data.selectedWeekDays) {
                if(data.selectedWeekDays[i] && i.indexOf(",") == -1) {
                    selected.push(i);
                    weekdayTranslations.push(weekdays.find(el => el.value == i).label);
                }
            }
        }
        
        if(data.ends == 'after') {
            humanReadable += "count=1";
        }
        if(data.ends == 'onDate') {
            humanReadable += "until=1";
        }

        let freqTranslations = {
            yearly: this.tr('year'),
            monthly: data.interval > 1 ? this.tr('months') : this.tr('month'),
            weekly: data.interval > 1 ? this.tr('weeks') : this.tr('week'),
            daily: data.interval > 1 ? this.tr('days') : this.tr('day')
        };


        humanReadable = this.tr('Every') + " " + humanReadable;
        if(data.interval > 1) {
            humanReadable = humanReadable.replace(/interval=1/, data.interval);
        } else {
            humanReadable = humanReadable.replace(/interval=1/, "");
        }
        humanReadable = humanReadable.replace(/freq=1/, " " + freqTranslations[freq] + " ");
        humanReadable = humanReadable.replace(/bymonth=1/, (data.selectedFormat == 'countday' ? " " + this.tr('of') + ' ' : ''  ) + data.month.label + " ");
        humanReadable = humanReadable.replace(/bymonthday=1/, data.monthday + ". ");
        humanReadable = humanReadable.replace(/bysetpos=1/, data.setPos.label.toLowerCase() + " ");
        humanReadable = humanReadable.replace(/count=1/, ", " + data.after + " " + this.tr('times'));
        humanReadable = humanReadable.replace(/until=1/, ", " + this.tr('until') + " " + moment(data.endDate).format(userObject.dateFormat));
        humanReadable = humanReadable.replace(/byday=1/, weekdayTranslations.join(", ").toLowerCase() + " ");
        humanReadable = humanReadable.replace(" , ", ", ");
        humanReadable = this.tr("Repeats") + " " + humanReadable;
        return humanReadable;
    }

    openRecurrenceDialog = () => {
        this.setState({showRecurrenceDialog: true});
    }

    recurrenceSave = (rule) => {
        let state = this.state;
        state.repeatRule = rule;
        state.showRecurrenceDialog = false;
        this.setState({showRecurrenceDialog: false,repeatRule: rule});

        this.props.setRecurrence(rule);   
    }

    recurrenceClose = () => {
        this.setState({showRecurrenceDialog: false});
    }

    render() {
        return(
            <div className="recurrence-wrapper">                           
                <div onClick={this.openRecurrenceDialog} className="recurrence-block">
                    <Repeat />
                    {
                        this.state.repeatRule ? this.repeatRuleToHumaReadable(this.state.repeatRule) : this.tr("Repeat")
                    }
                </div>
                {   
                    this.state.showRecurrenceDialog && (
                        <ResourceRecurrenceDialog
                            title={this.tr("Recurring Event")}
                            onSave={(rule) => this.recurrenceSave(rule)}
                            rrule={this.state.repeatRule ? this.state.repeatRule : ''}
                            onClose={() => this.recurrenceClose()}
                        /> 
                    )
                } 
            </div>
        );
    }
}

export default RepeaterDialog;