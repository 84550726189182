import _ from 'lodash';

/**
* Gets shown multidimension headers for invoices or bills.
* Gets info if rows order number dimension headers differ from settings order number headers.
* @returns Array of dimension headers. Boolean does row dimension headers differ from settings dimension headers.
*/
export function getMultiDimensionHeaders(props) {
    const { isNewInvoice, accountingData, invoiceRows, type } = props;

    if (isNewInvoice || type != "sales_invoices") { // For new invoices and all bills and products: Get always settings headers
        const dimensionHeaders = accountingData?.dimension_headers?.filter(h => h.visible == 1) || [];
        return { dimensionHeaders, dimensionHeadersDiffer: false };
    }

    const dimensionHeaders = [];

    const headerRowCategories = [2,5];
    const checkRows = invoiceRows.filter(r => !headerRowCategories.find(c => c == r.row_category));

    // Get headers for dimensions that are saved to some rows and have order number
    checkRows.forEach(row => {
        (row.dimension_values || []).forEach(dim => {
            const split = (dim.header_id || "").split("_");
            const headerId = split[0];
            const orderNum = split.length > 1 ? split[1] : "0";

            const header = (accountingData.dimension_headers || []).find(h => h.orig_id == headerId);

            if (header && Number(orderNum) > 0 && !dimensionHeaders.find(h => h.id == dim.header_id)) {
                dimensionHeaders.push({ ...header, id: dim.header_id, order_num: orderNum });
            }
        });
    });

    let dimensionHeadersDiffer = false;
    dimensionHeaders.forEach(header => { 
        if (!(accountingData.dimension_headers || []).find(h => h.visible == 1 && h.id == header?.id)) { // Check if added dimension header ids differ from settings headers. Header id includes order number so also differs if same header in different place.
            dimensionHeadersDiffer = true;
        }          
    });

    // Get other headers: Order number header is not yet added or not order number header
    (accountingData.dimension_headers || []).forEach(header => {
        const addedOrderHeader = header.order_num != "0" && dimensionHeaders.find(h => h.order_num == header.order_num);

        if (header.visible == 1 && (header.order_num == "0" || !addedOrderHeader)) { // If order_num == "0" and visible == 1, header is not order number header and can be shown.
            dimensionHeaders.push(header);
        }
    });

    dimensionHeaders.sort((a, b) => (a.order_num || "").localeCompare(b.order_num || ""));

    return {dimensionHeaders, dimensionHeadersDiffer};
}

export function getProductDimensionValuesForHeaders(productDimensions, defaultDimensions, dimensionHeaders, projectDimensions = []) {
    const dimension_map = {};
    const dimensionValues = [];
    // Add default dimension values to row dimensions.
    (defaultDimensions || []).forEach(d => {
        dimension_map[d.header_id] = d;
    });
    // Update project dimension values to row dimensions.
    (projectDimensions || []).forEach(pr => {
        dimension_map[pr.header_id] = pr;
    });
    // Update product dimension values to row dimensions.
    (productDimensions || []).forEach(p => {
        dimension_map[p.header_id] = p;
    });

    // Return dimensions that belong to given headers.
    Object.entries(dimension_map).forEach(([headerId, data]) => { 
        if (dimensionHeaders.find(h => h.id == headerId)) {
            dimensionValues.push(data);
        }
    });

    return dimensionValues;
}

export function getDefaultDimensionValuesForHeaders(dimensionHeaders) {
    const dimension_values = [];
    (dimensionHeaders || []).forEach(h => {
        const default_dimension = h.options?.find(d => d.is_default == 1);
        if (default_dimension) {
            dimension_values.push({ header_id: h.id, dimension_id: default_dimension.id });
        }
    });
    return dimension_values;
}

/**
 * Get project ids for invoice rows. 
 * If only one project selected: Add selected project to all rows.
 * If multiple projects selected: Add project from header where row is under.
 * @param rows rows to get project ids for.
 * @param allRows all invoice rows.
 * @param selectedProjects Array of selected projects with projects id.
 * @param isMaterialInvoice is invoice from project material. Material rows have projects_id.
 * @returns Array of projectids with rowids [{rowId: number, projectId: number}]
 */
export function getProjectIdsForInvoiceRows(rows, allRows, selectedProjects, isMaterialInvoice) {
    const headerRowCategories = [2,5];
    const reverseRows = _.cloneDeep(allRows).reverse();
    const rowProjectIds = [];

    rows.forEach(r => {
        let projectId = r.projects_id;
        if (!projectId) {
            // Always select selected project's id if one project selected.
            if (Array.isArray(selectedProjects) && selectedProjects?.length == 1) {
                projectId = selectedProjects[0]?.id;
            }
            else if (isMaterialInvoice) { // Get projects id from row's header if multiple projects selected for material invoice.
                const rowHeader = reverseRows.find(rr => rr.roworder < r.roworder && headerRowCategories.find(h => h == rr.row_category));
                projectId = rowHeader?.projects_id || 0;
            }
        }
        rowProjectIds.push({ rowId: r.id, projectId });
    });
    return rowProjectIds;
}

export function getProjectCustomerReference(addressReference, projectReference) {
    let ref = addressReference || "";

    if (projectReference) {
        ref = ref 
            ? ref + ", " + projectReference
            : projectReference;
    }

    return ref;
}