import React, { Component } from 'react';
import TaimerComponent from '../TaimerComponent';
import DialogBorder from './DialogBorder';
import DialogSection from './DialogSection';
import { DialogDropDownButton, DialogDropDownButtonItem } from './DialogDropDownButton';

import { SettingsContext } from '../SettingsContext';
import DataHandler from '../general/DataHandler';
import DataList from './../general/DataList';
//import './ConnectAccountToParent.css';

const TRANSL = {} // temp

export default class ConnectAccountToParent extends TaimerComponent {

    static contextType = SettingsContext;

	constructor(props, context) {
		super(props, context, "dialogs/ConnectAccountToParent");

        this.state = {
            selectedParent: undefined
        };

    }

    onSave = () => {
        if(!this.state.selectedParent) {
            return;
        }
        let data = {
            type: this.props.type,
            parent: this.state.selectedParent.id,
            company: this.props.company
        };
        let url = 'accounts/' + this.props.id + '/parent';
        DataHandler.put({url: url}, data).done(() => {
            let parent = {};
            if(this.props.type == "main_unit") {
                parent = {main_unit: this.state.selectedParent.id, parent_status: 4}
            } else {
                parent = {parental_company: this.state.selectedParent.id, parent_status: 2}
            }
            this.props.onSave(parent);
        });
    }

    render() {

        return (
            <DialogBorder
                title={this.props.type == "main_unit" ? this.tr("Set as Sub-unit") : this.tr("Set as Subsidairy")}
                onClose={this.props.onClose}
                onSave={this.onSave}
                disableSave={!this.state.selectedParent}
                className="TMRDialogGrid TMRDialogInputs ConnectAccountToParent"
                >

                <DialogSection>
                    <p className="full">
                        {this.props.type == "main_unit" ? 
                            this.tr("Please select the Main-unit for this Sub-unit")
                            :
                            this.tr("Please select the Parent Company for this Subsidairy")}
                    </p>
                    <DataList
                        dropLabel={this.props.type == "main_unit" ? this.tr("Main unit") : this.tr("Parent company")}
                        className="full"
                        options={this.props.parents}
                        onChange={(d) => this.setState({selectedParent: d})}
                        value={this.state.selectedParent}
                        openMenuOnFocus={true}
                        shownCount={20}
                    >
                    </DataList>

                </DialogSection>

            </DialogBorder>
        )
    }
}