import React from 'react';
import classNames from 'classnames';

import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from './../SettingsContext';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { ReactComponent as ProjectInvoiceIcon } from './Project_invoice.svg';
import { ReactComponent as BlankInvoiceIcon } from './Blank_invoice.svg';
import { ReactComponent as MassInvoiceIcon } from './Mass_invoice.svg';
import Close from '@mui/icons-material/Close';
import VersionContentManager from '../general/VersionContentManager';

const styles = theme => ({
    topBar: {
        backgroundColor: '#2D9FF7',
        height: '8px',
        marginBottom: '10px'
    },
    header: {
        textAlign: "center"
    }, 
    contentContainer: {
        textAlign: "center",
        maxWidth: "300px",
        padding: "0 15px",
        cursor: "pointer",
        flex: '1 1 300px',
        position: 'relative',
        paddingBottom: '36px',
    },
    ruler: {
        display: "inline-block",
        height: "100%",
        width: "1px",
        border: "1px inset",
    },
    footer: {
        paddingBottom: "20px"
    },
    closeButton: {
        position: "absolute",
        top: "28px",
        right: "20px",
        cursor: "pointer"
    },
    dialogContent: {
        display: "flex"
    },
    buttonContainer: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: "100%",
    }
});

class InitialDialog extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "invoices/InitialDialog");

    };

    chooseInvoiceType = (type) => {
        // mass invoicing selected
        if (type == '10' && VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'massInvoicing')) {
            this.context.functions.showUpgradeSlider();
            return;
        }
        this.props.data.onInitiate(type);
    }

    render() {

        const { userObject, addons } = this.context;

        const { classes, data } = this.props;

        return (
            <Dialog
                open
                className="dialogChoise"
                maxWidth="xl"
                // classes={{paper: classNames(classes.paper)}}               
                aria-labelledby="dialogChoise" >
                <div className={classNames(classes.topBar)}/>
                <DialogTitle >
                    <p className={classNames(classes.header)}> {this.tr('Choose how to invoice')} </p>
                    <Close className={classNames(classes.closeButton)} onClick={this.props.onDialogClose} />
                </DialogTitle>
                <DialogContent className={classNames(classes.dialogContent)} >
                    {data.blankAllowed &&
                    <div className={classNames(classes.contentContainer)} onClick={() => this.chooseInvoiceType('1')} >
                        <BlankInvoiceIcon height='140' />
                        <h4> {this.tr('Use blank invoice template')} </h4>
                        <p> {this.tr('Create an invoice from scratch. Just choose or create an account and add items. Save, send, and get paid faster.')}</p>
                        <div className={classNames(classes.buttonContainer)}>
                            <Button data-testid="create-blank-invoice-button" size="large" variant="outlined" /*onClick={() => this.chooseInvoiceType('1')}*/ color="primary">
                                {this.tr('Create blank invoice')}
                            </Button>
                        </div>
                    </div>}
                    <div className={classNames(classes.contentContainer)} onClick={() => this.chooseInvoiceType('2')} >
                        <ProjectInvoiceIcon height='140' />
                        <h4> {this.tr('Invoice project material')} </h4>
                        <p> {this.tr('Create an invoice with materials added to projects: hours, expenses, buills, scheduled invocing and more.')}</p>
                        <div className={classNames(classes.buttonContainer)}>
                            <Button data-testid="invoice-project-materials-button" size="large" variant="outlined" /*onClick={() => this.chooseInvoiceType('2')}*/ color="primary">
                                {this.tr('Invoice project materials')}
                            </Button>
                        </div>
                    </div>
                    {data.massInvoicingAllowed && <div className={classNames(classes.contentContainer)} onClick={() => this.chooseInvoiceType('10')} >
                        <MassInvoiceIcon height='140' />
                        <h4>{this.tr('Mass Invoice Creation')}</h4>
                        <p> {this.tr('Create multiple invoices at once with materials added to projects; hours, expenses, bills, scheduled invoicing and more...')}</p>
                        <div className={classNames(classes.buttonContainer)}>
                            <Button size="large" variant="outlined" /*onClick={() => this.chooseInvoiceType('10')}*/ color="primary">
                                {this.tr('Mass Invoice Creation')}
                            </Button>
                        </div>
                    </div>}
                </DialogContent>
               
            </Dialog>
        );
    }
}

export default withStyles(styles)(InitialDialog);
