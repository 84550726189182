/* react */
import React from 'react';

/* css */
import './Efina.css';

/* material-ui */
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import TaimerComponent from "../../TaimerComponent";
import ProjectTreeDropdown from "../../projects/ProjectTreeDropdown";

/* others */
import DataHandler from '../../general/DataHandler';
import { SettingsContext } from '../../SettingsContext';

class Mepco extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Mepco");

        this.state = {
            vacation_project_id: 0,
            sick_leave_project_id: 0,
            child_sick_leave_project_id: 0,
            projects: []
        };   
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/mepco`}).done(response => {
            this.setState(response);
        });
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        DataHandler.delete({url: `settings/company/${this.props.company}/mepco`}).done(this.back);
    }

    changeProject = (target, update) => {
        if (update[target] == undefined)
            return;
        
        DataHandler.post({ url: `settings/company/${this.props.company}/mepco/projects` }, {update});
    }
    
    render(){
        const { activated } = this.state;        
        const { tr } = this;

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Mepco Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("Vacation project")}</h4>
                    <ProjectTreeDropdown
                        name="project" // TODO: Onko tarpeellinen?
                        label={this.tr("Project")}
                        treeDropdownProps={{ growOptionContainer: false, showTooltip: true, resetOnClickaway: true, showTreeDropdownInfoIcon: false }}
                        value={this.state.vacation_project_id}
                        disabled={false}
                        disableAutoSelect
                        queryParameters={{right: 'read'}}
                        onSelect={e => {
                            this.setState({
                                vacation_project_id: e.id
                            }, () => {
                                const update = {
                                    vacation_project_id: e.id
                                };
                                this.changeProject('vacation_project_id', update);
                            });
                        }}
                    />
                </div>

                <div className="settings">
                    <h4>{tr("Sick leave project")}</h4>
                    <ProjectTreeDropdown
                        name="project" // TODO: Onko tarpeellinen?
                        label={this.tr("Project")}
                        treeDropdownProps={{ growOptionContainer: false, showTooltip: true, resetOnClickaway: true, showTreeDropdownInfoIcon: false }}
                        value={this.state.sick_leave_project_id}
                        disabled={false}
                        disableAutoSelect
                        queryParameters={{right: 'read'}}
                        onSelect={e => {
                            this.setState({
                                sick_leave_project_id: e.id
                            }, () => {
                                const update = {
                                    sick_leave_project_id: e.id
                                };
                                this.changeProject('sick_leave_project_id', update);
                            });
                        }}
                    />
                </div>

                <div className="settings">
                    <h4>{tr("Childcare leave project")}</h4>
                    <ProjectTreeDropdown
                        name="project" // TODO: Onko tarpeellinen?
                        label={this.tr("Project")}
                        treeDropdownProps={{ growOptionContainer: false, showTooltip: true, resetOnClickaway: true, showTreeDropdownInfoIcon: false }}
                        value={this.state.child_sick_leave_project_id}
                        disabled={false}
                        disableAutoSelect
                        queryParameters={{right: 'read'}}
                        onSelect={e => {
                            this.setState({
                                child_sick_leave_project_id: e.id
                            }, () => {
                                const update = {
                                    child_sick_leave_project_id: e.id
                                };
                                this.changeProject('child_sick_leave_project_id', update);
                            });
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default Mepco;
