import React from 'react';

function highlightString(string, highlighters) {
    const originalString = string;
    string               = string.toLowerCase();
    highlighters         = Array.isArray(highlighters)
        ? highlighters
        : [highlighters];

    const arr = string
        .split("")
        .map(c => false);

    highlighters
        .map(h => h.toLowerCase())
        .forEach(h => {
            let index = -1;

            do {
                index = string.indexOf(h, index + 1);

                if(index === -1) {
                    continue;
                }

                for(let i = index; i < index + h.length; ++i) {
                    arr[i] = true;
                }
            } while(index > -1);
        });

    const highlighted = originalString
        .split("")
        .map((char, index) => {
            return [char, arr[index]];
        });
        
    const parts     = [];
    let previous    = null;
    let current     = { string: "", highlight: false };
    const lastIndex = highlighted.length - 1;

    for(let i in highlighted) {
        const [char, h] = highlighted[i];
        let switched    = false;

        if(previous === null) {
            previous = h;
            current.highlight = h;
        }

        if(h !== previous) {
            parts.push(current);

            current  = { string: "", highlight: false };
            switched = true;
        }

        if(h === previous || switched) {
            current.string += char;
            current.highlight = h;
        }

        previous = h;

        if(i != lastIndex) {
            continue;
        }

        parts.push(current);
    }

    return parts;
}

function HighlightedString(props) {
    const {
        string,
        highlighters,
        highlightClassName 
    } = props;

    const parts = highlightString(string, highlighters);

    return (
        <React.Fragment>
            {parts.map(({ string, highlight }) => {
                return (
                    <span 
                        className={highlight 
                            ? highlightClassName 
                            : ""}>
                        {string}
                    </span>
                );
            })}
        </React.Fragment>
    );
}

export default HighlightedString;
