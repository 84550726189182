import * as MuiIcons from '@mui/icons-material';
import { 
    Button, 
    Table, 
    TableBody, 
    TableCell, 
    TableHead, 
    TableRow, 
    Tooltip, 
    Chip, 
    TextField 
} from '@mui/material';

import React from 'react';
import { cloneDeep, isEqual, isEmpty } from 'lodash';
import moment from 'moment';

import TaimerComponent from '../TaimerComponent';
import FieldEditSlider from './FieldEditSlider';
import { FieldEditSliderProps } from './FieldEditSlider';
import DataList from './DataList';
import Indicator from './Indicator';
import colors from '../colors';
import { EditableField } from "../general/FieldEditSlider";
import ContextMenu from "./ContextMenu";
import { MenuItem } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';

import styles from './SliderList.module.scss';

interface SliderListRowMenuItem {
    // TODO: Generics.
    icon: JSX.Element;
    text: string;
    onClick: () => void;
    variant?: string;
    visible?: boolean;
}

interface SliderListRowMenuProps {
    icon: JSX.Element;
    items: SliderListRowMenuItem[];
    contextMenuProps: { [key: string]: any };
}

class SliderListRowMenu extends React.Component<SliderListRowMenuProps> {
    static defaultProps = {
        icon: <MoreVert />,
        items: [],
        contextMenuProps: {}
    };

    contextMenu;

    constructor(props) {
        super(props);

        this.contextMenu = React.createRef();
    }

    renderMenuItems = (): JSX.Element => {
        return (
            <>
                {this.props.items
                .filter((item: SliderListRowMenuItem) => {
                    return item?.visible ?? true;
                })
                .map((item: SliderListRowMenuItem, index: number) => {
                    // Add a class to the icon that adds 8px of margin to the right.
                    const icon: JSX.Element = React.cloneElement(item.icon, {
                        className: styles.contextMenuIcon,
                    });

                    return (
                        <div 
                            className={styles.contextMenuEntry}
                            // Has to be done with an id to 
                            // override a global style from 
                            // TabQuotes.scss.
                            // Only one of them should be visible
                            // at a time anyway, so shouldn't be a problem.
                            id={styles?.[item?.variant ?? "normal"]}>
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    this.contextMenu?.current?.close();
                                    item.onClick();
                                }}>
                                {icon}{item.text}
                            </MenuItem>
                        </div>
                    );
                })}
            </>
        )
    }

    render() {
        const {
            icon,
            contextMenuProps
        }: {
            icon: JSX.Element;
            contextMenuProps: { [key: string]: any };
        } = this.props;

        const addedProps: { [key: string]: any } = contextMenuProps ?? {};

        return (
            <ContextMenu
                ref={this.contextMenu}
                classes={{
                    button: styles.contextMenuButton
                }}
                noExpandIcon={true}
                label={icon}
                disablePortal={false}
                {...addedProps}>
                {this.renderMenuItems()}
            </ContextMenu>
        );
    }
}

interface SliderRowField extends EditableField {
    key: string;
    title: string;
    type: string;
    validation?: string;
    required?: boolean;
}

// TODO: Generics for the entity that's handled in the slider.
// TODO: Generics for wherever they apply.
interface SliderListProps {
    // item?: any;
    'data-testid'?: string;
    rowPresentation: (row, list: SliderList) => { [key: string]: JSX.Element | string | null; };
    texts?: TextsObject | ((item) => TextsObject);
    icons: IconsObject;
    rows?: any[];
    className: string;
    minimumWidth?: number;
    onItemSave?: (row: any) => void;
    onDeleteRow?: (row: any) => void;
    fieldEditHandlers?: { [key: string]: (fieldData: any, row: any) => any };
    listColumns: ColumnObject[];
    sliderRowFields?: SliderRowField[] | ((item: any) => SliderRowField[]);
    headerChipConfig?: HeaderChipConfig;
    menuItems?: any[];
    customMenuItem?: any;
    wrapperId?: string | undefined;
    wrapperStyle?: { [key: string]: string };
    additionalContent?: JSX.Element | ((item: any, slider: any, onItemChanged: (values: any) => void) => JSX.Element);
    propControlledRows?: boolean;
    onSliderOpen?: (item) => void,
    onSliderClose?: (item, reason: string) => void,
    itemPropUpdateRelevantFields?: string[];
    editable?: boolean | ((r: any, c: any) => boolean);
    columnsToHideWhenNotEditable?: string[];
    fieldEditSliderProps?: Partial<FieldEditSliderProps>;
    summaryRowPresentation?: ((rows) => { [key: string]: JSX.Element | string | null; }) 
        | { [key: string]: JSX.Element | string | null; };
}

interface HeaderChipConfig {
    textFieldText?: string;
    indicatorText?: string;
    color?: string;
    icon?: JSX.Element;
}

interface SliderListState {
    rows: any[];
    header?: string;
    open: boolean;
    // TODO: Generics
    item: any;
}

interface ColumnObject {
    id: string;
    label: string;
    align?: "left" | "right" | "justify" | "center" | "inherit" | undefined;
    width?: number | string; // Number or percentage as a string.
    noClick?: boolean;
    // TODO: Generics
    cellClickHandler?: (row: any, list: SliderList) => void | undefined,
    included?: any[];
    icon?: string;
}

interface IconsObject {
    mainIcon: string;
}

interface TextsObject {
    mainHeader?: string;
    mainTooltip?: string;
    customerHeader?: string;
    sliderHeader?: string;
}

// TODO: default props
class SliderList extends TaimerComponent<SliderListProps, SliderListState> {
    static defaultProps = {
        editable: true,
        className: "",
        itemPropUpdateRelevantFields: []
    };

    wrapper: any   = React.createRef();
    rowSlider: any = React.createRef();
    idCounter      = -1;

    // menuItemFunctionCallsMap: any;

    constructor(props, context) {
        super(props, context, "general/SliderList");

        this.state = {
            rows: props.rows,
            header: undefined,
            open: false,
            item: undefined
        };
    }    

    componentDidMount = () => {
        super.componentDidMount();
        this.updateIdCounter();
    }

    componentDidUpdate = (oldProps: SliderListProps) => {
        if(!isEqual(oldProps.rows, this.props.rows)) {
            this.setState({ rows: this.props.rows || [] });
        }

        if((oldProps.rows?.length || 0) < (this.props.rows?.length || 0)) {
            this.updateIdCounter();
        }

        // If the prop rows change, and there's an item being
        // edited right now, we reflect the changes there.
        if((this.props.itemPropUpdateRelevantFields?.length ?? 0) > 0
            && !isEqual(oldProps.rows, this.props.rows) 
            && this.state.item !== undefined
            && this.props.rows !== undefined
        ) {
            this.updateItemOnPropChange();
        }
    };    

    /**
     * If new rows have been added without using addRow().
     */
    updateIdCounter = () => {
        const { rows } = this.props;

        rows?.forEach(r => {
            if (Number(r.id) <= this.idCounter) {
                this.idCounter = Number(r.id) - 1;
            }
        });
    }

    updateItemOnPropChange = () => {
        const item = cloneDeep(this.state.item);
        const row  = this.props.rows?.find(r => {
            return r.id === this.state.item.id;
        });

        if(!row) {
            return;
        }

        this.props.itemPropUpdateRelevantFields?.forEach((k: string) => {
            item[k] = row[k];
        });

        this.setState({
            item: item
        });
    }

    generateIcon = (variation, props = {}) => {
        const IconName = MuiIcons[variation];

        if(!IconName) {
            throw new Error(`The Material UI icon ${variation} does not exist.`);
        }

        return <IconName {...props} />;
    };

    generateTooltipContent = (rows) => {
        return (
            <div>
                {rows.map(r => 
                    (<p>{r.label}: <strong>{r.value}</strong></p>)
                )}
            </div>
        );
    }

    moveRowUp = (row) => {
        const rows = cloneDeep(this.state.rows);
        const index = rows.findIndex((r) => r.id == row.id);

        if (index != -1) {
            const newIndex = Math.max(0, index - 1);
            rows.splice(index, 1);
            rows.splice(newIndex, 0, row);
            this.setState({ rows });
        }
    };

    moveRowDown = (row) => {
        const rows = cloneDeep(this.state.rows);
        const index = rows.findIndex((r) => r.id == row.id);

        if (index != -1) {
            const newIndex = Math.min(rows.length - 1, index + 1);
            rows.splice(index, 1);
            rows.splice(newIndex, 0, row);
            this.setState({ rows });
        }
    }

    onChangeHeader = ({ target: { value: header } }) => {
        this.setState({ header });
    };

    getCustomHeader = () => {
        return this.state.header;
    }

    deleteRow = (row) => {
        const {
            onItemSave,
            onDeleteRow
        }: {
            onItemSave?: (r: any) => void;
            onDeleteRow?: (r: any) => void;
        } = this.props;
        
        if(onDeleteRow !== undefined) {
            onDeleteRow(row);
        } else if(onItemSave !== undefined) {
            onItemSave({ ...row, deleted: 1 }); 
        }

        if(this.props.propControlledRows) {
            return;
        }

        const rows       = cloneDeep(this.state.rows);
        const foundIndex = rows.findIndex((r) => r.id == row.id);

        if(foundIndex !== -1) {
            rows.splice(foundIndex, 1);
        }

        if(Number(row.id) > 0) {
            rows.push({ ...row, deleted: 1 });
        }

        this.setState({ 
            rows 
        });
    };

    // TODO: Generics
    handleFieldEditHandlers = (row: any): any => {
        if(this.props.fieldEditHandlers === undefined) {
            return row;
        }

        const handlerKeys: string[] = Object.keys(this.props.fieldEditHandlers);

        Object.keys(row)
            .filter((field: string) => handlerKeys.indexOf(field) > -1)
            .forEach((field: string) => {
                row[field] = this.props.fieldEditHandlers?.[field](row[field], row) ?? field;
            });

        return row;
    }

    // TODO: Generics.
    onItemSave = (row): void => {
        row         = this.handleFieldEditHandlers(row);
        const found = this.state.rows.find(r => Number(r.id) === Number(row.id));
        const rows  = !found
            ? [...cloneDeep(this.state.rows), row]
            : this.state.rows.map(r => r.id == row.id ? ({...r, ...row}) : r);

        // Trigger the saving immediately when the button is pressed, but..
        !!this.props?.onItemSave && this.props.onItemSave(row);

        const fn = () => this.closeSlider("save");

        // Use the timeout to let the save button spinner show for a moment,
        // and if saving triggers a refresh of the data provided to SliderList,
        // give the replica some time to catch up.
        setTimeout(() => {
            !this.props.propControlledRows
                ? this.setState({ rows: rows }, fn)
                : fn();
        }, 500);
    };

    handleSliderClose = (_, reason?: string) => {
        this.closeSlider(reason ?? "save");
    }

    closeSlider = (reason: string) => {
        const item = this.state.item;

        this.setState({ 
            open: false,
            item: undefined
        }, () => {
            if(!this.props.onSliderClose) {
                return;
            }

            this.props.onSliderClose(item, reason);
        });
    }

    addRow = (item = undefined) => {
        this.showRowSlider(item);
    }

    showRowSlider = (item = { id: this.idCounter-- }) => {
        item.id = item?.id ?? this.idCounter--;

        this.setState({
            open: true,
        }, () => {
            // FieldEditSlider overrides its internal
            // state.item with the one passed as the item
            // prop if you set open=true and the item together.
            this.setState({
                item: item
            }, () => {
                if(!this.props.onSliderOpen) {
                    return;
                }

                this.props.onSliderOpen(this.state.item); 
            });
        });
    }

    callMatchingSlider = (row) => {
        this.showRowSlider(row);
    }    

    // The passed config overrides the one in the props.
    generateHeaderChip = (conf: HeaderChipConfig | undefined) => {
        conf                = conf ?? this.props.headerChipConfig;
        const color         = conf?.color ?? colors.steel;
        const icon          = conf?.icon ?? <></>;
        const textFieldText = conf?.textFieldText ?? undefined;
        const indicatorText = conf?.textFieldText ?? undefined;
       
        return (
            <React.Fragment>
                {textFieldText && 
                    <TextField
                        className={styles.borderless}
                        disabled={true}
                        label={textFieldText}
                    />
                }
                {indicatorText && <Indicator
                    icon={icon} 
                    text={indicatorText}
                    color={color} />}
            </React.Fragment>
        );
    }

    renderAdditionalInfoText = (params) => {
        return params.tooltip  
            ? (<Tooltip title={params.tooltip} aria-label={params.tooltip} >
                    <span> {`${params.label} : `} <strong>{`${params.value ?? ''} ${params.after && params.value && moment(params.value)?.isValid() ? params.after : ''}`}</strong> </span>
                </Tooltip>)
            : (<span> {`${params.label} : `} <strong>{`${params.value ?? ''} ${params.after && params.value ? params.after : ''}`}</strong> </span>);
    }

    renderMenuItems = () => {
        // TODO: Refactor customMenuItem.
        const { 
            menuItems,
            customMenuItem,
        }: {
            menuItems?: any[];
            customMenuItem?: JSX.Element;
        } = this.props;

        const { 'data-testid': testid } = this.props;

        if(customMenuItem !== undefined) {
            return customMenuItem;
        }

        if(!menuItems || menuItems.length === 0) {
            return null;
        }

        if(menuItems.length === 1) {
            const [opt] = menuItems;

            return (<button data-testid={`${testid}-${opt.label}`} onClick={() => opt?.onClick(this)}>{opt.label}</button>);
        }
        
        // TODO: Make the placeholder customizable
        return (
            <DataList
                // placeholder={this.tr("Add item")}
                data-testid={`${testid}-menu-items`}
                variant="standard"
                classNamePrefix="wizard-row-add"
                className={styles.addmenu}
                options={menuItems}
                onChange={(opt) => opt?.onClick(this)}
            />
        );
    }

    rowIsEditable = (row, column: ColumnObject): boolean => {
        if(this.props.editable === undefined) {
            return true;
        }

        if(typeof(this.props.editable) !== "function") {
            return this.props.editable;
        }

        return this.props.editable(row, column);
    }

    getCellClickHandler = (c: ColumnObject, r: any): () => void => {
        if(!this.props.editable 
            || typeof(this.props.editable) === "function" && !this.props.editable(r, c)) {
                return () => {};
        }

        if(c.cellClickHandler !== undefined && typeof(c.cellClickHandler) === "function") {
            // For some reason my linter stopped understanding
            // that at this point c.cellClickHandler kind of has
            // to be a function.
            // @ts-ignore
            return () => c.cellClickHandler(r, this);
        }

        if(c.noClick) {
            return () => null;
        }

        return () => this.callMatchingSlider(r);
    };

    additionalContent = () => {
        const { additionalContent } = this.props;

        if(typeof(additionalContent) !== "function") {
            return additionalContent;
        }

        return (item, onItemChanged) => {
            return additionalContent(item, this.rowSlider.current, onItemChanged);
        };
    };

    title = () => {
        const { 
            texts, 
            icons, 
            headerChipConfig, 
        } = this.props;

        const { item } = this.state;

        const textsObject: TextsObject | undefined = typeof(texts) === "function"
            ? texts(item)
            : texts;

        return (
            <div className={styles.title}>
                <div className={styles.icon} data-removetag="true" >
                    {icons.mainIcon && this.generateIcon(icons.mainIcon)}
                </div>
                <div className={styles.right}>
                    <h2>{textsObject?.mainHeader}</h2>
                    {!isEmpty(headerChipConfig) && this.generateHeaderChip(headerChipConfig)}
                </div>
            </div>
        );
    };

    determineVisibleColumns = (): ColumnObject[] => {
        let { 
            listColumns
        }: {
            listColumns: ColumnObject[];
        } = this.props;

        const hideColumns: string[] = this.props.columnsToHideWhenNotEditable ?? [];

        // If all of the rows are are uneditable (props.editable === false),
        // and the column is set to be hidden when the row is uneditable,
        // we can hide the column itself.
        listColumns = listColumns
            .filter((c: ColumnObject) => (
                !(this.props.editable === false && hideColumns.indexOf(c.id) > -1)
            ));

        // If, on the other hand, props.editable is a function, there's
        // a chance editability is row or cell specific, in which case
        // we can't hide a whole column; only its content will be hidden
        // in the function "row".
        const allRowsNonEditable: (c: ColumnObject) => boolean = (c: ColumnObject): boolean => {
            return this.getNonDeletedRows().filter((row) => {
                return this.rowIsEditable(row, c);
            }).length === 0;
        };
           
        listColumns = listColumns.filter((c: ColumnObject) => {
            return !(typeof(this.props.editable) === "function"
               && hideColumns.indexOf(c.id) > -1
               && allRowsNonEditable(c)
            );
        });

        return listColumns;
    }


    header = () => {
        const listColumns: ColumnObject[] = this.determineVisibleColumns();

        return (
            <TableHead>
                <TableRow>
                    {listColumns.map((c: ColumnObject) => (
                        <TableCell 
                            key={c.id} 
                            align={c.align ?? 'left'} 
                            width={c.width}
                            title={c.label}
                            className={[
                                c.id == 'order' ? styles.orderContainer : '',
                                styles.columnTitle
                            ].join(" ")}>
                            {c.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    // TODO: Row types so this isn't needed.
    summaryRow = (rows: any[]): JSX.Element | null => {
        if(!this.props.summaryRowPresentation) {
            return null;
        }

        const rowPresentation: {
            [key: string]: JSX.Element | string | null; 
        } = typeof(this.props.summaryRowPresentation) !== "function"
            ? this.props.summaryRowPresentation
            : this.props.summaryRowPresentation(rows);

        return this.row(
            { id: "summary" }, 
            rowPresentation,
            { clickable: false }
        );
    };

    row = (
        r: { id: number | string }, 
        rowPresentation: { [key: string]: JSX.Element | string | null },
        settings: {
            clickable: boolean;
        } = { 
            clickable: true 
        }
    ): JSX.Element => {
        const {
            'data-testid': testid,
        } = this.props;

        return (
            <TableRow data-testid={`${testid}-row`} key={`${r.id}`}>
                {this.determineVisibleColumns().map((c: ColumnObject) => {
                    const classes = [
                        styles.tableCell,
                        c.noClick || !settings.clickable ? styles.normalCursor : '',
                        c.id == "order" ? styles.orderContainer : "",
                    ].join(" ");

                    const hideContent: boolean = !this.rowIsEditable(r, c) 
                        && this.props.columnsToHideWhenNotEditable !== undefined
                        && this.props.columnsToHideWhenNotEditable.indexOf(c.id) > -1;

                    return (
                        <TableCell
                            data-testid={`${testid}-cell-${c.id}`}
                            width={c.width}
                            key={`${r.id}_${c.id}`}
                            align={c.align}
                            className={classes}
                            onClick={c.noClick || !settings.clickable 
                                ? undefined 
                                : this.getCellClickHandler(c, r)}>
                            {!hideContent 
                                ? rowPresentation?.[c.id] ?? r[c.id] ?? null
                                : null}
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    };

    body = () => {
        const { 
            rows 
        } = this.state;

        return (
            <TableBody>
                {this.getNonDeletedRows().map((r, i) => {
                    const rowPresentation: { 
                        [key: string]: JSX.Element | string | null; 
                    } = this.props?.rowPresentation(r, this);

                    return this.row(r, rowPresentation);
                })}
                {this.summaryRow(rows)}
            </TableBody>
        );
    };

    getNonDeletedRows = (): any[] => {
        return this.state.rows.filter(r => !r.deleted);
    }

    fieldEditSlider = () => {
        const {
            sliderRowFields,
            fieldEditSliderProps,
            texts
        }: {
            sliderRowFields?: SliderRowField[] | ((item: any) => SliderRowField[]);
            fieldEditSliderProps?: Partial<FieldEditSliderProps>;
            texts?: TextsObject | ((item) => TextsObject);
        } = this.props;

        const {
            open,
            item
        }: { 
            open: boolean,
            item: any
        } = this.state;

        const sliderFields: SliderRowField[] | undefined = typeof(sliderRowFields) === "function"
            ? sliderRowFields(item)
            : sliderRowFields;

        const textsObject: TextsObject | undefined = typeof(texts) === "function"
            ? texts(item)
            : texts;

        return (
            <FieldEditSlider
                ref={this.rowSlider}
                item={item}
                useAnimatedSlider={true}
                saveOnEnter
                open={open}
                onClose={this.handleSliderClose}
                title={`${item?.id < 0 ? this.tr('Add') : this.tr('Edit')} ${this.tr(textsObject?.sliderHeader)}`}
                onSave={this.onItemSave}
                fields={sliderFields?.map(s => s.key == 'item' ? {...s, autoFocus: item?.id < 0 } : s) || []}
                additionalFields={this.additionalContent()}
                {...(fieldEditSliderProps ?? {})}
            />
        );
    };

    table = () => {
        const { 
            rows, 
            // TODO:
            // header, 
            // htmlInPrintMode 
        } = this.state;

        const rowContent: JSX.Element = !rows || rows.length === 0
            ? <span />
            : (
                <Table className={styles.table}>
                    {this.header()}
                    {this.body()}
                </Table> 
            );

        return (
            <div className={styles.rows}>
                {rowContent}
                <div className={styles.actions} data-removetag="true">
                    {this.renderMenuItems()}
                </div>
            </div>
        );
    };

    render() {
        const { 
            wrapperId,
            wrapperStyle,
            className,
            'data-testid': testid,
        } = this.props;

        const boxMinWidth: string = this.props?.minimumWidth
            ? `${this.props.minimumWidth}px`
            : "unset";

        const classNameString: string = [
            styles.content,
            className
        ].join(" ");

        return (
            <div 
                data-testid={testid}
                id={wrapperId} 
                className={classNameString} 
                style={wrapperStyle} 
                ref={this.wrapper}>
                <div>
                    <div 
                        className={styles.box}
                        style={{ minWidth: boxMinWidth }}>
                        {this.title()}
                        {this.table()}
                    </div>
                </div>
                {this.fieldEditSlider()}
            </div>
        );
    }
}

export default SliderList;
export {
    SliderList,
    SliderListRowMenu,
    type SliderListRowMenuItem,
    type SliderRowField,
    type SliderListProps,
    type ColumnObject
}
