import { Button } from '@mui/material';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';
import UserList from '../settings/pages/UserList';
import TaimerComponent from '../TaimerComponent';
import DataHandler from './DataHandler';
import styles from './LockUsersView.module.scss';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

interface State {
    selectedUsers: string[];
    userCount: number;
    locking?: boolean;
}

class LockUsersView extends TaimerComponent<WithSnackbarProps, State> {
    constructor(props, context) {
        super(props, context, 'general/LockUsersView');
        this.state = {
            selectedUsers: [],
            userCount: 0,
        };
    }

    onUsersLoaded = (userCount) => {
        this.setState({ userCount });
    };

    onRowChecked = ({ checkedRows }) => {
        const selectedUsers = Object.keys(checkedRows);
        this.setState({ selectedUsers });
    };

    onLockUsers = () => {
        this.setState({ locking: true }, async () => {
            const { selectedUsers } = this.state;
            const _ = await DataHandler.post({ url: `settings/users/lock` }, { ids: selectedUsers });
            setTimeout(() => {
                this.props.enqueueSnackbar(this.tr('Changes saved!'), {
                    variant: 'success',
                });
                this.context.functions.whoami();
            }, 1000);
        });
    };

    manageSubscription = () => {
        DataHandler.get({ url: `settings/subscription/portal` }).done((response) => {
            window.location = response;
        });
    };

    render() {
        const {
            taimerAccount,
            functions: { hasPrivilege },
        } = this.context;
        const { userCount, selectedUsers, locking } = this.state;
        const usersToLock = Math.max(0, userCount - taimerAccount.userlimit - selectedUsers.length);
        if (!hasPrivilege('admin', 'admin')) {
            return (
                <div id={styles.lockUsersView}>
                    <div className={styles.contactAdmin}>
                        <div>
                            <Loading  />
                            <h1>{this.tr('Please contact your admin user')}</h1>
                            <p>{this.tr('Billing changes need to be made to continue using Taimer')}</p>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div id={styles.lockUsersView}>
                <div className={styles.header}>
                    <div>
                        <h1>{this.tr('Apply billing changes to user amount')}</h1>
                        <p>
                            {this.htmlTr('You currently have too many users compared to your Taimer licenses. To correct, please select ${users} to lock or ${buy}', {
                                users: <span className={usersToLock == 0 ? styles.green : ''}>{this.tr('${amount} users', { amount: usersToLock })}</span>,
                                buy: <button onClick={this.manageSubscription}>{this.tr('buy more licenses')}</button>,
                            })}
                        </p>
                    </div>
                    <Button size="large" onClick={this.manageSubscription}>
                        {this.tr('Manage licenses')}
                    </Button>
                </div>
                <UserList onUsersLoaded={this.onUsersLoaded} lockUsersViewMode usersToLock={usersToLock} onCheck={this.onRowChecked} onLockUsers={this.onLockUsers} />
                {locking && <div className={styles.lockingOverlay}>{<Loading  />}</div>}
            </div>
        );
    }
}

export default withSnackbar(LockUsersView);
