import { cloneDeep } from 'lodash';
import InvoiceTranslations from '../general/backendTranslations/InvoiceTranslations';
import DataHandler from '../general/DataHandler';
import DataList from '../general/DataList';
import PDFView from '../general/PDFView';
import TaimerWizard, { TaimerWizardPage } from '../general/TaimerWizard';
import { EmailComposeView, EmailComposeViewState, RecipientOption } from '../projects/SendQuoteWizard';
import TaimerComponent from '../TaimerComponent';
import styles from './SendInvoiceWizard.module.scss';
import FileSaver from 'file-saver';
import moment from 'moment';
import { ReactComponent as SentImage } from '../general/icons/completed.svg';
import { ReactComponent as ErrorImage } from '../general/icons/error.svg';

interface SendInvoiceWizardProps {
    invoice: any;
    printMode?: boolean;
    printLanguage?: string;
    printDateFormat?: string;
    onInvoiceSent?: () => void;
}

interface SendInvoiceWizardState {
    printLanguage: string;
    printDateFormat: string;
    contacts: any[];
    invoicePDF?: any;
    email: EmailComposeViewState;
    loadingPDF?: boolean;
    printLanguageOptions: any;
    printDateOptions: any;
    error?: boolean;
}

class SendInvoiceWizard extends TaimerComponent<SendInvoiceWizardProps, SendInvoiceWizardState> {
    pages: TaimerWizardPage[] = [];
    quotePDFFile: any;
    constructor(props, context) {
        super(props, context, 'invoices/SendInvoiceWizard');
        this.pages = props.printMode
            ? [
                  {
                      title: this.tr('Preview'),
                      content: {
                          main: this.renderPreview,
                          right: this.renderSettings,
                      },
                      actions: [
                          {
                              label: this.tr('Print'),
                              disabled: () => !this.state.invoicePDF,
                              action: this.printInvoice,
                          },
                      ],
                  },
              ]
            : [
                  {
                      title: this.tr('Preview'),
                      content: {
                          main: this.renderPreview,
                          right: this.renderSettings,
                      },
                      actions: [
                          {
                              label: this.tr('Next'),
                              action: 'next',
                              disabled: () => !this.state.invoicePDF,
                          },
                      ],
                  },
                  {
                      title: this.tr('Compose email'),
                      content: { main: this.renderComposer },
                      actions: [
                          {
                              label: this.tr('Send'),
                              action: this.sendEmail,
                              setLoading: true,
                              disabled: this.requiredEmailFieldsMissing,
                          },
                      ],
                  },
                  {
                      title: this.tr('Sent'),
                      content: { main: this.renderSent },
                      actions: [
                          {
                              label: this.tr('Done'),
                              action: this.close,
                          },
                      ],
                      noReturn: true,
                  },
              ];
        this.state = {
            printLanguageOptions: [],
            printDateOptions: [],
            printLanguage: this.context.taimerAccount.ourCompanyLang,
            printDateFormat: this.convertDateFormat(this.context.taimerAccount.companyDateFormat, true),
            contacts: [],
            loadingPDF: true,
            email: {
                recipients: [],
                message: '',
            },
        };
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.getPrintSettings().then(() => {
            this.getInvoicePDF();
        });
        this.getContacts();
    }

    getPrintSettings = () => {
        return new Promise<void>((resolve) => {
            const { invoice, printLanguage: overridePrintLanguage, printDateFormat: overridePrintDateFormat } = this.props;
            DataHandler.get({ url: `subjects/companies/invoices/write_full+write_simple`, date_format: 1, print_lang: 1, print_options: 1 }).then((companies) => {
                const company = companies.find((c) => c.id == invoice.companies_id) || companies[0];
                const printLanguageOptions = company.print_languages || [];
                const printLanguage = (
                    printLanguageOptions.find((pl) => pl.value == (overridePrintLanguage || invoice.lang)) ||
                    printLanguageOptions.find((pl) => pl.value == company.print_lang) ||
                    printLanguageOptions.find((pl) => pl.value == this.context.taimerAccount.ourCompanyLang) ||
                    printLanguageOptions[0]
                )?.value;
                const printDateOptions = company.print_date_formats || [];
                const printDateFormat = (
                    printDateOptions.find((pdf) => pdf.value == (overridePrintDateFormat || invoice.date_format)) ||
                    printDateOptions.find((pdf) => pdf.value == company.date_format) ||
                    printDateOptions.find((pdf) => pdf.value == this.convertDateFormat(this.context.taimerAccount.companyDateFormat, true)) ||
                    printDateOptions[0]
                )?.value;
                this.setState(
                    {
                        printLanguageOptions,
                        printLanguage,
                        printDateFormat,
                        printDateOptions,
                    },
                    () => {
                        resolve();
                    }
                );
            });
        });
    };

    getContacts = () => {
        const { invoice, printMode } = this.props;
        if (printMode) return;
        DataHandler.get({ url: `autocomplete_data/contacts/${invoice.companies_id}/customer/${invoice.customers_id}` })
            .done((response) => {
                const contacts = (response.contacts || []).filter((c) => !!c.email);
                this.setState({ contacts });
            })
            .fail((error) => console.log(error));
    };

    printInvoice = () => {
        const { invoicePDF } = this.state;
        const { invoice } = this.props;
        if (!invoicePDF) return;
        FileSaver.saveAs(invoicePDF, 'invoice_' + invoice.bill_id + '.pdf');
        return 'close';
    };

    canSetInvoiceSent = (invoice) => {
        return invoice.state == '1' && invoice.bill_type != '2';
    };

    markInvoiceSent = () => {
        return new Promise<void>((resolve) => {
            const { invoice } = this.props;
            if (!this.canSetInvoiceSent(invoice)) {
                resolve();
                return;
            }
            DataHandler.post({ url: `invoices/mark_sent` }, { ids: [invoice.id] })
                .done(() => {
                    setTimeout(() => {
                        const analyticsData = {
                            invoices_sent: 1,
                            taimer_version: this.context.versionId,
                            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
                            invoice_destination: 'Taimer',
                            manual_send: true,
                            success: true,
                        };
                        //this.context.functions.sendAnalytics('invoice_sent', analyticsData);
                        this.props.onInvoiceSent && this.props.onInvoiceSent();
                        resolve();
                    }, 1000);
                })
                .fail(() => {
                    resolve();
                });
        });
    };

    sendEmail = () => {
        const {
            email: { message, recipients },
            printLanguage,
            printDateFormat,
        } = this.state;
        const { invoice } = this.props;
        const {
            project_read_companies: companies,
            userObject: { fullname },
        } = this.context;
        const company = (companies || []).find((c) => c.id == invoice.companies_id)?.name || this.context.taimerAccount.name;
        return new Promise<'next' | 'close'>((resolve) => {
            const transl = new InvoiceTranslations().returnTranslations([printLanguage]);
            DataHandler.postArrayBuffer(
                { url: 'invoices/send_pdf' },
                {
                    translations: transl,
                    ids: [invoice.id],
                    lang: printLanguage,
                    date_format: printDateFormat,
                    message: message || ' ',
                    messageContent: 'text',
                    recipients: recipients.map((r) => r.email).join(','),
                    pdfName: 'invoice_' + invoice.bill_id + '.pdf',
                    subject: this.beTr(
                        'Invoice ${invoiceNumber} from ${company}',
                        printLanguage || 'en',
                        { invoiceNumber: `#${invoice.bill_id}`, company },
                        'general/backendTranslations/InvoiceTranslations'
                    ),
                    sender: this.beTr('${userName} via Heeros', printLanguage || 'en', { userName: fullname.split(' ').reverse().join(' ') }, 'general/backendTranslations/InvoiceTranslations'),
                }
            )
                .done(() => {
                    //this.sendAnalyticsEvent();
                    this.markInvoiceSent()
                        .then(() => {
                            resolve('next');
                        })
                        .catch(() => {
                            // should show some fail page
                            resolve('next');
                        });
                })
                .fail((err) => {
                    if (err.status != 200) {
                        this.setState({ error: true }, () => {
                            resolve('next');
                        });
                    } else {
                        //this.sendAnalyticsEvent();
                        this.markInvoiceSent()
                            .then(() => {
                                resolve('next');
                            })
                            .catch(() => {
                                // should show some fail page
                                resolve('next');
                            });
                    }
                });
        });
    };

    sendAnalyticsEvent = () => {
        const analyticsData = {
            taimer_version: this.context.versionId,
            taimer_language: this.context.userObject.language,
            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
        };
        //this.context.functions.sendAnalytics('invoice_sent_by_email', analyticsData);
    };

    requiredEmailFieldsMissing = () => {
        return (this.state.email?.recipients || []).length == 0;
    };

    convertDateFormat = (format, reverse = false) => {
        if (!format) return undefined;
        if (reverse) {
            return format.replace('DD', '%d').replace('MM', '%m').replace('YYYY', '%Y');
        }
        return format.replace('%d', 'DD').replace('%m', 'MM').replace('%Y', 'YYYY');
    };

    getInvoicePDF = async () => {
        const { printLanguage, printDateFormat } = this.state;
        const { invoice } = this.props;
        this.setState({ loadingPDF: true }, async () => {
            const transl = new InvoiceTranslations().returnTranslations([printLanguage]);

            const response = await DataHandler.postArrayBuffer(
                { url: 'invoices/print_pdf', ids: [invoice.id], lang: printLanguage, date_format: printDateFormat, override_date_format: true },
                { translations: transl }
            );
            const invoicePDF = new Blob([response], {
                type: 'application/pdf',
            });
            this.setState({ invoicePDF, loadingPDF: false });
        });
    };

    onCreateRecipient = (params) => {
        const { invoice } = this.props;
        this.context.functions.addContact(
            {
                ...params,
                customers_id: invoice.customers_id,
            },
            { onContactCreated: this.onContactCreated }
        );
    };

    onContactCreated = (contact) => {
        const recipient: RecipientOption = { id: contact.id, label: `${contact.firstname} ${contact.lastname}`, email: contact.email };
        const recipients = cloneDeep(this.state.email?.recipients || []);
        recipients.push(recipient);
        this.setState({ email: { ...this.state.email, recipients } }, () => {
            this.getContacts();
        });
    };

    close = () => {
        this.context.functions.setOverlayComponent(undefined);
    };

    onPrintSettingChanged = ({ value }, { name }) => {
        const update: any = {
            [name]: value,
            invoicePDF: undefined,
            loadingPDF: true,
        };
        this.setState(update, () => {
            this.getInvoicePDF();
        });
    };

    onEmailFieldChanged = (key: string, value: string | RecipientOption[]) => {
        this.setState({ email: { ...this.state.email, [key]: value } });
    };

    renderPreview = () => {
        const { invoicePDF } = this.state;
        return <PDFView file={invoicePDF} />;
    };

    renderSettings = () => {
        const { printLanguageOptions, printDateOptions, printLanguage, printDateFormat, loadingPDF } = this.state;
        const translatedPrintLanguages = printLanguageOptions.map((o) => ({ ...o, label: this.tr(o.label) }));
        return (
            <div className={`${styles.settings} ${loadingPDF ? styles.disabled : ''}`}>
                <h2>{this.tr('Print details')}</h2>
                <div className={styles.fields}>
                    <DataList
                        name="printLanguage"
                        label={this.tr('Print language')}
                        options={translatedPrintLanguages}
                        value={translatedPrintLanguages.find((pl) => pl.value == printLanguage)}
                        onChange={this.onPrintSettingChanged}
                    />
                    <DataList
                        name="printDateFormat"
                        label={this.tr('Date format')}
                        options={printDateOptions}
                        value={printDateOptions.find((pd) => pd.value == printDateFormat)}
                        onChange={this.onPrintSettingChanged}
                    />
                </div>
            </div>
        );
    };

    renderComposer = () => {
        const { contacts, email } = this.state;
        const { invoice } = this.props;
        const { recipients, message } = email;
        return (
            <EmailComposeView
                recipients={recipients}
                recipientOptions={contacts}
                onCreateRecipient={this.onCreateRecipient}
                attachments={[{ name: this.tr('Invoice ${invoiceNumber}', { invoiceNumber: `#${invoice.bill_id}` }) }]}
                onFieldChanged={this.onEmailFieldChanged}
                message={message}
            />
        );
    };

    renderSent = () => {
        const { error } = this.state;
        return (
            <div className={styles.sent}>
                {error ? <ErrorImage/> : <SentImage/>}
                <h2>{error ? this.tr('Sending invoice failed!') : this.tr('Invoice sent successfully!')}</h2>
                <p>
                    {error
                        ? this.tr('Please try again later.')
                        : this.canSetInvoiceSent(this.props.invoice)
                        ? this.tr('The invoice was also marked as sent.')
                        : this.tr('The email was sent to all recipients.')}
                </p>
            </div>
        );
    };

    render() {
        return <TaimerWizard title={this.props.printMode ? this.tr('Print invoice') : this.tr('Send invoice')} pages={this.pages} updateTriggers={{ invoicePDF: this.state.invoicePDF }} />;
    }
}

export default SendInvoiceWizard;
