import React, { Component } from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import CameraIcon from '@mui/icons-material/Camera';
import Avatar from '@mui/material/Avatar';
import SettingsGrid from './SettingsGrid';
import Button from '@mui/material/Button';

class CompanyAddress extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context,"settings/pages/CompanyAddress");

        this.state = {
            loaded: false,
        }

        this.refFileInput = React.createRef();
    }

    componentDidMount ()  {
        super.componentDidMount();
        this.updateComponentData();
    }
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }
    updateComponentData = async () => {
        const { company } = this.props;

        const data = await DataHandler.get({url: `settings/company/${company}/address`});

        this.setState({loaded: true, data});
    }
    
    onChange = (data) => {
        this.setState({data});

        this.save(data);
    }

    save = async (data) => {
        const { userObject } = this.context;
        const { company } = this.props;
        
        await DataHandler.post({url: `settings/company/${company}/address`}, data);
        const newdata = {...this.state.data, data};

        this.setState({ loaded: true, data: newdata, errors: {} });
    }

    setError(error)
    {
        this.setState({errors: error.responseJSON})
    }

    render() {
        const { loaded, data, errors } = this.state;
        const { tr } = this;
        const { taimerAccount: { showState, isMulticompany } } = this.context;

        if (!loaded)
            return <div><Loading className='main-page-loading-indicator' /></div>

        const usMode = /* (data && data.state) || */ showState;    

        const settings = [
            {
                type: 'title',
                className: 'header',
                key: 'section-tile',
                label: tr("Address details"),
                subtext: tr("Add your company address details"),
            },
            {
                type: 'text',
                className: 'nextLine',
                name: 'name',
                label: tr("Company Name"),
                validation: ["empty"],
            },
            isMulticompany && {
                type: 'text',
                className: 'nextLine',
                name: 'company_address_name',
                label: tr("Company Name on Invoice"),
            },
            {
                type: 'text',
                className: 'nextLine',
                name: 'vatid',
                label: tr('International Company ID/VAT ID'),
            },
            {
                type: 'text',
                className: 'nextLine',
                name: 'address',
                label: tr("Address"),
                validation: ["empty"],
            },
            usMode && {
                type: 'text',
                className: 'nextLine',
                name: 'city',
                label: tr("City"),
                validation: ["empty"],
            },
            usMode && {
                type: 'text',
                className: 'nextLine',
                name: 'state',
                label: tr("State"),
                validation: [],
            },
            {
                type: 'text',
                className: 'nextLine',
                name: 'postalcode',
                label: tr("Post Number"),
                validation: ["empty"],
            },
            !usMode && {
                type: 'text',
                className: 'nextLine',
                name: 'city',
                label: tr("City"),
                validation: ["empty"],
            },
            {
                type: 'text',
                className: 'nextLine',
                name: 'country',
                label: tr("Country"),
                validation: ["empty"],
            },
            {
                type: 'text',
                className: 'nextLine',
                name: 'telephone',
                label: tr("Phone Number"),
                validation: ["empty"],
            },
            // {
            //     type: 'text',
            //     className: 'nextLine',
            //     name: 'telefax',
            //     label: tr("Fax Number"),
            // },6
            {
                type: 'text',
                className: 'nextLine',
                name: 'www',
                label: tr("Company URL"),
            },
            {
                type: 'text',
                className: 'nextLine',
                name: 'email',
                label: tr("Email"),
                validation: ["email"],
            },
        ];

        return <div className="SettingsGrid">
                <SettingsGrid errors={errors} settings={settings} item={data} onChange={this.onChange} />
            </div>
    }
}

export default CompanyAddress;