import moment from 'moment';
import DataHandler from './general/DataHandler';

function parseID(idobj)
{
    if (idobj === undefined)
        return -1;

    if (typeof idobj === "object")
    {
        const { id = -1 } = idobj;

        return id;
    }

    return parseInt(idobj, 10);
}

export function getUsersByID(ids = [], extra = {})
{
    return DataHandler.get({url: 'dialogs/users', id: ids.join(','), ...extra});
}

export function getCustomers(company, extra = {})
{
    const companies_id = parseID(company);

    if (companies_id < 1)
        return [];

    return DataHandler.get(
        {url: "dialogs/customers", companies_id, ...extra},
    );
}

export function getUnits(customer)
{
    const parent = parseID(customer);

    if (parent < 1)
        return [];

    return DataHandler.get(
        {url: "dialogs/customers", parent}
    );
}

export function getProjects(customer, extra = {})
{
    const customers_id = parseID(customer);

    if (customers_id < 1)
        return [];

    return DataHandler.get(
        {url: "dialogs/projects", customers_id, ...extra},    );
}

export function getProjectsByID(ids = [], extra = {})
{
    return DataHandler.post(
        {url: "dialogs/projects", ...extra},{id: ids.join(',')},
    );
}

export function getSubProjects(project, extra = {})
{
    const parent = parseID(project);

    if (parent < 1)
        return [];

    return DataHandler.get(
        {url: "dialogs/projects", parent, ...extra},
    );
}

export async function getWorktypes(company = undefined)
{
    return await DataHandler.get(
        {url: 'dialogs/worktypes', company},
    );
}

export async function getDefaultPricelistWorktypes(company = undefined)
{
    return await DataHandler.get(
        {url: 'dialogs/worktypes', default_pricelist: true, company},
    );
}

export async function getWorktypesForProject(project, extra = {})
{
    const id = parseID(project);

    return await DataHandler.get(
        {url: 'dialogs/worktypes', id, ...extra, refresh: project.isNew ? 1 : 0},
    );
}

export function getActivityTypes(company)
{
    let companies_id;
    if(company instanceof Array) {
        companies_id = company;
    }
    else if (company == 'all') {
        companies_id = undefined;
    } else {
        companies_id = parseID(company);
        if (companies_id < 1)
            return [];
    }

    return DataHandler.get(
        {url: `settings/activity_types`, companies_id: companies_id}
    );
}

export function getActivityUsers(company, project)
{
    const companies_id = parseID(company);

    if (companies_id < 1)
        return [];

    return DataHandler.get({url: 'dialogs/activityusers', companies_id, projects_id: parseID(project)})
}

export function getComboSearch(name, extra = {})
{
    return DataHandler.get(
        {url: 'dialogs/combodata', name, ...extra},
    );
}

export function postTaskQuick(task) {

    let data;
    let url;
    if(!task.id) {
        if(!task.users_hours || task.users_hours.length < 1) {
            task.users_hours = [
                {id: -1, users_id: task.users_id, hours: task.hours}
            ];
        }
        url = "resourcing/add_task";
        data = {
            description: task.description,
            startdate: moment(task.start_date).format("YYYY-MM-DD"),
            enddate: moment(task.end_date).format("YYYY-MM-DD"),
            starttime: moment(task.starttime,'LT').format("HH:mm"),
            endtime: moment(task.endtime,'LT').format("HH:mm"),
            hours: task.hours,
            projects_id: task.projects_id,
            type: 'task',
            users_hours: task.users_hours,
        };
    } else {
        url = "resourcing/edit_task/" + task.id;
        data = {
            startdate: moment(task.start_date).format("YYYY-MM-DD"),
            enddate: moment(task.end_date).format("YYYY-MM-DD"),
            starttime: moment(task.starttime,'LT').format("HH:mm"),
            endtime: moment(task.endtime,'LT').format("HH:mm"),
            hours: task.hours,
            type: 'task',
        }
    }
    data.timezone = moment.tz.guess();
    return DataHandler.post(
        {url: url}, data
    );
}
export function editTaskQuick(task) {
    const data = {
        startdate: moment(task.start_date).format("YYYY-MM-DD"),
        enddate: moment(task.end_date).format("YYYY-MM-DD"),
        starttime: moment(task.starttime,'LT').format("HH:mm"),
        endtime: moment(task.endtime,'LT').format("HH:mm"),
        hours: task.hours,
        type: 'task',
    };
    return DataHandler.post(
        {url: 'resourcing/edit_task'}, data
    );
}

export function deleteWorkhourEntry(id, message = "") {
    return DataHandler.delete({url: `timetracker/${id}`, message: message});
}

export function checkOverlaps(item) {
    return DataHandler.get({
        url: 'timetracker/checkOverlaps',
        id: item?.id ?? -1,
        datemode: item.isWorkhourTimer ? 'timer' : 'normal',
        startdate: moment(item.start).format('YYYY-MM-DD'),
        starttime: moment(item.start).format('HH:mm:ss'),
        enddate: moment(item.end).format('YYYY-MM-DD'),
        endtime: moment(item.end).format('HH:mm:ss'),
    });
}

export function postWorkhourEntry(item, invoiceRows = false)
{
    const details = {
        "datemode": item.isWorkhourTimer ? 'timer' : 'normal',
        "startdate": moment(item.start).format('YYYY-MM-DD'),
        "starttime": moment(item.start).format('HH:mm:ss'),
        "enddate": moment(item.end).format('YYYY-MM-DD'),
        "endtime": moment(item.end).format('HH:mm:ss'),
        "wh_customers_id": item.customer.id,
        "wh_units_id": item.unit.id,
        "wh_projects_id": item.project.id,
        // "wh_subprojects_id": item.subproject.id,
        "wh_projects_resource_id": item.wh_projects_resource && item.wh_projects_resource.id,
        "wh_projects_workflow_id": item.wh_projects_workflow_id,
        "resource_sub_done": item.resource_sub_done,
        "wh_worktasks_id": item.worktask && item.worktask.id,
        "description": item.description,
        "only_own_projects": 0,
        "quote_rows_id": item.projects_quotation_row,
        "timezone": moment.tz.guess(),
        "is_overtime": item.is_overtime,
        "overtime_description": item.overtime_description,
    };

    if (item.id)
        details.id = item.id;

    const data = {
        details,
    };

    if (invoiceRows)
    {
        const bill_entries = [];
        const bill_entries_products = [];
        const mileages = { "mileages": [], "additional": [] }
        const others = [];
        const dailies = [];
        let id = -1;

        invoiceRows.forEach(function (row) {
            if (row.type === 'product') {
                bill_entries.push({
                    id: row.id || id--,
                    description: row.name,
                    vat: row.vat,
                    quantity: row.amount,
                    value: row.unit_price,
                    product_register_id: row.product_register_id,
                    total: row.total,
                    deleted: row.deleted,
                });
            }
            else if (row.type === 'cpq') {
                bill_entries.push({
                    id: row.id || id--,
                    description: row.name,
                    vat: row.vat,
                    quantity: row.amount,
                    value: row.unit_price,
                    cpq_id: row.cpq_id,
                    total: row.total,
                    deleted: row.deleted,
                });
            }            
            else if (row.type === 'mileage') {

                const realId = row.id || id--;

                mileages.mileages.push({
                    id: realId,
                    mileage_id: row.subtype.id,
                    distance: row.amount,
                    total: row.total,
                    deleted: row.deleted,
                    accounting_products_id: row.subtype.integration_product
                });

                row.child.forEach(function (c) {
                    mileages.additional.push({
                        id: c.id || id--,
                        parent: realId,
                        additional_id: c.subtype.id,
                        distance: c.amount,
                        total: c.total,
                        deleted: c.deleted,
                        accounting_products_id: c.subtype.integration_product
                    });
                });
            }
            else if (row.type === 'daily') {
                dailies.push({
                    id: row.id || id--,
                    daily_id: row.subtype.id,
                    days: row.amount,
                    total: row.total,
                    deleted: row.deleted,
                    accounting_products_id: row.subtype.integration_product
                });
            }
            else if (row.type === 'invoiceable') {
                bill_entries.push({
                    id: row.id || id--,
                    quantity: row.amount,
                    value: row.unit_price,
                    description: row.name,
                    total: row.total,
                    deleted: row.deleted,
                });
            }
            else if (row.type === 'other') {
                others.push({
                    id: row.id || id--,
                    description: row.name,
                    total_no_vat: row.total_no_vat,
                    vat: row.vat,
                    total: row.total,
                    deleted: row.deleted,
                    purchase_expense_type_id:row.subtype.id,
                });
            }
        });

        data.bill_entries = bill_entries;
        data.bill_entries_products = bill_entries_products;
        data.mileages = mileages;
        data.dailies = dailies;
        data.others = others;
    }

    return DataHandler.post({ url: `timetracker/dialog` }, data);
}
