import { AddCircleOutline, CalendarToday, ChatOutlined, Delete, DescriptionOutlined, Edit, Filter2, NotificationsOutlined, SettingsOutlined, Timer } from '@mui/icons-material';
import ReactDOM from 'react-dom';
import { cloneDeep } from 'lodash';
import React from 'react';
import DataHandler from '../general/DataHandler';
import TaimerWizard, { TaimerWizardPage } from '../general/TaimerWizard';
import WorkhourTimer from '../navigation/WorkhourTimer';
import TaimerComponent from '../TaimerComponent';
import styles from './HoursWizard.module.scss';
import OutlinedField from './OutlinedField';
import Lottie from 'react-lottie';
import { addHours } from 'date-fns';
import CalendarView from '../workhours/time-tracker/CalendarView';
import { Calendar } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import BulkEntry from '../workhours/time-tracker/BulkEntry';
import wizardStyles from './TaimerWizard.module.scss';
import { ActionMenuButton, ActionsMenu } from '../navigation/ActionMenu';
import naviStyles from '../navigation/TaimerNavi.module.scss';
import { formatInputNumber } from '../helpers';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
const click = require('./animations/clickAnimation.json');
const check = require('./animations/checkAnimation.json');
const DnDCalendar = withDragAndDrop(Calendar, { backend: false });


import { ReactComponent as JobtypesIcon } from './icons/jobtypes.svg';
import { ReactComponent as StopwatchIcon } from './icons/stopwatch.svg';
import { ReactComponent as CalendarIcon } from './icons/calendarView.svg';
import { ReactComponent as BulkIcon } from './icons/bulkEntry.svg';

interface HoursWizardProps {
    sliderMode?: boolean;
}

interface HoursWizardState {
    jobtypes: any[];
    deletedJobtypes: any[];
    loadingJobtypes?: boolean;
    timerDone?: boolean;
    calendarDone?: boolean;
    bulkDone?: boolean;
    createdBulkEntries: number;
    calendarAnimationLeft: number;
    entries: any[];
    currency: string;
}

class HoursWizard extends TaimerComponent<HoursWizardProps, HoursWizardState> {
    pages: TaimerWizardPage[] = [];
    wizard: any = React.createRef();
    workhourTimer: any = {
        start: addHours(new Date(), -1),
    };
    neededBulkEntries = 2;
    topBarActions = ['add', 'separator', 'notes', 'timer', 'separator', 'notifications', 'dev', 'settings', 'separator'];
    constructor(props, context) {
        super(props, context, 'general/HoursWizard');
        this.pages = [
            ...(this.context.functions.isUserOwner()
                ? [
                      {
                          title: this.tr('Manage jobtypes'),
                          content: {
                              main: this.renderJobtypeEditor,
                              right: {
                                  image: <JobtypesIcon />,
                                  header: this.tr('Jobtypes for billing hours'),
                                  description: this.htmlTr(
                                      'Use jobtypes to define rates when tracking hours.${linebreak}We added a few jobtypes to get you started. Edit the rates and add more types according to your needs.',
                                      { linebreak: [<br />, <br />] }
                                  ),
                              },
                          },
                          actions: [
                              {
                                  label: this.tr('Next'),
                                  action: this.afterJobtypeEdits,
                                  setLoading: true,
                                  disabled: () => this.state.jobtypes?.length == 0 || this.state.jobtypes?.findIndex((jt) => !!jt.name && Number.isFinite(Number(jt.cost))) == -1,
                              },
                          ],
                      },
                  ]
                : []),
            {
                title: this.tr('Stopwatch'),
                content: {
                    main: this.renderTimer,
                    right: this.renderTimerExplainer,
                },
                actions: [
                    {
                        label: this.tr('Next'),
                        action: 'next',
                        visible: () => this.state.timerDone || false,
                    },
                ],
            },
            {
                title: this.tr('Calendar view'),
                content: {
                    main: this.renderCalendar,
                    right: this.renderCalendarExplainer,
                },
                actions: [
                    {
                        label: this.tr('Next'),
                        action: 'next',
                        visible: () => this.state.calendarDone || false,
                    },
                ],
            },
            {
                title: this.tr('Bulk entry'),
                content: {
                    main: this.renderBulk,
                    right: this.renderBulkExplainer,
                },
                actions: [
                    {
                        label: this.tr('Done'),
                        action: () => { return 'close'; },
                        visible: () => this.state.bulkDone || false,
                    },
                ],
            },
        ];
        this.state = {
            jobtypes: [],
            deletedJobtypes: [],
            createdBulkEntries: 0,
            calendarAnimationLeft: 80,
            entries: [],
            currency: this.context.taimerAccount.currency,
        };
    }

    componentDidMount(): void {
        super.componentDidMount();
        if (this.context.functions.isUserOwner()) {
            this.getJobtypes();
        }
    }

    getJobtypes = () => {
        this.setState({ loadingJobtypes: true }, async () => {
            const response = await DataHandler.get({ url: `settings/company/${this.context.userObject.companies_id}/jobtype/active` });
            this.setState({ jobtypes: response.worktasks, loadingJobtypes: false });
        });
    };

    saveJobtypeEdit = async (jobtype) => {
        const response = await DataHandler.put({ url: `settings/company/${this.context.userObject.companies_id}/jobtype` }, jobtype);
        return response;
    };

    saveNewJobtype = async (jobtype) => {
        const response = await DataHandler.post({ url: `settings/company/${this.context.userObject.companies_id}/jobtype` }, jobtype);
        return response;
    };

    onEditJobtype = (id, { target: { name, value } }) => {
        const jobtypes = cloneDeep(this.state.jobtypes);
        const index = jobtypes.findIndex((jt) => jt.id == id);
        if (index != -1) {
            const formattedValue = name == 'cost' ? formatInputNumber(value, 'no-format') : value;
            const jobtype = {
                ...jobtypes[index],
                [name]: formattedValue,
                editedInWizard: true,
            };
            jobtypes[index] = jobtype;
            this.setState({ jobtypes });
        }
    };

    onDeleteJobtype = (id) => {
        const jobtypes = cloneDeep(this.state.jobtypes);
        const index = jobtypes.findIndex((jt) => jt.id == id);
        if (index != -1) {
            const jobtype = jobtypes[index];
            jobtypes.splice(index, 1);
            const deletedJobtypes = cloneDeep(this.state.deletedJobtypes);
            if (Number(id) > 0 && deletedJobtypes.findIndex((dj) => dj.id == id) == -1) {
                deletedJobtypes.push(jobtype);
            }
            this.setState({ jobtypes, deletedJobtypes });
        }
    };

    onAddJobtype = () => {
        const jobtypes = cloneDeep(this.state.jobtypes);
        jobtypes.push({ id: -(jobtypes.length + 1), name: '', active: '1', deleted: '0' });
        this.setState({ jobtypes });
    };

    saveJobtypes = async () => {
        const promises: any = [];
        this.state.jobtypes.forEach((jt) => {
            if (Number.isFinite(Number(jt.cost))) {
                if (Number(jt.id) <= 0) {
                    promises.push(this.saveNewJobtype(jt));
                } else if (jt.editedInWizard) {
                    promises.push(this.saveJobtypeEdit(jt));
                }
            }
        });
        const responses = await Promise.all(promises);
        return responses;
    };

    deleteJobtypes = async () => {
        const promises: any = [];
        this.state.deletedJobtypes.forEach((dj) => {
            promises.push(this.saveJobtypeEdit({ ...dj, deleted: '1', active: '0' }));
        });
        const responses = await Promise.all(promises);
        return responses;
    };

    afterJobtypeEdits: () => Promise<'next'> = () => {
        return new Promise((resolve) => {
            this.saveJobtypes().then(() => {
                this.deleteJobtypes().then(() => {
                    resolve('next');
                });
            });
        });
    };

    afterBulkEntryCreated = () => {
        const createdBulkEntries = this.state.createdBulkEntries + 1;
        const bulkDone = createdBulkEntries >= this.neededBulkEntries;
        this.setState({ createdBulkEntries, bulkDone });
    };

    afterTimerEntryCreated = () => {
        this.setState({ timerDone: true });
    };

    afterCalendarEntryCreated = (entry) => {
        const entries = [...this.state.entries];
        entries.push(entry);
        this.setState({ calendarDone: true, entries }, () => {
            this.removeCalendarDragAnimation();
        });
    };

    onCreateCalendarEntry = (entry) => {
        if (entry.project) return;
        this.context.functions.addHours(entry, { demoMode: true, onEntryChanged: this.afterCalendarEntryCreated });
    };

    onCreateTimerEntry = () => {
        if (this.state.timerDone) {
            this.workhourTimer = {
                start: addHours(new Date(), -1),
            };
            this.setState({ timerDone: false });
            return;
        }
        this.context.functions.addHours({ start: this.workhourTimer.start, end: new Date() }, { demoMode: true, onEntryChanged: this.afterTimerEntryCreated });
    };

    removeCalendarDragAnimation = () => {
        const calToday = document.getElementsByClassName(styles.content)[0]?.getElementsByClassName('rbc-time-column rbc-today')[0];
        if (calToday) {
            const oldAnimation = calToday.getElementsByClassName(styles.dragAnimationContainer)[0];
            if (oldAnimation) {
                calToday.removeChild(oldAnimation);
            }
        }
    };

    onPageChanged = (page) => {
        if (this.context.functions.isUserOwner() && page == 1) {
            this.getJobtypes();
        }
        this.setState(
            {
                timerDone: false,
                calendarDone: false,
                bulkDone: false,
                createdBulkEntries: 0,
                entries: [],
            },
            () => {
                const calToday = document.getElementsByClassName(styles.content)[0]?.getElementsByClassName('rbc-time-column rbc-today')[0];
                if (calToday) {
                    const animationContainer = document.createElement('div');
                    animationContainer.classList.add(styles.dragAnimationContainer);
                    const oldAnimation = calToday.getElementsByClassName(styles.dragAnimationContainer)[0];
                    if (oldAnimation) {
                        calToday.removeChild(oldAnimation);
                    }
                    calToday.appendChild(animationContainer);
                    ReactDOM.render(
                        <div className={styles.dragAnimation}>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: click,
                                }}
                                speed={0.5}
                                height={200}
                                width={200}
                            />
                        </div>,
                        animationContainer
                    );
                }
            }
        );
    };

    getDemoProjects = () => {
        return [
            {
                key: 'demo_project_1',
                can_add_hours: true,
                id: 1,
                customer: {
                    id: 1,
                    name: 'Demo account 1',
                },
                name: 'Demo project 1',
                jobtype: {
                    id: 1,
                    name: 'Demo jobtype 1',
                },
                description: 'Default description',
            },
        ];
    };

    renderBulkExplainer = () => {
        const { bulkDone } = this.state;
        if (bulkDone) {
            const content: any = {
                animation: check,
                header: this.tr('Great work!'),
                description: this.tr("You just created multiple hour entries with the bulk entry tool. Going forward, you'll find bulk entry in My Day and in the Time Management view."),
            };
            return content;
        }
        return {
            image: <BulkIcon />,
            header: this.tr('Bulk entry'),
            description: this.htmlTr('With the bulk time entry tool, you can track hours to multiple projects at once for the whole week.', { linebreak: [<br />, <br />] }),
            showTestModeIndicator: true,
        };
    };

    renderBulk = () => {
        const { createdBulkEntries, bulkDone } = this.state;
        return (
            <div className={styles.content}>
                <div>
                    <div className={wizardStyles.box}>
                        <div className={wizardStyles.title}>
                            <div className={wizardStyles.icon}>
                                <Filter2 />
                            </div>
                            <h2>{this.tr('Add two hour entries by filling hours into the boxes')}</h2>
                        </div>
                        <BulkEntry
                            demoMode={true}
                            selectedDays={[1, 2, 3, 4, 5]}
                            colorsToUse={[]}
                            sidebarProjects={[]}
                            onSidebarAddProject={() => {}}
                            onSidebarRemoveProject={() => {}}
                            onSidebarProjectsChanged={() => {}}
                            onChangeUser={() => {}}
                            onChangeColor={() => {}}
                            onRequestData={() => {}}
                            listHeight={400}
                            events={[]}
                            workdays={[]}
                            demoProjects={this.getDemoProjects()}
                            onEntryCreated={this.afterBulkEntryCreated}
                            animationIndex={bulkDone ? undefined : Math.min(createdBulkEntries, 1)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderCalendarExplainer = () => {
        const { calendarDone } = this.state;
        if (calendarDone) {
            const content: any = {
                animation: check,
                header: this.tr('Great work!'),
                description: this.tr("You just created an hour entry with the hour calendar. Going forward, you'll find this calendar in My Day and in the Time Management view."),
            };
            return content;
        }
        return {
            image: <CalendarIcon />,
            header: this.tr('Calendar view'),
            description: this.htmlTr('Use the calendar to get a total overview of your hours.${linebreak}Easily create an entry by drawing it on the calendar.', { linebreak: [<br />, <br />] }),
            showTestModeIndicator: true,
        };
    };

    renderCalendar = () => {
        const { entries } = this.state;
        return (
            <div className={styles.content}>
                <div>
                    <div className={wizardStyles.box}>
                        <div className={wizardStyles.title}>
                            <div className={wizardStyles.icon}>
                                <CalendarToday />
                            </div>
                            <h2>{this.tr('Hold and drag to draw an hour entry')}</h2>
                        </div>
                        <CalendarView
                            onAddEvent={() => {}}
                            events={entries}
                            sidebarProjects={[]}
                            onSidebarProjectsChanged={() => {}}
                            onSidebarAddProject={() => {}}
                            onSidebarRemoveProject={() => {}}
                            sidebarUsers={[]}
                            onSidebarUsersChanged={() => {}}
                            onRequestData={() => {}}
                            calendarType={DnDCalendar}
                            onSaveEvent={() => {}}
                            onChangeColorProject={() => {}}
                            noSidebar={true}
                            workdays={[]}
                            openEditSlider={this.onCreateCalendarEntry}
                            colorsToUse="project"
                            demoMode
                            parentComponent="hours wizard"
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderTimerExplainer = () => {
        if (this.state.timerDone) {
            const content: any = {
                animation: check,
                header: this.tr('Great work!'),
                description: this.tr("You just created an hour entry with the stopwatch. Going forward, you'll find it in the top navigation bar."),
            };
            return content;
        }
        return {
            image: <StopwatchIcon />,
            header: this.tr('Stopwatch'),
            description: this.htmlTr(
                "Easily track time spent on tasks by using the stopwatch.${linebreak}It's easily accessible throughout the software, and can be found in the top navigation bar.",
                {
                    linebreak: [<br />, <br />],
                }
            ),
            showTestModeIndicator: true,
        };
    };

    renderTopBarAction = (item) => {
        const { timerDone } = this.state;

        switch (item) {
            case 'add':
                return (
                    <ActionMenuButton
                        testid="add-new-menu"
                        className={naviStyles.addNewMenu}
                        tooltip={''}
                        noIcon
                        src="actions"
                        desc={<AddCircleOutline />}
                        menuContent={<ActionsMenu menuClicked={() => {}} />}
                    />
                );
            case 'separator':
                return <span className={naviStyles.separator} />;
            case 'notes':
                return (
                    <div className={naviStyles.actionButton} onClick={() => {}}>
                        <DescriptionOutlined />
                    </div>
                );
            case 'timer':
                return (
                    <WorkhourTimer
                        timer={timerDone ? undefined : this.workhourTimer}
                        tooltip=""
                        onClick={this.onCreateTimerEntry}
                        animation={
                            timerDone ? undefined : (
                                <div className={styles.animation}>
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: click,
                                        }}
                                        speed={0.7}
                                        height={200}
                                        width={200}
                                    />
                                </div>
                            )
                        }
                    />
                );
            case 'teamchat':
                return (
                    <div className={naviStyles.actionButton} id="headerNewsfeed" onClick={() => {}}>
                        <ChatOutlined />
                    </div>
                );
            case 'notifications':
                return (
                    <div className={naviStyles.actionButton} id="headerNotificationFeed" onClick={() => {}}>
                        <NotificationsOutlined />
                    </div>
                );
            case 'settings':
                return (
                    <div data-testid="navi-settings" className={`${naviStyles.actionButton} `} onClick={() => {}}>
                        <SettingsOutlined />
                    </div>
                );
        }
    };

    renderTimer = () => {
        return (
            <div className={styles.content} style={{ width: 700 }}>
                <div>
                    <div className={wizardStyles.box}>
                        <div className={wizardStyles.title}>
                            <div className={wizardStyles.icon}>
                                <Timer />
                            </div>
                            <h2>{this.tr('Click the timer to stop it and create an hour entry')}</h2>
                        </div>
                        <div className={styles.topBarActions}>{this.topBarActions.map(this.renderTopBarAction)}</div>
                    </div>
                </div>
            </div>
        );
    };

    renderJobtypeEditor = () => {
        const { jobtypes, loadingJobtypes, currency } = this.state;
        return (
            <div className={`${styles.content}`} style={{ width: 700 }}>
                <div>
                    <div className={wizardStyles.box}>
                        <div className={wizardStyles.title}>
                            <div className={wizardStyles.icon}>
                                <Edit />
                            </div>
                            <h2>{this.tr('Edit jobtypes and their rates to suit your company')}</h2>
                        </div>
                        {loadingJobtypes ? (
                            <Loading className={styles.loader} />
                        ) : (
                            jobtypes.map((jt) => {
                                return (
                                    <div key={jt.id} className={styles.row}>
                                        <OutlinedField label={this.tr('Name')} name="name" value={jt.name} onChange={(e) => this.onEditJobtype(jt.id, e)} />
                                        <OutlinedField
                                            label={this.tr('Hourly rate')}
                                            name="cost"
                                            value={jt.cost}
                                            format="currency"
                                            currency={currency}
                                            validation={['numeric']}
                                            onChange={(e) => this.onEditJobtype(jt.id, e)}
                                        />
                                        <button onClick={() => this.onDeleteJobtype(jt.id)}>
                                            <Delete />
                                        </button>
                                    </div>
                                );
                            })
                        )}
                        {!loadingJobtypes && (
                            <button className={wizardStyles.secondaryAction} onClick={this.onAddJobtype}>
                                <AddCircleOutline />
                                {this.tr('Add jobtype')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    showCloseConfirmation = () => {
        return this.wizard.current && this.wizard.current.getCurrentPage() == this.pages.length && !this.state.bulkDone; // If in last page and bulk is not done.
    }

    onClose = (currentPage) => {
        if (currentPage == this.pages.length && this.state.bulkDone) {
            this.context.functions.sendMixpanelEvent('track_hours_wizard_completed');
            this.context.functions.sendMixpanelPeople('set', {
                "track_hours_wizard_end_date": new Date().toISOString(),
            });
            this.context.functions.onOnboardingItemCompleted('hours', this.props.sliderMode);
        }
    };

    render() {
        return (
            <TaimerWizard
                ref={this.wizard}
                title={this.context.functions.isUserOwner() ? this.tr('Setup & test the time tracker') : this.tr('Learn how to track hours')}
                pages={this.pages}
                updateTriggers={{ jobtypes: this.state.jobtypes }}
                onPageChanged={this.onPageChanged}
                showCloseConfirmation={this.showCloseConfirmation}
                onClose={(currentPage) => this.onClose(currentPage)}
            />
        );
    }
}

export default HoursWizard;
