import React from 'react';
import './TabQuotes.css';
import { SettingsContext } from './../SettingsContext';

/* material-ui */
import Switch from '@mui/material/Switch';
import ThreeDotsIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem }  from '@mui/material';
import { withSnackbar } from 'notistack';

import TaimerComponent from "../TaimerComponent";
import TextInputCell from '../list/cells/TextInputCell';
import CurrencyListCell from '../list/CurrencyListCell';
import ContextMenu from '../general/ContextMenu';
import { formatInputNumber } from '../helpers';

import { ReactComponent as CostTargetingImage } from './images/cost_targeting.svg';
import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';
import PrintIcon from '@mui/icons-material/Print';

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

class CostTargeting extends TaimerComponent {
    static defaultProps = {
	    currency: "EUR"
    };
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "projects/CostTargeting");

        this.state = {
            headers: undefined 
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state); 
    }

    setData = (data) => {
        if(data.headers && (this.state.headers === undefined || !isEqual(data.headers, this.state.headers))) {
            this.setState({ headers: data.headers });
        }
    }

    countSums = (h) => {
        let unitSum = 0;
        let targetSum = 0;

        if(h.has_automatic_targeting > 0 || h.targeted > 0) {
            targetSum += h.targeted - 0;
        }

        h.rows.forEach(r => {
            unitSum += (r.cost * r.quantity);
            targetSum += r.targeted - 0
        });

        let margin = ((targetSum / unitSum) * 100).toFixed(2);
        let color = (margin <= 100) ? "green" : "red";

        return (
            <React.Fragment>
                <div className="c1">
                    {Intl.NumberFormat(this.context.taimerAccount.numberFormat, {style: 'currency', currency: this.props.currency}).format(isNaN(unitSum) ? 0 : unitSum)}
                </div>
                <div className="c2">
                    {Intl.NumberFormat(this.context.taimerAccount.numberFormat, {style: 'currency', currency: this.props.currency}).format(isNaN(targetSum) ? 0 : targetSum)}
                </div>
                <div className={"c3 " + color}>
                    {isNaN(margin) || margin == "Infinity" ? '-' : margin} %
                </div>
            </React.Fragment>
        );
    }

    countTotals = (data) => {
        let unitSum = 0;
        let sumTotal = 0;
        let salesTotal = 0;
        let targetSum = 0;
        let targetWithoutOwnWorkSum = 0;
        let marginTotal = 0;
        
	    if(data && data.headers) {
            data.headers.forEach(h => {
                if(h.has_automatic_targeting > 0 || h.targeted > 0) {
                    targetSum += h.targeted - 0;
                    targetWithoutOwnWorkSum += h.targeted - 0;
                }
            	h.rows.forEach(r => {
                    unitSum += r.cost * r.quantity;
                    sumTotal += r.value * r.quantity;
            	    salesTotal += (r.value * r.quantity - (r.quantity * r.value * r.discountPercentage / 100)) - (r.cost * r.quantity);
                    targetSum += r.targeted - 0;
                    targetWithoutOwnWorkSum += r.workType != 1 ? r.targeted - 0 : 0;
                    marginTotal += (r.value * r.quantity) - (r.workType != 1 ? r.cost * r.quantity : 0);
	            })
            })
	    }
        
        //let invoiced = this.props.project.invoicing && this.props.project.invoicing.invoiced ? this.props.project.invoicing.invoiced : 0;

        if (isNaN(unitSum)) unitSum = 0;
        if (isNaN(sumTotal)) sumTotal = 0;
        if (isNaN(salesTotal)) salesTotal = 0;
        if (isNaN(targetSum)) targetSum = 0;
        if (isNaN(targetWithoutOwnWorkSum)) targetWithoutOwnWorkSum = 0;
        if (isNaN(marginTotal)) marginTotal = 0;

        return {
            costs: {
                cost: unitSum,
                target: targetSum,
                margin: ((targetSum / unitSum) * 100).toFixed(2)
            },
            profits: {
                budgeted: sumTotal - unitSum,
                actual: sumTotal - targetSum,
                margin: (((sumTotal - targetSum) / (sumTotal - unitSum)) * 100).toFixed(2)
            },
            gross: {
                budgeted: marginTotal,
                actual: sumTotal - targetWithoutOwnWorkSum,
                margin: ((sumTotal - targetWithoutOwnWorkSum) / marginTotal * 100).toFixed(2)
            }
        };
    }

    showProposalEditor = (quote) => {
        const { editMode, handleQuoteIdChange } = this.props;
        if(editMode) {
            this.props.enqueueSnackbar(this.tr("Please save or cancel your current quote before switching to another one."), {
                preventDuplicate: true,
                variant: "warning"
            });

            return; 
        }
        handleQuoteIdChange(quote.id, true);
    }

    render = () => {
        if(!this.state.headers) {
            return null; 
        }

        const totals       = this.countTotals({ headers: this.state.headers });
        const { quotes, currency, showTabs, editable, editMode, selectedQuoteId } = this.props;

        return (
            <React.Fragment>

                <div className="box">
                    <h1>{this.tr('Quote versions')}</h1>

                    <div className={`list quotes ${showTabs || 'no-tabs'}`}>
                        <div className="list-header quoteSelections">
                            <div className="c1">{this.tr('Active')}</div>
                            <div className="c2">{this.tr('Version')}</div>
                            <div className="c3"></div>
                        </div>
                        <div className="content">
                            {quotes.map(c => (
                                <div className={"row" + (c.id == selectedQuoteId ? " selected" : "")}>
                                    <div className="c1 noJustify">
                                        <Switch
                                            disabled={!editable}
                                            classes={{root: 'switch-root', switchBase: 'switch'}}
                                            checked={c.active == 1}
                                            onChange={(e, checked) => this.props.handleActiveChange(c, checked)}
                                            color="primary" />
                                    </div>
                                    <div className="c2 noJustify">
                                    <div
                                        className={(c.id == selectedQuoteId ? " selected" : "")}
                                        onClick={() => {
                                            if(editMode) {
                                                this.props.enqueueSnackbar(this.tr("Please save or cancel your current quote before switching to another one."), {
                                                    preventDuplicate: true,
                                                    variant: "warning"
                                                });

                                                return; 
                                            }
                                            this.props.handleQuoteIdChange(c.id);
                                        }}>
                                        {c.name != "" ? c.name : this.tr("Nameless Quote")}
                                    </div>
                                    {!editMode && (this.props.noProposalAndWriteRight(c) || this.props.hasProposalAndReadOrWriteRight(c)) && <div className="proposal" onClick={() => this.showProposalEditor(c)}>
                                        {c.type == 1 && (c.proposals_id && c.proposals_id != '-1' ? this.tr("View Proposal") : this.tr("Create Proposal"))}
                                    </div>}
                                    {c.type == 2 && <div className='refund-quote'>{this.tr("Refund quote")}</div>}
                                    </div>
                                    <div className="c3 noJustify">
                                    {editable && <ContextMenu label={<ThreeDotsIcon />} disablePortal={false} buttonProps={{className: 'quote-list-action'}} popperProps={{placement: 'left-end'}} className="cell row-menu" noExpandIcon>
                                        <MenuItem onClick={() => this.props.printQuote(c.id)}><PrintIcon className="cost-targeting-action-icon" />{this.tr('Print quote')}</MenuItem>
                                        <MenuItem className="delete" onClick={() => this.props.handleQuoteDelete(c.id)}><RemoveIcon className="cost-targeting-action-icon delete" />{this.tr('Delete quote')}</MenuItem>
                                    </ContextMenu>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {false && <div className="box targeting-info" /*style={{transformOrigin: "top left", transform: `scale(${this.props.scale})`}}*/>
                    <CostTargetingImage />
                    <p>{this.tr('Real-time cost targeting and sales quote performance report.')}</p>
                </div>}
                <div className="box" style={{ /*marginTop: this.props.offset - 764 + "px", transformOrigin: "top left", transform: `scale(${this.props.scale})`*/ width: "100%", position: "absolute", marginTop: "522px" }}>
                    <h1>{this.tr('Cost targeting')}</h1>
                    <div className="list targets">
                        <div className="list-header">
                            <div className="c1">{this.tr('Unit cost')}</div>
                            <div className="c2">{this.tr('Targeted cost')}</div>
                            <div className="c3"></div>
                        </div>
                        <div className="content">
                            {this.state.headers && this.state.headers.map(h => {
                                return(
                                    <React.Fragment>
                                    <div className="row">
                                    {
                                        (h.targeted > 0 || h.has_automatic_targeting > 0) && (
                                            <React.Fragment>
                                                <div className="c1"></div>
                                                <div className="c2">
                                                    <TextInputCell
                                                        name="name"
                                                        useClickAwayListener={false}
                                                        value={formatInputNumber(h.targeted)}
                                                        editable={false}
                                                        listCellType={CurrencyListCell}
                                                        listCellProps={{
                                                            inEditMode: false,
                                                            noInitFocus: true,
                                                            currency: currency
                                                        }}
                                                    />
                                                </div>
                                                <div className="c3"></div>
                                            </React.Fragment>
                                        )
                                    }
                                    </div>
                                    {h.rows.map(r => (
                                        <div className="row">
                                            {((r.type == 1) || (r.type == 3) || (r.type == 4)) && (
                                                <React.Fragment>
                                                    <div className="c1">
                                                        <div>{Intl.NumberFormat(
                                                            this.context.taimerAccount.numberFormat,
                                                            {style: 'currency', currency: currency}).format(r.cost * r.quantity)}
                                                        </div>
                                                    </div>
                                                    <div className="c2">
                                                        <TextInputCell
                                                            name="name"
                                                            useClickAwayListener={false}
                                                            value={formatInputNumber(r.targeted)}
                                                            validation={["numeric"]}
                                                            editable={this.props.editMode && r.has_automatic_targeting < 1}
                                                            listCellType={CurrencyListCell}
                                                            listCellProps={{
                                                                inEditMode: this.props.editMode && r.has_automatic_targeting < 1,
                                                                currency: currency,
                                                                noInitFocus: true
                                                            }}
                                                            onEdited={(name, value) => {
                                                                this.props.onChange(value, this.props.data.id, h.id, r.id);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="c3"></div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    ))}
                                    <div className="row total">
                                        {this.countSums(h)}
                                    </div>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                    <div className="list summary">
                        <div className="list-header">
                            <div className="c1">{this.tr('Budgeted cost')}</div>
                            <div className="c2">{this.tr('Actual cost')}</div>
                            <div className="c3"></div>
                        </div>
                        <div className="content">
                            <div className="row total">
                                <div className="c1">
                                    {Intl.NumberFormat(this.context.taimerAccount.numberFormat, {style: 'currency', currency: currency}).format(isNaN(totals.costs.cost) ? 0 : totals.costs.cost)}
                                </div>
                                <div className="c2">
                                    {Intl.NumberFormat(this.context.taimerAccount.numberFormat, {style: 'currency', currency: currency}).format(isNaN(totals.costs.target) ? 0 : totals.costs.target)}
                                </div>
                                <div className={totals.costs.margin <= 100 ? "c3 green" : "c3 red"}>
                                    {isNaN(totals.costs.margin) || totals.costs.margin == "Infinity" ? '-' : totals.costs.margin} %
                                </div>
                            </div>
                        </div>

                        <div className="list-header">
                            <div className="c1">{this.tr('Budgeted profit')}</div>
                            <div className="c2">{this.tr('Actual profit')}</div>
                            <div className="c3"></div>
                        </div>
                        <div className="content">
                            <div className="row total">
                                <div className="c1">
                                    {Intl.NumberFormat(this.context.taimerAccount.numberFormat, {style: 'currency', currency: currency}).format(totals.profits.budgeted)}
                                </div>
                                <div className="c2">
                                    {Intl.NumberFormat(this.context.taimerAccount.numberFormat, {style: 'currency', currency: currency}).format(totals.profits.actual)}
                                </div>
                                <div className="c3" className={totals.profits.margin >= 100 ? "c3 green" : "c3 red"}>
                                    {isNaN(totals.profits.margin) || totals.profits.margin.indexOf("Infinity") > -1 ? '-' : totals.profits.margin} %
                                </div>
                            </div>
                        </div>

                        <div className="list-header">
                            <div className="c1">{this.tr('Budgeted gross margin')}</div>
                            <div className="c2">{this.tr('Actual gross margin')}</div>
                            <div className="c3"></div>
                        </div>
                        <div className="content">
                            <div className="row total">
                                <div className="c1">
                                    {Intl.NumberFormat(this.context.taimerAccount.numberFormat, {style: 'currency', currency: currency}).format(isNaN(totals.gross.budgeted) ? 0 : totals.gross.budgeted)}
                                </div>
                                <div className="c2">
                                    {Intl.NumberFormat(this.context.taimerAccount.numberFormat, {style: 'currency', currency: currency}).format(isNaN(totals.gross.actual) ? 0 : totals.gross.actual)}
                                </div>
                                <div className={totals.gross.margin >= 100 ? "c3 green" : "c3 red"}>
                                    {isNaN(totals.gross.margin) || totals.gross.margin.indexOf("Infinity") > -1 ? '-' : totals.gross.margin} %
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

CostTargeting.defaultProps = {
    data: []
}

export default withSnackbar(CostTargeting);
