import moment from 'moment';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
import React from 'react';
import { Bar } from 'react-chartjs-2';

import TaimerComponent from '../TaimerComponent';
import colors from '../colors';
import CoreDialog from './../dialogs/mass_operations/CoreDialog';
import Utils from "./../general/Utils";
import RevenueRecognitionTable from './RevenueRecognitionTable';

import { Modal } from '@mui/material';
import InsightChartOptions from '../dashboard/insights/InsightChartOptions';
import DataHandler from '../general/DataHandler';
import ConfirmationPopUp from '../resourcing/views/ResourcingGrid/components/ConfirmationPopUp';
import './RevenueRecognition.css';

import { ScrollSync } from 'react-virtualized';

class RevenueRecognition extends TaimerComponent {

    static defaultProps = {showLockedUsersWithTag: true}

    constructor(props, context) {
        super(props, context, 'projects/RevenueRecognition');

        this._monthTitles = {
            1: this.tr('Jan'),
            2: this.tr('Feb'),
            3: this.tr('Mar'),
            4: this.tr('Apr'),
            5: this.tr('May'),
            6: this.tr('Jun'),
            7: this.tr('Jul'),
            8: this.tr('Aug'),
            9: this.tr('Sep'),
            10: this.tr('Oct'),
            11: this.tr('Nov'),
            12: this.tr('Dec'),
        };

        this._revenueRecognition = React.createRef();
        this._scheduledInvoicing = React.createRef();

        this.hoursViewModes = [
            {
                key: 'selling_price',
                label: this.tr('Selling price'),
                action: () => this.setState({ selectedHoursViewMode: 'selling_price' }),
            },
            {
                key: 'own_cost',
                label: this.tr('Own cost'),
                action: () => this.setState({ selectedHoursViewMode: 'own_cost' }),
            },
        ];

        this.translations = {
           locked: this.tr("locked"),
           freelancer: this.tr("freelancer")
        };        

        this.state = {
            months: this._getMonths(),
            projectMonths: this._getProjectMonths(),
            graphData: {},
            hiddenIndexes: [],
            users: [],
            teams: [],
            confirmationDialogData: { open: false },
            selectedHoursViewMode: 'selling_price',
            settings: {
                revenue_recognition_sync_scheduled_invoices: 0,
                revenue_recognition_equal_project_value: 0,
                revenue_recognition_month_lock_day: 1,
                revenue_recognition_month_lock_user_splits: 0,
                revenue_recognition_bills_by_date: 1,
                revenue_recognition_expenses_by_date: 1,
                revenue_recognition_purchase_orders_by_date: 1,
            },
            lockDate: moment(),
        };

        this.lastScrollLeft = 0;
    }

    checkMonthLock = (month, unlockMonths) => {
        const { lockDate, settings } = this.state;
        const { endDate } = this.props;
        let locked = false;
        const endDateMonthLastDate = moment(endDate).endOf('month').format('YYYY-MM-DD');

        if (settings.revenue_recognition_month_lock_day > 0 && !unlockMonths) {
            const now = moment(new Date(), 'YYYY-MM-DD');
            const monthLastDate = moment(month).endOf('month').format('YYYY-MM-DD');
            const previousMonthLastDate = moment(new Date(), 'YYYY-MM-DD').subtract(1,'months').endOf('month').format('YYYY-MM-DD');
            locked = (monthLastDate < previousMonthLastDate) || (monthLastDate === previousMonthLastDate && lockDate <= now) || moment(month, 'YYYY-MM-DD') > moment(endDateMonthLastDate, 'YYYY-MM-DD');
        } else {
            locked = moment(month, 'YYYY-MM-DD') > moment(endDateMonthLastDate, 'YYYY-MM-DD');
        }
        
        return locked;
    }

    _getTables = () => {
        const { settings, lockDate } = this.state;
        const { endDate } = this.props;
        return [
            {
                fieldKey: 'revenue_recognition',
                title: this.tr('Sales'),
                cellTitle: this.tr('Revenue Recognition'),
                endpoint: `projects/${this.props.id}/revenue_recognition`,
                setRevenueRecognitionData: this.setRevenueRecognitionData,
                showNoteDrawer: true,
                settings,
                lockDate,
                endDate,
                projectMonths: this.state.projectMonths,
                checkMonthLock: this.checkMonthLock,
                setScheduledTotals: this.setScheduledTotals,
                summaries: [
                    {
                        key: 'project_total',
                        title: this.tr('Project Total'),
                        isTotal: true,
                        value: Number(this.props.project.costest_sum || 0),
                    },
                    {
                        key: 'recognised_revenue',
                        title: this.tr('Recognised Revenue'),
                        isTableTotal: true,
                    },
                    { 
                        key: 'difference',
                        title: this.tr('Difference'), 
                        difference: true 
                    },
                ],
                onlyRead: !this.props.project.rights.includes('revenue_recognition_write'),
                hidden: !this.props.project.rights.includes('revenue_recognition_read'),
                dateEditable: true,
                ref: this._revenueRecognition,
            },
            {
                fieldKey: 'revenue_recognition_scheduled',
                useValue: true,
                settings,
                lockDate,
                endDate,
                projectMonths: this.state.projectMonths,
                checkMonthLock: this.checkMonthLock,
                title: this.tr('Scheduled Invoicing'),
                descriptionText: this.tr('Revenue recognition scheduled invoice'),
                cellTitle: this.tr('Scheduled Invoicing'),
                setScheduledInvoiceData: this.setScheduledInvoiceData,
                endpoint: `projects/${this.props.id}/revenue_recognition_scheduled`,
                onlyRead: !this.props.project.rights.includes('project_billing_entries_write'),
                hidden: !this.props.project.rights.includes('project_billing_entries_read'),
                optionsInSplit: true,
                dateEditable: true,
                ref: this._scheduledInvoicing,
            },
            {
                fieldKey: 'revenue_recognition_invoiced',
                useValue: false,
                settings,
                title: this.tr('Invoiced'),
                descriptionText: this.tr('Revenue recognition invoiced'),
                cellTitle: this.tr('Invoiced Total'),
                endpoint: `projects/${this.props.id}/revenue_recognition_invoiced`,
                onlyRead: true,
                hidden: !this.props.project.rights.includes('revenue_recognition_read'),
                dateEditable: false,
                noFooter: true,
                selectedViewMode: 'currency',
                hideViewModes: true,
                invertedTotalCalculation: true,
                useDataMonths: true,
            },
            {
                fieldKey: 'revenue_recognition_actual_costs',
                useValue: false,
                settings,
                title: this.tr('Actual Costs'),
                descriptionText: this.tr('Revenue recognition actual costs'),
                cellTitle: this.tr('Total costs'),
                endpoint: `projects/${this.props.id}/revenue_recognition_actual_costs`,
                onlyRead: true,
                hidden: !this.props.project.rights.includes('revenue_recognition_read'),
                dateEditable: false,
                noFooter: true,
                selectedViewMode: 'currency',
                hideViewModes: true,
                invertedTotalCalculation: true,
                useDataMonths: true,
            },
            {
                fieldKey: 'revenue_recognition_working_hour_costs',
                useValue: false,
                settings,
                title: this.tr('Working Hour Costs'),
                descriptionText: this.tr('Revenue recognition working hour costs'),
                cellTitle: this.tr('Tracked hours'),
                endpoint: `projects/${this.props.id}/revenue_recognition_working_hour_costs`,
                onlyRead: true,
                hidden: !(this.props.project.rights.includes('revenue_recognition_read') && this.props.project.rights.includes("hours_report_read")),
                dateEditable: false,
                noFooter: true,
                selectedViewMode: 'currency',
                hideViewModes: true,
                headerViewMode: 'hours',
                invertedTotalCalculation: true,
                useDataMonths: true,
            },
            {
                fieldKey: 'revenue_recognition_estimated_costs',
                useValue: false,
                settings,
                lockDate,
                endDate,
                projectMonths: this.state.projectMonths,
                checkMonthLock: this.checkMonthLock,
                title: this.tr('Estimated Costs'),
                descriptionText: this.tr('Revenue recognition estimated costs'),
                cellTitle: this.tr('Estimated Costs'),
                endpoint: `projects/${this.props.id}/revenue_recognition_estimated_costs`,
                onlyRead: !this.props.project.rights.includes('revenue_recognition_write'),
                hidden: !this.props.project.rights.includes('revenue_recognition_read'),
                isEstimatedCosts: true,
                dateEditable: false,
                nonEditableHeader: true,
                noFooter: true,
                selectedViewMode: 'currency',
                hideViewModes: true,
                invertedTotalCalculation: true,
            },
        ];
    };

    componentDidMount = () => {
        super.componentDidMount();
        this._getUsers();
        this._getTeams();
        this._getSettings();
    };

    componentDidUpdate = (oldProps) => {
        if (oldProps.startDate != this.props.startDate || oldProps.endDate != this.props.endDate) {
            const months = this._getMonths();
            const projectMonths = this._getProjectMonths();
            this.setState({
                months,
                projectMonths,
            });
        }
    };

    updateRevenueRecognition = () => {
        this._revenueRecognition.current && this._revenueRecognition.current._getData();
    };

    setRevenueRecognitionData = (data, split = false) => {
        this._scheduledInvoicing.current && this._scheduledInvoicing.current.setRevenueRecognitionData(data, split);
    };

    setScheduledInvoiceData = (data) => {
        this._revenueRecognition.current && this._revenueRecognition.current.setScheduledInvoiceData(data);
    };

    _getMonths = () => {
        const months = [];
        const date = moment(this.props.startDate);
        const endDate = this.props.endDate;
        while (date.isSameOrBefore(endDate, 'month') || months.length < 12) {
            months.push(moment(date));
            date.add(1, 'month');
        }
        return months;
    };

    _getProjectMonths = () => {
        const months = [];
        const date = moment(this.props.startDate);
        const endDate = this.props.endDate;
        while (date.isSameOrBefore(endDate, 'month')) {
            months.push(moment(date));
            date.add(1, 'month');
        }
        return months;
    };

    setGraphData = (graphData) => {
        this.setState({ graphData: { ...this.state.graphData, ...graphData } });
    };

    _getSettings = () => {
        DataHandler.get({ url: `settings/company/${this.props.company}/revenue_recognition` }).done((settings) => {
            let lockDate = moment();
            let lockDay = settings.revenue_recognition_month_lock_day;
            let lastDayOfMonth = moment(new Date(), 'YYYY-MM-DD');
            
            if (Number(lockDay) > Number(moment(lastDayOfMonth).endOf('month').format("D"))) {
                lockDay = moment(lastDayOfMonth).endOf('month').format("D");
            }

            this.setState({ settings, lockDate: lockDate.date(lockDay) });
        });
    };

    _getUsers = () => {
        DataHandler.get({
            url: `subjects/employees`
        }).done((users) => {
            const userTagProps = {
                fields: {name: 'name', label: 'label'},
                showLocked: this.props.showLockedUsersWithTag,
                transl: this.translations
            };            
            users.forEach((p, i) => {
                users[i] = ({...Utils.showLockedAndFreelancerUserTag(p, userTagProps)});
            });
            this.setState({ users });
        });
    };

    _getTeams = () => {
        DataHandler.get({ url: `settings/usergroups/${this.props.company}`, type: 'teams' }).done((teams) => {
            this.setState({ teams });
        });
    };

    _getCumulativeArray = (values) => {
        const cumulative = [];
        values.reduce((a, b, i) => {
            return (cumulative[i] = a + b);
        }, 0);
        return cumulative;
    };

    _renderConfirmationPopup = () => {
        const { open, header, desc, leftButtons, rightButtons } = this.state.confirmationDialogData;
        return (
            <Modal open={open}>
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    className="popup-container"
                >
                    <ConfirmationPopUp header={header} desc={desc} leftButtons={leftButtons} rightButtons={rightButtons} />
                </div>
            </Modal>
        );
    };

    openConfirmationDialog = (callback) => {
        this.context.functions.showDialog(
            <CoreDialog
                dialogType={"delete"}
                dialogProps={{
                    closeIconTooltip: this.tr("Cancel"),
                    hideWarningIcon: false,
                    header: this.tr('Are you sure?'),
                    confirmButtonClass: "red",
                    translatedConfirmButtonText: this.tr('Remove row'),
                    cancelButtonText:  this.tr('Cancel'),
                    warning: () => this.tr("Are you sure you want to remove this row? The row's data will be permanently removed."),
                    close: this.context.functions.closeDialog,
                    onCloseClick: this.context.functions.closeDialog,
                    onConfirm: () => {
                        callback && callback();
                        this.context.functions.closeDialog();
                    },
                    onCancel: () => {
                        this.context.functions.closeDialog();
                    },
                }}
            />
        );
    };

    openSyncConfirmationDialog = (yesCallback, noCallback, scheduled = false) => {
        this.context.functions.showDialog(
            <CoreDialog
                dialogType={"delete"}
                dialogProps={{
                    closeIconTooltip: this.tr("Keep editing"),
                    hideWarningIcon: false,
                    header: this.tr('Sync changes'),
                    confirmButtonClass: "blue",
                    translatedConfirmButtonText:  this.tr('Yes, update'),
                    cancelButtonText:  this.tr('No, just save'),
                    warning: () => scheduled ? this.tr('Do you want to save revenue recognition with the same values?') : this.tr('Do you want to save scheduled invoices with the same values?'),
                    close: this.context.functions.closeDialog,
                    onCloseClick: this.context.functions.closeDialog,
                    onConfirm: () => {
                        this.context.functions.closeDialog();
                        yesCallback && yesCallback();
                    },
                    onCancel: () => {
                        this.context.functions.closeDialog();
                        noCallback && noCallback();
                    },
                }}
            />
        );
    };

    _toggleHidden = (index) => {
        const hiddenIndexes = [...this.state.hiddenIndexes];
        const foundIndex = hiddenIndexes.indexOf(index);
        foundIndex == -1 ? hiddenIndexes.push(index) : hiddenIndexes.splice(foundIndex, 1);
        this.setState({ hiddenIndexes });
    };

    _getGraphDataArray = (monthlyValues, useValue = false) => {
        return this.state.months.map((month) => {
            const foundIndex = Object.keys(monthlyValues || {}).findIndex((m) => moment(m, 'YYYY-MM-DD').isSame(month, 'month'));
            if (foundIndex != -1) {
                const key = Object.keys(monthlyValues || {})[foundIndex];
                return Number(useValue ? monthlyValues[key]?.value || 0 : monthlyValues[key] || 0);
            }
            return 0;
        });
    };

    _renderGraph = () => {
        const options = InsightChartOptions;
        const { months, graphData, hiddenIndexes } = this.state;
        const { project } = this.props;
        const {
            functions: { presentCurrency },
        } = this.context;

        const datasets = [
            {
                label: this.tr('Cumulative Revenue Recognition'),
                type: 'line',
                barPercentage: 1,
                borderDash: [15],
                lineTension: 0,
                order: 1,
                data: this._getCumulativeArray(this._getGraphDataArray(graphData.revenue_recognition?.totals)),
                borderWidth: 2.5,
                backgroundColor: 'transparent',
                borderColor: colors.dark_sky_blue,
                pointBorderColor: 'transparent',
                hidden: hiddenIndexes.indexOf(0) != -1,
            },
            {
                label: this.tr('Cumulative Estimated Costs'),
                type: 'line',
                barPercentage: 1,
                borderDash: [15],
                lineTension: 0,
                order: 1,
                data: this._getCumulativeArray(this._getGraphDataArray(graphData.revenue_recognition_estimated_costs?.totals)),
                borderWidth: 2.5,
                backgroundColor: 'transparent',
                borderColor: colors.orangey_yellow,
                pointBorderColor: 'transparent',
                hidden: hiddenIndexes.indexOf(1) != -1,
            },
            {
                label: this.tr('Cumulative Actual Costs'),
                type: 'line',
                barPercentage: 1,
                borderDash: [15],
                lineTension: 0,
                order: 1,
                data: this._getCumulativeArray(this._getGraphDataArray(graphData.revenue_recognition_actual_costs?.totals)),
                borderWidth: 2.5,
                backgroundColor: 'transparent',
                borderColor: colors.carnation_pink,
                pointBorderColor: 'transparent',
                hidden: hiddenIndexes.indexOf(1) != -1,
            },
            {
                label: this.tr('Revenue Recognition'),
                type: 'bar',
                barPercentage: 1,
                lineTension: 0,
                order: 4,
                data: this._getGraphDataArray(graphData.revenue_recognition?.totals),
                backgroundColor: colors.dark_sky_blue,
                borderColor: colors.dark_sky_blue,
                hidden: hiddenIndexes.indexOf(1) != -1,
            },
            {
                label: this.tr('Estimated Costs'),
                type: 'bar',
                barPercentage: 1,
                lineTension: 0,
                order: 4,
                data: this._getGraphDataArray(graphData.revenue_recognition_estimated_costs?.totals),
                backgroundColor: colors.orangey_yellow,
                borderColor: colors.orangey_yellow,
            },
            {
                label: this.tr('Actual Costs'),
                type: 'bar',
                barPercentage: 1,
                lineTension: 0,
                order: 4,
                data: this._getGraphDataArray(graphData.revenue_recognition_actual_costs?.totals),
                backgroundColor: colors.carnation_pink,
                borderColor: colors.carnation_pink,
            },
        ];

        if (this.props.project.rights.includes('project_billing_entries_read')) {
            datasets.splice(1, 0, {
                label: this.tr('Cumulative Scheduled Invoicing'),
                type: 'line',
                barPercentage: 1,
                borderDash: [15],
                lineTension: 0,
                order: 1,
                data: this._getCumulativeArray(this._getGraphDataArray(graphData.revenue_recognition_scheduled?.totals, true)),
                borderWidth: 2.5,
                backgroundColor: 'transparent',
                borderColor: '#7580eb',
                pointBorderColor: 'transparent',
                hidden: hiddenIndexes.indexOf(1) != -1,
            });
            datasets.splice(4, 0, {
                label: this.tr('Scheduled Invoicing'),
                type: 'bar',
                barPercentage: 1,
                lineTension: 0,
                order: 4,
                data: this._getGraphDataArray(graphData.revenue_recognition_scheduled?.totals, true),
                backgroundColor: '#7580eb',
                borderColor: '#7580eb',
            });
        }

        datasets.forEach((dataset, i) => (dataset.hidden = hiddenIndexes.indexOf(i) != -1));

        const data = {
            datasets,
            labels: months.map((m) => this._monthTitles[m.month() + 1] + ' ' + m.format('YYYY')),
        };
        return (
            <div id="revenue-recognition-graph">
                <Bar
                    options={{
                        ...options,
                        pan: {
                            interaction: ['scroll', 'drag'],
                            enabled: true,
                            speed: 10,
                            mode: 'x',
                        },
                        zoom: {
                            enabled: false,
                        },
                        plugins: {
                            tooltip: {
                                ...options.plugins.tooltip,
                                callbacks: {
                                    labelColor: (tooltipItem) => {
                                        return {
                                            backgroundColor: data.datasets[tooltipItem.datasetIndex].borderColor,
                                        };
                                    },
                                    label: (tooltipItem) => {
                                        const dataset = tooltipItem.dataset;
                                        var label = ' ' + dataset.label || '';
    
                                        if (label) {
                                            label += ': ';
                                        }
                                        label += presentCurrency(tooltipItem.raw, project?.companies_currency);
                                        return label;
                                    },
                                },
                            },
                            legend: {
                                ...options.plugins.legend,
                                display: true,
                                onClick: (e, legendItem) => {
                                    const { datasetIndex } = legendItem;
                                    this._toggleHidden(datasetIndex);
                                },
                                labels: {
                                    ...options.plugins?.legend?.labels,
                                    generateLabels: () => {
                                        return data.datasets.map((obj, i) => {
                                            return {
                                                text: obj.label,
                                                datasetIndex: i,
                                                pointStyle: obj.type != 'line' ? 'rectRounded' : 'line',
                                                fillStyle: obj.borderColor,
                                                lineCap: 'round',
                                                lineJoin: 'round',
                                                strokeStyle: obj.type != 'line' ? '#FFF' : obj.borderColor,
                                                lineWidth: obj.type != 'line' ? 5 : 2,
                                                lineDashOffset: 0,
                                                lineDash: [3],
                                                hidden: hiddenIndexes.indexOf(i) != -1,
                                            };
                                        });
                                    },
                                },
                            },
                        },
                        scales: {
                            ...options.scales,
                            y: {
                                    ...options.scales.y,
                                    stacked: false,
                                    type: 'linear',
                                    position: 'left',
                                    beginAtZero: true,
                                    ticks: {
                                        ...options.scales.y?.ticks,
                                        callback: (value) => {
                                            return presentCurrency(value, project?.companies_currency);
                                        },
                                    },
                                },
                            x: {
                                    ...options.scales.x,
                                    stacked: false,
                                    beginAtZero: true,
                                    min: data.labels[0],
                                    //max: data.labels[Math.min(11, data.labels.length - 1)],
                                    max: data.labels.length - 1,
                                },
                        },
                    }}
                    data={data}
                    width={'100%'}
                />
            </div>
        );
    };

    _renderTables = (onScroll, scrollLeft, scrollTop) => {
        const { users, teams, months } = this.state;
        const { project } = this.props;
        return this._getTables().map((t) => {
            if (t.hidden) return null;
            return (
                <RevenueRecognitionTable
                    {...t}
                    setGraphData={this.setGraphData}
                    showSyncConfirmation={this.openSyncConfirmationDialog}
                    showConfirmation={this.openConfirmationDialog}
                    users={users}
                    teams={teams}
                    months={months}
                    project={project}
                    onScroll={onScroll}
                    scrollLeft={scrollLeft}
                    scrollTop={scrollTop}
                />
            );
        });
    };

    _renderNoTimespanView = () => {
        return (
            <div className="no-timespan">
                <Loading  />
                <h1>{this.tr('Project timespan not set.')}</h1>
                <p>{this.tr('Please set the project timespan to start using revenue recognition.')}</p>
            </div>
        );
    };

    render() {
        return (
            <div id="revenue-recognition" className={this.props.loading && 'loading'}>
                {!this.state.months || this.state.months.length == 0 ? (
                    this._renderNoTimespanView()
                ) : (
                    <>
                        <div className="revenue-recognition-timespan">{this.props.renderProjectTimespan && this.props.renderProjectTimespan()}</div>
                        {this._renderGraph()}
                        <ScrollSync>
                            {({ onScroll, scrollLeft, scrollTop }) => {
                                this.lastScrollLeft = scrollLeft;
                                return (
                                    this._renderTables(onScroll, this.lastScrollLeft, scrollTop)
                                );
                            }}
                        </ScrollSync>
                        {this._renderConfirmationPopup()}
                    </>
                )}
            </div>
        );
    }
}

export default RevenueRecognition;
