import React from 'react';
import ListCell from '../../../list/ListCell';
import CurrencyListCell from "../../../list/CurrencyListCell";
import styles from './UnbilledHoursBlockRow.module.scss';
import PropsOnlyListRow from "../../../list/PropsOnlyListRow";
import CheckboxCell from "../../../list/cells/CheckboxCell";
import TreeStructureIndicatorCell from "../../../list/cells/TreeStructureIndicatorCell";
import StatusCell from '../../../list/cells/StatusCell';
import colors from '../../../colors';
import cn from 'classnames';

import { format } from "date-fns";

export default class UnbilledHoursBlockRow extends PropsOnlyListRow {
    constructor(props, context) {
        super(props, {}, { childRowType: UnbilledHoursBlockRow }, "projects/UnbilledHoursBlockRow");
    }

    defineClassName() {
        const { data, isLastChild } = this.props;
        let rowType = "";

        if (data.is_total) {
            rowType = "unbilledHoursTotalRow";
        } else if (data.is_header) {
            rowType = data.is_userheader ? 'unbilledHoursHeaderRow userHeader' : 'unbilledHoursHeaderRow userSubheader';
        } else if (data.parentId === "") {
            rowType = 'worktaskRow';
        } else {
            rowType = (data.is_workinghour && isLastChild) ? 'unbilledHoursBlockRow lastChild' : 'unbilledHoursBlockRow';
        }

        return rowType;
    }

    showEditSlider(fields, saveFunc) {
        const { rowProps: { showEditSlider }, data } = this.props;
        showEditSlider({
            onSave: saveFunc,
            fields: fields,
            item: data,
        });
    }

    saveQuoteRow(data) {
        const { rowProps: { updateQuoteRow } } = this.props;
        updateQuoteRow(data.costestimate_id, data.material_id, data);
    }

    defineCells() {
        const { data, sharedData: { currency }, rowProps: { onCheck } } = this.props;
        const { userObject } = this.context;

        let cells = {
            checked:
                data.is_header ?
                <ListCell
                    name="checked"
                    value={""}
                    editable={false} />
                :
                <CheckboxCell 
                    className={!data.is_workinghour ? "deselector" : ""}
                    checked={this.props.checked}
                    onClick={() => {
                        this.props.listRef.check(this.props.data.id);
                        onCheck(this.props.listRef.getCheckedRows(), !this.props.checked, data);
                    }} />,
            name:
                <TreeStructureIndicatorCell
                    name="name"
                    treeMetaData={this.props.treeMetaData}
                    childrenVisible={this.props.childrenVisible}
                    listCellProps={{ alignCell: true, textAlign: "left" }}
                    onExpanderClick={e => this.props.listRef.toggleShowChildren(data.id)}
                    value={data.is_workinghour ? format(data.name, userObject.dateFormat) : data.name}
                    editable={false}
                    openEditOnFocus={false}
                    indentDeepestLevel={true}
                    showBranchIndicators={false}
                >
                    <ListCell
                        name="name"
                        value={data.is_workinghour ? format(data.name, userObject.dateFormat) : data.name}
                        editable={false}
                        openEditOnFocus={false}
                        fontWeight={data.is_header || data.is_total ? "bold" : "normal"}
                     />
                </TreeStructureIndicatorCell>,
            quantity:
                <ListCell
                    name="quantity"
                    value={data.quantity + (!data.is_header ? ' ' + this.tr('h') : '')}
                    editable={false}
                    fontWeight={data.is_header || data.is_total ? "bold" : "normal"}
                     />,
            billable:
                data.is_workinghour ?
                <StatusCell
                    value={data.is_billable} 
                    displayData={
                        {
                            color: data.is_billable ? colors.greenish_cyan : colors.faded_red, 
                            name: data.is_billable ? this.tr("Billable") : this.tr("Non-Billable")
                        }
                        
                    }
                    />
                :
                <ListCell
                    name="billable_hours"
                    value={data.billable + (!data.is_header ? ' ' + this.tr('h') : '')}
                    editable={false}
                    fontWeight={data.is_header || data.is_total ? "bold" : "normal"}
                     />,
            unbillable: 
                <ListCell
                    name="unbillable_hours"
                    value={data.is_workinghour ? "" : data.unbillable + (!data.is_header ? ' ' + this.tr('h') : '')}
                    editable={false}
                    fontWeight={data.is_header || data.is_total ? "bold" : "normal"}
                     />,               
            value: 
                (data.is_header || data.is_total)
                ?
                <ListCell
                    name="value"
                    value={data.value}
                    editable={false}
                    fontWeight={data.is_header || data.is_total ? "bold" : "normal"}
                     />
                :
                <CurrencyListCell
                    name="value"
                    currency={currency}
                    textAlign="left"
                    value={data.total / data.quantity}
                    editable={false} />,
            billable_amount:
                data.is_header
                ?
                <ListCell
                    name="billable_value"
                    value={data.billable_amount}
                    editable={false}
                    fontWeight={data.is_header || data.is_total ? "bold" : "normal"}
                     />
                :
                <CurrencyListCell
                    name="billable_value"
                    currency={currency}
                    textAlign="left"
                    value={data.billable_amount}
                    editable={false}
                    fontWeight={data.is_header || data.is_total ? "bold" : "normal"}
                     />,
            total: 
                data.is_header
                ?
                <ListCell
                    name="total"
                    value={data.total}
                    editable={false}
                    fontWeight={data.is_header || data.is_total ? "bold" : "normal"}
                     />
                :
                <CurrencyListCell
                    name="total"
                    currency={currency}
                    textAlign="left"
                    value={Number(data.total).toFixed(2)}
                    editable={false}
                    fontWeight={data.is_header || data.is_total ? "bold" : "normal"}
                     />
            
        };

        cells = {
            ...super.defineCells(),
            ...cells
        };

        return cells;
    }
}