import React from "react";
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
import { ReactComponent as MemoEmpty } from "./icons/memo_empty.svg";
import TaimerComponent from "../TaimerComponent";

import "./MemoView.css";
import {
  Button,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from "@mui/material";
import TaimerAvatar from "../general/TaimerAvatar";
import { SettingsContext } from "../SettingsContext";
import moment from "moment";
import { Add, ArrowDropDownRounded, Delete, Print } from "@mui/icons-material";
import ConfirmationDialog from "../list/dialogs/ConfirmationDialog";

import JoditEditor from "jodit-react";

const MemoListRow = props => {
  const { selected, memo, onClick, index, dateFormat, tr, avatarIdKey } = props;
  return (
    <div
      onClick={() => onClick(index)}
      style={{ backgroundColor: selected ? "#e1eef8" : "white" }}
      className="memo-list-row"
    >
      <TaimerAvatar name={memo.users_name} id={memo[avatarIdKey]} />
      <div className="text-container">
        <h3>
          {memo.name && memo.name.length > 0
            ? memo.name
            : memo.id == "-1"
            ? tr("New memo")
            : tr("No header")}
        </h3>
        {memo.users_name && memo.users_name.length > 0 && (
          <p className="margin-top">{memo.users_name}</p>
        )}
      </div>
      {memo.added && (
        <div className="date-container">
          <p>
            {moment
              .utc(memo.added, "YYYY-MM-DD HH:mm:ss")
              .local()
              .format(dateFormat)}
          </p>
          <p className="margin-top">
            {moment
              .utc(memo.added, "YYYY-MM-DD HH:mm:ss")
              .local()
              .format("HH:mm")}
          </p>
        </div>
      )}
    </div>
  );
};

class MemoView extends TaimerComponent {
  static contextType = SettingsContext;
  static defaultProps = {
    memos: [],
    avatarIdKey: "users_id"
  };

  constructor(props, context) {
    super(props, context, "general/MemoView");

    const editorButtons = [
      "paragraph",
      "|",
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "image",
      "file",
      "video",
      "table",
      "link",
      "|",
      "align",
      "undo",
      "redo"
    ];
    this.editorConfig = {
      buttons: editorButtons,
      buttonsMD: editorButtons,
      buttonsSM: editorButtons,
      buttonsXS: editorButtons,
      showPlaceholder: false,
      showTooltip: true,
      globalFullsize: false,
      // uploader: {
      // TODO check this to upload images
      //   insertImageAsBase64URI: false
      // },
      enableDragAndDropFileToEditor: true
    };
    this.headerInput = React.createRef();

    this.state = {
      memos: props.memos,
      selectedIndex: 0,
      optionsAnchor: null,
      optionsOpen: false,
      showDeleteConfirmation: false,
      headerHighlighted: false,
      saving: false
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount = () => {
    super.componentWillUnmount();
  };

  componentDidUpdate = oldProps => {
    if (oldProps.memos != this.props.memos) {
      const memos = [...this.props.memos];
      const oldMemos = this.state.memos;
      const newMemoIndex = oldMemos.findIndex(
        obj => obj.id == "-1" && obj.name == ""
      );
      if (newMemoIndex != -1) {
        memos.unshift(oldMemos[newMemoIndex]);
      }
      this.setState({ memos, saving: false });
    }
  };

  _showHeaderError = () => {
    const { enqueueSnackbar } = this.props;
    this.setState({ headerHighlighted: true });
    enqueueSnackbar(this.tr("Add a header to save memo!"), {
      variant: "error"
    });
  };

  _saveMemo = selectedMemo => {
    const { saveMemo } = this.props;
    if (!selectedMemo.name || selectedMemo.name.length == 0) {
      this._showHeaderError();
      return;
    }
    if (selectedMemo.id == -1) {
      this.setState({ saving: true }, () => {
        saveMemo(selectedMemo);
      });
    } else {
      saveMemo(selectedMemo);
    }
  };

  _onEditorBlur = comment => {
    const { selectedIndex, memos } = this.state;
    if (selectedIndex == null) return;
    this._editSelectedMemo("comment", comment);
    const selectedMemo = {
      ...memos[selectedIndex],
      comment
    };
    this._saveMemo(selectedMemo);
  };

  _addMemo = () => {
    const memos = [...this.state.memos];
    const { userObject } = this.context;
    const { avatarIdKey } = this.props;
    const newMemo = {
      id: "-1",
      name: "",
      comment: "",
      [avatarIdKey]: userObject.usersId,
      users_name: userObject.fullname
    };
    if (memos.findIndex(obj => obj.id == "-1") == -1) {
      memos.unshift(newMemo);
    }
    this.context.functions.setDirty(true);
    this.setState(
      {
        memos,
        selectedIndex: 0
      },
      () => {
        this.headerInput.current.focus();
      }
    );
  };

  _selectMemo = selectedIndex => {
    this.setState({ selectedIndex });
  };

  _renderMemoRows = () => {
    const { memos, selectedIndex } = this.state;
    const { avatarIdKey } = this.props;
    const { userObject } = this.context;
    return memos.map((memo, i) => {
      const selected = selectedIndex == i;
      return (
        <MemoListRow
          tr={this.tr}
          onClick={this._selectMemo}
          selected={selected}
          avatarIdKey={avatarIdKey}
          key={i}
          index={i}
          memo={memo}
          dateFormat={userObject.dateFormat}
        />
      );
    });
  };

  _renderMemoList = () => {
    return (
      <div className="memo-list">
        <div className="header-container">
          <h2>{this.tr("Memo")}</h2>
          <Tooltip title={this.tr("Add memo")}>
            <Button onClick={this._addMemo}>{<Add />}</Button>
          </Tooltip>
        </div>
        <div className="scrollable-content">{this._renderMemoRows()}</div>
      </div>
    );
  };

  _editHeader = e => {
    this._editSelectedMemo("name", e.target.value);
  };

  _editSelectedMemo = (key, value) => {
    const { selectedIndex } = this.state;
    if (selectedIndex == null) {
      return;
    }
    const memos = [...this.state.memos];
    memos[selectedIndex] = {
      ...memos[selectedIndex],
      [key]: value
    };
    this.setState({ memos });
  };

  _onHeaderBlur = () => {
    const { selectedIndex, memos } = this.state;
    if (selectedIndex == null) return;
    const selectedMemo = memos[selectedIndex];

    // not dirty if the header has a value, can save in the background -> allowed to navigate
    if (selectedMemo.name && selectedMemo.name.length > 0) {
      this.context.functions.setDirty(false);
    }
    this._saveMemo(selectedMemo);
  };

  _showOptions = event => {
    if (this.state.optionsOpen) {
      this.setState({ optionsAnchor: null, optionsOpen: false });
    } else {
      this.setState({ optionsAnchor: event.currentTarget, optionsOpen: true });
    }
  };

  _deleteMemo = () => {
    const { deleteMemo } = this.props;
    this.setState({ showDeleteConfirmation: false }, () => {
      const { selectedIndex, memos } = this.state;
      if (selectedIndex == null) return;
      const selectedMemo = memos[selectedIndex];
      deleteMemo(selectedMemo);
    });
  };

  _renderEditorPlaceholder = () => {
    return (
      <div className="editor-placeholder-container" key="placeholder">
        <MemoEmpty
          style={{ height: 250, width: 250 }}
        />
        {this.tr("Add and share a memo")}
      </div>
    );
  };

  _renderOptionsMenu = () => {
    return (
      <Popover
        onClose={() => {
          this.setState({
            optionsAnchor: null,
            optionsOpen: false
          });
        }}
        open={this.state.optionsOpen}
        anchorEl={this.state.optionsAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <List
          className="memo-options-list"
          component="div"
          onClick={e => e.stopPropagation()}
        >
          <ListItem button className="muiListItem" onClick={this._printMemo}>
            <ListItemIcon>
              <Print style={{ color: "#2d9ff7", height: 20, width: 20 }} />
            </ListItemIcon>
            <ListItemText primary={this.tr("Print memo")} />
          </ListItem>
          <ListItem
            button
            className="muiListItem"
            onClick={() =>
              this.setState({
                showDeleteConfirmation: true,
                optionsOpen: false,
                optionsAnchor: null
              })
            }
          >
            <ListItemIcon>
              <Delete style={{ color: "#f7548f", height: 20, width: 20 }} />
            </ListItemIcon>
            <ListItemText primary={this.tr("Delete memo")} />
          </ListItem>
        </List>
      </Popover>
    );
  };

  _printMemo = () => {
    const { selectedIndex, memos } = this.state;
    if (selectedIndex == null) return;
    const selectedMemo = memos[selectedIndex];
    if (!selectedMemo) return;
    const printContents = `<!doctype html>
      <html>
      <head>
      <style>
      * {
        font-family: 'Open Sans', sans-serif;
      }
      body {
        padding: 20px;
      }
      </style>
      <title></title>
      </head>
      <body>
      <div style="margin-bottom: 40px">
        <h1>${selectedMemo.name}</h1>
        <p style="margin-top: -10px">${this.tr(
          "Created by"
        )} <span style="font-weight: bold">${selectedMemo.users_name}</span></p>
      </div>
      ${selectedMemo.comment}
      </body>
      </html>`;
    const printWindow = window.open("", "PRINT");
    printWindow.document.body.innerHTML = printContents;
    printWindow.onfocus = () => {
      printWindow.close();
    };
    printWindow.focus();
    printWindow.print();
  };

  _clearHighlight = () => {
    this.setState({ headerHighlighted: false });
  };

  _renderEditor = () => {
    const { selectedIndex, memos, headerHighlighted } = this.state;
    if (selectedIndex == null) {
      return this._renderEditorPlaceholder();
    }
    const selectedMemo = memos[selectedIndex];
    if (!selectedMemo) {
      return this._renderEditorPlaceholder();
    }
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column"
        }}
        key="editor"
      >
        <div className="header-container">
          <div className="input-container">
            <input
              tabIndex={1}
              ref={this.headerInput}
              placeholder={this.tr("Add title here")}
              onChange={this._editHeader}
              className={`header-field ${headerHighlighted ? "error" : ""}`}
              value={selectedMemo.name}
              onFocus={this._clearHighlight}
              onBlur={this._onHeaderBlur}
            />
            <p>
              {this.tr("Created by")}{" "}
              <span className="bold">{selectedMemo.users_name}</span>
            </p>
          </div>
          {selectedMemo.id != -1 && (
            <React.Fragment>
              <Button
                onClick={this._showOptions}
                style={{ backgroundColor: "#2d9ff7" }}
              >
                {this.tr("Options").toUpperCase()}
                <ArrowDropDownRounded
                  style={{ color: "white", marginRight: -7 }}
                />
              </Button>
              {this._renderOptionsMenu()}
            </React.Fragment>
          )}
        </div>
        <JoditEditor
          tabIndex={2}
          config={this.editorConfig}
          value={selectedMemo.comment}
          onBlur={this._onEditorBlur}
        />
      </div>
    );
  };

  _renderConfirmation = () => {
    if (!this.state.showDeleteConfirmation) return null;

    return (
      <ConfirmationDialog
        data={{ text: this.tr("Do you really want to delete this memo?") }}
        onDialogSave={this._deleteMemo}
        onDialogClose={() => this.setState({ showDeleteConfirmation: false })}
      />
    );
  };

  render() {
    return (
      <div id="memo-view" className={this.state.saving ? 'memo-saving' : ''}>
        <div className="memo-content">
          {this._renderMemoList()}
          <div className="editor-container">{this._renderEditor()}</div>
        </div>
        {this._renderConfirmation()}
        {this.state.saving && <div className="memo-loading-overlay"><Loading  /></div>}
      </div>
    );
  }
}

export default MemoView;
