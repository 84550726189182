import React from 'react';
import './Efina.css';
import './Maventa.css';
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";
import ListCell from "../../list/ListCell";
import TrashCanIcon from "@mui/icons-material/Delete";
import TextInputCell from "../../list/cells/TextInputCell";
import DataHandler from '../../general/DataHandler';
import MenuItem from '@mui/material/MenuItem';
import { SettingsContext } from '../../SettingsContext';
import SettingRow from "../../list/rows/SettingRow";
import List from "../../list/List";
import AccountingAccounts from '../components/AccountingAccounts';
import AccountingDimensions from '../components/AccountingDimensions';
import AccountingVatCodes from '../components/AccountingVatCodes';

class NetSuite extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/NetSuite");

        this.state = {
            activated: 0,
            salesAccounts: []
        };

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.productsList = React.createRef();

        this.integration = "netsuite";
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getSalesAccounts();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/netsuite`}).done(response => {
            this.setState(response);
        });
    }

    getSalesAccounts = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/netsuite/1`}).done(response => {
            this.setState(response);
        });
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        DataHandler.delete({url: `settings/company/${this.props.company}/netsuite`}).done(this.back);
    }

    changeDefault = (type, id) => {
        const company = this.props.company;
        this.setState({[type]: id}, () => DataHandler.put({url: `accounting/defaults/${company}`}, {type: type, id: id }));
    }
    
    render(){
        const { activated } = this.state;        
        const { tr } = this;

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("NetSuite Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("Sales accounts")}</h4>
                    <AccountingAccounts 
                        integration={this.integration}
                        company={this.props.company}
                        type={1}
                        addButtonText={this.tr("Add sales account")}
                    />

                    <div className="drop-section">
                        <h4>{tr("Default sales account")}</h4>
                        <OutlinedField select disabled={false} label={tr("Default")} name={"default"} value={this.state.default_accounting_accounts_id} fullWidth >
                            <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_accounts_id", 0)}>{this.tr("No default")}</MenuItem>
                            {this.state.salesAccounts.map(({name, id}) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_accounts_id", id)}>{name}</MenuItem>)}
                        </OutlinedField>
                    </div>

                    <div className="settings">
                        <h4>{tr("Dimensions")}</h4>
                        <AccountingDimensions 
                            integration={this.integration}
                            company={this.props.company}
                            showDefaultOption
                        />
                    </div>

                    <div className="settings">
                        <h4>{this.tr("VAT codes")}</h4>
                        <AccountingVatCodes
                            integration={this.integration}
                            company={this.props.company}
                            showVatAccount={false}
                            showIntegrationID
                            showVatCode={false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default NetSuite;