import CloudDownload from '@mui/icons-material/CloudDownload';
import MenuItem from '@mui/material/MenuItem';
import { withSnackbar } from 'notistack';
import React from 'react';
import DataHandler from "./../general/DataHandler";
import OutlinedField from "./../general/OutlinedField";
import Utils from "./../general/Utils";
import BillsList from './../list/lists/BillsList';
import PurchaseOrderList from './../list/lists/PurchaseOrderList';

/* overlay */
import NoPermissionOverlay from './../list/overlays/NoPermissionOverlay';

import List from './../list/List';

/* context */
import TaimerComponent from '../TaimerComponent';
import colors from '../colors';
import PageTopSection from '../general/PageTopSection';
import { SettingsContext } from './../SettingsContext';

class BillsPoList extends TaimerComponent {
    static contextType  = SettingsContext;

    static defaultProps = {
        perpage: 30,
        showLockedUsersWithTag: true,
        // view: 'bills'
    };

    constructor(props, context) {
        super(props, context, "bills/BillsPoList");

        this.initialCompany = props.selectedTab === "po" ? context.functions.getCompany("purchaseorders", "read", false, true) : (context.functions.getCompany("receivedinvoices", "approve", false, true) || context.functions.getCompany("receivedinvoices", "pre_approve", false, true));
        const company = props.company ? props.company : this.initialCompany;
        this.setInitialView();

        this.state   = {
            company,
            hasPoWritePrivilege: false,
            count: 0
        };
        this.checked = [];

        this.autoCompleteData = undefined;
        
        this.getAutoCompleteData = this.getAutoCompleteData.bind(this);

        this.userTypeAutocompleteClasses = [
            'users',
            'privileged_users',
            'all_users'
        ];

        this.translations = {
            locked: this.tr("locked"),
            freelancer: this.tr("freelancer")
         };

        this.refView    = React.createRef();
        this.refFilters = React.createRef();
        this.refUpper   = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();
        this.getAutoCompleteData(this.state.company);
    }

    componentDidUpdate = (oldProps) => {
        if (oldProps.selectedTab != this.props.selectedTab) {
            this.setCount(0);
        }
        if (!this.props.selectedTab)
            this.setInitialView();
    }

    setInitialView = () => {
        const initialView = this.context.functions.hasPrivilege("receivedinvoices", ["approve", "pre_approve"]) ? "bills" : "po";
       
        if (initialView === "po" && !this.props.selectedTab)
            this.context.functions.updateView({ module: "costs", action: "main", selectedTab: "purchase-orders" });
        else if (!this.props.selectedTab)
            this.context.functions.updateView({ module: "costs", action: "main", selectedTab: "bills" });
    }

    async getAutoCompleteData(company) {
        company = company || this.initialCompany;
        const module = this.props.selectedTab === "po" ? "purchaseorders" : "bills";
        let users  = await DataHandler.get({ url: `${module}/autoCompleteData/${company}`, users: 1 });
        let data   = await DataHandler.get({ url: `${module}/autoCompleteData/${company}` });
        const userTagProps = {
            fields: {name: 'name'},
            showLocked: this.props.showLockedUsersWithTag,
            transl: this.translations
        };        
        Object.keys(users).forEach(k => {
            if (this.userTypeAutocompleteClasses.includes(k)) {
                users[k] = users[k].map(d => ({...Utils.showLockedAndFreelancerUserTag(d, userTagProps)}))
            }
        })

        this.autoCompleteData = {...data, ...users};

        if (module === "bills") 
            this.autoCompleteData.canSeePo = this.context.functions.checkPrivilege("purchaseorders", "read", company);        


        this.autoCompleteData.subcontract_statuses = [
            {
                id: "1",
                label: this.tr("Waiting"),
                color: "#ffb822"
            },
            {
                id: "2",
                label: this.tr("Rejected"),
                color: "#f7548f"
            },
            {
                id: "3",
                label: this.tr("Sent"),
                color: "#2d9ff7"
            },
            {
                id: "4",
                label: this.tr("Synced"),
                color: colors.greenish_cyan
            },
        ];
        this.autoCompleteData.bills_statuses = [
            {
                id: "0",
                label: this.tr("Waiting"),
                color: "#ffb822"
            },
            {
                id: "3",
                label: this.tr("Pre-approved"),
                color: "#979797"
            },
            {
                id: "1",
                label: this.tr("Approved"),
                color: colors.greenish_cyan
            },
            {
                id: "5",
                label: this.tr("To Payment"),
                color: "#2d9ff7",
            },
            {
                id: "4",
                label: this.tr("Archived"),
                color: "#716aca"
            },
            {
                id: "2",
                label: this.tr("Rejected"),
                color: "#ff5722"
            }
        ];

        if (this.props.selectedTab === "po") {
            const hasPoWritePrivilege = this.context.functions.checkPrivilege("purchaseorders", "write", company);
            this.autoCompleteData.hasPoWritePrivilege = hasPoWritePrivilege;
            this.setState({ hasPoWritePrivilege, company });
        }

        this.forceUpdate();
        return this.autoCompleteData;
    }

    export = (type) => {
        this.refView.current.export(type);
    }

    menuClick = (opt) => {
        this.refView.current[opt.function](...opt.params);
    }
     
	handleTabChange = (e, value) => { 	
        const { functions: { updateView, getCompany } } = this.context;
        updateView({selectedTab: value === "bills" ?  'bills' : 'po' });

        this.initialCompany = value === "po" ? getCompany("purchaseorders", "read", false, true) : (getCompany("receivedinvoices", "approve", false, true) || getCompany("receivedinvoices", "pre_approve", false, true));
        const company = this.props.company ? this.props.company : this.initialCompany;
        this.getAutoCompleteData(company);
    };
    
    setCount = count => {
        this.setState({ count });
    }

    render() {
        const { functions: { updateView, checkPrivilege, hasPrivilege }, versionId } = this.context;
        const { selectedTab, start, end, state } = this.props;
        const { tr } = this;
        const { company } = this.state;
 
        let menuOptions = [];

        if (selectedTab === "bills" && !hasPrivilege("receivedinvoices", ["approve", "pre_approve"]) || selectedTab === "po" && !hasPrivilege("purchaseorders", ["read"])) {
            return <div>{<List showOverlay={true} overlayComponent={NoPermissionOverlay}/>}</div>
        }

        if(checkPrivilege("receivedinvoices", "pre_approve", company)) {
            menuOptions = menuOptions.concat([
            
            ]);
            menuOptions.push({
                name: 'preapprove',
                label: this.tr('Pre-approve'),
                display: 'bills',
                function: 'updateStatus',
                params: [3],
            });
        }

        if(checkPrivilege("receivedinvoices", "approve", company)) {
            menuOptions = menuOptions.concat([
                {
                    name: 'approve',
                    label: this.tr('Approve'),
                    display: 'bills',
                    function: 'updateStatus',
                    params: [1],
                },
                {
                    name: 'topayment',
                    label: this.tr('To Payment'),
                    display: 'bills',
                    function: 'updateStatus',
                    params: [5],
                },
                {
                    name: 'archive',
                    label: this.tr('Archive'),
                    display: 'bills',
                    function: 'updateStatus',
                    params: [4],
                },
                {
                    name: 'reject',
                    label: this.tr('Reject'),
                    display: 'bills',
                    function: 'updateStatus',
                    params: [2],
                },
            ]);
        }

        if (this.context.addons.maventa && this.context.taimerAccount.use_maventa_targeting == 1) {
            menuOptions = menuOptions.concat([
                {
                    name: 'contract',
                    label: this.tr('Create contract billing'),
                    display: 'bills',
                    function: 'createContractBillRows',
                    params: ['maventa', company]
                }
            ]);
        }

        if (this.context.addons.fennoa && this.context.taimerAccount.use_maventa_targeting == 1) {
            menuOptions = menuOptions.concat([
                {
                    name: 'contract',
                    label: this.tr('Create contract billing (Fennoa)'),
                    display: 'bills',
                    function: 'createContractBillRows',
                    params: ['fennoa', company]
                }
            ]);
        }

        if (this.context.addons.netvisor && this.context.taimerAccount.use_maventa_targeting == 1) {
            menuOptions = menuOptions.concat([
                {
                    name: 'contract',
                    label: this.tr('Create contract billing (Netvisor, old method)'),
                    display: 'bills',
                    function: 'createContractBillRows',
                    params: ['netvisor', company, 'old']
                }
            ]);
        }

        if (this.context.addons.netvisor && this.context.taimerAccount.use_maventa_targeting == 1) {
            menuOptions = menuOptions.concat([
                {
                    name: 'contract',
                    label: this.tr('Create contract billing (Netvisor, new method)'),
                    display: 'bills',
                    function: 'createContractBillRows',
                    params: ['netvisor', company, 'new']
                }
            ]);
        }

        if (this.context.addons.netvisor && this.context.taimerAccount.use_maventa_targeting == 1) {
            menuOptions = menuOptions.concat([
                {
                    name: 'contract',
                    label: this.tr('Create contract billing (Netvisor, product register)'),
                    display: 'bills',
                    function: 'createContractBillRows',
                    params: ['netvisor', company, 'product_register']
                }
            ]);
        }

        if (this.context.addons.procountor && this.context.taimerAccount.use_maventa_targeting == 1) {
            menuOptions = menuOptions.concat([
                {
                    name: 'contract',
                    label: this.tr('Create contract billing (Procountor)'),
                    display: 'bills',
                    function: 'createContractBillRows',
                    params: ['procountor', company]
                }
            ]);
        }

        const tabs = [];

        const billsTab = {
            id: "bills",
            label: this.tr("Bills"),
        };
        const poTab =  {
            id: "po",
            label: this.tr("Purchase Orders"),
        };

        hasPrivilege("receivedinvoices", ["approve", "pre_approve"]) && tabs.push(billsTab);
        hasPrivilege("purchaseorders", ["read"]) && versionId == 4 && tabs.push(poTab);

        return (
            <div className="contentBlock" id="billList">
                <div className="listControlsContainer clearfix">
                    <div className="listControlsWrap" ref={this.refFilters}>
                        {selectedTab === 'bills' && (
                            <OutlinedField select label={this.tr('Options')} className="listFilterOutlinedField" shrinkLabel={false}>
                                {menuOptions
                                    .filter((opt) => opt.display === undefined || opt.display == selectedTab)
                                    .map((opt) => (
                                        <MenuItem key={opt.name} onClick={() => this.menuClick(opt)}>
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                            </OutlinedField>
                        )}
                    </div>
                    <PageTopSection
                        summaries={[{ title: selectedTab === 'bills' ? this.tr("Bills") : this.tr("Purchase Orders"), value: this.state.count }]}
                        mainButtons={[
                            {
                                'data-testid': "add-purchaseorder-button",
                                title: tr('NEW PURCHASE ORDER'),
                                action: () => updateView({ module: 'purchaseorder', action: 'view', companies_id: company, projects_id: this.props.projects_id }),
                                isVisible: selectedTab === 'po' && this.state.hasPoWritePrivilege,
                            },
                            {
                                'data-testid': "add-bill-button",
                                title: tr('NEW BILL'),
                                action: () => updateView({ module: 'receivedinvoice', action: 'view', company }),
                                isVisible: selectedTab === 'bills',
                            },
                        ]}
                        additionalButtons={[
                            {
                                title: this.tr('EXPORT'),
                                icon: <CloudDownload />,
                                action: () => this.export('xlsx'),
                            },
                        ]}
                        settingsButton={{
                            isVisible: !(null == this.context.privileges.admin),
                            title: this.tr('Settings'),
                            href: this.context.functions.urlify({ module: 'settings', action: 'index', group: 'features', page: 'invoicing' }),
                            action: () => this.context.functions.updateView({ module: 'settings', action: 'index', group: 'features', page: 'invoicing' }, false),
                        }}
                        refProp={this.refUpper}
                    />
                </div>
                {this.autoCompleteData !== undefined && selectedTab === 'po' && (
                    <PurchaseOrderList
                        enqueueSnackbar={this.props.enqueueSnackbar}
                        closeSnackbar={this.props.closeSnackbar}
                        filtersEl={this.refFilters.current}
                        sumsEl={this.refUpper.current}
                        autoCompleteData={this.autoCompleteData}
                        getAutoCompleteData={this.getAutoCompleteData}
                        viewProps={this.props.projects_id && { id: this.props.projects_id }}
                        ref={this.refView}
                        start={start}
                        end={end}
                        state={state}
                        setCount={this.setCount}
                        projects_id={this.props.projects_id}
                    />
                )}
                {this.autoCompleteData !== undefined && selectedTab === 'bills' && (
                    <BillsList
                        onCheck={(arr) => (this.checked = arr)}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                        closeSnackbar={this.props.closeSnackbar}
                        filtersEl={this.refFilters.current}
                        sumsEl={this.refUpper.current}
                        autoCompleteData={this.autoCompleteData}
                        getAutoCompleteData={this.getAutoCompleteData}
                        ref={this.refView}
                        start={start}
                        end={end}
                        state={state}
                        projectId={this.props.projects_id}
                        setCount={this.setCount}
                    />
                    )}
            </div>
        );
    }
}

export default withSnackbar(BillsPoList);
