import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import { SettingsContext } from '../../SettingsContext';
import './AccountingAccounts.scss';
import DataHandler from "../../general/DataHandler";
import SettingRow from "../../list/rows/SettingRow";
//import List from "../../list/List";
import SettingsList from "../../list/lists/SettingsList";
import ListCell from "../../list/ListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import RadioButtonCell from "../../list/cells/RadioButtonCell";
import Button from '@mui/material/Button';
import TrashCanIcon from "@mui/icons-material/Delete";
import { withSnackbar } from 'notistack';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";

class DimensionHeaderRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class DimensionRow extends SettingRow {
    /*branchChanged(obj) {
        let newState = this.state.data; 
        newState.branchofbusiness_id = obj.id; 
        this.setState({data: newState}); 
        this.editAndSave();
    }

    dimensionHeadersChanged(obj) {
        let newState = this.state.data; 
        newState.accounting_dimension_headers_id = obj.id; 
        this.setState({data: newState}); 
        this.editAndSave();
    }*/

    render() {
        //const bob = this.props.rowProps.branches.find(b => b.id == this.state.data['branchofbusiness_id']);
        //const dh = this.props.rowProps.dimensionHeaders.find(d => d.id == this.state.data['accounting_dimension_headers_id']);
       
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            dimension: <TextInputCell
                width={this.props.columnWidthMap['dimension']}
                name="dimension"
                value={this.state.data['dimension']}
                onEdited={this.editAndSave}
            />,
            is_default: <RadioButtonCell 
                onEdited={(event, checked) => {
                    this.editAndSave("is_default", checked);
                }}
                width={this.props.columnWidthMap['is_default']}
                checked={this.state.data['is_default'] == 1} 
            />
            /*branchofbusiness_id: <AutoCompleteCell
                listCellProps={{
                    className: "branchofbusiness_id"
                }}
                editable={true}
                autoCompleteData={this.props.rowProps.branches}
                name="branchofbusiness_id"
                width={this.props.columnWidthMap['branchofbusiness_id']}
                value={bob}
                components={{Option: props => {
                    const passProps = {
                        isDisabled: props.data.data.deleted == 1 || props.data.data.locked == 1,
                        isFocused: 	props.isFocused,
                        isMulti: 	props.isMulti,
                        isSelected: props.isSelected
                    };
        
                    return <TreeOption {...props.data} {...passProps} onSelect={bob => {
                        props.setValue(bob.id)
                        this.branchChanged(bob);
                    }} />;
                }}}
            />,
            accounting_dimension_headers_id: <AutoCompleteCell
                listCellProps={{
                    className: "accounting_dimension_headers_id"
                }}
                editable={true}
                autoCompleteData={this.props.rowProps.dimensionHeaders}
                name="accounting_dimension_headers_id"
                width={this.props.columnWidthMap['accounting_dimension_headers_id']}
                value={dh}
                onEdited={obj => this.dimensionHeadersChanged(obj)}
            />*/
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class AccountingDimensions extends TaimerComponent {
    static defaultProps = {
		showDefaultOption: false
    }
    
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/AccountingDimensions");

        this.state = {
            dimensionHeaders: [],
            dimensions: [],
        }

        this.columnConfig = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.dimensionHeadersList = React.createRef();
        this.dimensionsList = React.createRef();

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
    }

    componentDidMount () {
        super.componentDidMount();
        //this.getDimensionHeaders();
        this.getDimensions();
    }

    getDimensions = () => {
        DataHandler.get({url: `accounting/dimensions/${this.props.company}/${this.props.integration}`}).done(response => {
            this.setState(response);
        });
    }

    getDimensionHeaders = () => {
        DataHandler.get({url: `accounting/dimension_headers/${this.props.company}/${this.props.integration}`}).done(response => {
            this.setState(response);
        });
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    render() {
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <div className="accounting-list">
               <Button className="green" onMouseUp={() => this.dimensionsList.current.addNewRow()} size="large">{this.tr("Add dimension")}</Button>
                <SettingsList
                    innerRef={this.dimensionsList}
                    id="dimensionsList"
                    className="settingsList integrationList"
                    height="auto"
                    rowHeight={SettingRow.rowHeight}
                    newRow={{
                        id: -1,
                        name: "",
                        dimension: "",
                        accounting_dimension_headers_id: 0,
                        branchofbusiness_id: 0
                    }}
                    listRowType={DimensionRow}
                    columns={[
                        { width: 55, name: "delete", header: "", ...this.columnConfig },
                        { width: 350, name: "name", header: this.tr("Dimension name"), ...this.columnConfig },
                        { width: 350, name: "dimension", header: this.tr("Dimension code"), ...this.columnConfig },
                        ...(this.props.showDefaultOption ? [{width: 100, name: "is_default", header: this.tr("Default"), ...this.columnConfig}] : []),
                    ]}
                    rowProps={{
                        //branches: this.state.branches ? this.state.branches : [],
                        //dimensionHeaders: this.state.dimensionHeaders ? this.state.dimensionHeaders : [],
                        onCreate: data => {
                            data.company = this.props.company;
                            DataHandler.post({url: `accounting/dimensions/${this.props.integration}`}, { data: JSON.stringify(data) }).done(response => {
                                this.getDimensions();
                            });
                        },
                        onUpdate: data => {
                            data.company = this.props.company;
                            DataHandler.put({url: 'accounting/dimensions'}, { data: JSON.stringify(data) }).done(response => {
                                this.getDimensions();
                            });
                        },
                        onDelete: data => {
                            if (Number(data.id) < 0) {
                                this.list.current.removeRow(data.id);
                                return;
                            }
                            if (data.is_default == true) {
                                this.props.enqueueSnackbar(this.tr("Default dimension can't be removed"), {
                                    variant: "error",
                                });	
                                return;
                            }
                            data.deleted = "1";
                            this.setState({
                                dialogData: {
                                    saveFunc: () => DataHandler.delete({url: 'accounting/dimensions'}, { data: JSON.stringify(data) }).done(response => {
                                        this.getDimensions();
                                    }),
                                    text: this.tr("Do you want to delete dimension") + ": " + data.name + "?" 
                                }
                            });
                            this.openDialog('confirmation');
                        }
                    }}
                    data={this.state.dimensions}
                />

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
            </div>
        );
    }
}

export default withSnackbar(AccountingDimensions);