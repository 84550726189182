import React, { Component } from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

/* context */
import { SettingsContext } from './../../SettingsContext';

/* data backend */
import DataHandler from './../../general/DataHandler';
import { format, endOfMonth, startOfMonth, startOfYear, isValid, addMonths } from "date-fns";

/* local components */
import TaimerComponent from '../../TaimerComponent';
import DataList from './../../general/DataList';
import { DateRangePicker } from './../../general/react-date-range/src';
import TaimerAvatar from './../../general/TaimerAvatar';

/* material ui */
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

/* material icons */
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ChevronRight from '@mui/icons-material/ChevronRight';

/* chart */
import {Bar} from 'react-chartjs-2';
import ChartOptions from './../../general/ChartOptions';

/* css */
import './WonDeals.css';

/* extras */
import _ from 'lodash';
import debounce from 'lodash/debounce';

/* images */
import { ReactComponent as WonDealOverview } from '../images/WonDealOverview.svg';

class WonDeals extends TaimerComponent {
    
    static contextType = SettingsContext;
    
    constructor(props, context){
        super(props, context, "dashboard/overview/WonDeals");

        const { functions: { getFinancialYear } } = context;
        const dateRange = getFinancialYear();
    
        this.state = {
            open: true,
            data: [],
            elementWidth: 10000,
            elementWidthSet: false,
            dateRange: {
                startDate: dateRange.start,
                endDate: dateRange.end,
                key: "selection"
            },
        }
        this.element = React.createRef();
        this.chart = React.createRef();
        this.handleToggle = this.handleToggle.bind(this);
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.customersId !== prevProps.customersId || prevProps.company !== this.props.company)
            this.handleData();
        
        if(this.element.current && this.state.elementWidthSet == false)
            this.setState({elementWidth: this.element.current.clientWidth, elementWidthSet: true}); 
    }

    componentDidMount(){
        super.componentDidMount();
        this.handleData();
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('projectCreated', this.handleData);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('projectCreated', this.handleData);
    }

    handleResize = debounce(() => {
        this.updateView();
    }, 10)

    updateView = () => {
        const element = document.getElementById("overview-wondeals");
        if(element) {
            this.setState({elementWidth: element.clientWidth});
        }
    }

    handleData = async () => {
        const { company } = this.props;
        const {dateRange} = this.state;
        const data = await DataHandler.get({url: 'data/wondeals_overview/' + format(dateRange.startDate, "YYYY-MM-DD") + '/' + format(dateRange.endDate, "YYYY-MM-DD"), customersId: this.props.customersId || 0, company});
        this.setState({...data, loaded: true});
    }

    handleToggle(){
        if (this.state.open == true){
            this.setState({open: false});
        } else if (this.state.open == false) {
            this.setState({open: true});
        }
    }

    onDateChange = (event) => {

        const { startDate, endDate } = event.selection;

        this.setState({
            dateRange: {
                startDate: format(startDate, "YYYY-MM-DD"),
                endDate: format(endDate, "YYYY-MM-DD"),
                key: "selection"
            }
        }, () => this.handleData());
    }

    onDateInputChange = (dateType, date) => {

        const { endDate, startDate } = this.state.dateRange;
        date = format(date, "YYYY-MM-DD");

        if (dateType == "start") {
            this.setState({
                dateRange: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection"
                }
            }, () => this.handleData());
        } else {
            this.setState({
                dateRange: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection"
                }
            }, () => this.handleData());
        }
    }

    addDeal(e) {
        this.context.functions.updateView({module: 'projects', action: 'view', pipeline_id:-1, customers_id: this.props.customersId, companies_id: this.props.company}, e.ctrlKey || e.metaKey || e.button === 1);
    }

    render() {
        const { year, summary, open, loaded, dateRange } = this.state;
        const { taimerAccount, privileges, userObject, functions: { updateView } } = this.context;
        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: this.props.currency || "EUR"
        }).format;
        
        if (!loaded) {
            return <div><Loading className='main-page-loading-indicator' /></div>
        }

        const labels = [];

        const datasetRevenue = {
            data: [],
            backgroundColor: ChartOptions.colorPalette[12],
            type: 'bar',
            label: this.tr('Revenue'),
        }

        const datasetMargin = {
            data: [],
            borderColor: ChartOptions.colorPalette[16],
            backgroundColor: ChartOptions.colorPalette[16],
            pointBackgroundColor: "#FFFFFF",
            pointBorderColor: ChartOptions.colorPalette[16],
            type:'line',
            label: this.tr('Margin'),
            lineTension: 0
        }

        _.map(year, month =>{
            datasetMargin.data.push(month.data.margin);
            datasetRevenue.data.push(month.data.revenue);
        })

        _.map(year, label =>{
            labels.push(label.label)
        })

        const dealData = {
            labels: labels,
            dates: _.map(year, 'date'),
            datasets: [
                datasetMargin,
                datasetRevenue,
            ],
        }

        return (
            <div className={"grid-container content-block " + (!open && "closed")} id="overview-wondeals">
                { this.state.empty ? <div className="grid-item overlayDiv">
                    <div className = {"overlay"} >
                        <WonDealOverview className="svg"/>
                        {
                            privileges.projects && privileges.projects.write ?
                                 <p>{this.tr("No closed deals yet.")} <a 
                                    onClick={e => (e.button !== 1) && this.addDeal(e)} 
                                    onMouseUp={e => (e.button === 1) && this.addDeal(e)}>
                                    {this.tr("Add deal")}
                                </a></p>
                            : <p>{this.tr("There are no deals")}</p>
                        }   
                    </div>
                </div> : undefined }
                <div className="grid-item">
                    <div className="header">
                        <Button className="button" size="small" variant="text" onClick={this.handleToggle}>{this.tr("Won Deals Overview")} {!open ? <ExpandMore /> : <ExpandLess />}</Button>
                        <DateRangePicker
                            className="daterange"
                            ranges={[dateRange]}
                            onChange={this.onDateChange}
                            onInputChange={this.onDateInputChange}
                            label={this.tr("Time span")}
                            dateFormat={userObject.dateFormat} />                            
                    </div>
                    {open && (
                        <React.Fragment>
                            {
                                // <div ref={this.element} className={`wrapper ${this.state.elementWidth <= 1230 ? "divider" : ""}`}  >
                            }
                            <div ref={this.element} className={`wrapper`}  > 
                                <div className="chart-area">
                                    <Bar key={"chart"} id ="bars" ref={this.chart}
                                        data={dealData}
                                        height={400}
                                        options={{
                                            onClick: (bar, chart) => {
                                                const b = this.chart.current.chartInstance.getElementAtEvent(bar);
                                             
                                                if (!b[0]){
                                                    return
                                                }
                                                const index = b[0]._index;
                                                const parameters = { 
                                                    pipeline_id: -1, 
                                                    customerId:this.props.customersId,
                                                    companies_id: this.props.company,
                                                    date: (b[0]._chart.config.data && b[0]._chart.config.data.dates[index].split('-').reverse().join('-')) + "-01" }
                                                if(b[0]._model.label != undefined) {
                                                    updateView({module: 'projects', action: 'list', ...parameters}, bar.ctrlKey || bar.metaKey); 
                                                }
                                            },
                                            maintainAspectRatio: false,
                                            responsive: true,
                                            plugins: {
                                                tooltip: {
                                                    enabled: true,
                                                    callbacks: {
                                                        label: function(tooltipItem) {
                                                            const dataset = tooltipItem.dataset;
                                                            return dataset.label + ": " + currencyFormatter(tooltipItem.raw);
                                                        }
                                                    }
                                                },
                                                legend: {
                                                    display: true,
                                                    position: 'bottom',
                                                    labels: {
                                                        boxWidth: 12,
                                                        padding: 15,
                                                        usePointStyle: true
                                                    }
                                                },
                                            },
                                            elements: {
                                                line: {
                                                fill: false
                                                }
                                            },
                                            scales: {
                                                y: {
                                                    type: 'linear',
                                                    display: true,
                                                    position: 'left',
                                                    beginAtZero: true,
                                                },
                                                x: {
                                                    stacked: false,
                                                    grid: {display: false}
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div className="summary-area">
                                    <Table className="summary">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="title-avatar"></TableCell>
                                                <TableCell className="name title">{this.tr("Name")}</TableCell>
                                                <TableCell className="title">{this.tr("Revenue")}</TableCell>
                                                <TableCell className="title">{this.tr("Margin")}</TableCell>
                                                <TableCell className="title percentage">{this.tr("Margin")} %</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {_.map(summary.sales_agents, sa => {
                                                return(
                                                <TableRow className="row">
                                                    <TableCell className="avatar">
                                                        <TaimerAvatar
                                                            name={sa.sales_agent}
                                                            id={sa.user_id}
                                                            color={sa.profile_color}
                                                        />
                                                    </TableCell>
                                                    <TableCell className="label name">{sa.user_id == 0 ? this.tr("Unassigned")  : sa.sales_agent}</TableCell>
                                                    <TableCell className="number-value">{currencyFormatter(sa.revenue)}</TableCell>
                                                    <TableCell className="number-value">{currencyFormatter(sa.margin)}</TableCell>
                                                    <TableCell className="percentage">{isNaN(sa.margin/sa.revenue) ? '- ' : Math.round((sa.margin/sa.revenue)*100)}%</TableCell>
                                                </TableRow>
                                            )})}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    </div>
            </div>
        )
    }
}

export default WonDeals;
