import DataHandler from './DataHandler';
import _ from 'lodash';

const InvoiceSendFunctions = {

	default: (x) => {
	},
	quickbooks: (rows, enqueueSnackbar, updateData) => {
		const ids = [];
		_.forEach(rows, row => {
			ids.push(row.id)
		})
		DataHandler.post({url: `quickbooks/export/invoices`}, {ids}).done(response =>{
			if(response.success > 0 && response.error == 0){
				enqueueSnackbar("Invoices exported successfully", {
					variant: "success",
				});
			} else if (response.success == 0 && response.error > 0) {
				enqueueSnackbar("Failed to export invoices", {
					variant: "error",
				});
			} else if (response.success > 0 && response.error > 0) {
				enqueueSnackbar("Failed to export some invoices", {
					variant: "error",
				});
			}
			updateData();
		});
	}
};

export default InvoiceSendFunctions;