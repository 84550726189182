import React from 'react';
import ReactDOM from 'react-dom';

import CustomFieldText from './CustomFieldText';
import CustomFieldDate from './CustomFieldDate';
import CustomFieldDropdown from './CustomFieldDropdown';
import CustomFieldRadio from './CustomFieldRadio';
import CustomFieldMultiselect from './CustomFieldMultiselect';
import CustomFieldLink from './CustomFieldLink';

import _, { cloneDeep, isEqual } from 'lodash';

const fieldTypes = {
    text: CustomFieldText,
    dropdown: CustomFieldDropdown,
    date: CustomFieldDate,
    radio: CustomFieldRadio,
    multiselect: CustomFieldMultiselect,
    link: CustomFieldLink,
}

class CustomFieldList extends React.Component {
    static defaultProps = {
        disableOptional: false,
        disabled: false,
        errors: {},
    };

    isVisible = undefined;

    constructor(props, context) {
        super(props, context);

        this.refEndMarker = React.createRef();
        this.selectRefs = {};

        this.state = {
            fields: this.filterFields(cloneDeep(this.props.fields)),
            menuPlacements: {},
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        window.addEventListener("fieldEditSliderScrolled", this.onContentScrolled);
        this.setMenuPlacements();
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.fields, this.props.fields) || !isEqual(prevProps.project, this.props.project) || prevProps.form != this.props.form) {
            this.setState({
                fields: this.filterFields(cloneDeep(this.props.fields))
            }, () => {
                this.setMenuPlacements();
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
        window.removeEventListener("fieldEditSliderScrolled", this.onContentScrolled);
    }

    onWindowResize = () => {
        if (this.menuPlacementTimeout) {
            clearTimeout(this.menuPlacementTimeout);
        }
        this.menuPlacementTimeout = setTimeout(() => {
            this.setMenuPlacements();
        }, 500);
    };

    onContentScrolled = () => {
        this.setMenuPlacements();
    };

    filterFields = (fields) => {
        const { values, hideOptional, form, project } = this.props;
        let shown = [];
        if (form) {
            shown = _.sortBy(fields.filter(x => x.form == form), 'form_order');
        } else {
            shown = _.sortBy(fields.filter(x => x.show_in_details), 'form_order');
        }
        shown = shown.filter(field => {
            const value = values[field.id] || '';
            const validate = this.shouldValidateField(field)
            if(hideOptional && (!field.required || (!!project && !validate))) return false;
            if (field.deleted && !value) return false;
            return true;
        })
        return shown;
    }

    getVisibleFields = () => {
        return this.state.fields;
    }

    setMenuPlacements = () => {
        let menuPlacements = {};
        Object.keys(this.selectRefs).forEach((key) => {
            const selectRef = this.selectRefs[key];
            const domNode = ReactDOM.findDOMNode(selectRef);
            const windowHeight = window.innerHeight;
            const fieldBottom = (domNode?.getBoundingClientRect && domNode?.getBoundingClientRect())?.bottom || 0;
            let menuHeight = 0;
            const field = (this.state.fields || []).find((f) => `custom_${f.id}` == key);
            if (field) {
                const options = field.options;
                menuHeight = Math.min(400, (options || []).length * 46);
            }
            const menuPlacement = fieldBottom + menuHeight > windowHeight - 50 ? 'top' : 'bottom';
            menuPlacements = {
                ...menuPlacements,
                [key]: menuPlacement,
            };
        });
        this.setState({ menuPlacements });
    };

    shouldValidateField = (field) => {
        const { project, projects_sales_states } = this.props;
        let pipelines, statesToValidate = [];
        if (project) {
            pipelines = [0, Number(project.projects_pipelines_id) > 0 ? parseInt(project.projects_pipelines_id) : Number(project.status) * -1];
            const currentSalesSate = projects_sales_states.find(x => x.id == project.projects_sales_states_id);
            statesToValidate = currentSalesSate && (!!project.id || currentSalesSate.stateorder > 1) ? 
                                    projects_sales_states.filter(x => this.props.notCurrentStage ? x.stateorder < parseInt(currentSalesSate.stateorder) 
                                                                                                 : x.stateorder <= parseInt(currentSalesSate.stateorder)
                                    ).map(x => parseInt(x.id)) : [];
        }
        let validate = project && (
            (statesToValidate.indexOf(field.projects_sales_states_id) > -1 || field.projects_sales_states_id === 0) && 
            pipelines.indexOf(field.projects_pipelines_id) > -1
        );
        if (this.props.noValidate) validate = true;
        return validate;
    }

    render() {
        const { project, values, disabled, disableOptional, errors, onChange, disableDropdown, header, setSelectRef } = this.props;
        const { fields, menuPlacements } = this.state;
                                    
        this.selectRefs = {};

        return <React.Fragment>
            {fields.length > 0 && header && <h3>{header}</h3>}
            {fields.map(field => {
                const name = `custom_${field.id}`;
                const value = values[field.id] || '';
                const isDisabledDropdown = field.type == "dropdown" && disableDropdown;
                const validate = this.shouldValidateField(field)
                
                const El = fieldTypes[field.type] || CustomFieldText;

                return El && <El
                    ref={field.type == 'dropdown' ? (ref) => {
                        this.selectRefs = {
                            ...this.selectRefs,
                            [name]: ref,
                        };
                    } : undefined}
                    key={name}
                    name={name}
                    field={field}
                    className={isDisabledDropdown ? "unmounted-disabled-dropdown" : ""}
                    value={value}
                    hideNoValueError={this.props.hideNoValueError}
                    disabled={disabled || (disableOptional && (!field.required || (!!project && !validate))) || isDisabledDropdown}
                    onChange={onChange}
                    validate={validate}
                    noOnchangeValidation={field.type == "link"}
                    error={!disabled && errors[field.id]}
                    menuPlacement={menuPlacements[name]} />
            })}

            <div ref={this.refEndMarker} />
        </React.Fragment>
    }
}

export default CustomFieldList;