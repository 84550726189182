
/* css */
import "./ProductListRow.css";

/*material-ui*/
import MenuItem from '@mui/material/MenuItem';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Tooltip from '@mui/material/Tooltip';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';

/* others */
import React from 'react';
import ListCell from "../ListCell";
import DataHandler from '../../general/DataHandler';
import ContextMenu from '../../general/ContextMenu';
import CheckboxCell from "../cells/CheckboxCell";
import TextInputCell from "../cells/TextInputCell";
import TextInput from "../../general/TextInput";
import AutoCompleteCell from "../cells/AutoCompleteCell";
import CurrencyListCell from '../CurrencyListCell';
import BranchIndicator, { hasFurtherSiblings } from "../../general/BranchIndicator";
import { withSnackbar } from 'notistack';
import _ from 'lodash';
import PropsOnlyListRow from "../PropsOnlyListRow";
import LinkListCell from "../LinkListCell";
import {  TextField } from '@mui/material';
import Select from 'react-select';
import { AddCircleOutline,RemoveRedEye,WarningSharp } from "@mui/icons-material";
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import ClickAwayWrapper from "../../general/ClickAwayWrapper";
import { formatInputNumber } from '../../helpers';
import VersionContentManager from "../../general/VersionContentManager";

class TreeOption extends React.Component {
	static defaultProps = {
		rowHeight: 20,
		indentWidth: 20,
		inList: true,
		onSelect: () => {}
	};

	constructor(props) {
		super(props);
		this.wrapper 		  = React.createRef();
		this.branchIndicators = [];
		this.state 			  = { useTooltip: false };
		this.initialize = this.initialize.bind(this);
		this.initialize();
	}

	initialize() {
		for(let i = 0; i < this.props.level; ++i) {
			let hasMoreSiblings = this.props.level > 0 ? hasFurtherSiblings(this.props, this.props.level - i) : false;
			let noBottom	 	= i === this.props.level - 1 && this.props.level > 0 && this.props.isLastChild;

			this.branchIndicators.push(
				<BranchIndicator
					inList={this.props.inList}
					rowHeight={this.props.rowHeight}
					width={this.props.indentWidth}
					renderHor={i === this.props.level - 1}
					renderNothing={!hasMoreSiblings && !noBottom && i !== this.props.level - 1}
					noBottom={noBottom}
					key={`${this.props.data.value}-${i}`} />
			);
		}
	}


	componentDidMount() {
		// Only show the tooltip if the width of the element exceeds the width of the parent container.
		(this.wrapper.current.scrollWidth > this.wrapper.current.parentNode.clientWidth) && (this.setState({ useTooltip: true }));
	}

	render() {
		const Wrapper 	   = this.state.useTooltip ? Tooltip : React.Fragment;
		const wrapperProps = this.state.useTooltip ? { title: this.props.data.name, placement: "left" } : {};
		const className    = `treeOption ${this.props.isFocused ? "focused" : ""} ${this.props.isSelected ? "selected" : ""}`;

		return (
			<Wrapper {...wrapperProps}>
				<div ref={this.wrapper} onMouseDown={e => this.props.onSelect(this.props.data)} onTouchStart={e => this.props.onSelect(this.props.data)} className={className} style={{ height: "20px", lineHeight: "20px" }}>
					{this.branchIndicators}
					<span style={{ height: "20px", lineHeight: "20px" }}>{this.props.data ? this.props.data.name : ""}</span>
				</div>
			</Wrapper>
		);
	}
}


class ProductListRow extends PropsOnlyListRow {
	constructor(props) {
		super(props, null, undefined, "list/rows/ProductListRow");

		const balanceActionHeight = "40px";
		const balanceActionSelectedColor = "rgba(0, 0, 0, 0.14)";

		this.balanceActionSelectStyle = {
			control: (provided, state) => ({
				...provided,
				minHeight: balanceActionHeight,
				height: balanceActionHeight,
				boxShadow: state.isFocused ? null : null,
				border: "1px solid #dde3e8"
			}),
			valueContainer: (provided, state) => ({
				...provided,
				height: balanceActionHeight,
				padding: '0 6px',
				fontSize: "13px"
			}),

			input: (provided, state) => ({
				...provided,
				margin: '0px',
			}),
			indicatorSeparator: state => ({
				display: 'none',
			}),
			indicatorsContainer: (provided, state) => ({
				...provided,
				height: balanceActionHeight,
			}),
			option: (provided, state) => ({
				...provided,
				padding: 0,
				color: "#6b7897",
				textAlign: "left",
				paddingLeft: "10px",
				backgroundColor: state.isSelected ? balanceActionSelectedColor : "white",
    			':active': {
      				backgroundColor: state.isSelected ? balanceActionSelectedColor : "white"
				},
				':hover': {
					backgroundColor: state.isSelected ? balanceActionSelectedColor : "rgba(44, 159, 247, 0.08)",
					cursor: "pointer"
				}
			}),
		};

        this.popup          = React.createRef();
        this.popupTextInput = React.createRef();
		this.popupTextField = React.createRef();

		this.balanceActions = [
			{id: 0, value: 0, label: this.tr("Replace"), name: "Replace"}, 
			{id: 1, value: 1, label: this.tr("Update"), name: "Update"}
		];

        this.balanceTempState = {
            balance: props.data.balance,
			comment: undefined,
			action: this.balanceActions[0]
		};

		this.numericValidation = ["balance", "vat", "cost_price", "income_price", "discount_percent"]
	}

	codeIsNotUnique = (data) => {
		data["_invalidField_name"] = false; 
		data["_invalidField_code"] = false; 
		this.numericValidation.forEach(e => {
			data["_invalidField_" + e] = false; 
		});

		let codeError = false;
		if(this.props.rowProps.productCodes.indexOf(data.code) > -1 && data.code !== "" && (data.code != this.props.data.code || Number(data.id) < 0)) {
			codeError = true;
			data["code"] = this.props.data.code;
			data["_invalidField_code"] = true; 
			this.setData(data);
		}
		if (codeError) {
			this.props.enqueueSnackbar(this.props.rowProps.codeError, {
				variant: "error",
			});
		}
		return codeError;
	}

	//overriding to check product code
	update = (data = false) => {
		if (this.codeIsNotUnique(data)) {
			return;	
		}
		const dataWithCatalog = {...data, catalog_id: this.props.rowProps.catalog_id};
		return super.update(dataWithCatalog).done(() => {
			setTimeout(() => {
				this.props.rowProps.updateList();
			}, 1000);
		}).fail((res) => {
			this.setInvalidFields(Object.keys(res.responseJSON));
		});
}

	//overriding to check product code
	create = (data = false) => {
		if (!this.validateAllFields()) return;
		else if (this.codeIsNotUnique(data)) return;

		const dataWithCatalog = {...data, catalog_id: this.props.rowProps.catalog_id};
		super.create(dataWithCatalog).done(() => {
			setTimeout(() => {
				this.props.rowProps.updateList();
			}, 1000);
		}).fail((res) => {
			this.setInvalidFields(Object.keys(res.responseJSON));
		});
	}

	addToCatalog = () => {
        return this.getRowCallback("onAddToCatalog")([this.props.data]);
	}

	balanceWarning = (product) => {
        return this.getRowCallback("onBalanceWarning")(product);
	}

	defineClassName() {
		return "ProductListRow";
	  }

	onCtrlS() {
		this.props.data.id < 0 ? this.create() : this.update();
	}
	
	getCatalogString = (data) => {
		const catalogs = data.catalogs || [];
		if (catalogs.length == 0) {
		  return "-";
		}
		if (catalogs.length > 1) {
		  const title = catalogs.length > 5 ? catalogs.slice(0, 5).map(c => c.name).join(", ") + ` + ${this.tr("${count} catalogs", {count: catalogs.length - 5})}` : catalogs.map(c => c.name).join(", ")
		  return (
			<Tooltip placement="bottom-start" title={title}>
			  <div>{`${this.tr("${count} catalogs", {count: catalogs.length})}`}</div>
			</Tooltip>
		  );
		} else {
		  return `${this.tr("1 catalog")} (${catalogs[0].name})`;
		}
	  };

	  getSupplierString = (data) => {
		const suppliers = data.suppliers || [];
		if (suppliers.length == 0) {
		  return "-";
		}
		if (suppliers.length > 1) {
		  const title = suppliers.length > 5 ? suppliers.slice(0, 5).join(", ") + ` + ${this.tr("${count} suppliers", {count: suppliers.length - 5})}` : suppliers.join(", ")
		  return (
			<Tooltip placement="bottom-start" title={title}>
			  <div>{`${this.tr("${count} suppliers", {count: suppliers.length})}`}</div>
			</Tooltip>
		  );
		} else {
		  return `${this.tr("1 supplier")} (${suppliers[0]})`;
		}
	  };

	fieldEdited(id, name, value, validation) {
		const writePrivileges = this.props.sharedData['write'];

		if (id > 0)
			this.validateField(name, value, validation)
		else 
			writePrivileges && this.setDataAndUpdate(name, value);
	}

	calculateIncomePrice = (id, value) => {
		let newPrice = parseFloat(this.props.data['cost_price']) + (value * this.props.data['cost_price'] / 100);
		this.fieldEdited(id, "income_price", newPrice, "income_price");
	}

	validateField(name, value, validation, noUpdate = false) {
		const writePrivileges = this.props.sharedData['write'];
		
		if (validation === "empty" && (!value || value.trim() === "")) {
			this.showFieldError(name, name.charAt(0).toUpperCase() + name.slice(1) + " cannot be empty")
			return false;
		}

		if ((validation === "numeric" || validation === "percent") && !Number(value) && Number(value) !== 0) {
			this.showFieldError(name, "Invalid number")
			return false;
		}

		if (validation === "percent" && (Number(value) < 0 || Number(value) > 100)) {
			this.showFieldError(name, "Invalid percent")
			return false;
		}

		if (noUpdate) return true;

		if (name == "percentage_increase" && writePrivileges)
			this.calculateIncomePrice(this.props.data.id, value);
		else if (writePrivileges)
			this.setDataAndUpdate(name, value);	
	}

	showFieldError(name, message) {
		let data = {};
		this.props.enqueueSnackbar(this.tr(message), {
			variant: 'error'
		});
		data["_invalidField_" + name] = true; 
		this.setData(data);
	}

	validateAllFields() {
		let invalidFields = [];
		let nameValid = true;
		let integration_idValid = true;
		let invalidDiscount = false;
		let data = {};
		data["_invalidField_code"] = false; 
		data["_invalidField_name"] = false;
		data["_invalidField_integration_id"] = false; 

		this.numericValidation.forEach(e => {
			data["_invalidField_" + e] = false; 

			if (this.props.data[e] && !Number(this.props.data[e]) && Number(this.props.data[e]) !== 0) {
				invalidFields.push(e);
				data["_invalidField_" + e] = true; 
			}
			else if ((e === "discount_percent" || e === "vat") && (this.props.data[e] && Number(this.props.data[e]) < 0 || Number(this.props.data[e]) > 100)) {
				invalidDiscount = true;
				data["_invalidField_" + e] = true; 
			}
		})
		
		if (!this.props.data.name || this.props.data.name.trim() === "") {
			nameValid = false;
			data["_invalidField_name"] = true; 
			this.props.enqueueSnackbar(this.tr('Name cannot be empty'), {
				variant: 'error'
			});
		}
		if (this.context.addons.venda && (!this.props.data.integration_id || this.props.data.integration_id.trim() === "")) {
			integration_idValid = false; 
			data["_invalidField_integration_id"] = true; 
			this.props.enqueueSnackbar(this.tr('Integration ID cannot be empty'), {
				variant: 'error'
			});
		}
		
		this.setDataAndUpdate(data);

		if (invalidFields.length > 0 || !nameValid || invalidDiscount || (this.context.addons.venda && !integration_idValid)) {
			if (invalidFields.length > 0) {
				this.props.enqueueSnackbar(this.tr('Invalid numbers'), {
					variant: 'error'
				});
			}
			if (invalidDiscount) {
				this.props.enqueueSnackbar(this.tr('Invalid percent'), {
					variant: 'error'
				});
			}
			return false;
		} else
			return true;
	}

    saveBalance = () => {
		let { balance, comment, action } = this.balanceTempState;

		balance = String(balance).replace(",", ".");
		
		if (!this.validateField("balance", balance, "numeric", true)) {
			this.setData({ _balanceEditMode: false })
			return;
		}
		
		let newBalance = balance;
		if (action.value === 1)	
			newBalance = Number(this.props.data['balance']) + Number(balance);

		this.balanceTempState = {
			balance: newBalance, 
			comment: undefined,
			action: this.balanceActions[0]
		};

        this.setDataAndUpdate({ balance: newBalance, balance_msg: comment, _balanceEditMode: false });
    }

	defineCells() {
		const { rowProps : { currency, addProductsToCatalogMode }, data } = this.props;
		const writePrivileges = this.context.functions.checkPrivilege("products", "write", this.props.data.companies_id) && !addProductsToCatalogMode;
		let bob_id = data['product_types_id'];
		let group_string = "";
		let cont = true;

		while(cont) {
			cont = false;

			this.props.sharedData != false &&
			this.props.sharedData.flatgroups.forEach((bob) => {
				if(bob.id == bob_id) {
					if(group_string == "") group_string = bob.name;
					else group_string = bob.name + " > " + group_string;
					bob_id = bob.parent_id;
					cont = true;
				}
			});
		}
		let balanceClass = Number(data.lower_warning_alert) || Number(data.higher_warning_alert) > 0 ? "balance-alert" : (Number(data.balance_warning) > 0 ? "balance-warning" : "");
		if (this.props.data['_invalidField_balance'])
			balanceClass += " error-field";

		let percentageIncrease = Number(data['cost_price']) > 0 ? Math.round(data['percentage_increase']) : '0';

		const rowVatcode = Number(data["sales_vatcode"]) ? this.props.sharedData.vatcodes?.find(v => v.id == data["sales_vatcode"]) : false;
        const vatEditable = !rowVatcode && !this.props.sharedData?.salesAccounts?.find(a => a.id == data.sales_account && a.integration == "talenom");

		const cells = {
			expand:
				data['id'] > 0 ?
				<ContextMenu label={<MoreHoriz />}  buttonProps={{className: 'action-menu'}} className="cell row-menu" style={{width: this.props.columnWidthMap['expand'] + "px", textAlign: "center"}} noExpandIcon>
						<MenuItem  onClick={() => {this.props.rowProps.openProductSlider(data)}}   >
							<RemoveRedEye />
							{this.tr("View Product Card")}
						</MenuItem>	
						{writePrivileges ? 
						<>
						{data.parent_id == 0 && !VersionContentManager.isFeatureHidden(this.namespace, 'catalogs') && <MenuItem onClick={() => {this.addToCatalog()}}>
							<AddCircleOutline />
							{this.tr("Add To Catalog")}
						</MenuItem>}
					{/* 	<MenuItem  >
							<AddCircleOutlineSharp />
							{this.tr("Add To CPQ")}
						</MenuItem> */}
						<MenuItem  onClick={() => {this.balanceWarning(data)}} >
							<WarningSharp />
							{this.tr("Get Balance Warning")}
						</MenuItem>
					{/* 	<MenuItem  >
							<Delete />
							{this.tr("Dublicate")}
						</MenuItem> */}
						<MenuItem className="delete" onClick={this.delete}>
							<RemoveIcon className="Delete" />
							{this.tr("Delete")}
						</MenuItem>
						</> :
						<MenuItem className="delete" disabled>
							<RemoveIcon className="Delete" />
							{this.tr("Delete")}
						</MenuItem>
					}
				</ContextMenu>
				:
				<ListCell tabIndex="1" onlyDisplay={true} width={this.props.columnWidthMap['expand']}>
					<Tooltip title="Save" placement="bottom">
						<CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.create(this.props.data)} />
					</Tooltip>
				</ListCell>,
			checked:
				data['id'] > 0 ?
				<CheckboxCell checked={this.props.checked} width={this.props.columnWidthMap['checked']} onClick={() => {this.props.listRef.check(this.props.data.id);}} />
				:
				<ListCell width={this.props.columnWidthMap.checked} permanentEditMode={true} onlyDisplay={true}>
					<Tooltip title="Cancel" placement="bottom">
						<Cancel className="cancelIcon" onClick={() => this.props.listRef.removeRow(data.id)} />
					</Tooltip>
				</ListCell>,
			code:
				<TextInputCell
					className={this.state.codeError ? "error" : ""}
					listCellProps={{
                        inEditMode: data.id < 0,
                        showErrorBorder: this.props.data['_invalidField_code']
					}}
					width={this.props.columnWidthMap['code']}
					name="code"
					value={data['code']}
					onEdited={writePrivileges ? (v) => this.setDataAndUpdate("code", v) : undefined}
					editable={writePrivileges && (data.id < 0 || data.parent_id == 0)}
					/>,
			catalog:
				<LinkListCell
					width={this.props.columnWidthMap.catalog}
					handleClick={() => this.props.rowProps.openCatalogSlider(data)}
					asText={(data.catalogs || []).length == 0}
					value={this.getCatalogString(data)}
					noTab={true}
					editable={false}
				/>,
			/*
			supplier:
				<AutoCompleteCell
					editable={writePrivileges}
					 />,
			*/
			inventory_cost_value:
					<TextInputCell
						listCellType={CurrencyListCell}
						listCellProps={{
							inEditMode: false,
							currency: currency,
							textAlign: "right"
						}}
						editable={false}
						width={this.props.columnWidthMap['inventory_cost_value']}
						name="inventory_cost_value"
						value={data['inventory_cost_value']}
						/>,
			inventory_sales_value:
						<TextInputCell
							listCellType={CurrencyListCell}
							listCellProps={{
								inEditMode: false,
								currency: currency,
								textAlign: "right"
							}}
							editable={false}
							width={this.props.columnWidthMap['inventory_sales_value']}
							name="inventory_sales_value"
							value={data['inventory_sales_value']}
						/>,
			name:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0,
						handleClick:  () => this.props.rowProps.openProductSlider(data),
						showErrorBorder: this.props.data['_invalidField_name']
					}}
					listCellType={LinkListCell}
					width={this.props.columnWidthMap['name']}
					name="name"
					allowEmpty={false}
					onEmptyInput={() => {
						if (data.id > 0) {
							this.validateField("name", "", "empty");
						}
						else {
							this.setDataAndUpdate("name", "");
						}
                    }}
					value={data['name']}
					onEdited={(name, value) => this.validateField(name, value, "empty")}
					editable={writePrivileges && (data.id < 0 || data.parent_id == 0)} />,
			product_types_id:
				<AutoCompleteCell
					listCellProps={{
						showTooltipForOverflownText: true,
						className: "product_types_id"
					}}
					editable={writePrivileges}
					autoCompleteData={this.props.sharedData.bobOptions}
					name="product_types_id"
					value={data.id < 0 ? Number(data.product_types_id) : {id: -1, name: group_string}}
					width={this.props.columnWidthMap['product_types_id']} 
                    rowHeight={20}
					components={{ Option: props => {
						const passProps = {
							isDisabled: props.isDisabled,
							isFocused: 	props.isFocused,
							isMulti: 	props.isMulti,
							isSelected: props.isSelected
						};

						return <TreeOption {...props.data} {...passProps} onSelect={bob => {
							props.setValue(bob.id)
							this.setDataAndUpdate("product_types_id", bob.id);
						}} />;
					}}} />,
			supplier:
				<LinkListCell
					listCellProps={{
						inEditMode: false
					}}
					width={this.props.columnWidthMap['supplier']}
					name="supplier"
					editable={false}
					handleClick={() => this.props.rowProps.openProductSlider(data, "supplier_details")}
					asText={(data.suppliers || []).length == 0}
					value={this.getSupplierString(data)}
					noTab={true}
				/>,
			balance:
				<ListCell 
					controlEditMode={true}
					listCellProps={{
                        showErrorBorder: this.props.data['_invalidField_balance']
					}}
					value={this.props.data.balance || "0"}
					className={balanceClass}
					inEditMode={this.props.data['_balanceEditMode']}
					editable={writePrivileges}
                    onFocus={() => {
                        this.setData("_balanceEditMode", true);
                        setTimeout(() => {
							if (this.popupTextInput.current)
                            	this.popupTextInput.current.focus();
                        });
                    }}>
                    <ClickAwayWrapper className="balance-comment" active={this.props.data['_balanceEditMode']} onClickAway={event => {
						const path = event.path || (event.composedPath && event.composedPath());
                        if(path && path.indexOf(this.popup.current) > -1) {
                            return;
                        }

                        this.saveBalance();
                    }}>
						<div>
							<TextInput 
								ref={this.popupTextInput}
								initValue={this.props.data.balance}
								placeholder={this.balanceTempState.action.value === 1 ? this.tr("Amount") : ""}
								onInputListener={value => {
									this.balanceTempState.balance = value;
								}}
								onEnter={this.saveBalance} 
							/>
							<div ref={this.popup} style={{
								zIndex: 10,
								width: 170,
								position: "absolute",
								background: "#fff",
								boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.18)",
								border: "solid 1px #e7eced",
								padding: 8,
								top: 55,
								left: 0,
								textAlign: "left"
							}}>
								<Select
									className="balance-action"
									isSearchable={false}
									styles={this.balanceActionSelectStyle}
									options={this.balanceActions}
									defaultValue={this.balanceTempState.action}
									onFocus={(e) => {
										e.preventDefault();
										e.stopPropagation();
									}}
									onChange={(val) => {
										this.balanceTempState.action = val;
										if (val.value === 1) 
											this.balanceTempState.balance = "";
										else 
											this.balanceTempState.balance = this.props.data.balance;

										if (this.popupTextInput.current) {
											this.popupTextInput.current.updateValue(this.balanceTempState.balance);
											this.popupTextInput.current.focus();
										}
									}}
								/>
								<span className="balance-comment">{this.tr("Add comment to balance log")}:</span>
								<TextField
									className="balance-comment-input"
									multiline
									ref={this.popupTextField}
									placeholder={this.tr("Comment...")}
									size="large"
									inputProps={{
										onFocus: (e) => {
											e.preventDefault();
											e.stopPropagation();
										}
									}}
									onChange={e => this.balanceTempState.comment = e.target.value}
									/>
							</div>
						</div>
                    </ClickAwayWrapper>
                </ListCell>,
			  	// <TextInputCell
                    // listCellType={PopUpListCell}
					// listCellProps={{
						// inEditMode: data.id < 0,
						// showErrorBorder: this.props.data['_invalidField_balance'],
					// }}
                    // textInputProps={{
                        // onFocus: () => {
                           //  
                        // }
                    // }}
					// validation={['numeric']}
					// width={this.props.columnWidthMap['balance']}
					// name="balance"
					// value={data['balance']}
					// onEdited={(name, value) => this.fieldEdited(data.id, name, value, "numeric")}
                    // editable={writePrivileges}
					 // />, 
            
			freeBalance:
			 	<TextInputCell
					listCellProps={{
						inEditMode: false
					}}
					validation={['numeric']}
					width={this.props.columnWidthMap['freeBalance']}
					name="freeBalance"
					value={data['freeBalance'] || "0"}
					editable={false}
					 />, 
					 
			cost_price:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: data.id < 0,
						currency: currency,
						showErrorBorder: this.props.data['_invalidField_cost_price'],
						textAlign: "right"
					}}
					validation={['numeric']}
					width={this.props.columnWidthMap['cost_price']}
					name="cost_price"
					value={data['cost_price']}
					onEdited={(name, value) => this.fieldEdited(data.id, name, value, "numeric")}
					editable={writePrivileges}
					 />,
			income_price:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: data.id < 0,
						currency: currency,
						showErrorBorder: this.props.data['_invalidField_income_price'],
						textAlign: "right"
					}}
					validation={['numeric']}
					width={this.props.columnWidthMap['income_price']}
					name="income_price"
					value={data['income_price']}
					onEdited={(name, value) => this.fieldEdited(data.id, name, value, "numeric")}
					editable={writePrivileges}
					 />,
			percentage_increase:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0,
						textAlign: "right",
						showErrorBorder: this.props.data['_invalidField_percentage_increase'],
					}}
					width={this.props.columnWidthMap['percentage_increase']}
					name="percentage_increase"
					value={percentageIncrease}
					validation={['numeric']}
					onEdited={(name, value) => this.fieldEdited(data.id, name, value, "numeric")}
					editable={writePrivileges && data['cost_price'] > 0}
				/>,
			vat:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0,
						showErrorBorder: this.props.data['_invalidField_vat'],
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['vat']}
					name="vat"
					value={data['vat']}
					validation={['percent100', 'numeric']}
					onEdited={(name, value) => this.fieldEdited(data.id, name, value, "percent")}
					editable={writePrivileges && vatEditable}
					 />,
			discount_percent:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0,
						showErrorBorder: this.props.data['_invalidField_discount_percent'],
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['discount_percent']}
					name="discount_percent"
					validation={['percent100', 'numeric']}
					value={formatInputNumber(data.discount_percent)}
					onEdited={(name, value) => this.fieldEdited(data.id, name, value, "percent")}
					editable={writePrivileges}
					 />,
			unit:
				<AutoCompleteCell
					width={this.props.columnWidthMap['unit']}
					value={(data["unit_id"] && this.props.sharedData.units.findIndex(u => u.id == data["unit_id"]) != -1) ? Number(data["unit_id"]) : {id: -1, name: data["unit"]}}
					autoCompleteData={this.props.sharedData.units}
					allowCreate={true}
					name="unit"
					menuPlacement="bottom"
					editable={writePrivileges}
					onEdited={value => {
					 if(value.name) {
						this.setDataAndUpdate({"unit_id": value.id, "unit": value.name});
					 } else {
						 this.setData({"unit_id": null, "unit": value.value})
						DataHandler.post({url: 'settings/company/product/units'}, { id: -1, name: value.value, deleted: 0, companies_id: data.companies_id }).done(response => {
							this.setDataAndUpdate({"unit_id": response.id, "unit": response.name});
						});
					 }	 	
					}}
					/>,
			comment:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0
					}}
					width={this.props.columnWidthMap['comment']}
					name="comment"
					value={data['comment']}
					onEdited={writePrivileges ? (v) => this.setDataAndUpdate("comment", v) : undefined}
					editable={writePrivileges}
				/>,
			integration_id:
				<TextInputCell
					listCellProps={{
						inEditMode: data.id < 0,
						showErrorBorder: this.props.data['_invalidField_integration_id']
					}}
					width={this.props.columnWidthMap['integration_id']}
					name="integration_id"
					value={data['integration_id']}
					validation={this.context.addons.venda ? ['empty'] : undefined}
					onEdited={writePrivileges ? this.context.addons.venda ? (name, value) => this.fieldEdited(data.id, name, value, "empty") : (v) => this.setDataAndUpdate("integration_id", v) : undefined}
					editable={writePrivileges}
				/>
			}

		this.props.sharedData.accountingFields?.forEach(f => {
			const rowData = Number(data[f.name]) ? this.props.sharedData[f.optionsName]?.find(v => v.id == data[f.name]) : false;

			const field = <AutoCompleteCell
				width={this.props.columnWidthMap[f.name]}
				value={rowData ? data[f.name] : ""}
				autoCompleteData={this.props.sharedData[f.optionsName]}
				name={f.name}
				menuPlacement="bottom"
				editable={writePrivileges}
				onEdited={value => {
					const update = {
						[f.name]: value.id
					}
					if (f.name == "sales_account" && value.integration == "talenom") {
						this.props.enqueueSnackbar(this.tr("Product VAT % updated from sales account!"), {
							variant: "info",
						});
						update.vat = value.vat_value || 0;
					}
					if (f.name == "sales_vatcode") {
						update.vat =  value.percentage;
					}
					this.setDataAndUpdate({ ...update });
				}}
			/>

			cells[f.name] = field;
		})

		this.props.sharedData.dimensionHeaders?.forEach(h => {
			const headerDimensionValue = data.dimension_values?.find(v => v.header_id == h.id) || { dimension_id: 0 };
			const fieldvalue = (h.options || []).find(o => o.id == headerDimensionValue?.dimension_id);
            const name = "dimension-" + h.id;

			const field = <AutoCompleteCell
				width={this.props.columnWidthMap[name]}
				value={fieldvalue}
				autoCompleteData={h.options}
				name={name}
				menuPlacement="bottom"
				editable={writePrivileges}
				onEdited={value => {
					let rowDimensionValues = _.cloneDeep(data.dimension_values) || [];
					const valueIndex = rowDimensionValues.findIndex(i => i.header_id == h.id);
					if (valueIndex > -1) {
						rowDimensionValues[valueIndex] = { header_id: h.id, dimension_id: value.id };
					}
					else {
						rowDimensionValues.push({ header_id: h.id, dimension_id: value.id });
					}

					this.setDataAndUpdate({ "dimension_values": rowDimensionValues });
				}}
			/>

			cells[name] = field;
		})

		return cells;
	}

	// render() {

	// 	const childRows = this.createChildren(this.props.children, ProductListRow);

	// 	return (
	// 		<div className="listElement mainLevel">
	// 			{showDialog && <DeleteContactDialog id={[this.state.data.id]} onClose={() => {this.setState({showDialog: false}); this.props.rowProps.fetchData()}} />}
	// 			<div style={{height: "44px", lineHeight: "44px"}} className={className} >
	// 				<FocusGroup 
	// 					ref={this.focusGroup} 
	// 					onCtrlS={() => {
	// 						this.state.data.id < 0 ? this.saveNewRow() : this.cellEdited();
	// 					}}
	// 					focusOnInit={this.state.data.id < 0}
	// 					columnOrder={this.props.columnOrder}>
	// 					{this.props.columnOrder.map(columnName => {
	// 						const cell = cells[columnName];

	// 						return columnName === "expand" || columnName === "checked" ? cell : React.cloneElement(cell, {
    //                             listCellProps: { 
    //                                 ...(cell.props.hasOwnProperty("listCellProps") ? cell.props.listCellProps : {}), 
    //                                 showErrorBorder: this.state.invalids.indexOf(columnName) > -1 || (cell.props.listCellProps && cell.props.listCellProps.showErrorBorder) 
    //                             }
	// 						});
	// 					})}
	// 				</FocusGroup>
	// 			</div>
	// 			{childRows !== false && <div className="listElement">{childRows}</div>}
	// 		</div>
	// 	);
	// }
}

export default withSnackbar(ProductListRow);
