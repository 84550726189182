/* react */
import React from 'react';

/* css */
import './Efina.css';

/* material-ui */
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import TaimerComponent from "../../TaimerComponent";
import OutlinedField from '../../general/OutlinedField';

/* others */
import TrashCanIcon from "@mui/icons-material/Delete";
import { MenuItem } from '@mui/material';
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import List from "../../list/List";
import ListCell from "../../list/ListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import SettingRow from "../../list/rows/SettingRow";

class AccountRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            account_number: <TextInputCell
                width={this.props.columnWidthMap['account_number']}
                name="account_number"
                value={this.state.data['account_number']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}


class DATEV extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/DATEV");

        this.state = {
            salesAccounts: [],
        };

        this.salesAccountsList = React.createRef();
        this.purchasesAccountsList = React.createRef();
        this.productsList = React.createRef();
        this.dimensionHeadersList = React.createRef();
        this.dimensionsList = React.createRef();
        this.ratiosList = React.createRef();

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getSalesAccounts();
        this.getAccountingDefaults();
    }

    getSalesAccounts = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/datev/1`}).done(response => {
            this.setState(response);
        });
    }

    getAccountingDefaults = () => {
        DataHandler.get({url: `accounting/defaults/${this.props.company}`}).done(response => {
            this.setState(response);
        });
    }

    changeDefault = (type, id) => {
        const company = this.props.company;
        this.setState({[type]: id}, () => DataHandler.put({url: `accounting/defaults/${company}`}, {type: type, id: id }));
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        DataHandler.post({url: `settings/integrations/datev/${this.props.company}/deactivate`}).done(this.back);
    }
    
    render(){
        const { tr } = this;

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("DATEV Settings")}</h3>
                    {this.context.addons?.datev?.used_by_companies?.indexOf(this.props.company) > -1 && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("Sales accounts")}</h4>
                    <Button className="green" onMouseUp={() => this.salesAccountsList.current.addNewRow()} size="large">{tr("Add account")}</Button>
                    <br />
                    <List
                        ref={this.salesAccountsList}
                        id="accountingSalesAccountList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            account_number: "",
                        }}
                        listRowType={AccountRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Account name"), ...this.dColConf },
                            { width: 200, name: "account_number", header: tr("Account number"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                DataHandler.post({url: `accounting/accounts/${this.props.company}/datev/1`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url:  `accounting/accounts/${this.props.company}`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            },
                            onDelete: data => {
                                if(data.id == this.state.default_accounting_accounts_id) {
                                    this.props.enqueueSnackbar(this.tr("Default account cannot be deleted"), {
                                        variant: "error",
                                    });	
                                    return;
                                }
                                data.deleted = "1";
                                DataHandler.delete({url:  `accounting/accounts/${this.props.company}`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            }
                        }}
                        data={this.state.salesAccounts}
                    />

                    <div className="drop-section">
                        <h4>{tr("Default sales account")}</h4>
                        <OutlinedField select disabled={false} label={tr("Default")} name={"default"} value={this.state.default_accounting_accounts_id} fullWidth >
                            <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_accounts_id", 0)}>{this.tr("No default")}</MenuItem>
                            {this.state.salesAccounts.map(({name, id}) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_accounts_id", id)}>{name}</MenuItem>)}
                        </OutlinedField>
                    </div>
                </div>
            </div>
        )
    }
}

export default DATEV;