
import { SettingsContext } from "../../../SettingsContext";
import TaimerComponent from "../../../TaimerComponent";

import { BlockProps, SubitemsProps } from "../../base/CustomViewBase";
import ChartBlock from "./ChartBlock";
import ChartTable from "./ChartTable";
import StackedChart, { Dataset } from "./StackedChart";

import { addMonths, getMonth, getQuarter, getYear } from "date-fns";
import endOfMonth from "date-fns/endOfMonth/index";
import startOfMonth from "date-fns/startOfMonth/index";
import _, { sum } from "lodash";
import colors from "../../../colors";
import InsightDropDown from "../../../dashboard/insights/InsightDropDown";
import { InvoicesByStatusWithDate } from "../../../projects/ProjectStatistics";
import { AllowedGrouping, getKeyFromDate } from "./GroupingHelpers";
import styles from './InvoicesOverviewChart.module.scss';

interface Props extends SubitemsProps {
    blockProps: BlockProps;
    earliestDate: Date;
    latestDate: Date;
    invoicesByStatus: InvoicesByStatusWithDate[];
    currencyFormatter: (val: number) => string;
}

interface State {
    grouping: AllowedGrouping;
}

type Datasets = Record<'draft'|'waiting'|'sent'|'overdue'|'paid'|'credited', Dataset>;

export default class InvoicesOverviewChart extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    monthlyLabels: string[];

    constructor(props, context) {
        super(props, context, "customview/blocks/Chart/InvoicesOverviewChart");

        const { tr } = this;

        this.monthlyLabels =  [
            tr("Jan"),
            tr("Feb"),
            tr("Mar"),
            tr("Apr"),
            tr("May"),
            tr("Jun"),
            tr("Jul"),
            tr("Aug"),
            tr("Sep"),
            tr("Oct"),
            tr("Nov"),
            tr("Dec")
        ];

        this.state = {
            grouping: 'month', // todo audo detect based on data
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
    }

    setGrouping = (grouping: AllowedGrouping) => {
        this.setState({
            grouping
        });
    }

    renderToolbar = () => {
        const { grouping } = this.state;

        return (<>
            <InsightDropDown
                title={this.tr('View')}
                selected={grouping}
                tabs={[
                    {
                        key: 'month',
                        label: this.tr('By Month'),
                        action: () => this.setGrouping('month'),
                    },
                    {
                        key: 'quarter',
                        label: this.tr('By Quarter'),
                        action: () => this.setGrouping('quarter'),
                    },
                    {
                        key: 'year',
                        label: this.tr('By Year'),
                        action: () => this.setGrouping('year'),
                    },
                ]} />
        </>);
    }

    render() {
        const { blockProps, invoicesByStatus, earliestDate, latestDate, currencyFormatter } = this.props;
        const { grouping } = this.state;
        const { tr } = this;

        const start = startOfMonth(earliestDate);
        const end = endOfMonth(latestDate);

        // To ensure no gaps in graph
        let date = start;

        const labels: string[] = [];
        const keyToIndex: Record<string, number> = {};
        let i = 0;

        while (date <= end) {
            const key = getKeyFromDate(date, grouping);

            if (keyToIndex[key] !== undefined) {
                date = addMonths(date, 1);
                continue;
            }

            keyToIndex[key] = i;

            if (grouping === 'quarter') {
                labels.push(`${getYear(date)} Q${getQuarter(date)}`);
            } else if (grouping === 'month') {
                labels.push(`${this.monthlyLabels[getMonth(date)]} ${getYear(date)}`);
            } else if (grouping === 'year') {
                labels.push(`${getYear(date)}`);
            }

            date = addMonths(date, 1);
            i++;
        }

        const sets: Datasets = {
            draft: {
                label: tr("Draft"),
                data: new Array(labels.length).fill(0),
                backgroundColor: '#979797',
            },
            waiting: {
                label: tr("Waiting"),
                data: new Array(labels.length).fill(0),
                backgroundColor: '#ffb822',
            },
            sent: {
                label: tr("Sent"),
                data: new Array(labels.length).fill(0),
                backgroundColor: '#2d9ff7',
            },
            overdue: {
                label: tr("Overdue"),
                data: new Array(labels.length).fill(0),
                backgroundColor: '#f52b2b',
            },
            paid: {
                label: tr("Paid"),
                data: new Array(labels.length).fill(0),
                backgroundColor: colors.greenish_cyan,
            },
            credited: {
                label: tr("Credited"),
                data: new Array(labels.length).fill(0),
                backgroundColor: '#716aca',
                hideFromTotal: true,
                totalClassName: styles.credited,
                totalDisplayAbsolute: true,
            },
        };


        for (const row of invoicesByStatus) {
            const key = getKeyFromDate(row.date, grouping);
            const index = keyToIndex[key] ?? -1;

            if (index < 0) {
                continue;
            }

            sets.credited.data[index] += row.credited;
            sets.draft.data[index] += row.draft;
            sets.overdue.data[index] += row.overdue;
            sets.paid.data[index] += row.paid;
            sets.sent.data[index] += row.sent;
            sets.waiting.data[index] += row.waiting;
        }

        const datasets = _.chain(sets)
            .map(x => ({
                ...x,
                sum: sum(x.data)
            }))
            .orderBy(x => -x.sum)
            .value();

        return (<ChartBlock
            className={styles.root}
            blockProps={blockProps}
            tableType={ChartTable}
            tableProps={{
                className: styles.table,
                datasets,
                setColumnTitle: this.tr('Status'),
                valueColumnTitle: this.tr('Amount'),
                formatValue: currencyFormatter,
            }}
            chartType={StackedChart}
            chartProps={{
                className: styles.graph,
                datasets: datasets.filter(x => x.sum !== 0),
                labels,
                formatValue: currencyFormatter,
                xStacked: false,
                yStacked: false,
            }}
            toolbarComponent={this.renderToolbar()}
            />);
    }
}