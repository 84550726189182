/* react */
import React from 'react';

/* css */
import './Efina.css';

/* material-ui */
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";

/* others */
import DataHandler from '../../general/DataHandler';
import { SettingsContext } from '../../SettingsContext';
import { MenuItem } from '@mui/material';

class RopoCapital extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/RopoCapital");

        this.state = {
            username: "",
            password: "",
            deliveryType: 0
        };

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.deliveryTypes = [
            {value: 0, label: this.tr("Automatic")},
            {value: 1, label: this.tr("E-invoice")},
            {value: 4, label: this.tr("Email invoice")},
            {value: 2, label: this.tr("Paper invoice")},
            {value: 3, label: this.tr("Print")},
        ];
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getDeliveryMethod();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/ropocapital`}).done(response => {
            this.setState(response);
        }).fail(response => {
            this.setState({"username": "", "password": "", "activated": false});
        });
    }

    onClickSave = () => {
        DataHandler.put({url: `settings/company/${this.props.company}/ropocapital`}, this.state).done(resp => {
            this.props.enqueueSnackbar(this.tr("Ropo Capital authentication information saved"), {
                variant: "success",
            });	
            this.updateComponentData();
        });
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        DataHandler.delete({url: `settings/company/${this.props.company}/ropocapital`}).done(this.back);
    }
    
    getDeliveryMethod = () => {
        DataHandler.get({ url: `settings/company/${this.props.company}/invoicedeliverytype` },)
        .done(resp => {
            this.setState({deliveryType: resp.delivery_type});
        })
        .fail(err => {});;
    };

	changeDeliveryMethod = (type) => {
        DataHandler.post({url: `settings/company/${this.props.company}/invoicedeliverytype`}, {default_invoice_delivery_type: type}).done(() => {
            this.setState({deliveryType: type});
        });
    };
    
    render(){
        const { username, password, activated } = this.state;        
        const { tr } = this;

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Ropo Capital Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("Authentication information")}</h4>
                    <OutlinedField value={username} onChange={(evt) => this.setState({username: evt.target.value})} label={tr("Customer ID (CID)")} />
                    <OutlinedField value={password} onChange={(evt) => this.setState({password: evt.target.value})} label={tr("API code")} autoComplete="new-password" />
                </div>

                <div className="settings">
                    <Button color="primary" onClick={this.onClickSave} size="large">{tr("Save")}</Button>
                </div> 

                <div className="settings">
                    <h4>{tr("Default delivery method")}</h4>
                    <OutlinedField select disabled={false} label={"Default"} name={"default"} value={this.state.deliveryType} fullWidth >
                        {this.deliveryTypes.map(({value, label}) => <MenuItem key={value} value={value} onClick={() => this.changeDeliveryMethod(value)}>{label}</MenuItem>)}
                    </OutlinedField>
                </div>
            </div>
        )
    }
}

export default RopoCapital;