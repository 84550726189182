import React from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
import { Drawer, MenuItem, Switch } from '@mui/material';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import { withSnackbar } from 'notistack';

import TaimerComponent from '../TaimerComponent';
import { SettingsContext } from './../SettingsContext';
import DataHandler from './DataHandler';
import DataList from './DataList';
import './SpecialPermissionSlider.css';

const CustomOptionStyles = {
    margin: 0,
    padding: 0,
};

const ExtraPermissionCustomOption = (props) => {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            disabled={props.isDisabled}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
                width: 530,
                minWidth: '-webkit-fill-available',
            }}
            {...props.innerProps}
        >
            <div>
                <p style={CustomOptionStyles}>{props.data.name}</p>
                {props.data.company && (
                    <p style={{ ...CustomOptionStyles, color: '#aaa' }}>
                        {props.data.translate && props.selectProps.translations[props.data.company] ? props.selectProps.translations[props.data.company] : props.data.company}
                    </p>
                )}
            </div>
        </MenuItem>
    );
};

const SettingsPermissionTag = (props) => {
    const { item, onClick } = props;
    return (
        <div onClick={() => onClick(item)} className={`special-permission-tag clickable ${item.active && 'active'}`}>
            <p>{item.name}</p>
            {item.active ? <Visibility /> : <VisibilityOff />}
        </div>
    );
};

const ExtraPermissionTag = (props) => {
    const { item, onRemove } = props;
    return (
        <div className="special-permission-tag active">
            <p>{item.company ? `${item.name} (${item.company})` : item.name}</p>
            <Close className="clickable" onClick={() => onRemove(item)} />
        </div>
    );
};

class SpecialPermissionSlider extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'general/SpecialPermissionSlider');

        this._maxTags = 6;

        this.extraPermissionTranslations = {
            corporate: this.tr('Corporate'),
            dynamic: this.tr('Dynamic'),
            freelancer: this.tr('Freelancer'),
        };

        this._permissionNames = {
            projects: {
                read: 'Read',
                write: 'Write',
                project_crm: 'Activities',
                project_resourcing: 'Gantt',
                project_cost_estimate: 'Project Quotes',
                project_billing_entries: 'Project Invoicing',
                collaborate: 'Boards',
                project_pricelist: 'Pricelist',
                attachments: 'Attachments',
                profit_loss: 'Profit & Loss',
                project_actual_costs: 'Project Actual Costs',
                project_memo: 'Project Memo',
                own_resourcing: 'Own Gantt',
                hours: 'Hours Insights',
                special_permissions: 'Special Permissions',
                revenue_recognition: 'Revenue Recognition',
            },
            customers: {
                read: 'Read',
                write: 'Write',
                unit: 'Unit',
                customer_crm: 'Activities',
                customer_pricelist: 'Pricelist',
                attachments: 'Attachments',
                profit_loss: 'Profit & Loss',
                customer_budgets_target: 'Goals Target',
                customer_budgets_forecast: 'Goals Forecast',
                customer_budgets_commitment: 'Goals Commitment',
                customer_memo: 'Account Memo',
                hours: 'Hours Insights',
                special_permissions: 'Special Permissions',
            },
        };

        this.state = {
            loading: false,
            loadingFeatures: false,
            showAllPermissions: false,
            showAllExtraPermissions: false,
            selectedFeature: null,
            useDefaultPermissions: true,
            permissions: [],
            extraPermissions: [],
        };
    }

    componentDidUpdate = (oldProps) => {
        if (oldProps.open != this.props.open) {
            if (this.props.open) {
                this._getUsers();
                this._getFeatures();
            } else {
                this.setState({ selectedFeature: null });

                /* refresh permissions */
                this.props.updateRights && this.props.updateRights();
            }
        }
    };

    _getUsers = () => {
        const { mode, company } = this.props;
        DataHandler.get({ url: `subjects/permissions/subjects/${company}/${mode}` }).done((users) => {
            this.setState({ users });
        });
    };

    _getFeatures = () => {
        const { mode } = this.props;
        this.setState({ loadingFeatures: true }, () => {
            DataHandler.get({ url: `subjects/permissions/structure/${mode}` }).done((features) => {
                features = this._translatePermissionNames(features);
                this.setState({ features, loadingFeatures: false });
            });
        });
    };

    _translatePermissionNames = (permissions) => {
        const { tr } = this;
        const { mode } = this.props;
        permissions.forEach((permission) => {
            const strippedName = permission.name.replace('_read', '').replace('_write', '');
            let typeString = '';
            if (permission.name.includes('_read')) typeString = ' (Read)';
            if (permission.name.includes('_write')) typeString = ' (Write)';
            const base = this._permissionNames[mode][strippedName] || strippedName;
            permission.label = tr(base + typeString);
        });
        return permissions;
    };

    _getData = () => {
        this.setState({ loading: true }, () => {
            const { mode, company, entityId } = this.props;
            const { selectedFeature } = this.state;

            DataHandler.get({ url: `subjects/permissions/special/${company}/${mode}/${entityId}/${selectedFeature.id}` }).done((data) => {
                if (data.specialPermissions.length || data.useDefaultPermissions === false) {
                    const defaultIds = data.permissions.map((e) => e.id);
                    const extraPermissions = data.specialPermissions.filter((e) => !defaultIds.includes(e.id));
                    const specialIds = data.specialPermissions.map((e) => e.id);

                    data.permissions.forEach((e) => (e.active = specialIds.includes(e.id)));

                    /* save specialPermissions for later use */
                    this.setState({ loading: false, ...data, extraPermissions });
                } else {
                    data.permissions.forEach((e) => (e.active = true));
                    this.setState({ loading: false, ...data, extraPermissions: [] });
                }
            });
        });
    };

    _setSpecialPermissions = () => {
        const { mode, company, entityId } = this.props;
        const { selectedFeature, useDefaultPermissions, permissions, extraPermissions } = this.state;

        const activeDefaultPermissions = permissions.map((e) => e.active && e.id).filter((e) => e);
        const activeExtrapermissions = extraPermissions.map((e) => e.value);
        const data = {
            useDefaultPermissions,
            specialPermissions: [...activeDefaultPermissions, ...activeExtrapermissions],
        };

        DataHandler.post({ url: `settings/permissions/special/${company}/${mode}/${entityId}/${selectedFeature.id}` }, data).done(() => this._showSnackbar());
    };

    _renderHeaderBar = () => {
        const { onClose, mode } = this.props;
        const modeString = mode == 'projects' ? this.tr('Projects') : this.tr('Accounts');
        return (
            <div className="header">
                <h1>{`${this.tr('Special Permissions')} - ${modeString}`}</h1>
                <button onClick={onClose}>
                    <Close />
                </button>
            </div>
        );
    };

    _setUseDefaultPermissions = (e) => this.setState({ useDefaultPermissions: !e.target.checked }, this._setSpecialPermissions);

    _renderDefaultSettingsOption = () => {
        const { useDefaultPermissions } = this.state;
        return (
            <div className="default-option">
                <p>{this.tr('Activate special permissions')}</p>
                <Switch checked={!useDefaultPermissions} onChange={this._setUseDefaultPermissions} color="primary" />
            </div>
        );
    };

    _renderSettingsPermissions = () => {
        const { permissions, showAllPermissions } = this.state;
        const items = showAllPermissions ? permissions : permissions.slice(0, this._maxTags);
        return (
            <div className="settings-permissions">
                <h2>{this.tr('Permissions')}</h2>
                <p>{this.tr('Users and groups with default permission from Settings > Permissions. Click on user or group to hide permission from project.')}</p>
                <div className="special-permission-tags">
                    {items.map((item) => (
                        <SettingsPermissionTag onClick={this._toggleSettingsPermission} item={item} />
                    ))}
                    {permissions.length > this._maxTags && (
                        <button className="more-btn" onClick={this._toggleShowAllPermissions}>
                            {showAllPermissions ? `- ${this.tr('Show less')}` : `+ ${this.tr('Show more')}`}
                        </button>
                    )}
                </div>
            </div>
        );
    };

    _toggleSettingsPermission = (item) => {
        const permissions = cloneDeep(this.state.permissions);
        const index = permissions.findIndex((p) => p.id == item.id);
        const othersInActive = permissions.every((p, i)=> !p.active || i == index);
        if(othersInActive && this.state.extraPermissions.length === 0 && permissions[index].active) {
            this._showSnackbar(this.tr("At least one user has to have right"), "info");
            return;
        }
        if (index != -1) permissions[index].active = !permissions[index].active;
        this.setState({ permissions }, this._setSpecialPermissions);
    };

    _addExtraPermission = (data) => {
        const extraPermissions = cloneDeep(this.state.extraPermissions);
        extraPermissions.unshift(data);
        this.setState({ extraPermissions }, this._setSpecialPermissions);
    };

    _showSnackbar = (message = this.tr('Changes saved successfully!'), variant = 'success') => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(message, {
            variant,
        });
    };

    _removeExtraPermission = (data) => {
        const othersInActive = this.state.permissions.every((p, i)=> !p.active);
        const extraPermissions = cloneDeep(this.state.extraPermissions);
        if(othersInActive && extraPermissions.length <= 1) {
            this._showSnackbar(this.tr("At least one user has to have right"), "info");
            return;
        }
        const index = extraPermissions.findIndex((p) => p.id == data.id);
        if (index != -1) extraPermissions.splice(index, 1);
        this.setState({ extraPermissions }, this._setSpecialPermissions);
    };

    _toggleShowAllExtraPermissions = () => this.setState({ showAllExtraPermissions: !this.state.showAllExtraPermissions });
    _toggleShowAllPermissions = () => this.setState({ showAllPermissions: !this.state.showAllPermissions });

    _renderExtraPermissions = () => {
        const { extraPermissions, users, showAllExtraPermissions, permissions } = this.state;
        const items = showAllExtraPermissions ? extraPermissions : extraPermissions.slice(0, this._maxTags);
        const allPermissions = (extraPermissions || []).concat(permissions);

        return (
            <div className="extra-permissions">
                <h2>{this.tr('Extra Permissions')}</h2>
                <p>{this.tr('Add users and groups for special permission to project and selected feature.')}</p>
                <DataList
                    label={this.tr('Add group or user...')}
                    name="extra_permissions"
                    customOption={ExtraPermissionCustomOption}
                    translations={this.extraPermissionTranslations}
                    options={(users || []).filter((item) => allPermissions.findIndex((e) => e.id == item.value) == -1)}
                    onChange={this._addExtraPermission}
                    className="extra-permissions-data-list"
                    shownCount={20}
                />
                <div className="special-permission-tags">
                    {items.map((item) => (
                        <ExtraPermissionTag onRemove={this._removeExtraPermission} item={item} />
                    ))}
                    {extraPermissions.length > this._maxTags && (
                        <button className="more-btn" onClick={this._toggleShowAllExtraPermissions}>
                            {showAllExtraPermissions ? `- ${this.tr('Show less')}` : `+ ${this.tr('Show more')}`}
                        </button>
                    )}
                </div>
            </div>
        );
    };

    _selectFeature = (selectedFeature) => {
        if (!this.state.selectedFeature || selectedFeature.id != this.state.selectedFeature.id) {
            this.setState({ selectedFeature }, () => {
                this._getData();
            });
        }
    };

    _renderFeatureSelection = () => {
        const { selectedFeature, features, loadingFeatures } = this.state;
        return (
            <div className="feature-selection">
                <h2>{this.tr('Select a feature to define extra permissions')}</h2>
                <DataList
                    label={loadingFeatures ? this.tr('Loading features...') : this.tr('Select a feature...')}
                    name="features"
                    disabled={loadingFeatures}
                    value={selectedFeature}
                    options={features || []}
                    onChange={this._selectFeature}
                    className="extra-permissions-data-list"
                />
            </div>
        );
    };

    _renderContent = () => {
        const { useDefaultPermissions, loading } = this.state;
        return loading ? (
            <Loading  />
        ) : (
            <div>
                {this._renderDefaultSettingsOption()}
                <div className={`special-permissions ${useDefaultPermissions && 'inactive'}`}>
                    <p className="description">{this.tr('This blue tag shows which permission group/user has editing rights for this function.')}</p>
                    {this._renderSettingsPermissions()}
                    {this._renderExtraPermissions()}
                </div>
            </div>
        );
    };

    render() {
        const { open, onClose } = this.props;
        const { selectedFeature } = this.state;
        return (
            <Drawer open={open} onClose={onClose} id="special-permission-slider" anchor="right">
                <div className="content">
                    {this._renderHeaderBar()}
                    {this._renderFeatureSelection()}
                    {selectedFeature && this._renderContent()}
                </div>
            </Drawer>
        );
    }
}

export default withSnackbar(SpecialPermissionSlider);
