import React from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
import TaimerComponent from './../../TaimerComponent';
import FileSaver from 'file-saver';
import List from "../List";
import { SettingsContext } from './../../SettingsContext';
import AdvancedSearch from "../../search/AdvancedSearch";
import DataHandler from '../../general/DataHandler';
import ContactListRow from "../rows/ContactListRow";
import MenuItem from '@mui/material/MenuItem';
import AddToMailingListDialog from './../dialogs/AddToMailingListDialog';
import RemoveFromMailingListDialog from './../dialogs/RemoveFromMailingListDialog';
import CreateMailingListDialog from './../dialogs/CreateMailingListDialog';
import DeleteMailingListDialog from './../dialogs/DeleteMailingListDialog';
import AddTagsDialog from './../dialogs/AddTagsDialog';
import RemoveTagsDialog from './../dialogs/RemoveTagsDialog';
import DeleteContactDialog from './../dialogs/DeleteContactDialog';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import _ from 'lodash';
import OutlinedField from "./../../general/OutlinedField";
import withStyles from '@mui/styles/withStyles';
import NoPermissionOverlay from '../../overlays/NoPermissionOverlay';
import { withSnackbar } from 'notistack';
import { languages } from 'country-data';
import PageTopSection from '../../general/PageTopSection';
import { CloudDownload } from '@mui/icons-material';

import './ContactList.css';

import ContactListOverlay from '../overlays/ContactListOverlay';
const styles = theme => ({

});

class ContactList extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/lists/ContactAndUserList");

        this.contactStickySearchKey = "contact_list";

        this.checkedContacts = [];
        this.contactSearchTerms = {};

        this.lastContactsQueryParams = {};
        this.contactSortConfig = undefined;

        this.initialFetchDone = {
            contact: false,
        };

        this.filtersInitialValues = {
            selectedMailingList: 0,
            relation_status: 0,
            customer_partner: context.folder === "ourbusiness" || this.context.folder === "ourdemo" ? 1 : 0,
            customer_status: 0,
            customers_types_name: 0,
            perpage: this.props.perpage,
            company: context.userObject.companies_id > 0 ? context.userObject.companies_id : 1,
            page: 1,
        }

        const { taimerAccount: { showState }, functions: { checkPrivilege, hasPrivilege } } = this.context;
        const usMode = /* (data['state']) || */ showState;

        this.state = {
            ...this.filtersInitialValues,
            autoCompleteDataDone: false,
            contactData: [],
            view: "contacts",
            values: [],
            contactPageCount: 0,
            contactCount: 0,
            hasContacts: true,
            companies: [],
            field_edited: "",
            contactStickySearchInitialized: false,
            contactSettings: {},
        };
        this.getParams = {};

        this.contactfields = [
            //columnHeaderType for expand hidden for now to disable contact adding from list
            { field: "expand", name: "expand", header: "", columnHeaderType: /*"roundButton"*/ "", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { field: "checked", name: "checked", header: "", columnHeaderType: "checkbox", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { field: "lastname", name: "lastname", header: this.tr("Surname"), width: 125, showMenu: true, resizeable: true },
            { field: "firstname", name: "firstname", header: this.tr("First Name"), width: 125, showMenu: true, resizeable: true },
            { field: "title", name: "title", header: this.tr("Title"), width: 175, showMenu: true, resizeable: true },
            { field: "position", name: "position", header: this.tr("Position"), width: 200, showMenu: true, resizeable: true },
            { field: "account", name: "account", header: this.tr("Account"), width: 200, showMenu: true, resizeable: true, visualizationType: "tree", entityMode: true },
            { field: "customer_types_name_concat", name: "customers_types_name_concat", header: this.tr("Account type"), width: 200, showMenu: false, resizeable: true },
            { field: "customer_partner", name: "customer_partner", header: this.tr("Contact type"), width: 200, showMenu: true, resizeable: true },
            hasPrivilege("persons", "contact_owner_read")  && { field: "contact_owner", name: "contact_owner", header: this.tr("Contact Owner"), width: 200, showMenu: true, resizeable: true },
            { field: "created", name: "created", header: this.tr("Created"), width: 200, showMenu: true, resizeable: true,  type: "date" },
            // { field: "position", name: "position", header: "Position", width: 200, showMenu: true, resizeable: true},
            { field: "on_mailing_lists", name: "on_mailing_lists", header: this.tr("Email Lists"), width: 200, showMenu: false, resizeable: true },
            { field: "branch_of_business", name: "branch_of_business", header: this.tr("Branch of Business"), width: 200, showMenu: true, resizeable: true },
            { field: "email", name: "email", header: this.tr("Email"), width: 200, showMenu: true, resizeable: true },
            { field: "phone", name: "phone", header: this.tr("Phone"), width: 175, showMenu: true, resizeable: true },
            { field: "address", name: "address", header: this.tr("Street Address"), width: 175, showMenu: true, resizeable: true },
            { field: "postal_code", name: "postal_code", header: this.tr("Zip code"), width: 175, showMenu: true, resizeable: true },
            { field: "city", name: "city", header: this.tr("City"), width: 175, showMenu: true, resizeable: true },
            { field: "country", name: "country", header: this.tr("Country"), width: 200, showMenu: true, resizeable: true },
            { field: "lang", name: "lang", header: this.tr("Language"), width: 150, showMenu: true, resizeable: true, entityMode: true },
            { field: "tags", name: "tags", header: this.tr("Tags"), width: 175, showMenu: true, resizeable: true },
            { field: "project_tags", name: "project_tags", header: this.tr("Project tags"), width: 175, showMenu: true, resizeable: true },
            { field: "edited_by", name: "edited_by", header: this.tr("Edited By"), width: 150, showMenu: true, resizeable: true },
            { field: "edited", name: "edited", header: this.tr("Edited"), width: 200, showMenu: true, resizeable: true },
            usMode && { field: "state", name: "state", header: this.tr("State"), width: 175, showMenu: true, resizeable: true },

        ].filter(x => x)

        this.advancedSearchFieldsContacts = this.contactfields.filter(f => f.field !== "expand" && f.field !== "checked" && f.field !== "edited" && f.field !== "on_mailing_lists" && f.field !== "customer_types_name_concat" && f.field !== "customer_partner").map(f => {
            const field = { field: f.field, transl: f.header, type: f.type, entityMode: f.entityMode || false, visualizationType: f.visualizationType || "list" };

            if (f.hasOwnProperty("autoCompleteData"))
                field.autoCompleteData = f.autoCompleteData;

            return field;
        });

        this.contactList = React.createRef();
        this.contactadvancedSearch = React.createRef();
        this.pageChanged = this.pageChanged.bind(this);
        this.fetchAutoCompleteData = this.fetchAutoCompleteData.bind(this);
        this.fetchcontactData = this.fetchcontactData.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.sortByEmailList = this.sortByEmailList.bind(this);

        this.contactFiltersAreInInitialState = this.contactFiltersAreInInitialState.bind(this);
        this.initializeContactStickySearch = this.initializeContactStickySearch.bind(this);
        this.saveContactStickySearch = this.saveContactStickySearch.bind(this);

        this.newContact = {
            id: -1,
            lastname: "",
            firstname: "",
            account: { id: 0 },
            vacance: { id: 0 },
            on_mailing_lists: [],
            branch_of_business: "",
            title: "",
            customer_types: "",
            position: "",
            contact_owner: { id: 0 },
            email: "",
            phone: "",
            modified_by: "",
            modified: "",
            lang: "",
            tags: [],
            street_address: "",
            postal_code: "",
            city: "",
            country: ""
        };

         this.taimerLangs = [
            "fin", "swe", "nor", "dan", "icl", 
            "est", "lit", "lav", 
            "eng", "deu", "ita", "fra", "spa", "por", "rus", "pol", "bul", "hrv", "ces", "ell", "hun", "gle", "mlt", "ron", "slk", "slv",
            "zho", "jpn", "nld", "ara", "hin", "pan", "ind"
        ];
        this.languages = languages.all.filter(l => (this.taimerLangs.indexOf(l.alpha3) > -1)).filter(ln => ["Panjabi", "Flemish", "Castilian", "Moldavian"].indexOf(ln.name) == -1).map(language => {
                if (language.alpha3 === "ell")
                    return {id: language.alpha3 ,value: language.alpha3, name: "Greek", label: "Greek"};
                else
                    return {id: language.alpha3 ,value: language.alpha3, name: language.name, label: language.name};
        });
    }

    async componentDidMount() {
        super.componentDidMount();

        this.listenReset();
        let rights = "persons/read";
        
        const contactSettings = await DataHandler.get({ url: `settings/company/${this.state.company}/contact_settings`});
        DataHandler.get({ url: `subjects/companies/${rights}` }).done(companies => {
            rights = rights.split("/");
            const company = this.context.functions.getCompany(rights[0], rights[1], this.state.company, true);
            this.filtersInitialValues.company = company;
            this.setState({ companies, company, contactSettings }, () => this.updateComponentData());
        });
    }

    updateComponentData = () => {
        this.state.contactStickySearchInitialized ? this.getInitialData() : this.initializeContactStickySearch();
    }

    componentWillUnmount() {
        this.unListenReset();
    }

    getInitialData = async (override, updatePageCount, stickySearch, stickySearchExists = true) => {
        this.fetchAutoCompleteData().then(x => {
            //TR-1097 reluctant exeption
            let d = 0;
            if (this.context.folder === "ourbusiness" || this.context.folder === "ourdemo") {
                d = x.customer_types.find(x => x.is_default == 1)?.name;
            }
            this.filtersInitialValues.customers_types_name = d;

            if (!stickySearchExists && d) {
                this.setState({customers_types_name: d}, () => this.fetchcontactData(override, updatePageCount, stickySearch))
            } else {
                this.fetchcontactData(override, updatePageCount, stickySearch)
            }
        })
        ;
    }

    initializeContactStickySearch() {
        DataHandler.get({ url: `saved_search/sticky/${this.contactStickySearchKey}` }).done((response, _, request) => {
            if (request.status !== 200) {
                this.getInitialData({}, true, true);
                return;
            }

            if (!this.context.privileges.persons.read.find(el => el == response.company)) {
				response.company = this.filtersInitialValues.company;
			}

            this.contactSearchTerms = response.contactSearchTerms || {};
            this.contactSortConfig = response.contactSortConfig;
            this.setState({ ...response }, () => this.getInitialData({ page: response.page, maillist: response.selectedMailingList }, true, true));
        }).fail(response => {
            this.getInitialData({}, true, true, false);

        }).always((response, _, request) => {
            this.setState({ contactStickySearchInitialized: true });
        });
    }

    saveContactStickySearch() {
        const stateToSave = {};
        stateToSave.contactSearchTerms = _.cloneDeep(this.contactSearchTerms);
        stateToSave.contactSortConfig = _.cloneDeep(this.contactSortConfig);

        Object.keys(this.filtersInitialValues).forEach(e =>
            stateToSave[e] = this.state[e]
        );

        DataHandler.post({ url: `saved_search/sticky/${this.contactStickySearchKey}`, }, { search: stateToSave });
    }

    contactFiltersAreInInitialState() {
        const initial = _.cloneDeep(this.filtersInitialValues);
        delete initial.page;
        delete initial.perpage;
        const filters = {}

        for (const key in initial) {
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = JSON.stringify(this.state[key]);
        }

        const freetext = this.contactSearchTerms ? this.contactSearchTerms.freetextSearchTerm : "";

        return _.isEqual(initial, filters) && !freetext;
    }

    _resetContactFilters = (evt) => {
        if (!evt || evt.keyCode == '27') {
            if (this.contactadvancedSearch) {
                this.contactadvancedSearch.current.clearSearch(evt, true);
                this.contactadvancedSearch.current.clearSearchTextInput();
            }

            this.contactSearchTerms = {};
            this.contactSortConfig = undefined;

            //TR-1097 reluctant exeption
            let defaultCustomerType = 0;
            if (this.context.folder === "ourbusiness" || this.context.folder === "ourdemo") {
                defaultCustomerType = this.autoCompleteData.customer_types.find(x => x.is_default == 1)?.name;
            }
            this.setState({
                ...this.filtersInitialValues,
                customers_types_name: defaultCustomerType,
            }, () => this.fetchcontactData({ ...this.filtersInitialValues, customers_types_name: defaultCustomerType, maillist: 0 }));
        }
    }

    pageChanged(page) {
        this.setState({ page: page });

        // If the AdvancedSearch component is in advanced mode, we need to trigger the
        // search with the currently selected advanced filtering options and just add
        // the page number.

        // if (this.contactadvancedSearch) {
        // this.fetchcontactData(pageConf);
        // } else if (this.useradvancedSearch) {
        // this.fetchuserData(pageConf);
        // }
    }

    async fetchAutoCompleteData() {
        const { company } = this.state;
        const tagSettings = await DataHandler.get({url: `settings/company/${company}/tagSettings`});
        
        const translations = {
            freelancer: this.tr("freelancer"),
            archived: this.tr("locked")
        };

		return DataHandler.get({ url: `autocomplete_data/contacts/${company}`, translations, from_list: 1}).done(autoCompleteData => {
            autoCompleteData.tagPoolSettingsData = tagSettings.tagPoolSettingsData;

			autoCompleteData.modified_by = autoCompleteData.modified_by.map(ac => {
				if (ac.companies_id < 1)
				    ac.name = `${ac.name} (${this.tr('freelancer')})`;
				return ac;
            });
            autoCompleteData.users = autoCompleteData.users.map(ac => {
                if (ac.companies_id < 1)
                    ac.name = `${ac.name} (${this.tr('freelancer')})`;
                return ac;
            });
			DataHandler.get({url: `settings/company/${company}/langs`}).done(langs => {
				autoCompleteData.langs = langs;
				this.autoCompleteData = autoCompleteData;
				this.setState({ autoCompleteDataDone: true });
			})
		});
	}

    // When the list is initialized, we use this method to fetch the initial data.
    async fetchcontactData(override = {}, updatePageCount = true, stickySearch = false) {
        const parameters = { ...this.lastContactsQueryParams, page: 1, perpage: this.state.perpage, ...override }
        const mode = { advanced: "advanced", freetext: "freetext", none: "none" };
        const { company } = this.state;
        const sortQueryParams = this.contactSortConfig !== undefined ? { name: this.contactSortConfig.name, asc: this.contactSortConfig.asc } : {};
        const advParams = { ...parameters, advanced_search_criteria: JSON.stringify(this.contactSearchTerms.advanced_search_criteria), mode: mode.advanced, sort: sortQueryParams, company };

        const callback = () => this.initialFetchDone.contact = true;
        this.contactList.current && this.contactList.current.setState({ isLoading: true});
        if (override.all_ids)
            return DataHandler.post({ url: "get_contacts",  ...parameters, relation_status: this.state.relation_status, customer_partner: this.state.customer_partner, customers_types_name: this.state.customers_types_name }, { ...advParams, company: company });

        if (!stickySearch)
            this.saveContactStickySearch();

        const filterParams = {
            relation_status: this.state.relation_status, customer_partner: this.state.customer_partner, customers_types_name: this.state.customers_types_name
        }

        if (this.contactSearchTerms !== undefined && this.contactSearchTerms.mode === "advanced") {
            DataHandler.post({ url: "get_contacts", ...filterParams }, advParams).done((contactData) => {this.setState({ contactData: contactData.contacts, hasContacts: contactData.has_contacts > 0 })});
            this.lastContactsQueryParams = { ...advParams, ...filterParams };
            if (updatePageCount) {
                const pages = await DataHandler.post({ url: 'get_contacts', ...parameters, getcount: 1, relation_status: this.state.relation_status, customer_partner: this.state.customer_partner, customers_types_name: this.state.customers_types_name }, { ...advParams, company: company });

                this.setState({
                    page: parameters.page,
                    contactCount: pages.count,
                    contactPageCount: pages.page_count
                }, callback);
            }

        } else if (this.contactSearchTerms !== undefined && this.contactSearchTerms.mode === "freetext") {
            DataHandler.post({ url: "get_contacts", mode: mode.freetext, ...filterParams, ...parameters }, { company, sort: sortQueryParams, ...this.contactSearchTerms }).done(contactData => { this.setState({ contactData: contactData.contacts, hasContacts: contactData.has_contacts > 0 }) });
            this.lastContactsQueryParams = { mode: mode.freetext, ...parameters, ...filterParams, sort: sortQueryParams, ...this.contactSearchTerms };
            if (updatePageCount) {
                const pages = await DataHandler.post({ url: 'get_contacts', mode: mode.freetext, ...parameters, getcount: 1, relation_status: this.state.relation_status, customer_partner: this.state.customer_partner, customers_types_name: this.state.customers_types_name }, { ...this.contactSearchTerms, company: company });
                this.setState({
                    page: parameters.page,
                    contactCount: pages.count,
                    contactPageCount: pages.page_count
                }, callback);
            }

        } else {
            DataHandler.post({ url: "get_contacts", mode: mode.none, ...filterParams, ...parameters, }, { company, sort: sortQueryParams }).done(contactData => { this.setState({ contactData: contactData.contacts, hasContacts: contactData.has_contacts > 0 }) });
            this.lastContactsQueryParams = { mode: mode.none, ...parameters, ...filterParams, sort: sortQueryParams };
            if (updatePageCount) {
                const pages = await DataHandler.post({ url: 'get_contacts', mode: mode.none, relation_status: this.state.relation_status, customer_partner: this.state.customer_partner, customers_types_name: this.state.customers_types_name, ...parameters, getcount: 1 }, { company: company });

                this.setState({
                    page: parameters.page,
                    contactCount: pages.count,
                    contactPageCount: pages.page_count
                }, callback);
            }
        }
        this.contactList.current && this.contactList.current.setState({ isLoading: false});

    }

    dialogs = {
        addMail: AddToMailingListDialog,
        removeMail: RemoveFromMailingListDialog,
        createMail: CreateMailingListDialog,
        deleteMail: DeleteMailingListDialog,
        addTags: AddTagsDialog,
        removeTags: RemoveTagsDialog,
        deleteContacts: DeleteContactDialog,
        confirmation: ConfirmationDialog,
    }

    openDialog = (name, dialogProps = {}) => {
        dialogProps.contacts = this.contactList.current.getCheckedData().map(e => {
            return { ...e, id: e.contact_id }
        });
        dialogProps.company = this.state.company;
        this.setState({ currentDialog: name, dialogProps });
    }

    closeDialog = async () => {
        this.setState({ currentDialog: false });
        await this.fetchAutoCompleteData();
        this.fetchcontactData();
        this.contactList.current.checkAll(false);
        this.checkedContacts = [];
    }

    async loadOptions(value, { action }) {
        this.setState({ values: value });
        this.sortByEmailList(value && value.id);
    }

    sortByEmailList(value) {
        this.fetchcontactData({ maillist: value });
    }

	fetchAllIds = async () => {
		const data = await this.fetchcontactData({all_ids: 1}, false);
		return data.ids;
	}

    getSelectedRows = async (noRelationId = false) => {
		const allCheckedExcept = this.contactList.current.getAllCheckedExcept();
        let selected;
        		
        if(allCheckedExcept) {
            const ids = await this.fetchAllIds();
			selected = ids.filter(id => {
				return !allCheckedExcept[id]; 
            });
        } else {
			selected = this.contactList.current.getCheckedRows();
        }
        
        if (noRelationId) 
            return selected.map(e => e.split('_')[0]);

		return selected;
    }

    export = async (target) => {  
        const { contactData } = this.state;
 
        if (!contactData || contactData.length < 1) {
            this.props.enqueueSnackbar(this.tr("Nothing to export!"), {
                variant: "warning",
            });
            return;
        }
            
        const view = _.capitalize(this.state.view);
        let ids = await this.getSelectedRows();
        if (ids.length === 0) 
            ids = await this.fetchAllIds();

        let idParams = {};
        if (ids.length > 0)
            idParams = { ids: JSON.stringify(ids) };

        const currentList = this.contactList.current;
        const columnOrder = currentList.visibleColumnsOrder;
        const fields = this.contactfields;
        const exportHeaders = [];

        _.forEach(columnOrder, column => {
            _.forEach(fields, (field, i) => {
                if (column == field.name && column != "expand" && column != "checked" && column != "activated" && column != "deactivated" && column != "edited_by" && column != "edited") {
                    exportHeaders.push(field.header);
                }
            })
        })

        const params = { ...this[`last${view}QueryParams`], company: this.state.company };
        params.file_name = this.tr("contacts_list_export");

        DataHandler.postArrayBuffer({ url: `${_.lowerCase(view)}_export`, ...params, order: columnOrder, columnNames: exportHeaders, export: target }, {...idParams}, true)
            .done((response) => {

                const blob = new Blob([response], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
                });
                FileSaver.saveAs(blob, `${params.file_name}.${target}`);
                this.contactList.current && this.contactList.current.setState({ isLoading: false});
            });

    }

    listenReset = () => {
        document.body.addEventListener("keyup", this._resetContactFilters);
    }

    unListenReset = () => {
        document.body.removeEventListener("keyup", this._resetContactFilters);
    }

    setChangedField = (name, callback) => {
        this.setState({ field_edited: name }, callback);
    }

    renderSummarySection = (totalCount) => {
		const { functions: { urlify, updateView, hasPrivilege, addContact } } = this.context;
		return (
			<PageTopSection
			summaries={[{ title: this.tr("Contacts"), value: totalCount || 0 }]}
			additionalButtons={[
				{
					title: this.tr("EXPORT"),
					action: () => this.export('xlsx'),
					icon: <CloudDownload />
				}
			]} mainButtons={[
				{
					title: this.tr("ADD CONTACT"),
					action: () => addContact({ companies_id: this.contactList.current.state.company }),
					isVisible: hasPrivilege("persons", "read"),
                    "data-testid": 'add-contact-button'
				},
			]} settingsButton={{
				isVisible: !(null == this.context.privileges.admin),
				title: this.tr("Settings"),
				href: urlify({ module: 'settings', action: 'index', group: 'features', page: 'contact' }),
				action: () => updateView({ module: 'settings', action: 'index', group: 'features', page: 'contact' }, false)
			}}  />
		);
	}

    render() {
        if (!this.state.contactStickySearchInitialized) {
            return null;
        }
        const { dialogProps, currentDialog, view, companies, company, contactData } = this.state;
        const Dialog = currentDialog ? this.dialogs[currentDialog] : undefined;
        const { functions: { checkPrivilege }, taimerAccount: { isMulticompany, subContractorType } } = this.context;
        const { tr } = this;
        const newRowErrors = {
            firstname: this.tr("The firstname field is required."),
            lastname: this.tr("The lastname field is required."),
            email: this.tr("Email is not valid."),
            phone: this.tr("Phone is not valid"),
            title: this.tr("Title is not valid"),
            vacance: this.tr("Position is not valid"),
            lang: this.tr("Language is not valid"),
            owner: this.tr("Owner is not valid"),
        }
        const SelectProps = {
            MenuProps: {
                onEntering: () => this.unListenReset(),
                onExited: () => this.listenReset()
            }
        }

        if (!checkPrivilege("persons", "read", company)) {
            return <NoPermissionOverlay />
        }

        if (!this.state.autoCompleteDataDone)
            return <div><Loading className='main-page-loading-indicator' /></div>
        /*
        let i = 0;
        const contactData = this.state.contactData.map(e => {
            i++;
            return { ...e, id: e.id + i, contact_id: e.id }
        });
        */
        return (
            <div id="contact-list" className="contentBlock">
                <div className="listControlsContainer clearfix filter-container">
                    <div className="primary header-container">
                        {Dialog && <Dialog {...dialogProps} onClose={this.closeDialog} />}

                        {companies.length > 1 &&
                            <OutlinedField className="listFilterOutlinedField" name="company" value={company} label={tr("Select company")} onChange={e => { 
                                this.setState({ [e.target.name]: e.target.value }, () => this.updateComponentData());
                                this.context.functions.setLastCompany(e.target.value);
                            }} select>
                                {companies.map(e => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                            </OutlinedField>
                        }
                        <OutlinedField select label={this.tr("Options")} className="listFilterOutlinedField" shrinkLabel={false}>
                            <MenuItem onClick={() => this.openDialog("addMail", { mailing_lists: this.autoCompleteData.mailing_list })}>
                                {this.tr("Add selected to mailing list")}
                            </MenuItem>
                            <MenuItem onClick={() => this.openDialog('removeMail', { mailing_lists: this.autoCompleteData.mailing_list })} >
                                {this.tr("Remove selected from mailing list")}
                            </MenuItem>
                            <MenuItem onClick={() => this.openDialog('createMail')} >
                                {this.tr("Create mailing list")}
                            </MenuItem>
                            <MenuItem onClick={() => this.openDialog('deleteMail', { mailing_lists: this.autoCompleteData.mailing_list })}>
                                {this.tr("Delete mailing list")}
                            </MenuItem>
                            <MenuItem onClick={() => this.openDialog('addTags', { tags: this.autoCompleteData.tags })}>
                                {this.tr("Add tags to selected")}
                            </MenuItem>
                            <MenuItem onClick={() => this.openDialog('removeTags', { tags: this.autoCompleteData.tags })}>
                                {this.tr("Remove tags from selected")}
                            </MenuItem>
                            <MenuItem onClick={() => this.openDialog('deleteContacts')} >
                                {this.tr("Remove selected rows")}
                            </MenuItem>
                        </OutlinedField>

                        <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Relation status")} name="relation_status"
                            value={this.state.relation_status} select
                            onChange={e => {
                                this.setState({ relation_status: e.target.value }, () => this.fetchcontactData({ relation_status: e.target.value }, true));

                            }}>
                            <MenuItem key={0} value={0}>{!isMulticompany ? this.tr('All') : this.tr("Related to company & no relations")}</MenuItem>
                            <MenuItem key={1} value={1}>{this.tr('Has relations')}</MenuItem>
                            <MenuItem key={2} value={2}>{this.tr('Has no relations')}</MenuItem>
                        </OutlinedField>

                        <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Account type")} name="customers_types_name"
                            value={this.state.customers_types_name} select
                            onChange={e => {
                                const update = {customers_types_name: e.target.value};
                                if (e.target.value != 0) {
                                    const sbt = this.autoCompleteData.customers_types.filter(ct => ct.name === e.target.value);
                                    if (sbt[0].id == subContractorType)
                                        Object.assign(update, {customer_partner: 2});
                                    else 
                                        Object.assign(update, {customer_partner: 1});
                                } else {
                                    Object.assign(update, {customer_partner: 0});
                                }                           

                                this.setState(update, () => this.fetchcontactData(update, true));

                            }}>
                            <MenuItem key={0} value={0}>{this.tr('All')}</MenuItem>
                            {[...(this.autoCompleteData && this.autoCompleteData.hasOwnProperty("customers_types") ? this.autoCompleteData.customers_types : [])].map(customers_types => {
                                return <MenuItem key={customers_types.id} value={customers_types.name}>{customers_types.name}</MenuItem>;
                            })}
                        </OutlinedField>

                        <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Contact type")} name="contact_type"
                            value={this.state.customer_partner} select
                            onChange={e => {
                                this.setState({ customer_partner: e.target.value }, () => this.fetchcontactData({ customer_partner: e.target.value }, true));
                            }}>
                            <MenuItem key={0} value={0}>{this.tr('All')}</MenuItem>
                            <MenuItem key={1} value={1}>{this.tr('Contact')}</MenuItem>
                            <MenuItem key={2} value={2}>{this.tr('Partner')}</MenuItem>
                        </OutlinedField>
                        
                        <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Account status")} name="customer_status"
                            value={this.state.customer_status} select
                            onChange={e => {
                                this.setState({ customer_status: e.target.value }, () => this.fetchcontactData({ customer_status: e.target.value }, true));
                            }}>
                            <MenuItem key={0} value={0}>{this.tr('All')}</MenuItem>
                            <MenuItem key={1} value={-1}>{this.tr('Active')}</MenuItem>
                            <MenuItem key={2} value={1}>{this.tr('Locked')}</MenuItem>
                        </OutlinedField>

                        <OutlinedField select SelectProps={SelectProps} label={this.tr("View email list")} className="listFilterOutlinedField" value={this.state.selectedMailingList} onChange={e => {
                            this.setState({ selectedMailingList: e.target.value }, () => this.sortByEmailList(e.target.value));
                        }}>
                            {[{ id: 0, name: this.tr("All") }, ...(this.autoCompleteData && this.autoCompleteData.hasOwnProperty("mailing_list") ? this.autoCompleteData.mailing_list : [])].map(mailingList => {
                                return <MenuItem value={mailingList.id}>{mailingList.name}</MenuItem>;
                            })}
                        </OutlinedField>
                        <AdvancedSearch
                            ref={this.contactadvancedSearch}
                            mode={this.contactSearchTerms && this.contactSearchTerms.mode ? this.contactSearchTerms.mode : undefined}
                            initialFilters={this.contactSearchTerms ? this.contactSearchTerms.currentFilters : undefined}
                            mainConfig={this.contactSearchTerms && this.contactSearchTerms.advanced_search_criteria ? { operator: this.contactSearchTerms.advanced_search_criteria.operator } : undefined}
                            freetextLabel={this.contactSearchTerms ? this.contactSearchTerms.freetextSearchTerm : ""}
                            alwaysShowClearFilters={!this.contactFiltersAreInInitialState()}
                            onClearSearch={this._resetContactFilters}
                            fields={this.advancedSearchFieldsContacts}
                            noRequests={true}
                            onSearchTrigger={(contactSearchTerms) => {

                                if (contactSearchTerms.advanced_search_criteria?.filters) {
                                    const newFilters = {};
                                    Object.entries(contactSearchTerms.advanced_search_criteria.filters).map(([name, filterArray]) => {
                                        if (name == 'contact_owner') 
                                            newFilters[name] = filterArray.map(fa => ({...fa, value: fa.value.split(' (')[0]}));
                                        else
                                            newFilters[name] = filterArray;
                                    });
                                    contactSearchTerms.advanced_search_criteria.filters = newFilters;
                                }
                                this.contactSearchTerms = contactSearchTerms;
                                this.fetchcontactData({ page: 1 });
                            }}
                            onSearchResult={this.setData}
                            autoCompleteData={this.autoCompleteData}
                            perpage={this.props.perpage}
                            autoCompleteData={{
                                edited_by: this.autoCompleteData['modified_by'],
                                customer_types: this.autoCompleteData['customer_types'],
                                position: this.autoCompleteData['positions'],
                                contact_owner: this.autoCompleteData['users'],
                                account: [this.autoCompleteData['customers'], "parent_id"],
                                on_mailing_lists: this.autoCompleteData['mailing_list'],
                                lang: this.languages,
                                branch_of_business: this.autoCompleteData['branch_of_business'],
                                tags: this.autoCompleteData['tags'],
                                project_tags: this.autoCompleteData['project_tags'],
                                customer_partner: this.autoCompleteData['customer_partner'],
                            }}
                        />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    {this.renderSummarySection(this.state.contactCount)}
                </div>
                <div style={{ display: "block" }}>
                    <List
                        ref={this.contactList}
                        rowKey="list_id"
                        idType={"string"}
                        rowProps={{
                            contactSettings: this.state.contactSettings,
                            languageOptions: this.languages,
                            listRef: this.contactList, onCheck: (checked, data) => {
                                if (checked) {
                                    this.checkedContacts.push(data);
                                } else {
                                    this.checkedContacts = this.checkedContacts.filter(c => c.list_id != data.list_id);
                                }
                            },
                            newRowErrors: newRowErrors,
                            companies_id: company,
                            fetchData: () => {
                                this.fetchcontactData({ page: this.state.page })
                            },
                            setChangedField: this.setChangedField,
                            fetchAutoCompleteData: this.fetchAutoCompleteData,
                            onCreate: (data) => {
                                const d = data;
                                if (d.id > 0)
                                    return;

                                d.companies_id = company;
                                d.from_list = 1;
                                return DataHandler.post({ url: `contacts_new/${d.id}` }, d)
                                    .done(response => {
                                        this.fetchcontactData();
                                        this.fetchAutoCompleteData();
                                    })
                                    .fail(response => {
                                        if (response.status === 422) {
                                            _.forEach(response.responseJSON, (error, i) => {
                                                this.props.enqueueSnackbar(newRowErrors[i], {
                                                    variant: 'error'
                                                });
                                            })
                                        }
                                    });
                            },
                            onUpdate: (data) => {
                                const d = data;
                                if (Number(d.contact_id) < 0)
                                    return;

                                d.companies_id = company;
                                d.field_edited = this.state.field_edited;

                                return DataHandler.post({ url: `contacts_save/${d.contact_id}?relation_id=${data.relation_id}` }, d)
                                    .done(response => {
                                        this.fetchcontactData({page: this.state.page});
                                        this.fetchAutoCompleteData();
                                    })
                                    .fail(response => {
                                        if (response.status === 422) {
                                            _.forEach(response.responseJSON, (error, i) => {
                                                this.props.enqueueSnackbar(newRowErrors[i], {
                                                    variant: 'error'
                                                });
                                            })
                                        }
                                    });
                            },
                            onDelete: (data) => {
                                if (data.id < 0) {
                                    this.contactList.current.removeRow(data.id);
                                } else {
                                    this.openDialog("confirmation", {
                                        data: {
                                            id: data.contact_id,
                                            text: this.tr("Do you want to delete:") + " " + data.lastname + " " + data.firstname + "?"
                                        },
                                        onDialogSave: (id) => DataHandler.post({ url: 'delete_contact/', id: data.contact_id }).done(() => {
                                            this.closeDialog();
                                            this.fetchcontactData();
                                        }),
                                        onDialogClose: () => this.closeDialog()
                                    });
                                }
                            }
                        }}
                        onSortRows={(name, asc) => {
                            this.contactSortConfig = {
                                name: name,
                                asc: asc
                            };

                            this.fetchcontactData();
                        }}
                        data={contactData}
                        columns={this.contactfields}
                        sharedData={this.autoCompleteData}
                        height="fitRemaining"
                        trimHeight={-10}
                        noStateData={true}
                        saveColumnConfig={true}
                        useAllCheckedExcept={true}
                        className="contactList"
                        listRowType={ContactListRow}
                        newRow={this.newContact}
                        saveColumnConfig={true}
                        userListSettingsKey="contact_list"
                        showNoResultsMessage={this.initialFetchDone.contact}
                        showOverlay={!this.state.hasContacts}
                        overlayComponent={ContactListOverlay}
                        overlayProps={{company: this.state.company}}
                        controlPage={true}
                        page={this.state.page}
                        showPageSelector={true}
                        onPerPageChange={perpage => {
                            this.setState({ perpage }, () => this.fetchcontactData());
                        }}
                        onPageChange={page => {
                            this.setState({ page }, () => this.fetchcontactData({ page }));
                        }}
                        pageCount={this.state.contactPageCount}
                        totalCount={this.state.contactCount}
                        perpage={this.state.perpage} 
                        useHSRightPadding
                        />
                </div >
            </div>
        );
    }
}

ContactList.defaultProps = {
    perpage: 30
}

export default withSnackbar(withStyles(styles, { withTheme: true })(ContactList));
