import React from "react";
import TaimerComponent from "../../TaimerComponent";
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import { DateRangePicker } from "../../general/react-date-range/src";
import DataHandler from "../../general/DataHandler";
import { formatInputNumber } from '../../helpers';
import { format } from "date-fns";
import _ from "lodash";
import moment from 'moment';
class SubmitHoursDialog extends TaimerComponent {

    constructor(props, context) {
        super(props, context, "workhours/time-tracker/SubmitHoursDialog");

        this.state = {
            dialogData: props.dialogData,
            currentDialogProps: undefined
        }
    }

    componentDidMount = async () => {
        super.componentDidMount();
        if (this.props.dialogData.ids) {
            this.originalDialogProps(this.props.dialogData);
            return;
        }

        const data = await this.fetchDialogData(this.props.dialogData.dateRange, this.props.dialogData.type);
        const dialogData = {...this.props.dialogData, ...data};
        this.setState({ dialogData }, () => this.originalDialogProps())
    }

    originalDialogProps = (data = false) => {
        data = data ? data : this.state.dialogData;
        const confirmDisabled = data ? (data.ids ? Number(data.updateHours) < 1 : data.selectedHours.length == 0) : true;

        this.dialogProps = {
            sendForApproval: {
                dialogType: "delete",
                open: true,
                onDialogClose: this.props.closeDialog,
                dialogProps: {
                    testid: "SubmitHoursDialog",
                    hideWarningIcon: true,
                    header: data?.type == "revoke" ? this.tr("Revoke hours") : this.tr("Send hours for approval"),
                    confirmButtonClass: "blue",
                    translatedConfirmButtonText: data && data.type == "revoke" ? this.tr("Revoke") : this.tr("Send"),
                    confirmDisabled: confirmDisabled,
                    loading: data.loading,
                    warning: () => {
                        if (data.ids) {
                            return this.renderContentFromListData(data);
                        }
                
                        return data ? this.renderContent(data) : "";
                    },
                    close: this.props.closeDialog,
                    onCloseClick: this.props.closeDialog,
                    onConfirm: () => {
                        const dialogData = {
                            ...this.state.dialogData, 
                            loading: true
                        }
                        this.originalDialogProps(dialogData)
                        this.setState({dialogData}, () => this.submitHours(_.cloneDeep(this.state.dialogData.dateRange)))
                    }
                }
            },
        }

        this.setState({ dialogData: data, currentDialogProps: this.dialogProps.sendForApproval.dialogProps })
    };

    renderContent = (data) => {
        const showAllHours = Number(data.sum_all_hours) > Number(data.sum_daterange);
        const dateRangeText = data.type == "revoke" ? "revokeable" : "submittable";
        const totalText = data.type == "revoke" ? "revokeable" : "not yet submitted";

        return (
            <>
                <DateRangePicker
                    className="dialogDateRange"
                    ranges={[data.dateRange]}
                    onChange={this.onDialogDateChange}
                    onInputChange={this.onDialogDateInputChange}
                    label={this.tr("Send hours from timerange")}
                    dateFormat={this.context.userObject.dateFormat}
                />
                <p className="large-text"  id="hours_to_approval">{this.tr("You have ${amount} h " + dateRangeText + " in the selected timerange.", {amount: formatInputNumber(data.sum_daterange, "hours")})}</p>

                {showAllHours &&
                    <p className="large-text">{this.tr("You have total ${amount} h " + totalText + ".", {amount: formatInputNumber(data.sum_all_hours, "hours")})} 
                        <a onClick={(evt) => this.selectAllHoursForSubmit(evt)}> {this.tr("Select all")}</a> 
                    </p>
                }
            </>
        );
    }

    renderContentFromListData = (data) => {
        const alreadyUpdated = data.alreadyUpdated.length;
        const notAllowed     = data.notAllowed.length;
        const notEditable    = data.notEditable.length;
        const incorrectData  = data.incorrectData.length;
        const notSubmittable = data.notSubmittable?.length;

        let warningInfo = "";
        if (data.updateHours.length == 0)
            warningInfo = this.tr("No updateable hour entries.");
        else {
            warningInfo = data.type == "submit" 
                ? this.tr("Are you sure you want to send ${amount} hour entries for approval?", {amount: data.updateHours.length}) 
                : this.tr("Are you sure you want revoke ${amount} hour entries?", {amount: data.updateHours.length});
        }

        const infoPart = data.type == "submit" 
            ? "sent for approval" 
            : "revoked";

        const alreadyUpdatedPart = data.type == "submit" 
            ? this.tr("hour entries are already submitted.") 
            : this.tr("hour entries are already in status \"Waiting\".");

        return (
            <>
                <p>{warningInfo}</p>
                <ul>
                {alreadyUpdated > 0 && 
                    <li>{alreadyUpdated + " " + alreadyUpdatedPart}</li>
                }
                {incorrectData > 0 && 
                    <li>{incorrectData + " " + this.tr("hour entries cannot be " + infoPart) + ": " +  this.tr("Wrong status.")}</li>
                }
                {notSubmittable > 0 && 
                    <li>{notSubmittable + " " + this.tr("hour entries cannot be " + infoPart) + ": " +  this.tr("Hour approval submission is not enabled.")}</li>
                }
                {notEditable > 0 && 
                    <li>{notEditable + " " + this.tr("hour entries cannot be " + infoPart) + ": " +  this.tr("Not editable anymore.")}</li>
                }
                {notAllowed > 0 && 
                    <li>{notAllowed + " " + this.tr("hour entries cannot be " + infoPart) + ": " +  this.tr("No permission.")}</li>
                }
                </ul>
            </>
        );
    }

    selectAllHoursForSubmit = (evt) => {
        evt.preventDefault();
        const dateRange = {
            startDate: this.state.dialogData.all_hours_start,
            endDate: this.state.dialogData.all_hours_end,
            key: "selection"
        }

        const dialogData = {
            ...this.state.dialogData, 
            dateRange, 
            sum_daterange: this.state.dialogData.sum_all_hours, 
            allSelected: true,
            selectedHours: this.state.dialogData.all_hours.map(e => e.id)
        }

        this.originalDialogProps(dialogData)
    }

    onDialogDateChange = async (event) => {
        const selection = event.selection ? event.selection : event.range1;
        const { startDate, endDate } = selection;
        const dateRange = {
            startDate: format(startDate, "YYYY-MM-DD"),
            endDate: format(endDate, "YYYY-MM-DD"),
            key: "selection",
        }

        const fetchData = await this.fetchDialogData(dateRange, this.state.dialogData.type);
        const dialogData = {
            ...this.state.dialogData, 
            dateRange,
            ...fetchData
        }

        this.originalDialogProps(dialogData)
    };

    onDialogDateInputChange = async (dateType, date) => {
        const { endDate, startDate } = this.state.dialogData.dateRange;
        date = format(date, "YYYY-MM-DD");
        let dateRange = {};
        if (dateType == "start") {
            dateRange = {
                startDate: date,
                endDate: endDate,
                key: "selection",
            }
        }
        else {
            dateRange = {
                startDate: startDate,
                endDate: date,
                key: "selection",
            }
        }
        const fetchData = await this.fetchDialogData(dateRange, this.state.dialogData.type);
        const dialogData = {
            ...this.state.dialogData, 
            dateRange,
            ...fetchData
        }

        this.originalDialogProps(dialogData)
    };

    submitHours = (dateRange = undefined) => {
        const data = _.cloneDeep(this.state.dialogData);
        const url  = `timetracker/workhours/put_approval`; 
        const ids = data.ids ? data.updateHours.join(",") : data.selectedHours.join(",");
        const hourInfoPart = data.ids ? "hour entries" : "h";
        const params = { ids: ids, type: data.type, hour_amount: data.sum_daterange};
        let analyticsData = {
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "taimer_version": this.context.versionId,
            "quantity": 0,
        };
        DataHandler.put({ url: url }, { data: params })
            .done(response => {
                if (response.response && response.response.status === "FAILED") {
                    const info = data.type == "revoke" 
                        ? this.tr("Error in revoking workhours")
                        : this.tr("Error in sending workhours")
                    
                    this.props.enqueueSnackbar && this.props.enqueueSnackbar(info, {
                        variant: "error",
                    });
                }
                else {
                    const updatedAmount = data.ids ? response.updated_entries : formatInputNumber(response.updated_hours, "hours");
                    const notUpdatedAmount = data.ids ? response.not_updated_entries : formatInputNumber(response.not_updated_hours, "hours");

                    const info = data.type == "revoke" 
                        ? this.tr("${amount} " + hourInfoPart + " revoked", {amount: updatedAmount})
                        : this.tr("${amount} " + hourInfoPart + " submitted for approval", {amount: updatedAmount})
                    if (response.updated_entries > 0) {
                        analyticsData.quantity = response.updated_entries;
                        const eventFunction = data.type == "revoke" ? "hours_recover_approval" : "hours_approval";
                        //this.context.functions.sendAnalytics(eventFunction, analyticsData);
                        this.props.enqueueSnackbar && this.props.enqueueSnackbar(info, {
                            variant: "success",
                        });
                    }
                    response.not_updated_entries > 0 && this.props.enqueueSnackbar && this.props.enqueueSnackbar(this.tr("${amount} " + hourInfoPart + " could not be updated", {amount: notUpdatedAmount}), {
                        variant: "error",
                    });

                    this.props.onConfirm && this.props.onConfirm(dateRange);
                }
                this.props.closeDialog();
            })
            .fail(err => {
                const info = data.type == "revoke" 
                    ? this.tr("Error in revoking workhours")
                    : this.tr("Error in sending workhours")
            
                this.props.enqueueSnackbar && this.props.enqueueSnackbar(info, {
                    variant: "error",
                });

                this.props.closeDialog();
            });
    }

    fetchDialogData = async (dateRange, type = "submit", refresh = 0) => {
        const fetchParams = {
            start: dateRange.startDate,
            end: dateRange.endDate,
            for_approval_submit: 1,
            type,
            refresh
        }
    
        let fetchData = {};
        try {
            fetchData = await DataHandler.get({ url: "timetracker/workhours", ...fetchParams });
            fetchData.selectedHours = fetchData.daterange_hours.map(e => e.id);
        } catch (err) { };
    
        return fetchData;
    }

    render() {
        const { dialogData, currentDialogProps } = this.state;
        if (!currentDialogProps)
            return null; 

        return (
            <MassDialog
                open
                onDialogClose={this.props.closeDialog}
                onClose={this.props.closeDialog}
                onDialogSave={this.confirmDialog}
                data={dialogData}
                dialogType={"delete"}
                dialogProps={{...currentDialogProps}}
        />
        );
    }	
}

SubmitHoursDialog.defaultProps = {
    
};

export default SubmitHoursDialog;