import PropTypes from 'prop-types';
import TaimerComponent from '../TaimerComponent';

/* context */
import { SettingsContext } from './../SettingsContext';

/* local components */
import ProfitLoss from '../charts/ProfitLoss';
import HourInsights from '../dashboard/insights/hours/HoursInsight';
import ContextMenu from '../general/ContextMenu';
import ProductCatalogModalProvider from '../list/dialogs/ProductCatalogModalProvider';
import MailLogin from '../mail/MailLogin';
import ActionIcons from '../navigation/ActionIcons';
import TabActivities from './../general/TabActivities';
import TabAttachments from './../general/TabAttachments';
import TabFinance from './../projects/TabFinance';
import TabAccountStructure from './TabAccountStructure';
import TabAsiakastieto from './TabAsiakastieto';
import TabBasic from './TabBasic';
import TabGoals from './TabGoals';
import TabMediapro from './TabMediapro';
import TabProjects from './TabProjects';
import TabRelations from './TabRelations';
import TabReportingGroup from './TabReportingGroup';

/* css */
import './AccountView.css';

/*material ui */
import MenuItem from '@mui/material/MenuItem';

/* data backend */
import DataHandler from './../general/DataHandler';

/* other */
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import InvoicingInsight from '../dashboard/insights/invoicing/InvoicingInsight';
import WithTabs from '../navigation/WithTabs';
import List from './../list/List';
import NoPermissionOverlay from './../list/overlays/NoPermissionOverlay';
import TabMemo from './TabMemo';

import { AddBusiness, Business, CorporateFare, Lock, LockOpen, Refresh, Star, SubdirectoryArrowRight, VpnKey } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import ConnectAccountToParent from '../dialogs/ConnectAccountToParent';
import DataList from '../general/DataList';
import SpecialPermissionSlider from '../general/SpecialPermissionSlider';
import VersionContentManager from '../general/VersionContentManager';
import cardStyles from '../general/styles/CardStyles.module.scss';
import styles from './AccountView.module.scss';
import { ReactComponent as Loading } from '../dashboard/insights/img/loading.svg';

class AccountView extends TaimerComponent {
    static contextType = SettingsContext;
    backButton = {
        visible: true,
        fallbackLocation: { module: 'contacts', action: 'main', selectedTab: 'accounts' },
    };
    headerTitle = this.tr('Account');
    snackbarKey = false;

    constructor(props, context) {
        super(props, context, 'accounts/AccountView');

        const {
            userObject: { customer_read_companies },
        } = context;
        const tabs = this.formatTabs(VersionContentManager.getViewTabs(this.namespace));

        this.state = {
            account: {
                rating: 0,
                name: '',
                invoicing_addresses: [{ id: -1 }],
                visiting_addresses: [],
                delivery_addresses: [{ id: -1 }],
                account_hours: [],
                team_members: [],
                contacts: [],
                types: [],
                activities: [],
                invoicing: [],
                rights: [],
                project_rights: [],
                id: 'unsaved',
                customer_id: '',
                currency: 'EUR',
                parent_status: 0,
            },
            companies: [],
            showDeleteConfirmation: false,
            savedCallback: null,
            customership_groups: [],
            account_types: [],
            customFields: [],
            customFieldsErrors: {},
            accountLockDialogData: {
                open: false,
            },
            showAccountNameInHeader: (this.props.selectedSubTab || 'overview') != 'overview',
            accounts: [],
            mainUnits: [],
            tabs,
        };

        if (this.context.addons.custom_customer_id && this.context.addons.custom_customer_id.used_by_companies.indexOf(props.company) > -1) {
            this.state.account.customer_id = '';
            this.state.accountNumberExists = false;
        }

        this.state.account.account_manager = context.userObject.usersId;

        let initalCompany = customer_read_companies.find((el) => el.id == context.userObject.companies_id);
        if (!initalCompany && customer_read_companies.length > 0) {
            initalCompany = customer_read_companies[0];
        }
        this.state.company = props.company > 0 ? props.company : initalCompany ? initalCompany.id : context.functions.getCompany('customers', 'read');

        if (props.parent_company > 0) {
            this.state.account.parental_company = props.parent_company;
            this.state.account.parent_status = 2;
        }
        if (props.main_unit > 0) {
            this.state.account.main_unit = props.main_unit;
            this.state.account.parent_status = 4;
        }
    }

    formatTabs = (tabs, pipelines = []) => {
        return tabs ? tabs.map((tab) => this.formatTab(tab, pipelines)) : undefined;
    };

    formatTab = (tab, pipelines) => {
        let tabItems = tab.items;
        if (this.context.addons.use_estonian_business_register?.used_by_companies.includes(this.state?.company || this.context.userObject.companies_id) && tabItems)
            tabItems = tabItems.filter(i => i.id != 'asiakastieto');
        return {
            ...tab,
            getDestination: this.getDestinationForTab,
            onClick: this[tab.onClick],
            label: this.tr(tab.label),
            items:
                tab.id == 'pipeline'
                    ? (pipelines || []).filter((pipeline) => Number(pipeline.id) > 0).map((pipeline) => ({ ...pipeline, id: `pipeline_${pipeline.id}` }))
                    : this.formatTabs(tabItems, pipelines),
        };
    };

    getDestinationForTab = (tab) => {
        const { account, company } = this.state;
        const reportAccountParams = {
            accounts_id: account.id,
            accounts_name: account.name,
            preset_company: company,
        };
        switch (tab.id) {
            case 'employees_report':
                return {
                    module: 'reports',
                    action: 'view',
                    selectedReport: 'employees_report',
                    selectedTab: 'hourReports',
                    ...reportAccountParams,
                };
            case 'employees_daily':
                return {
                    module: 'reports',
                    action: 'view',
                    selectedReport: 'employees_daily',
                    selectedTab: 'hourReports',
                    ...reportAccountParams,
                };
            case 'invoicing_vs_subcontracting':
                return {
                    module: 'reports',
                    action: 'view',
                    selectedReport: 'invoicing_vs_subcontracting',
                    selectedTab: 'invoicing',
                    ...reportAccountParams,
                };
            case 'invoicing_vs_subcontracting_bills':
                return {
                    module: 'reports',
                    action: 'view',
                    selectedReport: 'invoicing_vs_subcontracting_bills',
                    selectedTab: 'invoicing',
                    ...reportAccountParams,
                };
            default:
                return undefined;
        }
    };

    openParentDialog = (type) => {
        if (VersionContentManager.isFeatureUpgradeTrigger(this.namespace, type == 'parent_company' ? 'subsidiaries' : 'subUnits')) {
            this.context.functions.showUpgradeSlider();
            return;
        }
        const { account, mainUnits, company } = this.state;
        this.context.functions.showDialog(
            <ConnectAccountToParent type={type} id={account.id} onClose={this.context.functions.closeDialog} onSave={this.parentSaved} parents={mainUnits} company={company} />
        );
    };

    parentSaved = (parent) => {
        this.accountDataChanged(parent);
        this.context.functions.closeDialog();
    };

    updateComponentData = () => {
        const { id } = this.props;
        const { company } = this.state;
        this.getProjectPipelines();

        DataHandler.get({ url: `subjects/account_entities/vatid+customer_company+parent_id` }).done((accounts) => {
            const mainUnits = accounts
                .filter((el) => el.companies_id == 0)
                .map((el) => {
                    el.label = el.name;
                    return el;
                })
                .sort((a, b) => {
                    return a.label.localeCompare(b.label);
                });
            this.setState({ accounts: accounts, mainUnits: mainUnits });
        });

        DataHandler.get({ url: `accounts/${id || 'new'}/company/${company}` })
            .done((data) => {
                this.setState({ account: { ...this.state.account, ...data, types: (data.types || []).map(t => ({ ...t, value: t.id })) } }, () => {
                    if (!this.checkPrivilege('customers', 'read')) {
                        this.props.updateView({
                            selectedTab: 'relations',
                        });
                    }
                });
            })
            .fail(() => {
                this.setState({ error: 'no_permission' });
            });
        DataHandler.get({ url: `settings/company/${company}/account/customfields`, include_deleted: id || 0 }).done((data) => {
            this.setState({ customFields: data });
        });
        DataHandler.get({ url: `settings/company/${company}/defaults` }).done((response) => this.setState({ currency: response.currency }));
        if (this.context.taimerAccount.shareCustomerBasicForAll) {
            DataHandler.get({ url: `subjects/companies`, companies: this.context.userObject.customer_read_companies.map((e) => e.id), print_lang: 1, currency: 1, print_options: 1 }).done(
                (companies) => this.setState({ companies: companies.map((c) => ({ ...c, label: c.name })) })
            );
        } else {
            DataHandler.get({ url: `subjects/companies/customers/read`, print_lang: 1, currency: 1, print_options: 1 }).done((companies) =>
                this.setState({ companies: companies.map((c) => ({ ...c, label: c.name })) })
            );
        }
        DataHandler.get({ url: `subjects/customership_groups/${company}` }).done((customership_groups) => {
            customership_groups.unshift({ id: 0, name: this.tr('All') });
            this.setState({ customership_groups });
        });
        DataHandler.get({ url: `subjects/account_types` }).done((account_types) => {
            account_types.unshift({ id: 0, name: this.tr('All') });
            this.setState({ account_types });
        });
    };

    updateAccountRights = () => {
        const { id } = this.props;
        const { company } = this.state;
        this.updateComponentData();
        DataHandler.get({ url: `accounts/${id}/company/${company}/rights` }).done((data) => {
            const account = _.cloneDeep(this.state.account);
            account.rights = data;
            this.setState({ account });
        });
    };

    componentDidMount() {
        super.componentDidMount();
        this.updateComponentData();

        window.addEventListener('activitySaved', this.updateComponentData);
        window.addEventListener('newContactCreated', this.updateComponentData);
    }

    componentDidUpdate = (prevProps) => {
        if ((this.props.selectedTab && prevProps.selectedTab !== this.props.selectedTab) || (this.props.selectedSubTab && prevProps.selectedSubTab !== this.props.selectedSubTab)) {
            this.setState({ showAccountNameInHeader: (this.props.selectedSubTab || 'overview') != 'overview' }, () => {
                this.context.functions.setOverrideHeaderTitles(undefined);
            });
        }
    };

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('activitySaved', this.updateComponentData);
        window.removeEventListener('newContactCreated', this.updateComponentData);
    }

    getProjectPipelines = async () => {
        const pipelines = await DataHandler.get({ url: `subjects/pipelines/${this.state.company}` });
        const tabs = this.formatTabs(VersionContentManager.getViewTabs(this.namespace), pipelines);
        this.setState({ tabs });
    };

    accountDataChanged = (update, showSnackbar = false) => {
        const { id } = this.props;

        if (update.tags) update.updateTags = true;

        const account = { ...this.state.account };
        if (!id) this.context.functions.setDirty(true, this.dirtyHandler);

        if (update.main_unit > 0) {
            account.parental_company = undefined;
        }
        if (update.parental_company > 0) {
            account.main_unit = undefined;
        }

        if (update.custom) {
            update.custom = { ...account.custom, ...update.custom };
        }

        
        this.setState({ account: { ...account, ...update } }, () => {
            if (id) this.save({ id, ...update}, showSnackbar, account);
        })
        
    };

    save = (update, showSnackbar = false, oldAccData = false) => {
        const { company } = this.state;
        update.id &&
            update.id > 0 &&
            DataHandler.put({ url: `accounts/${update.id}/company/${company}`, relations_id: this.state.account.relations_id }, update)
                .done((res) => this.handleSaveResponse(res, showSnackbar))
                .fail((response) => {
                    if (response.status === 403 && response.responseJSON.error && response.responseJSON.error === 'EXISTING_ACCOUNT_ID') {
                        this.showAccountNumberExistsNotification();
                        return;
                    }

                    if (response.responseJSON.error === "fields_missing") {
                        this.props.enqueueSnackbar(this.tr('Saving information failed! Required account details are missing:  ${fields}!', {
                            fields: response.responseJSON.missing.join(', '),
                        }), {
                            variant: 'error',
                        });
                        if(oldAccData) {
                            this.setState({ account: oldAccData });
                        }
                    }
                });
    };

    handleSaveResponse = (response, showSnackbar = false) => {
        let account = { ...this.state.account };

        Object.keys(account).forEach((e) => {
            if (response[e]) account[e] = response[e];
        });
        // resetting team members
        const team_members = _.cloneDeep(account.team_members || [])
            .filter((member) => member.deleted != 1)
            .map((member) => ({ ...member, new: 0 }));
        account = {
            ...account,
            team_members,
        };

        this.setState({ account, saving: false }, () => {
            !!showSnackbar &&
                this.props.enqueueSnackbar(this.tr('Changes saved succesfully!'), {
                    variant: 'success',
                });
        });
    };

    checkPrivilege = (group, right, company) => {
        const {
            functions: { checkPrivilege },
        } = this.context;
        const {
            account: { rights, id },
        } = this.state;

        if (group === 'customers' && id > 0) return rights.indexOf(right) > -1;
        //global
        else return checkPrivilege(group, right, company);
    };

    hasProjectPrivilege = (right, company) => {
        const {
            functions: { checkPrivilege },
        } = this.context;
        const {
            account: { project_rights },
        } = this.state;
        const global = checkPrivilege('projects', right, company);

        // Has rights globally
        if (global) return true;

        for (const i in project_rights) {
            for (const j in project_rights[i].rights) {
                if (project_rights[i].rights[j] === right) {
                    return true;
                }
            }
        }
        return false;
    };

    showAccountNumberExistsNotification = () => {
        this.props.enqueueSnackbar(this.tr('The given account number already exists. Please choose another account number.'), {
            variant: 'error',
        });
    };

    updateAccountNumber = (accountNumber) => {
        this.setState({
            account: {
                ...this.state.account,
                customer_id: accountNumber,
            },
        });
    };

    lockCustomer = (data, selectNewAccount) => {
        const { company } = this.state;

        if (data.productAmount > 0 && selectNewAccount) {
            DataHandler.post(
                { url: `products/supplier/assign_new/${data.companies_id || company}` },
                { oldSupplier: data.orig_id || data.id, newSupplier: selectNewAccount.supplier, newAddress: selectNewAccount.address }
            );
        } else if (data.productAmount > 0) {
            DataHandler.delete({ url: `products/supplier/${data.orig_id || data.id}` });
        }

        DataHandler.post({ module: 'customers', action: 'lockCustomer' }, { id: data.orig_id || data.id, relations_id: data.relations_id }).done(() => {
            setTimeout(this.updateComponentData, 1000);
        });

        this.closeAccountLockDialog();
    };

    unLockCustomer = () => {
        const { account, company } = this.state;
        const data = {
            companies_id: company,
            locked: -1,
        };
        DataHandler.put({ url: `accounts/${account.id}/unlock`, ...data }).done(() => {
            setTimeout(this.updateComponentData, 1000);
        });
    };

    closeAccountLockDialog = () => {
        this.setState({ accountLockDialogData: { ...this.state.accountLockDialogData, open: false } });
    };

    openAccountLockDialog = (data, id, onConfirm, deleteRelation = false) => {
        DataHandler.get({ url: `products/supplier/${id}/productamount`, type: data.type_id, company: data.companies_id }).done((response) => {
            data.productAmount = Number(response.productAmount);

            this.setState({ accountLockDialogData: { open: true, data, action: deleteRelation ? 'delete' : 'lock', onClose: this.closeAccountLockDialog, onConfirm } });
        });
    };

    hasRightToView = (view) => {
        const { company, account, tabs } = this.state;
        const {
            userObject,
            privileges,
            versionId,
            taimerAccount: { isMulticompany, countryCode },
        } = this.context;

        switch (view) {
            case 'projects_list':
            case 'projects_kanban':
                return this.hasProjectPrivilege('read', company);
            case 'activities':
                return this.checkPrivilege('customers', 'customer_crm_read', company);
            case 'reporting_group':
                return this.checkPrivilege('customers', 'read', company);
            case 'financial_overview':
                return (
                    (this.checkPrivilege('invoices', 'write_simple', company) || this.checkPrivilege('invoices', 'write_full', company)) &&
                    this.checkPrivilege('projects', 'project_cost_estimate_read', company) &&
                    this.checkPrivilege('projects', 'project_actual_costs_read', company) &&
                    this.checkPrivilege('projects', 'project_billing_entries_read', company)
                );
            case 'relations':
                return isMulticompany;
            case 'account_structure':
                return versionId >= 3 || this.checkPrivilege('customers', 'unit_read', company);
            case 'profit_loss':
                return this.checkPrivilege('customers', 'profit_loss_read', company);
            case 'hours_insight':
                return this.checkPrivilege('customers', 'hours_read', company);
            case 'invoicing_insight':
                return this.checkPrivilege('customers', 'invoicing_read', company);
            case 'goals':
                return (
                    this.checkPrivilege('customers', 'customer_budgets_target_read', company) ||
                    this.checkPrivilege('customers', 'customer_budgets_forecast_read', company) ||
                    this.checkPrivilege('customers', 'customer_budgets_commitment_read', company)
                );
            case 'email':
                return userObject.show_email == 1;
            case 'attachments':
                return this.checkPrivilege('customers', 'attachments_read', company);
            case 'memo':
                return this.checkPrivilege('customers', 'customer_memo_read', company);
            case 'mediapro':
                return (
                    ((parseInt(account?.parent_id || 0) > 0 && this.context.addons?.mediapro?.used_by_companies.indexOf(company) > -1) || false) &&
                    this.checkPrivilege('customers', 'mediapro_read', company)
                );
            case 'asiakastieto':
                return countryCode == 'FI';
            case 'employees_report':
            case 'employees_daily':
                return true; // users can see reports of their own hours
            case 'invoicing_vs_subcontracting':
            case 'invoicing_vs_subcontracting_bills':
                return ['invoicing'].some((right) => (privileges.reports || {})[right]);
            case 'recognition_reports':
                return ['recognition'].some((right) => (privileges.reports || {})[right]);
            default: {
                const tab = tabs.find((t) => t.id == view);
                if (tab) {
                    const permission = (!tab.permission || this.checkPrivilege(tab.permission[0], tab.permission[1], company)) && !tab.hidden;
                    const addon = !tab.addon || (this.context.addons.hasOwnProperty(tab.id) && this.context.addons[tab.id].used_by_companies.indexOf(company) > -1);
                    return permission && addon;
                }
                return true;
            }
        }
    };

    onCompanyChanged = (company) => {
        if (this.state.account?.parent_status == 4) {
            this.props.enqueueSnackbar(this.tr('Cannot view Sub-units as other companies'), {
                variant: 'error',
            });
            return;
        }
        this.props.updateView({ company }, false, undefined, undefined, true);
        this.setState({ company, account: { ...this.state.account, tags: false } }, this.updateComponentData);
    };

    getCompanyField = () => {
        const { company, companies } = this.state;
        const {
            taimerAccount: { isMulticompany },
        } = this.context;
        if (!isMulticompany) return null;
        return (
            <div className={styles.companyField}>
                <DataList
                    placeholder={this.tr('Select company')}
                    variant="standard"
                    classNamePrefix="gura"
                    className={styles.companyDatalist}
                    value={companies.find((c) => c.id == company)}
                    options={companies}
                    onChange={(c) => this.onCompanyChanged(c.id)}
                    menuPosition="fixed"
                />
            </div>
        );
    };

    getHeaderTitles = () => {
        const noAccountName = !this.state?.account?.name || !this.state.showAccountNameInHeader;
        return noAccountName
            ? [this.tr('Account')]
            : [
                  {
                      label: `${this.state?.account?.name}${this.state?.account?.customer_id ? ` (${this.state?.account?.customer_id})` : ''}`,
                      sublabel: this.getCompanyField,
                  },
              ];
    };

    setShowAccountNameInHeader = (showAccountNameInHeader) => {
        const customerId = this.context.addons?.custom_customer_id?.used_by_companies?.indexOf(this.state.company) > -1
            ? ` (${this.state?.account?.customer_id || ""})`
            : ` (${this.state?.account?.id || ""})`;

        this.context.functions.setOverrideHeaderTitles(
            showAccountNameInHeader
                ? [
                      {
                          label: `${this.state?.account?.name}${customerId}`,
                          sublabel: this.getCompanyField,
                      },
                  ]
                : undefined
        );
    };

    addProject = () => {
        const { account, company } = this.state;
        this.context.functions.addProject({
            customers_id: account.id,
            companies_id: company,
            origin_point: "account_view",
        });
    };

    addActivity = () => {
        const { account, company } = this.state;
        this.context.functions.openActivitySlider({
            companies_id: company,
            customers_id: this.checkPrivilege('customers', 'customer_crm_write', company) ? account.id : undefined,
        });
    };

    addContact = () => {
        const { account, company } = this.state;
        this.context.functions.addContact({
            companies_id: company,
            customers_id: account.id,
        });
    };

    updateDataFromAsiakastieto = async () => {
        const { account, company } = this.state;
        const update = {};
        const results = await DataHandler.get({ url: `/accounts/${account.id}/asiakastieto`, vatid: account.vatid, no_graphs: false, force_get: true });

        if (results.length == 0 || Object.keys(results).length == 0) {
            this.props.enqueueSnackbar(this.tr('Failed to find account') + '.', {
                variant: 'error',
            });
            return;
        }

        _.forEach(results, (result, i) => {
            if (i == 'invoicing_address' || i == 'visiting_address' || i == 'delivery_address') {
                i = i + 'es';
                update[i] = [];
                let addressExists = false;
                const asiakastietoAddress = {};
                if (Object.keys(account[i]).length > 0) {
                    _.forEach(account[i], (address, x) => {
                        let diffCount = 0;
                        _.forEach(address, (field, n) => {
                            if (n === 'address' || n === 'postalcode' || n === 'city') {
                                if (account[i][x][n] !== result[n]) diffCount++;
                            }
                            asiakastietoAddress[n] = result[n];
                        });
                        if (diffCount == 0) {
                            asiakastietoAddress['id'] = address.id;
                            addressExists = true;
                        } else if (!addressExists) {
                            asiakastietoAddress['id'] = -1;
                            asiakastietoAddress['vatid'] = account.vatid;
                        }
                    });
                    if (Object.keys(asiakastietoAddress).length > 0) update[i].push(asiakastietoAddress);
                } else {
                    result['id'] = -1;
                    update[i].push(result);
                }
            }
        });
        this.accountDataChanged(update, true);
    };

    updateDataFromAriregister = async () => {
        const { account, company } = this.state;
        const update = {};
        const result = await DataHandler.get({ url: `/accounts/${account.id}/ariregister`, vatid: account.vatid });

        if (!result || Object.keys(result).length == 0 || result.ariregister_not_found) {
            this.props.enqueueSnackbar(this.tr('Failed to find account') + '.', {
                variant: 'error',
            });
            return;
        }

        ['invoicing_address', 'visiting_address'].forEach(name => {
            const freshData = result[name];
            const dataString = name + 'es';
            update[dataString] = [];

            let addressExists = false;
            const registerAddress = {};

            if (Object.keys(account[dataString]).length > 0) {
                _.forEach(account[dataString], (address, x) => {
                    let diffCount = 0;
                    _.forEach(address, (field, n) => {
                        if (n === 'address' || n === 'postalcode' || n === 'city') {
                            if (account[dataString][x][n] !== freshData[n]) 
                                diffCount++;
                        }
                        if (n === 'vatid_2') {
                            if (account[dataString][x][n] !== result['vatid_2'])
                                diffCount++;
                        }
                        registerAddress[n] = freshData[n];
                    });
                    if (diffCount == 0) {
                        registerAddress['id'] = address.id;
                        addressExists = true;
                    } else if (!addressExists) {
                        registerAddress['id'] = -1;
                        registerAddress['vatid'] = result.vatid;
                        registerAddress['vatid_2'] = result.vatid_2;
                    }
                });
                if (Object.keys(registerAddress).length > 0) 
                    update[dataString].push(registerAddress);
            } else {
                freshData['id'] = -1;
                update[dataString].push(freshData);
            }

        });
        this.accountDataChanged(update, true);
    };


    renderActionButtons = () => {
        if (!this.props.id) return null;

        const { account, company } = this.state;
        const {
            taimerAccount: { isMulticompany, countryCode },
            functions: { checkPrivilege },
            userObject,
            privileges,
        } = this.context;

        const editable = account.parent_status == 4 ? this.checkPrivilege('customers', 'unit_write', company) : this.checkPrivilege('customers', 'write', company);

        let shownActionButtons = {
            addSubsidiary: !VersionContentManager.isFeatureHidden(this.namespace, 'subsidiaries') 
                && checkPrivilege('customers', 'write') 
                && this.checkPrivilege('customers', 'corporate_structure_write')
                && this.context.versionId >= 3,
            addSubUnit: !VersionContentManager.isFeatureHidden(this.namespace, 'subUnits') && checkPrivilege('customers', 'unit_write', company),
            connectParent: !VersionContentManager.isFeatureHidden(this.namespace, 'subsidiaries') 
                && this.checkPrivilege('customers', 'write') 
                && this.checkPrivilege('customers', 'corporate_structure_write')
                && this.context.versionId >= 3,
            connectMainUnit: !VersionContentManager.isFeatureHidden(this.namespace, 'subUnits') && this.checkPrivilege('customers', 'unit_write'),
        };
        // 0 = tavallinen asiakas, 1 emoyhtiö, 2 tytäryhtiö, 3 pääyksikkö, 4 alyksikkö
        switch (Number(account.parent_status)) {
            case 4:
                shownActionButtons = { all: false };
                break;
            case 2:
                shownActionButtons.addSubsidiary = false;
                shownActionButtons.connectParent = false;
                shownActionButtons.connectMainUnit = false;
                break;
            case 3:
                shownActionButtons.addSubsidiary = false;
                shownActionButtons.connectMainUnit = false;
                break;
            case 1:
                shownActionButtons.connectParent = false;
                shownActionButtons.connectMainUnit = false;
                shownActionButtons.addSubUnit = false;
                break;
            default:
                break;
        }
        const showLockItem = !isMulticompany && account.id > 0 && editable;

        const items = [];
        if (showLockItem) {
            items.push(
                <MenuItem key={1} onClick={account.locked < 0 ? () => this.openAccountLockDialog(account, account.id, this.lockCustomer) : this.unLockCustomer}>
                    {account.locked < 0 ? <Lock /> : <LockOpen />}
                    {account.locked < 0 ? this.tr('Lock account') : this.tr('Activate account')}
                </MenuItem>
            );
        }
        if (editable && shownActionButtons.connectParent) {
            items.push(
                <MenuItem
                    key={2}
                    onClick={() => {
                        this.openParentDialog('parent_company');
                    }}
                >
                    <Business />
                    {this.tr('Connect to Parent Company')}
                    {VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'subsidiaries') && <Star className={styles.starIcon} />}
                </MenuItem>
            );
        }

        if (editable && shownActionButtons.connectMainUnit) {
            items.push(
                <MenuItem
                    key={3}
                    onClick={() => {
                        this.openParentDialog('main_unit');
                    }}
                >
                    <SubdirectoryArrowRight />
                    {this.tr('Set as Sub-unit')}
                    {VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'subUnits') && <Star className={styles.starIcon} />}
                </MenuItem>
            );
        }

        if (account.id > 0 && this.checkPrivilege('customers', 'special_permissions') && !VersionContentManager.isFeatureHidden(this.namespace, 'specialPermissions')) {
            items.push(
                <MenuItem key={4} onClick={() => this.setSpecialPermissionSliderOpen(true)}>
                    <VpnKey />
                    {this.tr('Edit special permissions')}
                </MenuItem>
            );
        }

        if (countryCode === 'FI' && !VersionContentManager.isFeatureHidden(this.namespace, 'asiakastieto')) {
            if (this.context.addons.use_estonian_business_register?.used_by_companies.includes(company)) {
                items.push(
                    <MenuItem key={5} onClick={() => this.updateDataFromAriregister()}>
                        <Tooltip
                            title={this.tr('Will update customer data from estonian business register. Business id must match the one found in the register for the update to work.')}
                            classes={{ tooltip: 'darkblue-tooltip' }}
                        >
                            <div>
                                <Refresh />
                                {this.tr('Update from Estonian Business Register')}
                            </div>
                        </Tooltip>
                    </MenuItem>
                ); 
            } else {
                items.push(
                    <MenuItem key={5} onClick={() => this.updateDataFromAsiakastieto()}>
                        <Tooltip
                            title={this.tr('Will update customer data from suomen asiakastieto. Business id must match the one found in suomen asiakastieto for the update to work.')}
                            classes={{ tooltip: 'darkblue-tooltip' }}
                        >
                            <div>
                                <Refresh />
                                {this.tr('Update from Asiakastieto')}
                            </div>
                        </Tooltip>
                    </MenuItem>
                );                
            }

        }

        return (
            <div className={cardStyles.actionButtons}>
                {items.length > 0 && (
                    <ContextMenu
                        //@ts-ignore
                        className={cardStyles.optionsMenu}
                        buttonProps={{
                            color: 'green',
                            stickyIcon: true,
                            size: 'large',
                        }}
                        variant="outlined"
                        label={this.tr('Actions')}
                        size="medium"
                        placement={'bottom-end'}
                        data-testid={'contextmenu_actions'}
                    >
                        {items}
                    </ContextMenu>
                )}
                <ContextMenu
                    //@ts-ignore
                    className={`${cardStyles.optionsMenu} ${cardStyles.addNew}`}
                    buttonProps={{
                        color: 'green',
                        stickyIcon: true,
                        size: 'large',
                    }}
                    variant="outlined"
                    label={this.tr('Add new')}
                    size="medium"
                    placement={'bottom-end'}
                    data-testid={'account_contextmenu_add_new'}
                >
                    {privileges.invoices && privileges.invoices.write_full && (
                        <MenuItem
                            data-testid="add_new_invoice"
                            onClick={() => {
                                this.context.functions.addInvoice({
                                    account,
                                    company,
                                    origin_point: "account_view"
                                });
                            }}
                        >
                            <ActionIcons.invoice />
                            {this.tr('Invoice')}
                        </MenuItem>
                    )}
                    {this.props.id && this.checkPrivilege('projects', 'write', company) && account.allow_project_creation == 1 && account.locked < 1 && (
                        <MenuItem data-testid="add_new_project" onClick={this.addProject}>
                            <ActionIcons.project />
                            {this.tr('Project')}
                        </MenuItem>
                    )}
                    {userObject.hasCrmWritePermission && (
                        <MenuItem data-testid="add_new_activity" onClick={this.addActivity}>
                            <ActionIcons.activity />
                            {this.tr('Activity')}
                        </MenuItem>
                    )}
                    {this.checkPrivilege('persons', 'read', company) && (
                        <MenuItem data-testid="add_new_contact" onClick={this.addContact}>
                            <ActionIcons.contact />
                            {this.tr('Contact')}
                        </MenuItem>
                    )}
                    {shownActionButtons.addSubUnit && (
                        <MenuItem
                            data-testid="add_new_subunit"
                            onClick={() => {
                                this.context.functions.addAccount({ main_unit: account.id, parentname: account.name, origin_point: "account_view_add_new_subunit" });
                            }}
                        >
                            <AddBusiness />
                            {this.tr('Sub-unit')}
                        </MenuItem>
                    )}
                    {shownActionButtons.addSubsidiary && (
                        <MenuItem
                            data-testid="add_new_subsidary"
                            onClick={() => {
                                this.context.functions.addAccount({ parent_company: account.id, parentname: account.name, origin_point: "account_view_add_new_subsidiary" });
                            }}
                        >
                            <CorporateFare />
                            {this.tr('Subsidiary')}
                        </MenuItem>
                    )}
                </ContextMenu>
            </div>
        );
    };

    onOpenSlider = (title, mode) => {
        const { account, company, companies } = this.state;
        const { id } = this.props;

        const editable = account.parent_status == 4 ? this.checkPrivilege('customers', 'unit_write', company) : this.checkPrivilege('customers', 'write', company);

        const tabProps = {
            ...this.props,
            account,
            editable,
            checkPrivilege: this.checkPrivilege,
            hasProjectPrivilege: this.hasProjectPrivilege,
            /*callbacks */
            valueChanged: this.accountDataChanged,
            updateData: this.updateComponentData,
            accountNumberExists: this.state.accountNumberExists,
            updateAccountNumber: this.updateAccountNumber,
            showAccountNumberExistsNotification: this.showAccountNumberExistsNotification,
            company,
            companies,
            onCompanyChanged: this.onCompanyChanged,
            setShowAccountNameInHeader: this.setShowAccountNameInHeader,
            onOpenSlider: this.onOpenSlider,
        };
        let content;
        switch (mode) {
            case 'account_structure':
                content = <TabAccountStructure {...tabProps} customer={id} customership_groups={this.state.customership_groups} account_types={this.state.account_types} />;
                break;
            case 'relations':
                content = <TabRelations {...tabProps} openAccountLockDialog={this.openAccountLockDialog} closeAccountLockDialog={this.closeAccountLockDialog} />;
                break;
            default:
                break;
        }
        this.context.functions.showSliderContent(title, content);
    };

    setSpecialPermissionSliderOpen = (specialPermissionSliderOpen) => this.setState({ specialPermissionSliderOpen });

    render() {
        const { account, error, company, companies, customFields, customFieldsErrors, saving, accounts, mainUnits, specialPermissionSliderOpen, tabs } = this.state,
            { profit_loss_range, hours_date_range } = account;
        const { id } = this.props;
        if (error) {
            return <div>{<List showOverlay={true} overlayComponent={NoPermissionOverlay} />}</div>;
        }

        // parent_status == 4 on aliyksikkö
        const editable = account.parent_status == 4 ? this.checkPrivilege('customers', 'unit_write', company) : this.checkPrivilege('customers', 'write', company);

        const tabProps = {
            ...this.props,
            account,
            editable,
            checkPrivilege: this.checkPrivilege,
            hasProjectPrivilege: this.hasProjectPrivilege,
            /*callbacks */
            valueChanged: this.accountDataChanged,
            updateData: this.updateComponentData,
            accountNumberExists: this.state.accountNumberExists,
            updateAccountNumber: this.updateAccountNumber,
            showAccountNumberExistsNotification: this.showAccountNumberExistsNotification,
            company,
            companies,
            onCompanyChanged: this.onCompanyChanged,
            setShowAccountNameInHeader: this.setShowAccountNameInHeader,
            onOpenSlider: this.onOpenSlider,
        };

        const activityDefault = {
            customer: {
                id,
                name: account.name,
            },
        };

        return (
            <div className="taimer-view" id="account-view">
                {(saving || (id && !(account.id - 0))) && (
                    <div className="saving-overlay">
                        <Loading />
                    </div>
                )}
                <WithTabs
                    height={55}
                    tabs={tabs}
                    selectedTab={this.props.selectedTab}
                    selectedSubTab={this.props.selectedSubTab}
                    selectedSubTabChild={this.props.selectedSubTabChild}
                    checkItemPrivilege={this.hasRightToView}
                    rightComponent={this.renderActionButtons()}
                    tabsAlwaysVisible
                >
                    {(selectedTab) => {
                        if (!this.hasRightToView(selectedTab)) {
                            return (
                                <TabBasic {...tabProps} currency={this.state.currency} customFields={customFields} customFieldsErrors={customFieldsErrors} accounts={accounts} mainUnits={mainUnits} />
                            );
                        }
                        switch (selectedTab) {
                            case 'overview':
                                return (
                                    <TabBasic
                                        {...tabProps}
                                        currency={this.state.currency}
                                        customFields={customFields}
                                        customFieldsErrors={customFieldsErrors}
                                        accounts={accounts}
                                        mainUnits={mainUnits}
                                    />
                                );
                            case 'activities':
                                return (
                                    <TabActivities
                                        {...tabProps}
                                        hasWritePermission={this.checkPrivilege('customers', 'customer_crm_write', company)}
                                        editable={this.checkPrivilege('customers', 'customer_crm_write', company)}
                                        defaultTab="all"
                                        entity="account"
                                        newActivityDefault={activityDefault}
                                    />
                                );
                            case 'goals':
                                return <TabGoals {...tabProps} />;
                            case 'asiakastieto':
                                return <TabAsiakastieto {...tabProps} />;
                            case 'invoicing':
                                return <TabFinance selectedType={4} {...tabProps} entity="account" />;
                            case 'financial_overview':
                                return <TabFinance selectedType={5} {...tabProps} entity="account" />;
                            case 'profit_loss':
                                return (
                                    <ProfitLoss
                                        useDefaultDateRange
                                        startDate={profit_loss_range?.start}
                                        endDate={profit_loss_range?.end}
                                        company={tabProps.company}
                                        header={this.tr('Profit & Loss')}
                                        singleCustomer
                                        customer={id}
                                    />
                                );
                            case 'attachments':
                                return <TabAttachments {...tabProps} editable={this.checkPrivilege('customers', 'attachments_write', company)} entity="accounts" />;
                            case 'memo':
                                return <TabMemo {...tabProps} />;
                            case 'account_structure':
                                return <TabAccountStructure {...tabProps} customer={id} customership_groups={this.state.customership_groups} account_types={this.state.account_types} />;
                            case 'relations':
                                return <TabRelations {...tabProps} openAccountLockDialog={this.openAccountLockDialog} closeAccountLockDialog={this.closeAccountLockDialog} />;
                            case 'email':
                                return <MailLogin {...tabProps} className="account" />;
                            case 'reporting_group':
                                return <TabReportingGroup {...tabProps} />;
                            case 'mediapro':
                                return <TabMediapro {...tabProps} />;
                            case 'hours_insight':
                                return (
                                    <HourInsights
                                        noHeader={true}
                                        useDefaultDateRange
                                        startDate={hours_date_range?.start}
                                        endDate={hours_date_range?.end}
                                        className="insights-account-card"
                                        header={this.tr('Hours Overview')}
                                        singleCustomer
                                        company={tabProps.company}
                                        customer={id}
                                    />
                                );
                            case 'invoicing_insight':
                                return (
                                    <InvoicingInsight
                                        className="insights-account-card"
                                        header={this.tr('Invoicing Overview')}
                                        singleCustomer
                                        customer={id}
                                        company={tabProps.company}
                                        allowFullDateRange
                                    />
                                );
                            default: {
                                if (selectedTab.startsWith('pipeline_')) {
                                    return <TabProjects {...tabProps} pipeline_id={selectedTab.replace('pipeline_', '')} entity="account" />;
                                }
                                return null;
                            }
                        }
                    }}
                </WithTabs>
                <ProductCatalogModalProvider accountDeleteDialogData={this.state.accountLockDialogData} />
                <SpecialPermissionSlider
                    open={specialPermissionSliderOpen}
                    onClose={() => this.setSpecialPermissionSliderOpen(false)}
                    mode="customers"
                    company={company}
                    entityId={account.id}
                    updateRights={this.updateAccountRights}
                />
            </div>
        );
    }
}
AccountView.propTypes = {
    id: PropTypes.string,
    enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(AccountView);
