import React from 'react';
import './Integration.css';
import './Maventa.css';
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import TaimerComponent from "../../TaimerComponent";
import DataHandler from '../../general/DataHandler';
import OutlinedField from '../../general/OutlinedField';
import DataList from '../../general/DataList';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import AccountingAccounts from '../components/AccountingAccounts';
import AccountingVatCodes from '../components/AccountingVatCodes';
//import AccountingTermsOfPayments from '../components/AccountingTermsOfPayments';
import AccountingDimensionsTreeStructure from '../components/AccountingDimensionsTreeStructure';
import { SettingsContext } from '../../SettingsContext';
import AccountingWorkflows from '../components/AccountingWorkflows';
import SettingsGrid from '../pages/SettingsGrid';
import NameSelectSlider from '../components/NameSelectSlider';

import { CloudUpload, InfoOutlined } from '@mui/icons-material';

class Heeros extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Heeros");

        this.state = {
            activated: 0,
            username: "",
            password: "",
            accounting_company_id: "",
            end_customer_id: "",
            dialogData: undefined,
            currentDialog: false,
            settings: {
                "approverName" : "",
                "approveWorkflow" : "",
                "approveStatus" : "",
                "send_heeros_invoices_immediately": 0,
                "create_bills_project_dimensions": 0, 
                "bills_project_dimension_name": "",
                "create_new_customers": 0,
                "update_customers": 0,
                "create_new_products": 0,
                "update_products": 0,
            },
            dimensionSelectSliderOpen: false,
            purchasedimensionNamesFetched: false,
            dimensionHeaders: []
        };

        this.dialogs = {
            confirmation: ConfirmationDialog
        };

        this.integration = "heeros";

        this.billSettings = [
            {
				type: 'text',
				name: 'approverName',
				label: this.tr('Approver name'),
				className: 'respSetting third marginTop',
			},
			{
				type: 'text',
				name: 'approveWorkflow',
				label: this.tr('Approval workflow'),
				className: 'respSetting third',
			},
            {
				type: 'text',
				name: 'approveStatus',
				label: this.tr('Approval status number'),
				className: 'respSetting third',
			},
        ];

        this.dimensionTreeList = React.createRef();
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getSalesDimensionHeaders();
    }

    getAuthData = () => {
        DataHandler.get({url: `integrations`, company_id: this.props.company, integration: this.integration}).done(response => {
            this.setState(response);
        });
    }

    saveAuth = () => {
        const data = {
            username: this.state.username,
            password: this.state.password,
            realm: this.state.accounting_company_id + "/" + this.state.end_customer_id,
            company_id: this.props.company,
            integration: this.integration,
        }

        DataHandler.put({url: `integrations`}, data).done(response => {
            this.props.enqueueSnackbar(this.tr("Heeros authentication information saved"), {
                variant: "success",
            });	
            this.setState({activated: 1});
        });
    }

    back = () => {
        const {functions: {updateView}} = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        this.setState({
            dialogData: {
                saveFunc: () => DataHandler.delete({url: `integrations`, company_id: this.props.company, integration: 'heeros'}).done(() => {
                    this.props.enqueueSnackbar(this.tr("Heeros authentication deactivated"), {
                        variant: "success"
                    });	
                    this.back();
                }),
                text: this.tr("Do you want to deactivate Heeros integration?")
            }
        }, () => this.openDialog('confirmation'));
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    changeDefault = (type, id) => {
        const company = this.props.company;
        this.setState({[type]: id}, () => DataHandler.put({url: `accounting/defaults/${company}`}, {type: type, id: id }));
    }

    fetchAccounts = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Heeros accounts..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/integrations/accounts`, company_id: this.props.company, integration: this.integration}).done(response => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Accounts fetched succesfully!"), {
                variant: "success",
            });	
        });
    }

    fetchDimensions = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Heeros dimensions..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/integrations/dimensions`, company_id: this.props.company, integration: this.integration}).done(response => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Dimensions fetched succesfully!"), {
                variant: "success",
            });	
        });
    }

    fetchPurchaseDimensionNames = () => {
        if (this.state.purchasedimensionNamesFetched) {
            return;
        }
        DataHandler.get({url: `settings/integrations/dimensions`, company_id: this.props.company, integration: this.integration, type: "purchase_names"}).done(response => {
            this.setState({ purchasedimensionNames: response.dimension_names, purchasedimensionNamesFetched: true })
        });
    }

    fetchVatCodes = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Heeros VAT codes..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/integrations/vatcodes`, company_id: this.props.company, integration: this.integration}).done(response => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("VAT codes fetched succesfully!"), {
                variant: "success",
            });	
        });
    }

    sendBillProjects = () => {
        const { company } = this.props;

        const key = this.props.enqueueSnackbar(this.tr("Uploading projects to Heeros..."), {
            variant: "info",
            persist: true
        });

        DataHandler.post({url: `settings/company/${company}/heeros/upload_bill_projects`}).done(response => {
            this.props.closeSnackbar(key);

            if (response?.success == 1) { 
                this.props.enqueueSnackbar(this.tr("${amount} projects uploaded succesfully!", {amount: response.sent_count}), {
                    variant: "success",
                });	
            }
            else {
                let msg = this.tr("Error in sending projects!");
                let variant = "error";
                const errorMsg = response.message;
    
                if (errorMsg == "CANNOT_BE_SENT_AGAIN_IN_MINUTES") {
                    msg = this.tr("Projects have already been sent: Can be sent again in ${minutes} minutes", {minutes: response.value});
                    variant = "warning";
                }
                else if (errorMsg) {
                    const translate = response.translate_message == 1;
                    msg = this.tr("Error in sending projects: ${errorMsg}", {errorMsg: translate ? this.tr(errorMsg) : errorMsg});
                }
        
                this.props.enqueueSnackbar(msg, {variant});	

                if (Number(response.sent_count) > 0) {
                    this.props.enqueueSnackbar(this.tr("${amount} projects uploaded successfully!", {amount: response.sent_count}), {
                        variant: "success",
                    });	
                }
            }
        })
        .fail(err => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Error in sending projects!"), {
                variant: "error",
            });	
        })
    }

    onChangeBillSettings = (settings) => {
		DataHandler.post({url: `integrations/custom_settings`}, {integration: this.integration, settings: settings, company_id: this.props.company})
			.done(response => {
				this.props.enqueueSnackbar(this.tr('Bill approval settings saved'), {
					variant: 'success',
					preventDublicate: true,
				});
                this.setState({settings: settings});
			})
			.fail(response => {
				this.props.enqueueSnackbar(this.tr('Something went wrong while saving your settings'), {
					variant: 'error',
					preventDublicate: true,
				});
			});
    }

    onSettingChange = (update, name, value, additional = {}) => {
        const { company } = this.props;
        const { settings, settings: { bills_project_dimension_name } } = this.state;

        if (name == "create_bills_project_dimensions" && value == 1 && !bills_project_dimension_name) {
            this.openDimensionSelectSlider();
            return;
        }

        this.setState({settings: {...settings, [name]: value, ...additional}}, () =>  {
            DataHandler.put({url: `settings/company/${company}/heeros/settings`}, {[name]: value, ...additional}).done(response => {
                // Success response
            })
            .fail(err => {
                let msg = this.tr("Error in saving settings!");
                if (err?.responseJSON?.error) {
                    msg = err?.responseJSON?.error;
                }
                this.props.enqueueSnackbar(msg, {
                    variant: "error",
                });	
                this.updateComponentData();
            })
        });
    }

    openDimensionSelectSlider = () => {
        this.setState({ dimensionSelectSliderOpen: true });
    }

    renderDimensionSelectSlider = () => {
        const { dimensionSelectSliderOpen, purchasedimensionNames, settings: { bills_project_dimension_name, create_bills_project_dimensions }, purchasedimensionNamesFetched } = this.state;

        return (
            dimensionSelectSliderOpen ? (<NameSelectSlider
                onClose={() => this.setState({ dimensionSelectSliderOpen: false })}
                onSave={(dimensionName) => {
                    const additional = {};
                    if (create_bills_project_dimensions != 1) {
                        additional.create_bills_project_dimensions = 1;
                    }
                    this.onSettingChange({},"bills_project_dimension_name" , dimensionName, additional);
                }}
                selectedName={bills_project_dimension_name}
                options={purchasedimensionNames}
                getOptions={this.fetchPurchaseDimensionNames}
                dataFetched={purchasedimensionNamesFetched}
                title={this.tr("Activate automatic project creation")}
                fieldTitle={this.tr("Project dimension name in Heeros Purchase Invoices")}
                infoText={this.tr("To activate automatic project creation, you need to select the appropriate project dimension to which PSA projects are created.")}
                changeFreeTextLabel={this.tr("Don’t see the correct dimension name?")}
                loadingText={this.tr("Loading project dimensions...")}
            />)
            : null
        );
    }

    renderProjectUploadConfirmation = () => {
        this.context.functions.showConfirmationDialog({
            header: this.tr('Upload active PSA projects to Heeros'),
            warning: this.tr('Are you sure you want to upload all active PSA projects to Heeros Purchase Invoices for dimension use?'),
            infoText: this.tr("Max. 1000 projects can be send to Heeros Purchase invoices at a time. If you have more projects, come back to send again after an hour."),
            confirmText: this.tr('Upload'),
            onConfirm: this.sendBillProjects,
            confirmButtonClass: "blue"
        });
    }
    
    getSalesDimensionHeaders = () => {
        DataHandler.get({url: `accounting/dimension_headers/${this.props.company}/${this.integration}`}).done(response => {
            response?.dimensionHeaders.unshift({ id: 0, value: 0, label: this.tr("Not selected"), order_nums: [] });
            this.setState(response);
        });
    }

    updateDimensionOrder = (id, order_num) => {
        const header = this.state.dimensionHeaders.find(h => h.id == id);
        if (header.order_nums.find(n => n == order_num)) {
            return;
        }

        const dimensionHeaders = this.state.dimensionHeaders.map(h => {
            if (h.id != id) {
                const index = h.order_nums.findIndex(o => o == order_num);
                if (index > -1) {
                    h.order_nums.splice(index, 1);
                }
            }
            else {
                h.order_nums.push(order_num);
            }
            return h;
        });
        this.setState({ dimensionHeaders });

        DataHandler.put({url: `accounting/dimension_headers/order`}, {id: id, order_num: order_num, company: this.props.company, integration: this.integration}).done(response => {
            this.dimensionTreeList.current && this.dimensionTreeList.current.getDimensions();
        })
        .fail(err => {
            this.props.enqueueSnackbar(this.tr('Something went wrong while saving your settings'), {
                variant: 'error',
                preventDublicate: true,
            });
            this.getSalesDimensionHeaders();
        })
    }

    render(){
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        const { settings: { send_heeros_invoices_immediately, create_new_customers, update_customers, create_new_products, update_products, create_bills_project_dimensions, bills_project_dimension_name } } = this.state;        
        const projectSettings = { create_bills_project_dimensions };
        const invoicesSettings = { send_heeros_invoices_immediately, create_new_customers, update_customers, create_new_products, update_products };
        const notSelectedDimensionHeader = this.state.dimensionHeaders.find(d => d.id == 0);

        return (
            <div id="integration-wrapper" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{this.tr("Heeros settings")}</h3>
                    {this.state.activated == 1 && <Button className="red deactivate" size="large" onClick={this.deactivate}>{this.tr("Deactivate")}</Button>}
                </div>

                <div className="settings smaller-margin">
                    <h4>{this.tr("Authentication information")}</h4>
                    <OutlinedField value={this.state.username} onChange={(evt) => this.setState({username: evt.target.value})} label={this.tr("Username")} disabled={this.state.activated == 1} />
                    <OutlinedField value={this.state.password} onChange={(evt) => this.setState({password: evt.target.value})} label={this.tr("Password")} type="password" autoComplete="new-password" disabled={this.state.activated == 1} />
                    <OutlinedField value={this.state.accounting_company_id} onChange={(evt) => this.setState({accounting_company_id: evt.target.value})} label={this.tr("Accounting company ID")} />
                    <OutlinedField value={this.state.end_customer_id} onChange={(evt) => this.setState({end_customer_id: evt.target.value})} label={this.tr("End customer ID")} />

                    <Button className="auth-button" color="primary" onClick={this.saveAuth} size="large">{this.tr("Save")}</Button>
                </div>

                <div className="settings">
                    <h3>{this.tr("Sales invoices")}</h3>
                </div>

                <SettingsGrid item={invoicesSettings} onChange={this.onSettingChange} settings={[{
                    type: "check",
                    name: "send_heeros_invoices_immediately",
                    label: this.tr("Send invoices to customers immediately after they are sent to Heeros Sales Invoices"),
                    className: "respSetting third"
                }]} />

                <SettingsGrid item={invoicesSettings} onChange={this.onSettingChange} settings={[{
                    type: "check",
                    name: "create_new_customers",
                    label: this.tr("Create new customers in Heeros Sales Invoices"),
                    className: "respSetting third"
                }]} />

                <SettingsGrid item={invoicesSettings} onChange={this.onSettingChange} settings={[{
                    type: "check",
                    name: "update_customers",
                    label: this.tr("Update existing customers in Heeros Sales Invoices"),
                    className: "respSetting third"
                }]} />

                <SettingsGrid item={invoicesSettings} onChange={this.onSettingChange} settings={[{
                    type: "check",
                    name: "create_new_products",
                    label: this.tr("Create new products in Heeros Sales Invoices"),
                    className: "respSetting third"
                }]} />

                <SettingsGrid item={invoicesSettings} onChange={this.onSettingChange} settings={[{
                    type: "check",
                    name: "update_products",
                    label: this.tr("Update existing products in Heeros Sales Invoices"),
                    className: "respSetting third"
                }]} />

                <div className="settings first">
                    <h4>{this.tr("Sales accounts")}</h4>
                    <AccountingAccounts 
                        integration={this.integration}
                        company={this.props.company}
                        type={1}
                        addButtonText={this.tr("Add sales account")}
                        showDefaultOption={true}
                    />
                </div>

                <div className="settings">
                    <h4>{this.tr("VAT codes")}</h4>
                    <AccountingVatCodes
                        integration={this.integration}
                        company={this.props.company}
                        showVatAccount={false}
                    />
                </div>

                {/*<div className="settings">
                    <h4>{this.tr("Payment terms")}</h4>
                    <AccountingTermsOfPayments 
                        integration={this.integration}
                        company={this.props.company}
                    />
                </div>*/}

                <div className="settings">
                    <h4>{this.tr("Dimensions")}</h4>
                    <AccountingDimensionsTreeStructure 
                        ref={this.dimensionTreeList}
                        integration={this.integration}
                        company={this.props.company}
                        showDefaultOption={true}
                        onHeadersChanged={this.getSalesDimensionHeaders}
                    />
                </div>

                <div className="settings">
                    <h4>{this.tr("Dimensions in invoices")}</h4>
                    <div className='datalist-group-wrapper'>
                        {(["1", "2", "3"]).map((number) => {      
                            return <DataList
                                label={this.tr("Dimension" +  " " + number)}
                                name={"dimension" + "_" + number}
                                value={this.state.dimensionHeaders.find(d => (d.order_nums || []).includes(number)) || notSelectedDimensionHeader}
                                options={this.state.dimensionHeaders}
                                onChange={(e) => this.updateDimensionOrder(e.id, number)}
                                shownCount={20}
                            />
                        })}
                    </div>
                </div>

                <div className="settings">
                    <h3>{this.tr("Bills")}</h3>
                </div>

                <div className="settings first">
                    <h4>{this.tr("Bill workflows")}</h4>
                    <AccountingWorkflows
                        integration={this.integration}
                        company={this.props.company}
                        showDefaultOption={true}
                    />
                </div>

                <div className="settings">
                <h4>{this.tr("Bill accounts")}</h4>
                    <Button className="blue" onMouseUp={() => this.fetchAccounts()} size="large">{this.tr("Get accounts")}</Button>
                </div>

                <div className="settings">
                <h4>{this.tr("Bill dimensions")}</h4>
                    <Button className="blue" onMouseUp={() => this.fetchDimensions()} size="large">{this.tr("Get dimensions")}</Button>
                </div>

                <div className="settings">
                <h4>{this.tr("Bill VAT codes")}</h4>
                    <Button className="blue" onMouseUp={() => this.fetchVatCodes()} size="large">{this.tr("Get VAT codes")}</Button>
                </div>

                <div className="settings">
                    <h4>{this.tr('Approval information')}</h4>
                    <p className='subtitle'>
					    {this.tr('Define the approver and workflow status for invoices that are sent to Heeros.')}
					</p>
                    <SettingsGrid 
                        settings={this.billSettings} 
                        item={this.state.settings} 
                        onChange={this.onChangeBillSettings}
                    />
                </div>

                <div className="settings">
                    <h4>{this.tr('Projects')}</h4>
                    <SettingsGrid item={projectSettings} onChange={this.onSettingChange} settings={[{
                        type: "check",
                        name: "create_bills_project_dimensions",
                        label: this.tr("Send created projects automatically to Heeros Purchase Invoices for dimension use"),
                        className: "respSetting full-length-check"
                    }]} />

                    <div className={`text-button ${create_bills_project_dimensions == 1 ? "" : "disabled"}`}>
                        <span className='label'>{this.tr("Project dimension name")}: <b>{bills_project_dimension_name}</b></span>
                        <span className='button' onClick={() => create_bills_project_dimensions == 1 && this.openDimensionSelectSlider()}>{this.tr("Edit")}</span>
                    </div>
                    <div className={`text-button ${create_bills_project_dimensions == 1 ? "" : "disabled"}`} onClick={() => create_bills_project_dimensions == 1 && this.renderProjectUploadConfirmation()}>
                        <CloudUpload className='icon' />
                        <span className='button'>{this.tr('Upload all PSA projects to Heeros Purchase Invoices for dimension use')}</span>
                    </div>
                </div>


                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}

                {this.renderDimensionSelectSlider()}
            </div>
        )
    }
}

export default Heeros;