import React from 'react';
import TaimerComponent from '../TaimerComponent';
import { Button, Drawer } from '@mui/material';
import styles from './BuyLicensesSlider.module.scss';
import { Close } from '@mui/icons-material';
import LoaderButton from './LoaderButton';
import DataHandler from './DataHandler';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

interface Props {
    ref?: any;
    open: boolean;
    onClose?: () => void;
}

interface State {
    open: boolean;
    loading?: boolean;
}

class BuyLicensesSlider extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'general/BuyLicensesSlider');
        this.state = {
            open: false,
        };
    }

    onClose = () => {
        this.setState({ open: false }, () => {
            setTimeout(() => {
                this.props.onClose && this.props.onClose();
            }, 500);
        });
    };

    componentDidMount = () => {
        if (this.props.open) {
            this.setState({ open: true });
        }
    };

    buyMoreLicenses = () => {
        this.setState({ loading: true }, () => {
            DataHandler.get({ url: `settings/subscription/portal` }).done((response) => {
                this.setState({ loading: false });
                window.location = response;
            }).fail(() => {
                this.setState({ loading: false });
            });
        });
    };

    render() {
        const { loading, open } = this.state;
        const { ref, ...rest } = this.props;

        return (
            <Drawer {...rest} open={open} onClose={this.onClose} anchor="right">
                <div id={styles.buyLicensesSlider}>
                    <div className={styles.top}>
                        <Button onClick={this.onClose}>
                            <Close />
                        </Button>
                    </div>
                    <div className={styles.content}>
                        <Loading  />
                        <div className={styles.header}>
                            <h1>{this.tr("You're out of active licenses")}</h1>
                            <p>
                                {this.htmlTr(
                                    "To add more users, you need to buy more licenses. Don't worry – it's as easy as opening the customer portal and clicking a few buttons. We'll open the portal for you if you click the “${buttonTitle}” button below.",
                                    {
                                        buttonTitle: <strong>{this.tr('Buy more licenses')}</strong>,
                                    }
                                )}
                            </p>
                            <p className={styles.italic}>{this.tr('Remember: teamwork makes the dream work!')}</p>
                        </div>
                        <div className={styles.actions}>
                            <LoaderButton loading={loading} text={this.tr('Buy more licenses')} size="large" onClick={this.buyMoreLicenses} />
                        </div>
                    </div>
                </div>
            </Drawer>
        );
    }
}

export default BuyLicensesSlider;
