import React from 'react';

/* css */
import "./InvoiceList.css";

/* material-ui */
import CloudDownload from '@mui/icons-material/CloudDownload';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import withStyles from '@mui/styles/withStyles';

/* others */
import { WarningRounded } from '@mui/icons-material';
import { endOfMonth, format, startOfMonth } from "date-fns";
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import TaimerComponent from "../../TaimerComponent";
import colors from '../../colors';
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import DataHandler from "../../general/DataHandler";
import PageTopSection from '../../general/PageTopSection';
import Slider from '../../general/Slider';
import InvoiceLogView from '../../invoices/InvoiceLogView';
import HandleErrorDialog from "../../invoices/dialogs/HandleErrorDialog";
import NoPermissionOverlay from '../../overlays/NoPermissionOverlay';
import AdvancedSearch from "../../search/AdvancedSearch";
import { SettingsContext } from './../../SettingsContext';
import { returnCurrencyList } from "./../../general/Currencies";
import InvoiceApi from "./../../general/InvoiceApi";
import InvoiceSendFunctions from "./../../general/InvoiceSendFunctions";
import OutlinedField from "./../../general/OutlinedField";
import Utils from "./../../general/Utils";
import InvoiceTranslations from './../../general/backendTranslations/InvoiceTranslations';
import { DateRangePicker } from './../../general/react-date-range/src';
import EmptyInvoiceConfirmationDialog from "./../../invoices/dialogs/EmptyInvoiceConfirmationDialog";
import List from "./../List";
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import HandleRowAttachments from "./../dialogs/HandleRowAttachments";
import MarkAsPaidMultiple from "./../dialogs/MarkAsPaidMultiple";
import MarkAsPaidSingle from "./../dialogs/MarkAsPaidSingle";
import InvoiceListRow from "./../rows/InvoiceListRow";

import SendInvoiceWizard from '../../invoices/SendInvoiceWizard';
import InvoiceListOverlay from "../overlays/InvoiceListOverlay";

const styles = theme => ({
	marginLeft: {
		marginLeft: "16px"
	},
	exportButton: {
		cursor: "pointer",
		color: "#6b7897",
		fontSize: "14px",
		minWidth: "180px",
		display: 'flex',
		alignItems: 'center',
		minWidth: '180px',
	},
	infoContainer: {
		fontSize: "12px",
		color: '#a2abc5'
	}
});

const currencyList = returnCurrencyList();
const currencySymbolsMap = currencyList.reduce((acc, cl) => ({...acc, [cl.id]: cl.symbol}), {});

class InvoiceList extends TaimerComponent {

	static contextType = SettingsContext;

	constructor(props, context) {
		super(props, context, "list/lists/InvoiceList");

		const date = this.props.date ? new Date(this.props.date) : null;

		const start = this.props.start ? new Date(this.props.start) : (date ? startOfMonth(date) : null);
		const end = this.props.end ? new Date(this.props.end) : ( date ? endOfMonth(date) : null );
		this.initialMonth = { start, end };

		this.stickySearchKey = "invoice_list";

		// -1 reserved for waiting for actions status
		this.statuses = {
			"0": {"name": this.tr("all"), "color": "#dde3e8"},
			"5": {"name": this.tr("draft"), "color": "#979797", "trigger_id": 0},
			"1": {"name": this.tr("waiting"), "color": "#ffb822", "trigger_id": 1},
			"2": {"name": this.tr("sent"), "color": "#2d9ff7", "trigger_id": 2},
			"4": {"name": this.tr("paid"), "color": colors.greenish_cyan, "trigger_id": 3},
			"3": {"name": this.tr("credited"), "color": "#716aca", "trigger_id": 4},
			"6": {"name": this.tr("overdue"), "color": "#f52b2b", "trigger_id": 5},
			"7": {"name": this.tr("sending"), "color": "#003A78", "trigger_id": 6}
		};	

		const company = this.props.company ? this.props.company : (this.props.companies_id ? this.props.companies_id : 0);

		this.filtersInitialValues = {
			company: company ? company : context.functions.getCompany("invoices", "write_full", false, true) || context.functions.getCompany("invoices", "write_simple", false, true),
			tabValue: this.props.type ? _.findKey(this.statuses, x => x.trigger_id == this.props.type) : 0,
			dateRange: {
				startDate: this.initialMonth.start && format(this.initialMonth.start, 'YYYY-MM-DD'),
				endDate: this.initialMonth.end && format(this.initialMonth.end, 'YYYY-MM-DD'),
				key: "selection"
			}
		};


		this.autoCompleteData = false;
        this.initialFetchDone = false;

		this.dialogs = {
			singlePayment: MarkAsPaidSingle,
			attachments: HandleRowAttachments,
			multiplePayment: MarkAsPaidMultiple,
            confirmation: ConfirmationDialog,
			emptyInvoice: EmptyInvoiceConfirmationDialog,
			sendConfirmation: MassDialog,
			handleError: HandleErrorDialog
		};
		this.dialogTexts = {
			createNotice: this.tr("Are you sure you want to create payment notice?"),
			createCredit: this.tr("Are you sure you want to create credit invoice?"),
			formalize: this.tr("Are you sure you want to formalize the invoice?"),
			delete: this.tr("Are you sure you want to delete the invoice?")
		}

		this.getParams = {};
		this.savedParams = {};

		this.list = React.createRef();
		this.advancedSearch = React.createRef();

		this.state = {
			...this.filtersInitialValues,
			tabColor: '#dde3e8',
			showTabs: false,
			currentDialog: false,
			dialogData: false,
			pageCount: 1,
			perpage: this.props.perpage,
			page: 1,
			invoiceCount: "–",
			netTotal: 0,
			grossTotal: 0,
			paidTotal: 0,
			creditTotal: 0,
			draftTotal: 0,
			hasInvoices: true,
			companyCurrency: "EUR",
			companies: [],
			sendingInProgress: false,
			snackBarKey: undefined,
			stickySearchInitialized: false,
            invoice_only_material: false,
            addInvoice: this.props.addInvoice,
            isFetching: false,
			integrationErrorCompanies: [],
			allSelectedInvoicesData: [],
			checkedData: []
		};

		const sendOptionArgs = {
            lang: context.taimerAccount.ourCompanyLang,
            maventa: context.taimerAccount.hasMaventa,
			s3SweFinance: context.taimerAccount.hasS3SweFinance,
            efina: (context.addons && context.addons.efina) ? true : false,
            talenom: (context.addons && context.addons.talenom) ? true : true,
			addons: this.context.addons,
        };

        this.userTypeAutocompleteClasses = ['users', 'edited_by'];
        this.userTypeDataHeaders = {edited_id: 'edited'};
        this.translations = {
            locked: this.tr("locked"),
            freelancer: this.tr("freelancer")
         };        

		this.methods = {
			"rootOptions": {
				"0": {"analyticsKey": "Maventa", "name": this.tr("Send Maventa"), "func": "maventa", "condition": "maventa", "show": "1", "checkSent": "1", "companies": context.addons?.maventa?.used_by_companies},
				"1": {"name": this.tr("Send Sweden PE Finance"), "condition": "s3SweFinance", "func": "s3", "show": "1", "target": "s3_swe_finance"}
			},
			"eInvoiceOptions":{
				"1": {"name": this.tr("Print Finvoice v2.0"), "func": "finvoice2", "condition": "lang", "show": "fi"},
                "11": {"name": this.tr("Finvoice v3.0"), "func": "finvoice3", "condition": "lang", "show": "fi"},
				"2": {"name": this.tr("Print Gothia"), "condition": "false", "func": "default", "show": "", "companies": context.addons?.gothia?.used_by_companies},
				"3": {"name": this.tr("Send OneWay"), "condition": "false", "func": "default", "show": ""},
				"4": {"name": this.tr("Send Pohjola XML"), "condition": "false", "func": "default", "show": ""},
				"5": {"name": this.tr("Send Pohjola PDF"), "condition": "false", "func": "default", "show": ""},
				"6": {"name": this.tr("Send Suomen Perintätoimisto"), "condition": "false", "func": "default", "show": ""},
				"7": {"name": this.tr("Send SFTP"), "condition": "false", "func": "default", "show": ""},
				"8": {"name": this.tr("Print Venda"), "condition": "false", "func": "default", "show": ""},
				"9": {"name": this.tr("Send Maestro"), "condition": "false", "func": "default", "show": ""},
				"10": {"name": this.tr("Send Nordea"), "condition": "false", "func": "default", "show": ""}
			},
			"accountingOptions":{
				"0":  {"analyticsKey": "Tikon","name": this.tr("Print Tikon"), "condition": "false", "func": "default", "show": ""},
				"1":  {"analyticsKey": "Tikon rescontra","name": this.tr("Print Tikon rescontra"), "condition": "false", "func": "default", "show": ""},
				"2":  {"analyticsKey": "Netvisor","name": this.tr("Send Netvisor"), "condition": "netvisor", "func": "netvisor", "show": "", "companies": context.addons?.netvisor?.used_by_companies},
				"3":  {"analyticsKey": "Econet","name": this.tr("Send Econet"), "condition": "false", "func": "default", "show": ""},
				"4":  {"analyticsKey": "Talenom","name": this.tr("Send Talenom"), "condition": "talenom", "func": "talenom", "show": "", "companies": context.addons?.talenom?.used_by_companies},
				"5":  {"analyticsKey": "Fivaldi","name": this.tr("Send Fivaldi"), "condition": "fivaldi", "func": "fivaldi", "show": "", "companies": context.addons?.fivaldi?.used_by_companies},
				"6":  {"analyticsKey": "Wintime file","name": this.tr("Send Wintime file"), "condition": "wintime", "func": "wintime", "show": "", "companies": context.addons?.wintime?.used_by_companies},
				"7":  {"analyticsKey": "Nav","name": this.tr("Send Nav"), "condition": "nav", "func": "nav", "show": "", "companies": context.addons?.nav?.used_by_companies},
				"8":  {"analyticsKey": "NavDebug","name": this.tr("Nav Debug excel"), "condition": "nav", "func": "navDebug", "show": "", "companies": context.addons?.nav?.used_by_companies},
				"9":  {"analyticsKey": "Efina","name": this.tr("Send Efina"), "condition": "efina", "func": "efina", "show": "", "companies": context.addons?.efina?.used_by_companies},
				"10": {"analyticsKey": "Fortnox","name": this.tr("Send Fortnox"), "condition": "fortnox", "func": "fortnox", "show": "", "companies": context.addons?.fortnox?.used_by_companies},
				"11": {"analyticsKey": "Procountor","name": this.tr("Send Procountor"), "condition": "procountor", "func": "procountor", "show": "", "companies": context.addons?.procountor?.used_by_companies},
				"12": {"analyticsKey": "RopoCapital","name": this.tr("Send RopoCapital"), "condition": "ropocapital", "func": "ropocapital", "show": "", "companies": context.addons?.ropocapital?.used_by_companies},
				"13": {"analyticsKey": "Quickbooks","name": this.tr("Export Quickbooks"), "condition": "quickbooks", "func": "quickbooks", "show": true, "companies": context.addons?.quickbooks?.used_by_companies},
				"14": {"analyticsKey": "Fennoa", "name": this.tr("Send Fennoa"), "condition": "fennoa", "func": "fennoa", "show": "", "companies": context.addons?.fennoa?.used_by_companies},
				"15": {"analyticsKey": "Wintime file", "name": this.tr("Generate Wintime file"), "condition": "wintime", "func": "wintime_demo", "show": "", "companies": context.addons?.wintime?.used_by_companies},
				"16": {"analyticsKey": "Merit Aktiva", "name": this.tr("Send Merit Aktiva"), "condition": "meritaktiva", "func": "meritaktiva", "show": "", "checkSent": "1", "companies": context.addons?.meritaktiva?.used_by_companies},
				"17": {"analyticsKey": "Xero", "name": this.tr("Send Xero"), "condition": "xero", "func": "xero", "show": "", "companies": context.addons?.xero?.used_by_companies},
				//"18": {"analyticsKey": "EmCe files", "name": this.tr("Export EmCe files"), "condition": "emce", "func": "emce_invoice", "show": ""},
				"18": {"name": this.tr("Export EmCe file"), "condition": "emce", "func": "emce", "show": "", "companies": context.addons?.emce?.used_by_companies},
				"19": {"analyticsKey": "Fivaldi XML", "name": this.tr("Print Fivaldi XML"), "condition": "fivaldi", "func": "fivaldi_file", "show": "", "companies": context.addons?.fivaldi?.used_by_companies},
				"20": {"analyticsKey": "Heeros","name": this.tr("Send to Heeros"), "condition": "heeros", "func": "heeros", "show": "", "checkSent": "1", "companies": context.addons?.heeros?.used_by_companies},
				"21": {"analyticsKey": "Gothia","name": this.tr("Print Gothia"), "condition": "gothia", "func": "gothia", "show": "", "companies": context.addons?.gothia?.used_by_companies},
				"22": {"analyticsKey": "DATEV", "name": this.tr("DATEV XML"), "condition": "datev", "func": "datev_file", "show": "", "companies": context.addons?.datev?.used_by_companies},
				"23": {"analyticsKey": "NAVCSV","name": this.tr("NAV CSV"), "condition": "navcsv", "func": "navcsv", "show": "", "companies": context.addons?.navcsv?.used_by_companies},
				"24": {"analyticsKey": "TietotiliOnline Finvoice","name": this.tr("TietotiliOnline Finvoice"), "condition": "tietotili", "func": "print_tietotili", "show": "", "companies": context.addons?.tietotili?.used_by_companies},
				"25": {"analyticsKey": "TietotiliOnline", "name": this.tr("Send to TietotiliOnline"), "condition": "tietotili", "func": "send_tietotili", "show": "", "companies": context.addons?.tietotili?.used_by_companies},
				"26": {"analyticsKey": "SAP", "name": this.tr("Send to SAP"), "condition": "sap", "func": "sap", "show": "", "companies": context.addons?.sap?.used_by_companies},
				"27": {"analyticsKey": "NetSuite invoices","name": this.tr("NetSuite invoices CSV"), "condition": "netsuite", "func": "netsuite", "show": "", "companies": context.addons?.netsuite?.used_by_companies},
				"28": {"analyticsKey": "NetSuite credit invoices","name": this.tr("NetSuite credit invoices CSV"), "condition": "netsuite", "func": "netsuite_credit", "show": "", "companies": context.addons?.netsuite?.used_by_companies},
			}
		};		

		this.sendOptions = {
			root: Object.values(this.methods.rootOptions).map(e => {
				if (sendOptionArgs[e.condition] == e.show)
					return { name: e.name, condition: e.condition, func: e.func, target: e.target, analyticsKey: e.analyticsKey, checkSent: e.checkSent, companies: e.companies };
			}).filter(s => s !== undefined),
			eInvoice: Object.values(this.methods.eInvoiceOptions).map(e => {
				if (sendOptionArgs[e.condition] == e.show)
					return { name: e.name, condition: e.condition, func: e.func, companies: e.companies };
			}).filter(s => s !== undefined),
			accounting: Object.values(this.methods.accountingOptions).map(e => {
				if (sendOptionArgs.addons[e.condition] != undefined)
					return { name: e.name, condition: e.condition, func: e.func, analyticsKey: e.analyticsKey, checkSent: e.checkSent, companies: e.companies };
			}).filter(s => s !== undefined)
		};

		this.fields = [
			{ "field": "rowmenu", "staticIndex": 0, "name": "rowmenu", "header": "", "width": 60, "fieldType": "rowmenu", "showMenu": false, "resizeable": false, "moveable": false, "hideable": false },
			{ "field": "checked", "staticIndex": 1, "name": "checked", "header": "", "width": 40, "fieldType": "check", "columnHeaderType": "checkbox", "showMenu": false, "resizeable": false, "moveable": false, "hideable": false },
			{ "field": "notification", "staticIndex": 2, "name": "notification", "header": "", "width": 40, "fieldType": "notification", "showMenu": false, "resizeable": false, "moveable": false, "hideable": false },
			{ "field": "attachment", "name": "attachment", "header": "", "width": 40, "fieldType": "attachment", "showMenu": false, "resizeable": false, "moveable": false, "hideable": false, "disabled": true },
			{ "field": "status", "name": "status", "header": this.tr("Status"), "width": 160, "fieldType": "special" },
			{ "field": "bill_id", "name": "bill_id", "header": this.tr("Nr."), "width": 80, "fieldType": "bill_link" },
			{ "field": "parentid", "name": "parentid", "header": this.tr("Parent"), "width": 80, "fieldType": "bill_link" },
			{ "field": "creationdate", "name": "creationdate", "header": this.tr("Creation date"), "width": 180, "fieldType": "date", type: "date" },
			{ "field": "duedate", "name": "duedate", "header": this.tr("Due date"), "width": 180, "fieldType": "date_editable", type: "date" },
			{ "field": "deliverydate", "name": "deliverydate", "header": this.tr("Delivery date"), "width": 180, "fieldType": "date_editable", type: "date" },
			{ "field": "customer", "name": "customer", "header": this.tr("Account"), "width": 300, "fieldType": "linktext", "visualizationType": "tree", "entityMode": true },
			{ "field": "projects", "name": "projects", "header": this.tr("Projects"), "width": 300, "fieldType": "multitext", "visualizationType": "tree", "entityMode": true },
			{ "field": "billingstartdate", "name": "billingstartdate", "header": this.tr("Start date"), "width": 180, "fieldType": "date", type: "date" },
			{ "field": "billingenddate", "name": "billingenddate", "header": this.tr("End date"), "width": 180, "fieldType": "date", type: "date" },
			{ "field": "net_total", "name": "net_total", "header": this.tr("Sum VAT 0%"), "width": 150, "fieldType": "currency", type: "number" },
			...(["customers-view", "projects-view"].includes(this.props.selectedPage) ? [{ field: "assigned_total", name: "assigned_total", header: this.tr("Assigned total"), width: 150, "fieldType": "currency", type: "number" }] : []),
			{ "field": "gross_total", "name": "gross_total", "header": this.tr("Total"), "width": 150, "fieldType": "currency", type: "number" },
			{ "field": "paid_total", "name": "paid_total", "header": this.tr("Paid."), "width": 150, "fieldType": "paid", type: "number" },
			{ "field": "paid_date", "name": "paid_date", "header": this.tr("Paid date"), "width": 180, "fieldType": "date", type: "date" },
			{ "field": "edited", "name": "edited", "header": this.tr("Edited by"), "width": 200, "fieldType": "linktext" },
			{ "field": "reporting_group", "name": "reporting_group", "header": this.tr("Reporting group"), "width": 200,  "fieldType": "text", "visualizationType": "tree", "entityMode": true },
			{ "field": "referencenumber", "name": "referencenumber", "header": this.tr("Reference nr."), "width": 200, "fieldType": "text" },
			{ "field": "customerreference", "name": "customerreference", "header": this.tr("Your reference"), "width": 200, "fieldType": "text" },
		];
		
		this.additionalFields = [
			{ "field": "project_type", "name": "project_type", "header": this.tr("Project type"), "width": 200, "fieldType": "text", "entityMode": true },
			{ "field": "project_category", "name": "project_category", "header": this.tr("Project category"), "width": 200, "fieldType": "text", "visualizationType": "tree", "entityMode": true },
		];

		if (context.addons?.invoice_currency) {			
			this.fields.push({ "field": "currency_code", "name": "currency_code", "header": this.tr("Currency"), "width": 60, "fieldType": "rate_text" });
			this.fields.push({ "field": "currency_rate", "name": "currency_rate", "header": this.tr("Currency rate"), "width": 80, "fieldType": "rate_text" });
			this.fields.push({ "field": "total_in_currency", "name": "total_in_currency", "header": this.tr("Total in currency"), "width": 120, "fieldType": "rate_currency", type: "number" });
		}		

		if (context.addons && context.addons.procountor) {
			this.fields.push({field: "in_procountor", name: "in_procountor", header: this.tr("In Procountor"), "width": 200, "fieldType": "text"})
		}
		if (context.addons && context.addons.ropocapital) {
			this.fields.push({field: "in_ropocapital", name: "in_ropocapital", header: this.tr("In RopoCapital"), "width": 200, "fieldType": "text"})
		}
		if (context.addons && context.addons.fennoa) {
			this.fields.push({field: "in_fennoa", name: "in_fennoa", header: this.tr("In Fennoa"), "width": 200, "fieldType": "text"})
		}
		if (context.addons && context.addons.wintime) {
			this.fields.push({field: "in_wintime", name: "in_wintime", header: this.tr("Exported to Wintime"), "width": 200, "fieldType": "text"})
		}
		if (context.addons && context.addons.sap) {
			this.fields.push({field: "voucher_date", name: "voucher_date", header: this.tr("Accounting date"), width: 180, fieldType: "date", type: "date" })
		}

		this.state.tabColor = this.statuses[this.state.tabValue].color;
		this.getParams.real_state = this.state.tabValue;


		this.emptyResultData = {
			invoices_new: [], 
			count: 0, 
			page_count: 30, 
			net_total: 0, 
			gross_total: 0, 
			paid_total: 0, 
			credit_total: 0, 
			draft_total: 0,
			has_invoices: false
		}

		this.originalDialogProps = () => ({
            sendConfirmation: {
                dialogType: "delete",
                open: this.state.currentDialog === "sendConfirmation",
                onDialogClose: this.closeDialog,
                dialogProps: {
                    header: this.state.dialogData?.header,
                    confirmButtonClass: "blue",
                    confirmButtonText: this.tr("Send"),
                    warning: () => {
                        return this.state.dialogData ? this.renderConfirmationContent(this.state.dialogData) : "";
                    },
                    close: this.closeDialog,
                    onCloseClick: this.closeDialog,
                    onConfirm: () => {
						this.closeDialog();
						this.state.dialogData?.confirmFunc && this.state.dialogData?.confirmFunc();
                    }
                }
            }
        });
        this.dialogProps = this.originalDialogProps();


		const functionBinds = ['setData', 'switchTabs', 'markInvoiceSent', 'markInvoiceSentMulti', 'sortRows', 'callAttachmentDialog', 'print', 'apiHelper'];
		[...functionBinds].forEach(f => this[f] = this[f].bind(this));
		
		this.filtersAreInInitialState = this.filtersAreInInitialState.bind(this);
        this.initializeStickySearch   = this.initializeStickySearch.bind(this);
        this.saveStickySearch         = this.saveStickySearch.bind(this);
	}

	componentDidMount() {
            super.componentDidMount();
			this.listenReset();
            this.initializeStickySearch();
            if (this.state.addInvoice) {
                this.props.updateView({
                    addInvoice: false});

                this.addInvoice();
            } 
	}

	componentWillUnmount() {

		super.componentWillUnmount();
		
		this.unListenReset();
	}

	shouldComponentUpdate(nextProps, nextState) {

		if (this.state.perpage !== nextState.perpage || this.state.page !== nextState.page)
			return false;

		return true;
	}

	componentDidUpdate(prevProps)  {

		if (this.props.projects_id !== prevProps.projects_id)
			this.fetchData();

	}	

	updateComponentData = (override = {}, useSavedParams = false, callback = undefined, initial = false) => {

		const { company } = this.state;

		DataHandler.get({ url: `invoices/autoCompleteData/${company}` }).done(
			response => {
                const userTagProps = {
                    fields: {name: 'name'},
                    showLocked: this.props.showLockedUsersWithTag,
                    transl: this.translations
                };				
	            Object.keys(response).forEach(k => {
	                if (this.userTypeAutocompleteClasses.includes(k)) {
	                    response[k] = response[k].map(d => ({...Utils.showLockedAndFreelancerUserTag(d, userTagProps)}))
	                }
	            })				

				this.autoCompleteData = response;

				this.fetchData(override, useSavedParams, callback, initial)
			}
		);

		DataHandler.get({ url: `subjects/companies/invoices/write_full+write_simple`, currency: 1, invoice_only_material: 1, allow_mass_invoicing: 1, print_lang: 1 }).done(companies => {
			let c = false;
			if (companies.length > 0) {
				companies.forEach(company => {
					if (company.id == this.state.company) {
						this.setState(state => {
						state.companyCurrency = company.currency;
						state.invoice_only_material = company.invoice_only_material == "1";
							return state;
						})
						c = true;
					}
				})
				if (!c) {
					this.setState(state => {
					state.companyCurrency = companies[0].currency;
					state.invoice_only_material = companies[0].invoice_only_material == "1";
						return state;
					})
				}
				this.setState(state => {
				state.companies = companies;
					return state;
				})
			}
			else
				this.setState(state => {
				state.companyCurrency = this.context.taimerAccount.currency;
					return state;
				})
		});
	}

	isProjectOrAccountModule = () => {
		return this.props.viewProps && (this.props.viewProps.module === "customers" || this.props.viewProps.module === "projects");
	}

	hasInitialFilterProps = () => {
		const initialProps = ["type", "start", "end", "customerId", "users_id", "projects_id", "date", "bill_ids"];
		let i;
		for (i = 0; i < initialProps.length; i++) {
			if (this.props[initialProps[i]]) 
				return true;
		}
		
		return false;
	}
	
	async initializeStickySearch() {
		const integrationErrorCompanies = await this.fetchIntegrationErrors();

		if (this.isProjectOrAccountModule() || this.hasInitialFilterProps()) {
			this.setState({ stickySearchInitialized: true }, () => this.updateComponentData({}, false, undefined, true));
			return;
		}
		
        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` }).done((response, __, request) => {
            if(request.status !== 200) {
				this.updateComponentData({}, false, undefined, true);
                return;            
			}
			
			if (response.searchTerms)
				this.searchTerms = response.searchTerms;

			const fullPrivileges = this.context.privileges.invoices.write_full;
			const simplePrivileges = this.context.privileges.invoices.write_simple;
			const hasFullPrivileges = fullPrivileges && fullPrivileges.find(el => el == response.company);
			const hasSimplePrivileges = simplePrivileges && simplePrivileges.find(el => el == response.company);

			if (!hasFullPrivileges && !hasSimplePrivileges) {
				response.company = this.filtersInitialValues.company;
			}

			if (this.props.company)	
				response.company = this.props.company;

			if (this.initialMonth.start !== null || this.initialMonth.end !== null) 
				response.dateRange = this.filtersInitialValues.dateRange;

			// If remembering waiting for actions filter and there is no error invoices anymore for selected company,
			if (response.tabValue == -1) {
				const integrationErrors = integrationErrorCompanies?.find(e => e.company_id == response.company);
				if (!Number(integrationErrors?.amount)) {
					response.tabValue = this.filtersInitialValues.tabValue;
				}
			}
				
			response.tabValue = this.props.type ? _.findKey(this.statuses, x => x.trigger_id == this.props.type) : response.tabValue;
			this.getParams.real_state = response.tabValue;
			this.getParams.sort = response.sort;

            this.setState({ ...response }, () => this.updateComponentData({}, false, undefined, true));
        }).fail(response => {
			this.updateComponentData({}, false, undefined, true);

        }).always((response, _, request) => {
            this.setState({ stickySearchInitialized: true });
        });
    }


    saveStickySearch(filters) {
		const stateToSave = _.cloneDeep(filters);

		stateToSave.searchTerms = this.searchTerms;
		stateToSave.tabValue = this.state.tabValue;
		stateToSave.sort = this.state.sort;

        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}`, }, { search: stateToSave });
    }

    filtersAreInInitialState() {
        const initial = _.cloneDeep(this.filtersInitialValues);
        const filters = {}

        for(const key in initial) {
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = JSON.stringify(this.state[key]);
        }

        const freetext = this.searchTerms ? this.searchTerms.freetextSearchTerm : "";

        return _.isEqual(initial, filters) && !freetext;
    }

	_resetFilters = (evt, update = {}) => {
		if (!evt || evt.keyCode == '27') {
			this.advancedSearch.current.clearSearch(evt, true);
			this.advancedSearch.current.clearSearchTextInput();

			this.getParams = {};
			this.getParams.real_state = update.tabValue ? update.tabValue : this.filtersInitialValues.tabValue;
			this.searchTerms = undefined;

			update = {...this.filtersInitialValues, ...update}

			this.setState({
				...update,
				sort: undefined,
				page: 1
			}, () => {
				setTimeout(() => {
					this.resetListAndFetchData({...update}, true)
				}, 100);
			});
		}
	}

	fetchData = (override = {}, useSavedParams = false, callback = undefined, initial = false) => {

		if (this.props.returnEmptyResult) {
			this.setData(this.emptyResultData);
			return false;
		}
		if(this.isFetching) {
			return false;
		}
		this.isFetching = true;

		if (override.allInvoicesData != 1) {
			this.list.current && this.list.current.setState({ isLoading: true});
		}

		let parameters = { page: this.state.page, perpage: this.state.perpage };

		const { projects_id, projects_ids, users_id, bill_ids } = this.props;

		const  customers_id  = this.props.customerId;
		const  type_data  = this.props.type;
		const { dateRange, company, companyCurrency } = this.state;
		const advParams = {};

		if (useSavedParams) {
			parameters = this.savedParams;
		} else {
			for (const gp in this.getParams)
				parameters[gp] = this.getParams[gp];
			if (this.searchTerms !== undefined) {
				parameters.mode = this.searchTerms.mode;
				if (this.searchTerms.mode == 'advanced') {
					advParams.advanced_search_criteria = JSON.stringify(this.searchTerms.advanced_search_criteria);
				} else {
					advParams.freetext = this.searchTerms.freetextSearchTerm;
				}
			}
		}

		for (const oi in override)
			parameters[oi] = override[oi];

		this.savedParams = parameters;

		const start = format(dateRange.startDate, 'YYYY-MM-DD');
		const end = format(dateRange.endDate, 'YYYY-MM-DD');

		const paramsToSave = {
			...parameters,
			company,
			dateRange
		}

		if (!this.isProjectOrAccountModule())		
			this.saveStickySearch(paramsToSave);
			
		const params = { ...parameters, start, end, projects_id, projects_ids, users_id, company, customers_id, type_data, ids: bill_ids, currency: companyCurrency, assignedData: ["customers-view", "projects-view"].includes(this.props.selectedPage)};
   
		return DataHandler.post({ url: `invoices/list`}, {...params , ...advParams}).done(			
			response => {
				this.isFetching = false;
				this.list.current && this.list.current.setState({ isLoading: false});
				if (override.onlyIds || override.allInvoicesData == 1)
					return response;

				response.invoices_new = _.cloneDeep(response.invoices_new).map(r => {
					if (r.currency_code)
						r.currency_symbol = currencySymbolsMap[r.currency_code];
					return r;
				});

				this.setData(response);
			}
		);

	}

	sortRows(columnName, isAscending) {
		const sortObj = { name: columnName, asc: isAscending };
		this.setState({ sort: sortObj }, () => {
			this.getParams.sort = sortObj;
			this.list.current.setPage(1);
			this.fetchData();
		});
	}

	getSelectedRowIds = async () => {

        const allCheckedExcept = this.list.current.getAllCheckedExcept();

        if (allCheckedExcept) {
            let selected = await this.fetchData({ onlyIds: true });
            selected = selected.filter(id => {
                return !allCheckedExcept[id]; 
            });
            return selected;        	
        } else {
            return this.list.current.getCheckedRows();
        }	
	}

	setData(data) {
		if (data === undefined || data.length === 0) {
			this.list.current.setData([]);
			return;
		}

		const { invoices_new, page_count, count, net_total, gross_total, paid_total, credit_total, draft_total} = data;
        const userTagProps = {
            fields: this.userTypeDataHeaders,
            showLocked: this.props.showLockedUsersWithTag,
            transl: this.translations,
            userData: this.autoCompleteData.users
        };
        invoices_new.forEach((inv, i) => {
            if (Object.keys(inv).some(k => Object.values(this.userTypeDataHeaders).includes(k))) {          
                invoices_new[i] = ({...Utils.showLockedAndFreelancerUserTag(inv, userTagProps)});
            }
        })       

		this.setState(state => {
			state.data = invoices_new === undefined || invoices_new.length === 0 ? [] : invoices_new;
			state.pageCount = page_count !== undefined ? page_count : 1;
			state.invoiceCount = count !== undefined ? count : 0;
			state.netTotal = net_total !== undefined ? net_total : 0;
			state.grossTotal = gross_total !== undefined ? gross_total : 0;
			state.paidTotal = paid_total !== undefined ? paid_total : 0;
			state.creditTotal = credit_total !== undefined ? credit_total : 0;
			state.draftTotal = draft_total !== undefined ? draft_total : 0;
			state.hasInvoices = data.has_invoices > 0;
			return state;
        }, () => {
            this.list.current && this.list.current.endPageChangeAnimation();

            this.initialFetchDone = true;
        });
	}

	export = async (target) => {
		if (!this.state.data || this.state.data.length < 1) {
			this.props.enqueueSnackbar(this.tr("Nothing to export!"), {
                variant: "warning",
            });
            return;
		}

		const { dateRange, companyCurrency } = this.state;

		const start = format(dateRange.startDate, 'YYYY-MM-DD'),
			end = format(dateRange.endDate, 'YYYY-MM-DD');
		
		const columnOrder = this.list.current.visibleColumnsOrder;
		let ids = await this.getSelectedRowIds();

		if (ids.length === 0) {
            ids = await this.fetchData({ onlyIds: true });
		}
		
		const fields = this.fields;
		const exportHeaders = [];        
		_.forEach(columnOrder, column => {
			_.forEach(fields, (field, i) => {
				if(column == field.name && column != "rowmenu" && column != "checked"){
					exportHeaders.push(field.header);
				}
			})
		})
		const statusHeaders = [
			this.tr("draft"),
			this.tr("waiting"),
			this.tr("sent"),
			this.tr("paid"),
			this.tr("credited"),
			this.tr("overdue"),
		]
		const projects_id = this.props.projects_id;
		const projects_ids = this.props.projects_ids;
		const params = { ...this.savedParams, onlyIds: undefined, projects_id, start, end };
        params.file_name = this.tr("invoices_list_export");

		DataHandler.postArrayBuffer({ url: `invoices/list_export`, ...params, order: columnOrder, columnNames: exportHeaders, currency: companyCurrency, export: target, status_headers: statusHeaders }, {projects_ids, ids}, true)
			.done((response) => {

				const blob = new Blob([response], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});
				FileSaver.saveAs(blob, `${params.file_name}.${target}`);
                this.list.current && this.list.current.setState({ isLoading: false});
			});

	}

	send = (invoice) => {
		this.context.functions.setOverlayComponent(<SendInvoiceWizard invoice={invoice} onInvoiceSent={this.resetListAndFetchData} />);
	}

	print = async (lang, invoice) => {

		if (invoice) {
			this.context.functions.setOverlayComponent(<SendInvoiceWizard printMode invoice={invoice} />);
			return;
		}

		let ids;
		if (!invoice) {
			ids = await this.getSelectedRowIds();
			if (ids.length == 0) {
				return;
			}			
		} else {
			ids = [invoice.id];
		}

        let langs = [lang];
		if (!langs || langs[0] == undefined || langs.length < 1) {
			langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: ids});
		}

		const transl = new InvoiceTranslations().returnTranslations(langs);

		DataHandler.postArrayBuffer({ url: 'invoices/print_pdf', lang: lang, ids: ids }, {translations: transl})
			.done((response) => {
				const blob = new Blob([response], {
					type: 'application/pdf'
				});
				FileSaver.saveAs(blob, 'invoices.pdf');
			});
	}

	switchTabs(e, value) {
		this.setState({ /*tabColor: this.statuses[value].color, */ tabValue: value, page: 1 }, () => {
			this.getParams.real_state = value;
			this.resetListAndFetchData();
		});
	}

	//rowfunction: mark row as sent
	markInvoiceSent(ids) {

		DataHandler.post({ url: `invoices/mark_sent` }, {ids}).done(() => {

			const analyticsData = {
				"invoices_sent": ids.length,
				"taimer_version": this.context.versionId,
				"event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
				"invoice_destination": "Taimer",
				"manual_send": true,
				"success": true,
			}
	        //this.context.functions.sendAnalytics("invoice_sent", analyticsData);

			const response = this.resetListAndFetchData();
			return response;
		});
	}

	markInvoiceSentMulti = async () => {

		const ids = await this.getSelectedRowIds();
		if (ids.length == 0) {
			return;
		}

		DataHandler.post({ url: `invoices/check_state`, state: '1', integrationState: ['0','1'] }, {ids}).done(
			response => {
				if (response == 'ok') {
					this.list.current.uncheckAll()
					this.markInvoiceSent(ids);
					
					const analyticsData = {
						"invoices_sent": ids.length,
						"taimer_version": this.context.versionId,
						"event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
						"invoice_destination": "Taimer",
						"manual_send": true,
						"success": true,
					}
					//this.context.functions.sendAnalytics("invoice_sent", analyticsData);
				}
				else {
					this.props.enqueueSnackbar(this.tr("You can only mark waiting invoices as sent."), {
						variant: "error",
					});				
				}
			}
		);
	}

	//rowfunction: mark row(s) as paid
	callSingleMarkPaidDialog = (id) => {
		const data = this.state.data.filter(row => row.id == id)[0],
			paid = data.currency_paid_total != '' ? data.currency_paid_total - 0 : 0;

		const dialogData = {
			id: id,
			total: (data.gross_total - 0),
			currency_total: (data.currency_gross_total - 0),
			paid: paid,
			left: data.paid_total != '' ? ((data.currency_gross_total - 0) - paid) : (data.currency_gross_total - 0),
			saveFunc: 'savePaid',
			projects: data.projects,
			customers: data.customers,
			bill_id: data.bill_id,
			currencyCode: data.currency_code,
			currencyRate: data.currency_rate,
			currencySymbol: currencySymbolsMap[data.currency_code]
		}
		this.openDialog('singlePayment', dialogData);
	}

	callMultipleMarkPaidDialog = async () => {

		const ids = await this.getSelectedRowIds();
		if (ids.length == 0) {
			return;
		}

		const { enqueueSnackbar } = this.props;
		
		DataHandler.post({ url: `invoices/check_state`, state: '2', integrationState: ['0','1'] }, {ids}).done(
			response => {
				if (response == 'ok') {
					const dialogData = { ids: ids, saveFunc: 'savePaid' };
					this.openDialog('multiplePayment', dialogData);					
				} else {
					enqueueSnackbar(this.tr("You can only mark sent invoices as paid"), {
						variant: "error",
					});					
				}
			}
		);
	}

	savePaid = (data) => {

		let params;
		if (data.multiple) {
			params = {
				ids: data.ids,
				date: data.date
			}
		} else {
			const rowData = this.state.data.filter(row => row.id == data.id)[0];
			const index = this.state.data.findIndex(row => row.id == data.id);

			params = {
				id: data.id,
				payment: data.payment,
				date: data.date,
				type: rowData.type,
				bill_id: rowData.bill_id
			}
		}console.log(params);
		this.list.current.uncheckAll()

		DataHandler.post({ url: `invoices/${data.action}` }, params).done(
			response => this.resetListAndFetchData());

		this.closeDialog();
	}

	//rowfunction: handle single row attachments
	callAttachmentDialog(id) {
		const dialogData = {
			id: id,
			targetModule: 'invoices'
		}
		this.openDialog('attachments', dialogData);
	}

	//rowfunction: call confirmation dialog

	callConfirmationDialog = async (f, id) => {
		const extra = {};
		let text = this.dialogTexts[f];
		if (f == "delete") {
			extra.relatedInvoices = await this.getRelatedInvoices(id);
			if (extra.relatedInvoices?.length > 0) {
				text += " " + this.tr('This will also delete related invoices: ${invoiceNumbers}.', {invoiceNumbers: extra.relatedInvoices.map(r => r.bill_id).join(", ")});
			}
		}

		const dialogData = {
			id: id ? id : 'multiple',
			saveFunc: f,
			text,
			...extra
		}
		this.openDialog('confirmation', dialogData);
	}

	getRelatedInvoices = async (id) => {
		const params = { id, get_recursive: true};
		const invoices = DataHandler.get({ url: 'invoices/related_invoices', ...params })
		return invoices;
	}

	isErrorInvoicesSelected = () => {
		const data = this.state.checkedData?.filter(d => d.integration_state == 2);
		return (data || []).length > 0;
	}

	callErrorHandleDialog = async (id) => {
		let data = id === "selectedRows" ? 
			this.state.checkedData : 
			this.state.data.filter(d => d.id == id);

		data = data.filter(d => d.integration_state == 2);
		const ids = data ? data.map(r => r.id) : [];

		const dialogData = {
			handleError: (action) => this.handleIntegrationError(ids, action),
			invoices: data
		}
		this.openDialog('handleError', dialogData);
	}

	handleIntegrationError = (ids, action) => {
		switch(action) {
			case "edit_invoice":
				this.setIntegrationState(ids, 0, 1, action);
				break;
			case "send_again":
				this.setIntegrationState(ids, 0, 0, action);
				break;
			case "remove_error":
				this.setIntegrationState(ids, 1, 0, action);
				break;
		  }
		
		this.closeDialog();
	}

	setIntegrationState = (ids, integrationState, revertInvoices = 0, action = "") => {
		const { company } = this.state;
		const params = { ids: ids, integrationState, revertInvoices };

		this.setSendingStatus(true, this.tr("Updating invoices"));

		DataHandler.post({url: `invoices/set_integration_state`}, params).done(async response => {
			const updatedIds = response.updated_ids;
			const notUpdatedIds = response.not_updated_ids;
			this.context.functions.fetchNavigationNotificationData("invoices");

			if (notUpdatedIds.length > 0) {
				this.props.enqueueSnackbar(this.tr("Error in updating ${amount} invoice(s)", {amount: notUpdatedIds.length}), {
					variant: "error",
				});
			}

			if (updatedIds.length < 1) {
				this.setSendingStatus(false);
				return;
			}

			if (action ==  "edit_invoice" && ids.length == 1 && updatedIds.length == 1) {
				this.context.functions.updateView({ module: 'invoices', action: 'view', id: ids[0] });
				this.setSendingStatus(false);
				return;
			}
			
			const integrationErrorCompanies = await this.fetchIntegrationErrors();
			let resetStatusFilter = false;

			// If waiting for actions filter is active and company has no error invoices anymore, set default status filter,
			if (this.state.tabValue == -1) {
				const integrationErrors = integrationErrorCompanies?.find(e => e.company_id == company);
				resetStatusFilter = !Number(integrationErrors?.amount);
			}

			this.setSendingStatus(false);
			let showSuccessSnackbar = false;

			if (action == "send_again") {
				setTimeout(() => {
					const tabValue = resetStatusFilter ? this.filtersInitialValues.tabValue : this.state.tabValue;
					this.setState({ tabValue, page: 1 }, () => {
						this.getParams.real_state = tabValue;
						this.sendInvoicesToIntegration(updatedIds, "heeros", true);
					});
				}, 1000);
			}
			else if (resetStatusFilter) {
				showSuccessSnackbar = true;
				setTimeout(() => {
					this.switchTabs(null, this.filtersInitialValues.tabValue);
                }, 100);
			}
			else {
				showSuccessSnackbar = true;
				this.setState({ page: 1 }, () => {
					this.resetListAndFetchData();
				});
			}

			if (showSuccessSnackbar) {
				this.props.enqueueSnackbar(this.tr("${amount} invoice(s) updated successfully", {amount: updatedIds.length}), {
					variant: "success",
				});
			}

	    }).fail(err => {
			this.setSendingStatus(false);
			this.props.enqueueSnackbar(this.tr("Error in updating invoice(s)"), {
				variant: "error",
			});
		});
	}

	fetchIntegrationErrors = async () => {
		let integrationErrorCompanies = [];
		try {
			integrationErrorCompanies = await DataHandler.get({ url: `invoices/integration_errors_by_company` });
		} catch (e) {}
            
		this.setState({ integrationErrorCompanies });
		return integrationErrorCompanies;
	}

	sendInvoicesToIntegration = (ids, func, noSentCheck = false) => {
		const { enqueueSnackbar, closeSnackbar } = this.props;
		const { company } = this.state;

		const sendOption = this.sendOptions.accounting.find(s => s.func == func);
		if (!sendOption || this.state.sendingInProgress == true) {
			return;
		} else {
			const confirmFunc = () => InvoiceApi[sendOption.func]({ ids: ids }, enqueueSnackbar, this.fetchData, closeSnackbar, this.stateUpdater, company, this.tr, this.setSendingStatus, sendOption.analyticsKey, this.htmlTr);
			if (sendOption.checkSent == 1 && !noSentCheck)
				this.checkSentInvoices(sendOption, confirmFunc);
			else
				confirmFunc();
		}
	}

	// rowfunction: create notices and refunds
	createNotice = async (id) => {
		let ids = [];
		if (id == 'multiple') {
			ids = await this.getSelectedRowIds();
			if (ids.length == 0) {
				return;
			}
			
		} else {
			ids.push(id);
		}

		const { enqueueSnackbar } = this.props;

		const langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: ids});

		const transl = new InvoiceTranslations().returnTranslations(langs);

		DataHandler.post({ url: `invoices/check_state`, state: '2', integrationState: ['0','1'] }, {ids}).done(
			response => {
				if (response == 'ok') {
					DataHandler.post({url: `invoices/notice`}, {ids: ids, translations: transl}).done(
						resp => {
							this.closeDialog();
							this.list.current.uncheckAll()
							this.resetListAndFetchData();
							enqueueSnackbar(this.tr("Payment reminder invoice has been created."), {
								variant: "success",
							});				
						}
					);				
				} else {
					enqueueSnackbar(this.tr("You can only create payment reminder for sent invoices."), {
						variant: "error",
					});				
				}
			}
		);
	}

	createCredit = async (id) => {

		let ids = [];
		if (id == 'multiple') {
			ids = await this.getSelectedRowIds();
			if (ids.length == 0)
				return;
        }
		else
			ids.push(id);

		const { enqueueSnackbar } = this.props;

		const langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: ids});

		const transl = new InvoiceTranslations().returnTranslations(langs);		

		DataHandler.post({ url: `invoices/check_state`, state: ['2','4'], integrationState: ['0','1'] }, {ids}).done(
			response => {
				if (response == 'ok') {
					DataHandler.post({url: `invoices/refund`}, {ids: ids, translations: transl}).done(
						resp => {
							this.closeDialog();
							this.list.current.uncheckAll()
							this.resetListAndFetchData();
						}
					);				
				} else {
					enqueueSnackbar(this.tr("You can only create credit note for sent or paid invoices."), {
						variant: "error",
					});				
				}
			}
		);
	}

	formalize = (id) => {

		const params = { id: id };

		DataHandler.post({ url: `invoices/formalize` }, params).done(

			response => {
				this.closeDialog();
				this.fetchData(false, true);
			}
		);

	}

	delete = (id) => {

		const params = { id: id };
		const { dialogData } = this.state; 

		DataHandler.post({ url: `invoices/delete` }, params).done(

			response => {
				const deletedAmount = response?.deleted_amount;
				if (!response['errors']) {
					const msg = deletedAmount > 1
						? this.tr('${amount} invoices has been deleted.', {amount: deletedAmount})
						: `${response.bill_id} ${this.tr('has been deleted')}`; 

					this.props.enqueueSnackbar(msg, {
						variant: 'success',
					});
				}

				this.closeDialog();
				this.setState({page: 1}, () => this.resetListAndFetchData({page: 1}) )
			}
		);

	}

	//dialogs general
	openDialog = (name, dialogData = {}) => {
		this.setState({ 
			currentDialog: name, 
			dialogData,
			currentDialogProps: { 
                ...(this.dialogProps.hasOwnProperty(name) ? this.dialogProps[name].dialogProps : {}), 
                ...(typeof(dialogData) === "object" ? dialogData : {}) 
            }
		});
	}

	closeDialog = () => {
		this.setState({ currentDialog: false });
	}

	saveDialog = (saveFunc, data) => {
		this[saveFunc](data);
	}

	onDateChange = (event) => {

		const { startDate, endDate } = event.selection;

		this.setState({
			page: 1,
			dateRange: {
				startDate: format(startDate, "YYYY-MM-DD"),
				endDate: format(endDate, "YYYY-MM-DD"),
				key: "selection"
			}
		}, () => this.resetListAndFetchData());

    }

	onDateInputChange = (dateType, date) => {

		const { endDate, startDate } = this.state.dateRange;
		date = format(date, "YYYY-MM-DD");		

		if (dateType == "start") {
			this.setState({
				page: 1,
				dateRange: {
					startDate: date,
					endDate: endDate,
					key: "selection"
				}
			}, () => this.resetListAndFetchData());
		} else {
			this.setState({
				page: 1,
				dateRange: {
					startDate: startDate,
					endDate: date,
					key: "selection"
				}
			}, () => this.resetListAndFetchData());
		}
	}    

	listenReset = () => {
		document.body.addEventListener("keyup", this._resetFilters);
	}

	unListenReset = () => {
		document.body.removeEventListener("keyup", this._resetFilters);
    }
    
    callEmptyInvoiceDialog = (data) => {
        this.openDialog('emptyInvoice', data);
    }

	addInvoice = async (evt) => {
        const { project, account } = this.props;
        const { company } = this.state;
		this.context.functions.addInvoice({ account, project, company, origin_point: "invoice_list" }, evt?.ctrlKey || evt?.metaKey || evt?.button === 1);
	}

	showAll = () => {
		this.props.projectIdHandler();
	}

	apiHelper(key) {
		const rows = this.list.current.getCheckedData();
		const { enqueueSnackbar } = this.props;
		const updateData = this.fetchData;
		InvoiceSendFunctions[key](rows,  enqueueSnackbar, updateData );
	}

	stateUpdater = (obj) => {
		const { enqueueSnackbar } =  this.props;

		if(obj && obj.loading == true) {
			enqueueSnackbar(this.tr("Sending invoice"), {
				variant: "info",
			});
		}

		this.setState(obj);
	}

	setSendingStatus = (status, message = false) => {
		message = message || this.tr("Sending invoices. This may take a while.");
		if (status == true) {
			const key = this.props.enqueueSnackbar(message, {
				variant: "info",
				persist: true
			});
			this.setState({sendingInProgress: status, snackBarKey: key});
		} else {
			this.props.closeSnackbar(this.state.snackBarKey);
			this.setState({sendingInProgress: status, snackBarKey: undefined});
		}
		
	}

	handleTabChange = (selectedPage) => {
		const { functions: { updateView } } = this.context;
		updateView({ selectedPage });
	}

	setWaitingForActionsFilter = (company) => {
		this._resetFilters(undefined, { tabValue: -1, company });
	}

	renderSummarySection = () => {
		const { companyCurrency, companies } = this.state;
		const { numberFormat } = this.context.taimerAccount;
		const currencyFormatter = new Intl.NumberFormat(numberFormat, { style: "currency", minimumFractionDigits: 0, maximumFractionDigits: 0, currency: companyCurrency }).format;
		return (
			<PageTopSection mainButtons={[(this.props.module !== "projects" || this.props.canCreateInvoice) && {
				title: this.tr("ADD INVOICE"),
				action: this.addInvoice,
				'data-testid': "add-invoice-button",
				isVisible: !this.props.projects_id && !this.props.projects_ids
			}]} summaries={[
				{
					title: this.tr("Sum excl. VAT"),
					value: currencyFormatter(this.state.netTotal)
				},
				{
					title: this.tr("Sum incl. VAT"),
					value: currencyFormatter(this.state.grossTotal)
				},
				{
					title: this.tr("Paid."),
					value: currencyFormatter(this.state.paidTotal)
				},
				{
					title: this.tr("Credited."),
					value: currencyFormatter(this.state.creditTotal)
				},
				{
					title: this.tr("Drafted."),
					value: currencyFormatter(this.state.draftTotal)
				},
			]} settingsButton={{
				isVisible: !(null == this.context.privileges.admin),
				title: this.tr("Settings"),
				href: this.context.functions.urlify({ module: 'settings', action: 'index', group: 'features', page: 'invoicing' }),
				action: () => this.context.functions.updateView({ module: 'settings', action: 'index', group: 'features', page: 'invoicing' }, false)
			}} additionalButtons={[{
				title: this.tr("EXPORT"),
				action: () => this.export('xlsx'),
				icon: <CloudDownload />
			}]} />
		);
	}


    resetListAndFetchData = (update, updateAutocomplete = false) => {
        if (this.list.current)
			this.list.current.resetCheckedRows();
			
		if (updateAutocomplete) 
			this.updateComponentData({ ...update });
		else
        	this.fetchData({ ...update });
	}

	checkSentInvoices = async (e, confirmFunc = () => {}) => {
		const alreadySentInvoices = await DataHandler.post({ url: `invoices/check_sent` }, {integration: e.func, ids: this.list.current.getCheckedData().map(el => el.id)});

		if (alreadySentInvoices.length > 0) {
			const header = this.tr(e.name) + "?";
			this.openDialog("sendConfirmation", {name: e.analyticsKey, already_sent: alreadySentInvoices, header: header, confirmFunc: confirmFunc});
		}
		else  {
			confirmFunc();
		}
	}

	renderConfirmationContent = (data) => {
        return (
				data.already_sent && (
					<div id="invoices-already-sent-dialog-list">
						<p>{this.tr("Following bills are already sent to ${integration}, are you sure you want to send them again?", {integration: data.name})}</p>
						<ul>
							{data.already_sent.map((invoiceNumber) => { 
								return (
									<li>{invoiceNumber}</li>
								)
							})}
						</ul>
					</div>
		));
    }

	isCompanyUsingIntegration = (integration) => {
        const { addons } = this.context;
		const { company } = this.state;

        return (addons[integration] && addons[integration].used_by_companies.indexOf(company) > -1);
	}

	renderWaitingForActionsNotification = () => {
		const { navigationNotifications } = this.props;
		const { integrationErrorCompanies, companies, company } = this.state;

		if (integrationErrorCompanies.length < 1 || !Number(navigationNotifications?.invoices)) {
			return null;
		}

        return (
            <div data-testid="invoice_notification_container" className="notification-container">
                <span className="notification serious">
                    <WarningRounded />
					<ul>
						<li>
							<span>{this.tr("${amount} invoices are waiting for actions", { amount: navigationNotifications?.invoices })}</span>
							{companies.length == 1 && <span className="button" onClick={() => this.setWaitingForActionsFilter(company)}>{this.tr("Show invoices")}</span>}
						</li>
						<li>
							{companies.length > 1 && integrationErrorCompanies.map(e => {
								return (
									<span className="button company-button" onClick={() => this.setWaitingForActionsFilter(e.company_id)}>{e.company_name + " (" + e.amount + ")"}</span>
								)
							})}
						</li>
					</ul>
				</span>
            </div>
        );
    }

	onListCheckAll = () => {
		const { integrationErrorCompanies, company } = this.state;

		if (integrationErrorCompanies?.find(c => c.company_id == company)) { // Fetch data for all selected invoices. Used for showing Handle error -option and errors in handle error -dialog.
			setTimeout(async () => {
				const data = await this.fetchData({ allInvoicesData: 1 });
				this.setState({ allSelectedInvoicesData: data.invoices, checkedData: data.invoices });
			}, 10);
		}
	}

	onListUnCheckAll= async () => {
		setTimeout(() => {
			this.setState({ allSelectedInvoicesData: [], checkedData: [] });
		}, 10);
	}

	onListCheck = async () => {
		const { allSelectedInvoicesData } = this.state;
		const allCheckedExcept = this.list.current ? this.list.current.getAllCheckedExcept() : false;
		let checkedData = [];
		if (allCheckedExcept) {
			checkedData = (allSelectedInvoicesData || []).filter(invoice => {
				return !allCheckedExcept[invoice.id];
			});
		} else {
			checkedData = this.list.current ? this.list.current.getCheckedData() : [];
		}

		setTimeout(() => {
			this.setState({ checkedData });
		}, 10);
	}

	showInvoiceLogSlider = (invoiceLogSliderData) => this.setState({ invoiceLogSliderData });
	closeInvoiceLogSlider = () => this.setState({ invoiceLogSliderData: undefined });

	render() {
		
		const fields = this.fields.filter(e => !e.disabled).map(e => {
			return e;
		});
		const fieldColumns = this.fields.filter(e => 
			!e.disabled && 
			e.name != 'reporting_group' && 
			(e.name != "notification" || (e.name == "notification" && this.isCompanyUsingIntegration("heeros")))
		);

		let searchFields = [...fields];
		searchFields.splice(9, 0, ...this.additionalFields);
		const notIncludedInSearch = ["status", "checked", "rowmenu", "attachment", "creationdate", "duedate", "deliverydate", "billingstartdate", "billingenddate", "paid_date", "notification", "voucher_date"];
		if (this.props.projects_id) {
			notIncludedInSearch.push(...['project_type', 'project_category', 'projects', 'customer']);
		}
		searchFields = searchFields.filter(f => !notIncludedInSearch.includes(f.field)).map(f => { return { field: f.field, transl: f.header, type: f.type, visualizationType: f.visualizationType || "list", entityMode: f.entityMode || false } });
        searchFields.push({ field: 'row_description', transl: this.tr('row description'), type: "text", visualizationType: "list", entityMode: false, excludedOperators: ["doesnotcontain", "neq", "eq"] });

		const { updateView, classes,  enqueueSnackbar, closeSnackbar, projects_id, projects_ids, navigationNotifications } = this.props;
		const { dateRange, tabColor, tabValue, currentDialog, dialogData, methods, data, pageCount, invoiceCount, perpage, showTabs, companies, company, companyCurrency, invoiceLogSliderData, integrationErrorCompanies } = this.state;
		const checkedRows = this.list.current ? this.list.current.getCheckedData() : [];
		const { symbol, currency, numberFormat } = this.context.taimerAccount;
		const { userObject, addons, taimerAccount, functions: { checkPrivilegeAny, hasPrivilege } } = this.context;

		if (!hasPrivilege("invoices", ["write_simple", "write_full"])) {
			return <NoPermissionOverlay />
		}

		//tab mechanics & overrides
		const StyledTabs = withStyles({
			indicator: {
				backgroundColor: tabColor
			}
		})(Tabs);

		const createTabs = _.map(this.statuses, (entry, key) => {
			return <Tab key={key} value={Number(key)} label={entry.name} id={entry.trigger_id} />;
		});

		//menu button component override
		const button = {
			className: 'list-menu'
		}

		const printLanguage = this.state.companies?.find(c => c.id == this.state.company)?.print_lang;		

		//row functions to pass down, rowDisplay defines if the row is fluid ('flex') or not ('block')
		const rowProps = {
			rowDisplay: 'flex',
			sentHandler: this.markInvoiceSent,
			markPaidHandler: this.callSingleMarkPaidDialog,
			createNoticeHandler: this.callConfirmationDialog,
			createCreditHandler: this.callConfirmationDialog,
			formalizeHandler: this.callConfirmationDialog,
			deleteHandler: this.callConfirmationDialog,
			attachmentHandler: this.callAttachmentDialog,
			updateView: this.props.updateView,
			currency: this.state.companyCurrency,
			showInvoiceLogSlider: this.showInvoiceLogSlider,
			printLanguage: printLanguage,
			print: this.print,
			send: this.send,
			callErrorHandleDialog: this.callErrorHandleDialog,
			listTr: this.tr
		}

		const dialogProps = {
			currency: this.state.companyCurrency
		}

	    const options = [
	    	// ... projects_id > 0 ? [{ "label": this.tr("Show all"), "func": "showAll", "argument": false }] : [],
	        { "label": this.tr("Mark as sent"), "func": "markInvoiceSentMulti", "argument": false },
	        { "label": this.tr("Mark as paid"), "func": "callMultipleMarkPaidDialog", "argument": false },
	        { "label": this.tr("Create payment notice"), "func": "callConfirmationDialog", "argument": "createNotice" },
	        { "label": this.tr("Create credit"), "func": "callConfirmationDialog", "argument": "createCredit" }
	    ];		

		const SelectProps = {
			MenuProps: {
				onEntering: () => this.unListenReset(),
				onExited: () => this.listenReset()
			}
		}

		const checkedRowsIds = {
			ids: checkedRows.map(r => r.id).join(','),
			bill_ids: checkedRows.map(r => r.bill_id).join(',')
		}

		//view dialogs
		const Dialog = currentDialog ? this.dialogs[currentDialog] : undefined;
		const integrationErrors = integrationErrorCompanies?.find(c => c.company_id == company)
		const hasIntegrationErrorInvoices = Number(integrationErrors?.amount) > 0;

		return (
			<div className="contentBlock" id="invoiceList">
				<div className="listControlsContainer clearfix">
					<div className="invoice-list-header-row">
						<div className="header-container primary">
							{companies.length > 1 && <OutlinedField disabled={this.props.viewProps && this.props.viewProps.module === "projects"} SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Company")} value={company} select 
								onChange={e => {
									companies.forEach(company => {
										if (company.id == e.target.value)
											this.setState({companyCurrency: company.currency})
									})								
									this.setState({ company: e.target.value, page: 1 }, () => this.resetListAndFetchData({}, true));
									this.context.functions.setLastCompany(e.target.value);
								}}>
									{ companies.map(row => (
										<MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
									))}
								</OutlinedField>}
								<OutlinedField
									className="listFilterOutlinedField"
									select
									SelectProps={SelectProps}
									name="mass_actions"
									label={this.tr("Options")}
									shrinkLabel={false}
									onChange={e => {
										if (!e.target.value)
											return;

										if (e.target.value == 'handle_error') {
											this.callErrorHandleDialog('selectedRows');
											return;
										}

										const { func, argument } = options.filter(o => o.label == e.target.value)[0];
										this[func](argument);
									}} >
									{Number(navigationNotifications?.invoices) > 0 && this.isErrorInvoicesSelected() && <MenuItem key={'handle_error'} value={'handle_error'} className="listitem-error">{this.tr('Handle error')}</MenuItem>}
									{options.map(opt => {
										return <MenuItem className={opt.className || ""} key={opt.label} value={opt.label}>{opt.label}</MenuItem>
									})}
								</OutlinedField>

								<OutlinedField
									className="listFilterOutlinedField"
									select
									SelectProps={SelectProps}
									name="exports"
									label={this.tr("Print & export")}
									shrinkLabel={false}>
									<MenuItem key={'print'} onClick={() => this.print()}>{this.tr('Print')}</MenuItem>
									{printLanguage != 'en' && <MenuItem key={'print_en'} onClick={() => this.print('en')}>{this.tr('Print in english')}</MenuItem>}
									{this.sendOptions.root.map(e => !e.hidden && (e.companies == undefined || e.companies?.indexOf(company) > -1) && (<MenuItem key={e.name} onClick={() => {
										if(this.state.sendingInProgress == true) {
											return;
										} else {
											const confirmFunc = () => InvoiceApi[e.func]({ids: this.list.current.getCheckedData().map(el => el.id)}, enqueueSnackbar, this.fetchData, closeSnackbar, this.stateUpdater, company, this.tr, this.setSendingStatus, this.htmlTr);
											if (e.checkSent == 1)
												this.checkSentInvoices(e, confirmFunc);
											else
												confirmFunc();
										}
										}}>{e.name}</MenuItem>))}
									{this.sendOptions.eInvoice.map(e => !e.hidden && (e.companies == undefined || e.companies?.indexOf(company) > -1) && (<MenuItem key={e.name} onClick={() => InvoiceApi[e.func]({ids: this.list.current.getCheckedData().map(el => el.id)}, enqueueSnackbar, this.fetchData, closeSnackbar, this.stateUpdater, company, this.tr)}>{e.name}</MenuItem>))}
									{this.sendOptions.accounting.map(e => !e.hidden && (e.companies == undefined || e.companies?.indexOf(company) > -1) && (<MenuItem key={e.name} onClick={() => {
										if(this.state.sendingInProgress == true) {
											return;
										} else {
											const confirmFunc = () => InvoiceApi[e.func]({ids: this.list.current.getCheckedData().map(el => el.id)}, enqueueSnackbar, this.fetchData, closeSnackbar, this.stateUpdater, company, this.tr, this.setSendingStatus, e.analyticsKey, this.htmlTr);
											if (e.checkSent == 1)
												this.checkSentInvoices(e, confirmFunc);
											else
												confirmFunc();
										}
										}}>{e.name}
										</MenuItem>))}
								</OutlinedField>
								
						{showTabs ||
							<OutlinedField
								className="listFilterOutlinedField"
								select
								SelectProps={SelectProps}
								name="statuses"
								label={this.tr("Status")}
								value={this.state.tabValue}
								onChange={e => this.switchTabs(e, e.target.value)}>
								{hasIntegrationErrorInvoices && <MenuItem key={-1} value={-1}>{this.tr("Waiting for actions")}</MenuItem>}
								{_.map(this.statuses, (status, index) => {
									return <MenuItem key={index} value={index}>{status.name.charAt(0).toUpperCase() + status.name.substr(1)}</MenuItem>;
								})}
							</OutlinedField>}

						<DateRangePicker
							className="daterange"
							ranges={[dateRange]}
							onChange={this.onDateChange}
							onInputChange={this.onDateInputChange}
							label={this.tr("Time span")}
							dateFormat={userObject.dateFormat} />

						<AdvancedSearch
							ref={this.advancedSearch}
							mode={this.searchTerms && this.searchTerms.mode ? this.searchTerms.mode : undefined}
							initialFilters={this.searchTerms ? this.searchTerms.currentFilters : undefined}
							mainConfig={this.searchTerms && this.searchTerms.advanced_search_criteria ? { operator: this.searchTerms.advanced_search_criteria.operator } : undefined} 
							freetextLabel={this.searchTerms ? this.searchTerms.freetextSearchTerm : ""}
							alwaysShowClearFilters={!this.filtersAreInInitialState()}
							onClearSearch={this._resetFilters}
							fields={searchFields}
							onSearchResult={this.setData}
							autoCompleteData={{
								customer: [this.autoCompleteData['customers'], "parent_id"],
								projects: [this.autoCompleteData['projects'], "parent_id"],
								project_type: this.autoCompleteData['project_types'],
								project_category: [this.autoCompleteData['project_categories'] ? this.autoCompleteData['project_categories'].filter(pc => (pc.deleted < 1 && pc.locked < 1)) : this.autoCompleteData['project_categories'], "parent_id"],
								edited: this.autoCompleteData['edited_by'],
								reporting_group: [this.autoCompleteData['reporting_groups'], "parent_id"]
							}}
							autoCompleteDataFilters={{
								projects: "customers_id|customer.id",
								reporting_group: "customers_id|customer.id"
							}}
							perpage={perpage}
							noRequests={true}
							onSearchTrigger={(searchTerms) => {
								this.searchTerms = searchTerms;
								this.list.current && this.list.current.setPage(1);
								this.resetListAndFetchData({ page: 1 });
							}}
							/>
							{showTabs &&
							<StyledTabs
								onChange={this.switchTabs}
								value={tabValue} >
								{createTabs}
							</StyledTabs>}
					</div>
					{this.props.renderNoteToInvoiceCreator && this.props.renderNoteToInvoiceCreator()}
					{!projects_id && Number(navigationNotifications?.invoices) > 0 && this.renderWaitingForActionsNotification()}
				</div>
				{this.renderSummarySection()}
			</div>
			{ this.state.loading ? <LinearProgress /> : null }
			<List
				// fluid
				ref={this.list}
				data={data}
				columns={fieldColumns}
				sharedData={this.autoCompleteData}
				height="fitRemaining"
				trimHeight={-20}
				className="invoiceList"
				listRowType={InvoiceListRow}
				saveColumnConfig={true}
				userListSettingsKey="invoice_list"
				rowProps={rowProps}
				showNoResultsMessage={this.initialFetchDone}
				showPageSelector={true}
				noStateData={true}
				pageCount={pageCount}
				totalCount={invoiceCount}
				showOverlay={!this.state.hasInvoices}
				overlayComponent={InvoiceListOverlay}
				perpage={perpage}
				page={this.state.page}
				controlPage={true}
				onPageChange={page => {
					this.list.current.startPageChangeAnimation();
					this.setState({ page: page })
					this.fetchData({ page: page });
				}}
				useAllCheckedExcept={true}
				onSortRows={this.sortRows}
				onPerPageChange={value => { this.setState({ perpage: value, page: 1 }); this.fetchData({ perpage: value, page: 1 }); }} 
				useHSRightPadding
				onCheckAll={this.onListCheckAll}
				onUnCheckAll={this.onListCheckAll}
				onCheck={this.onListCheck}
				/>
			<br />
				{Dialog && <Dialog
					open
					onDialogClose={this.closeDialog}
					onDialogSave={this.saveDialog}
					data={dialogData} 
					dialogType={this.dialogProps[this.state.currentDialog]?.dialogType}
                    dialogProps={{...this.state.currentDialogProps}}
					{...dialogProps}/>}
				<Slider title={`${this.tr("Invoice Log")}: ${this.tr("Invoice")} ${invoiceLogSliderData?.bill_id}`} open={!!invoiceLogSliderData} onClose={this.closeInvoiceLogSlider}>
					<InvoiceLogView invoiceId={invoiceLogSliderData?.id} company={invoiceLogSliderData?.company} currency={companyCurrency} />
				</Slider>
			</div>
		);
	}
}

InvoiceList.defaultProps = {
	perpage: 40,
	showLockedUsersWithTag: true,
    enqueueSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(withSnackbar(InvoiceList));
