import React from "react";
import { hexColorWithOpacity } from "../helpers";
import "./StatusTag.css";

import { Tooltip, Fade } from '@mui/material';

/**
 * A tag element to show the status of something.
 * 
 * @property {string} color is used to determine the color of the label,
 * and the same color is used in the background with a lower opacity.
 * @property {string} text is the text shown in the label. Is styled with CSS to always be uppercase.
 * @property {boolean} clickable is just a bool to determine if the cursor should be a pointer & lower opacity when hovering.
 * @property {function} onClick is a function that gets called when clicking the element. Should be used together with clickable.
 * @property {boolean} absolute is used to make the element's position absolute, align it to the left and center it vertically. Works when when using inside a ListCell.
 * If a margin is needed, pass value for "left" inside the style prop. E.g. { left: 16 }.
 */
const RenderTag = React.forwardRef((props, ref) => {
  const { color, text, clickable, absolute, style, onClick } = props;
  return (
    <div
      {...props}
      ref={ref}
      onClick={onClick}
      style={{
        backgroundColor: color && hexColorWithOpacity(color, 0.15),
        color: color,
        ...style,
      }}
      className={`status-tag ${absolute && "absolute"} ${
        clickable && "clickable"
      }`}
    >
      {text}
    </div>
  );
});
class StatusTag extends React.Component {
  render() {
    const { tooltip } = this.props;

    if (tooltip) {
      const tooltipProps = typeof this.props.tooltip === "object" ? tooltip : {title: tooltip, arrow: true};
      console.log(this.props);
      return (
        <div>
          <Tooltip {...tooltipProps}>
            {<RenderTag {...this.props} />}
          </Tooltip>
        </div>
      );
    }
    else
      return <RenderTag {...this.props} />
  }

};

export default StatusTag;
