import { Portal } from '@mui/base';
import MenuItem from '@mui/material/MenuItem';
import { format } from "date-fns";
import FileSaver from 'file-saver';
import _ from 'lodash';
import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import PurchaseOrderView from '../../bills/PurchaseOrderView';
import AdvancedSearch from "../../search/AdvancedSearch";
import PurchaseOrderListRow from '../rows/PurchaseOrderListRow';
import DataHandler from "./../../general/DataHandler";
import OutlinedField from "./../../general/OutlinedField";
import Utils from "./../../general/Utils";
import { DateRangePicker } from './../../general/react-date-range/src';
import List from "./../List";
import "./ProjectList.css";



/* context */
import { SettingsContext } from './../../SettingsContext';

import PurchaseOrderListOverlay from '../overlays/PurchaseOrderListOverlay';

class PurchaseOrderList extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
        perpage: 30,
        supplierId: undefined,
        showLockedUsersWithTag: true,
    };

    constructor(props, context) {
        super(props, context, "list/lists/PurchaseOrderList");

        this.context = context;

        const startDate = this.props.start ? format(new Date(this.props.start), "YYYY-MM-DD") : undefined;
        const endDate = this.props.end ? format(new Date(this.props.end), "YYYY-MM-DD") : undefined;

        this.state = {
            subcontracts: [],
            count: 0,
            pages: 0,
            page: 1,
            perpage: props.perpage, 
            projectsId: this.props.viewProps ? Number(this.props.viewProps.id) : 0,
            filters: {
                company: props.company ? props.company : context.functions.getCompany("purchaseorders", "read", false, true),
                state: null
            },
            creationDateSpan: { 
                startDate: startDate, 
                endDate: endDate,
                key: "selection"
            },
            companies: [],
            autoCompleteData: props.autoCompleteData,
            initialFetchDone: false,
            currency: "EUR",
            printLanguageOptions: [{value: "en", label: this.tr("English")}],
            printLang: "en"
        };

        this.stickySearchKey = this.state.projectsId > 0 ? "projects_purchaseorder_list" : "purchaseorder_list";

        this.filtersInitialValues = {
            filters: {
                company: props.company ? props.company : context.functions.getCompany("purchaseorders", "read", false, true),            
                state: null
            },
            page: 1,
            perpage: props.perpage, 
            sortTerms: {},
            creationDateSpan: { 
                startDate: startDate, 
                endDate: endDate,
                key: "selection"
            },
		};

        if (props.state)
            this.state.filters.state = props.state;

        this.autoCompleteData = props.autoCompleteData;
        this.fields = [
            { name: "expand", header: "", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            // { name: "checked", header: "", columnHeaderType: "checkbox", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { name: "state", header: this.tr("Match to bill"), width: 100 },
            { name: "id", header: this.tr("No."), width: 50, searchable: true },
            { name: "supplier", header: this.tr("Supplier"), width: 160, searchable: true },
            ... (this.state.projectsId < 1 ? [{ name: "customer", header: this.tr("Customer"), width: 160, searchable: true }] : []),
            ... (this.state.projectsId < 1 ? [{ name: "project", header: this.tr("Project"), width: 160, searchable: true }] : []),
            { name: "quote", header: this.tr("Quote"), width: 110 },
            { name: "synced_to_bill", header: this.tr("Synced to bill"), width: 110 },
            { name: "creation_date", header: this.tr("Creation date"), width: 110 },
            { name: "duedate", header: this.tr("Due date"), width: 110 },
            { name: "state_date", header: this.tr("Status date"), width: 110 },
            { name: "delivery_date", header: this.tr("Delivery date"), width: 110 },
            { name: "netsum", header: this.tr(" Net total"), width: 100 },
            { name: "grosssum", header: this.tr("Gross total"), width: 100 },
            { name: "user", header: this.tr("Contact"), width: 140 },
            { name: "targeted_user", header: this.tr("Targeted user"), width: 140 },
        ];

        this.userTypeDataHeaders = {
            targeted_users_id: 'targeted_user',
            users_id: 'user'
        };

        this.translations = {
            locked: this.tr("locked"),
            freelancer: this.tr("freelancer")
        };        

        this.sortTerms = {};
        this.searchTerms = undefined;

        this.refList = React.createRef();
        this.printPo = React.createRef();
        this.advancedSearch = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();
        this.initializeStickySearch();
    }

    getAutoCompleteData = async (stickySearch = false) => {
        const companiesUrl = Number(this.state.projectsId) < 1 ? `subjects/companies/purchaseorders/read` : `subjects/companies_with_project_right/purchase_order_read`
        const companies        = await DataHandler.get({url: companiesUrl, currency: 1, print_lang: 1, country_lang: 1, print_options: 1});
        const autoCompleteData = await this.props.getAutoCompleteData(this.state.filters.company);
        const currency = this.getCurrency(this.state.filters.company, companies);
        const printLanguageOptions = companies.find(c => c.id == this.state.filters.company)?.print_languages;
        this.setState({ autoCompleteData, companies, currency, printLanguageOptions }, () => this.fetchData({}, stickySearch));
    } 

    getCurrency = (id, companies) => {
        companies = companies || this.state.companies;
        let currency = "EUR";

        if (!companies || companies.length < 1) 
            return currency;

        let found = false;
        companies.forEach(company => {
            if(company.id == id) {
                currency = company.currency
                found = true;
            }
        })
        
        if (!found) 
            currency = companies[0].currency;

        return currency;
    }


    initializeStickySearch() {
        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` }).done((response, _, request) => {
            if(request.status !== 200) {
                this.getAutoCompleteData();
                return;            
            }

            const privileges = this.context.privileges.purchaseorders?.read;
			const hasPrivilegess = privileges?.find(el => el == response.company);
            
            if (response.filters && (!hasPrivilegess || this.state.projectsId > 0)) {
				response.filters.company = this.filtersInitialValues.filters.company;
			}
			
			if (response.searchTerms)
                this.searchTerms = response.searchTerms;
            if (response.sortTerms)
                this.sortTerms = response.sortTerms;
                                
            this.setState({ ...response }, () => this.getAutoCompleteData(true));
        }).fail(response => {
            this.getAutoCompleteData();

        }).always((response, _, request) => {
            this.setState({ stickySearchInitialized: true });
        });
    }

    fetchData = async (overrides = {}, stickySearch = false) => {
        const { filters, page, perpage, projectsId, creationDateSpan } = this.state;

        let searchData = {};

        if (this.props.supplierId !== undefined)
            searchData.supplier_id += this.props.supplierId;

        if(this.searchTerms !== undefined && this.searchTerms.mode === "advanced") {
            searchData = {
                mode: 'advanced',
                advanced_search_criteria: JSON.stringify(this.searchTerms.advanced_search_criteria)
            };

            
        } else if(this.searchTerms !== undefined && this.searchTerms.mode === "freetext") {
            searchData = {
                mode: 'freetext',
                freetext: this.searchTerms.freetextSearchTerm,
                advanced_search_criteria: JSON.stringify(this.searchTerms.advanced_search_criteria)
            };
        }


        let filtersToSave = {filters, page, perpage, creationDateSpan};
        this.saveStickySearch(filtersToSave);

        let data = await DataHandler.post({
            url: 'purchaseorders/list',
            page,
            perpage,
            projectsId,
            ...filters,
            ...overrides,
            sort: this.sortTerms,
            ...({
                creationDateStart: creationDateSpan.startDate !== undefined ? format(creationDateSpan.startDate, "YYYY-MM-DD") : undefined,
                creatioDateEnd: creationDateSpan.endDate !== undefined ? format(creationDateSpan.endDate, "YYYY-MM-DD") : undefined
            }),
        }, searchData);
        const userTagProps = {
            fields: this.userTypeDataHeaders,
            showLocked: this.props.showLockedUsersWithTag,
            transl: this.translations,
            userData: this.props.autoCompleteData.all_users
        };
        data.subcontracts.forEach((p, i) => {
            if (Object.keys(p).some(k => Object.values(this.userTypeDataHeaders).includes(k))) {
                data.subcontracts[i] = ({...Utils.showLockedAndFreelancerUserTag(p, userTagProps)});
            }
        })

        data.initialFetchDone = true;
        this.setState(data, () => this.initializeList(page, stickySearch));
    }

    initializeList = (page, stickySearch) => {
        if (!this.refList.current)
            return;

        this.refList.current.endPageChangeAnimation();
        this.props.setCount && this.props.setCount(this.state.count);

        if (stickySearch) 
            this.refList.current.setPage(page);
    }

    saveStickySearch(filters) {
		let stateToSave = _.cloneDeep(filters);

		stateToSave.searchTerms = this.searchTerms;
		stateToSave.sortTerms = this.sortTerms;

        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}`, }, { search: stateToSave });
    }

    filtersAreInInitialState() {
        const initial = _.cloneDeep(this.filtersInitialValues);
        delete initial.page;
        delete initial.perpage;
        const filters = {};

        for(let key in initial) {
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = key === "sortTerms" ? JSON.stringify(this[key]) : JSON.stringify(this.state[key]);
        }

        const freetext = this.searchTerms ? this.searchTerms.freetextSearchTerm : "";

        return _.isEqual(initial, filters) && freetext === "";
    }

    _resetFilters = (evt) => {
		if (!evt || evt.keyCode == '27') {
			this.advancedSearch.current.clearSearch(evt, true);
			this.advancedSearch.current.clearSearchTextInput();

            this.searchTerms = undefined;
            this.sortTerms = {};

			this.setState({
				...this.filtersInitialValues,
			}, () => this.fetchData());
		}
    }

    setFilter = (name, value = undefined) => {

        const filters = {
            ...this.state.filters
        };

        if (value === undefined)
            delete filters[name];
        else
            filters[name] = value;

        this.refList.current.setPage(1);
        this.setState({filters}, this.fetchData);
    }

    print = (lang, id) => {
        const key = this.props.enqueueSnackbar(this.tr("Generating purchase order PDF-file..."), {
            variant: "info",
            persist: true
        });

        this.setState({ printPurchaseOrder: true, printPurchaseOrderId: id, printLang: lang, snackBarKey: key})
    }

    onPrintRenderReady = () => {
        setTimeout(() => {
            this.printPo.current && this.printPo.current.print(this.state.snackBarKey);
            this.setState({printPurchaseOrder: false});
        }, 1000);
    }

    export = () => {
        const target = "xlsx";
        const fields = this.fields;
        const { subcontracts, filters, currency, projectsId } = this.state;

        if (!subcontracts || subcontracts.length < 1) {
            this.props.enqueueSnackbar(this.tr("Nothing to export!"), {
                variant: "warning",
            });
            return;
        }

        let overrides = {};

        const params = { 
            ...filters,
            ...overrides,
            projectsId,
            sort: this.sortTerms,
            currency,
            url: "purchaseorders/export"
        };

        const columns = this.refList.current.columnOrder;
        const exportHeaders = [];        

        _.forEach(columns, column => {
			_.forEach(fields, field => {
				if(column == field.name && column != "expand"){
                    exportHeaders.push(field.header);
                }
			})
        })
        
        params.order = columns;
        params.columnNames = exportHeaders;
        params.file_name = this.tr("purchaseorders_list_export");

        DataHandler.getArrayBuffer({ ...params, export: target }).done(response => {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });

            FileSaver.saveAs(blob, `${params.file_name}.${target}`);
        });
    }

    companyChanged = async (company) => {
        this.refList.current.setPage(1);
        this.setState({ filters: {...this.state.filters, company }}, () => this.getAutoCompleteData());
        this.context.functions.setLastCompany(company);
    }

    resetList() {
        this.refList.current.resetCheckedRows();
        this.refList.current.setPage(1);
    }

    onDateChange = (event, name) => {
        const { startDate, endDate } = event.selection;

        this.setState({
            [name]: {
                startDate: format(startDate, "YYYY-MM-DD"),
                endDate: format(endDate, "YYYY-MM-DD"),
                key: "selection"
            }
        }, () => this.fetchData());

    }

    onDateInputChange = (dateType, date, name) => {
        const { endDate, startDate } = this.state[name];
        date = format(date, "YYYY-MM-DD");      

        if (dateType == "start") {
            this.setState({
                [name]: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection"
                }
            }, () => this.fetchData());
        } else {
            this.setState({
                [name]: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection"
                }
            }, () => this.fetchData());
        }
    }

    render() {
        const { filtersEl, sumsEl } = this.props;
        const { filters, has_purchase, companies, autoCompleteData, initialFetchDone, currency, projectsId, printLanguageOptions } = this.state;
        
        if(!this.state.stickySearchInitialized) {
            return null;
        }

        return (<React.Fragment>
            {this.state.printPurchaseOrder && (
                <div style={{ display: "none", width: "0px", height: "0px" }}>
                    <PurchaseOrderView ref={this.printPo} id={this.state.printPurchaseOrderId} printMode={true} printLang={this.state.printLang} onPrintRenderReady={this.onPrintRenderReady} />
                 </div>
            )}
            <Portal container={filtersEl}>
                {companies.length > 1 && !projectsId && <OutlinedField className="listFilterOutlinedField" label={this.tr("Company")} value={filters.company} select onChange={e => this.companyChanged(e.target.value)}>
                { companies.map(row => (
                    <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                ))}
                </OutlinedField>}
                <OutlinedField
                    select
                    label="Status"
                    className="listFilterOutlinedField"
                    value={filters.state || -1}>
                    <MenuItem value={-1} onClick={() => this.setFilter("state")}>
                        {this.tr("All")}
                    </MenuItem>
                    {
                        autoCompleteData.subcontract_statuses.map((opt) =>
                            <MenuItem value={opt.id} key={opt.id} onClick={() => this.setFilter("state", opt.id)}>
                                {opt.label}
                            </MenuItem>
                        )
                    }
                </OutlinedField>

                <div className="flex"><DateRangePicker
                    className="daterange"
                    ranges={[this.state.creationDateSpan]}
                    onChange={(event) => { this.resetList(); this.onDateChange(event, 'creationDateSpan') }}
                    onInputChange={(dateType, date) => { this.resetList(); this.onDateInputChange(dateType, date, 'creationDateSpan') }}
                    label={this.tr("Creation date")}
                    dateFormat={this.context.userObject.dateFormat} /></div>

                <AdvancedSearch
                    ref={this.advancedSearch}
                    mode={this.searchTerms && this.searchTerms.mode ? this.searchTerms.mode : undefined}
					initialFilters={this.searchTerms ? this.searchTerms.currentFilters : undefined}
					mainConfig={this.searchTerms && this.searchTerms.advanced_search_criteria ? { operator: this.searchTerms.advanced_search_criteria.operator } : undefined} 
					freetextLabel={this.searchTerms ? this.searchTerms.freetextSearchTerm : ""}
					alwaysShowClearFilters={!this.filtersAreInInitialState()}
                    onClearSearch={this._resetFilters}
                    fields={this.fields.filter(f => f.searchable).map(f => { return { field: f.name, type: f.type, transl: f.header, visualizationType: f.visualizationType || "list", entityMode: f.entityMode || false } })}
                    noRequests={true}
                    onSearchTrigger={(searchTerms) => {
                        this.searchTerms = searchTerms;
                        this.resetList();
                    }}
                    autoCompleteData={{
                        supplier: autoCompleteData.allowed_customers,
                        customer: autoCompleteData.allowed_customers,
                        project: autoCompleteData.allowed_projects,
                        // reporting_group: [autoCompleteDatcustomersa.reporting_groups, "parent_id"]
                    }}
                    autoCompleteDataFilters={{
                        // reporting_group: "customers_id|supplier.id"
                    }}
                />
            </Portal>
            <Portal container={sumsEl}>
                <span className="info"></span>
            </Portal>
            <List
                ref={this.refList}
                data={this.state.subcontracts}
                columns={this.fields}
                height="fitRemaining"
                trimHeight={-10}
                rowHeight={44}
                className="purchaseOrderMainList"
                listRowType={PurchaseOrderListRow}
                rowProps={{companies_id: filters.company, projectsId, currency, printLanguageOptions, print: this.print }}
                ignoreRowPropsChange={false}
                showOverlay={!has_purchase && initialFetchDone}
                overlayComponent={PurchaseOrderListOverlay}
                overlayProps={{company: filters.company, projects_id: this.props.projects_id, hasPoWritePrivilege: autoCompleteData.hasPoWritePrivilege}}
                noColorVariance={true}
                sharedData={autoCompleteData}
                saveColumnConfig={true}
                userListSettingsKey="purchase_order_list"
                showPageSelector={true}
                pageCount={this.state.pages}
                totalCount={this.state.count}
                perpage={this.state.perpage}
                noStateData={true}
                onPerPageChange={perpage => {
                    this.refList.current.setPage(1);
                    this.setState({ perpage: perpage }, this.fetchData);
                }}
                onPageChange={page => {
                    this.refList.current.startPageChangeAnimation();
                    this.setState({ page: page }, this.fetchData);
                }}
                onSortRows={(colName, asc) => {
                    this.sortTerms = { name: colName, asc: asc };
                    this.refList.current.setPage(1);
                }}
                useHSRightPadding
                />
        </React.Fragment>);
    }
}

export default PurchaseOrderList;
