import React from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
import TaimerComponent from './../../TaimerComponent';
import FileSaver from 'file-saver';
import List from "../List";
import { SettingsContext } from './../../SettingsContext';
import AdvancedSearch from "../../search/AdvancedSearch";
import DataHandler from '../../general/DataHandler';
import MultiSelect from '../../general/MultiSelect';
import UserListRow from "../rows/UserListRow";
import MenuItem from '@mui/material/MenuItem';
import './UserList.css';
import _ from 'lodash';
import OutlinedField from "./../../general/OutlinedField";
import withStyles from '@mui/styles/withStyles';
import NoPermissionOverlay from '../../overlays/NoPermissionOverlay';
import { withSnackbar } from 'notistack';
import { 
    companyHasDimensionAddOn,
    getDimensionAutoCompleteData,
    createAdvancedSearchFieldsForDimensions
} from "../../dimensions/helpers"
import { CloudDownload } from '@mui/icons-material';
import PageTopSection from '../../general/PageTopSection';
import { UserDialog } from '../dialogs/ProductCatalogDialogs';

const styles = theme => ({

});

class UserList extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/lists/ContactAndUserList");

        this.userStickySearchKey = "user_list";

        this.checkedUsers = [];
        this.userSearchTerms = {};
        this.lastUsersQueryParams = {};
        this.userSortConfig = undefined;

        this.initialFetchDone = {
            user: false
        };

        this.userFiltersInitialValues = {
            typeFilter: '0',
            typeFilterDefault: [{ value: "0", label: this.tr("All") }],
            perpage: this.props.perpage,
            company: context.userObject.companies_id > 0 ? context.userObject.companies_id : 1,
            page: 1,
        }

        const { taimerAccount: { showState }, functions: { checkPrivilege } } = this.context;

        this.state = {
            ...this.userFiltersInitialValues,
            view: "users",
            autoCompleteDataDone: false,
            userData: [],
            values: [],
            userPageCount: 0,
            contactCount: 0,
            userCount: 0,
            userLimit: {
                used: 1,
                limit: 1,
            },
            companies: [],
            field_edited: "",
            customers_types_name: 0,
            userStickySearchInitialized: false,
            dimensions: [],
            dimensionItems: {},
            dimensionAutoCompleteData: {},
            dimensionAutoCompleteDataFilters: {},
            userDialogData: { open: false }
        };
        this.getParams = {};

        const userfields = this.getListColumns();

        this.advancedSearchFieldsUsers = userfields.filter(f => f.field !== "expand" && f.field !== "checked").map(f => {
            let field = { field: f.field, transl: f.header, type: f.type };

            if (f.hasOwnProperty("autoCompleteData"))
                field.autoCompleteData = f.autoCompleteData;

            return field;
        });
        

        this.userList = React.createRef();
        this.useradvancedSearch = React.createRef();
        this.userTypeSelect = React.createRef();
        this.pageChanged = this.pageChanged.bind(this);
        this.fetchAutoCompleteData = this.fetchAutoCompleteData.bind(this);
        this.fetchuserData = this.fetchuserData.bind(this);
        this.loadOptions = this.loadOptions.bind(this);

        this.userFiltersAreInInitialState = this.userFiltersAreInInitialState.bind(this);
        this.initializeUserStickySearch = this.initializeUserStickySearch.bind(this);
        this.saveUserStickySearch = this.saveUserStickySearch.bind(this);
        this.initDimensions = this.initDimensions.bind(this);

        this.newUserRow = {
            id: -1,
            lastname: "",
            firstname: "",
            position: "",
            team: "",
            company: "",
            email: "",
            phone: "",
            activated: "",
            deactivated: "",
            modified_by: this.context.userObject.usersId,

        };
        this.userTypes = [
            { id: "1", value: "1", label: this.tr('user') },
            { id: "2", value: "2", label: this.tr('freelancer') }
        ];
    }

    componentDidMount() {
        super.componentDidMount();

        this.listenReset();
        let rights = "persons/userlist_read";
        DataHandler.get({ url: 'settings/limits/users' }).done((userLimit) => {
            this.setState({ userLimit })
        })

        DataHandler.get({ url: `subjects/companies/${rights}` }).done(companies => {
            rights = rights.split("/");
            const company = this.context.functions.getCompany(rights[0], rights[1], this.state.company, true);
            this.userFiltersInitialValues.company = company;
            this.setState({ companies, company }, () => this.updateComponentData());
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.company !== this.state.company) {
            this.initDimensions(this.state.company);
        }
    }

    updateComponentData = () => {
        this.state.userStickySearchInitialized ? this.getInitialData() : this.initializeUserStickySearch();
    }

    componentWillUnmount() {
        this.unListenReset();
    }

    getListColumns = () => {
        return [
            { field: "expand", name: "expand", header: "", /*columnHeaderType: "",*/ width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            ...(this.context.functions.checkPrivilege("admin", "admin", this.state.company) ? [{ field: "checked", name: "checked", staticIndex: 1, header: "", columnHeaderType: "checkbox", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false }] : []),
            { field: "lastname", name: "lastname", header: this.tr("Surname"), width: 150, showMenu: true, resizeable: true },
            { field: "firstname", name: "firstname", header: this.tr("First Name"), width: 150, showMenu: true, resizeable: true },
            { field: "title", name: "title", header: this.tr("Title"), width: 200, showMenu: true, resizeable: true },
            { field: "position", name: "position", header: this.tr("Position"), width: 200, showMenu: true, resizeable: true },
            { field: "company", name: "company", header: this.tr("Company"), width: 175, showMenu: true, resizeable: true },
            { field: "email", name: "email", header: this.tr("Email"), width: 225, showMenu: true, resizeable: true },
            { field: "phone", name: "phone", header: this.tr("Phone"), width: 225, showMenu: true, resizeable: true },
            { field: "team", name: "team", header: this.tr("Team"), width: 175, showMenu: true, resizeable: true },
            //{ field: "activated", name: "activated", header: this.tr("Activated"), width: 200, showMenu: true, resizeable: true },
            //{ field: "deactivated", name: "deactivated", header: this.tr("Deactivated"), width: 200, showMenu: true, resizeable: true },
            //{ field: "edited_by", name: "edited_by", header: this.tr("Edited By"), width: 175, showMenu: true, resizeable: true },

        ];
    }

    async initDimensions(company) {
        if(!companyHasDimensionAddOn(company, this.context.addons)) {
            this.setState({ 
                dimensions: [],
                dimensionItems: {},
                dimensionAutoCompleteData: {},
                dimensionAutoCompleteDataFilters: {},
            });

            return;
        }

        const data = await getDimensionAutoCompleteData(company);

        this.setState({ ...data }); 
    }

    getInitialData = (override, stickySearch) => {
        this.fetchAutoCompleteData();
        this.fetchuserData(override, stickySearch);
        this.initDimensions(this.state.company);
    }

    pageChanged(page) {
        this.setState({ page: page });

        // If the AdvancedSearch component is in advanced mode, we need to trigger the
        // search with the currently selected advanced filtering options and just add
        // the page number.

        // if (this.contactadvancedSearch) {
        // this.fetchcontactData(pageConf);
        // } else if (this.useradvancedSearch) {
        // this.fetchuserData(pageConf);
        // }
    }

    fetchAutoCompleteData() {
        const { company } = this.state;
        return DataHandler.get({ url: "autocomplete_data/contacts/" + company }).done(autoCompleteData => {
            autoCompleteData.modified_by = autoCompleteData.modified_by.map(ac => {
                if (ac.companies_id < 1)
                    ac.name = `${ac.name} (${this.tr('freelancer')})`;
                return ac;
            });
            this.autoCompleteData = autoCompleteData;
            this.setState({ autoCompleteDataDone: true });
        });
    }

    initializeUserStickySearch() {
        DataHandler.get({ url: `saved_search/sticky/${this.userStickySearchKey}` }).done((response, _, request) => {
            if (request.status !== 200) {
                this.getInitialData({}, true);
                return;
            }

            if (!this.context.privileges.persons.userlist_read.find(el => el == response.company)) {
				response.company = this.userFiltersInitialValues.company;
            }
            
            this.userSearchTerms = response.userSearchTerms || {};
            this.userSortConfig = response.userSortConfig;

            this.setState({ ...response }, () => this.getInitialData({ page: response.page }, true));
        }).fail(response => {
            this.getInitialData({}, true);

        }).always((response, _, request) => {
            this.setState({ userStickySearchInitialized: true });
        });
    }

    saveUserStickySearch() {
        let stateToSave = {};
        stateToSave.userSearchTerms = _.cloneDeep(this.userSearchTerms);
        stateToSave.userSortConfig = _.cloneDeep(this.userSortConfig);

        Object.keys(this.userFiltersInitialValues).forEach(e =>
            stateToSave[e] = this.state[e]
        );

        DataHandler.post({ url: `saved_search/sticky/${this.userStickySearchKey}`, }, { search: stateToSave });
    }

    userFiltersAreInInitialState() {
        const initial = _.cloneDeep(this.userFiltersInitialValues);
        delete initial.page;
        delete initial.perpage;
        delete initial.typeFilterDefault;
        const filters = {}

        for (let key in initial) {
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = JSON.stringify(this.state[key]);
        }

        const freetext = this.userSearchTerms ? this.userSearchTerms.freetextSearchTerm : "";

        return _.isEqual(initial, filters) && !freetext && this.state.typeFilterDefault[0].value === "0";
    }

    _resetUserFilters = (evt) => {
        if (!evt || evt.keyCode == '27') {
            this.useradvancedSearch.current.clearSearch(evt, true);
            this.useradvancedSearch.current.clearSearchTextInput();

            this.userSearchTerms = {};
            this.userSortConfig = undefined;
            this.userTypeSelect.current.handleChangeMulti(this.userFiltersInitialValues.typeFilterDefault);

            this.setState({
                ...this.userFiltersInitialValues,
            }, () => this.fetchuserData());
        }
    }

    fetchuserData(override = {}, stickySearch = false) {
        let parameters = { ...this.lastUsersQueryParams, page: 1, perpage: this.state.perpage, typeFilter: this.state.typeFilter }
        let mode = { advanced: "advanced", freetext: "freetext", none: "none" };
        const { company } = this.state;

        this.userList.current && this.userList.current.setState({ isLoading: true});
        for (let i in override)
            parameters[i] = override[i];
        const sortQueryParams = this.userSortConfig !== undefined ? { name: this.userSortConfig.name, asc: this.userSortConfig.asc } : {};
        const advParams = { ...parameters, advanced_search_criteria: JSON.stringify(this.userSearchTerms.advanced_search_criteria), mode: mode.advanced, sort: sortQueryParams, company };

        const callback = () => this.initialFetchDone.user = true;

        if (!stickySearch)
            this.saveUserStickySearch();

        if (this.userSearchTerms !== undefined && this.userSearchTerms.mode === "advanced") {
            if (override.onlyIds)
                return DataHandler.post({ url: "get_users" }, advParams);

            DataHandler.post({ url: "get_users" }, advParams).done(userData => this.setUserList(userData));
            this.lastUsersQueryParams = { ...advParams };
            DataHandler.post({ url: 'get_users', getcount: 1 }, advParams).done(resp => {
                this.userList.current && this.userList.current.setState({ isLoading: false});
                this.setState({
                    page: parameters.page,
                    userCount: resp.count,
                    userPageCount: resp.page_count
                }, callback);
            });
        } else if (this.userSearchTerms !== undefined && this.userSearchTerms.mode === "freetext") {
            if (override.onlyIds)
                return DataHandler.post({ url: "get_users", mode: mode.freetext, ...parameters }, { sort: sortQueryParams, ...this.userSearchTerms, company });

            DataHandler.post({ url: "get_users", mode: mode.freetext, ...parameters }, { sort: sortQueryParams, ...this.userSearchTerms, company }).done(userData => this.setUserList(userData));
            this.lastUsersQueryParams = { mode: mode.freetext, ...parameters, sort: sortQueryParams, ...this.userSearchTerms };
            DataHandler.post({ url: 'get_users', mode: mode.freetext, ...parameters, getcount: 1 }, { ...this.userSearchTerms, company }).done(resp => {
                this.userList.current && this.userList.current.setState({ isLoading: false});
                this.setState({
                    page: parameters.page,
                    userCount: resp.count,
                    userPageCount: resp.page_count
                }, callback);
            });
        } else {
            if (override.onlyIds)
                return DataHandler.post({ url: "get_users", mode: mode.none, ...parameters }, { sort: sortQueryParams, company });

            DataHandler.post({ url: "get_users", mode: mode.none, ...parameters }, { sort: sortQueryParams, company }).done(userData => this.setUserList(userData));
            this.lastUsersQueryParams = { mode: mode.none, ...parameters, sort: sortQueryParams };
            DataHandler.post({ url: 'get_users', mode: mode.none, ...parameters, getcount: 1 }, { company }).done(resp => {
                this.userList.current && this.userList.current.setState({ isLoading: false});
                this.setState({
                    page: parameters.page,
                    userCount: resp.count,
                    userPageCount: resp.page_count
                }, callback);
            });
        }

    }

    setUserList = (userData) => {

        let { users } = userData;

        users.forEach(u =>
            u.company = u.companies_id == 0 ? this.tr("freelancer") : u.company
        );

        this.setState({ userData: users, hasFreelancers: userData.freelancers > 0 });
    }

    async loadOptions(value, { action }) {
        this.setState({ values: value });
        this.sortByEmailList(value && value.id);
    }

    fetchAllIds = async () => {
		const data = await this.fetchuserData({ onlyIds: true });
		return data.ids.map(e => e.id);
	}

	getSelectedRows = async () => {
		const allCheckedExcept = this.userList.current.getAllCheckedExcept();
		let selected;

        if(allCheckedExcept) {
            selected = await this.fetchAllIds();
            selected = selected.filter(id => {
                return !allCheckedExcept[id]; 
            });
        } else {
            selected = this.userList.current.getCheckedRows();
        }

		return selected;
    }

    export = async (target) => {
        const { userData } = this.state;
        if (!userData || userData.length < 1) {
            this.props.enqueueSnackbar(this.tr("Nothing to export!"), {
                variant: "warning",
            });
            return;
        }

        let selected = await this.getSelectedRows();

		if (selected.length === 0) {
            selected = await this.fetchAllIds();
        }

        const currentList = this.userList.current;
        const columnOrder = currentList.visibleColumnsOrder;
        const fields = this.getListColumns();
        const exportHeaders = [];

        _.forEach(columnOrder, column => {
            _.forEach(fields, (field, i) => {
                if (column == field.name && column != "expand" && column != "checked" && column != "activated" && column != "deactivated" && column != "edited_by" && column != "edited") {
                    exportHeaders.push(field.header);
                }
            })
        })

        const params = { ...this[`lastUsersQueryParams`], company: this.state.company };
        params.file_name = this.tr("users_list_export");
        params.freelancer_transl = this.tr('freelancer');
        
        DataHandler.postArrayBuffer({ url: `users_export`, ...params, order: columnOrder, columnNames: exportHeaders, export: target }, {ids: selected}, true)
            .done((response) => {

                var blob = new Blob([response], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
                });

                FileSaver.saveAs(blob, `${params.file_name}.${target}`);
                this.userList.current && this.userList.current.setState({ isLoading: false});
            });

    }

    listenReset = () => {
        document.body.addEventListener("keyup", this._resetUserFilters);
    }

    unListenReset = () => {
        document.body.removeEventListener("keyup", this._resetUserFilters);
    }


    setChangedField = (name, callback) => {
        this.setState({ field_edited: name }, callback);
    }

    addUser = () => {
        const { company } = this.state;
        this.context.functions.addUser({ companies_id: company });
    }

    showError = (resp) => {
        const { enqueueSnackbar } = this.props;
        let message = this.tr("Error in saving!");

        if (resp.status == 422) {
            if (resp.responseJSON.email) 
                message = this.tr("Saving failed") + ": " + this.tr("Invalid email!");
            else if (resp.responseJSON.error && (resp.responseJSON.error === 'EMAIL_IN_USE' || resp.responseJSON.error === 'EMPTY_EMAIL_NOT_ALLOWED')) 
                message = this.tr("Saving failed") + ": " + this.tr(resp.responseJSON.msg);
        }
        enqueueSnackbar(message, {variant: 'error'});
    }

    closeUserDialog = () => {
        this.setState({ userDialogData: { ...this.state.userDialogData, open: false } });
    };

    openUserDialog = (data, action, confirmFunc) => {
        this.setState({ userDialogData: { open: true, data, action, onClose: this.closeUserDialog, onConfirm: confirmFunc } });
    };

    renderSummarySection = (totalCount) => {
        const { company } = this.state;
		const { functions: { urlify, updateView, hasPrivilege, checkPrivilege } } = this.context;
		return (
			<PageTopSection
			summaries={[{ title: this.tr("Users") , value: totalCount || 0 }]}
			additionalButtons={checkPrivilege("admin", "admin", company) ? [
				{
					title: this.tr("EXPORT"),
					action: () => this.export('xlsx'),
					icon: <CloudDownload />,
				}
			] : []} mainButtons={[
				{
					title: this.tr("ADD USER"),
					action: this.addUser,
					isVisible: hasPrivilege("admin", "admin")
				}
			]} settingsButton={{
				isVisible: !(null == this.context.privileges.admin),
				title: this.tr("Settings"),
				href: urlify({ module: 'settings', action: 'index', group: 'users', page: 'default' }),
				action: () => updateView({ module: 'settings', action: 'index', group: 'users', page: 'default' }, false)
			}}  />
		);
	}

    sendPassword = (data) => {
        const { enqueueSnackbar } = this.props;

        if (this.state.passwordResetInProgress) {
            enqueueSnackbar(this.tr(`Password reset is already in progress`), {
                variant: 'error',
            });
            return;
        }

        enqueueSnackbar(this.tr(`Sending password reset mail`), {
            variant: 'info',
        });

        DataHandler.post({ url: 'settings/users/' + data.id + '/send_password_email' })
            .done((resp) => {
                if (resp.result === 'fail')
                    enqueueSnackbar(this.tr(`Password reset failed. please check user email`), {
                        variant: 'error',
                    });
                else
                    enqueueSnackbar(this.tr(`Password reset mail sent`), {
                        variant: 'success',
                    });
            })
            .fail(() => enqueueSnackbar(this.tr(`Something went wrong. Please try again`), { variant: 'error' }))
            .always(() => this.setState({ passwordResetInProgress: false }));

        this.setState({ passwordResetInProgress: true });
        this.closeUserDialog();
    }

    unlockUser = (data) => {
        const { id } = data;
        const { enqueueSnackbar } = this.props;

        DataHandler.post({ url: 'settings/users/' + id + '/unlock' }).done((resp) => {
            if (resp.status && resp.status == 'error') {
                enqueueSnackbar(this.tr(`Not enough licenses`), {
                    variant: 'error',
                });
            }
            setTimeout(() => {
                this.fetchuserData({ page: this.state.page });
            }, 1000);
        });
        this.closeUserDialog();
    }

    lockUser = (data) => {
        const { id } = data;
        DataHandler.post({ url: 'settings/users/' + id + '/lock' }).done((resp) => {
            setTimeout(() => {
                this.fetchuserData({ page: this.state.page });
            }, 1000);
        });
        this.closeUserDialog();
    }

    delete = (data) => {
        this.context.functions.showConfirmationDialog({
            header: this.tr('Delete user'),
            warning: this.tr('Do you want to delete user') + ': ' + data.lastname + '  ' + data.firstname + ' (US' + data.id + ')' + '?',
            confirmText: this.tr('Delete'),
            onConfirm: () => {
                DataHandler.delete({ url: 'settings/users/' + data.id }).done((resp) => {
                    setTimeout(() => {
                        this.fetchuserData({ page: this.state.page });
                    }, 1000);
                });
            }
        });
    }

    render() {
        if (this.state.view === "users" && !this.state.userStickySearchInitialized) {
            return null;
        }

        const { view, companies, company, userDialogData} = this.state;

        const { functions: { checkPrivilege } } = this.context;
        const { tr } = this;

        if (view === "users" && !checkPrivilege("persons", "userlist_read", company)) {
            return <NoPermissionOverlay />
        }

        if (!this.state.autoCompleteDataDone)
            return <div><Loading className='main-page-loading-indicator' /></div>

        return (
            <div id="user-list" className="contentBlock">
                <div className="listControlsContainer clearfix filter-container">
                    <div className="primary header-container">
                        {companies.length > 1 &&
                            <OutlinedField className="listFilterOutlinedField" name="company" value={company} label={tr("Select company")} onChange={e => { 
                                this.setState({ [e.target.name]: e.target.value }, this.updateComponentData);
                                this.context.functions.setLastCompany(e.target.value);
                            }} select>
                                {companies.map(e => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                            </OutlinedField>
                        }

                        {this.state.hasFreelancers &&
                            <MultiSelect
                                ref={this.userTypeSelect}
                                className="user-type"
                                width="160px"
                                menuMaxWidth="160px"
                                menuMinWidth="160px"
                                options={this.userTypes}
                                defaultValue={this.state.typeFilterDefault}
                                noAllOptions
                                label={this.tr("User type")}
                                onChange={(data) => {
                                    data && this.setState({ typeFilter: data[0].value, typeFilterDefault: data }, () => this.fetchuserData());
                                }}
                            />
                        }
                        <AdvancedSearch
                            ref={this.useradvancedSearch}
                            mode={this.userSearchTerms && this.userSearchTerms.mode ? this.userSearchTerms.mode : undefined}
                            initialFilters={this.userSearchTerms ? this.userSearchTerms.currentFilters : undefined}
                            mainConfig={this.userSearchTerms && this.userSearchTerms.advanced_search_criteria ? { operator: this.userSearchTerms.advanced_search_criteria.operator } : undefined}
                            freetextLabel={this.userSearchTerms ? this.userSearchTerms.freetextSearchTerm : ""}
                            alwaysShowClearFilters={!this.userFiltersAreInInitialState()}
                            onClearSearch={this._resetUserFilters}
                            fields={[
                                ...this.advancedSearchFieldsUsers,
                                ...createAdvancedSearchFieldsForDimensions(this.state.dimensions)
                            ]}
                            noRequests={true}
                            onSearchTrigger={(userSearchTerms) => {
                                this.userSearchTerms = userSearchTerms;

                                this.fetchuserData({ page: 1 });
                            }}
                            onSearchResult={this.setData}
                            // autoCompleteData={{ 
                                // ...this.autoCompleteData, 
                                // ...this.state.dimensionAutoCompleteData 
                            // }}
                            perpage={this.props.perpage}
                            autoCompleteData={{
                                team: this.autoCompleteData['team'],
                                ...this.state.dimensionAutoCompleteData
                            }} 
                            autoCompleteDataFilters={this.state.dimensionAutoCompleteDataFilters} />
                        <div style={{ clear: "both" }}></div>
                        {this.renderSummarySection(this.state.userCount)}
                    </div>
                </div>

                <div style={{ display: "block" }}>
                    <List
                        ref={this.userList}
                        rowProps={{
                            enqueueSnackbar: this.props.enqueueSnackbar,
                            onUpdate: data => {
                                DataHandler.post({ url: `users_save/${data.id}` }, data).fail((resp) => {
                                    this.showError(resp);
                                });;
                            },
                            listRef: this.userList, onCheck: (checked, data) => {
                                if (checked) {
                                    this.checkedUsers.push(data);
                                } else {
                                    this.checkedUsers = this.checkedUsers.filter(u => u.id = !data.id);
                                }
                            },
                            fetchData: () => {
                                this.fetchuserData({ page: this.state.page });
                            },
                            sendPassword: (data) => this.openUserDialog(data, 'Reset_password', this.sendPassword),
                            lockUser: (data) => this.openUserDialog(data, 'Deactivate', this.lockUser),
                            unlockUser: (data) => this.openUserDialog(data, 'Activate', this.unlockUser),
                            onDelete: this.delete,
                            company: company,
                            returnProps: {
                                returnmodule: "users",
                                returnaction: "list",
                            }
                        }}
                        noStateData={true}
                        data={this.state.userData}
                        columns={this.getListColumns()}
                        sharedData={this.autoCompleteData}
                        height="fitRemaining"
                        trimHeight={-10}
                        onSortRows={(name, asc) => {
                            this.userSortConfig = {
                                name: name,
                                asc: asc
                            };
                            this.fetchuserData();
                        }}
                        className="userList"
                        listRowType={UserListRow}
                        newRow={this.newUserRow}
                        saveColumnConfig={true}
                        showNoResultsMessage={this.initialFetchDone.user}
                        useAllCheckedExcept={true}
                        userListSettingsKey="user_list"
                        controlPage={true}
                        page={this.state.page}
                        onPageChange={page => {
                            this.setState({ page }, () => this.fetchuserData({ page }));
                        }}
                        onPerPageChange={perpage => {
                            this.setState({ perpage }, this.fetchuserData({ perpage }));
                        }}
                        showPageSelector={true}
                        pageCount={this.state.userPageCount}
                        totalCount={this.state.userCount}
                        perpage={this.state.perpage}
                        useHSRightPadding
                        />
                </div>
                {userDialogData && userDialogData.open && <UserDialog {...userDialogData} />}
            </div>
        );
    }
}

UserList.defaultProps = {
    perpage: 30
}

export default withSnackbar(withStyles(styles, { withTheme: true })(UserList));
