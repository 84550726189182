import React from 'react';
import Paper from '@mui/material/Paper';
import Select, { createFilter } from 'react-select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import TaimerComponent from "../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../SettingsContext';

/* css */
import 'react-virtualized/styles.css'
import './MultiSelect.css';

import { List, AutoSizer } from 'react-virtualized';

const styles = theme => ({
  root: {
    flexGrow: 1,
    minWidth: 100,
    height: "100%"
  },
  input: {
    display: 'flex',
    paddingLeft: 0,
    height: '100%',
    // position: 'absolute',
  },
  valueContainer: {
    display: 'flex',
    // flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    paddingLeft: 12,
  },
  chip: {

  },
  chipFocused: {

  },
  noOptionsMessage: {

  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    width: "fit-content",
    left: 0,
    right: 0,
    borderRadius: '5px',
    zIndex: 999
  },
  divider: {

  },
  arrowdropdown: {
    width: "18px",
    height: "18px",
    marginRight: "-6px",
    "border-radius": "18px",
    "background-color": "#f7f8fc",
    "cursor": "pointer",
    fill: "#7c86a2",
    "& path": {
      color: "#dcdee6"
    }
  },
  control: {
    margin: 0,
    height: "100%"


  }
});

const NoOptionsMessage = (props) => {
  return (
    <MenuItem
      component="div"
      style={{ backgroundColor: 'transparent', height: '25px', paddingTop: '10px' }} onClick={() => props.selectProps.onMenuClose()}>
      {props.children}
    </MenuItem>
  );

}

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
}

const Control = (props) => {
  const {
    children,
    innerProps,
    selectProps: { classes, textFieldProps, isDisabled },
  } = props;



  return (
    <TextField
      className={props.selectProps.classes.control}
      fullWidth
      variant="filled"
      disabled={isDisabled}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          children,
          ...innerProps,
        },
      }}
      {...textFieldProps}
    />
  );
}



Option = (props) => {
  return (
    props.value != 0 ?
      <MenuItem
        {...props.innerProps}>
        <Checkbox checked={props.isSelected} className="menu-item-checkbox" />
        <ListItemText primary={props.children} className="menu-item-itemtext" />
      </MenuItem>
      :
      <MenuItem
        className={"menuitem-select-all-none"}
      >
        <p {...props.innerProps} className={"menuitem-select-all-none-p"} >{props.selectProps.selectAllLabel}</p>
        {
          props.selectProps.quickSelectComponents && props.selectProps.quickSelectComponents.map(e => 
              (
                <p
                {...props.innerProps}
                onClick={() => {
                  props.selectProps.onChange(props.options.filter(e.filterFunction));
                }}
                className={"menuitem-select-all-none-p"}
                >
                  {e.label}
                </p>
              )
            )
        }
        <p onClick={() => props.clearValue()} className={"menuitem-select-all-none-p"} >{props.selectProps.selectNoneLabel}</p>
      </MenuItem>
  );
}

const Placeholder = (props) => {
  const { children } = props;
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
}

const SingleValue = (props) => {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

const MultiValue = (props) => {

  if ((props.selectProps.value.length > 0 && props.data.value == props.selectProps.value[0].value)) {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {", " + props.children}
    </React.Fragment>
  );
}

function Menu(props) {
  let menuStyle = {
    width: props.selectProps.menuWidth,
    maxWidth: props.selectProps.menuMaxWidth,
    minWidth: props.selectProps.menuMinWidth,
    boxSizing: 'border-box',
  };

  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps} style={menuStyle}>
      {props.children}
    </Paper>
  );
}

const MenuList = props => {
  if (!props.children.length) {
    return props.children;
  }

  const rows = props.children;
  const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
    <div key={key} style={{...style, minWidth: 'max-content'}}>{rows[index]}</div>
  );

  return (
    <List
      style={{ width: props.selectProps.menuWidth, minWidth: (props.selectProps.menuMinWidth || '400px') }}
      width={1000}
      height={Math.min(rows.length * 46, 300)}
      rowHeight={46}
      rowCount={rows.length}
      rowRenderer={rowRenderer}
    />
  )
}

const OwnDropIndicator = (props) => {
  return <ArrowDropDown className={props.selectProps.classes.arrowdropdown} color="#4E8290" />;
}

/**
 * Defaultvalue and options.value have to be in same format (string or int).
 * Otherwise values will not be selected on list as default .
 */
class MultiSelect extends TaimerComponent {
  static contextType = SettingsContext;

  static defaultProps = {
    shownCount: 20,
    disabled: false,
    autoReset: false,
    showSingleValueInCaseOfOnlyOneOption: false,
    onOpenMenu: () => {},
    onCloseMenu: () => {},
  };

  constructor(props, context) {
    super(props, context, "general/MultiSelect");
    this.state = {
      width: 0,
      value: [],
      isAllSelected: false,
      isDefaultDone: false,
      inputValue: ''
    }

    this.refRoot = React.createRef();
    this.refSelect = React.createRef();
    this.paused = false;
  }

  componentDidMount() {
    if (this.refRoot.current) {
      this.setState({ width: this.refRoot.current.clientWidth });
    }

    if (this.props.autoReset) {
      this.handleChangeMulti(this.props.defaultValue);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.autoReset && prevProps.defaultValue !== this.props.defaultValue) {
      this.handleChangeMulti(this.props.defaultValue);
    }

    if (this.refRoot.current && this.refRoot.current.clientWidth !== this.state.width) {
      this.setState({ width: this.refRoot.current.clientWidth });
    }
  }


  ValueContainer = (props) => {
    let string = "";
    if (Array.isArray(props.children) && props.children[0] && Array.isArray(props.children[0])) {
      props.children[0].forEach((val, i) => {
        if (i == 0) string += val.props.children;
        else string += ", " + val.props.children;
      })
    }


    const inFocus =  this.refSelect.current ? this.refSelect.current.state.isFocused : false;
    const allText = props.selectProps.showSingleValueInCaseOfOnlyOneOption && props.options.length == 2 ? props.options[1]?.label : this.tr("All") + " "; 

    if (this.state.isAllSelected && !inFocus) return <p className={`${props.selectProps.classes.valueContainer} multiselect-valuecontainer`}>{allText}{props.children[1] && props.children[1]}</p>;
    return (
      <p className={`${props.selectProps.classes.valueContainer} multiselect-valuecontainer`}>
        {string}
        {props.children[1] && props.children[1]}
      </p>
    );
  }

  
  /**
   * Handles value changes and sets defaultValue
   *
   */
  handleChangeMulti = (value, skipOnChange = {}) => {
    let propsOptions = this.props.options;
    if (propsOptions && !this.props.noAllOptions) propsOptions = [{ value: "0", label: this.tr("All") }, ...propsOptions];
  
    const length = value ? value.length : 0;

    if (value && length > 0 && value[length - 1]?.value == 0) {
      let options = [];
      propsOptions.forEach((value, i) => {
        if (value.value != 0) options.push(value);
      });
      this.setState({value: options, isAllSelected: true});
      // if(!skipOnChange) {
      //   if(this.props.returnAll) this.props.onChange(this.props.options);
      //   else this.props.onChange([{value: "0", label: this.tr("All")}]);
      // }
      return;
    } else {
      if (length == (propsOptions.length - (!this.props.noAllOptions ? 1 : 0))) {
        let options = [];
        propsOptions.forEach((value, i) => {
          if (value.value != 0) options.push(value);
        });
        this.setState({value: options, isAllSelected: true});
        // if(!skipOnChange) {
        //   if(this.props.returnAll) this.props.onChange(this.props.options);
        //   else this.props.onChange([{value: "0", label: this.tr("All")}]);
        // }
        return;
      }

      if (!value)
        value = [];

      this.setState({ value: value, isAllSelected: skipOnChange.action === "clear" ? false : length === 0});
      //this.props.onChange(value);
    }
  }

  onInputChange = (inputValue, meta) => {
    if (meta.action === 'input-blur' || meta.action === 'set-value') {
      return;
    }
    this.setState({inputValue});
  }

  sendChange = () => {
    this.props.onCloseMenu();
    this.paused = true;
    
    setTimeout(() => {
      if (this.state.isAllSelected) {
        if (this.props.returnAll) this.props.onChange(this.props.options);
        else this.props.onChange([{ value: "0", label: this.tr("All") }]);
      } else {
        this.props.onChange(this.state.value);
      }

      this.unpause();
    }, 1);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.paused) {
      setTimeout( () => this.forceUpdate(), 300 );
    }

    return !this.paused;
  }

  unpause = () => this.paused = false;

  /**
   * Multiselect renderer
   *
   */
  render() {

    const { classes, className = "", placeholder = null, width, menuMaxWidth, reset, autoReset, disabled } = this.props;
    const { value, isDefaultDone } = this.state;
    const { tr } = this;
    let { options } = this.props;

    if (options && options.length > 0 && !this.props.noAllOptions) options = [{ value: "0", label: this.tr("All") }, ...options];

    let menuAutoWidth = this.props.menuWidth || 'auto';

    if (options && options.length > 0 && !this.props.menuWidth) {
      const len = Math.max(...options.map(x => String(x.label || "").length));

      menuAutoWidth = (len + 10) + "ch";
    }

    if (!isDefaultDone && options && !autoReset) {
      this.handleChangeMulti(this.props.defaultValue || [], {...this.props.skipInitialChange});
      this.setState({ isDefaultDone: true });
    }

    if (reset) {
      this.handleChangeMulti(this.props.resetValue);
      this.props.resetFunction();
    }

    const ClearIndicatorStyles = (base, state) => ({
      ...base,
      cursor: 'pointer',
    });

    let textFieldProps = {
      label: this.props.label ? this.props.label : "",
      className: "multiselect-root-div",
      InputLabelProps: {
        shrink: true,
        className: "multiselect-root-label",
      }
    };

    const components = {
      Control,
      Menu,
      MenuList,
      MultiValue,
      NoOptionsMessage,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer: this.ValueContainer,
      IndicatorSeparator: null,
      ClearIndicator: null,
      DropdownIndicator: OwnDropIndicator,
    };

    let rootstyle = {
      width,
    };

    return (
      <div ref={this.refRoot} className={`${classes.root} ${className}`} onMouseOver={this.unpause} style={rootstyle}>
        <Select
          ref={this.refSelect}
          classes={classes}
          styles={{ clearIndicator: ClearIndicatorStyles }}
          id="multiselect-root"
          value={value}
          options={options ? options : []}
          components={components}
          onChange={this.handleChangeMulti}
          onInputChange={this.onInputChange}
          inputValue={this.state.inputValue}
          isMulti
          menuWidth={menuAutoWidth}
          menuMaxWidth={menuMaxWidth}
          menuMinWidth={width || this.state.width}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          textFieldProps={textFieldProps}
          placeholder={placeholder}
          selectAllLabel={this.tr("All")}
          selectNoneLabel={this.tr("None")}
          noOptionsMessage={() => this.tr("No Options")}
          onMenuOpen={this.props.onOpenMenu}
          onMenuClose={this.sendChange}
          filterOption={createFilter({ignoreAccents: false})}
          isDisabled={disabled}
          showSingleValueInCaseOfOnlyOneOption={this.props.showSingleValueInCaseOfOnlyOneOption}
          quickSelectComponents={this.props.quickSelectComponents}
        />
      </div>
    );
  }
}


export default withStyles(styles)(MultiSelect);
