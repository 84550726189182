import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import _ from 'lodash';

/* material ui */
import { Button, Tooltip, Tabs, Tab } from '@mui/material';
import { Portal } from '@mui/base';

/* local components */
import TaimerComponent from "../TaimerComponent";
import TabInvoicing from "./TabInvoicing";
import TabCosts from "./TabCosts";
import TabFinancialOverview from "./TabFinancialOverview";
import InvoiceList from "./../list/lists/InvoiceList";
import ContextMenu from './../general/ContextMenu';
import OutlinedField from "./../general/OutlinedField";
import { ReactComponent as NoteIcon } from './images/note.svg';

/* data backend */
import DataHandler from './../general/DataHandler';

/* css */
import './TabFinance.css';

/* context */
import { SettingsContext } from './../SettingsContext';
import PageTopSection from '../general/PageTopSection';
import EmptyInvoiceConfirmationDialog from '../invoices/dialogs/EmptyInvoiceConfirmationDialog';

class TabFinance extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "projects/TabFinance");
        this.list = React.createRef();

        const invoiceRights = [{invoices: "write_simple"}, {invoices: "write_full"}].some(r => props.checkPrivilege(...Object.entries(r)[0], props.company));

        this.state = {
            selectedType: this.props.selectedType,
            projectId: this.props.entity == 'project' ? this.props.id : undefined,
            accountId: this.props.entity == 'account' ? this.props.id : undefined,
            projectIds: [],
            memo: false,
            notes: [],
            dataLoaded: false,
            currency: 'EUR',
            passedTabProps: {}
        }

        const navInUse = context.addons?.nav && context.addons?.nav.used_by_companies.indexOf(props.company) > -1;
        this.dialogs = {
            emptyInvoice: EmptyInvoiceConfirmationDialog,
		};

        this.componentMap = [
            {id: 5, component: TabFinancialOverview, showRule: ['account', 'project'], label: this.tr('Overview'), view: [{projects: "project_billing_entries_read"}, {projects: "project_actual_costs_read"}, {projects: "project_cost_estimate_read"}], edit: [], className: "overview"},
            {id: 4, component: InvoiceList, showRule: ['account', 'project'], label: this.tr('Invoiced'), view: [], edit: [], className: ""},
            {id: 1, component: TabInvoicing, showRule: ['project'], label: this.tr('Scheduled invoicing'), view: [{projects: "project_billing_entries_read"}], edit: ["projects", "project_billing_entries_write", props.company], className: ""},
            ...(!navInUse ? [{id: 2, component: TabInvoicing, showRule: ['project'], label: this.tr('Automatic invoice'), view: [{projects: "project_billing_entries_read"}], edit: ["projects", "project_billing_entries_write", props.company], className: ""}] : []),
            {id: 3, component: TabCosts, showRule: ['project'], label: this.tr('Costs'), view: [{projects: "project_actual_costs_read"}], edit: ["projects", "project_actual_costs_write", props.company], className: ""}
        ];

        this.tabComponent = React.createRef();

    }

    componentDidMount() {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate = (oldProps) => {
        if (oldProps.selectedType != this.props.selectedType) {
            this.setState({ selectedType: this.props.selectedType });
        }
    }

    updateComponentData = () => {
        const componentInfo = this.getTabComponent();
        const privilege = componentInfo.TabComponent == TabCosts ? "project_actual_costs_read" : "project_billing_entries_read";
        
        if (this.props.entity == 'account')
            DataHandler.get({ url: `accounts/overviewprojects/${this.state.accountId}/${this.props.company}`})
                .done(response => {this.setState({projectIds: response.map(r => r.id), dataLoaded: true})});
        else
            this.setState({dataLoaded:true});

        if ((this.props.entity == 'project' || this.props.entity == 'account') && componentInfo.TabComponent != InvoiceList) {
            DataHandler.get({url: `subjects/companies/projects/${privilege}`, currency: 1}).done(companies => {
                let c = false;
                companies.forEach(company => {
                    if(company.id == this.props.company) {
                        this.setState({currency: company.currency});
                        c = true;
                    }
                })
                if (!c && companies && companies.length > 0) {
                    this.setState({currency: companies[0].currency})
                }
                this.setState({companies})
            });
        }
    }    

    notesClicked = () => {
        const newEvent = new Event("notesClicked");
        document.dispatchEvent(newEvent);

        this.props.notesClicked();
    }

    updateTab = (selectedType) => {
        this.context.functions.updateView({selectedType});   
        const componentInfo = this.getTabComponent();

        if (this.props.entity == 'project' && componentInfo.TabComponent != InvoiceList) 
            this.updateComponentData();
    }

    getTabComponent = () => {
        const components = this.componentMap.filter(m => this.tabRights(m.id, 'view'));
        const selectedComponent = components.find(cm => cm.id == this.state.selectedType);
        const TabComponent = selectedComponent?.component; 
        return { components, selectedComponent, TabComponent };
    }

    tabRights = (tab, method) => {
        const {company, checkPrivilege } = this.props;

        const rights = this.componentMap.find(cm => cm.id == tab)[method];
        let result = false;
        result = rights.every(r => checkPrivilege(...Object.entries(r)[0], company));
        //hölmö poikkeus mutta write simple rights pitäisi ottaa huomioon tilanteet jolloin vaan write full on määritelty. eli siis tyyliin vähintään simple...
        if ([4, 5].includes(tab) && result)
            result = [{invoices: "write_simple"}, {invoices: "write_full"}].some(r => checkPrivilege(...Object.entries(r)[0], company));

        return result;
    }

    addInvoice = async (e) => {
        const { project } = this.props;
        this.context.functions.addInvoice({ project, company: project.companies_id, origin_point: "project_tab_finance" }, e?.ctrlKey || e?.metaKey || e?.button === 1);
    }
    
    openDialog = (name) => {
		this.setState({ currentDialog: name });
	}

	closeDialog = () => {
		this.setState({ currentDialog: false });
	}

	saveDialog = (saveFunc, data) => {
		this[saveFunc](data);
	}

    renderNoteToInvoiceCreator = () => {
        return (this.props.entity && this.props.entity === 'project' && this.props.project.message_for_invoicer && this.props.project.message_for_invoicer.length ? (
            <div className={`notes`}>
                <NoteIcon />
                {this.props.entity == 'project' && <span className="note-header">{this.tr('Note to invoice creator') + '.'} </span>}
                {this.props.entity == 'project' && (
                    <span className="note-link" onClick={this.notesClicked}>
                        {this.tr('View')}
                    </span>
                )}
            </div>
        ) : (
            this.props.entity == 'project' && (
                <span className="note-link" onClick={this.notesClicked}>
                    <NoteIcon />
                    <p>{this.tr('Note to invoice creator')}</p>
                </span>
            )
        ))
    }

    render () {
        const { id, company, checkPrivilege, canCreateInvoice } = this.props;
        const { statistics, selectedType, projectId, projectIds, memo, dialogOpen, dataLoaded, currentDialog, dialogData } = this.state;
        const { taimerAccount, versionId, addons } = this.context;

        const componentInfo = this.getTabComponent();
        const TabComponent = componentInfo.TabComponent;
        const selectedComponent = componentInfo.selectedComponent;
        const components = componentInfo.components;

        const Dialog = currentDialog ? this.dialogs[currentDialog] : undefined;

        return (
            <div id="projects-finance" className={this.componentMap.find((m) => m.id == selectedType).className}>
                {dataLoaded && TabComponent && (
                    <TabComponent
                        {...this.props}
                        // start={this.props.startDate}
                        // end={this.props.endDate}
                        projects_id={projectId}
                        projects_ids={projectIds}
                        noTabs
                        noHeader
                        renderNoteToInvoiceCreator={this.renderNoteToInvoiceCreator}
                        returnEmptyResult={this.props.entity == 'account' && projectIds.length < 1 ? true : false}
                        selectedType={selectedType}
                        key={selectedType}
                        ref={this.tabComponent}
                        currency={this.state.currency}
                        projectIdHandler={() => this.setState({ projectId: undefined })}
                        canCreateInvoice={canCreateInvoice}
                        {...this.state.passedTabProps}
                    />
                )}
                {Dialog && <Dialog
					open
					onDialogClose={this.closeDialog}
					onDialogSave={this.saveDialog}
					data={dialogData} />}
            </div>
        );
    }
}
TabFinance.propTypes = {
    id: PropTypes.string.isRequired,
};

export default TabFinance;
