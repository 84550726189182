import React from 'react';
import classNames from 'classnames';

import { SettingsContext } from './../../SettingsContext';
import OutlinedField from "./../../general/OutlinedField";
import { DatePicker } from './../../general/react-date-range/src';
import TaimerComponent from './../../TaimerComponent';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';

import { format } from "date-fns";

import './../../general/react-date-range/src/styles.css';
import './../../general/react-date-range/src/theme/default.css';

const styles = theme => ({
    topBar: {
        backgroundColor: '#2D9FF7',
        height: '8px',
        borderRadius: '4px 4px 0 0',
        marginBottom: '10px'
    },
    field: {
        marginBottom: "10px",
        width: "calc(100% - 14px)"
    },
    paper: {
        width: '360px'
    },
    datePicker: {
        width: '100%',
        boxSizing: 'border-box',
        minHeight: '56px',
        margin: '8px 0 4px'
    }    
});

class MarkAsPaidMultiple extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/dialogs/MarkAsPaidMultiple");

        this.state = {
            date: format(new Date(), 'YYYY-MM-DD')
        }
    
    this.datePicker = React.createRef();

    };

    onInputChange = (name, date) => {
        this.setState({date: format(new Date(date), 'YYYY-MM-DD')});
    };

    onDateChange = (date) => {
        this.setState({date: format(new Date(date), 'YYYY-MM-DD')});
    }

    handleCancel = () => {
        this.props.onDialogClose();
    };

    save = () => {     
        const { date } = this.state;
        this.props.onDialogSave(this.props.data.saveFunc, {
            action: 'mark_paid', 
            date: date,
            ids: this.props.data.ids,
            multiple: true});
    }

    render() {

        const { userObject } = this.context;

        const { data, classes } = this.props;
        const { ids } = data;

        const dateFormat = userObject.dateFormat;
        return (
            <Dialog
                open
                className="invoiceDialog"
                maxWidth="xs"
                classes={{paper: classNames(classes.paper)}}               
                aria-labelledby="markAsPaidSingle" >
                <div className={classNames(classes.topBar)}/>
                <DialogContent className="invoiceDialogContent" ref={cont => this.container = cont}>
                    <DatePicker
                        ref={this.datePicker}
                        label={this.tr("Date")}
                        className={`${classNames(classes.datePicker)} date`}
                        date={this.state.date}
                        dateFormat={dateFormat}
                        onChange={this.onDateChange}
                        onInputChange={this.onInputChange}
                        onClose={this.datePicker.handleToggle}
                        closeCalendarOnComplete={true}
                        closeOnComplete={false}
                    />
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="outlined" onClick={this.handleCancel} >
                        {this.tr("Cancel")}
                    </Button>
                    <Button size="large" variant="outlined" onClick={this.save} color="primary">
                        {this.tr("Ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

MarkAsPaidMultiple.defaultProps = {
    onDialogClose: () => {},
    onDialogSave: () => {},
    data: {total: '', paid: '', left: '', date: "1970-01-01"},
    dateFormat: "YYYY-MM-DD",
}

export default withStyles(styles)(MarkAsPaidMultiple);
