import FileSaver from 'file-saver';
import React from 'react';

import AdvancedSearch from "../../search/AdvancedSearch";
import DataHandler from "./../../general/DataHandler";
import Utils from "./../../general/Utils";
import List from "./../List";
import CustomerListRow from "./../rows/CustomerListRow";

import MenuItem from '@mui/material/MenuItem';
import OutlinedField from "./../../general/OutlinedField";

import CloudDownload from '@mui/icons-material/CloudDownload';
import withStyles from '@mui/styles/withStyles';
import { withSnackbar } from 'notistack';

import _ from 'lodash';
import "./CustomerList.css";

import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import AttachmentDialog from "../dialogs/HandleRowAttachments";

import ProductCatalogModalProvider from '../dialogs/ProductCatalogModalProvider';
import CreateSubUnitNotification from "./../../accounts/CreateSubUnitNotification";

/* context */
import { SettingsContext } from './../../SettingsContext';

import {
    getDimensionAutoCompleteData
} from "../../dimensions/helpers";

import TaimerComponent from '../../TaimerComponent';

import NoPermissionOverlay from '../overlays/NoPermissionOverlay';

/* validator */
import colors from '../../colors';
import PageTopSection from '../../general/PageTopSection';
import VersionContentManager from '../../general/VersionContentManager';

import CustomerListOverlay from "../overlays/CustomerListOverlay";

const styles = theme => ({
	buttonContainer: {
		display: 'flex',
		height: "47px",
		alignItems: "center"
	},
    button: {
        marginRight: "16px",
        textTransform: 'uppercase',
    },
    exportButton: {
        cursor: "pointer",
        color: "#6b7897",
        fontSize: "14px",
        minWidth: "180px",
        display: 'flex',
        alignItems: 'center',
        minWidth: '180px',
	},
    cloudDownload: {
    	margin: "0 6px 0 24px"
    }
});

class CustomerList extends TaimerComponent {
	static contextType = SettingsContext;

	constructor(props, context) {
		super(props, context, 'list/lists/CustomerList');

        let columnMap = [
			{ name: "name", hide: false, label: this.tr("Account") },
			{ name: "customership_group", hide: false, label: this.tr("Account Group") },
			{ name: "customer_type", hide: false, label: this.tr("Account Type") },
			{ name: "email",hide: false, label: this.tr("Email") },
			{ name: "telephone", hide: false, label: this.tr("Phone")},
			{ name: "www", hide: false, label: this.tr("WWW")},
			{ name: "user", hide: false, label: this.tr("Account Manager") },
			{ name: "branchofbusiness", hide: false, label: this.tr("Branch of Business") },		
        ]; 

		this.searchTerms = undefined;

		let preferedCompany = context.functions.getPreferedCompany(context.userObject.customer_read_companies.map(x => x.id));

		let initalCompany = context.userObject.customer_read_companies.find(el => el.id == preferedCompany);
        if(!initalCompany && context.userObject.customer_read_companies.length > 0) {
            initalCompany = context.userObject.customer_read_companies[0];
		}
		
		this.filtersInitialValues = {
			page: 1,
			perpage: props.perpage,
			customership_group: 0,
			enterprise_group: 0,
			customer_type: 0,
			lock_status: -1,
			team: 0,
			parent_company: -100,
            account_manager: -1,
			customer_unit: context.userObject.accountlistDefaultUnitFilter,
			company: initalCompany ? initalCompany.id : context.functions.getCompany("customers", "read", false, true),
		}
		
		this.defaultFilters = {
			customership_group: 0,
			enterprise_group: 0,
			customer_type: 0,
			lock_status: -1,
			team: 0,
            account_manager: -1,
			company: context.functions.getCompany("customers", "read", false, true),
		}

		const treeViewSettings = JSON.parse(localStorage.getItem("customerlist_treeview_settings")) || {  
            showTreeStructures: true, 
            showWholeTrees: true,
            showMatchesAndChildren: false
        };

		this.stickySearchKey = "account_list";
 
		this.state = { 
			...this.filtersInitialValues, 
			data: [], 
			pageCount: 1, 
			default_cus_type: 0,
			projectCount: "–", 
			showCrm: false, 
			hasAccounts: true,
			companies: context.userObject.customer_read_companies,	
			confirmationData: {},
			displayEnterpriseGroups: true,
			displayParentCompany: this.context.versionId == 4 ? true : false,
			displayUnit: this.context.versionId == 4 ? true : false,
			hasParentCustomers: true,
			accountDeleteDialogData: {
                open: false
            },
			...treeViewSettings,
			stickySearchInitialized: false,
			dimensions: [],
			dimensionItems: {},
			dimensionAutoCompleteData: {},
			dimensionAutoCompleteDataFilters: {},
			hasSubunitRights: false
		};

		this.getParams = {};
		this.lastGetParams = {};
		this.lastPostParams = {};

		this.initialFetchDone = false;
		this.searchMade = false;

		this.list 	  					= React.createRef();
		this.advancedSearch 			= React.createRef();
		this.pageChanged 				= this.pageChanged.bind(this);
        this.fetchData 					= this.fetchData.bind(this);
        this.setData 					= this.setData.bind(this);
        this.addRow 					= this.addRow.bind(this);
        this.changeDropSearch			= this.changeDropSearch.bind(this);
        this.lockCustomer				= this.lockCustomer.bind(this);
        this.unLockCustomer				= this.unLockCustomer.bind(this);
        this.deleteCustomer				= this.deleteCustomer.bind(this);
        this.sortRows					= this.sortRows.bind(this);
		this.openDialog          		= this.openDialog.bind(this);
    	this.confirmDialog       		= this.confirmDialog.bind(this);
		this.closeDialog         		= this.closeDialog.bind(this);
		this.filtersAreInInitialState   = this.filtersAreInInitialState.bind(this);
        this.initializeStickySearch     = this.initializeStickySearch.bind(this);
        this.saveStickySearch           = this.saveStickySearch.bind(this);
		this.createQuickGroup			= this.createQuickGroup.bind(this);
        this.initDimensions             = this.initDimensions.bind(this);


        this.is_parent_values = [
	        {id: 0, name: ''},
    	    {id: 1, name: this.tr('Emo')},
			{id: -1, name: this.tr('Tytär')},
			{id: 2, name: this.tr('Emo') + ", " + this.tr('Tytär')},
    	];
    	this.customer_units = [
            {id: "-100", name: this.tr('All')},
	        {id: "0", name: this.tr("Not in relation")},
    	    {id: "1", name: this.tr('Pääyksikkö')},
    	    {id: "2", name: this.tr('Aliyksikkö')}
		];

		this.dialogs = {
			confirmation: ConfirmationDialog,
			attachments: AttachmentDialog
		};

		this.lockedStatuses = [
			{id: '-1', label: this.tr('Active'), color: colors.greenish_cyan},
			{id: '1', label: this.tr('Locked'), color: "#f52b2b"}
		];

        this.userTypeAutocompleteClasses = [
            'account_managers',
            'account_managers_locked',
            'all_users',
            'all_users_locked',
            'employees',
        ];

		this.translations = {
			locked: this.tr("locked"),
			freelancer: this.tr("freelancer")
		};        

	}

	componentDidMount() {
		super.componentDidMount(); 
		this.initializeStickySearch();
		this.listenReset();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		this.unListenReset();
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.company != this.state.company) {
			this.fetchAutocompleteData({page: 1, perpage: this.state.perpage}, true);
		}
		if(prevState.showTreeStructures !== this.state.showTreeStructures || prevState.showWholeTrees !== this.state.showWholeTrees || prevState.showMatchesAndChildren !== this.state.showMatchesAndChildren) {
		    localStorage.setItem("customerlist_treeview_settings", JSON.stringify({
		        showTreeStructures: this.state.showTreeStructures,
		        showWholeTrees: this.state.showWholeTrees,
		        showMatchesAndChildren: this.state.showMatchesAndChildren
		    }));
		}

        if(prevState.company !== this.state.company) {
            this.initDimensions(this.state.company);
        }
	}

    async initDimensions(company) {
        if(this.context.addons?.dimensions?.used_by_companies?.indexOf(company) === -1) {
            this.setState({ 
                dimensions: [],
                dimensionItems: {},
                dimensionAutoCompleteData: {},
                dimensionAutoCompleteDataFilters: {},
            });

            return;
        }

        const data = await getDimensionAutoCompleteData(company);

        this.setState({ ...data }); 
    }

	pageChanged(page) {
		const pageConf = { page: page };

		// If the AdvancedSearch component is in advanced mode, we need to trigger the
		// search with the currently selected advanced filtering options and just add
		// the page number.
		if(this.advancedSearch.current.isInAdvancedMode())
			this.advancedSearch.current.triggerSearch(pageConf);
	}

	fetchAutocompleteData = async (update = {}, noTypeUpdate = false) => {
		//vanhan backendin url
		//{ module: "customers", action: "get_autocomplete_data", companies_id: this.state.company }
		const { userObject } = this.context;
		const params = { user: userObject.usersId, lang: userObject.language }
		
		const tagSettings =  await DataHandler.get({url: `settings/company/${this.state.company}/tagSettings`});

        DataHandler.get({url: `accounts/autocompletedata/${this.state.company}`, ...params}).done(data => {
            const userTagProps = {
                fields: {name: 'name'},
                showLocked: this.props.showLockedUsersWithTag,
                transl: this.translations
            };
            Object.keys(data).forEach(k => {
                if (this.userTypeAutocompleteClasses.includes(k)) {
                    data[k] = data[k].map(d => ({...Utils.showLockedAndFreelancerUserTag(d, userTagProps)}))
                }
            })

			this.autoCompleteData = data;
			this.autoCompleteData.is_parent_values = this.is_parent_values;
			this.autoCompleteData.customer_units = this.customer_units;
			this.autoCompleteData.teams = data.team_groups;
			this.autoCompleteData.customership_groups && this.autoCompleteData.customership_groups.unshift({id: 0, name: this.tr('All')});
			this.autoCompleteData.enterprise_groups && this.autoCompleteData.enterprise_groups.unshift({id: 0, name: this.tr('All')});
			this.autoCompleteData.customer_types && this.autoCompleteData.customer_types.unshift({id: 0, name: this.tr('All')});
			this.autoCompleteData.statuses = [...this.lockedStatuses];
			this.autoCompleteData.tagPoolSettingsData = tagSettings.tagPoolSettingsData;

			this.autoCompleteData.enterprise_groups.filter(eg => eg.deleted != '1').length < 2 && this.setState({displayEnterpriseGroups: false});
			this.setState({ hasParentCustomers: data.has_parent_customers, hasSubunitRights: data.has_subunit_rights });

			for (let i = 0; i < this.autoCompleteData.customer_types.length; i++) {
				if (this.autoCompleteData.customer_types[i].is_default == 1) {
					this.filtersInitialValues.customer_type = this.autoCompleteData.customer_types[i].id.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
					if (!noTypeUpdate)
						this.setState({ customer_type: (this.filtersInitialValues.customer_type) }, () => this.fetchData({ ...update }));
				}
			}
			if (noTypeUpdate)
				this.fetchData({ ...update });
		});
	}

	// When the list is initialized, we use this method to fetch the initial data.
	fetchData(override = {}, useLastParams = false, callback = undefined) {
		this.list.current && (!override.dontResetfilters || this.searchMade)  && this.list.current.resetCheckedRows();
        this.list.current && this.list.current.setState({ isLoading: true});

		let parameters = { page: 1, companies_id: this.state.company || '' };
		parameters = {...parameters,  ...this.filtersInitialValues};
		
		parameters = {...parameters, 
			showTreeStructures: Boolean(this.state.showTreeStructures),
            wholeTrees: this.state.showTreeStructures && this.state.showWholeTrees,
            matchesAndChildren: this.state.showTreeStructures && this.state.showMatchesAndChildren};
		let postParams = {};
		if(useLastParams) {
			parameters = _.cloneDeep(this.lastGetParams);
			postParams =  _.cloneDeep(this.lastPostParams);
		} else {
			for(let gp in this.getParams)
				parameters[gp] = this.getParams[gp];
			if(this.searchTerms !== undefined) {
				parameters.mode = this.searchTerms.mode;
				if(this.searchTerms.mode == 'advanced') {
					postParams.advanced_search_criteria = JSON.stringify(this.searchTerms.advanced_search_criteria);
				} else if (this.searchTerms.mode == 'freetext') {
					postParams.freetext = this.searchTerms.freetextSearchTerm;
				}
			}
		}
		parameters.perpage = this.state.perpage;

		for(let oi in override)
			parameters[oi] = override[oi];
		this.lastGetParams =  _.cloneDeep(parameters);
		this.lastPostParams =  _.cloneDeep(postParams);

		if (override.get_all_ids) 
			return DataHandler.post({url: `accounts/get_all`}, {...parameters, ...postParams});

		if(parameters.page !== this.state.page) {
			this.setState({ page: parameters.page }); 
		}

		this.saveStickySearch(parameters);
		this.searchMade = false;

		DataHandler.post({url: `accounts/get_all`}, {...parameters, ...postParams})
		.done(
			callback ? callback :
				async data => {
					this.setData(data);
                    this.list.current && this.list.current.setState({ isLoading: false});
				}
		)
		.fail(data => {

		});
	}

	initializeStickySearch() {
        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` }).done((response, _, request) => {
            if(request.status !== 200) {
				this.fetchAutocompleteData({page: this.filtersInitialValues.page, perpage: this.filtersInitialValues.perpage });
                return;            
			}

			if (!this.context.userObject.customer_read_companies.find(el => el.id == response.company)) {
				response.company = this.filtersInitialValues.company;
			}
			
			if (response.searchTerms)
				this.searchTerms = response.searchTerms;

			for(let key in response) {
				this.getParams[key] = response[key];			
			}

            this.setState({ ...response }, () => this.fetchAutocompleteData({...response, page: response.page || 1, perpage: response.perpage || this.filtersInitialValues.perpage}, true));
        }).fail(response => {
			this.fetchAutocompleteData({page: this.filtersInitialValues.page, perpage: this.filtersInitialValues.perpage });

        }).always((response, _, request) => {
            this.setState({ stickySearchInitialized: true });
        });
    }


    saveStickySearch(filters) {
		let stateToSave = _.cloneDeep(filters);
		delete stateToSave.companies_id;
		delete stateToSave.dontResetfilters;

		stateToSave.searchTerms = this.searchTerms;
		stateToSave.sort = this.state.sort;

        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}`, }, { search: stateToSave });
    }

    filtersAreInInitialState() {
		const initial = _.cloneDeep(this.filtersInitialValues);
		delete initial.page;
		delete initial.perpage;
		const filters = {};

        for(let key in initial) {
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = JSON.stringify(this.state[key]);
        }

        const freetext = this.searchTerms ? this.searchTerms.freetextSearchTerm : "";

        return _.isEqual(initial, filters) && !freetext;
    }

	_resetFilters = (evt) => {
		if (!evt || evt.keyCode == '27') {
			this.advancedSearch.current.clearSearch(evt, true);
			this.advancedSearch.current.clearSearchTextInput();

			this.searchTerms = undefined;
			this.getParams = {};

			this.setState({
				...this.filtersInitialValues,
				sort: undefined
			}, () => this.fetchData({ page: 1 }));
		}
	}

	setData(data) {
		if(data === undefined) {
			return;
		}

		const customers 	= data.customers;
		const pageCount    	= data.customers_page_count;
		const customerCount = data.customer_count;
		const userObject	= this.context.userObject;
		let showCrm		= this.state.showCrm;
		if(data.show_crm == userObject.companies_id){
			showCrm = true;
		}
		this.setState(state => {
			state.showCrm = showCrm;
			state.data = !customers !== undefined && customers.length > 0 ? customers : [];
			state.pageCount = pageCount !== undefined ? pageCount : 1;
			state.customerCount = customerCount !== undefined ? customerCount : 0;
			state.hasAccounts = data.has_accounts > 0;
			return state;
		}, () => {
            this.list.current && this.list.current.endPageChangeAnimation();

            this.initialFetchDone = true;
        });
	}

	sortRows(columnName, isAscending) {
		let sortObj = {name: columnName, asc: isAscending};
		this.state.sort = sortObj;
		this.getParams.sort = sortObj;
		this.fetchData({ page: 1, dontResetfilters: true });
	}


	addRow() {
		const { addons } =this.context;
		if (addons.customers && addons.customers.limit && addons.customers.used >= addons.customers.limit) {
            this.context.functions.showUpgradeSlider();
            return;
        }
		this.list.current.addNewRow();
	}

	fetchAllIds = async () => {
		const data = await this.fetchData({get_all_ids: 1}, true);
		return data.all_customer_ids;
	}

	getSelectedRows = async () => {
		const allCheckedExcept = this.list.current.getAllCheckedExcept();
		let selected;

        if(allCheckedExcept) {
			const ids = await this.fetchAllIds();
			selected = ids.filter(id => {
				return !allCheckedExcept[id]; 
			});
        } else {
			selected = this.list.current.getCheckedRows();
		}

		return selected;
    }

	async exportExcel(target, fields) {
		if(!this.state.data || this.state.data.length < 1) {
			this.props.enqueueSnackbar(this.tr("Nothing to export!"), {
                variant: "warning",
            });
            return;
		}

		const columns = this.list.current.visibleColumnsOrder;
		const exportHeaders = [];        
		let params   = {};
		let selected = await this.getSelectedRows();

		if (selected.length === 0) {
            selected = await this.fetchAllIds();
        }
				
		_.forEach(columns, column => {
			_.forEach(fields, field => {
				if(column == field.name && column != "context" && column != "checked"){
                    exportHeaders.push(field.header);
                }
			})
		})

		params.order = columns;
        params.columnNames = exportHeaders;
        params.statusNames = { "-1": this.tr("Active"), "1": this.tr("Locked") };
		params.ids = selected;
		params.file_name = this.tr("accounts_list_export");
		params.sort = this.state.sort;

		DataHandler.postArrayBuffer({url: "accounts/list_export", export: target, companies_id: this.state.company}, params, true).done(response => {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            FileSaver.saveAs(blob, `${params.file_name}.${target}`);
            this.list.current && this.list.current.setState({ isLoading: false});
        });
	}

	createQuickGroup() {

	}

	removeQuickGroup() {

	}

	lockCustomer(data, selectNewAccount) {
		const { company } = this.state;

		if (data.productAmount > 0 && selectNewAccount) {
			DataHandler.post({url: `products/supplier/assign_new/${data.companies_id}`}, {oldSupplier: data.orig_id, newSupplier: selectNewAccount.supplier, newAddress: selectNewAccount.address});
		}
		else if (data.productAmount > 0) {
			DataHandler.delete({url: `products/supplier/${data.orig_id}`});
		}

		const update = {
			customers_id: data.orig_id,
			companies_id: company, 
			locked: 1,
			relations_id: data.relations_id || data.orig_id,
			type_id: data.customer_types_id,
			only_locked_update: 1
		}
		DataHandler.put({ url: `accounts/relations/${data.relations_id ? data.relations_id : data.id}`, data: update }).done(() => {
			this.fetchData({page: this.state.page});
		});

		this.closeAccountDeleteDialog();
	}

	unLockCustomer(data) {
		const { company } = this.state;
		const update = {
			customers_id: data.orig_id,
			companies_id: company, 
			locked: -1,
			relations_id: data.relations_id || data.orig_id,
			type_id: data.customer_types_id,
			only_locked_update: 1
		}
		DataHandler.put({ url: `accounts/relations/${data.relations_id ? data.relations_id : data.id}`, data: update }).done(() => {
			this.fetchData({page: this.state.page});
		});
	}

	deleteCustomer(data) {
        DataHandler.delete({url: `accounts/deleteAccount/${data.orig_id}/companies_id/${data.companies_id}`}).done(() => {
			this.fetchData({ page: 1 });

			this.props.enqueueSnackbar(this.tr("Account \"${account}\" deleted succesfully!", {account: `${data.name} (${this.context.addons.custom_customer_id && this.context.addons.custom_customer_id.used_by_companies.indexOf(data.companies_id) > -1 ? data.customer_id : data.orig_id})`}), {
				variant: "success" 
			});
		});
	}

	changeDropSearch(event) {
		let name  = event.target.name;
		let value = event.target.value;
		this.getParams[name] = value;
		const newState = {};
		newState[name] = value;

		if (name === "company") {
			this.context.functions.setLastCompany(value);
		}
 
		this.setState(newState, () => this.fetchData({ page: 1 }));
	}

    listenReset = () => {
       document.body.addEventListener("keyup", this._resetFilters);
    }

    unListenReset = () => {
       document.body.removeEventListener("keyup", this._resetFilters);
    }

	openDialog(name, callback = null) {
		this.setState({ currentDialog: name, savedCallback: callback });
	}


	confirmDialog(saveFunc, id) {
		saveFunc(id);
		this.closeDialog();
	}

	closeDialog = () => {
		this.setState({currentDialog: false, dialogData: undefined, savedCallback: null});
	}

	callAttachmentDialog(id) {
		this.setState({
			dialogData: {
				id: id,
				targetModule: "accounts"
			}
		}, () => this.openDialog('attachments'))
	}

	closeAccountDeleteDialog = () => {
        this.setState({ accountDeleteDialogData: { ...this.state.accountDeleteDialogData, open: false } });
    }

    openAccountDeleteDialog = (data, action, confirmFunc) => {
		DataHandler.get({url: `products/supplier/${data.orig_id}/productamount`, type: data.customer_types_id, company: data.companies_id}).done(response => {
			data.productAmount = Number(response.productAmount);
			
			this.setState({ accountDeleteDialogData: { open: true, data, action, onClose: this.closeAccountDeleteDialog, onConfirm: confirmFunc } })
		});  
	}

    showCreateSubUnitNotification = (customerId, customerName, companyId) => {
        const { 
            enqueueSnackbar, 
            closeSnackbar 
        } = this.props;

        const snackbarId = enqueueSnackbar(null, {
            persist: true,
            preventDuplicate: true,
            content: key => {
                return (
                    <CreateSubUnitNotification 
                        onCreateClick={() => {
                            closeSnackbar(snackbarId);

                            this.context.functions.addAccount({
                                main_unit: customerId,
                                companies_id: companyId,
								parentname: customerName,
                                origin_point: "customer_list_sub_unit_notification"
                            });
                        }}
                        onDismissClick={() => closeSnackbar(snackbarId)}
                    />
                );
            }
        });
    };
	
    accountDelete = (data, selectNewAccount) => {
		if (data.productAmount > 0 && selectNewAccount) {
			DataHandler.post({url: `products/supplier/assign_new/${data.companies_id}`}, {oldSupplier: data.orig_id, newSupplier: selectNewAccount.supplier, newAddress: selectNewAccount.address});
		}
		else if (data.productAmount > 0) {
			DataHandler.delete({url: `products/supplier/${data.orig_id}`});
		}

		this.deleteCustomer(data);
		this.closeAccountDeleteDialog();
	};

	getFields = () => {
		const { userObject, taimerAccount: {preventCompanySpecificAutocompleteData}, functions: { checkPrivilege, checkPrivilegeAny }, addons } = this.context;
		const customCustomerIdOn = addons.custom_customer_id && addons.custom_customer_id.used_by_companies.indexOf(this.state.company) > -1 ? true : false;
		const hideCompanySpecificFields = this.context.taimerAccount.preventCompanySpecificAutocompleteData && userObject.companies_id != this.state.company && !checkPrivilege("customers", 'read', this.state.company);
		let fields = [
			{ field: "context", name: "context", header: "", columnHeaderType: checkPrivilege("customers", "write") && "roundButton", width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, showResizeMarker: false },
			/*{ field: "expand", name: "expand", header: "", content: (<div style={{ position: "relative", height: "100%" }}>
					<div onClick={() => this.list.current.addNewRow()} style={{ cursor: "pointer", width: "24px", height: "24px", borderRadius: "12px", background: "#50e3c2", textAlign: "center", lineHeight: "24px", fontSize: "20px", color: "#fff", position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px" }}>+</div>
				</div>), width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },*/
			{ field: "checked", name: "checked", header: "", columnHeaderType: "checkbox", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
			{ field: "id", name: "id", header: customCustomerIdOn ? this.tr("Account Id") : this.tr("Account Nr."), width: 122, resizeable: false, moveable: false },
			... (this.context.addons.nav ? [{ field: "wintime_id", name: "wintime_id", header: this.tr("NAV ID"), width: 120 }] : []),
			... (this.context.addons.mediapro ? [{ field: "mediapro_id", name: "mediapro_id", header: this.tr("Mediapro ID"), width: 120 }] : []),
			{ field: "name", name: "name", header: this.tr("Account"), width: 400 },
			...(userObject.customer_read_companies.length > 1 ? [{ field: "company", name: "company", header: this.tr("Company"), width: 200 }] : []),
			{ field: "vatid", name: "vatid", header: this.tr("Business ID"), width: 160 },
			{ field: "customership_group", name: "customership_group", header: this.tr("Account Group"), width: 160, hidden: hideCompanySpecificFields },
			{ field: "enterprise_group", name: "enterprise_group", header: this.tr("Enterprise Group"), width: 160, 
				hidden: VersionContentManager.isFeatureHidden(this.namespace, 'enterpriseGroups') || (this.state.displayEnterpriseGroups && hideCompanySpecificFields) },
			{ field: "customer_type", name: "customer_type", header: this.tr("Account Type"), width: 150 },
	                { field: "locked", name: "locked", header: this.tr("Status"), width: 175 },
			... (this.state.displayParentCompany && !VersionContentManager.isFeatureHidden(this.namespace, 'subUnits') ? [{ field: "is_parent", name: "is_parent", header: this.tr("Parent Company"), width: 100 }] : []),
			... (this.state.displayUnit && !VersionContentManager.isFeatureHidden(this.namespace, 'subUnits') ? [{ field: "customer_unit", name: "customer_unit", header: this.tr("Unit"), width: 120 }] : []),
			{ field: "email", name: "email", header: this.tr("Email"), width: 150 },
			{ field: "telephone", name: "telephone", header: this.tr("Phone"), width: 120 },
			{ field: "www", name: "www", header: this.tr("WWW"), width: 120 },
			{ field: "account_manager", name: "account_manager", header: this.tr("Account Manager"), width: 150, hidden: hideCompanySpecificFields },
			{ field: "branchofbusiness", name: "branchofbusiness", header: this.tr("Branch of Business"), width: 160 },
			{ field: "tags", name: "tags", header: this.tr("Tags"), width: 150, hidden: hideCompanySpecificFields },
			{ field: "address", name: "address", header: this.tr("Address"), width: 300 },
			{ field: "postalcode", name: "postalcode", header: this.tr("Postal code"), width: 130 },
			{ field: "city", name: "city", header: this.tr("City"), width: 180 },
			{ field: "country", name: "country", header: this.tr("Country"), width: 180 },
			/* TAIM9-110  { field: "last_visit", name: "last_visit", header: this.tr("Last visit", width: 200 }, */
        ];

        let searchFields = [
			{ field: "id", name: "id", header: this.tr("Nr."), width: 61, resizeable: false, moveable: false },
			... (this.context.addons.nav ? [{ field: "wintime_id", name: "wintime_id", header: this.tr("NAV ID") }] : []),
			... (this.context.addons.mediapro ? [{ field: "mediapro_id", name: "mediapro_id", header: this.tr("Mediapro ID") }] : []),
			{ field: "name", name: "name", header: this.tr("Account"), visualizationType: "tree" },
			{ field: "vatid", name: "vatid", header: this.tr("Business ID") },
			{ field: "email", name: "email", header: this.tr("Email") },
			{ field: "telephone", name: "telephone", header: this.tr("Phone") },
			{ field: "address", name: "address", header: this.tr("Address") },
			{ field: "postalcode", name: "postalcode", header: this.tr("Postal code") },
			{ field: "city", name: "city", header: this.tr("City") },
			{ field: "country", name: "country", header: this.tr("Country") },
			{ field: "www", name: "www", header: this.tr("WWW") },
			...(!hideCompanySpecificFields ? [{ field: "account_manager", name: "account_manager", header: this.tr("Account Manager"), entityMode: true, noSort: true }] : []),
			...(!hideCompanySpecificFields ? [{ field: "team", name: "team", header: this.tr("Teams") }] : []),
			{ field: "branchofbusiness", name: "branchofbusiness", header: this.tr("Branch of Business") },
			...(!hideCompanySpecificFields ? [{ field: "customer_team", name: "customer_team", header: this.tr("Account Team") }] : []),
			...(!hideCompanySpecificFields ? [{ field: "tags", name: "tags", header: this.tr("Tags") }] : [])
		];
		if(customCustomerIdOn) {
			fields.splice(3, 0, { field: "customer_id", name: "customer_id", header: this.tr("Account: Nr.") , width: 122, resizeable: true, moveable: true });
			searchFields.splice(1, 0, { field: "customer_id", name: "customer_id", header: this.tr("Account Nr."), width: 122, resizeable: false, moveable: false });
		} 

        if(this.state.showCrm) {
        	const crmFields = [
        		//{ field: "company_created", name: "company_created", header: this.tr("Created") },
        		//{ field: "crm_created", name: "crm_created", header: this.tr("CRM created"), width: 200 },
				//{ field: "crm_date", name: "crm_date", header: this.tr("CRM date"), width: 200 },
				//{ field: "crm_type", name: "crm_type", header: this.tr("CRM type") },
				//{ field: "crm_desc", name: "crm_desc", header: this.tr("CRM desc") },
        	];
        	fields = fields.concat(crmFields);
        	searchFields = searchFields.concat(crmFields)
		}

		const customFields = [];

		this.autoCompleteData.custom_fields.forEach((v) => {
            if (v.show_in_details) {
                fields.push({
                    field: `custom_${v.id}`,
                    name: `custom_${v.id}`,
                    header: v.name,
                    width: 140,
                    type: v.type == 'date' ? 'date' : 'text',
                });
				searchFields.push({
                    field: `custom_${v.id}`,
                    name: `custom_${v.id}`,
					transl: v.name,
					tagTransl: v.name,
                    header: v.name,
                    type: v.type == 'date' ? 'date' : 'text',
                });

				customFields.push(v.id);
            }
        });

		return {searchFields, fields, customFields};
	}

	renderSummarySection = () => {
		const { functions: { checkPrivilege }} = this.context;
		const { fields } = this.getFields(); 
		return (
			<PageTopSection 
			summaries={[{ title: this.tr("Accounts"), value: this.state.customerCount || 0 }]}
			settingsButton={{
				isVisible: !(null == this.context.privileges.admin),
				title: this.tr("Settings"),
				href: this.context.functions.urlify({ module: 'settings', action: 'index', group: 'features', page: 'accounts' }),
				action: () => this.context.functions.updateView({module: 'settings', action: 'index', group: 'features', page: 'default'}, false)
			}} mainButtons={[
				{
					title: this.tr("ADD ACCOUNT"),
					action: (e) => this.context.functions.addAccount({origin_point: "customer_list"}),
					isVisible: checkPrivilege("customers", "write"),
					"data-testid": 'add-account-button'
				}
			]} additionalButtons={[
				{
					title: this.tr("EXPORT"),
					icon: <CloudDownload />,
					action: () => this.exportExcel('xlsx', fields)
				}
			]} />
		);
	}

	render() {
		if(!this.state.stickySearchInitialized) {
            return null;
        }

        const { companies, company } = this.state;
		const { userObject, functions: { checkPrivilege, checkPrivilegeAny, companyUsesAddOn }, addons } = this.context;
		if (userObject.customer_read_companies.length < 1) {
            return <div>{<List showOverlay={true} overlayComponent={NoPermissionOverlay}/>}</div>
        }

		if(!this.autoCompleteData) {
			return <div></div>;
		}
		const {fields, searchFields, customFields} = this.getFields(); 

        let newRow = {
        	name: "",
        	account_manager: this.context.userObject.usersId,
			locked: "-1",
            _customerIdIsDuplicate: false,
			_customerIdFieldIsEmpty: false
        };
        if(this.context.taimerAccount.forceCustomerShipGroup) {
        	let selectableCShipGroups = this.autoCompleteData.customership_groups.filter(el => el.deleted != 1 && el.id != 0);
        	if(selectableCShipGroups.length == 1) {
        		newRow.customership_groups_id = selectableCShipGroups[0].id;
        	}

        }

        const button = {
			className: 'list-menu'
		}

		const SelectProps = {
			MenuProps: {
				onEntering: () => this.unListenReset(),
				onExited: () => this.listenReset()
			}
		}

		const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
		const hideCompanySpecificFields = this.context.taimerAccount.preventCompanySpecificAutocompleteData && userObject.companies_id != this.state.company && !checkPrivilege("customers", 'read', this.state.company);


			
		return (
			<div data-testid="customers-list" className="contentBlock" style={{ background: "#fff" }}>
							<div className="listControlsContainer clearfix">
					<div className="header-container primary">
					{/* <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" buttonProps={button} shrinkLabel={false} label={this.tr("Options")} select>
		                <MenuItem onClick={() => this.exportExcel()}>{this.tr('Export excel')}</MenuItem>
		            </OutlinedField>
                 */}            
                            {companies.length > 1 &&
                            	<OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Company")} name="company"  value={company} select onChange={this.changeDropSearch}>
                                { [{id: 0, name: this.tr("All")}, ...companies].map(row => (
                                    <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                ))}
                            </OutlinedField>
                        	}
                            
                            <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Account Type")} name="customer_type"  value={this.state.customer_type} select onChange={this.changeDropSearch}>
                                {this.autoCompleteData.customer_types.map(row => {	
                                    if (row.deleted == undefined || row.deleted < 1)
                                        return (
                                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
									    )
								})}
                            </OutlinedField>
                            <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Account Status")} name="lock_status"  value={this.state.lock_status} select onChange={this.changeDropSearch}>
                                <MenuItem key={0} value={0}>{this.tr('All')}</MenuItem>
                                <MenuItem key={-1} value={-1}>{this.tr('Open')}</MenuItem>
                                <MenuItem key={1} value={1}>{this.tr('Locked')}</MenuItem>
                            </OutlinedField>
							{
								!hideCompanySpecificFields && <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Account Group")} name="customership_group"  value={this.state.customership_group} select onChange={this.changeDropSearch}>
                                { this.autoCompleteData.customership_groups.filter(x => x.deleted != 1).map(row => (
                                    <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                ))}
                            </OutlinedField>
                        }
							{this.state.displayEnterpriseGroups && !hideCompanySpecificFields && !VersionContentManager.isFeatureHidden(this.namespace, 'enterpriseGroups') && <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Enterprise Group")} name="enterprise_group"  value={this.state.enterprise_group} select onChange={this.changeDropSearch}>
                                { this.autoCompleteData.enterprise_groups.filter(x => x.deleted != 1).map(row => (
                                    <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                ))}
                            </OutlinedField>}
                            {this.state.displayParentCompany && this.state.hasParentCustomers && !VersionContentManager.isFeatureHidden(this.namespace, 'subUnits') && <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Parent Company")} name="parent_company"  value={this.state.parent_company} select onChange={this.changeDropSearch}>
                                { [{id: -100, name: this.tr('All')},...this.is_parent_values].map(row => (
                                    row.id !== 2 && <MenuItem key={row.id} value={row.id}>{row.id === 0 ? this.tr("Not in relation") : row.name}</MenuItem>
                                ))}
                            </OutlinedField>}
                            {this.state.displayUnit && this.state.hasSubunitRights && !VersionContentManager.isFeatureHidden(this.namespace, 'subUnits') && <OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Customer unit")} name="customer_unit"  value={this.state.customer_unit} select onChange={this.changeDropSearch}>
                                { this.customer_units.map(row => (
                                    <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                ))}
                            </OutlinedField>}
                            {/*<OutlinedField SelectProps={SelectProps} className="listFilterOutlinedField" label={this.tr("Account Manager")} name="account_manager"  value={this.state.account_manager} select onChange={this.changeDropSearch}>
                                { [{id: "-1", value: "-1", name: this.tr("All"), label: this.tr("All"), deleted: "0"}, {id: "0", value: "0", name: this.tr("No account manager"), label: this.tr("No account manager"), deleted: "0"}, ...this.autoCompleteData.account_managers].map(row => (
                                    <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                ))}
                                </OutlinedField>*/}
                            <AdvancedSearch
								ref={this.advancedSearch}

                                mode={this.searchTerms && this.searchTerms.mode ? this.searchTerms.mode : undefined}
								initialFilters={this.searchTerms ? this.searchTerms.currentFilters : undefined}
                                mainConfig={this.searchTerms && this.searchTerms.advanced_search_criteria ? { operator: this.searchTerms.advanced_search_criteria.operator } : undefined} 
                                freetextLabel={this.searchTerms ? this.searchTerms.freetextSearchTerm : ""}
                                alwaysShowClearFilters={!this.filtersAreInInitialState()}
								onClearSearch={this._resetFilters}
									
								fields={searchFields.filter(f => f.field !== "expand" && f.field !== "checked").map(f => { return { field: f.field, transl: f.header, tagTransl: f.tagTransl, type: f.type, entityMode: (f.entityMode ? f.entityMode : false), noSort: (f.noSort ? f.noSort : false), visualizationType: f.visualizationType || "list" }})}
                                onSearchResult={this.setData}
                                key={this.state.showCrm ? "crm_true" : "crm_false"}
                                perpage={this.state.perpage}
                                noRequests={true}
                                onSearchTrigger={(searchTerms) => {
										this.searchTerms = searchTerms;
										this.searchMade = true;
										this.list.current.setPage(1);
								}}
                                autoCompleteData={{
										name: [this.autoCompleteData.customers, "parent_id"],
                                        account_manager: this.autoCompleteData.account_managers ? [{id: "0", value: "0", name: this.tr("No account manager"), label: this.tr("No account manager"), deleted: "0"}, ...this.autoCompleteData.account_managers, ...this.autoCompleteData.account_managers_locked] : [],
                                        tags: this.autoCompleteData.tags,
										team: this.autoCompleteData.teams,
										customer_team: this.autoCompleteData.employees
                                }}
                                />
                        </div>
						{this.renderSummarySection()}
                    </div>
                    <List
                        ref={this.list}
                        data={this.state.data}
                        columns={fields}
                        height="fitRemaining"
                        className="customerList"
                        listRowType={CustomerListRow}
                        newRow={newRow}
                        key={this.state.showCrm ? "crm_true" : "crm_false"}
                        parentKey="parent_id"
						treeData={true}
						manualCreate={true}
                        parentsExpandedOnInit={true}
                        showOverlay={!this.state.hasAccounts}
                        noStateData={true}
						saveColumnConfig={true}
						useAllCheckedExcept={true}
                        showNoResultsMessage={this.initialFetchDone}
                        overlayComponent={CustomerListOverlay}
                        userListSettingsKey={customFields.length === 0 ? "customer_list" : `customer_list_${company}`}
						ignoreRowPropsChange={false}
                        rowProps={{
							sharedData: this.autoCompleteData,
							addons: addons,
                            callAttachmentDialog: id => this.callAttachmentDialog(id),
                            onCreate: data => {
                                data.types = [{ id: data.customer_types_id }];
                                data.invoicing_addresses = [{id: -1}];
								data.delivery_addresses = [{id: -1}];   
								data.custom = {};
								for(let i in data) {
									if(i.substr(0, 7) !== "custom_")
										continue;
									let id    = i.split("_")[1];
									let value = data[i];
									data.custom[id] = value && typeof value === "object" && value.hasOwnProperty("id") ? value.id : value
								}
								DataHandler.post({ url: `accounts/company/${company}` }, data).done(response => {
									this.list.current.removeNewRow(data.id);
									this.props.enqueueSnackbar(this.tr("Account \"${account}\" created succesfully!", {account: `${data.name} (${this.context.addons.custom_customer_id && this.context.addons.custom_customer_id.used_by_companies.indexOf(data.companies_id) > -1 ? response.customer_id : response.id})`}), {
										variant: "success" 
									});

									this.fetchAutocompleteData();

									if(this.context.functions.hasPrivilege('customers', 'unit_write') && companyUsesAddOn("mediapro", company)) {
										this.showCreateSubUnitNotification(response.id, data.name, company);
									}
									return response;
								}).fail(e => {
									const resp = e.responseJSON;
									if (resp.error == 'fields_missing') {
										this.props.enqueueSnackbar(`${this.tr('Required fields not filled:')} ${(resp.missing || []).join(", ")}`, {
											variant: "error",
										});
										data.error = e;
										return data;	
									} else {
										this.props.enqueueSnackbar(this.tr("An account is already using custom id ${id}.", {id: data.customer_id}), {
											variant: "error" 
										});
										data.error = e;
										return data;
									}
								});
                            },
                            onUpdate: data => {		
								data.id = data.orig_id;
								if (data.delivery_addresses) 
									delete data.delivery_addresses;
								if (data.invoicing_addresses) 
									delete data.invoicing_addresses;

								data.updateTags = true;
                                return DataHandler.put({ url: `accounts/${data.orig_id}/company/${data.companies_id || this.state.company}`}, data).then(() => {
									//prevent updated data from disappearing on data fetch. TAIM9-1918
									!data.newRow && setTimeout(() => {
										this.fetchData({page: this.state.page});
									}, 1000); 
								});
                            },
                            onDelete: (data) => {
                                if(data.id < 0)
                                    this.list.current.removeRow(data.id);
                                else {
									this.openAccountDeleteDialog(data, "delete", this.accountDelete);
								}
                            },
                            unLockCustomer: this.unLockCustomer,
							lockCustomer: (data) => this.openAccountDeleteDialog(data, "lock", this.lockCustomer),
							enqueueSnackbar: this.props.enqueueSnackbar,
							statusLabels: { "-1": this.tr("Active"), "1": this.tr("Locked") },
							openDialog: this.openDialog,
							company: this.state.company,
                            refreshList: () => {
                                this.fetchData();
							},
							updateTagPool: (tag) => {
								this.autoCompleteData.tags.push(tag);
							},
							companies: companies
                        }}
                        onPerPageChange={perpage => {
                            this.list.current.startPageChangeAnimation();
                            this.setState({ page:1, perpage }, () => this.fetchData({ page: 1, perpage, dontResetfilters: true }));
                        }}
                        onSortRows={this.sortRows}
                        showPageSelector={true}
                        pageCount={this.state.pageCount}
                        totalCount={this.state.customerCount}
						perpage={this.state.perpage}
						controlPage={true}
						page={this.state.page}
                        onPageChange={page => { 
							this.list.current.startPageChangeAnimation(); 
							this.setState({ page }, () => this.fetchData({ page, perpage: this.state.perpage, dontResetfilters: true }));
						}}
						useHSRightPadding               
                    />
				{Dialog && <Dialog
					open
					onDialogClose={this.closeDialog}
					onDialogSave={this.confirmDialog}
					data={this.state.dialogData}
					/>}

				{this.state.currentDialog === "lock" ? 
				<ConfirmationDialog 
					data={{ text: this.tr("Do you really want to lock this account?") }} 
					onDialogSave={()=> {
						this.state.savedCallback();
						this.closeDialog();
					}}
					onDialogClose={() => {
						this.closeDialog();
					}}
				/> : undefined}



				<ProductCatalogModalProvider 
                    accountDeleteDialogData={this.state.accountDeleteDialogData}
                />
                </div>
            );
    }
}

CustomerList.defaultProps = {
	perpage: 30,
	showLockedUsersWithTag: true,
}

export default withSnackbar(withStyles(styles)(CustomerList));
