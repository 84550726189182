import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import {CardElement, injectStripe} from 'react-stripe-elements';
import { Button } from '@mui/material';
import DataHandler from '../../general/DataHandler';

class CreditCardForm extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/CreditCardForm");
        
        this.submit = this.submit.bind(this);
        this.getIntent = this.getIntent.bind(this);
    }

    componentDidMount () {
        super.componentDidMount();

        this.getIntent();
    }

    async getIntent() {
        const response = await DataHandler.get({url: 'settings/subscription/getSetupIntent'});
        this.setState({setupIntent: response.intent.client_secret});
    }

    async submit() {        
        let {setupIntent} = await this.props.stripe.handleCardSetup(this.state.setupIntent);
            
        if (setupIntent && setupIntent.payment_method)
            this.props.cardTokenCreated(setupIntent.payment_method);
    }

    render() {
        const { tr } = this;
        return (
            <React.Fragment>
                <CardElement />

                {this.props.showButtons === true && (
                <React.Fragment>
                    <div style={{flex: "0 0 auto", margin: "25px 20px 0", display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                        <Button onClick={() => this.props.close()}>
                            {tr("Cancel")}
                        </Button>
                        <Button onClick={this.submit}>
                            {tr("Change card")}
                        </Button>
                    </div>
                </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    static defaultProps = {
        showButtons: true
    }
}

export default injectStripe(CreditCardForm);