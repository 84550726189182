import PropTypes from "prop-types";
import React from 'react';
import TaimerComponent from "../TaimerComponent";

/* material ui */
import { MenuItem } from '@mui/material';

/* local components */
import ContextMenu from '../general/ContextMenu';
import { formatInputNumber } from '../helpers';
import AutoCompleteCell from '../list/cells/AutoCompleteCell';
import OutlinedField from "./../general/OutlinedField";
import DateCell from "./../list/cells/DateCell";
import StatusCell from "./../list/cells/StatusCell";
import TextInputCell from './../list/cells/TextInputCell';
import TreeCell from './../list/cells/TreeCell';
import CurrencyListCell from "./../list/CurrencyListCell";
import LinkListCell from "./../list/LinkListCell";
import List from './../list/List';
import ListCell from './../list/ListCell';
import ListRow from './../list/ListRow';
import PropsOnlyListRow from "./../list/PropsOnlyListRow";
import StyledTooltip from '../general/StyledTooltip';
import Link from '../general/Link';

/* data backend */
import DataHandler from './../general/DataHandler';

import { ReactComponent as AddIcon } from './../general/icons/add.svg';
import { ReactComponent as ExpenseIcon } from './../general/icons/Expense.svg';
import { ReactComponent as RemoveIcon } from './../general/icons/remove.svg';
import { ReactComponent as ToBillIcon } from './../general/icons/ToBill.svg';
import { ReactComponent as TravelExpenseIcon } from './../general/icons/TravelExpense.svg';


/* css */
import './TabCosts.css';

/* context */
import { SettingsContext } from './../SettingsContext';

/* material icons */
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import withStyles from '@mui/styles/withStyles';

/* other */
import { format } from "date-fns";
import _ from 'lodash';
import colors from '../colors';

const styles = theme => ({
    treeCellInput: {
        width: "70% !important",
        paddingRight: 8,
        paddingLeft: 5+8+2,
        paddingTop: 7,
        paddingBottom: 6,
        borderRadius: 2,
        textAlign: 'left',
    },
});

class CostRow extends PropsOnlyListRow {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, {}, {}, "projects/TabCosts");

        this.descriptionCell = React.createRef();

    }

    componentDidMount() {
        super.componentDidMount();
    }

    cellEdited = (name, value) => {

        if (!value || this.props.data[name] == value)
            return;

        if (this.context.addons.nav && !this.props.data.nav_worktype && name != 'nav_worktype' && name != 'deleted') {
            this.setState({missingWorktype: true});
            this.props.rowProps.enqueueSnackbar(this.tr("Please enter worktype."), {
                variant: "warning"
            }); 
        } else if (this.context.addons.nav && (this.props.data.nav_worktype || name == 'nav_worktype')) {
            this.setState({missingWorktype: false});
        }

        if (name == 'deleted') {
            this.props.rowProps.onDelete(value);
            this.setDataAndUpdate(name, 1);
            return;
        }

        this.setDataAndUpdate(name, value);
        if (this.props.data.sub_type == '5') {
            setTimeout(() => this.props.rowProps.updateComponent(), 1000);
        }

    }

    statusTooltip = data => {
        if (!data.invoice_status_reasons?.length)
            return "";

        const statusCodes = {
            forced_not_invoiceable: this.tr('forced_not_invoiceable'),
            project_not_invoiceable: this.tr('project_not_invoiceable'),
            other_companies_hours_not_invoiceable: this.tr('other_companies_hours_not_invoiceable'),
            hours_not_invoiceable: this.tr('hours_not_invoiceable'),
            hours_not_invoiceable_before: this.tr('hours_not_invoiceable_before'),
            overtime_hours_not_approved: this.tr('overtime_hours_not_approved'),
            hours_not_approved: this.tr('hours_not_approved'),
            bills_not_invoiceable: this.tr('bills_not_invoiceable'),
            expenses_not_invoiceable: this.tr('expenses_not_invoiceable'),
            expenses_not_invoiceable_before: this.tr('expenses_not_invoiceable_before'),
            hours_not_submitted: this.tr('hours_not_submitted'),
            attached_to_invoice: this.tr('attached_to_${bill_id}_invoice', data.bill_id)
        };

        return data.invoice_status_reasons?.map(e => statusCodes[e] && <>{statusCodes[e]}<br/></>)
    }

    goToInvoices = (e) => {
        e.preventDefault && e.preventDefault();
		e.stopPropagation && e.stopPropagation();

        this.context.functions.updateView({ module: "invoices", action: "main", selectedTab: "invoices" });
    }

    renderInvoicesTooltip = (invoices) => {
        return invoices.map(i => {
            return (
                <p><Link openInNewTab url={{ module: 'invoices', action: 'view', id: i.bills_id }}>
                    {i.invoice_nr}
                </Link></p>
            )
        });
    }

    defineCells() {
        const { taimerAccount, functions: { checkPrivilege, hasPrivilege } } = this.context;
        const { data, columnOrder, columnWidthMap, sharedData, rowProps } = this.props;
        const { currency } = rowProps;

        const props = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": `cell ${prop}`, style: {width: columnWidthMap[prop] + 'px', flex: columnWidthMap[prop] + " 1 0px" }} }
        });
        const cellProps = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": prop, listCellProps: {"className": prop}, width: columnWidthMap[prop], name: prop, value: data[prop]}}
        });

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency
        }).format;

        const dateFormat = new Intl.DateTimeFormat(taimerAccount.numberFormat).format;

        const defineEditable = (fieldType) => {
            if (fieldType == 'nav_worktype') 
                return (data.sub_type != '11') && data.nav_worktype_editable > 0;
            if (data.bills_id > 0)
                return false;
            if (!rowProps.editable)
                return false;
            if (['2', '3', '5', '11'].includes(data.sub_type))
                if (['5', '11'].includes(data.sub_type) && ['entry_date', 'name', 'quantity', 'value'].includes(fieldType))
                    return true;
                else if (data.sub_type != '5' && fieldType == 'list_price')
                    return true;
                else
                    return false;
            else
                return false;
        }

        const commonProps = {
            inEditMode:false,
            editable: false,
            listCellProps: {zeroBasis: true, inEditMode: false}
        };

        const linkDefinition = (data) => {
            let obj = { id: data.content_id, module: 'worktrips', action: 'modify', expenseType: '2' };
            if (data.sub_type == 1)
                obj = { id: data.id, module: 'workhours', action: 'calendar' };
            else if (data.sub_type == 2)
                obj = { ...obj, id: data.content_id, module: 'receivedinvoice', action: 'view' };
            else if (data.sub_type == 3)
                obj = { ...obj, expenseType: '1' };
            return obj;
        }

        const enableLink = 
            [8,7,6,9].indexOf(parseInt(data.sub_type)) !== -1 && checkPrivilege("worktrips", "approve") || // travel
           // [1,].indexOf(parseInt(data.sub_type)) !== -1 && hasPrivilege("workhours") || 
            [2,].indexOf(parseInt(data.sub_type)) !== -1 && checkPrivilege("receivedinvoices", "approve") || // recieved invoice
            [3,].indexOf(parseInt(data.sub_type)) !== -1 && checkPrivilege("worktrips", "approve");

        const linkButtonText = (data) => {
            if (data.sub_type == 2)
                return <React.Fragment><ToBillIcon /> {this.tr('To bill')} </React.Fragment>;
            else if (data.sub_type == 3)
                return <React.Fragment><ExpenseIcon /> {this.tr('To purchase expense')} </React.Fragment>;
            else
                return <React.Fragment><TravelExpenseIcon /> {this.tr('To travel expense')} </React.Fragment>;
        }

        const uninvoicedStateButtonText = () => {
            if (data.bills_id > 0) {
                const transl = data.invoice_status === 1 ? this.tr("Attached, Invoice") : this.tr("Invoice");
                const bill_id = data.bills_id == '999999999' ? "" : data.bill_id;
                return { name: `${transl} ${bill_id}`, color: colors.dark_sky_blue};
            }
            else if (data.invoice_status === 2 && data.approve_status === undefined)
                return { name: this.tr("Not invoiceable"), color: colors.warm_pink};
            else if (data.invoice_status === 2 && data.approve_status === 2)
                return { name: this.tr("Not invoiceable, Not approved"), color: colors.warm_pink};
            else if (data.invoice_status === 2 && data.approve_status === 0)
                return { name: this.tr("Not invoiceable, Not submitted"), color: colors.warm_pink};
            else if (data.approve_status === 2)
                return { name: this.tr("Uninvoiced, Not approved"), color: colors.bluey_purple};
            else if (data.approve_status === 0)
                return { name: this.tr("Uninvoiced, Not submitted"), color: colors.bluey_purple};
            else
                return { name: this.tr("Uninvoiced"), color: colors.bluey_purple};
        }        

        const treeOptions = (quote) => {
            if(quote != null){
                return quote.map(q => {
                    return {...q, children: q.children.map(qq => {
                        return {...qq, children: qq.children.filter(qqq => qqq.work_type == data.quote_type || qqq.work_type == data.quote_extra_type)}
                    }
                    )}
                });
            }
        }

        if (data.bills_id == '999999999')
            data.bills_id = 0;

        const fullname = `${data.fullname}${(data.users_company < 1 ? ` (${this.tr("freelancer")})` : '')}${(data.user_locked > 0 ? ` (${this.tr("locked")})` : '')}`;

        let clickableStatus = {};
        if (data.bills_id > 0 && data.bills_id != '999999999')
            clickableStatus = {
                clickable: true,
                urlHandler: value => `index.html?module=invoices&action=view&id=${data.bills_id}`
            };
        else if (data.approve_status === 2 && checkPrivilege("workhours", "approve_own_project"))
            clickableStatus = {
                clickable: true,
                urlHandler: value => `index.html?module=workhours&action=calendar&tab=approvals`
            };

        /* hour entries and expenses can be forced to non-billable */
        let billableEditable = data.sub_type === '1' || data.main_type === '2';
        if (Number(data.bills_id) > 0 || data.invoice_status_reasons?.includes("project_not_invoiceable"))
            billableEditable = false;

        // if (data.main_type == 2) {
        //     console.log(data.billing, sharedData.billingOptions);
        // }

        const cells = {
            "context": data.sub_type == 1 && data.bills_id < 1 ? (<div className="cell" style={{width: columnWidthMap.context + 'px', flex: columnWidthMap.context + " 1 0px" }} />) : (
                <ListCell className="context-cell" width={columnWidthMap.context} zeroBasis={true} onlyDisplay={true}>
                    <ContextMenu label={<ContextMenuIcon /*style={{ color: "#b0b3ba" }}*/ />} buttonProps={{ className: 'action-menu' }} className="cell row-menu" style={{width: columnWidthMap.context + 'px', flex: columnWidthMap.context + " 1 0px" }} noExpandIcon>
                        {rowProps.editable && data.sub_type == 5 && <MenuItem className="delete" onClick={() => this.cellEdited('deleted', data.id)}><RemoveIcon className="Delete" />{this.tr("Delete")}</MenuItem>}
                        {data.bills_id > 0 && 
                            <MenuItem 
                                onClick={(evt) => (evt.button !== 1) && ( rowProps.updateView({ module: 'invoices', action: 'view', id: data.bills_id }, evt.ctrlKey || evt.metaKey || evt.button === 1) )}
                                onMouseUp={(evt) => (evt.button === 1) && ( rowProps.updateView({ module: 'invoices', action: 'view', id: data.bills_id }, evt.ctrlKey || evt.metaKey || evt.button === 1) )}>
                               <AddIcon /> {this.tr("Invoice")}
                            </MenuItem>}
                        {data.sub_type != 1 && data.sub_type != 5 && 
                            <MenuItem 
                                onClick={(evt) => (evt.button !== 1) && ( rowProps.updateView({ ...linkDefinition(data) }, evt.ctrlKey || evt.metaKey || evt.button === 1) )}
                                onMouseUp={(evt) => (evt.button === 1) && ( rowProps.updateView({ ...linkDefinition(data) }, evt.ctrlKey || evt.metaKey || evt.button === 1) )}>
                                {linkButtonText(data)}
                            </MenuItem>}
                    </ContextMenu>
                </ListCell>
            ),
            "quotes": (
                <TreeCell
                    width={columnWidthMap.quotes}
                    treeSelectProps={{
                        InputProps: {classes: {
                            input: sharedData.classes.treeCellInput
                        }},
                    }}
                    listCellProps={{
                        className: "tree",
                        inEditMode: rowProps.editable,
                        editable: rowProps.editable && data.bills_id < 1
                    }}                
                    value={data.quote_rows_id}                
                    options={treeOptions(sharedData.quotes)}
                    onEdited={option => option !== 0 && this.cellEdited("quote_rows_id", option)}  />               
            ),
            "subTypeName": (
                enableLink ? 
                    <LinkListCell
                    {...commonProps}
                    handleClick={(evt) => { rowProps.updateView({ ...linkDefinition(data) }, evt.ctrlKey || evt.metaKey || evt.button === 1) }}
                    width={columnWidthMap.subTypeName}
                    value={data.subTypeName} 
                    noTab={true} />
                :
                <ListCell
                    {...commonProps}
                    width={columnWidthMap.subTypeName}
                    value={data.subTypeName} />               
            ),
            "fullname": (
                <ListCell
                    {...commonProps}
                    width={columnWidthMap.fullname}
                    value={fullname} />
            ),
            "worktask": (
                this.context.addons.nav ? (
                    <AutoCompleteCell
                        editable={defineEditable('nav_worktype')}
                        name="nav_worktype"
                        width={columnWidthMap.worktask}
                        value={data.nav_worktype}
                        autoCompleteData={data.sub_type === '1' ? this.props.sharedData.navJobtypesForWh : this.props.sharedData.navJobtypesForPurchases}
                        listCellProps={{
                            noInitFocus: true,
                            className: 'autocomplete-cell',
                            zeroBasis: true, 
                            inEditMode: false, 
                            showErrorBorder: this.context.addons.nav && !data.nav_worktype && (this.state.missingWorktype || data.sub_type == '5')
                        }}
                        onEdited={value => {
                            this.cellEdited("nav_worktype", value)
                        }} />   
                ) : (
                    <ListCell
                        {...commonProps}
                        width={columnWidthMap.worktask}
                        value={data.worktask} />
                )
            ),            
            "entry_date": (
                <DateCell
                    {...commonProps}
                    listCellProps={{className: 'datecell', inEditMode: false}}
                    editable={defineEditable("entry_date")}
                    width={columnWidthMap.entry_date}
                    value={data.entry_date}
                    onEdited={(n, date) => this.cellEdited("entry_date", date)} />
            ),
            "billable": (
                <AutoCompleteCell
                    inEditMode={true}
                    width={columnWidthMap.billable}
                    name="billable"
                    value={data.main_type == 2 ? data.billing : data.billable}
                    listCellProps={{className: "billable"}}
                    editable={billableEditable}
                    autoCompleteData={data.main_type == 2 ? sharedData.billingOptions : sharedData.billableOptions}
                    searchable={false}
                    onEdited={value => {
                        this.cellEdited("billable", value)
                    }} />
            ),
            "billed": 
                data.multi_invoices?.length > 1 ? 
                    <ListCell width={columnWidthMap.invoiced} onlyDisplay alignCell textAlign={"left"}>
                        <div className={`cellValue normal pseudoLink`}>
                            <StyledTooltip content={this.renderInvoicesTooltip(data.multi_invoices)} placement="right">
                                <a href={`index.html?module=invoices&action=main&selectedTab=invoices`} onClick={(e) => this.goToInvoices(e)}>
                                    {this.tr("${amount} invoices", {amount: data.multi_invoices?.length})}
                                </a>
                            </StyledTooltip>                    
                        </div>
                    </ListCell>
                : (
                data.id > 0 ? (
                <StatusCell
                    {...commonProps}
                    value={null}
                    listCellProps={{className: 'statusCell'}} 
                    width={columnWidthMap.billed}
                    tooltip={this.statusTooltip(data)}
                    {...clickableStatus}
                    displayData={uninvoicedStateButtonText()}/>
                ) : (
                    <ListCell
                    {...commonProps}
                    name="placeholder"
                    textAlign="right"
                    value={""} />
                )
            ),
            "name": (
                <TextInputCell
                    {...commonProps}
                    editable={defineEditable("name")}
                    ref={this.descriptionCell}
                    listCellProps={{
                        placeholder: "",
                        style: {
                            fontStyle: "normal"
                        },
                        inEditMode: false
                    }}
                    focusOnMount
                    width={columnWidthMap.name}
                    name="name"
                    value={data.name}
                    onEdited={this.cellEdited} />
            ),            
            "quantity": (
               <TextInputCell
                    {...commonProps}
                    editable={defineEditable("quantity")}
                    width={columnWidthMap.quantity}
                    name="quantity"
                    value={formatInputNumber(data.quantity)}
                    validation={["numeric"]}
                    onEdited={this.cellEdited} />
            ),
            "value": (
               <TextInputCell
                    {...commonProps}
                    editable={defineEditable("value")}
                    textAlign="right"
                    listCellType={CurrencyListCell}
                    listCellProps={{
                        currency: currency,
                        inEditMode: false,
                        maximumFractionDigits: 4,
                    }}
                    width={columnWidthMap.value}
                    name="value"
                    value={data.value}
                    validation={["numeric"]}
                    onEdited={this.cellEdited} />
            ),
            "total_value": (
                <ListCell
                    {...commonProps}
                    textAlign="right"
                    editable={false}
                    width={columnWidthMap.total_value}
                    name="total_value"
                    value={/*data.sub_type == '5' ? '-' : */currencyFormatter(data.quantity * data.value)} />
            ),
            "list_price": (
                <TextInputCell
                    {...commonProps}
                    editable={defineEditable("list_price")}
                    textAlign="right"
                    listCellType={CurrencyListCell}
                    listCellProps={{
                        currency: currency,
                        maximumFractionDigits: 4,
                    }}
                    width={columnWidthMap.list_price}
                    name="list_price"
                    value={data.sub_type == '5' ? data.value : data.list_price}
                    validation={["numeric"]}
                    onEdited={this.cellEdited} />
            ),
            "list_price_total": (
                <ListCell
                    {...commonProps}
                    textAlign="right"
                    name="list_price_total"
                    width={columnWidthMap.list_price_total}
                    value={currencyFormatter(data.list_price_total ?? 0)} />
            ),
            "billed_price": (
                <ListCell
                    {...commonProps}
                    editable={rowProps.editable}
                    textAlign="right"
                    editable={false}
                    name="billed_price"
                    width={columnWidthMap.billed_price}
                    value={currencyFormatter(data.billed == '2' ? 0 : data.billed_price)} />
            )          
        };

        return cells;
    }

}

class TotalsRow extends ListRow {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, {}, {}, "projects/TabCosts");
    }

    render = () => {

        const { taimerAccount } = this.context;
        const { columnOrder, columnWidthMap, sharedData, rowProps } = this.props;
        const { currency } = rowProps;

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency
        }).format;

        const dateFormat = new Intl.DateTimeFormat(taimerAccount.numberFormat).format;

        const commonProps = {
            inEditMode: false,
            editable: false
        };

        const {totals} = rowProps;

        const cells = {
            "context": 
                ( <div className="cell" style={{width: columnWidthMap.context, flex: columnWidthMap.context + " 1 0px"}}/>
            ),
            "name": (
                <ListCell
                    {...commonProps}
                    width={columnWidthMap.quotes + columnWidthMap.subTypeName + columnWidthMap.fullname + columnWidthMap.worktask + columnWidthMap.entry_date + columnWidthMap.billable + columnWidthMap.billed + columnWidthMap.name}
                    value={`${this.tr('Total')}:`} />
            ),          
            "quantity": (
               <TextInputCell
                    {...commonProps}
                    width={columnWidthMap.quantity}
                    name="quantity"
                    value={formatInputNumber(totals.quantity)} />
            ),
            "value": (
                <div style={{width: columnWidthMap.value}}/>
            ),
            "total_value": (
                <ListCell
                    {...commonProps}
                    textAlign="right"
                    listCellType={CurrencyListCell}
                    width={columnWidthMap.total_value}
                    name="total_value"
                    value={currencyFormatter(totals.total_value)} />
            ),
            "list_price": (
                <div style={{width: columnWidthMap.list_price}}/>
            ),
            "list_price_total": (
                <ListCell
                    {...commonProps}
                    textAlign="right"
                    listCellType={CurrencyListCell}
                    editable={false}
                    name="list_price_total"
                    width={columnWidthMap.list_price_total}
                    value={currencyFormatter(totals.list_price_total)} />
            ),
            "billed_price": (
                <ListCell
                    {...commonProps}
                    textAlign="right"
                    listCellType={CurrencyListCell}
                    editable={false}
                    name="billed_price"
                    width={columnWidthMap.billed_price}
                    value={currencyFormatter(totals.billed_price > 0 ? totals.billed_price : 0)} />
            )           
        };

        return (<React.Fragment>
            <div className="emptyspace" />
            <aside className="row totals-row" style={{
                height: "44px",
                lineHeight: "44px",
                display: "flex"
            }}>
                {columnOrder.map(columnName => cells[columnName])}
            </aside></React.Fragment>
        );
    }
}

class TabCosts extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "projects/TabCosts");
        this.list = React.createRef();

        this.state = {
            statistics: {},
            costs: [],
            quotes: [],
            selectedStatus: undefined,
            selectedType: undefined,
            selectedUser: undefined,
            selectedUserType: undefined,
            pageCount: 1,
            totalCount: "–",
            perpage: 30,
            page: 1,
            hasFreelancers: false,
            actualDifference: 0,
            actualDifferenceNoWork: 0,
            actualDifferencePercent: 0,
            actualDifferenceNoWorkPercent: 0,
            currency: "EUR",
            companies: [],
            navJobtypes: [],
            totals: {
                quantity: 0,
                list_price: 0,
                total_value: 0,
                list_price_total: 0,
                billed_price: 0                
            },
            freshlyAddedCostsIds: []
        }

        this.filterOptions = {
            statuses: [
                {id: 0, label: this.tr('all')}, 
                {id: 1, label: this.tr('Invoiced'), name: this.tr('Invoiced'), color: colors.greenish_cyan}, 
                {id: 2, label: this.tr('Uninvoiced'), name: this.tr('Uninvoiced'), color: '#6f42c1'}
            ],
            types: [
                {id: 0, label: this.tr('all')},
                {id: "1", label: this.tr("Own work")},
                {id: "2", label: this.tr("Travel expenses")},
                {id: "3", label: this.tr("Subcontracting")},
            ],
            users: [],
            usertypes: [
                {id: 0, label: this.tr("all")},
                {id: 1, label: this.tr("Users")},
                {id: 2, label: this.tr("Freelancers")},
            ]
        }

        this.subTypes = [
            {id: "1", name: this.tr("Hour entries")},
            {id: "2", name: this.tr("Bills")},
            {id: "3", name: this.tr("Expenses")},
            {id: "5", name: this.tr("Tot. cost")},
            {id: "6", name: this.tr("Travel expense")},
            {id: "7", name: this.tr("Travel expense")},
            {id: "8", name: this.tr("Travel expense")},
            {id: "9", name: this.tr("Travel expense")},
            {id: "11", name: this.tr("Refund")}
        ];
        this.billableOptions = [ //workinghours
            {id: "0", value: "0", name: this.tr("Default")},
            {id: "-1",value: "-1", name: this.tr("Non-billable")},
        ];
        this.billingOptions = [  //expenses
            {id: "1", value: "1", name: this.tr("Default")},
            {id: "-1",value: "-1", name: this.tr("Non-billable")},
        ];

        this.travellingTypesMap = [
            {id: '6', string: this.tr("daily allowance")},
            {id: '7', string: this.tr("mileage")},
            {id: '8', string: this.tr("additional allowance")},
            {id: '9', string: this.tr("other allowance")}
        ];        

        this.newRow = {
            projects_id: this.props.id,
            entry_date: format(new Date(), "YYYY-MM-DD"),
            main_type: "3",
            sub_type: "5",
            quantity: "1",
            billed: '2',
            name: '',
            value: '',
            list_price: '0',
            id: -1,
            bills_id: '0',
        };        
    }
    getNavJobtypes = (company, project) => {
        return DataHandler.get(
            {url: 'subjects/nav/worktypes/' + company, project},
        );
    }

    updateStatistics = () => {
        const { id, company } = this.props;
        const { selectedType, selectedStatus, selectedUser, selectedUserType, page, perpage, sort } = this.state;
        return DataHandler.get(
            {url: `projects/${id}/costs`, main_type: selectedType, billed: selectedStatus, users_id: selectedUser, usertype: selectedUserType, page, perpage, company, sort},
        ).done(resp => {
            this.setState({
                statistics: {
                    totalUninvoiced: resp.total_uninvoiced,
                    totalInvoiced: resp.total_invoiced,
                    totalToBeInvoiced: resp.total_to_invoice
                }
            });
        });
    }

    updateComponentData = async (initial) => {
        const { id, classes, company } = this.props;
        const { selectedType, selectedStatus, selectedUser, selectedUserType, page, perpage, sort } = this.state;
       
        const params = {url: `projects/${id}/costs`, main_type: selectedType, billed: selectedStatus, users_id: selectedUser, usertype: selectedUserType, page, perpage, company, sort};
        
        return DataHandler.get({...params}).done(data => {

            data.quotes.unshift({id: -1, disabled: false, name: this.tr("Remove targeting"), value: -1, children: []})

            const {actual_costs, quotes, page_count, count} = data;

            const costs = actual_costs.map((ac, i) => {

                ac['subTypeName'] = this.subTypes.find(st => st.id == ac['sub_type']).name;
                ac['mainTypeName'] = this.filterOptions.types.find(st => st.id == ac['main_type']).label;
                
                if (this.travellingTypesMap.map(tt => tt.id).includes(ac.sub_type)) {
                    ac['name'] = `${this.tr("Nr.")} ${ac.content_id}, ${this.travellingTypesMap.find(t => t.id == ac.sub_type).string}${ac.description ? `, ${ac.description}` : ''}`;
                }

                if (ac.sub_type == '2' && ac.invoice_nr !== '')
                    ac['name'] = `${this.tr("Nr.")} ${ac.invoice_nr}, ${ac.name}`;

                //totalCostsNoWork += ac.main_type === '1' && ac.sub_type === '1' ? 0 : (ac.quantity * ac.value);

                if (ac.quote_rows_id > 0) {
                    quotes.find(function (ee) {
                        if (ee.id !== ac.quote_rows_id)
                            return false;
                        ac.targeted = (ac.targeted-0) + (ac.quantity * ac.value);

                        return true;
                    });
                }                

                ac.id = i+1;
                if (actual_costs.length === i+1)
                    ac.lastRow = 1;

                return ac;
            });

            if (initial) 
                this.filterOptions.users = [{id: 0, label: this.tr('all')}, ...data.users];

            this.setState(state => {
                if (initial)
                    state.hasFreelancers = data.has_freelancers > 0 ? true : false;
                state.costs = costs;
                state.quotes = quotes;
                state.corporate_hour_invoicing_mode = data.corporate_hour_invoicing_mode;
                state.statistics.totalUninvoiced = data.total_uninvoiced;
                state.statistics.totalInvoiced = data.total_invoiced;
                state.statistics.totalToBeInvoiced = data.total_to_invoice;
                state.pageCount = page_count !== undefined ? page_count : 1;
                state.totalCount = count !== undefined ? count : 0;
                return state;
            }, () => this.updateStatistics());
        });
    }

    delay = (t, v) => {
        return new Promise(function(resolve) { 
            setTimeout(resolve.bind(null, v), t)
        });
    }
    componentDidMount() {
        const { company, id } = this.props;
        
        if (this.context.addons.nav)
            this.getNavJobtypes(company, id).done(navJobtypes => this.setState({navJobtypes}, () => this.updateComponentData(true)));
        else
            this.updateComponentData(true);
        
        DataHandler.get({url: `subjects/companies/projects/project_actual_costs_read`, currency: 1}).done(companies => {
            let c = false;
            companies.forEach(company => {
                if(company.id == this.props.company) {
                    this.setState({currency: company.currency});
                    c = true;
                }
            })
            if (!c) {
                this.setState({currency: companies[0].currency})
            }
            this.setState({companies})
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.perpage !== nextState.perpage || this.state.page !== nextState.page)
            return false;

        return true;
    }

    componentWillUnmount() {
        if (this.context.addons.nav) {
            if (this.state.freshlyAddedCostsIds.length > 0) {
                this.state.freshlyAddedCostsIds.forEach(c => {
                    const freshCostData = this.list.current?.state.data.find(st => st.content_id == c);
                    if (freshCostData && !freshCostData.nav_worktype)
                        DataHandler.post({url: `projects/${this.props.id}/cost`}, {...freshCostData, deleted: 1}).done(() => {});
                });
            }
        }
    }

    countTotals = (costs) => {
        const {totals} = this.state;
        const newTotals = {};

        Object.entries(totals).forEach(([t, v]) => {
            newTotals[t] = costs.reduce((acc, c) => {
                let val = Number(c[t]);
                if (t == 'total_value')
                    val = c.quantity * c.value;
                return acc + val;
            }, 0)
        });
        this.setState({totals: newTotals});
    }    

    sortRows = (columnName, isAscending) => {
        const sort = { name: columnName, asc: isAscending };
        this.setState({sort}, () => this.updateComponentData());
    }    

    handleFilterChange = (targetFilter, value) => {
        this.setState({ [`selected${targetFilter}`]: value }, () => {
            this.updateComponentData();
        });        
    }

    addRow = () => {
        const update = {
            selectedStatus: undefined,
            selectedType: undefined,
            selectedUser: undefined,
            selectedUserType: undefined,
            page: 1,
        };
        
        this.setState(update, () => {
            const newRow = _.cloneDeep(this.newRow);
            DataHandler.post({url: `projects/${this.props.id}/cost`}, newRow).done( async (resp) => {
                if (this.context.addons.nav && resp?.new_id)
                    this.setState({freshlyAddedCostsIds: [...this.state.freshlyAddedCostsIds, resp.new_id]})                
                this.list.current.addNewRow({
                    content_id: resp?.new_id, 
                    id: this.list.current.formattedData.length + 1, 
                    fullname: this.context.userObject.fullname,
                    invoice_status: resp?.invoice_status,
                    invoice_status_reasons: resp?.invoice_status_reasons,
                });
                this.list.current.listElementReferences[0].current.getCellRef('name').listCell.current.openEdit();
            })
        });
    }

    render () {
        const { id, editable, classes } = this.props;
        const { costs, quotes, statistics, pageCount, totalCount, perpage, corporate_hour_invoicing_mode, navJobtypes } = this.state;
        const { taimerAccount, versionId } = this.context;
        const currency = this.props.currency || taimerAccount.currency || 'EUR';

        const columns = [
            { name: "context", header: "", columnHeaderType: editable ? "roundButton" : "", width: 50,"showMenu": false, "resizeable": false, "moveable": false, "hideable": false },
            { name: "quotes", header: this.tr("Quote targeting"), width: 150 },
            { name: "subTypeName", header: this.tr("Type"), width: 120 },
            { name: "fullname", header: this.tr("User"), width: 150 },
            { name: "worktask", header: this.tr("Job type"), width: 120 },
            { name: "entry_date", header: this.tr("Created"), width: 100 },
            { name: "name", header: this.tr("Description"), width: 300 },
            { name: "billable", header: this.tr("billable"), width: 170 },
            { name: "billed", header: this.tr("Invoicing"), width: 170 },
            { name: "quantity", header: this.tr("Qty"), width: 113 },
            { name: "value", header: this.tr("Unit cost"), width: 113 },
            { name: "total_value", header: this.tr("Own cost"), width: 113 },
            { name: "list_price", header: this.tr("Selling price"), width: 113 },
            { name: "list_price_total", header: this.tr("Invoiceable"), width: 113 },
            { name: "billed_price", header: this.tr("Actually Invoiced"), width: 113 },
        ];

        const rowProps = {
            editable,
            listRef: this.list,
            updateView : this.props.updateView,
            updateComponent: this.updateComponentData,
            onUpdate: data => {
                DataHandler.post({url: `projects/${id}/cost`}, data).done(() => {
                    if (data.deleted > 0 || data.sub_type === '1'|| data.main_type === '2')
                        setTimeout(() => this.updateComponentData(true), 1000); //fix-me
                    else
                        this.updateStatistics();
                })
            },
            onDelete: (id) => this.setState({costs: this.state.costs.filter(d => d.id == id)}, () => this.list.current.removeRow(id)),                        
            currency: currency,
            listContainer: this.listContainer,
            corporate_hour_invoicing_mode: corporate_hour_invoicing_mode,
            enqueueSnackbar: this.props.enqueueSnackbar
        }

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency
        }).format;

        const sharedData = {
            statuses: this.filterOptions.statuses,
            quotes: quotes,
            classes: classes,
            navJobtypesForWh: navJobtypes.filter(e => e.for_workhours === '1'),
            navJobtypesForPurchases: navJobtypes.filter(e => e.for_purchases === '1'),
            billableOptions: this.billableOptions,
            billingOptions: this.billingOptions
        }

        return (
            <div id="projects-costs" ref={cont => this.listContainer = cont}>
                <div className="column">
                    <div className="header-container" >
                        <div className="filter-container">
                            <OutlinedField
                                className="costs-filter"
                                select
                                name="status"
                                label={this.tr("Invoicing status")}
                                value={this.state.selectedStatus}
                                onChange={e => this.handleFilterChange('Status', e.target.value)} >

                                {this.filterOptions.statuses.map(opt => {
                                    return <MenuItem key={opt.id} value={opt.id}>{opt.label}</MenuItem>
                                })}
                            </OutlinedField>

                            <OutlinedField
                                className="costs-filter"
                                select
                                name="type"
                                label={this.tr("By type")}
                                value={this.state.selectedType}
                                onChange={e => this.handleFilterChange('Type', e.target.value)} >
                                {this.filterOptions.types.map(opt => {
                                    return <MenuItem key={opt.id} value={opt.id}>{opt.label}</MenuItem>
                                })}
                            </OutlinedField>
                            <OutlinedField
                                className="costs-filter"
                                select
                                name="user"
                                label={this.tr("By user")}
                                value={this.state.selectedUser}
                                onChange={e => this.handleFilterChange('User', e.target.value)}>
                                {this.filterOptions.users.map(opt => {
                                    return <MenuItem key={opt.id} value={opt.id}>{opt.label}</MenuItem>
                                })}
                            </OutlinedField>
                            {this.state.hasFreelancers && <OutlinedField
                                className="costs-filter"
                                select
                                name="usertype"
                                label={this.tr("By usertype")}
                                value={this.state.selectedUserType}
                                onChange={e => this.handleFilterChange('UserType', e.target.value)}>
                                {this.filterOptions.usertypes.map(opt => {
                                    return <MenuItem key={opt.id} value={opt.id}>{opt.label}</MenuItem>
                                })}
                            </OutlinedField> }                                     
                            {this.props.renderNoteToInvoiceCreator && this.props.renderNoteToInvoiceCreator()}               
                        </div>                    
                        <div className="statistics-container">
                            <div className="amount">
                                <h2>{this.tr('Total own costs')}</h2>
                                {currencyFormatter(statistics.totalUninvoiced)} <span className="subtitle">{this.tr('excl. vat')}</span>
                            </div>                                                      
                            <div className="amount">
                                <h2>{this.tr('To be invoiced')}</h2>
                                {currencyFormatter(statistics.totalToBeInvoiced)} <span className="subtitle">{this.tr('excl. vat')}</span>
                            </div> 
                            <div className="amount">
                                <h2>{this.tr('Invoiced')}</h2>
                                {currencyFormatter(statistics.totalInvoiced)} <span className="subtitle">{this.tr('excl. vat')}</span>
                            </div>
                        </div>
                    </div>
                    <List
                    ref={this.list} 
                    data={costs} 
                    noStateData={true} 
                    columns={columns}
                    roundbutton={this.addRow}
                    listRowType={CostRow}
                    rowProps={rowProps}
                    newRow={this.newRow}
                    sharedData ={sharedData}
                    height="fitRemaining"
                    trimHeight={-20}
                    saveColumnConfig={true}
                    showPageSelector={true}
                    pageCount={pageCount}
                    totalCount={totalCount}
                    perpage={perpage}
                    onPageChange={page => {
                        this.list.current.emptyNewData();
                        this.setState({ page: page }, () => this.updateComponentData());
                    }}
                    onSortRows={this.sortRows}
                    onPerPageChange={value => {
                        this.list.current.emptyNewData(); 
                        this.setState({ perpage: value }, () => this.updateComponentData()); 
                    }}
                    userListSettingsKey="tabCosts"  />
                </div>
            </div>
        );
    }
}

TabCosts.propTypes = {
    id: PropTypes.string.isRequired,
};

export default withStyles(styles)(TabCosts);
