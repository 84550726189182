
import _ from 'lodash';

import {
    dimensionValue,
    dimensionHeader
} from "./AccountingSlider";

export interface ProjectDimensions {
    projectId: number;
    dimension: dimensionValue;
}

export interface RowProjectIds {
    projectId: number;
    rowId: number;
}

export interface ProjectParentData {
    id: number;
    parentid: number;
    project_id: string;
}

export interface ProcountorIntegrationSettings {
    use_parent_project_dimension_for_subprojects: number;
    add_project_dimension_automatically: number;
    project_dimension: number|string; // Header id for project dimension.
    project_parent_data: ProjectParentData[];
    separate_subproject_dimension: number;
    subproject_dimension: number|string; // Header id for subproject dimension.
}


export function selectTopMostParentProject(project: ProjectParentData, allProjects: ProjectParentData[]) {
    if (!project.parentid) {
        return project;
    }
    const parent = allProjects?.find(p => p.id == project.parentid);
    if (parent) {
        return selectTopMostParentProject(parent, allProjects);
    }
    return project;
}

/**
 * Gets Procountor project dimension from header given in integrationSettings (integrationSettings.project_dimension).
 * @param projectId id of project to get dimension for.
 * @param integrationSettings settings for Procountor dimensions.
 * @param dimensionHeaders dimension headers with options where project dimension is searched from.
 * @returns project dimension if found, null if not found.
 */
export function getProcountorProjectDimension(projectId: number|string, integrationSettings: ProcountorIntegrationSettings, dimensionHeaders: dimensionHeader[]) {
    const addProjectDimension = integrationSettings?.add_project_dimension_automatically == 1;
    if (!addProjectDimension) {
        return null;
    }

    let projectData: ProjectParentData|undefined = integrationSettings.project_parent_data?.find(p => p.id == projectId);
    if (!projectData) {
        return null;
    }
    let subProjectData: ProjectParentData|undefined;
    if (integrationSettings.use_parent_project_dimension_for_subprojects == 1 && integrationSettings.separate_subproject_dimension != 1) {
        projectData = selectTopMostParentProject(projectData, integrationSettings.project_parent_data);
    } else if (integrationSettings.separate_subproject_dimension == 1 && projectData.parentid > 0) {
        subProjectData = projectData;
        projectData = integrationSettings.project_parent_data?.find(p => p.id == subProjectData?.parentid);
    }
    if (!projectData) {
        return null;
    }

    const projectNumber = projectData?.project_id?.trim() || "";
    const subProjectNumber = subProjectData?.project_id?.trim() || "";
    let mainProjectHeader, subProjectHeader;
    if (integrationSettings.separate_subproject_dimension == 1 && subProjectData) {
        subProjectHeader = dimensionHeaders?.find(h => h.dimension_header == integrationSettings.subproject_dimension);
        mainProjectHeader = dimensionHeaders?.find(h => h.dimension_header == integrationSettings.project_dimension);
    } else {
        mainProjectHeader = dimensionHeaders?.find(h => h.dimension_header == integrationSettings.project_dimension);
    }
    if (!mainProjectHeader) {
        return null;
    }

    const mainProjectDimensions = mainProjectHeader?.options || [];
    const subProjectDimensions = subProjectHeader?.options || [];

    let projectDimension: dimensionValue|null = null;
    let subProjectDimension: dimensionValue|null = null;

    mainProjectDimensions.forEach(d => {
        const name = d.dimension_name?.trim().split(" ")[0] || "";
        if (name == projectNumber) {
            projectDimension = { header_id: mainProjectHeader.id, dimension_id: d.id };
        }
    });

    if (subProjectHeader) {
        subProjectDimensions.forEach(d => {
            const name = d.dimension_name?.trim().split(" ")[0] || "";
            if (name == subProjectNumber) {
                subProjectDimension = { header_id: subProjectHeader.id, dimension_id: d.id };
            }
        });
    }

    // Add empty dimension for header if dimension is not found.
    if (!projectDimension) { 
        projectDimension = { header_id: mainProjectHeader.id, dimension_id: "-1" };
    }

    if (subProjectHeader && !subProjectDimension) {
        subProjectDimension = { header_id: subProjectHeader.id, dimension_id: "-1" };
    }

    if (subProjectHeader) {
        return [projectDimension, subProjectDimension];
    }

    return projectDimension;
}

/**
 * Updates new project dimension value value to dimension_values -array to given rows.
 * @param rows rows to be updated.
 * @param newDimension dimension to be added.
 * @param keepType keep existing value if row has value of this type in same dimension header..
 * @returns updated rows.
 */
export function updateProjectDimensionValueToRows(rows, projectDimensions: ProjectDimensions[], rowProjectIds: RowProjectIds[], keepType?: number|null) {
    const headerRowCategories = [2,5];
    
    const resp = _.cloneDeep(rows).map(r => {
        // Don't add dimensions to header rows.
        if (headerRowCategories.find(h => h == r.row_category)) {
            return r;
        }

        const projectId = rowProjectIds.find(p => p.rowId == r.id)?.projectId;
        const newDimensions = projectDimensions.filter(d => d.projectId == projectId).map(d => d.dimension) || [];
        r.dimension_values = updateDimensionValues(r.dimension_values, newDimensions, keepType);
        return r;
    });

    return resp;
}

/**
 * Updates new value to dimension values array.
 * Removes current value from header and adds new one.
 * @param dimensionValues current dimension values.
 * @param newDimension dimensions to be added.
 * @param keepType keep existing value if value of this type exists in same dimension header.
 * @returns updated dimension values array.
 */
export function updateDimensionValues(dimensionValues: dimensionValue[], newDimensions: dimensionValue[], keepType?: number|null) {
    dimensionValues = dimensionValues || [];

    if (!Array.isArray(newDimensions)) {
        newDimensions = [newDimensions];
    }

    // Remove previously selected from header.
    // Don't remove if row has dimension with type keepType.
    newDimensions.forEach(newDimension => {
        const existingHeaderDimensionIndex = dimensionValues.findIndex(dv => dv?.header_id == newDimension?.header_id && dv?.type != keepType);
        if (existingHeaderDimensionIndex > -1) {
            dimensionValues.splice(existingHeaderDimensionIndex, 1);
        }

        const existingDimension = dimensionValues.find(dv => dv?.header_id == newDimension?.header_id);
        if (!existingDimension && newDimension && Number(newDimension.dimension_id) > 0) { // Don't add dimension if dimension_id < 1. This way dimension value can be emptied from header.
            dimensionValues.push(newDimension);
        }
    });

    return dimensionValues;
}